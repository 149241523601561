import { Button } from 'semantic-ui-react'
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import React from "react";
import '../../../../../../i18n';

import './mdl_upload.css';
import CMP_FILEUPLOAD from "../../../../../../components/cmp_fileupload/cmp_fileupload";



export default function({set_mdl_open, set_parentmdl_open, var_form, set_form, populate_function}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_ready, set_ready ] = useState(false);
    const [ var_processing, set_processing ] = useState(false);

    const [ var_fileuploadstatus, set_fileuploadstatus ] = useState('');
    const [ var_fileuploadname, set_fileuploadname ] = useState('');
    const [ var_fileuploadlabel, set_fileuploadlabel ] = useState(t('FORM DOCUMENT'));
    const [ var_fileuploaddirectory, set_fileuploaddirectory ] = useState('forms');
    const [ var_fileuploaddisplayname, set_fileuploaddisplayname ] = useState(null);
    const [ var_filetype, set_filetype ] = useState('application/pdf, image/png, image/jpg, image/jpeg');
    const [ var_fileextension, set_fileextension ] = useState(['pdf','png','jpg','jpeg']);
    const [ var_filesize, set_filesize ] = useState('10');
    const [ var_fileupload, set_fileupload ] = useState(null);



    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(var_fileuploadstatus === 'PENDING'){
            set_ready(true);
            const form = {...var_form};
            form['filename'] = var_fileuploadname;
            form['submittedfilename']=var_fileuploaddisplayname;
            set_form(form);
        }
        if(var_fileuploadstatus === 'COMPLETE'){
            post_activeform();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_fileuploadstatus]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function post_activeform() {
        try {
            let data = {};
            data['imagefile'] = var_form.filename;
            data['submittedfilename'] = var_form.submittedfilename;
            data['form_id'] = var_form.formid;
            data['individual_id'] = JSON.parse(sessionStorage.getItem('authentication')).id;
            data['location_id'] = var_form.locationid;
            data['organization_id'] = JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id;
            data['transactionby'] = JSON.parse(sessionStorage.getItem('authentication')).id;
            data['transactionorg'] = JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id;

            await API_post_uploadform(data);

            set_ready(false);
            set_processing(false);
            populate_function();
            set_parentmdl_open(false);
            set_mdl_open(false);

        } catch (e) {
            console.log(e);
        }

    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_uploadform(data){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('indv-forms', '/post-uploadform', { body: data});
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_submit(){
        if(var_ready) {
            if (var_fileuploadstatus === 'PENDING') {
                set_fileuploadstatus('START');
                set_processing(true);
            } else {
                set_processing(true);
                post_activeform();
            }
        }
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{t("Upload A Completed Form")}</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
            </div>

            <div className="modal-content">
                <div className="formdetails">
                   { var_form.locationid !== '' &&
                        <div className="detail-item">
                            <div className="detail-item-label">{t('LOCATION/PROJECT')}</div>
                            <div className="detail-item-text">{var_form.locationname}</div>
                        </div>
                    }
                    <div className="detail-item">
                        <div className="detail-item-label">{t('FORM')}</div>
                        <div className="detail-item-text">{var_form.formname}</div>
                    </div>
                </div>

                <CMP_FILEUPLOAD
                    var_fileuploaddirectory={var_fileuploaddirectory}
                    var_fileuploadlabel={var_fileuploadlabel}
                    var_fileuploadstatus={var_fileuploadstatus}
                    set_fileuploadstatus={set_fileuploadstatus}
                    var_fileuploadname={var_fileuploadname}
                    set_fileuploadname={set_fileuploadname}
                    var_fileuploaddisplayname={var_fileuploaddisplayname}
                    set_fileuploaddisplayname={set_fileuploaddisplayname}
                    var_placeholder={var_form.submittedfilename || var_form.filename || t('No file selected...')}
                    var_filetype={var_filetype}
                    var_fileextension={var_fileextension}
                    var_filesize={var_filesize}
                    var_ready={var_ready}
                    set_ready={set_ready}
                    var_fileupload={var_fileupload}
                    set_fileupload={set_fileupload}>
                </CMP_FILEUPLOAD>
                <p>{t('Only PDF, JPG, PNG file types accepted')}</p>
                <p>{t('Uploaded file will be unavailable for viewing until the virus scan is complete')}</p>

            </div>

            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className={"btn_secondary" + (var_ready ? ' btn_active' : '')} onClick={() => set_mdl_open(false)}>{t("CANCEL")}</Button>
                    {!var_processing ?
                        <Button className={"btn_primary " + (var_ready ? ' btn_active' : '')} onClick={() => onClick_submit()}>{t("SUBMIT COMPLETED FORM")}</Button>
                        :
                        <Button loading className="btn_primary btn_active">{t("SUBMIT COMPLETED FORM")}</Button>
                    }
                </div>
            </div>
        </>
    )
}
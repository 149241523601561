import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Table, Button, Modal } from 'semantic-ui-react';
import datelib from '../../../../../../libs/date-lib';
import { useTranslation } from 'react-i18next';
import '../../../../../../i18n';
import auth from '../../../../../../libs/auth-lib';

import CMP_TABLECONTROLS, { filter_helper } from '../../../../../../components/cmp_tablecontrols/cmp_tablecontrols';
import MDL_ADD_INDIVIDUAL from './mdl_assign/mdl_assign';
import MDL_CONFIRMATION from '../../../../../../components/cmp_confirmation/cmp_confirmation';

import './tab_assignedto.css';



export default function({ var_activetab, form, var_location_id }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();
    const [ var_individuals, set_individuals ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(10);
    const [ var_offset, set_offset] = useState(0);
    const [ var_sortby, set_sortby ] = useState('firstname');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));
    const [ var_mdl_add_individual_open, set_mdl_add_individual_open ] = useState(false);

    const [ var_mdl_confirmation_open, set_mdl_confirmation_open ] = useState(false);
    const [ var_individualform, set_individualform ] = useState(null);
    const [ var_individualname, set_individualname ] = useState(null);
    const [ var_display_last_individual_error, set_display_last_individual_error ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_activetab === 'ASSIGNEDTO') {
            set_filter(filter_helper.initialize(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_activetab]);

    useEffect(()=>{
        if (var_filter.load){
            populate_individuals(null, 0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_filter])

    useEffect(()=>{
        populate_individuals(null, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_add_individual_open])

    //  async functions ------------------------------------------------------------------------------------------------
    async function populate_individuals(limit,offset,sortby,sortorder,filter){
        if (var_activetab !== 'ASSIGNEDTO') return; // Don't load data if tab is not visible

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_form_individuals(limit,offset,sortby,sortorder,filter);
            set_individuals(results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername,sortorder){
        try {
        return await API_get_form_individuals(filter_helper.maxFilterItems,0,filtername,sortorder,{},filtername);
        } catch (e) {
        console.log(e);
        }
    }

    async function download_form_individuals(limit, offset){
        try {
        return await API_get_form_individuals(limit,offset,var_sortby,var_sortorder,var_filter);
        } catch (e){
        console.log(e);
        throw e;
        }
    }

    async function remove_individual(){
        set_ready(false);
        set_loading(true);
        try {
            await API_delete_form_individual();
            populate_individuals();
        } catch (e) {
            console.log(e);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_form_individuals(limit,offset,sortby,sortorder,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-forms',
                       '/get-form-individuals/' + form.id,
                       {
                        queryStringParameters: {
                            limit: limit,
                            offset: offset,
                            sortby: sortby,
                            sortorder: sortorder,
                            tz: datelib.timezone,
                            filtername: filtername,
                            location_id: var_location_id
                        },
                        body: filter
                    }
                );
    }

    function API_delete_form_individual(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.del('org-forms', '/delete-individual-form/' + var_individualform.individualform_id, { body: var_individualform });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_individuals('',0,sortby,sortorder)
    }

    function onClick_removeindividual(item){
        // don't delete the last one!!
        if (var_individuals[0]['totalrows'] > 1) {
            const individualform = {...var_individualform};
            individualform['individualform_id'] = item.id;
            individualform['individual_id'] = item.individual_id;
            individualform['location_id'] = var_location_id;
            individualform['organization_id'] = auth.actingorganization_id;
            individualform['transactionby'] = auth.id;
            individualform['transactionorg'] = auth.organization_id;
            set_individualform(individualform);

            set_individualname(item.firstname+ ' ' + item.lastname);
            set_mdl_confirmation_open(true);
        } else {
            set_display_last_individual_error(true);
            setTimeout(() => set_display_last_individual_error(false), 2500);
        }
    }


    // RENDER APP ======================================================================================================

    return (
        <div className="modal-tab">
            <div className="content-filter">
                <div className="content-filter-item item-pagination">
                    <CMP_TABLECONTROLS
                        var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                        total_rows={var_individuals && var_individuals.length > 0 ? var_individuals[0]['totalrows'] : 0} populatefunction={populate_individuals}
                        downloadname='ASSIGNED INDIVIDUALS TO FORM' downloadfunction={download_form_individuals}
                        var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                        table_config={[
                            {name: 'firstname', datatype: 'text', labelKey: 'FIRSTNAME', download: true, filter: true},
                            {name: 'lastname', datatype: 'text', labelKey: 'LASTNAME', download: true, filter: true},
                            {name: 'email', datatype: 'text', labelKey: 'EMAIL', download: true, filter: true}
                        ]}
                    />
                </div>
            </div>
            <div className="tablewrapper">
                <Table sortable compact className={(var_loading ? " tbl_loading" : "")}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell sorted={var_sortby === 'firstname' ? var_sortorder : null} onClick={()=>onClick_sort('firstname')}>{t('FIRSTNAME')}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'lastname' ? var_sortorder : null} onClick={()=>onClick_sort('lastname')}>{t('LASTNAME')}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'email' ? var_sortorder : null} onClick={()=>onClick_sort('email')}>{t('EMAIL')}</Table.HeaderCell>
                            <Table.HeaderCell className="td_icon_center"></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    {var_ready && var_individuals[0]['totalrows'] !== 0 &&
                    <Table.Body>
                        {var_individuals.map((item, i) =>
                            <Table.Row key={i} >
                                <Table.Cell >{item.firstname}</Table.Cell>
                                <Table.Cell >{item.lastname}</Table.Cell>
                                <Table.Cell>{item.email}</Table.Cell>
                                <Table.Cell className="td_icon_center" onClick={() => onClick_removeindividual(item)}><img src={"/icons/delete 18px (6B7280).svg?ts=" + Date.now()} alt={t("delete icon")}/></Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                    }
                </Table>
                {var_ready && var_individuals[0]['totalrows'] === 0 &&
                <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t('alert')}/>{t('there are no individuals to display')}</div>
                }
            </div>
            <div className="content-filter">
                <div className="content-filter-item item-pagination">
                    <CMP_TABLECONTROLS
                        var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                        total_rows={var_individuals && var_individuals.length > 0 ? var_individuals[0]['totalrows'] : 0} populatefunction={populate_individuals}
                    />
                </div>
            </div>
            {var_display_last_individual_error &&
                <div className="message warning">
                    <div className="message_icon"><img src={"/icons/warning 60px (d91e18).svg?ts=" + Date.now()} alt={t('warning icon')} /></div>
                    <div className="message_text_wrapper">
                        <div className="message_text cause">{t('There must be atleast 1 individual assigned')}</div>
                    </div>
            </div>}
            {auth.has_access('ORG-FORMS', 'insert') &&
            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_primary btn_active" onClick={() => set_mdl_add_individual_open(true)}>{t('ADD INDIVIDUAL')}</Button>
                </div>
            </div>
            }

            {/***** MODAL: MANAGE ************************************************************************************/}

            <Modal id="org-locations-form_mdl_assign"
                   dimmer={'inverted'}
                   open={var_mdl_add_individual_open}
                   onClose={() => set_mdl_add_individual_open(false)}>
                <MDL_ADD_INDIVIDUAL
                    set_mdl_open={set_mdl_add_individual_open}
                    var_location_id={var_location_id}
                    activeform_id={form.id}>
                </MDL_ADD_INDIVIDUAL>
            </Modal>

            {/***** END MODAL: MANAGE ********************************************************************************/}

            {/***** MODAL: CONFIRMATION *******************************************************************************/}

            <Modal id="mdl_confirmation"
                   dimmer={'inverted'}
                   open={var_mdl_confirmation_open}
                   onClose={() => set_mdl_confirmation_open(false)}>
                <MDL_CONFIRMATION
                    set_mdl_open={set_mdl_confirmation_open}
                    var_modaltitle={t("Remove Form")}
                    var_message={t("Are you sure you want to remove") + ' ' + var_individualname + ' ' + t("from") + ' ' + form.formname + t("?")}
                    confirmation_function={remove_individual}>
                </MDL_CONFIRMATION>
            </Modal>

            {/***** END MODAL: CONFIRMATION **************************************************************************/}

        </div>
    )

};

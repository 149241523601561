import { Modal, Table } from 'semantic-ui-react';
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import Moment from "moment";
import datelib from '../../../../libs/date-lib';

import { useTranslation } from 'react-i18next';
import '../../../../i18n';

import MDL_INDV from "../../../org/individuals/mdl_indv/mdl_indv";
import CMP_TABLECONTROLS, { filter_helper } from '../../../../components/cmp_tablecontrols/cmp_tablecontrols';

import './crd_onsite.css';



export default function(appProps){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();
    const [ var_onsites, set_onsites ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(100);
    const [ var_offset, set_offset] = useState(0);
    const [ var_search, set_search ] = useState('');
    const [ var_sortby, set_sortby ] = useState('onsite');
    const [ var_sortorder, set_sortorder ] = useState('descending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));


    const [var_onsitedate, set_onsitedate ] = useState(new Date());
    const [ var_mdl_indv_open, set_mdl_indv_open ] = useState(false);
    const [ var_target_id, set_target_id ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        set_filter(filter_helper.initialize(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_onsitedate]);

    useEffect(()=>{
        if (var_filter.load){
            populate_onsites(null,0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_filter])

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_onsites(limit,offset,sortby,sortorder,search,filter){

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        if(search) {
            if(search === '-') {
                search = '';
            }
            set_search(search);
        }else{
            search=var_search
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_onsites(limit,offset,sortby,sortorder,search,filter);
            set_onsites(results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername,sortorder){
        try {
        return await API_get_onsites(filter_helper.maxFilterItems,0,filtername,sortorder,var_search,{},filtername);
        } catch (e) {
        console.log(e);
        }
    }

    async function download_onsites(limit, offset){
        try {
        return await API_get_onsites(limit,offset,var_sortby,var_sortorder,var_search,var_filter);
        } catch (e){
        console.log(e);
        throw e;
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_onsites(limit,offset,sortby,sortorder,search,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-onsites', '/get-org-onsites/'+JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id+'/'+Moment(var_onsitedate).valueOf(),
        {queryStringParameters: {
             limit: limit,
             offset: offset,
             sortby: sortby,
             sortorder: sortorder,
             search:search,
             tz: datelib.timezone,
             filtername: filtername
         },
         body: filter
     }
);
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_onsites('',0,sortby,sortorder)
    }

    function onChange_onsitedate(event, data){
        if(data.value !== var_onsitedate) {
            set_onsitedate(data.value);
        }
    }

    function onClick_individual(var_target_id){
        set_target_id(var_target_id);
        set_mdl_indv_open(true);
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="content-card" id="crd_org_onsites">
                <div className="content-card-header">
                    <div className="content-card-header-title">{t('Onsite')}</div>
                </div>
                <div className="content-card-gridcontent">
                    <div className="content-filter">
                        <SemanticDatepicker id='onsitedate'
                                            locale={JSON.parse(sessionStorage.getItem('authentication')).language.datepicker}
                                            onChange={onChange_onsitedate}
                                            format={'MMM DD, YYYY'}
                                            clearable={false}
                                            datePickerOnly={true}
                                            showToday={false}
                                            value={var_onsitedate}/>
                    <div className="content-filter-item item-pagination">
                        <CMP_TABLECONTROLS
                            var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                            total_rows={var_onsites && var_onsites.length > 0 ? var_onsites[0]['totalrows'] : 0} populatefunction={populate_onsites}
                            downloadname='Onsite' downloadfunction={download_onsites}
                            var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                            table_config={[
                                {name: 'onsite', datatype: 'text', labelKey: 'TODAY', download: true, filter: true},
                                {name: 'individual', datatype: 'text', labelKey: 'NAME', download: true, filter: true},
                                {name: 'organization', datatype: 'text', labelKey: 'ORGANIZATION', download: true, filter: true},
                                {name: 'email', datatype: 'text', labelKey: 'EMAIL', download: true, filter: true},
                                {name: 'onsitelastdone', datatype: 'text', labelKey: 'LAST ENTRY', download: true, filter: false},
                                {name: 'epochlastdone', datatype: 'date', labelKey: 'LAST ENTRY', download: false, filter: true}

                            ]}
                        />                        
                    </div>
                    </div>
                    <div className="tablewrapper">
                        <Table sortable compact className={(var_loading ? " tbl_loading" : "")}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell className="td_icon_center" sorted={var_sortby === 'onsite' ? var_sortorder : null} onClick={()=>onClick_sort('onsite')}>{t('TODAY')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'individual' ? var_sortorder : null} onClick={()=>onClick_sort('individual')}>{t('NAME')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'organization' ? var_sortorder : null} onClick={()=>onClick_sort('organization')}>{t('ORGANIZATION')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_email" sorted={var_sortby === 'email' ? var_sortorder : null} onClick={() => onClick_sort('email')}>{t('EMAIL')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'epochlastdone' ? var_sortorder : null} onClick={() => onClick_sort('epochlastdone')}>{t('LAST ENTRY')}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {var_ready && var_onsites[0]['totalrows'] !== 0 &&
                            <Table.Body>
                                {var_onsites.map((item, i) =>
                                    <Table.Row key={i} onClick={()=>onClick_individual(item.individual_id)}>
                                        <Table.Cell className="td_icon_center">
                                            {item.onsite === 'YES' &&
                                            <div className='td_icon_center'> <img src={"/icons/checkmark 30px (69b536).svg?ts=" + Date.now()} alt={t('org-onsite.onsite.crd_onsite.alt.active')}/></div>
                                            }
                                            {item.onsite === 'NO' &&
                                            <div className='td_icon_center'><img src={"/icons/x 30px (e0454f).svg?ts=" + Date.now()} alt={t('org-onsite.onsite.crd_onsite.alt.inactive')}/></div>
                                            }
                                            {item.onsite === 'NONE' &&
                                            <div className='tbl_circle'><img src={"/icons/circle 30px (bcbebe).svg?ts=" + Date.now()} alt={t('org-onsite.onsite.crd_onsite.alt.no_result')}/></div>
                                            }
                                        </Table.Cell>
                                        <Table.Cell >{item.individual}</Table.Cell>
                                        <Table.Cell >{item.organization}</Table.Cell>
                                        <Table.Cell className="td_email">{item.email}</Table.Cell>
                                        <Table.Cell >{datelib.datetime_localized_format(item.onsitelastdone)}</Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                            }
                        </Table>
                        {var_ready && var_onsites[0]['totalrows'] === 0 &&
                        <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt="alert icon"/>{t('there are no onsite reports to display')}</div>
                        }
                    </div>
                    <div className="content-filter">
                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_onsites && var_onsites.length > 0 ? var_onsites[0]['totalrows'] : 0} populatefunction={populate_onsites}
                            />                        
                        </div>
                    </div>
                </div>



                {/***** MODAL: INDIVIDUAL ****************************************************************************/}

                <Modal id="mdl_org_individuals_indv"
                       dimmer={'inverted'}
                       open={var_mdl_indv_open}
                       onClose={() => set_mdl_indv_open(false)}>
                    <MDL_INDV
                        set_mdl_open={set_mdl_indv_open}
                        var_individual_id={var_target_id}
                        populate_function={populate_onsites}>
                    </MDL_INDV>
                </Modal>

                {/***** END MODAL: INDIVIDUAL ************************************************************************/}



            </div>
        </>
    )

}
import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import {Button, Checkbox, Form, Input, Modal, Card, CardContent, CardMeta} from 'semantic-ui-react';
import auth from '../../../../../../libs/auth-lib';

import CMP_FILEUPLOAD from "../../../../../../components/cmp_fileupload/cmp_fileupload";
import MDL_CONFIRMATION from "../../../../../../components/cmp_confirmation/cmp_confirmation";

import './tab_details.css';

import { useTranslation } from 'react-i18next';
import '../../../../../../i18n';


export default function({ set_mdl_open, var_activetab, var_credential_id, populate_function, set_indv_or_org }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();
    const [ var_credential, set_credential ] = useState([]);
    const [ var_dd_categories, set_dd_categories ] = useState([]);
    const [ var_status, set_status ] = useState(false);
    const [ var_issuedrequired, set_issuedrequired ] = useState(true);
    const [ var_expires, set_expires ] = useState(false);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_validationerror, set_validationerror] = useState(false);
    const [ var_validationerrormessage, set_validationerrormessage] = useState('');
    const [ var_descriptioncount, set_descriptioncount] = useState(0);
    const [ var_skillscount, set_skillscount] = useState(0);

    const [ var_fileuploadstatus, set_fileuploadstatus ] = useState('');
    const [ var_fileuploadname, set_fileuploadname ] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [var_fileuploadlabel, set_fileuploadlabel] = useState(t('CREDENTIAL IMAGE'));
    // eslint-disable-next-line no-unused-vars
    const [ var_fileuploaddirectory, set_fileuploaddirectory ] = useState('courses');
    const [ var_fileuploaddisplayname, set_fileuploaddisplayname ] = useState(null);

    const [ var_mdl_confirmation_open, set_mdl_confirmation_open ] = useState(false);

    const [ var_filetype, set_filetype ] = useState('image/png, image/jpg, image/jpeg');
    const [ var_fileextension, set_fileextension ] = useState(['png','jpg','jpeg']);
    const [ var_filesize, set_filesize ] = useState('10');
    const [ var_fileupload, set_fileupload ] = useState(null);
    const [ var_courselogo_url, set_courselogo_url] = useState(null);
    const [ var_img_load_error, set_img_load_error ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_activetab === 'DETAILS') {
            populate_credential();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_activetab]);

    useEffect(() => {
        if(var_credential.id !== undefined) {
            const credential = {...var_credential};
            if (var_status) {
                credential['status'] = 'ACTIVE';
            } else {
                credential['status'] = 'PENDING';
            }
            set_credential(credential);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_status]);

    useEffect(() => {
        if(var_credential.id !== undefined) {
            const credential = {...var_credential};
            if (var_issuedrequired) {
                credential['issuedrequired'] = 'YES';
            } else {
                credential['issuedrequired'] = 'NO';
            }
            set_credential(credential);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_issuedrequired]);

    useEffect(() => {
        if(var_credential.id !== undefined) {
            const credential = {...var_credential};
            if (var_expires) {
                credential['expires'] = 'YES';
                credential['expires_amount'] = '1';
                credential['expires_unit'] = 'day';
            } else {
                credential['expires'] = 'NO';
                credential['expires_amount'] = null;
                credential['expires_unit'] = null;
            }
            set_credential(credential);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_expires]);

    useEffect(() => {
        if(var_fileuploadstatus === 'PENDING'){
            // set_ready(true);
            const credential = {...var_credential};
            credential['imagefile'] = var_fileuploadname;
            credential['submittedfilename'] = var_fileuploaddisplayname;
            set_credential(credential);
        }
        if(var_fileuploadstatus === 'COMPLETE'){
            update_credential();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_fileuploadstatus]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_credential(){
        try {
            let result = await API_get_org_credential();
            if(result[0]['expires'] === 'YES'){
                set_expires(true);
            }else{
                set_expires(false);
            }
            if(result[0]['status'] === 'ACTIVE'){
                set_status(true);
            }else{
                set_status(false);
            }
            if(result[0]['issuedrequired'] === 'NO'){
                set_issuedrequired(false);
            }else{
                set_issuedrequired(true);
            }
            result[0]['organization_id'] = JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id;
            result[0]['transactionby'] = JSON.parse(sessionStorage.getItem('authentication')).id;
            result[0]['transactionorg'] = JSON.parse(sessionStorage.getItem('authentication')).organization_id;
            set_credential(result[0]);
            set_courselogo_url((await API.endpoint('storage'))+'/course/');
            set_indv_or_org(result[0].indv_or_org);
            set_descriptioncount(result[0].description?.length || 0);
            set_skillscount(result[0].skills?.length || 0);

            await populate_credential_categories(result[0].organization_id);
        } catch (e) {
            console.log(e);
        }
    }

    async function update_credential(credential) {
        set_processing(true);
        try {
            await API_put_org_credential(credential || var_credential);
            set_ready(false);
            set_processing(false);
            populate_credential();
            populate_function();
        } catch (e) {
            console.log(e);
        }
    }

    async function archive_credential(){
        try {
            if(var_credential.status !== 'ARCHIVE'){
                await API_put_org_archive('ARCHIVE');
            }else{
                await API_put_org_archive('ACTIVE');
            }
            populate_credential();
            populate_function();
        } catch (e) {
            console.log(e);
        }
    }

    async function populate_credential_categories(organization_id) {
        try {
            let result = await API_get_credential_categories(organization_id);
            if (result && result.length > 0) {
                set_dd_categories(result.map(item => ({ value: item.id, text: item.id })));
            }
        } catch (e) {
            console.log(e);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_credential(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-credentials', '/get-org-credential/'+var_credential_id);
    }

    function API_put_org_credential(credential) {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.put('org-credentials', '/put-org-credential/'+var_credential_id, { body: credential });
    }

    function API_put_org_archive(var_archive){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.put('org-credentials', '/put-org-archive/'+var_credential_id+'/'+var_archive, { body: var_credential });
    }

    function API_get_credential_categories(organization_id) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.get('org-credentials', '/get-credential-categories/'+organization_id);
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(event){
        const credential = { ...var_credential };
        if (event.target.value === '') {
            credential[event.target.name] = null;
        } 
        else{
            credential[event.target.name] = event.target.value.substring(0,500);
        } 
        set_credential(credential);
        set_validationerror(false);
        set_ready(true);
    }

    function onChange_category(event, data) {
        const credential = { ...var_credential };
        credential.credential_category_id = data.value;
        set_credential(credential);
        set_validationerror(false);
        set_ready(true);     
    }   

    function onChange_status(){
        set_status(var_status => !var_status);
        set_ready(true);
    }

    function onChange_issuedrequired(){
        set_issuedrequired(var_issuedrequired => !var_issuedrequired);
        set_ready(true);
    }

    function onChange_expires(){
        set_expires(var_expires => !var_expires);
        set_ready(true);
    }

    function onChange_expires_unit(event, { value }){
        const credential = { ...var_credential };
        credential['expires_unit'] = value;
        set_credential(credential);
        set_ready(true);
    }

    function onChange_toggle(property) {
        const credential = { ...var_credential };
        credential[property] = (credential[property] === 'YES' ? 'NO' : 'YES');
        set_credential(credential);
        set_ready(true);
    }

    function onClick_submit(){
        if(var_ready) {
            let var_validation = false;
            let var_validmessage = '';
            if (!var_credential.name || var_credential.name.trim() === '') {
                var_validation = true;
                var_validmessage = 'Please provide a credential name';
            }
            if (var_credential.expires === 'YES') {
                if (!var_credential.expires_amount || var_credential.expires_amount.trim().length === 0) {
                    var_validation = true;
                    var_validmessage = 'Please provide the expires amount';
                } else {
                    let num = Number(var_credential.expires_amount.trim());
                    if (Number.isNaN(num) || Math.floor(num) !== num || num < 1 || num > 5000) {
                        var_validation = true;
                        var_validmessage = 'Please provide an expires amount between 1 and 5000';
                    }
                }
            }
            if (!var_credential.credential_category_id) {
                var_validation = true;
                var_validmessage = 'Please provide a category for this credential';
            }
            set_validationerrormessage(var_validmessage);
            set_validationerror(var_validation);

            if (var_validation === false) {
                // update/clear info for credential number
                let credential = var_credential;
                if (credential.has_credential_number === 'YES') {
                    credential.credential_number_required = credential.credential_number_required || 'NO';
                    if (!credential.credential_number_label || credential.credential_number_label.trim().length === 0) {
                        credential.credential_number_label = null;
                    }
                } else {
                    credential.has_credential_number = 'NO';
                    credential.credential_number_required = null;
                    credential.credential_number_label = null;
                }
                set_credential(credential);

                // start save process now
                if(var_fileuploadstatus === 'PENDING') {
                    set_fileuploadstatus('START');
                    set_processing(true);
                }else{
                    set_processing(true);
                    update_credential(credential);
                }
            }
        }
    }



    // RENDER APP ======================================================================================================

    return (
        <div className="modal-tab">

            {/*{var_credential.status === 'ARCHIVE' &&*/}
            {/*<div className="modal-archive" onClick={() => onClick_unarchive()}>*/}
                {/*<div className="modal-archive-icon"><img className="btn_icon" src={"/icons/archive 30px (ffffff).svg?ts=" + Date.now()} alt="archive icon"/></div>*/}
                {/*<div className="modal-archive-text">This credential has been archived.  Click this bar to make it active again.*/}
                {/*</div>*/}
            {/*</div>*/}
            {/*}*/}

            <div id="tab_details">
            <div className="modal-content-title"><b>{t('Details')}</b></div>
                <Form>
                    
                    <Form.Group>
                        <Form.Field
                            className='name'
                            name='name'
                            value={var_credential.name || ''}
                            control={Input}
                            onChange={onChange_input}
                            label={t('CREDENTIAL NAME')}
                            placeholder={t('Credential name')}
                            maxLength="100"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Select
                            className='category'
                            name='category'
                            label={t('CATEGORY')}
                            options={var_dd_categories}
                            value={var_credential.credential_category_id}
                            onChange={onChange_category}
                            placeholder={t('Select a category...')}
                            fluid                            
                            selection
                            search
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                            className='code'
                            name='code'
                            value={var_credential.code || ''}
                            control={Input}
                            onChange={onChange_input}
                            label={t('CODE')}
                            placeholder={t('Code')}
                            maxLength="50"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.TextArea
                            className='description'
                            name='description'
                            value={var_credential.description || ''}
                            onChange={e => {set_descriptioncount(e.target.value.length); onChange_input(e)}}
                            label={t('DESCRIPTION')}
                            placeholder={t('Description')}
                            maxLength={500}
                        /> 
                    </Form.Group>
                    <div className='the-count'><p>{var_descriptioncount}/500</p></div>
                    <Form.Group>
                        <Form.TextArea
                            className='skills'
                            name='skills'
                            value={var_credential.skills || ''}
                            onChange={e => {set_skillscount(e.target.value.length); onChange_input(e)}}
                            label={t('SKILLS')}
                            placeholder={t('Skills')}
                            maxLength={500}
                        />
                    </Form.Group>
                    <div className='the-count'><p>{var_skillscount}/500</p></div>
                </Form>
                {var_fileupload ?

                <div className="certificate_logo">
                    <img src={URL.createObjectURL(var_fileupload)} alt={t('uploaded logo')}/>
                </div>

                : (var_credential.imagefile && var_credential.imagefile !== '') &&
                <div className="certificate_logo">
                    {(var_credential.imagefile.match(/.(jpg|jpeg|png|bmp|gif)$/i) || var_credential.imagefile.startsWith('data')) &&
                    (var_img_load_error
                    ?
                    <div className="img-load-error">
                    {t('Image preview not available.')}
                    </div>
                    :
                    <img
                        onError={(e)=>{e.target.onerror = null;set_img_load_error(true)}}
                        src={var_credential.imagefile.startsWith('https://') ||
                        var_credential.imagefile.startsWith('http://') ||
                        var_credential.imagefile.startsWith('data') ||
                        !var_credential.imagefile.match(/.(jpg|jpeg|png|bmp|gif)$/i) ? var_credential.imagefile :
                        var_courselogo_url + var_credential.id + '/' + var_credential.imagefile}
                        alt={t('certificate logo')}>
                    </img>
                    )
                }   
                </div>
                }
                <CMP_FILEUPLOAD
                    var_fileuploaddirectory={var_fileuploaddirectory}
                    var_fileuploadlabel={var_fileuploadlabel}
                    var_fileuploadstatus={var_fileuploadstatus}
                    set_fileuploadstatus={set_fileuploadstatus}
                    var_fileuploadname={var_fileuploadname}
                    set_fileuploadname={set_fileuploadname}
                    var_fileuploaddisplayname={var_fileuploaddisplayname}
                    set_fileuploaddisplayname={set_fileuploaddisplayname}
                    var_placeholder={var_credential.submittedfilename ? var_credential.submittedfilename : 'No file selected'}
                    var_filetype={var_filetype}
                    var_fileextension={var_fileextension}
                    var_filesize={var_filesize}
                    var_ready={var_ready}
                    set_ready={set_ready}
                    var_fileupload={var_fileupload}
                    set_fileupload={set_fileupload}>
                </CMP_FILEUPLOAD>
                <div className="file_details">
                    <div>
                        <p>{t('Only JPG, JPEG, PNG file types accepted')}</p>
                        <p>{t('Uploaded file will be unavailable for viewing until the virus scan is complete')}</p>
                    </div>
                </div>

                <div className = 'settings_title'><b>{t('Settings')}</b></div>

                {auth.org_wallet &&
                <div className='setting_sub_categories'><b>{t('TYPE')}</b></div>
                }
                
                    {auth.org_wallet &&
                        <Form.Group>
                            
                                 <Checkbox
                                    radio
                                    name='IndivCheckbox'
                                    className={var_credential.indv_or_org === 'INDV'? '' : 'toggle_active'}
                                    label={t('Individual')}
                                    checked={var_credential.indv_or_org === 'INDV'}
                                    disabled
                            />
                            
                                <Checkbox
                                    radio
                                    label={t('Organization')}
                                    name='OrgCheckbox'
                                    className={var_credential.indv_or_org === 'ORG'? '' : 'toggle_active'}
                                    checked={var_credential.indv_or_org === 'ORG'}
                                    disabled
                                
                            />
                            
                        </Form.Group>
                    }
                    <div className='setting_sub_categories'><b>{t('STATUS')}</b></div>
                    
                    <Form.Group>
                    <div className='form_spacing'>
                        <Checkbox
                            radio
                            className={"status " + (var_status ? ' toggle_active' : '')}
                            label={t('Active')}
                            checked={var_status === true}
                            onChange={onChange_status}
                        />
                    
                        <Checkbox
                            radio
                            className={"status " + (var_status ? ' toggle_pending' : '')}
                            label={t('Pending')}
                            checked={var_status === false}
                            onChange={onChange_status}

                        />
                    </div>
                    </Form.Group>
                    
                    <div className= 'other_title'><b>{t('OTHER')}</b></div>

                    {auth.org_verifiable_credentials &&
                    <div className='toggle_container'>
                        <Form.Group className='primary_toggle_group'>
                            <Checkbox
                                className='color'
                                name='color'
                                label={t('Available as a Verifiable Credential')}
                                onChange={() => onChange_toggle('verifiable_credential')}
                                checked={var_credential.verifiable_credential === 'YES' || false}
                            />
                        </Form.Group>
                    </div>
                    }

                    <Form.Group>
                        <Checkbox
                            className={"issuedrequired " + (var_issuedrequired ? ' toggle_active' : '')}
                            label={t('Issued date is required')}
                            onChange={onChange_issuedrequired}
                            checked={var_issuedrequired || false}
                        />
                    </Form.Group>
                    
                    <div className='toggle_container'>
                        <Form.Group className='primary_toggle_group'>
                            <Checkbox
                                className={var_expires ? 'toggle_active' : ''}
                                label={t('Credential expires')}
                                onChange={onChange_expires}
                                checked={var_expires || false}

                            />
                        </Form.Group>
                    </div>
                        {var_expires &&
                        <div>
                        <Card className='card'>
                            <Card.Content>
                                <Card.Meta>
                                    <div className='card_spacing'>{t('Specify the timeframe for expiry.')}</div>
                                </Card.Meta>
                                 
                                <Form.Group>
                                    <Form.Field
                                        className='expires_amount'
                                        name='expires_amount'
                                        value={var_credential.expires_amount || ''}
                                        control={Input}
                                        onChange={onChange_input}
                                        label={t('AMOUNT')}
                                        placeholder={t('Number')}
                                    />
                                    <Form.Select
                                        className='expires_unit'
                                        compact
                                        name='expires_unit'
                                        label={t('UNIT')}
                                        placeholder={t('day(s)')}
                                        onChange={onChange_expires_unit}
                                        value={var_credential.expires_unit || 'day'}
                                        options={[{"text": t('day(s)'), "value": "day"},
                                            {"text": t('month(s)'), "value": "month"},
                                            {"text": t('year(s)'), "value": "year"}]}
                                    />
                                </Form.Group>
                            </Card.Content>
                        </Card>
                        </div>
                        }
                    <div className='toggle_container'>
                        <Form.Group className='secondary_toggle_group'>
                            <Checkbox
                                className={var_credential.has_credential_number === 'YES' ? 'toggle_active' : ''}
                                label={t('Has credential number, course ID, student ID, etc.')}
                                onChange={() => onChange_toggle('has_credential_number')}
                                checked={var_credential.has_credential_number === 'YES'}
                            />
                        </Form.Group>
                    </div>
                        {var_credential.has_credential_number === 'YES' &&
                        <div>
                            <Card>
                                <CardContent>
                                    <CardMeta>
                                        <div className='card_spacing'>
                                            {t('Specify the label to be displayed for this field.')}</div>
                                    </CardMeta>
                                    <div className='card_spacing'>
                                    <Form.Group>
                                        <Form.Field
                                            name='credential_number_label'
                                            value={var_credential.credential_number_label || ''}
                                            control={Input}
                                            onChange={onChange_input}
                                            label={t('Field Label')}
                                            placeholder={t('Name')}
                                            />
                                    </Form.Group>
                                    </div>
                                    <Form.Group>
                                        <Checkbox
                                                className={var_credential.credential_number_required === 'YES' ? 'toggle_active' : ''}
                                                label={t('This is a required field')}
                                                onChange={() => onChange_toggle('credential_number_required')}
                                                checked={var_credential.credential_number_required === 'YES'}
                                            />
                                    </Form.Group>
                                </CardContent>
                            </Card>
                        </div>
                        
                        }

                {var_validationerror &&
                    <div className="message warning">
                        <div className="message_icon"><img src={"/icons/warning 60px (d91e18).svg?ts=" + Date.now()} alt={t('warning icon')} /></div>
                        <div className="message_text_wrapper">
                            <div className="message_text cause">{t(var_validationerrormessage)}</div>
                        </div>
                    </div>
                }
            </div>
            <div className="modal-footer">

                {/*{var_credential.status !== 'ARCHIVE' &&*/}
                {/*<div className="modal-footer-archive">*/}
                    {/*<Button className="btn_tertiary" onClick={() => onClick_archive()}><img className="btn_icon" src={"/icons/archive 30px (4dcbd4).svg?ts=" + Date.now()} alt="archive icon"/>ARCHIVE CREDENTIAL</Button>*/}
                {/*</div>*/}
                {/*}*/}

                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>{t('CANCEL')}</Button>
                    {!var_processing ?
                        <Button className={"btn_primary " + (var_ready ? 'btn_active' : '')} onClick={() => onClick_submit()}>{t('UPDATE CREDENTIAL')}</Button>
                        :
                        <Button loading className="btn_primary btn_active">{t('UPDATE CREDENTIAL')}</Button>
                    }
                </div>
            </div>


            {/***** MODAL: CONFIRMATION *******************************************************************************/}

            <Modal id="mdl_confirmation"
                   dimmer={'inverted'}
                   open={var_mdl_confirmation_open}
                   onClose={() => set_mdl_confirmation_open(false)}>
                <MDL_CONFIRMATION
                    set_mdl_open={set_mdl_confirmation_open}
                    var_modaltitle={t('Archive Credential')}
                    var_message={t('Are you sure you want to archive this credential?')}
                    confirmation_function={archive_credential}>
                </MDL_CONFIRMATION>
            </Modal>

            {/***** END MODAL: CONFIRMATION **************************************************************************/}


        </div>
    )

};
import { API } from "aws-amplify";
import { Button, Message, Dimmer, Loader } from 'semantic-ui-react';
import React, { useLayoutEffect, useEffect, useState } from "react";
import { pdf } from '@react-pdf/renderer';
import {saveAs} from 'file-saver';
import datelib from '../../../libs/date-lib';

import CRD_CREDENTIALS from "./crd_credentials/crd_credentials";
import CRD_CREDENTIALSATTACHMENTS from "./crd_credentialsAttachments/crd_credentialsAttachments";
import DOWNLOAD from "./report";
import { useTranslation } from 'react-i18next';
import '../../../i18n';

import "./orgprofile.css";



export default function(appProps) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const QRCode = require('qrcode.react');
    const [ var_ready, set_ready ] = useState(false);
    const [ var_linkstatus, set_linkstatus ] = useState('');
    const { t } = useTranslation();

    const [ var_org, set_org ] = useState([]);
    const [ var_credentials, set_credentials] = useState([]);
    const [var_loading, set_loading] = useState(true);

    let url = window.location.href;
    let arr = url.split("/");
    const hostname = arr[0] + "//" + arr[2];
    const qrURL = hostname + '/public/orgprofile/' + appProps.match.params.linkid;


    //  event listeners ------------------------------------------------------------------------------------------------

    useLayoutEffect(()=>{
        set_loading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=>{
        appProps.set_visibleframe(false);
        populate_public_org();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_public_org(){
        try {
            const results = await API_get_public_org();
            set_linkstatus(results.status);
            results.credentials && set_credentials(results.credentials);
            const org = results.profile
            if (!org) {
                set_loading(false);
                return;
            }
            if (org.logo && !org.logo.toLowerCase().startsWith('https://') && !org.logo.toLowerCase().startsWith('http://')) {
                org.org_logo = (await API.endpoint('storage')) + '/logo/' + org.id + '/' + org.logo;
            }
            set_org(org);
            set_loading(false);
            set_ready(true);
        } catch (e) {
            console.log(e);
        }
    }

    async function Download_PDF(){
        set_loading(true);

        const canvas = document.getElementById("qr-gen");
        const pngUrl = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");

        let credential_url = (await API.endpoint('storage')) + '/orgcredential/';

        let filename = (var_org.name != null? var_org.name.replace(/[/|\\:*?"<>]/g, " ") : '') + '-' + new Date(new Date().getTime() - new Date().getTimezoneOffset()*60*1000).toISOString().substr(0,19).replace('T', ' ');

        const blob = await pdf(
                <DOWNLOAD var_org={var_org} var_credentials={var_credentials} var_qrCode={pngUrl} credential_url={credential_url} />
            ).toBlob();
        saveAs(blob, filename);
        set_loading(false);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_public_org(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-wallet', '/get-public-profile/' + appProps.match.params.linkid,
            {queryStringParameters: {
                tz : datelib.timezone,
            }});
    }

    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

 
    return(
        <div className="public_content" id="pg_publicprofile">
             {var_loading &&
                <>
                <Dimmer active inverted>
                    <Loader />
                </Dimmer>
                </>
            }

            {var_ready && !var_loading &&
            <div className="public_wrapper">
                {var_linkstatus === 'ACTIVE' &&
                    <>
                       <div className="profile_report_card">
                            <div className="profile_report_header">
                                <div className="profile_report_header_top">
                                    <div className="profile_report_detail">
                                        <div className="profile_report_org_info">
                                            {var_org && var_org.org_logo && var_org.org_logo != null && var_org.org_logo !== '' &&
                                            <div className="profile_report_logo">
                                                <img src={var_org.org_logo} alt="organization logo" />
                                            </div>
                                            }
                                        </div>
                                        <div className="profile_report_title">
                                            <div className="report_title">{var_org.name}</div>
                                            <Button className="btn_tertiary btn_download" onClick={() => Download_PDF()}>
                                                <img className="btn_icon" src={"/icons/download 30px (4dcbd4).svg?ts=" + Date.now()} alt="download icon" />
                                                {t('Export as PDF')}
                                            </Button>
                                        </div>
                                        <ul className="profile_report_info">
                                            {var_org && var_org.name && var_org.name !== '' &&
                                            <li>
                                                    <div className="profile_header_info_th">{t('ORGANIZATION')}</div>
                                                <div className="profile_header_info_td">{var_org===undefined? '' :(var_org.name===null? '' : var_org.name)}</div>
                                            </li>
                                            }
                                            {var_org && var_org.address1 && var_org.address1 !== '' &&
                                                <li>
                                                    <div className="profile_header_info_th">{t('ADDRESS')}</div>
                                                    <div className="profile_header_info_td">{var_org===undefined? '' : (var_org.address1===null? '' : var_org.address1)}</div>
                                                </li>
                                            }
                                            {var_org && var_org.city && var_org.city !== '' &&
                                                <li>
                                                    <div className="profile_header_info_th">{t('CITY')}</div>
                                                    <div className="profile_header_info_td">{var_org===undefined? '' : (var_org.city===null? '' : var_org.city)}</div>
                                                </li>
                                            }
                                            {var_org && var_org.postal && var_org.postal !== '' &&
                                                <li>
                                                    <div className="profile_header_info_th">{t('POSTAL')}</div>
                                                    <div className="profile_header_info_td">{var_org===undefined? '' : (var_org.postal===null? '' : var_org.postal)}</div>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                    <div className="report_qrcode">
                                        <QRCode
                                            className="qrcode"
                                            id="qr-gen"
                                            value={qrURL}
                                            size={120}
                                            level={"H"}
                                            includeMargin={false}
                                        />
                                    </div>
                                </div>
                                <CRD_CREDENTIALS
                                    var_credentials={var_credentials}
                                />
                                <CRD_CREDENTIALSATTACHMENTS
                                    var_credentials={var_credentials}
                                />
                            </div>
                        </div>
                    </>
                }
            </div>
            }

            {!var_loading && (var_linkstatus === 'INACTIVE' || var_linkstatus === 'REVOKED') &&
                <div className="public_wrapper">
                    <div>
                        <div className="content">
                            <div className="content-wrapper">
                                <Message
                                    color='red'>{t('A valid or active public profile could not be found. Please check the link again or the organization you received it from.')}</Message>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
};

import React, {useEffect, useState} from "react";
import { API } from "aws-amplify";
import { Button, Message, Dimmer, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import {saveAs} from 'file-saver';
import Moment from "moment";
import datelib from '../../../libs/date-lib';
import { pdf } from '@react-pdf/renderer';

import STA_DAILYCHECK from "./sta_dailycheck/sta_dailycheck";
import STA_CREDENTIALS from "./sta_credentials/sta_credentials";
import STA_ONSITE from "./sta_onsite/sta_onsite";

import CRD_INDIVIDUALS from "./crd_individuals/crd_individuals";
// import CRD_REQUIREMENTS from "./crd_requirements/crd_requirements";
import CRD_DOCUMENTS from "./crd_documents/crd_documents";
import CRD_PROFILE from "./crd_profile/profile";
import DOWNLOAD from "./report";

import "./location.css";


export default function(appProps) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const QRCode = require('qrcode.react');
    const [var_loading, set_loading] = useState(true);
    const [var_location, set_location] = useState([]);
    const [var_location_ready, set_location_ready] = useState(false);
    const [var_enabled, set_enabled] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [ var_date, set_date ] = useState(Moment().utcOffset(new Date().getTimezoneOffset()*-1).format('MMM DD, YYYY'));
    const { t } = useTranslation();

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        appProps.set_visibleframe(false);
        window.scrollTo(0, 0);
        populatelocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populatelocation(){
        try {
            set_loading(true);
            let location = await API_get_location();
            if (location.org[0].org_logo && !location.org[0].org_logo.toLowerCase().startsWith('https://') && !location.org[0].org_logo.toLowerCase().startsWith('http://')) {
                location.org[0].org_logo = (await API.endpoint('storage')) + '/logo/' + location.org[0].organization_id + '/' + location.org[0].org_logo;
            }
            set_location(location);
            set_loading(false);
            set_location_ready(true);
        } catch (e) {
            console.log(e);
        }
    }

    async function Downlaod_PDF(){
        set_loading(true);

        let pngUrl = null;

        if(var_location.org[0].qraccess && var_location.org[0].qraccess === "YES")
        {
            const canvas = document.getElementById("qr-gen");
            pngUrl = canvas
              .toDataURL("image/png")
              .replace("image/png", "image/octet-stream");
        }

        let credential_url = (await API.endpoint('storage')) + '/credential/';

        let filename = (var_location != null? var_location.detail.location_name.replace(/[/|\\:*?"<>]/g, " ") : '') + '-' + new Date(new Date().getTime() - new Date().getTimezoneOffset()*60*1000).toISOString().substr(0,19).replace('T', ' ');

        const blob = await pdf(
                <DOWNLOAD var_location={var_location} var_qrCodeURL={pngUrl} 
                          credential_url={credential_url} var_linkid={appProps.match.params.profileid}
                />
            ).toBlob();
        saveAs(blob, filename);
        set_loading(false);
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    async function API_get_location(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        let results;
        try {
            results = await API.get('org-locations', '/get-public-location/' + appProps.match.params.profileid + '?onsitedate=' + Moment(new Date()).valueOf() + '&tz=' + datelib.querystring_timezone);
            set_enabled(true);
        } catch (e) {
            if (e.response.status === 404) {
                console.log('404 error');

                // Populate with empty results
                results = {
                    org: [
                        {
                            org_logo: ''
                        }
                    ]
                };
                set_enabled(false);
            }
        }
        set_loading(false);
        return results;
    }

    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return(
        <div className="public_content" id="pg_publiclocation">

            {var_loading &&
                <>
                <Dimmer active inverted>
                    <Loader />
                </Dimmer>
                </>
            }
            {var_location_ready &&
            <>
            {var_enabled ?
                <div className="public_wrapper">
            {(() => {
                if(var_location && var_location.statusCode === undefined)
                {
                if(var_location.message === undefined)
                {
                    return(
                    <div className="location_report_card">
                        <div className="location_report_header">
                            <div className="location_report_header_top">
                                <div className="location_report_detail">
                                    <div className="location_report_org_info">
                                        {(var_location && var_location.org[0].org_logo && var_location.org[0].org_logo != null && var_location.org[0].org_logo !== '') ?
                                            <div className="location_report_logo">
                                                <img src={var_location.org[0].org_logo} alt="org logo"/>
                                            </div>
                                            :
                                            <div
                                                className="report_title">{var_location === undefined ? '' : (var_location.org[0].name === null ? '' : var_location.org[0].name)}</div>
                                        }
                                    </div>
                                    <div className="location_report_title">
                                        <div className="report_title">
                                            {var_location===undefined? '' :(var_location.detail.location_name===null? '' : var_location.detail.location_name)}
                                        </div>
                                        <Button className="btn_tertiary btn_download" onClick={() => Downlaod_PDF()}>
                                            <img className="btn_icon" src={"/icons/download 30px (4dcbd4).svg?ts=" + Date.now()} alt="download icon" />
                                            {t('Export as PDF')}
                                        </Button>
                                    </div>
                                    <ul className="location_report_info">
                                        <li>
                                                <div className="location_report_info_th">{t('CODE')}</div>
                                            <div className="location_report_info_td">{var_location===undefined? '' :(var_location.detail.code===null? '' : var_location.detail.code)}</div>
                                        </li>
                                        <li>
                                            <div className="location_report_info_th">{t('CITY')}</div>
                                            <div className="location_report_info_td">{var_location===undefined? '' : (var_location.detail.city===null? '' : var_location.detail.city)}</div>
                                        </li>
                                        <li>
                                            <div className="location_report_info_th">{t('PROVINCE')}</div>
                                            <div className="location_report_info_td">{var_location===undefined? '' : (var_location.detail.province===null? '' : var_location.detail.province)}</div>
                                        </li>
                                        <li>
                                            <div className="location_report_info_th">{t('COUNTRY')}</div>
                                            <div className="location_report_info_td">{var_location===undefined? '' : (var_location.detail.country===null? '' : var_location.detail.country)}</div>
                                        </li>
                                    </ul>
                                </div>
                                {var_location.org[0].qraccess && var_location.org[0].qraccess === "YES" &&
                                <div className="report_qrcode">
                                    <QRCode
                                        className="qrcode"
                                        id="qr-gen"
                                        value={var_location.org[0].org_host + '/public/location/'+appProps.match.params.profileid}
                                        size={120}
                                        level={"H"}
                                        includeMargin={false}
                                    />
                                </div>
                                }
                            </div>

                            <div className="detailsummary">
                                <div className="detailsummary_header">
                                    <div className="detailsummary_title">{t("Location/Project Summary")}</div>
                                    <div className="detailsummary_subtitle">{t('as of')} {datelib.date_localized_format(var_date)}</div>
                                </div>
                                <div className="detailsummary_wrapper">

                                        {var_location.summary && var_location.summary.dailycheck != null &&
                                        <div className="detail_wrapper">
                                            <STA_DAILYCHECK
                                                var_dailyCheckSummary = {var_location.summary.dailycheck[0]}>
                                            </STA_DAILYCHECK>
                                        </div>
                                        }
                                        {var_location.summary && var_location.summary.credential != null &&
                                        <div className="detail_wrapper">
                                            <STA_CREDENTIALS
                                                var_credentialSummary = {var_location.summary.credential[0]}>
                                            </STA_CREDENTIALS>
                                        </div>
                                        }
                                        {var_location.summary && var_location.summary.onsite != null &&
                                        <div className="detail_wrapper">
                                            <STA_ONSITE
                                                var_onsiteSummary = {var_location.summary.onsite[0]}>
                                            </STA_ONSITE>
                                        </div>
                                        }

                                </div>
                            </div>
                        </div>
                        <div className="content-card-body">
                            <CRD_INDIVIDUALS
                                var_individuals = {var_location.individuals}>
                            </CRD_INDIVIDUALS>
                            {/* <CRD_REQUIREMENTS
                                var_requirements = {var_location.requirements}>
                            </CRD_REQUIREMENTS> */}
                            {var_location.documents && var_location.documents != null &&
                                <CRD_DOCUMENTS
                                    var_documents = {var_location.documents}>
                                </CRD_DOCUMENTS>
                            }
                            <div className="profile_list">
                                {var_location.individuals.map((item, i) =>
                                <div className={"profile"+i} key={i}>
                                   <CRD_PROFILE
                                        var_individual = {item}
                                        var_org = {var_location.org[0]}
                                        var_linkid = {appProps.match.params.profileid} >
                                  </CRD_PROFILE>
                                  </div>
                                )}
                            </div>
                        </div>
                    </div>
                    )
                }
                else {
                    return(
                    <div>
                        <div className="content">
                            <div className="content-wrapper">
                                <Message color='red'>{t('A valid or active public profile could not be found. Please check the link again or the individual you received it from.')}</Message>
                            </div>
                        </div>
                    </div>
                    )
                }
                }
                else
                {
                    return(
                        <div>
                            <div className="content">
                                <div className="content-wrapper">
                                    <Message color='red'>{t('Internal Server Error')}</Message>
                                </div>
                            </div>
                        </div>
                        )
                }

            })()}
            </div>
                :
                <div className="public_wrapper">
                    <div>
                        <div className="content">
                            <div className="content-wrapper">
                                <Message color='red'>{t('A valid or active public profile could not be found. Please check the link again or the location/project you received it from')}</Message>
                            </div>
                        </div>
                    </div>
                </div>
            }
            </>
            }
        </div>
    )

};

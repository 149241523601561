import { Table, Modal } from 'semantic-ui-react';
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import datelib from '../../../../libs/date-lib';

import MDL_UPDATE from "./mdl_update/mdl_update";
import CMP_TABLECONTROLS, { filter_helper } from '../../../../components/cmp_tablecontrols/cmp_tablecontrols';

import './crd_packages.css';

import { useTranslation } from 'react-i18next';
import '../../../../i18n';

export default function({var_activetab}){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();

    const [ var_packages, set_packages ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(100);
    const [ var_offset, set_offset] = useState(0);
    const [ var_sortby, set_sortby ] = useState('name');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));
    const [ var_mdl_update_open, set_mdl_update_open ] = useState(false);
    const [ var_target_id, set_target_id ] = useState('');
    const [ var_target_name, set_target_name ] = useState('');



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_activetab === 'PACKAGES') {
            set_filter(filter_helper.initialize(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_activetab]);

    useEffect(()=>{
        if(var_filter.load) {
            populate_packages(null, 0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_filter]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_packages(limit,offset,sortby,sortorder,filter){
        if (var_activetab !== 'PACKAGES') return; // Don't load data if tab is not visible

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_packages(limit,offset,sortby,sortorder,filter);
            set_packages(results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername,sortorder){
        try {
            return await API_get_packages(filter_helper.maxFilterItems, 0, filtername, sortorder, {}, filtername);
        } catch (e) {
            console.log(e);
        }
    }

    async function download_packages(limit, offset){
        try {
            return await API_get_packages(limit, offset, var_sortby, var_sortorder, var_filter);
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_packages(limit,offset,sortby,sortorder,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-forms',
            '/get-packages/'+JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id,
            {queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    tz: datelib.timezone,
                    filtername: filtername
                },
                body: filter
            }
        );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_packages('',0,sortby,sortorder)
    }

    function onClick_openpackage(package_id, package_name){
        set_target_id(package_id);
        set_target_name(package_name);
        set_mdl_update_open(true);
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="content-card" id="crd_org_individuals_individuals">
                <div className="content-card-header">
                    <div className="content-card-header-title">{t('Packages')}</div>
                </div>
                <div className="content-card-gridcontent">
                    <div className="content-filter">
                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_packages && var_packages.length > 0 ? var_packages[0]['totalrows'] : 0} populatefunction={populate_packages}
                                downloadname='Packages' downloadfunction={download_packages}
                                var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                                table_config={[
                                    {name: 'name', datatype: 'text', labelKey: 'NAME', download: true, filter: true},
                                    {name: 'forms', datatype: 'int', labelKey: 'FORMS', download: true, filter: true}
                                ]}
                            />
                        </div>
                    </div>
                    <div className="tablewrapper">
                        <Table id="tbl_packages" sortable compact className={(var_loading ? " tbl_loading" : "")}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell sorted={var_sortby === 'name' ? var_sortorder : null} onClick={()=>onClick_sort('name')}>{t("NAME")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'forms' ? var_sortorder : null} onClick={()=>onClick_sort('forms')}>{t("FORMS")}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {var_ready && var_packages[0]['totalrows'] !== 0 &&
                            <Table.Body>
                                {var_packages.map((item, i) =>
                                    <Table.Row key={i} onClick={() => onClick_openpackage(item.id, item.name)}>
                                        <Table.Cell >{item.name}</Table.Cell>
                                        <Table.Cell >{item.forms}</Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                            }
                        </Table>
                        {var_ready && var_packages[0]['totalrows'] === 0 &&
                        <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t("alert icon")}/>{t("there are no packages to display")}</div>
                        }
                    </div>
                    <div className="content-filter">
                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_packages && var_packages.length > 0 ? var_packages[0]['totalrows'] : 0} populatefunction={populate_packages}
                            />
                        </div>
                    </div>
                </div>

                {/***** MODAL: UPDATE ********************************************************************************/}

                <Modal id="org-forms_mdl_updatepackages"
                       dimmer={'inverted'}
                       open={var_mdl_update_open}
                       onClose={() => set_mdl_update_open(false)}>
                    <MDL_UPDATE
                        set_mdl_open={set_mdl_update_open}
                        var_package_id={var_target_id}
                        var_package_name={var_target_name}
                        populate_function={populate_packages}>
                    </MDL_UPDATE>
                </Modal>

                {/***** END MODAL: UPDATE ***************************************************************************/}

            </div>
        </>
    )

}
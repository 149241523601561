import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, StyleSheet, View, Image } from '@react-pdf/renderer';

export default function({var_org, var_qrCode}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

	const styles = StyleSheet.create({
		profileHeader: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			padding: '12px 16px',
			borderBottom: 1,
			borderStyle: 'solid',
			borderColor: '#ebebeb'
		},
		profileHeaderOrgInfo: {
			flexDirection: 'row',
			alignItems: 'center',
			marginBottom: 10
		},
		profileHeaderLogoImg: {
			maxWidth: 120,
			maxHeight: 100,
			width: 100,
			marginRight: 25
		},
		profileHeaderTitle: {
			fontSize: 24,
			fontWeight: 500,
		},
		profileHeaderItem: {
			flexDirection: 'row',
			alignItems: 'center',
			fontSize: 9,
			padding: '3px 0',
		},
		profileHeaderItemIcon: {
			height: 10,
			width: 10,
			marginRight: 5
		},
		headerInfo: {
			flexDirection: 'row',
			paddingTop: 4,
		},
		headerInfoItem: {
			marginRight: 10,
		},
		headerInfoItemLabel: {
			color: '#cfd3d5',
			fontWeight: 500,
			fontSize: 8,
			textTransform: 'capitalize'
		},
		headerInfoItemText: {
			fontSize: 9,
			paddingTop: 2
		},
		image: {
			width: 90,
			height: 90,
		},
	});

    // RENDER APP ======================================================================================================
    return(
        <View style={styles.profileHeader}>
			<View style={styles.profileHeaderLeft}>
				<View style={styles.profileHeaderOrgInfo}>
					{var_org && var_org.org_logo && var_org.org_logo != null && var_org.org_logo !== '' && 
					<View style={styles.profileHeaderLogoImg} >
						<Image style={styles.profileHeaderLogoImg} src={ var_org.org_logo + '?noCache=' + Math.random().toString()} source={{ header: {'Access-Control-Allow-Origin': '*'}}}  />
					</View>
					}
					<View style={styles.profileHeaderTitle}>
						<Text>{var_org.name}</Text>
					</View>
				</View>
			
				<View style={styles.headerInfo}>
					{var_org && var_org.address1 && var_org.address1 != null && var_org.address1 !== '' &&
						<View style={styles.headerInfoItem}>
							<View style={styles.headerInfoItemLabel}>
								<Text>{t('ADDRESS')}</Text>
							</View>
							<View style={styles.headerInfoItemText}>
								<Text>{var_org.address1}</Text>
							</View>
						</View>
					}
					{var_org && var_org.city && var_org.city != null && var_org.city !== '' &&
						<View style={styles.headerInfoItem}>
							<View style={styles.headerInfoItemLabel}>
								<Text>{t('CITY')}</Text>
							</View>
							<View style={styles.headerInfoItemText}>
								<Text>{var_org.city}</Text>
							</View>
						</View>
					}
					{var_org && var_org.postal && var_org.postal != null && var_org.postal !== '' &&
						<View style={styles.headerInfoItem}>
							<View style={styles.headerInfoItemLabel}>
								<Text>{t('POSTAL')}</Text>
							</View>
							<View style={styles.headerInfoItemText}>
								<Text>{var_org.postal}</Text>
							</View>
						</View>
					}
				</View>
			</View>
			{var_qrCode != null &&
                  <View style={styles.profileHeaderRight}>
                    <Image src={ {uri: var_qrCode} } style={styles.image} />
                  </View>
                  }
        </View>
    )

}

import { Button, Form } from 'semantic-ui-react'
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import '../../../../../../../i18n';

import './mdl_assign.css';



export default function({set_mdl_open, var_location_id, activeform_id}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_selectedindividual, set_selectedindividual ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_processing, set_processing ] = useState(false);

    const [ var_dd_individuals, set_dd_individuals ] = useState([]);


    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_dd_individuals();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------


    async function populate_dd_individuals(){
        try {
            let results;
            if (var_location_id) {
                results = await API_get_dd_loc_form_individuals();
            }
            set_dd_individuals(results);
        } catch (e) {
            console.log(e);
        }
    }

    async function assign_form() {
        set_processing(true);
        try {
            let data = {};
            data['activeform_id'] = activeform_id;
            data['individuals'] = var_selectedindividual;
            data['location_id'] = var_location_id;
            data['organization_id'] = JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id;
            data['transactionby'] = JSON.parse(sessionStorage.getItem('authentication')).id;
            data['transactionorg'] = JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id;

            await API_post_form_individuals(data);

            set_ready(false);
            set_processing(false);
            set_mdl_open(false);
        } catch (e) {
            console.log(e);
        }

    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_dd_loc_form_individuals(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-forms', '/get-dd-loc-form-individuals/'+var_location_id+'/'+activeform_id);
    }

    function API_post_form_individuals(data){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-forms', '/post-form-individuals', { body: data});
    }



    //  event functions ------------------------------------------------------------------------------------------------


    function onChange_individual(e, { value }){
        set_selectedindividual(value);
        set_ready(true);
    }

    function onClick_submit(){
        if(var_ready) {
            assign_form();
        }
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{t("Assign Form")}</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
            </div>

            <div className="modal-content">
                <Form>
                    <Form.Select
                        className='individual_id'
                        id='individual_id'
                        name='individual_id'
                        label={t("INDIVIDUALS")}
                        options={var_dd_individuals}
                        value={var_selectedindividual || []}
                        onChange={onChange_individual}
                        placeholder={t("Select an individual...")}
                        multiple
                        selection
                        search
                        fluid
                    />
                </Form>
            </div>

            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>{t("CANCEL")}</Button>
                    {!var_processing ?
                        <Button className={"btn_primary " + (var_ready ? ' btn_active' : '')} onClick={() => onClick_submit()}>{t("ASSIGN FORM")}</Button>
                        :
                        <Button loading className="btn_primary">{t("ASSIGN FORM")}</Button>
                    }
                </div>
            </div>
        </>
    )
}
import { Button } from 'semantic-ui-react'
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import XLSX from 'xlsx';
import FileSaver from 'file-saver';
import Moment from 'moment';

import './cmp_download.css';

export default function({var_ready, total_rows, downloadname, downloadfunction, table_config}){
    //  variable declarations ------------------------------------------------------------------------------------------
    const MAX_BATCH_SIZE = 1000;
    const { t } = useTranslation();

    const [ var_processing, set_processing ] = useState(false);

    //  general functions ----------------------------------------------------------------------------------------------

    function format_value(config, value){
        return config.datatype === 'function'
            ? config.downloadfunction(value)
            : value === undefined || value === null
            ? null
            : config.datatype === 'date'
            ? Moment.unix(value).utc(false).format('ll')
            : config.datatype === 'epoch'
            ? Moment.unix(value).utc(true).format('lll')
            : value;
    }

    //  event functions ------------------------------------------------------------------------------------------------

    async function onClick_download(){
        // Make sure the page is ready and we're not in the middle of a download
        if (!var_ready || total_rows === 0 || var_processing){
            return;
        }

        set_processing(true);
        const download_config = table_config.filter(item => item.download);
        try {
            const workbook = XLSX.utils.book_new();
            let worksheet = XLSX.utils.aoa_to_sheet([download_config.map(item => (item.label ? item.label : t(item.labelKey)).toUpperCase())]);
            let offset = 0;
            while (offset < total_rows){
                let data = await downloadfunction(MAX_BATCH_SIZE, offset);
                let formatted_data = data.map(record => {
                    let formatted_record = {};
                    download_config.forEach(item => {
                        formatted_record[item.name] = format_value(item, record[item.name]);
                    });
                    return formatted_record;
                });
                XLSX.utils.sheet_add_json(worksheet, formatted_data, {skipHeader: true, origin: 'A'+(offset+2)});
                offset += MAX_BATCH_SIZE;
            }

            XLSX.utils.book_append_sheet(workbook, worksheet, downloadname || 'Download');

            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const excelData = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
            FileSaver.saveAs(excelData, (downloadname || 'Download')+'.xlsx');
        } catch (e) {
            console.log(e);
            alert(t('Unable to download'));
        } finally {
            set_processing(false);
        }
    }

    // RENDER ==========================================================================================================

    return (
        <div>
            <Button id='btn_download' className={'btn_icononly download_button'+(var_ready && total_rows > 0 ? '' : ' download_not_ready')} onClick={onClick_download} loading={var_processing}>
                {!var_processing && <img className='btn_icon' src={'/icons/download 30px (4dcbd4).svg?ts=' + Date.now()} alt={t('download icon')}/>}
            </Button>
        </div>
    );
}
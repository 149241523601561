import { useEffect } from "react";
import React from "react";

import './crd_details.css';
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

export default function({var_detail, var_org}){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="profile_header">
                <div className="profile_header_left">
                    <div className="profile_header_title">{var_detail.individual}</div>
                </div>
                <ul className="profile_header_info">
                    {var_org && var_org.name && var_org.name != null && var_org.name !== '' &&
                        <li>
                                <div className="profile_header_info_th">{t('ORGANIZATION')}</div>
                            <div className="profile_header_info_td">{var_org===undefined? '' :(var_org.name===null? '' : var_org.name)}</div>
                        </li>
                    }
                </ul>
            </div>
        </>
    )
}

import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { Button, Form, Input } from 'semantic-ui-react';
import auth from '../../../../../libs/auth-lib';

import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import './crd_orgdetails.css'

export default function CRD_ORGDETAILS({var_pageready}){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();

    const [ var_ready, set_ready ] = useState(false);
    const [ var_organization, set_organization ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_validationerror, set_validationerror ] = useState(false);
    const [ var_validationerrormessage, set_validationerrormessage ] = useState('');
    const [ var_updated, set_updated ] = useState(false);
    const [ var_errormessage, set_errormessage ] = useState('');

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_pageready) {
            populate_organization();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_pageready]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_organization() {
        try {
            await API_get_organization().then((organization)=>{
                set_organization(organization[0]);
            });
        } catch (e) {
            console.log(e);
        }
    }

    async function update_organization() {
        try {
            await API_put_organization();
        } catch (e) {
            set_errormessage('There was an error updating the organization');
            console.log(e);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_organization() {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org', '/get-organization/'+auth.organization_id)
            .then(response => response)
            .catch(error => {
                console.log(error.response);
                return [{"id":null}];  // on error, return a sane result
            });
    }

    function API_put_organization() {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.put('org', '/put-organization/' + auth.organization_id, { body: var_organization });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(event) {
        const organization = { ...var_organization };
        set_validationerror(false);
        organization[event.target.name] = event.target.value;
        set_organization(organization);
        set_ready(true);
    }

    function onClick_submit() {
        set_processing(true);
        let var_validation = false;
        let var_validmessage = '';
        set_errormessage('');
        if ((!var_organization.name || var_organization.name.trim() === '')) {
            var_validation = true;
            var_validmessage = 'Please provide the business name';
        } else if (!var_organization.address1 || var_organization.address1.trim() === '') {
            var_validation = true;
            var_validmessage = 'Please provide your address';
        } else if (!var_organization.city || var_organization.city.trim() === '') {
            var_validation = true;
            var_validmessage = 'Please provide your city';
        } else if (!var_organization.province || var_organization.province.trim() === '') {
            var_validation = true;
            var_validmessage = 'Please provide your province/state';
        } else if (!var_organization.postal || var_organization.postal.trim() === '') {
            var_validation = true;
            var_validmessage = 'Please provide your postal/zip code';
        }

        set_validationerrormessage(var_validmessage);
        set_validationerror(var_validation);
        if (var_validation === false) {
            update_organization();
            set_ready(false);
            set_updated(true);
            setTimeout(() => set_updated(false), 2500);
        }
        set_processing(false);
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="content-card" id="crd_orgdetails">
                <div className="content-card-header">
                    <div className="content-card-header-title">{t('Profile Details')}</div>
                </div>
                <div className="content-card-content">
                    {var_validationerror &&
                        <div className="message warning">
                            <div className="message_icon"><img src={"/icons/warning 60px (d91e18).svg?ts=" + Date.now()} alt={t('warning icon')} /></div>
                            <div className="message_text_wrapper">
                                <div className="message_text cause">{t(var_validationerrormessage)}</div>
                            </div>
                        </div>
                    }
                    {var_updated &&
                        <div className="message success">
                            <div className="message_icon"><img src={"/icons/checkmark 60px (69b536).svg?ts=" + Date.now()} alt={t('success icon')} /></div>
                            <div className="message_text_wrapper">
                                <div className="message_text cause">{t('Profile Updated')}</div>
                            </div>
                        </div>
                    }
                    {var_errormessage !== '' &&
                        <div className="message error">
                            <div className="message_icon"><img src={"/icons/x 60px (DB2430).svg?ts=" + Date.now()} alt={t('success icon')} /></div>
                            <div className="message_text_wrapper">
                                <div className="message_text cause">{t(var_errormessage)}</div>
                            </div>
                        </div>
                    }
                    <Form>
                        <Form.Group className="fg_name">
                            <div className="detail-item-label">{t('NAME')}</div>
                            <Form.Field
                                className='name'
                                id='name'
                                name='name'
                                value={var_organization.name || ''}
                                control={Input}
                                onChange={onChange_input}
                                placeholder={t('Business name...')}
                            />
                        </Form.Group>
                        <Form.Group className="fg_address">
                            <div className="detail-item-label">{t('ADDRESS')}</div>
                            <Form.Field
                                className='address1'
                                id='address1'
                                name='address1'
                                value={var_organization.address1 || ''}
                                control={Input}
                                onChange={onChange_input}
                                placeholder={t('Address...')}
                            />
                        </Form.Group>
                        <Form.Group className="fg_address">
                            <div className="detail-item-label">{t('ADDRESS 2')}</div>
                            <Form.Field
                                className='address2'
                                id='address2'
                                name='address2'
                                value={var_organization.address2 || ''}
                                control={Input}
                                onChange={onChange_input}
                                placeholder={t('Address 2...')}
                            />
                        </Form.Group>
                        <Form.Group>
                            <div className="detail-item-label">{t('CITY')}</div>
                            <Form.Field
                                className='city'
                                id='city'
                                name='city'
                                value={var_organization.city || ''}
                                control={Input}
                                onChange={onChange_input}
                                placeholder={t('City...')}
                            />
                        </Form.Group>
                        <Form.Group>
                            <div className="detail-item-label">{t('PROVINCE/STATE')}</div>
                            <Form.Field
                                className='province'
                                id='province'
                                name='province'
                                value={var_organization.province || ''}
                                control={Input}
                                onChange={onChange_input}
                                placeholder={t('Province/State...')}
                            />
                        </Form.Group>
                        <Form.Group className="fg_postal">
                            <div className="detail-item-label">{t('POSTAL/ZIP CODE')}</div>
                            <Form.Field
                                className='postal'
                                id='postal'
                                name='postal'
                                value={var_organization.postal || ''}
                                control={Input}
                                onChange={onChange_input}
                                placeholder={t('Postal/Zip Code...')}
                            />
                        </Form.Group>
                    </Form>

                </div>
                <div className="content-card-footer">
                    <div className="content-card-footer-buttons">
                        {!var_processing ?
                        <Button className={'btn_primary ' + (var_ready ? 'btn_active' : '')} onClick={() => onClick_submit()}>{t('UPDATE PROFILE')}</Button>
                        :
                        <Button loading className="btn_primary btn_active">{t('UPDATE PROFILE')}</Button>
                        }
                    </div>
                </div>
            </div>
        </>
   );
}
//  OUTCOMES -----------------------------------------------------------------------------------------------------------
//
//  - displays a modal for the user to confirm removing a credential from the list of pre-approvals/pre-rejects for a requirement
//
//  --------------------------------------------------------------------------------------------------------------------

import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { API } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import '../../../../../../../i18n';

import CMP_CONFIRMATION from '../../../../../../../components/cmp_confirmation/cmp_confirmation';
import CMP_SUCCESS from '../../../../../../../components/cmp_success/cmp_success';

import './mdl_removecredential.css';

function MDL_REMOVECREDENTIAL({ var_mdl_open, set_mdl_open, requirement_name, accept_or_reject, issuer_credential_record, populate_function }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_complete, set_complete ] = useState(false);
    const [ var_transaction_id, set_transaction_id ] = useState(null);
    const [ var_item_name, set_item_name ] = useState(null);

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(()=>{
        if (var_mdl_open) {
            set_complete(false);
            set_item_name(issuer_credential_record.credential || issuer_credential_record.issuer);
        }
    },[var_mdl_open]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function delete_requirement_historical() {
        try {
            let result = await API_delete_requirement_historical();
            set_transaction_id(result.transaction_id);
            populate_function && await populate_function();
            set_complete(true);
        } catch (e) {
            console.log(e);
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    async function API_delete_requirement_historical() {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.del('org-requirements','/delete-requirement-historical/'+issuer_credential_record.id+'/'+JSON.parse(sessionStorage.getItem('authentication')).organization_id);
    }

    //  event functions ------------------------------------------------------------------------------------------------

    async function onClick_removecredential() {
        await delete_requirement_historical();
    }

    // RENDER APP ======================================================================================================

    return (
        <>{var_complete ? render_success() : render_confirmation()}</>
    );

    function render_confirmation() {
        return (
            <CMP_CONFIRMATION set_mdl_open={set_mdl_open} var_modaltitle={t('Remove Credential')}
                var_message={t('Are you sure you want to remove ') + var_item_name +
                 t(' from the pre-approval ' + (accept_or_reject === 'ACCEPT' ? 'accepted' : 'rejected') + ' list for the ') + requirement_name + t(' requirement?')}
                confirmation_function={onClick_removecredential} var_call_to_function={true} success_function={() => {}} />
        );
    }

    function render_success() {
        return (
            <CMP_SUCCESS title={t('Credential Removed')} set_mdl_open={set_mdl_open} success_or_fail='SUCCESS' transaction_id={var_transaction_id} body={
                var_item_name +
                t(' has successfully been removed from the ') + requirement_name + t(' pre-approval '+(accept_or_reject === 'ACCEPT' ? 'accepted' : 'rejected')+' list')}
            />
        );
    }
}

MDL_REMOVECREDENTIAL.propTypes = {
    var_mdl_open: propTypes.bool.isRequired,
    set_mdl_open: propTypes.func.isRequired,
    requirement_name: propTypes.string.isRequired,
    accept_or_reject: propTypes.oneOf(['ACCEPT', 'REJECT']).isRequired,
    issuer_credential_record: propTypes.object,
    populate_function: propTypes.func
}

export default MDL_REMOVECREDENTIAL;
import { Button, Form, Input, Dropdown } from 'semantic-ui-react'
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import auth from '../../../../../libs/auth-lib';
import '../../../../../i18n';

import './mdl_inviteindividual.css';



export default function({ set_mdl_inviteindividual_open, populate_individuals }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_inviteindividual, set_inviteindividual ] = useState([]);
    const [ var_checkinviteindividual_exists, set_checkinviteindividual_exists ] = useState(false);
    const [ var_ready, set_ready] = useState(false);
    const [var_processing, set_processing] = useState(false);
    const [var_validationerror, set_validationerror] = useState(false);
    const [var_validationerrormessage, set_validationerrormessage] = useState('');
    const [ var_dd_locations, set_dd_locations] = useState([]);



    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        const inviteindividual = { ...var_inviteindividual };
        inviteindividual['individual_type'] = 'USER';
        inviteindividual['permission_id'] = '4EBEB4CF-4B68-4A29-A8FF-01FBC744A898';
        inviteindividual['invitedby'] = auth.firstname + ' ' + auth.lastname;
        inviteindividual['invitedbyemail'] = auth.email;
        inviteindividual['organization'] = auth.organization;
        inviteindividual['organization_id'] = auth.actingorganization_id;
        inviteindividual['transactionby'] = auth.id;
        inviteindividual['transactionorg'] = auth.organization_id;
        set_inviteindividual(inviteindividual);

        get_dd_org_locations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=> {
        let var_validation = true;

        if ((!var_inviteindividual.firstname || var_inviteindividual.firstname === '') && (!var_inviteindividual.lastname || var_inviteindividual.lastname === '') && (!var_inviteindividual.email || var_inviteindividual.email === '')) {
            var_validation = false;
        }
        set_ready(var_validation);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_inviteindividual]);

    useEffect(()=> {
        if (var_dd_locations.length > 0) {
            const inviteindividual = { ...var_inviteindividual };     
            inviteindividual['location_id'] = var_dd_locations.find(item => item.default_org === 'YES')?.value;
            set_inviteindividual(inviteindividual);            
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_dd_locations]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function checkinviteindividual(){
        set_checkinviteindividual_exists(false);
        set_processing(true);

        try {
            let result = await API_post_checkinviteindividual();
            if(result[0]['emails']>0){
                set_checkinviteindividual_exists(true);
                set_ready(false);
                set_processing(false);
            }else{
                invite_individual();
            }
        } catch (e) {
            console.log(e);
        }
    }

    async function invite_individual(){
        try {
            let inviteresult = await API_post_inviteindividual();
            if(inviteresult[0] === 'FAILED'){
                alert(t("There was a problem creating the invite.  Please log out and log back into the system and try again."))
            }else{
                set_ready(false);
                set_processing(false);
                set_mdl_inviteindividual_open(false);
                populate_individuals();
            }
        } catch (e) {
            console.log(e);
        }
    }

    async function get_dd_org_locations() {
        set_dd_locations(await API_get_dd_org_locations());
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_checkinviteindividual(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('admin', '/post-checkinviteindividual/'+ auth.actingorganization_id, { body: var_inviteindividual });
    }

    function API_get_dd_org_locations() {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-locations', '/get-dd-org-locations/'+ auth.organization_id);
    }

    function API_post_inviteindividual(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-individuals', '/post-indv-invite', { body: var_inviteindividual });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_type(e, { value }){
        const inviteindividual = { ...var_inviteindividual };
        inviteindividual['individual_type'] = value;
        set_inviteindividual(inviteindividual);
    }

    function onChange_invite_input(event){
        if(event.target.name==='email'){
            event.target.value=event.target.value.toLowerCase().trim()
        }
        set_validationerror(false);
        set_ready(true);
        const inviteindividual = { ...var_inviteindividual };
        inviteindividual[event.target.name] = event.target.value.trimStart();
        set_inviteindividual(inviteindividual);
    }

    function onChange_location(e, { value }){
        const inviteindividual = { ...var_inviteindividual };
        inviteindividual['location_id'] = value;
        set_inviteindividual(inviteindividual);
    }

    function onClick_submit(){
        if (var_ready) {
            let var_validation = false;
            let var_validmessage = '';
            if ((var_inviteindividual.firstname === undefined || var_inviteindividual.firstname === '') && (var_inviteindividual.lastname === undefined || var_inviteindividual.lastname === '')) {
                var_validation = true;
                var_validmessage = 'Please provide a first and last name';
            } else if ((var_inviteindividual.firstname === undefined || var_inviteindividual.firstname === '') && (var_inviteindividual.email === undefined || var_inviteindividual.email === '')) {
                var_validation = true;
                var_validmessage = 'Please provide a first name and email address';
            } else if ((var_inviteindividual.lastname === undefined || var_inviteindividual.lastname === '') && (var_inviteindividual.email === undefined || var_inviteindividual.email === '')) {
                var_validation = true;
                var_validmessage = 'Please provide a last name and email address';
            } else {
                if (!var_inviteindividual.firstname || var_inviteindividual.firstname === '') {
                    var_validation = true;
                    var_validmessage = 'Please provide a first name';
                }

                if (!var_inviteindividual.lastname || var_inviteindividual.lastname === '') {
                    var_validation = true;
                    var_validmessage = 'Please provide a last name';
                }

                if (!var_inviteindividual.email || var_inviteindividual.email === '') {
                    var_validation = true;
                    var_validmessage = 'Please provide an email address';
                } else {
                    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|([\w-]+(?:\+[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*)|([\w-]+(?:\.[\w-]+)*)([\w-]+(?:\+[\w-]+)*)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                    if (!pattern.test(var_inviteindividual.email)) {
                        var_validation = true;
                        var_validmessage = 'Please provide a valid email address';
                    }
                }
            }
            set_validationerrormessage(var_validmessage);
            set_validationerror(var_validation);
            if (var_validation === false) {
                checkinviteindividual();
            }
        }
    }
    


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">{t("Invite Individual")}</div>
            <div className="modal-content">
                {var_checkinviteindividual_exists &&
                <div className="message warning">
                    <div className="message_icon"><img src={"/icons/warning 60px (d91e18).svg?ts=" + Date.now()} alt={t("warning icon")}/></div>
                    <div className="message_text resolution">{auth.organization} {t("already has an active individual with this email address.")}</div>
                </div>
                }
                <Form>
                    {auth.org_guest &&
                    <Form.Group>
                        <Form.Select
                            className='individual_type'
                            name='individual_type'
                            label={t("INVITE TYPE")}
                            options={[  {text: 'USER', value: 'USER'},
                                {text: 'GUEST', value: 'GUEST'}]}
                            value={var_inviteindividual.individual_type || ''}
                            onChange={onChange_type}
                            placeholder={t("Select an invite type...")}
                        />
                    </Form.Group>
                    }
                    <Form.Field
                        id='email'
                        name='email'
                        value={var_inviteindividual.email || ''}
                        onChange={onChange_invite_input}
                        control={Input}
                        label={t("EMAIL")}
                        placeholder={t("Email...")}
                    />
                    <Form.Group className="mdl_inviteindividual_individual">
                        <Form.Field
                            id='firstname'
                            name='firstname'
                            value={var_inviteindividual.firstname || ''}
                            onChange={onChange_invite_input}
                            control={Input}
                            label={t("FIRST NAME")}
                            placeholder={t("First name...")}
                        />
                        <Form.Field
                            id='lastname'
                            name='lastname'
                            value={var_inviteindividual.lastname || ''}
                            onChange={onChange_invite_input}
                            control={Input}
                            label={t("LAST NAME")}
                            placeholder={t("Last name...")}
                        />
                    </Form.Group>
                    <Dropdown
                        id='location'
                        name='location'
                        value={var_inviteindividual.location_id || ''}
                        onChange={onChange_location}
                        label={t("LOCATION/PROJECT")}
                        placeholder={t("Location/Project...")}
                        options={var_dd_locations}
                        fluid
                        search
                        clearable
                        selection
                    />
                </Form>
                {var_validationerror &&
                    <div className="message warning">
                        <div className="message_icon"><img src={"/icons/warning 60px (d91e18).svg?ts=" + Date.now()} alt={t('warning icon')} /></div>
                        <div className="message_text_wrapper">
                            <div className="message_text cause">{t(var_validationerrormessage)}</div>
                        </div>
                    </div>
                }
            </div>
            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_inviteindividual_open(false)}>{t("CANCEL")}</Button>
                    {!var_processing ?
                        <Button className={"btn_primary " + (var_ready ? ' btn_active' : '')} onClick={() => onClick_submit()}>{t("INVITE INDIVIDUAL")}</Button>
                        :
                        <Button loading className="btn_primary btn_active">{t("INVITE INDIVIDUAL")}</Button>
                    }

                </div>
            </div>
        </>
    )
}

import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import {Button, Modal, Table} from 'semantic-ui-react';
import auth from '../../../../../../libs/auth-lib';
import datelib from '../../../../../../libs/date-lib';
import { useTranslation } from 'react-i18next';
import '../../../../../../i18n';

import CMP_TABLECONTROLS, {filter_helper} from '../../../../../../components/cmp_tablecontrols/cmp_tablecontrols';
import MDL_INDV from "../../../../individuals/mdl_indv/mdl_indv";
import MDL_ADD from "./mdl_add/mdl_add";
import MDL_CONFIRMATION from "../../../../../../components/cmp_confirmation/cmp_confirmation";

import './tab_individuals.css';



export default function({ var_activetab, var_location_id, var_target}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_individuals, set_individuals ] = useState([]);
    const [ var_individual, set_individual ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(10);
    const [ var_offset, set_offset] = useState(0);
    const [ var_search, set_search ] = useState('');
    const [ var_sortby, set_sortby ] = useState('individual');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));

    const [ var_mdl_indv_open, set_mdl_indv_open ] = useState(false);
    const [ var_target_id, set_target_id ] = useState(false);
    const [ var_target_text, set_target_text ] = useState(false);
    const [ var_mdl_add_open, set_mdl_add_open ] = useState(false);

    const [ var_mdl_confirmation_open, set_mdl_confirmation_open ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_activetab === 'INDIVIDUALS') {
            set_filter(filter_helper.initialize(false));
        }
    },[var_activetab]);

    useEffect(()=>{
        if(var_filter.load){
            populate_individuals(null,0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_filter]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_individuals(limit,offset,sortby,sortorder,search,filter){
        if (var_activetab !== 'INDIVIDUALS') return; // Don't load data if tab is not visible

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        if(search) {
            if(search === '-') {
                search = '';
            }
            set_search(search);
        }else{
            search=var_search
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_loc_individuals(limit,offset,sortby,sortorder,search,filter);
            set_individuals(results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername,sortorder){
        try {
            return await API_get_loc_individuals(filter_helper.maxFilterItems,0,filtername,sortorder,var_search,[],filtername);
        } catch (e) {
            console.log(e);
        }
    }

    async function download_loc_individuals(limit, offset){
        try {
            return await API_get_loc_individuals(limit, offset, var_sortby, var_sortorder, var_search, var_filter);
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    async function remove_individual(){
        set_ready(false);
        set_loading(true);
        try {
            await API_delete_loc_individual();
            populate_individuals();
        } catch (e) {
            console.log(e);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_loc_individuals(limit,offset,sortby,sortorder,search,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-locations',
            '/get-loc-individuals/'+var_location_id,
            {
                queryStringParameters: {
                    limit : limit,
                    offset : offset,
                    sortby : sortby,
                    sortorder : sortorder,
                    search : search,
                    tz : datelib.timezone,
                    filtername : filtername
                },
                body: filter
            }
        );
    }

    function API_delete_loc_individual(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.del('org-locations', '/delete-loc-individual/'+var_target_id, { body: var_individual });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_individuals('',0,sortby,sortorder)
    }

    function onClick_individual(var_target_id){
        set_target_id(var_target_id);
        set_mdl_indv_open(true);
    }

    function onClick_addindividual(){
        set_mdl_add_open(true);
    }

    function onClick_removeindividual(var_target_id, var_target_text, var_individual_id){
        const individual = {...var_individual};
        individual['individual_id'] = var_individual_id;
        individual['location_id'] = var_location_id;
        individual['organization_id'] = auth.actingorganization_id;
        individual['transactionby'] = auth.id;
        individual['transactionorg'] = auth.organization_id;
        set_individual(individual);

        set_target_id(var_target_id);
        set_target_text(var_target_text);
        set_mdl_confirmation_open(true);
    }




    // RENDER APP ======================================================================================================

    return (
        <div className="modal-tab">
            <div className="content-filter">
                <div className="content-filter-item item-pagination">
                    <CMP_TABLECONTROLS
                        var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                        total_rows={var_individuals && var_individuals.length > 0 ? var_individuals[0]['totalrows'] : 0} populatefunction={populate_individuals}
                        downloadname='Individuals' downloadfunction={download_loc_individuals}
                        var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                        table_config={[
                            {name: 'individual', datatype: 'text', labelKey: 'NAME', download: true, filter: true},
                            {name: 'email', datatype: 'text', labelKey: 'EMAIL', download: true, filter: true},
                            {name: 'phone', datatype: 'text', labelKey: 'PHONE', download: true, filter: true},
                            {name: 'organization', datatype: 'text', labelKey: 'ORGANIZATION', download: true, filter: true},
                            {name: 'permission', datatype: 'text', labelKey: 'PERMISSION', download: true, filter: true},
                            {name: 'individual_type', datatype: 'text', labelKey: 'TYPE', download: true, filter: true},
                            {name: 'invitestatus', datatype: 'text', labelKey: 'INVITE STATUS', download: true, filter: true},
                            ...auth.org_qraccess ?
                                [{name: 'visitor', datatype: 'text', labelKey: 'VISITOR', download: true, filter: true}] : [],
                            ...auth.org_r2w ?
                                [{name: 'onsite', datatype: 'text', labelKey: 'ONSITE', download: true, filter: true}] : []
                        ]}
                    />
                </div>
            </div>
            <div className="tablewrapper">
                <Table sortable compact className={(var_loading ? " tbl_loading" : "")}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell sorted={var_sortby === 'individual' ? var_sortorder : null} onClick={()=>onClick_sort('individual')}>{t("NAME")}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'email' ? var_sortorder : null} onClick={()=>onClick_sort('email')}>{t("EMAIL")}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'phone' ? var_sortorder : null} onClick={()=>onClick_sort('phone')}>{t("PHONE")}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'organization' ? var_sortorder : null} onClick={() => onClick_sort('organization')}>{t("ORGANIZATION")}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'permission' ? var_sortorder : null} onClick={()=>onClick_sort('permission')}>{t("PERMISSION")}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'individual_type' ? var_sortorder : null} onClick={()=>onClick_sort('individual_type')}>{t("TYPE")}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'invitestatus' ? var_sortorder : null} onClick={()=>onClick_sort('invitestatus')}>{t("INVITE STATUS")}</Table.HeaderCell>
                            {auth.org_qraccess &&
                                <Table.HeaderCell sorted={var_sortby === 'visitor' ? var_sortorder : null} onClick={()=>onClick_sort('visitor')}>{t("VISITOR")}</Table.HeaderCell>
                            }
                            {auth.org_r2w &&
                                <Table.HeaderCell sorted={var_sortby === 'onsite' ? var_sortorder : null} onClick={()=>onClick_sort('onsite')}>{t("ONSITE")}</Table.HeaderCell>
                            }

                            {[auth.constants.permission_ids.Admin, auth.constants.permission_ids.GlobalAdmin, auth.constants.permission_ids.Superadmin].includes(auth.permission_id) &&
                                <Table.HeaderCell className="td_icon_center"></Table.HeaderCell>
                            }
                        </Table.Row>
                    </Table.Header>
                    {var_ready && var_individuals[0]['totalrows'] !== 0 &&
                    <Table.Body>
                        {var_individuals.map((item, i) =>
                            <Table.Row key={i} >
                                <Table.Cell onClick={()=>onClick_individual(item.id)}>{item.individual}</Table.Cell>
                                <Table.Cell onClick={()=>onClick_individual(item.id)}>{item.email}</Table.Cell>
                                <Table.Cell onClick={()=>onClick_individual(item.id)}>{item.phone}</Table.Cell>
                                <Table.Cell onClick={()=>onClick_individual(item.id)}>{item.organization}</Table.Cell>
                                <Table.Cell onClick={()=>onClick_individual(item.id)}>{item.permission}</Table.Cell>
                                <Table.Cell onClick={()=>onClick_individual(item.id)}>{item.individual_type}</Table.Cell>
                                <Table.Cell onClick={()=>onClick_individual(item.id)}>{item.invitestatus}</Table.Cell>

                                {auth.org_qraccess &&
                                    // Visitor - indicate if the individual is just a visitor
                                    <Table.Cell onClick={()=>onClick_individual(item.id)}>{item.visitor}</Table.Cell>
                                }
                                {auth.org_r2w &&
                                    // Onsite - display the onsite status for the day
                                    <Table.Cell onClick={()=>onClick_individual(item.id)}>{item.onsite}</Table.Cell>
                                }

                                {[auth.constants.permission_ids.Admin, auth.constants.permission_ids.GlobalAdmin, auth.constants.permission_ids.Superadmin].includes(auth.permission_id) &&
                                    <>
                                    {item.visitor !== 'YES' ?
                                    <Table.Cell className="td_icon_center" onClick={() => onClick_removeindividual(item.individuallocation_id, item.individual, item.id)}><img src={"/icons/delete 18px (6B7280).svg?ts=" + Date.now()} alt={t("delete icon")}/></Table.Cell>
                                    :<Table.Cell></Table.Cell>
                                    }
                                    </>
                                }
                            </Table.Row>
                        )}
                    </Table.Body>
                    }
                </Table>
                {var_ready && var_individuals[0]['totalrows'] === 0 &&
                <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t("alert icon")}/>{t("there are no individuals to display")}</div>
                }
            </div>
            <div className="content-filter">
                <div className="content-filter-item item-pagination">
                    <CMP_TABLECONTROLS
                        var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                        total_rows={var_individuals && var_individuals.length > 0 ? var_individuals[0]['totalrows'] : 0} populatefunction={populate_individuals}
                    />
                </div>
            </div>

            {[auth.constants.permission_ids.Admin, auth.constants.permission_ids.GlobalAdmin, auth.constants.permission_ids.Superadmin].includes(auth.permission_id) &&
                <div className="modal-footer">
                    <div className="modal-footer-buttons">
                        <Button className={"btn_primary " + (var_ready ? 'btn_active' : '')} onClick={() => onClick_addindividual()}>{t("ADD INDIVIDUAL")}</Button>
                    </div>
                </div>
            }


            {/***** MODAL: INDIVIDUAL ****************************************************************************/}

            <Modal id="mdl_org_individuals_indv"
                   dimmer={'inverted'}
                   open={var_mdl_indv_open}
                   onClose={() => set_mdl_indv_open(false)}>
                <MDL_INDV
                    set_mdl_open={set_mdl_indv_open}
                    var_individual_id={var_target_id}
                    populate_function={populate_individuals}>
                </MDL_INDV>
            </Modal>

            {/***** END MODAL: INDIVIDUAL ************************************************************************/}



            {/***** MODAL: ADD ***************************************************************************************/}

            <Modal id="org-locations_mdl_addindividual"
                   dimmer={'inverted'}
                   open={var_mdl_add_open}
                   onClose={() => set_mdl_add_open(false)}>
                <MDL_ADD
                    set_mdl_open={set_mdl_add_open}
                    populate_function={populate_individuals}
                    var_location_id={var_location_id}>
                </MDL_ADD>
            </Modal>

            {/***** END MODAL: ADD ***********************************************************************************/}



            {/***** MODAL: CONFIRMATION *******************************************************************************/}

            <Modal id="mdl_confirmation"
                   dimmer={'inverted'}
                   open={var_mdl_confirmation_open}
                   onClose={() => set_mdl_confirmation_open(false)}>
                <MDL_CONFIRMATION
                    set_mdl_open={set_mdl_confirmation_open}
                    var_modaltitle={t("Remove Individual")}
                    var_message={t("Are you sure you want to remove") + ' ' + var_target_text + ' ' + t("from") + ' ' + var_target + t("?")}
                    confirmation_function={remove_individual}>
                </MDL_CONFIRMATION>
            </Modal>

            {/***** END MODAL: CONFIRMATION **************************************************************************/}


        </div>
    )

};

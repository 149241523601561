import Moment from 'moment';

const datelib = {
    // Gets the local timezone
    get timezone() { return Intl.DateTimeFormat().resolvedOptions().timeZone || this.utc_offset; },

    // Gets the local timezone encoded for a querystring
    get querystring_timezone() { return encodeURIComponent(this.timezone); },

    get utc_offset() { return Moment().format('Z'); },

    // Converts a date to its epoch
    date_to_epoch: function(thedate) { return this.moment_to_epoch(Moment(thedate)); },

    // Converts a moment to its epoch
    moment_to_epoch: function(themoment) { return themoment.valueOf() / 1000; },

    // Converts an epoch to its moment
    epoch_to_utcmoment: function(epoch) { return Moment.utc(epoch*1000); },

    // Takes a date in local time (ie: Mon Nov 01 2021 00:00:00 GMT-0600 (Mountain Daylight Time))
    // and converts it to the moment of midnight utc of the same date.  It does not convert timezone.
    localdate_to_utc_midnight_moment: function(localdate) { return Moment.utc(Moment(localdate).format('YYYY-MM-DD'), 'YYYY-MM-DD'); },

    // Takes a date in local time (ie: Mon Nov 01 2021 00:00:00 GMT-0600 (Mountain Daylight Time))
    // and converts it to midnight utc of the same date.  It does not convert timezone.
    localdate_to_utc_midnight_date: function(localdate) { return this.localdate_to_utc_midnight_moment(localdate).toDate(); },

    // Takes a date in local time (ie: Mon Nov 01 2021 00:00:00 GMT-0600 (Mountain Daylight Time))
    // and converts it to the epoch of midnight utc of the same date.  It does not convert timezone.
    localdate_to_utc_midnight_epoch: function(localdate) { return this.moment_to_epoch(this.localdate_to_utc_midnight_moment(localdate)); },

    // Takes a utc moment and converts it to the moment of midnight local time of the same date.  It does not convert timezone.
    utcmoment_to_local_midnight_moment: function(utcdate) { return Moment(utcdate.format('YYYY-MM-DD'), 'YYYY-MM-DD'); },

    // Takes a utc moment and converts it to midnight local time of the same date.  It does not convert timezone.
    utcmoment_to_local_midnight_date: function(utcdate) { return this.utcmoment_to_local_midnight_moment(utcdate).toDate(); },

    // Takes a utc moment and converts it to the epoch of midnight local time of the same date.  It does not convert timezone.
    utcmoment_to_local_midnight_epoch: function(utcdate) { return this.moment_to_epoch(this.utcmoment_to_local_midnight_moment(utcdate)); },

    // Formats a date based on locale - shortened version
    date_localized_format: function(thedate) { return (thedate && thedate !== '-' && thedate !== 'n/a') ? Moment(thedate).format('ll') : thedate;},

    // Formats a datetime based on locale - shortened version
    datetime_localized_format: function(thedatetime) { return thedatetime ? Moment(thedatetime).format('lll') : thedatetime;},

};

export default datelib;
import { Button, Modal, Table } from 'semantic-ui-react';
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import datelib from '../../../../libs/date-lib';

import CMP_TABLECONTROLS, { filter_helper } from '../../../../components/cmp_tablecontrols/cmp_tablecontrols';
import MDL_ADD from "./mdl_add/mdl_add";
import MDL_RESPONSE from "./mdl_response/mdl_response";
import MDL_ASSIGNMENT from "./mdl_assignment/mdl_assignment";
import MDL_CONFIRMATION from "../../../../components/cmp_confirmation/cmp_confirmation";

import './crd_networks.css';

import { useTranslation } from 'react-i18next';
import '../../../../i18n';

export default function(appProps){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();

    const [ var_networks, set_networks ] = useState([]);
    const [ var_item, set_item ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(100);
    const [ var_offset, set_offset] = useState(0);
    const [ var_archive, set_archive ] = useState('NO');
    const [ var_sortby, set_sortby ] = useState('organization');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));

    const [ var_mdl_add_open, set_mdl_add_open ] = useState(false);
    const [ var_mdl_response_open, set_mdl_response_open ] = useState(false);

    const [ var_mdl_assignment_open, set_mdl_assignment_open ] = useState(false);

    const [ var_mdl_confirmation_open, set_mdl_confirmation_open ] = useState(false);
    const [ var_confirmationtext, set_confirmationtext ] = useState('');


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_archive !== undefined) {
            let filter = filter_helper.initialize(false);
            set_filter(filter);
            populate_networks(null, null, null, null, null, filter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_archive]);

    useEffect(()=>{
        if(var_filter.load) {
            populate_networks(null, 0);
        }
    },[var_filter]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_networks(limit,offset,sortby,sortorder,archive,filter){

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }


        if(archive) {
            set_archive(archive);
        }else{
            archive=var_archive
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_networks(limit,offset,sortby,sortorder,archive,filter);
            set_networks(results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername,sortorder){
        try {
            return await API_get_networks(filter_helper.maxFilterItems, 0, filtername, sortorder, var_archive, {}, filtername);
        } catch (e) {
            console.log(e);
        }
    }

    async function download_org_networks(limit, offset){
        try {
            return await API_get_networks(limit, offset, var_sortby, var_sortorder, var_archive, var_filter);
        }  catch (e) {
             console.log(e);
             throw e;
         }
     }

    async function update_network(var_status) {
        try {
            await API_put_org_network(var_status);
            populate_networks();
        } catch (e) {
            console.log(e);
        }

    }

    async function populate_after_accept() {
        try {
            await populate_networks();
            appProps.set_networksrefresh && appProps.set_networksrefresh(true);
        } catch (e) {
            console.log(e);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_networks(limit,offset,sortby,sortorder,archive,filter, filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org',
                        '/get-org-networks/'+JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id,
                        {queryStringParameters: {
                            limit: limit,
                            offset: offset,
                            sortby: sortby,
                            sortorder: sortorder,
                            archive: archive,
                            tz : datelib.timezone,
                            filtername: filtername
                        },
                        body: filter
                    }
                    );
    }

    function API_put_org_network(var_status){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));

        const var_network = {};
        var_network['id'] = var_item.id;
        var_network['organization_id'] = JSON.parse(sessionStorage.getItem('authentication')).organization_id;
        var_network['transactionby'] = JSON.parse(sessionStorage.getItem('authentication')).id;
        var_network['transactionorg'] = JSON.parse(sessionStorage.getItem('authentication')).organization_id;

        return API.put('org', '/put-org-network/'+var_status, { body: var_network });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_networks('',0,sortby,sortorder)
    }

    function onApply_filter(filter){
        populate_networks(null,0,null,null,null,filter);
    }


    function onClick_addorganization(){
        set_mdl_add_open(true);
    }

    function onClick_network(item){
        set_item(item);
        if(item.status === 'REQUESTED'){
            set_mdl_response_open(true);
        }
        if(item.status === 'CONNECTED TO CONTRACTOR'){
            set_mdl_assignment_open(true);
        }
        if(item.status === 'REJECTED'){
            set_confirmationtext(item.organization+' has rejected the request to join your network.  Would you like to resend the invite?');
            set_mdl_confirmation_open(true);
        }
    }

    function resendinvite(){
        update_network('PENDING')
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="content-card" id="crd_org_individuals_individuals">
                <div className="content-card-header">
                    <div className="content-card-header-title">{JSON.parse(sessionStorage.getItem('authentication')).organization} {t('Networks')}</div>
                    <Button className="btn_tertiary" onClick={() => onClick_addorganization()}><img className="btn_icon" src={"/icons/add 30px (4dcbd4).svg?ts=" + Date.now()} alt="add icon"/>{t("ADD ORGANIZATION")}</Button>
                </div>
                <div className="content-card-gridcontent">
                    <div className="content-filter">
                        <div className="content-filter-archive">
                        </div>

                        <div className="content-filter-item item-pagination">
                        <CMP_TABLECONTROLS
                            var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                            total_rows={var_networks && var_networks.length > 0 ? var_networks[0]['totalrows'] : 0} populatefunction={populate_networks}
                            downloadname='Organization Networks' downloadfunction={download_org_networks}
                            var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters} onApply_filter={onApply_filter}
                            table_config={[
                                {name: 'organization', datatype: 'text', labelKey: 'ORGANIZATION', download: true, filter: true},
                                {name: 'status', datatype: 'text', labelKey: 'STATUS', download: true, filter: true}
                            ]}
                        />
                        </div>
                    </div>
                    <div className="tablewrapper">
                        <Table sortable compact className={(var_loading ? " tbl_loading" : "")}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell sorted={var_sortby === 'status' ? var_sortorder : null} onClick={()=>onClick_sort('status')}>{t('STATUS')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'organization' ? var_sortorder : null} onClick={()=>onClick_sort('organization')}>{t('ORGANIZATION')}</Table.HeaderCell>
                                    {/*<Table.HeaderCell sorted={var_sortby === 'individuals' ? var_sortorder : null} onClick={()=>onClick_sort('individuals')}>{t('INDVS')}</Table.HeaderCell>*/}
                                </Table.Row>
                            </Table.Header>
                            {var_ready && var_networks[0]['totalrows'] !== 0 &&
                            <Table.Body>
                                {var_networks.map((item, i) =>
                                    <Table.Row key={i} onClick={()=>onClick_network(item)}>
                                        <Table.Cell >{item.status}</Table.Cell>
                                        <Table.Cell >{item.organization}</Table.Cell>
                                        {/*<Table.Cell >{item.individuals === '' ? 'n/a' : item.individuals}</Table.Cell>*/}
                                    </Table.Row>
                                )}
                            </Table.Body>
                            }
                        </Table>
                        {var_ready && var_networks[0]['totalrows'] === 0 &&
                        <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t('alert icon')}/>{t('there are no networks to display')}</div>
                        }
                    </div>
                    <div className="content-filter">
                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_networks && var_networks.length > 0 ? var_networks[0]['totalrows'] : 0} populatefunction={populate_networks}
                            />
                        </div>
                    </div>
                </div>

                {/***** MODAL: ADD ***********************************************************************************/}

                <Modal id="org-networks_mdl_addorganization"
                       dimmer={'inverted'}
                       open={var_mdl_add_open}
                       onClose={() => set_mdl_add_open(false)}>
                    <MDL_ADD
                        set_mdl_open={set_mdl_add_open}
                        populate_function={populate_networks}
                    />
                </Modal>

                {/***** END MODAL: ADD *******************************************************************************/}

                {/***** MODAL: RESPONSE ******************************************************************************/}

                <Modal id="org-networks_mdl_response"
                       dimmer={'inverted'}
                       open={var_mdl_response_open}
                       onClose={() => set_mdl_response_open(false)}>
                    <MDL_RESPONSE
                        set_mdl_open={set_mdl_response_open}
                        populate_function={populate_after_accept}
                        var_item={var_item}
                    />
                </Modal>

                {/***** END MODAL: RESPONSE **************************************************************************/}

                {/***** MODAL: ASSSIGNMENT ***************************************************************************/}

                <Modal id="org-networks_mdl_assignment"
                       dimmer={'inverted'}
                       open={var_mdl_assignment_open}
                       onClose={() => set_mdl_assignment_open(false)}>
                    <MDL_ASSIGNMENT
                        set_mdl_open={set_mdl_assignment_open}
                        populate_function={populate_networks}
                        var_item={var_item}
                    />
                </Modal>

                {/***** END MODAL: ASSSIGNMENT ***********************************************************************/}

                {/***** MODAL: CONFIRMATION *******************************************************************************/}

                <Modal id="mdl_confirmation"
                       dimmer={'inverted'}
                       open={var_mdl_confirmation_open}
                       onClose={() => set_mdl_confirmation_open(false)}>
                    <MDL_CONFIRMATION
                        set_mdl_open={set_mdl_confirmation_open}
                        var_modaltitle={t('Rejected Network Request')}
                        var_message={var_confirmationtext}
                        confirmation_function={()=>resendinvite()}>
                    </MDL_CONFIRMATION>
                </Modal>

                {/***** END MODAL: CONFIRMATION **************************************************************************/}

            </div>
        </>
    )

}
//  OUTCOMES -----------------------------------------------------------------------------------------------------------
//
//  - displays a modal for the user to select an issuer/credential to add to the pre-approval accept/reject list
//
//  --------------------------------------------------------------------------------------------------------------------

import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { API } from 'aws-amplify';
import { Form, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import '../../../../../../../i18n';
import datelib from '../../../../../../../libs/date-lib';

import CMP_SUCCESS from '../../../../../../../components/cmp_success/cmp_success';

import './mdl_addcredential.css';

function MDL_ADDCREDENTIAL({ var_mdl_open, set_mdl_open, requirement_id, requirement_name, accept_or_reject, populate_function }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_issuers, set_issuers ] = useState([]);
    const [ var_credentials, set_credentials ] = useState([]);
    const [ var_issuer_id, set_issuer_id ] = useState(null);
    const [ var_credential_id, set_credential_id ] = useState(null);
    const [ var_transaction_id, set_transaction_id ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_complete, set_complete ] = useState(false);

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(()=>{
        if (var_mdl_open) {
            set_issuer_id(null);
            set_credential_id(null);
            set_complete(false);
            set_processing(false);
            populate_issuers();
        }
    },[var_mdl_open]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_issuers() {
        try {
            let issuers = await API_get_dd_manage_history_issuers();
            set_issuers(issuers);
        } catch (e) {
            console.log(e);
        }
    }

    async function populate_credentials(issuer_id) {
        try {
            let credentials = await API_get_dd_manage_history_credentials(issuer_id);
            set_credentials(credentials);
        } catch (e) {
            console.log(e);
        }
    }

    async function add_requirement_historical() {
        set_processing(true);
        try {
            let result = await API_post_requirement_historical();
            set_transaction_id(result.transaction_id);
            populate_function && await populate_function();
            set_complete(true);
            set_processing(false);
        } catch (e) {
            console.log(e);
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    async function API_get_dd_manage_history_issuers() {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-requirements','/get-dd-manage-history-issuers/'+requirement_id);
    }

    async function API_get_dd_manage_history_credentials(issuer_id) {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-requirements','/get-dd-manage-history-credentials/'+requirement_id+'/'+issuer_id);
    }

    async function API_post_requirement_historical() {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-requirements','/post-requirement-historical',{
            queryStringParameters: {
                tz : datelib.timezone
            },
            body: {
                requirement_id,
                issuer_id: var_issuer_id,
                credential_id: var_credential_id,
                status: accept_or_reject === 'ACCEPT' ? 'VALID' : 'INVALID',
                transactionorg: JSON.parse(sessionStorage.getItem('authentication')).organization_id
            }
        });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_issuer(event, data) {
        set_issuer_id(data.value);
        set_credential_id(null);
        set_credentials([]);
        populate_credentials(data.value);
    }

    function onChange_credential(event, data) {
        set_credential_id(data.value);
    }

    function onClick_addcredential() {
        if (var_issuer_id && (accept_or_reject === 'REJECT' || var_credential_id)) {
            add_requirement_historical();
        }
    }

    // RENDER APP ======================================================================================================

    return (
        <>{var_complete ? render_success() : render_form()}</>
    );

    function render_form() {
        let cansubmit = var_issuer_id && (accept_or_reject === 'REJECT' || var_credential_id);
        return (
            <div id='mdl_addcredential_container'>
                <div className="modal-header">
                    <div className="modal-header-title">{t('Add Credential')}</div>
                    <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
                </div>
                <div className='modal-content'>
                    <div>{t('Select the credential you want to add to the pre-approved '+(accept_or_reject === 'ACCEPT' ? 'accepted' : 'rejected')+' list.')}</div>
                    <Form>
                        <Form.Group>
                            <Form.Select
                                className='issuer_ids'
                                name='issuer_ids'
                                label={t('ISSUER')}
                                options={var_issuers}
                                value={var_issuer_id}
                                onChange={onChange_issuer}
                                placeholder={t('Issuer...')}
                                fluid
                                selection
                                search
                                disabled={var_processing}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Select
                                className='credential_ids'
                                name='credential_ids'
                                label={t('CREDENTIAL')}
                                options={var_credentials}
                                value={var_credential_id}
                                onChange={onChange_credential}
                                placeholder={t('Credential...')}
                                fluid
                                selection
                                search
                                disabled={var_processing || var_issuer_id === null}
                            />
                        </Form.Group>
                    </Form>
                </div>
                <div className='modal-footer'>
                    <div className='modal-footer-buttons'>
                        <Button className='btn_secondary btn_active' onClick={() => set_mdl_open(false)}>{t('CANCEL')}</Button>
                        <Button disabled={!cansubmit} loading={var_processing} className={'btn_primary' + (cansubmit? ' btn_active' : '')} onClick={onClick_addcredential}>{t('ADD')}</Button>
                    </div>
                </div>
            </div>
        );
    }

    function render_success() {
        return (
            <CMP_SUCCESS title={t('Credential Added')} set_mdl_open={set_mdl_open} success_or_fail='SUCCESS' transaction_id={var_transaction_id} body={
                (var_credential_id === null ? var_issuers.find(item => item.value === var_issuer_id).text : var_credentials.find(item => item.value === var_credential_id).text) +
                t(' has successfully been added to the ') + requirement_name + t(' pre-approval '+(accept_or_reject === 'ACCEPT' ? 'accepted' : 'rejected')+' list')}
            />
        );
    }
}

MDL_ADDCREDENTIAL.propTypes = {
    var_mdl_open: propTypes.bool.isRequired,
    set_mdl_open: propTypes.func.isRequired,
    requirement_id: propTypes.string.isRequired,
    requirement_name: propTypes.string.isRequired,
    accept_or_reject: propTypes.oneOf(['ACCEPT', 'REJECT']).isRequired,
    populate_function: propTypes.func
}

export default MDL_ADDCREDENTIAL;
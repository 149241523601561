import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { Form, Checkbox, Button } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import auth from '../../../libs/auth-lib';

import './mdl_edit.css';

export default function ({var_mdl_open, set_mdl_open, targetentity, targetentity_id, customsecurity_id, accessoptions, refreshfunction}) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_haschanges, set_haschanges ] = useState(false);
    const [ var_record, set_record ] = useState(null);
    const [ var_access, set_access ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);
    // lookup data
    const [ var_individual_options, set_individual_options ] = useState([]);
    const [ var_permission_options, set_permission_options ] = useState([]);
    const [ var_location_options, set_location_options ] = useState([]);

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        set_record(null);
        populate_record();
        populate_lookups();
        set_haschanges(false);
    }, [var_mdl_open]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_record() {
        let record = customsecurity_id ? await API_get_custom_security_record() : {
            targetentity,
            targetentity_id,
            organization_id: auth.organization_id,
            accesstarget: 'INDIVIDUAL',
            accesstarget_id: null,
            area_type: null,
            area_id: null,
            access: JSON.stringify(accessoptions.filter(item => item.isrequired).map(item => item.code)),
            is_only_security_access: 'NO'
        };
        set_record(record);
        let selected_access = JSON.parse(record.access);
        let access = {};
        accessoptions.forEach(item => access[item.code] = selected_access.includes(item.code));
        set_access(access);
    }

    async function populate_lookups() {
        set_individual_options(await API_get_dd_org_active_individual_users());
        set_permission_options(await API_get_dd_permissions());
        set_location_options(await API_get_dd_org_locations());
    }

    async function update_record() {
        set_processing(true);
        await API_post_put_custom_security();
        await refreshfunction();
        set_mdl_open(false);
        set_processing(false);
    }

    function transaction_info() {
        return {
            organization_id: auth.organization_id,
            transactionby: auth.id,
            transactionorg: auth.organization_id
        };
    }

    function display_last_security_error() {
        return var_record.is_only_security_access === 'YES' && !var_access.security;
    }

    function cansave() {
        // can save if changes have been made, an access target has been selected and at least one access checked
        return var_haschanges && var_record.accesstarget_id && accessoptions.some(item => var_access[item.code]) && !display_last_security_error();
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_dd_org_active_individual_users() {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-individuals', '/get-dd-org-active-individual-users/'+auth.organization_id);
    }

    function API_get_dd_permissions() {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('admin', '/get-dd-permissions');
    }

    function API_get_dd_org_locations() {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-locations', '/get-dd-org-locations/'+auth.organization_id);
    }

    function API_get_custom_security_record() {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org', '/get-custom-security-record/'+customsecurity_id);
    }

    function API_post_put_custom_security() {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        if (customsecurity_id) {
            return API.put('org', '/put-custom-security', { body: {transaction_info: transaction_info(), record: var_record}});
        } else {
            return API.post('org', '/post-custom-security', { body: {transaction_info: transaction_info(), record: var_record}});
        }
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_access(access_item) {
        let access = {...var_access};
        access[access_item] = !access[access_item];
        set_access(access);
        let record = {...var_record};
        record.access = JSON.stringify(accessoptions.filter(item => access[item.code]).map(item => item.code));
        set_record(record);
        set_haschanges(true);
    }

    function onChange_individual(event, data) {
        let record = {...var_record};
        record.accesstarget = 'INDIVIDUAL';
        record.accesstarget_id = data.value;
        set_record(record);
        set_haschanges(true);
    }

    function onChange_permission(event, data) {
        let record = {...var_record};
        record.accesstarget = 'PERMISSION';
        record.accesstarget_id = data.value;
        set_record(record);
        set_haschanges(true);
    }

    function onChange_location(event, data) {
        let record = {...var_record};
        let value = (data && data.value) ? data.value : null;
        record.area_type = value ? 'LOCATION' : null;
        record.area_id = value;
        set_record(record);
        set_haschanges(true);
    }

    function onClick_save() {
        if (!var_processing && cansave()) {
            update_record();
        }
    }

    // RENDER APP ======================================================================================================

    return (
        <div className='cmp_customsecurity-mdl_edit-container'>
            <div className='modal-header'><div className='modal-header-title'>{t(customsecurity_id ? 'Edit Security' : 'Add Security')}</div>
                <div className='modal-header-close' onClick={() => set_mdl_open(false)}><img src={'/icons/x 60px (717473).svg?ts=' + Date.now()} alt={t('x icon')} /></div>
            </div>
            <div className='modal-content'>
                {var_record && render_form()}
            </div>
            <div className='modal-footer'>
                <div className='modal-footer-buttons'>
                    <Button disabled={!cansave()} loading={var_processing} className={'btn_primary' + (cansave() ? ' btn_active' : '')} onClick={() => onClick_save()}>{t('SAVE')}</Button>
                </div>
            </div>
        </div>
    );

    function render_form() {
        return (
            <>
                <Form>
                    <Form.Group>
                        <Form.Select
                            className='select_field'
                            id="individuals"
                            name="individuals"
                            label={t('INDIVIDUAL')}
                            placeholder={t('Individuals...')}
                            options={var_individual_options}
                            onChange={onChange_individual}
                            value={var_record.accesstarget === 'INDIVIDUAL' ? var_record.accesstarget_id : null}
                            fluid
                            search
                        />
                        <div className='or_text'>{t('OR')}</div>
                        <Form.Select
                            className='select_field'
                            id="permissions"
                            name="permissions"
                            label={t('ROLE')}
                            placeholder={t('Roles...')}
                            options={var_permission_options}
                            onChange={onChange_permission}
                            value={var_record.accesstarget === 'PERMISSION' ? var_record.accesstarget_id : null}
                            fluid
                            search
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Select
                            className='select_field'
                            id="locations"
                            name="locations"
                            label={t('LOCATION/PROJECT')}
                            placeholder={t('Locations/Projects...')}
                            options={var_location_options}
                            onChange={onChange_location}
                            value={var_record.area_type === 'LOCATION' ? var_record.area_id : null}
                            fluid
                            search
                            clearable
                        />
                    </Form.Group>
                    <Form.Group>
                        <div className='access_checkboxes'>
                            {accessoptions.map(item => 
                                <Checkbox
                                    key={'checkbox-'+item.code}
                                    label={t(item.code + (item.description ? (' - ' + item.description) : ''))}
                                    onChange={() => onChange_access(item.code)}
                                    checked={var_access[item.code]}
                                    toggle
                                    readOnly={item.isrequired}
                                />
                            )}
                        </div>
                    </Form.Group>
                </Form>
                {display_last_security_error() &&
                <div className="message warning">
                    <div className="message_icon"><img src={"/icons/warning 60px (d91e18).svg?ts=" + Date.now()} alt={t('warning icon')} /></div>
                    <div className="message_text_wrapper">
                        <div className="message_text cause">{t('Unable to remove the last security access')}</div>
                    </div>
                </div>}
            </>
        );
    }
}
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Table } from 'semantic-ui-react';
import datelib from '../../../../../../libs/date-lib';

import { useTranslation } from 'react-i18next';
import '../../../../../../i18n';

import CMP_TABLECONTROLS, { filter_helper } from "../../../../../../components/cmp_tablecontrols/cmp_tablecontrols";

import './tab_acknowledged.css';



export default function({ var_activetab, var_document_id }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();
    const [ var_acknowledgements, set_acknowledgements ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(10);
    const [ var_offset, set_offset] = useState(0);
    const [ var_search, set_search ] = useState('');
    const [ var_sortby, set_sortby ] = useState('individual');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_activetab === 'ACKNOWLEDGED') {
            set_filter(filter_helper.initialize(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_activetab]);

    useEffect(()=>{
        if (var_filter.load){
            populate_acknowledgements(null, 0);
        }
    },[var_filter])

    //  async functions ------------------------------------------------------------------------------------------------
    async function populate_acknowledgements(limit,offset,sortby,sortorder,search,filter){
        if (var_activetab !== 'ACKNOWLEDGED') return; // Don't load data if tab is not visible

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        if(search) {
            if(search === '-') {
                search = '';
            }
            set_search(search);
        }else{
            search=var_search
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_org_acknowledgements(limit,offset,sortby,sortorder,search,filter);
            set_acknowledgements(results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername,sortorder){
        try {
        return await API_get_org_acknowledgements(filter_helper.maxFilterItems,0,filtername,var_search,sortorder,{},filtername);
        } catch (e) {
        console.log(e);
        }
    }

    async function download_org_acknowledgements(limit, offset){
        try {
        return await API_get_org_acknowledgements(limit,offset,var_sortby,var_sortorder,var_search,var_filter);
        } catch (e){
        console.log(e);
        throw e;
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_acknowledgements(limit,offset,sortby,sortorder,search,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-documents',
                       '/get-org-acknowledgements/'+var_document_id+'/'+JSON.parse(sessionStorage.getItem('authentication')).organization_id,
                       {
                        queryStringParameters: {
                            limit: limit,
                            offset: offset,
                            sortby: sortby,
                            sortorder: sortorder,
                            search: search,
                            tz: datelib.timezone,
                            filtername: filtername
                        },
                        body: filter
                    }
                );
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_acknowledgements('',0,sortby,sortorder)
    }



    // RENDER APP ======================================================================================================

    return (
        <div className="modal-tab">
            <div className="content-filter">
                <div className="content-filter-item item-pagination">
                    <CMP_TABLECONTROLS
                        var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                        total_rows={var_acknowledgements && var_acknowledgements.length > 0 ? var_acknowledgements[0]['totalrows'] : 0} populatefunction={populate_acknowledgements}
                        downloadname='DOCUMENTS ACKNOWLEDGEMENTS' downloadfunction={download_org_acknowledgements}
                        var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                        table_config={[
                            {name: 'individual', datatype: 'text', labelKey: 'INDIVIDUAL', download: true, filter: true},
                            {name: 'acknowledged', datatype: 'text', labelKey: 'ACKNOWLEDGED', download: true, filter: false},
                            {name: 'epochacknowledged', datatype: 'date', labelKey: 'ACKNOWLEDGED', download: false, filter: true}
                        ]}
                    />
                </div>
            </div>
            <div className="tablewrapper">
                <Table sortable compact className={(var_loading ? " tbl_loading" : "")}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell sorted={var_sortby === 'individual' ? var_sortorder : null} onClick={()=>onClick_sort('individual')}>{t('INDIVIDUAL')}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'acknowledged' ? var_sortorder : null} onClick={()=>onClick_sort('acknowledged')}>{t('ACKNOWLEDGED')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    {var_ready && var_acknowledgements[0]['totalrows'] !== 0 &&
                    <Table.Body>
                        {var_acknowledgements.map((item, i) =>
                            <Table.Row key={i} >
                                <Table.Cell >{item.individual}</Table.Cell>
                                <Table.Cell >{datelib.datetime_localized_format(item.acknowledged)}</Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                    }
                </Table>
                {var_ready && var_acknowledgements[0]['totalrows'] === 0 &&
                <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t('Acknowledged.alt.alert')}/>{t('there are no acknowledgements to display')}</div>
                }
            </div>
            <div className="content-filter">
                <div className="content-filter-item item-pagination">
                    <CMP_TABLECONTROLS
                        var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                        total_rows={var_acknowledgements && var_acknowledgements.length > 0 ? var_acknowledgements[0]['totalrows'] : 0} populatefunction={populate_acknowledgements}
                    />
                </div>
            </div>
        </div>
    )

};

import React, {useEffect, useState} from "react";
import { API } from "aws-amplify";
import { useHistory } from "react-router-dom";

import CRD_REQUEST from "./crd_request/crd_request";
import CRD_REQUESTSENT from "./crd_requestsent/crd_requestsent";
import CRD_RESETVALIDATING from "./crd_resetvalidating/crd_resetvalidating";
import CRD_RESETINVALID from "./crd_resetinvalid/crd_resetinvalid";
import CRD_RESET from "./crd_reset/crd_reset";
import CRD_RESETFAIL from "./crd_resetfail/crd_resetfail";

import "./reset.css";



export default function(appProps) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const history = useHistory();

    const [ var_request, set_request ] = useState([]);
    const [ var_requestresult, set_requestresult ] = useState('');
    const [ var_reset_step, set_reset_step ] = useState('REQUEST');



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect( ()=>{
        if(sessionStorage.getItem('authentication')){
            history.push('/indv/wallet')
        }else{
            appProps.set_visibleframe(false);

            if(window.location.search.substr(1,9) === 'requestid'){
                checkrequest(window.location.search.substr(11))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])



    //  async functions ------------------------------------------------------------------------------------------------

    async function checkrequest(requestid){

        set_reset_step('RESETVALIDATING');

        try {
            let result = await API_get_passwordreset(requestid);
            set_requestresult(result);
            if(result === 'PASS') {
                set_reset_step('RESET');
            }else{
                set_reset_step('RESETINVALID');
            }
        } catch (e) {
            console.log(e);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_passwordreset(requestid){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('admin', '/get-passwordreset/'+requestid);
    }



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return(

        <div className="login_wrapper">
            <div className="login">
                {var_reset_step === 'REQUEST' &&
                <CRD_REQUEST
                    set_reset_step={set_reset_step}
                />
                }

                {var_reset_step === 'REQUESTSENT' &&
                <CRD_REQUESTSENT/>
                }

                {var_reset_step === 'RESETVALIDATING' &&
                <CRD_RESETVALIDATING/>
                }

                {var_reset_step === 'RESETINVALID' &&
                <CRD_RESETINVALID/>
                }

                {var_reset_step === 'RESET' &&
                <CRD_RESET
                    var_requestresult={var_requestresult}
                    var_requestid={window.location.search.substr(11)}
                    var_request={var_request}
                    set_request={set_request}
                    set_reset_step={set_reset_step}
                />
                }

                {var_reset_step === 'RESETFAIL' &&
                <CRD_RESETFAIL/>
                }


            </div>
        </div>
    )


};

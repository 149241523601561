import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input, Modal } from 'semantic-ui-react';
import { API } from 'aws-amplify';
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { useTranslation } from 'react-i18next';
import Moment from 'moment';
import { enCA, frCA } from 'date-fns/locale';

import STA_DAILYCHECK from "./sta_dailycheck/sta_dailycheck";
import STA_CREDENTIALS from "./sta_credentials/sta_credentials";
import STA_ONSITE from "./sta_onsite/sta_onsite";
import MDL_CONFIRMATION from "../../../../../../components/cmp_confirmation/cmp_confirmation";
import MDL_SHARING from "../mdl_sharing/mdl_sharing";

import auth from '../../../../../../libs/auth-lib';
import datelib from '../../../../../../libs/date-lib';
import '../../../../../../i18n';
import './tab_details.css';



export default function({ set_mdl_open, var_activetab, var_location_id, populate_function, set_activetab, onClick_tab, set_org_owns_location }){

    //  variable declarations ------------------------------------------------------------------------------------------

    const QRCode = require('qrcode.react');
    const { t } = useTranslation();

    const [ var_location, set_location] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [ var_date, set_date ] = useState(Moment().utc(false).format('ll'));
    const [ var_default, set_default ] = useState(false);
    const [ var_dailylimit, set_dailylimit ] = useState(false);
    const [ var_ready, set_ready] = useState(false);
    const [ var_processing, set_processing] = useState(false);
    const [ var_default_location, set_default_location ] = useState(false);

    const [ var_questionnaires_dd, set_questionnaires_dd ] = useState([]);
    const [ var_mdl_confirmation_open, set_mdl_confirmation_open ] = useState(false);
    const [ var_mdl_sharing_open, set_mdl_sharing_open ] = useState(false);
    const [ var_required_field, set_required_field ] = useState(false);
    const [ var_startdate, set_startdate ] = useState(null);
    const [ var_enddate, set_enddate ] = useState(null);
    const [ var_readonly, set_readonly ] = useState(true);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_activetab === 'DETAILS') {
            populate_dd_questionnaires();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_activetab]);

    useEffect(() => {
        if(var_location.default_org !== undefined) {

            const location = {...var_location};

            if (var_default) {
                location['default_org'] = 'YES';
            } else {
                location['default_org'] = 'NO';
            }
            set_location(location);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_default]);

    useEffect(() => {
        if(var_location.dailylimit !== undefined) {

            const location = {...var_location};

            if (var_dailylimit) {
                location['dailylimit'] = 'YES';
            } else {
                location['dailylimit'] = 'NO';
            }
            set_location(location);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_dailylimit]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_dd_questionnaires(){
        try {
            let results = await API_get_dd_questionnaires();
            set_questionnaires_dd(results);
            populate_location();
        } catch (e) {
            console.log(e);
        }
    }

    async function populate_location(){
        try {
            let result = await API_get_org_location();
            if(result[0]['default_org'] === 'YES'){
                set_default(true);
                set_default_location(true);
            }else{
                set_default(false);
                set_default_location(false);
            }
            if(result[0]['dailylimit'] === 'YES'){
                set_dailylimit(true);
            }else{
                set_dailylimit(false);
            }

            //  location should not be editable if the location name is 'Guests'
            if(result[0].location_name !== 'Guests') {
                set_readonly(result[0].organization_id !== auth.organization_id);
            }else{
                set_readonly(true);
            }

            set_org_owns_location(result[0].organization_id === auth.organization_id);

            result[0]['organization_id'] = auth.actingorganization_id;
            result[0]['transactionby'] = auth.id;
            result[0]['transactionorg'] = auth.organization_id;
            set_location(result[0]);
            if(result[0]['startdate']){
                set_startdate(new Date(datelib.utcmoment_to_local_midnight_date(datelib.epoch_to_utcmoment(result[0]['startdate']))));
            }
            if(result[0]['enddate']) {
                set_enddate(new Date(datelib.utcmoment_to_local_midnight_date(datelib.epoch_to_utcmoment(result[0]['enddate']))));
            }
        } catch (e) {
            console.log(e);
        }
    }

    async function update_location(){
        if (var_readonly) throw 'Unauthorized';
        set_processing(true);
        try {
            await API_put_org_location();
            if (auth.org_dailycheck){
                update_questionnaire();
            }else {
                set_ready(false);
                set_processing(false);
                populate_location();
                populate_function();
            }
        } catch (e) {
            console.log(e);
        }
    }

    async function update_questionnaire(){
        if (var_readonly) throw 'Unauthorized';
        set_processing(true);
        try {
            await API_put_loc_questionnaire();
            set_ready(false);
            set_processing(false);
            populate_location();
            populate_function();
        } catch (e) {
            console.log(e);
        }
    }

    async function archive_location(){
        if (var_readonly) throw 'Unauthorized';
        try {
            if(var_location.status !== 'ARCHIVE'){
                await API_put_loc_archive('ARCHIVE');
            }else{
                await API_put_loc_archive('ACTIVE');
            }
            populate_location();
            populate_function();
        } catch (e) {
            console.log(e);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_dd_questionnaires(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-dailychecks', '/get-dd-org-questionnaires/' + auth.actingorganization_id);
    }

    function API_get_org_location(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-locations', '/get-org-location/'+var_location_id);
    }

    function API_put_org_location(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.put('org-locations', '/put-org-location/'+var_location_id, { body: var_location });
    }

    function API_put_loc_questionnaire(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.put('org-dailychecks', '/put-loc-questionnaire/'+var_location_id, { body: var_location });
    }

    function API_put_loc_archive(var_archive){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.put('org-locations', '/put-org-archive/'+var_location_id+'/'+var_archive, { body: var_location });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_default(){
        set_default(var_default => !var_default);
        set_ready(true);
    }

    function onChange_input(event){
        const location = { ...var_location };
        location[event.target.name] = event.target.value;
        if(event.target.name==="location_name"){
            set_required_field(false)
        }
        set_location(location);
        set_ready(true);
    }

    function onChange_startdate(event, data){
        if(data.value !== var_startdate) {
            set_startdate(data.value);
            const location = { ...var_location };
            location['startdate'] = data.value ? datelib.localdate_to_utc_midnight_epoch(data.value) : null;
            set_location(location);
            set_ready(true);
        }
    }

    function onChange_enddate(event, data){
        if(data.value !== var_enddate) {
            set_enddate(data.value);
            const location = { ...var_location };
            location['enddate'] = data.value ? datelib.localdate_to_utc_midnight_epoch(data.value) : null;
            set_location(location);
            set_ready(true);
        }
    }

    function onChange_questionnaire_dd(event, { value }){
        const location = { ...var_location };
        location['questionnaire_id'] = value;
        set_location(location);
        set_ready(true);
    }

    function onChange_dailylimit(){
        set_ready(true);
        set_dailylimit(var_dailylimit => !var_dailylimit);
    }

    function onClick_submit(){
        if(var_ready) {
            if (!var_location.location_name || var_location.location_name === '') {
                set_processing(false);
                set_required_field(true);
            }else{
            set_processing(true);
            update_location();
            }
        }
    }

    function onClick_archive(){
        set_mdl_confirmation_open(true);
    }

    function onClick_unarchive(){
        archive_location();
    }

    function onOpen_mdl_sharing(){
        set_mdl_sharing_open(true);
    }

    function onClick_downloadQR(){
        const canvas = document.getElementById("locationQRcode");
        const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = var_location.location_name+' QR.png';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };


    // RENDER APP ======================================================================================================

    return (
        <div className="modal-tab">
            <div className="detailsummary">
                <div className="detailsummary_header">
                    <div className="detailsummary_title">{t("Location/Project Summary")}</div>
                    <div className="detailsummary_subtitle"> {t("as of")} {var_date}</div>
                </div>
                <div className="detailsummary_wrapper">
                    {auth.org_dailycheck &&
                    <div className="detail_wrapper" onClick={() => onClick_tab(`DAILYCHECKS`)}>
                        <STA_DAILYCHECK
                            var_activetab={var_activetab}
                            var_location_id={var_location_id}
                        />
                    </div>
                    }
                    {auth.org_credentials &&
                    <div className="detail_wrapper" onClick={() => onClick_tab(`CREDENTIALS`)}>
                        <STA_CREDENTIALS
                            var_activetab={var_activetab}
                            var_location_id={var_location_id}
                        />
                    </div>
                    }
                    {auth.org_r2w &&
                    <div className="detail_wrapper" onClick={() => onClick_tab(`INDIVIDUALS`)}>
                        <STA_ONSITE
                            var_activetab={var_activetab}
                            var_location_id={var_location_id}
                        />
                    </div>
                    }
                </div>
            </div>

            {(var_location.status === 'ARCHIVE' && !var_readonly && [auth.constants.permission_ids.Admin, auth.constants.permission_ids.GlobalAdmin, auth.constants.permission_ids.Superadmin].includes(auth.permission_id))
            ?    <div className="modal-archive" onClick={() => onClick_unarchive()}>
                    <div className="modal-archive-icon"><img className="btn_icon" src={"/icons/archive 30px (ffffff).svg?ts=" + Date.now()} alt={t("archive icon")}/></div>
                    <div className="modal-archive-text">{t("This location/project has been archived. Click this bar to make it active again.")}
                    </div>
                </div>
            : var_location.status === 'ARCHIVE'
            ?   <div className="modal-archive">
                    <div className="modal-archive-icon"><img className="btn_icon" src={"/icons/archive 30px (ffffff).svg?ts=" + Date.now()} alt={t("archive icon")}/></div>
                    <div className="modal-archive-text">{t("This location/project has been archived.")}
                    </div>
                </div>
            : null
            }

            <div id="tab_details">
                <Form>
                    <div className="details_left">
                        {auth.permission_id === auth.constants.permission_ids.Superadmin &&
                        <Form.Group>
                            <Checkbox
                                toggle
                                className={"default " + (var_default ? ' toggle_active' : '')}
                                label={auth.organization + ' ' + t('default location/project')}
                                onChange={onChange_default}
                                checked={var_default || false}
                                disabled={var_location.status === 'ARCHIVE' || var_readonly}
                            />
                        </Form.Group>
                        }
                        <Form.Group>
                            <Form.Field
                                className='code'
                                name='code'
                                value={var_location.code || ''}
                                control={Input}
                                onChange={onChange_input}
                                label={t("CODE")}
                                placeholder={t("Code...")}
                                readOnly={var_readonly}
                            />
                            <div className="form-spacer"></div>
                            <Form.Field
                                className='location_name'
                                name='location_name'
                                value={var_location.location_name || ''}
                                control={Input}
                                onChange={onChange_input}
                                label={t("LOCATION/PROJECT NAME")}
                                placeholder={t("Location/Project name...")}
                                readOnly={var_readonly}
                            />
                        </Form.Group>
                        <Form.Group className="date_fields">
                            <SemanticDatepicker className='startdate'
                                                locale={auth.language.datepicker}
                                                format={auth.language.name === 'French' ? 'D MMM YYYY' : 'MMM DD, YYYY'}
                                                formatOptions={{locale: auth.language.name === 'French' ? frCA: enCA}}
                                                name='startdate'
                                                onChange={onChange_startdate}
                                                clearable={true}
                                                datePickerOnly={true}
                                                showToday={false}
                                                maxDate = { var_enddate ? new Date(var_enddate) : null}
                                                label={t('START DATE')}
                                                placeholder={t('Start date...')}
                                                disabled={var_processing}
                                                value={var_startdate}/>
                            <SemanticDatepicker className='enddate'
                                                locale={auth.language.datepicker}
                                                format={auth.language.name === 'French' ? 'D MMM YYYY' : 'MMM DD, YYYY'}
                                                formatOptions={{locale: auth.language.name === 'French' ? frCA: enCA}}
                                                name='enddate'
                                                onChange={onChange_enddate}
                                                clearable={true}
                                                datePickerOnly={true}
                                                showToday={false}
                                                minDate = { new Date(var_startdate) }
                                                label={t('END DATE')}
                                                placeholder={t('End date...')}
                                                disabled={var_processing}
                                                value={var_enddate}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field
                                className='area'
                                name='area'
                                value={var_location.area || ''}
                                control={Input}
                                onChange={onChange_input}
                                label={t("AREA")}
                                placeholder={t("Area...")}
                            />
                            <Form.Field
                                className='businessunit'
                                name='businessunit'
                                value={var_location.businessunit || ''}
                                control={Input}
                                onChange={onChange_input}
                                label={t("BUSINESS UNIT")}
                                placeholder={t("Business unit...")}
                            />
                            <Form.Field
                                className='costobject'
                                name='costobject'
                                value={var_location.costobject || ''}
                                control={Input}
                                onChange={onChange_input}
                                label={t("COST OBJECT")}
                                placeholder={t("Cost object...")}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Field
                                className='address'
                                name='address'
                                value={var_location.address || ''}
                                control={Input}
                                onChange={onChange_input}
                                label={t("ADDRESS")}
                                placeholder={t("Address...")}
                                readOnly={var_readonly}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Field
                                className='city'
                                name='city'
                                value={var_location.city || ''}
                                control={Input}
                                onChange={onChange_input}
                                label={t("CITY")}
                                placeholder={t("City...")}
                                readOnly={var_readonly}
                            />
                            <div className="form-spacer"></div>
                            <Form.Field
                                className='province'
                                name='province'
                                value={var_location.province || ''}
                                control={Input}
                                onChange={onChange_input}
                                label={t("STATE/PROVINCE")}
                                placeholder={t("State/Province...")}
                                readOnly={var_readonly}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Field
                                className='country'
                                name='country'
                                value={var_location.country || ''}
                                control={Input}
                                onChange={onChange_input}
                                label={t("COUNTRY")}
                                placeholder={t("Country...")}
                                readOnly={var_readonly}
                            />
                        </Form.Group>
                        {var_required_field=== true &&
                            <div id="reqfielderror" className="message warning">
                                <div className="message_icon"><img src={"/icons/warning 60px (d91e18).svg?ts=" + Date.now()} alt={t('warning icon')} /></div>
                                <div className="message_text_wrapper">
                                    <div className="message_text cause">{t('Please provide a name for this location/project')}</div>
                                </div>
                            </div>
                            }

                    </div>

                    <div className="details_right">
                            {auth.org_dailycheck &&
                            <>
                                <Form.Group>
                                    <Form.Select
                                        className='questionnaire_id'
                                        id='questionnaire_id'
                                        name='questionnaire_id'
                                        label={t("DEFAULT QUESTIONNAIRE")}
                                        search
                                        options={var_questionnaires_dd}
                                        value={var_location.questionnaire_id || ''}
                                        onChange={onChange_questionnaire_dd}
                                        placeholder={t("Select a default questionnaire...")}
                                        disabled={var_readonly}
                                    />
                                </Form.Group>
                                {var_location.questionnaire_id !== '' && var_location.questionnaire_id !== null &&
                                    <Form.Group>
                                        <Checkbox
                                            toggle
                                            className={"dailylimit" + (var_dailylimit ? ' toggle_active' : '')}
                                            label={var_dailylimit ? t('Limit one check per day') : t("No daily limit")}
                                            onChange={onChange_dailylimit}
                                            checked={var_dailylimit || false}
                                            disabled={var_readonly}
                                        />
                                    </Form.Group>
                                }
                            </>
                            }

                        {auth.org_qraccess &&
                        <div>
                            <div className="qrcode-label">{t("LOCATION/PROJECT QR ACCESS CODE (CLICK TO DOWNLOAD)")}</div>
                            <QRCode
                                onClick={() => onClick_downloadQR()}
                                className="qrcode"
                                id="locationQRcode"
                                value={auth.org_host + '/login?location_id='+var_location_id}
                                size={200}
                                level={"H"}
                                includeMargin={false}
                            />
                            <div className="qrcode-label">{t("QR ACCESS CODE URL")}</div>
                            <div className="qraddress">{auth.org_host + '/login?location_id='+var_location_id}</div>
                        </div>
                        }
                    </div>
                </Form>
            </div>

            {[auth.constants.permission_ids.Admin, auth.constants.permission_ids.GlobalAdmin, auth.constants.permission_ids.Superadmin].includes(auth.permission_id) && !var_readonly &&
                <div className="modal-footer">

                    {var_location.status !== 'ARCHIVE' &&
                    <div className="modal-footer-archive">
                        <Button className="btn_tertiary" disabled={var_default_location === true} onClick={() => onClick_archive()}><img className="btn_icon" src={"/icons/archive 30px (4dcbd4).svg?ts=" + Date.now()} alt={t("archive icon")}/>{t("ARCHIVE LOCATION/PROJECT")}</Button>
                    </div>
                    }

                    <div className="modal-footer-share">
                        <Button className="btn_tertiary" onClick={() => onOpen_mdl_sharing()}><img className="btn_icon" src={"/icons/share 60px (4dcbd4).svg?ts=" + Date.now()} alt={t("share icon")}/>{t("SHARE LOCATION/PROJECT")}</Button>
                    </div>

                    <div className="modal-footer-buttons">
                        <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>{t("CANCEL")}</Button>
                        {!var_processing ?
                            <Button className={"btn_primary " + (var_ready ? 'btn_active' : '')} onClick={() => onClick_submit()}>{t("UPDATE LOCATION/PROJECT")}</Button>
                            :
                            <Button loading className="btn_primary btn_active">{t("UPDATE LOCATION/PROJECT")}</Button>
                        }
                    </div>
                </div>
            }



            {/***** MODAL: CONFIRMATION ******************************************************************************/}

            <Modal id="mdl_confirmation"
                   dimmer={'inverted'}
                   open={var_mdl_confirmation_open}
                   onClose={() => set_mdl_confirmation_open(false)}>
                <MDL_CONFIRMATION
                    set_mdl_open={set_mdl_confirmation_open}
                    var_modaltitle={t("Archive Location/Project")}
                    var_message={t("Are you sure you want to archive this location/project?")}
                    confirmation_function={archive_location}>
                </MDL_CONFIRMATION>
            </Modal>

            {/***** END MODAL: CONFIRMATION **************************************************************************/}


            {/***** MODAL: SHARING *******************************************************************************/}

            <Modal id="mdl_sharing"
                dimmer={'inverted'}
                open={var_mdl_sharing_open}
                onClose={() => set_mdl_sharing_open(false)}>
                <MDL_SHARING
                    set_mdl_open={set_mdl_sharing_open}
                    var_location_id={var_location_id}>
                </MDL_SHARING>
            </Modal>

            {/***** END MODAL: SHARING ***************************************************************************/}


        </div>
    )

};

import React, { useState } from "react";

import './mdl_update.css';
import TAB_DETAILS from "./tab_details/tab_details";
import TAB_ASSIGNEDTO from "./tab_assignedto/tab_assignedto";

import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

export default function({ set_mdl_open, var_location_id, form, onClick_paperclip }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();
    const [ var_activetab, set_activetab] = useState('DETAILS');


    //  variable listeners ---------------------------------------------------------------------------------------------



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_tab(var_tab){ 
        set_activetab(var_tab);
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{t('Update Form')}</div>
                <div className="modal-header-tabs">
                    <div className={"modal-header-tabs-item"  + (var_activetab === 'DETAILS' ? ' active' : '')} onClick={() => onClick_tab(`DETAILS`)}>
                    {t('Details')}
                    </div>
                    <div className={"modal-header-tabs-item"  + (var_activetab === 'ASSIGNEDTO' ? ' active' : '')} onClick={() => onClick_tab(`ASSIGNEDTO`)}>
                    {t('Assigned To')}
                    </div>
                </div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t('close icon')}/></div>
            </div>
            <div className="modal-tabs">
            <div className={(var_activetab !== 'DETAILS' ? 'modal-tab-hide' : '')}>
                    <TAB_DETAILS
                        onClick_paperclip={onClick_paperclip}
                        form={form}>
                    </TAB_DETAILS>
                </div>
                <div className={(var_activetab !== 'ASSIGNEDTO' ? 'modal-tab-hide' : '')}>
                    <TAB_ASSIGNEDTO
                        var_activetab={var_activetab}
                        var_location_id={var_location_id}
                        form={form}>
                    </TAB_ASSIGNEDTO>
                </div>
            </div>

        </>
    )
}
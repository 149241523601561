import {Button, Dropdown, Checkbox} from 'semantic-ui-react'
import { API } from "aws-amplify";
import { useEffect, useState, Component } from "react";
import React from "react";
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import StepZilla from 'react-stepzilla';
import '../../../../../../../i18n';
import datelib from '../../../../../../../libs/date-lib';

import './mdl_add.css';

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

export default function({set_mdl_open, populate_function, var_location_id, set_mdl_id}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_selectedrequirement, set_selectedrequirement ] = useState([]);
    const [ var_finalrequirements, set_finalrequirements ] = useState([]);
    const [ var_finalrequirements_no_preapproved, set_finalrequirements_no_preapproved ] = useState('');
    const [ var_selectedpackage, set_selectedpackage ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_api_error, set_api_error ] = useState(false);
    const [ var_dd_requirements, set_dd_requirements ] = useState([]);
    const [ var_dd_requirements_no_preapproved, set_dd_requirements_no_preapproved ] = useState([]);    
    const [ var_dd_selected_requirements, set_dd_selected_requirements ] = useState([]);
    const [ var_dd_packages, set_dd_packages ] = useState([]);
    const [ var_selectedindividual, set_selectedindividual ] = useState([]);
    const [ var_dd_individuals, set_dd_individuals ] = useState([]);
    const [ var_all_individuals, set_all_individuals ] = useState(false);
    const [ var_previous_step, set_previous_step ] = useState(0);
    const [ var_step2a_ready, set_step2a_ready ] = useState(false);
    const [ var_step2b_ready, set_step2b_ready ] = useState(false);
    const [ var_step3_ready, set_step3_ready ] = useState(false);
    const [ var_transactions, set_transactions ] = useState([]);

    class Step1 extends Component {
        render() {
            return <div className="requirements-modal">
                <div className="modal-header">
                    <div className="modal-header-title">{t("Assign Requirements")}</div>
                    <div className="modal-header-close" onClick={() => onClick_close()}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
                </div>

                <div className="modal-content">
                    <div className='step-form'>
                        {t("Do you wish to add a single requirement or a requirement package?")}
                    </div>
                    <div className='step-action'>
                        <Button
                            className={"btn_primary btn_active"}
                            onClick={() => {this.props.jumpToStep(1); set_previous_step(0);}}
                        >{t("REQUIREMENT(S)")}</Button>
                        <Button
                            className={"btn_secondary"}
                            onClick={() => {this.props.jumpToStep(2); set_previous_step(0);}}
                        >{t("PACKAGE(S)")}</Button>
                    </div>
                </div>
            </div>
        }
    }

    class Step2a extends Component {
        render() {
            return <div className="requirements-modal">
                <div className="modal-header">
                    <div className="modal-header-title">{t("Select Requirement")}</div>
                    <div className="modal-header-close" onClick={() => onClick_close()}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
                </div>

                <div className="modal-content">
                    <Dropdown
                        className="requirement_id"
                        id='requirement_id'
                        name="requirement_id"
                        label={t("REQUIREMENT")}
                        options={var_dd_requirements}
                        value={var_selectedrequirement || []}
                        onChange={onChange_requirement}
                        placeholder={t("Requirements...")}
                        fluid
                        multiple
                        selection
                    />
                </div>

                {var_finalrequirements_no_preapproved.length > 0 &&
                <div id="requirement_warning" className="message warning">
                    <div className="message_icon"><img src={"/icons/warning 60px (d91e18).svg?ts=" + Date.now()} alt={t('warning icon')} /></div>
                    <div className="message_text_wrapper">
                        <div className="message_text cause">{t('None of the requirements in')} {var_finalrequirements_no_preapproved} {t('have any pre-approved credentials so automatic validation is not possible. Pre-approved credentials can be added in the ')}
                            <a href={"/org/requirements/setup"}>{t('Requirements Setup')}</a> {t('page')}.
                        </div>
                    </div>
                </div>}

                <div className="modal-footer">
                    <div className="modal-footer-buttons">
                        <Button
                            className='btn_secondary go-back'
                            onClick={() => {this.props.jumpToStep(0)}}
                        >{t("GO BACK")}</Button>
                        <Button
                            className={"btn_primary " + (var_step2a_ready ? ' btn_active' : '')}
                            disabled={(var_step2a_ready ? false : true)}
                            onClick={() => {this.props.jumpToStep(3); set_previous_step(1);}}
                        >{t("CONTINUE")}</Button>
                    </div>
                </div>
            </div>
        }
    }

    class Step2b extends Component {
        render() {
            return <div className="requirements-modal">
                <div className="modal-header">
                    <div className="modal-header-title">{t("Select Package")}</div>
                    <div className="modal-header-close" onClick={() => onClick_close()}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
                </div>

                <div className="modal-content">
                    <div className='step-form'>
                        <Dropdown
                            className="package_id"
                            id='package_id'
                            name="package_id"
                            label={t("PACKAGE")}
                            options={var_dd_packages}
                            value={var_selectedpackage || ''}
                            onChange={onChange_package}
                            placeholder={t("Packages...")}
                            fluid
                            multiple
                            selection
                        />
                    </div>
                    { var_dd_selected_requirements.length > 0 &&
                    <div className='step-form-detail'>
                        {t("If you select this package, the following requirements will be assigned:")}
                        <ul>
                        { var_dd_selected_requirements.map((item, i) =>
                            <li key={i}>{item.name}</li>
                        )}
                        </ul>
                    </div>
                    }
                    { var_processing &&
                        <div className="ui active centered inline loader"></div>
                    }
                </div>

                <div className="modal-footer">
                    <div className="modal-footer-buttons">
                        <Button
                            className='btn_secondary go-back'
                            onClick={() => {this.props.jumpToStep(0)}}
                        >{t("GO BACK")}</Button>
                        <Button
                            className={"btn_primary " + (var_step2b_ready ? ' btn_active' : '')}
                            disabled={(var_step2b_ready ? false : true)}
                            onClick={() => {this.props.jumpToStep(3); set_previous_step(2);}}
                        >{t("CONTINUE")}</Button>
                    </div>
                </div>
            </div>
        }
    }

    class Step3 extends Component {
        render() {
            return <div className="requirements-modal">
                <div className="modal-header">
                    <div className="modal-header-title">{t("Select Recipient")}</div>
                    {(!var_processing || var_api_error) ?
                        <div className="modal-header-close" onClick={() => onClick_close()}><img
                            src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")}/></div>
                        :
                        <div className="modal-header-close"></div>
                    }
                </div>

                <div className="modal-content">
                    <Dropdown
                        className='individual_id'
                        id='individual_id'
                        name='individual_id'
                        label={t("INDIVIDUAL")}
                        options={var_dd_individuals}
                        value={var_selectedindividual || []}
                        onChange={onChange_individual}
                        placeholder={t("Select an individual...")}
                        multiple
                        selection
                        search
                        fluid
                        disabled={var_all_individuals}
                    />

                    <Checkbox
                        className='add_to_all'
                        label='Add to everyone in location/project'
                        checked={var_all_individuals || false}
                        onChange={onChange_all_individuals}
                    />

                </div>

                <div className="modal-footer">
                    <div className="modal-footer-buttons">
                        <Button
                            className='btn_tertiary go-back'
                            onClick={() => {this.props.jumpToStep(var_previous_step)}}
                            disabled={var_processing}
                        >{t("GO BACK")}</Button>

                        {!var_processing ?
                            <Button
                                className={"btn_primary " + (var_step3_ready ? ' btn_active' : '')}
                                disabled={(var_step3_ready ? false : true)}
                                onClick={() => {onClick_assign_requirements(this)}}
                            >{t("ASSIGN REQUIREMENTS")}</Button>
                            :
                            <Button
                            className="btn_primary btn_active"
                            loading
                            >{t("ASSIGN REQUIREMENTS")}</Button>
                        }
                    </div>
                </div>
            </div>
        }
    }

    class Step4 extends Component {
        render() {
            return <div className="requirements-modal">
                <div className="modal-header">
                    <div className="modal-header-title">{t("Requirement Assignment Complete")}</div>
                    <div className="modal-header-close" onClick={() => onClick_close()}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
                </div>

                <div className="modal-content">
                    <div><img src={"/icons/checkmark 60px (549E17).svg?ts=" + Date.now()} alt={t("checkmark icon")}/></div>
                    <div className="summary-data">
                        <h3>{t("REQUIREMENTS ASSIGNED")}</h3>
                        <p>{t("The following requirements have been assigned:")}</p>
                        <div className="scrolling">
                            {var_transactions.map((item, i) =>
                                <div className='transaction-record' key={i}>
                                    <strong>{item.requirement.name} - {item.individual.firstname} {item.individual.lastname}</strong>
                                    <p>{t("Ledger Transaction ID")}: {item.transaction.id}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="modal-footer">
                    <div className="modal-footer-buttons">
                        <Button
                            className={"btn_primary " + (var_step3_ready ? ' btn_active' : '')}
                            disabled={(var_step3_ready ? false : true)}
                            onClick={() => onClick_close()}
                        >{t("CLOSE")}</Button>
                    </div>
                </div>
            </div>
        }
    }

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_dd_requirements();
        populate_dd_packages();
        populate_dd_individuals();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_dd_requirements(){
        try {
            let results = await API_get_dd_requirements();
            set_dd_requirements(results);

            if (results) {
                set_dd_requirements_no_preapproved(results.filter(item => item.total_preapproved === 0));
            }
        } catch (e) {
            console.log(e);
        }
    }

    async function populate_dd_packages(){
        try {
            let results = await API_get_dd_packages();
            set_dd_packages(results);
        } catch (e) {
            console.log(e);
        }
    }

    async function populate_dd_individuals(){
        try {
            let results = await API_get_dd_individuals();
            set_dd_individuals(results);
        } catch (e) {
            console.log(e);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_dd_requirements(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-credentials', '/get-dd-loc-availablerequirements/'+var_location_id);
    }

    function API_get_dd_packages(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-requirements', '/get-dd-loc-availablepackages/'+var_location_id);
    }

    function API_get_package_requirements(package_id){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-requirements', '/get-package-requirements/'+package_id,
            {
                queryStringParameters: {
                    limit: 10000,
                    offset: 0,
                    sortby: 'id',
                    sortorder: 'ascending'
                }
            }
        );
    }

    function API_get_requirement(requirement_id){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-requirements', '/get-requirement/'+requirement_id);
    }

    function API_post_indv_requirement_assignment(data){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('indv-credentials', '/post-indv-requirement-assignment', { body: data});
    }

    function API_post_loc_requirement_assignment(data){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-locations', '/post-loc-requirement-assignment', { body: data});
    }

    function API_post_indv_requirement(data){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('indv-credentials', '/post-indv-requirement', 
        {
            queryStringParameters: {
                tz : datelib.timezone
            },
            body: data
        });
    }

    function API_get_dd_individuals(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-locations', '/get-dd-individuals-users/'+var_location_id);
    }
    function API_get_individual(id){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-requirements', '/get-indv/'+id);
    }

    //  event functions ------------------------------------------------------------------------------------------------

    async function onChange_requirement(e, { value }){
        let selected_requirements = [];
        for (let requirement of value) {
            let result = await API_get_requirement(requirement);
            selected_requirements.push(result);
        }
        set_finalrequirements(selected_requirements);

        let requirements_text_no_preapproved = var_dd_requirements_no_preapproved.filter(item => value.includes(item.value))?.map(item => item.text.trim());
        set_finalrequirements_no_preapproved(requirements_text_no_preapproved?.join(', '));
        
        set_selectedrequirement(value);
        if(value && value.length > 0) {
            set_step2a_ready(true);
        } else {
            set_step2a_ready(false);
        }
    }

    async function onChange_package(e, {value}) {
        let requirement_ids = [];
        let selected_requirements = [];
        set_processing(true);
        set_selectedpackage(value);
        for (let selected_package of value) {
            let result = await API_get_package_requirements(selected_package);
            for (let requirement of (result.results || [])) {
                requirement_ids.push(requirement.requirement_id);
            }
        }
        requirement_ids = requirement_ids.filter(onlyUnique);
        for (let requirement of requirement_ids) {
            let result = await API_get_requirement(requirement);
            selected_requirements.push(result);
        }
        set_finalrequirements(selected_requirements);
        set_dd_selected_requirements(selected_requirements);
        if(selected_requirements && selected_requirements.length > 0) {
            set_step2b_ready(true);
        } else {
            set_step2b_ready(false);
        }
        set_processing(false);
    }

    function onChange_individual(e, { value }){
        set_selectedindividual(value);
        if(value && value.length > 0) {
            set_step3_ready(true);
        } else {
            set_step3_ready(false);
        }
    }

    function onChange_all_individuals(){
        set_all_individuals(!var_all_individuals);
        if(!var_all_individuals) {
            set_selectedindividual([]);
        }
        if(!var_all_individuals) {
            set_step3_ready(true);
        } else {
            set_step3_ready(false);
        }
    }

    async function onClick_assign_requirements(self) {
        set_processing(true);

        // Get list of individuals
        let individuals = [];
        if (var_all_individuals) {
            for (let indv of var_dd_individuals) {
                individuals.push(indv)
            }
        } else {
            individuals = var_selectedindividual;
        }

        // Get list of requirements
        let requirements = var_finalrequirements;

        // Iterate through each requirement, adding it to each individual
        let transactions = [];
        for (let requirement of requirements) {
            let requirementDetail = await API_get_requirement(requirement.id);
            if (var_all_individuals) {
                let reqdata = {};
                reqdata['requirement_id'] = requirement.id;
                reqdata['location_id'] = var_location_id;
                reqdata['organization_id'] = JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id;
                reqdata['transactionby'] = JSON.parse(sessionStorage.getItem('authentication')).id;
                reqdata['transactionorg'] = JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id;
                try {
                    await API_post_loc_requirement_assignment(reqdata);
                } catch (e) {
                    set_api_error(true);
                    throw e;
                }
            }
            for (let individual of individuals) {
                try {
                    let data = {};
                    let individual_id;
                    if (typeof individual === 'object' && individual !== null) {
                        individual_id = individual.value;
                    } else {
                        individual_id = individual;
                    }
                    data['requirement_id'] = requirement.id;
                    data['targetentity'] = 'LOCATION';
                    data['targetentity_id'] = var_location_id;
                    data['individual_id'] = individual_id;
                    data['organization_id'] = JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id;
                    data['transactionby'] = JSON.parse(sessionStorage.getItem('authentication')).id;
                    data['transactionorg'] = JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id;

                    if (!var_all_individuals) {
                        await API_post_indv_requirement_assignment(data);
                    }
                    let result = await API_post_indv_requirement(data);
                    let individualDetails = await API_get_individual(individual_id);

                    await transactions.push({requirement: requirementDetail, individual: individualDetails[0], transaction: {id: result.id}});
                } catch (e) {
                    console.log(e);
                }
            }
        }
        set_transactions(transactions);

        self.props.jumpToStep(4);
        set_mdl_id('org-locations_mdl_addrequirement-wide');
        set_processing(false);
    }

    async function onClick_close() {
        populate_function();
        set_mdl_open(false);
    }

    // RENDER APP ======================================================================================================

    const steps =
        [
            {name: 'Step1', component: <Step1 />},
            {name: 'Step2a', component: <Step2a />},
            {name: 'Step2b', component: <Step2b />},
            {name: 'Step3', component: <Step3 />},
            {name: 'Step4', component: <Step4 />}
        ]

    return (
        <>
            <StepZilla steps={steps}
                       showSteps={false}
                       showNavigation={false}
            />
        </>
    )
}
import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Progress } from 'semantic-ui-react';
import { API } from 'aws-amplify';


import './cmp_bulkdownload_status.css';

function CMP_BULKDOWNLOAD_STATUS({ bulkdownload_id, onError, onComplete }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_progress, set_progress ] = useState(0);
    const [ var_status, set_status ] = useState('CREATING');

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        const var_interval = setInterval(() => {
            if (var_status === 'CREATING') {
                check_status();
            }
        }, 750); // 0.75 seconds
        return () => clearInterval(var_interval);
    }, []);


    //  async functions ------------------------------------------------------------------------------------------------

    async function check_status() {
        try {
            let status = await API_get_bulkdownload_status();
            set_progress(status.create_progress_percent);
            set_status(status.status);
            status.status === 'ERROR' && onError && onError();
            status.status === 'ACTIVE' && onComplete && onComplete();
        } catch (exception) {
            console.log(exception);
            set_status('ERROR');
            onError && onError();
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_bulkdownload_status() {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.get('storage', '/get-bulkdownload-status/' + bulkdownload_id);
    }


    //  event functions ------------------------------------------------------------------------------------------------


    // RENDER APP ======================================================================================================

    return (
        <div className='cmp-bulkdownload-wrapper'>
            <Progress percent={var_progress} progress/>
        </div>
    )
}

CMP_BULKDOWNLOAD_STATUS.propTypes = {
    bulkdownload_id: propTypes.string.isRequired,
    onError: propTypes.func,
    onComplete: propTypes.func
}

export default CMP_BULKDOWNLOAD_STATUS;
//  OUTCOMES -----------------------------------------------------------------------------------------------------------
// 
//  - the user can insert a new requirement for the ORG
//
//  --------------------------------------------------------------------------------------------------------------------

import { API } from 'aws-amplify';
import React, { useEffect, useState} from 'react';
import { Button, Checkbox, Form, Input } from 'semantic-ui-react';
import form_helper from '../../../../../libs/form-lib';
import auth from '../../../../../libs/auth-lib';
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import './mdl_add.css';

export default function({ set_mdl_open, populate_function, var_organization_id }){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();

    const [ var_requirement, set_requirement ] = useState({status: 'ACTIVE'});
    const [ var_status, set_status ] = useState(true);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_validationerror, set_validationerror ] = useState(false);
    const [ var_validationerrormessage, set_validationerrormessage]  = useState('');
    const [ var_descriptioncount, set_descriptioncount ] = useState(0);


    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_requirement.transactionby === undefined){
            const requirement = {...var_requirement};
            requirement['organization_id'] = var_organization_id;
            requirement['transactionby'] = auth.id;
            requirement['transactionorg'] = auth.organization_id;
            set_requirement(requirement);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_requirement]);

    useEffect(() => {
        const requirement = { ...var_requirement };
        if(var_status) {
            requirement['status'] = 'ACTIVE';
        }else{
            requirement['status'] = 'ARCHIVE';
        }
        set_requirement(requirement);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_status]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function insert_requirement() {
        let requirement = { ...var_requirement };
        form_helper.cleanse_string_property(requirement, 'name');
        form_helper.cleanse_string_property(requirement, 'description');
        try {
            await API_post_requirement(requirement);
            set_ready(false);
            set_processing(false);
            set_mdl_open(false);
            populate_function();
        } catch (e) {
            console.log(e);
        }

    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_requirement(requirement){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-requirements', '/post-requirement/', { body: requirement });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_submit(){
        if(var_ready){
            let var_validation = false;
            let var_validmessage = '';
            if (!form_helper.validate_required_string(var_requirement.name)) {
                var_validation = true;
                var_validmessage = 'Please provide a name for this requirement';
            }
            set_validationerrormessage(var_validmessage);
            set_validationerror(var_validation);
            if (!var_validation) {
                set_processing(true);
                insert_requirement();
            }
        }
    }

    function onChange_input(event){
        const requirement = { ...var_requirement };
        if (event.target.value === ''){
            requirement[event.target.name] = null;
        }else{
            requirement[event.target.name] = event.target.value.substring(0,500);
        }
        set_requirement(requirement);
        set_validationerror(false);
        set_ready(true);
    }

    function onChange_status(){
        set_status(!var_status);
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{t('Add Requirement')}</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
            </div>
            {auth.has_access('ORG-REQ', 'manage_req') &&
            <div className="modal-content">
                <Form>
                    <Form.Group>
                        <Checkbox
                            toggle
                            className={"status " + (var_status ? ' toggle_active' : '')}
                            label={var_status ? t('ACTIVE') : t('ARCHIVE')}
                            onChange={onChange_status}
                            checked={var_status || false}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                            className="name"
                            name="name"
                            value={var_requirement.name || ''}
                            control={Input}
                            onChange={onChange_input}
                            label={t('REQUIREMENT NAME')}
                            placeholder={t('Requirement name...')}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.TextArea
                            className="description"
                            name="description"
                            value={var_requirement.description || ''}
                            onChange={e => {onChange_input(e); set_descriptioncount(e.target.value.length)}}
                            label={t('DESCRIPTION')}
                            placeholder={t('Description...')}
                            maxLength={500}
                        />
                    </Form.Group>
                    <div className='count'>{var_descriptioncount}/500</div>
                </Form>
                {var_validationerror &&
                    <div className="message warning">
                        <div className="message_icon"><img src={"/icons/warning 60px (d91e18).svg?ts=" + Date.now()} alt={t('warning icon')} /></div>
                        <div className="message_text_wrapper">
                            <div className="message_text cause">{t(var_validationerrormessage)}</div>
                        </div>
                    </div>
                }
            </div>
            }
            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>{t('CANCEL')}</Button>
                    {!var_processing ?
                        <Button className={"btn_primary " + (var_ready ? ' btn_active' : '')} disabled={!var_ready} onClick={() => onClick_submit()}>{t('ADD REQUIREMENT')}</Button>
                        :
                        <Button loading className="btn_primary btn_active">{t('ADD REQUIREMENT')}</Button>
                    }
                </div>
            </div>
        </>
    )
}
import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { Table, Button, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import '../../../../../../i18n';

import CMP_TABLECONTROLS, {filter_helper} from '../../../../../../components/cmp_tablecontrols/cmp_tablecontrols';
import MDL_ADDREQUIREMENT from './mdl_addrequirement/mdl_addrequirement';

import './tab_requirements.css';

export default function ({ var_activetab, package_id, populate_function }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_records, set_records ] = useState(null);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(10);
    const [ var_offset, set_offset] = useState(0);
    const [ var_sortby, set_sortby ] = useState('requirement');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));

    const [ var_mdl_addrequirement_open, set_mdl_addrequirement_open ] = useState(false);

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(()=>{
        if (var_activetab === 'REQUIREMENTS') {
            set_filter(filter_helper.initialize(false));
        }
    },[var_activetab]);

    useEffect(()=>{
        if (var_filter.load){
            populate_records(null,0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_filter]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_records(limit,offset,sortby,sortorder,filter){
        if (var_activetab !== 'REQUIREMENTS') return; // Don't load data if tab is not visible

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_package_requirements(limit,offset,sortby,sortorder,filter);
            set_totalrows(results.totalrows);
            set_records(results.results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
    }

    async function populate_filters(filtername,sortorder){
        try {
            return (await API_get_package_requirements(filter_helper.maxFilterItems,0,filtername,sortorder,{},filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_results(limit,offset){
        try {
            return (await API_get_package_requirements(limit,offset,var_sortby,var_sortorder,var_filter)).results;
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    async function addrequirement(id) {
        let requirements = [{
            package_id,
            requirement_id: id
        }];
        await API_post_package_requirements(requirements);
        await populate_records();
        populate_function && populate_function();
    }

    async function removerequirement(id) {
        await API_delete_package_requirement(id);
        // repopulate records.  If user was on the last page and the only item on the page was removed then display the previous page
        if (var_offset < (var_totalrows - 1) || var_offset === 0) {
            await populate_records();
        } else {
            await populate_records(null, var_offset - var_limit);
        }
        populate_function && populate_function();
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_package_requirements(limit,offset,sortby,sortorder,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-requirements', '/get-package-requirements/'+package_id,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername
                },
                body: filter
            }
        );
    }

    function API_post_package_requirements(requirements) {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-requirements', '/post-package-requirements/', { body: requirements });
    }

    function API_delete_package_requirement(id) {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.del('org-requirements', '/delete-package-requirement/'+id+'/'+JSON.parse(sessionStorage.getItem('authentication')).organization_id);
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        } else {
            sortorder = sortorder === 'ascending' ? 'descending' : 'ascending';
        }
        populate_records('',0,sortby,sortorder)
    }

    function onClick_removerequirement(id) {
        removerequirement(id);
    }

    function onClick_addrequirement(){
        set_mdl_addrequirement_open(true);
    }

    // RENDER APP ======================================================================================================

    return (
        <div className="modal-tab">
            <div className='content-filter'>
                <div className='content-filter-item item-pagination'>
                    <CMP_TABLECONTROLS
                        var_limit={var_limit} var_offset={var_offset} var_ready={var_records !== null} var_loading={var_loading}
                        total_rows={var_totalrows} populatefunction={populate_records}
                        downloadname='Package Requirements' downloadfunction={download_results}
                        var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                        table_config={[
                            {name: 'requirement', datatype: 'text', labelKey: 'REQUIREMENT', download: true, filter: true}
                        ]}
                    />
                </div>
            </div>
            <div className='tablewrapper'>
                <Table sortable compact className={(var_loading ? 'tbl_loading' : '')}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell sorted={var_sortby === 'requirement' ? var_sortorder : null} onClick={() => onClick_sort('requirement')}>{t('REQUIREMENT')}</Table.HeaderCell>
                            <Table.HeaderCell className="td_icon_center"></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    {var_records && var_records.length > 0 &&
                    <Table.Body>
                        {var_records.map((item) =>
                            <Table.Row key={item.id}>
                                <Table.Cell>{item.requirement}</Table.Cell>
                                <Table.Cell className="td_icon_center" onClick={() => onClick_removerequirement(item.id)}><img src={"/icons/delete 18px (6B7280).svg?ts=" + Date.now()} alt={t("delete icon")}/></Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                    }
                </Table>
                {!var_loading && var_totalrows === 0 &&
                <div className='emptytable'><img src={'/icons/alert 60px (e5e5e5).svg?ts=' + Date.now()} alt='alert icon'/>{t('there are no requirements to display')}</div>
                }
            </div>
            <div className='content-filter'>
                <div className='content-filter-item item-pagination'>
                    <CMP_TABLECONTROLS
                        var_limit={var_limit} var_offset={var_offset} var_ready={var_records !== null} var_loading={var_loading}
                        total_rows={var_totalrows} populatefunction={populate_records}
                    />
                </div>
            </div>

            <div className="modal-footer">
                <div className='modal-footer-buttons'>
                    <Button className='btn_primary btn_active' onClick={onClick_addrequirement}>{t('ADD REQUIREMENTS')}</Button>
                </div>
            </div>
            
            {/***** MODAL: ADD REQUIREMENT **********************************************************************/}

            <Modal id='mdl_addrequirement'
                dimmer={'inverted'}
                open={var_mdl_addrequirement_open}
                onClose={() => set_mdl_addrequirement_open(false)}>
                <MDL_ADDREQUIREMENT
                    var_mdl_open={var_mdl_addrequirement_open}
                    set_mdl_open={set_mdl_addrequirement_open}
                    package_id={package_id}
                    addrequirement_function={addrequirement} />
            </Modal>

            {/***** END MODAL: ADD REQUIREMENT ******************************************************************/}
        </div>
    )
}
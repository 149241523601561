import { Button, Form, Input, Dropdown } from 'semantic-ui-react'
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";

import { useTranslation } from 'react-i18next';
import '../../../../../i18n';
import auth from '../../../../../libs/auth-lib';

import './mdl_details.css';
import config from '../../../../../config';


export default function ({ set_mdl_details_open, var_indv, set_indv, populate_indv, set_language }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();
    const [var_ready, set_ready] = useState(false);
    const [var_processing, set_processing] = useState(false);
    const [var_validationerror, set_validationerror] = useState(false);
    const [var_validationerrormessage, set_validationerrormessage] = useState('');

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------

    async function update_indv() {
        set_processing(true);
        await API_put_indv().then(() => {
            set_ready(false);
            set_processing(false);
            let authentication = JSON.parse(sessionStorage.getItem('authentication'));
            authentication['language'] = config.language[var_indv.default_language];
            sessionStorage.setItem('authentication', JSON.stringify(authentication));
            auth.clear();
            populate_indv();
            set_language(var_indv.default_language);
            set_mdl_details_open(false);
        }).catch(err => {
            set_ready(false);
            set_processing(false);
            console.log(err);
        });
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_put_indv() {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.put('indv', '/put-indv/' + JSON.parse(sessionStorage.getItem('authentication')).id, { body: var_indv });

    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(event) {
        const indv = { ...var_indv };
        set_validationerror(false);
        indv[event.target.name] = event.target.value;
        if(event.target.name==="phone"){
            indv[event.target.name] = event.target.value.trimStart();
        }else{
            indv[event.target.name] = event.target.value;
        }
        set_indv(indv);
        set_ready(true);
    }

    function onChange_language(e, { value }) {
        console.log(value);
        const indv = { ...var_indv }
        indv['default_language'] = value;
        set_indv(indv);
        set_ready(true);
    }

    function onClick_submit() {
        if (var_ready) {
            let var_validation = false;
            let phoneValidation =new RegExp(/^[A-Za-z0-9-()_+ ]+$/);
            let var_validmessage = '';
            if ((!var_indv.firstname || var_indv.firstname.trim() === '') && (!var_indv.lastname || var_indv.lastname.trim() === '')) {
                var_validation = true;
                var_validmessage = 'Please provide your first and last name';
            } else if (!var_indv.firstname || var_indv.firstname.trim() === '') {
                var_validation = true;
                var_validmessage = 'Please provide your first name';
            } else if (!var_indv.lastname || var_indv.lastname.trim() === '') {
                var_validation = true;
                var_validmessage = 'Please provide your last name';
            } else if (!var_indv.default_language || var_indv.default_language.trim() === '') {
                var_validation = true;
                var_validmessage = 'Please select a default language';
            } else if(var_indv.phone && !phoneValidation.test(var_indv.phone)){
                var_validation = true;
                var_validmessage = 'Please provide a valid phone number';
            }
            set_validationerrormessage(var_validmessage);
            set_validationerror(var_validation);
            if (var_validation === false) {
                update_indv();
            }
        }
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">{t('Update Profile Details')}</div>
            <div className="modal-content">
                <Form>
                    <Form.Group className="fg_name">
                        <Form.Field
                            className='firstname'
                            id='firstname'
                            name='firstname'
                            value={var_indv.firstname || ''}
                            control={Input}
                            onChange={onChange_input}
                            label={t('FIRST NAME')}
                            placeholder={t('First name...')}
                        />
                        <Form.Field
                            className='lastname'
                            id='lastnamelastname'
                            name='lastname'
                            value={var_indv.lastname || ''}
                            control={Input}
                            onChange={onChange_input}
                            label={t('LAST NAME')}
                            placeholder={t('Last name...')}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                            className='phone'
                            id='phone'
                            name='phone'
                            value={var_indv.phone || ''}
                            control={Input}
                            onChange={onChange_input}
                            label={t('PHONE')}
                            placeholder={t('Phone...')}
                        />
                        <Form.Field
                            className='default_language'
                            id='default_language'
                            name='default_language'
                            value={var_indv.default_language || ''}
                            control={Dropdown}
                            onChange={onChange_language}
                            label={t('LANGUAGE')}
                            placeholder={t('Select language')}
                            options={[{ key: 'en', text: 'English', value: 'en' }, { key: 'fr', text: 'Français', value: 'fr' }]}
                            selection
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                            className='city'
                            id='city'
                            name='city'
                            value={var_indv.city || ''}
                            control={Input}
                            onChange={onChange_input}
                            label={t('CITY')}
                            placeholder={t('City...')}
                        />
                        <Form.Field
                            className='province'
                            id='province'
                            name='province'
                            value={var_indv.province || ''}
                            control={Input}
                            onChange={onChange_input}
                            label={t('PROVINCE/STATE')}
                            placeholder={t('Province/State...')}
                        />
                    </Form.Group>
                </Form>
                {var_validationerror &&
                    <div className="message warning">
                        <div className="message_icon"><img src={"/icons/warning 60px (d91e18).svg?ts=" + Date.now()} alt={t('warning icon')} /></div>
                        <div className="message_text_wrapper">
                            <div className="message_text cause">{t(var_validationerrormessage)}</div>
                        </div>
                    </div>
                }
            </div>
            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_details_open(false)}>{t('CANCEL')}</Button>
                    {!var_processing ?
                        <Button className={"btn_primary " + (var_ready ? ' btn_active' : '')} onClick={() => onClick_submit()}>{t('UPDATE PROFILE')}</Button>
                        :
                        <Button loading className="btn_primary btn_active">{t('UPDATE PROFILE')}</Button>
                    }

                </div>
            </div>
        </>
    )
}

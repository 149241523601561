import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import auth from '../../../../libs/auth-lib';
import { useTranslation } from 'react-i18next';
import '../../../../i18n';

import CRD_ORGDETAILS from './crd_orgdetails/crd_orgdetails'
import CRD_ORGPROFILE from './crd_orgprofile/crd_orgprofile';

export default function(appProps) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();
    const history = useHistory();
    const [ var_pageready, set_pageready ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (auth.is_loggedin && auth.org_wallet) {
            appProps.set_visibleframe(true);
            appProps.set_visiblemenu('ORGANIZATION')
            appProps.set_activemenu('/org/wallet/wallet');
            set_pageready(true);
        } else {
            history.push('/login');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    //  async functions ------------------------------------------------------------------------------------------------

    //  API calls ------------------------------------------------------------------------------------------------------

    //  event functions ------------------------------------------------------------------------------------------------


    // RENDER APP ======================================================================================================

    return (
        <>
            {auth.is_loggedin &&
            <div className='content'>
                <div className='content-topmenu'>
                    {JSON.parse(sessionStorage.getItem('authentication')).organization} {t('Profile')}
                </div>
                <div className='content-wrapper'>
                    <CRD_ORGDETAILS 
                        var_pageready={var_pageready}
                    />
                    <CRD_ORGPROFILE />
                </div>
            </div>
            }
        </>
    )
}
import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import './crd_details.css';


export default function({ var_pageready }){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();
    const history = useHistory();

    const [ var_org, set_org ] = useState([]);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_pageready) {
            populate_org()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_pageready]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_org(){
        try {
            await API_get_org().then((org)=>{
                set_org(org[0]);
            });
        } catch (e) {
            console.log(e);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org', '/get-organization/'+JSON.parse(sessionStorage.getItem('authentication')).organization_id)
            .then(response => response)
            .catch(error => {
                console.log(error.response);
                return [{"id":null}];  // on error, return a sane result
            });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onOpen_pg_profile(){
        history.push('/org/wallet/profile');
    }

    function onOpen_pg_sharing(){
        history.push('/org/wallet/sharing');
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="content-card" id="crd_indv_overview_details">
                <div className="content-card-header">
                    <div className="content-card-header-title">{t('Profile Details')}</div>
                    <div className="content-card-subheader_icon" onClick={() => onOpen_pg_sharing()}><img src={"/icons/share 60px (717473).svg?ts=" + Date.now()} alt={t('share icon')}/></div>
                    <div className="content-card-subheader_icon" onClick={() => onOpen_pg_profile()}><img src={"/icons/gear 60px (717473).svg?ts=" + Date.now()} alt={t('gear icon')}/></div>
                </div>
                <div className="content-card-content">
                    <div className="detail-item">
                        <div className="detail-item-label">{t('NAME')}</div>
                        <div className="detail-item-text">{var_org.name}</div>
                    </div>
                    <div className="detail-spacer"></div>
                    <div className="detail-item">
                        <div className="detail-item-label">{t('ADDRESS')}</div>
                        <div className="detail-item-text">{var_org.address1}</div>
                    </div>
                    <div className="detail-spacer"></div>
                    <div className="detail-item">
                        <div className="detail-item-label"></div>
                        <div className="detail-item-text">{var_org.city} {var_org.province}</div>
                    </div>
                    <div className="detail-spacer"></div>
                    <div className="detail-item">
                        <div className="detail-item-label"></div>
                        <div className="detail-item-text">{var_org.postal}</div>
                    </div>
                </div>
            </div>
        </>
    )

}

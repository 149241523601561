// import { API } from "aws-amplify";
import React from "react";
import { Table, TableBody } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import { scroller } from "react-scroll";

import './crd_individuals.css';


export default function({var_individuals}){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();

    //  event listeners ------------------------------------------------------------------------------------------------


    //  async functions ------------------------------------------------------------------------------------------------


    function scrollToSection(id){
        try {
            var cls = "profile"+id;
            scroller.scrollTo(cls, {
                duration: 800,
                delay: 0,
                smooth: "easeInOutQuart",
              });
        } catch (e) {
            console.log(e);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------




    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <>
            <section className="public_location_section">
                <div className="public_location_section_header">{t('Individuals')}</div>
                <div className="public_location_section_body">
                    <div className="tablewrapper">
                        <Table unstackable compact>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell className="td_name">{t('NAME')}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <TableBody>
                            {var_individuals.map((item, i) =>
                                <Table.Row key={i} onClick={() => scrollToSection(i)}>
                                    <Table.Cell className="td_name">{item.detail.individual}</Table.Cell>
                                </Table.Row>
                            )}
                            </TableBody>
                        </Table>
                        {var_individuals.length === 0 &&
                            <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t('alert icon')}/>{t('there are no individuals to display')}</div>
                        }
                    </div>
                </div>
            </section>
        </>
    )

}
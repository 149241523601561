import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Input } from 'semantic-ui-react';
import { API } from 'aws-amplify';
import auth from '../../../../../libs/auth-lib';
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import './tab_details.css';

import MDL_CONFIRMATION from '../../../../../components/cmp_confirmation/cmp_confirmation';
import MDL_CONFIRMREINVITE from './mdl_confirmreinvite/mdl_confirmreinvite';
import MDL_CONFIRMREVOKE from './mdl_confirmrevoke/mdl_confirmrevoke';
import MDL_SHARING from './mdl_sharing/mdl_sharing';


export default function({ set_mdl_open, var_activetab, var_individual_id, populate_function, set_indv_from_org, var_indv_from_org }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_individual, set_individual] = useState([]);
    const [ var_ready, set_ready] = useState(false);
    const [ var_processing, set_processing] = useState(false);
    const [ var_permissionoptions, set_permissionoptions ] = useState([]);
    const [ var_original_details, set_original_details ] = useState({});
    const [ var_is_guest, set_is_guest ] = useState(null);

    const [ var_mdl_confirmation_open, set_mdl_confirmation_open ] = useState(false);
    const [ var_mdl_confirmreinvite_open, set_mdl_confirmreinvite_open ] = useState(false);
    const [ var_mdl_confirmrevoke_open, set_mdl_confirmrevoke_open ] = useState(false);
    const [ var_mdl_sharing_open, set_mdl_sharing_open ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        let getpermission = async()=>{
            if ([auth.constants.permission_ids.Admin, auth.constants.permission_ids.GlobalAdmin, auth.constants.permission_ids.Superadmin].includes(auth.permission_id)) {
                let options = await API_get_dd_roles();
                set_permissionoptions(options.map(item => ({text: t(item.permission.toUpperCase()), value: item.id})));
            }
        };
        getpermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_original_details]);

    useEffect(()=>{
        if(var_activetab === 'DETAILS') {
            populate_individual();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_activetab]);

    useEffect(()=>{
        // guest types can only have user level permission
        if (var_is_guest && var_permissionoptions.length !== 0) {
            const user_permission = var_permissionoptions.find(option => option.text.toLowerCase() === 'user').value
            const individual = { ...var_individual };
            individual['permission_id'] = user_permission;
            set_individual(individual);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_is_guest, var_permissionoptions]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_individual(){
        try {
            let individual = await API_get_org_individual();
            set_indv_from_org(individual[0].organization_id === auth.organization_id);
            individual[0]['individual_id'] = var_individual_id;
            individual[0]['organization_id'] = auth.actingorganization_id;
            individual[0]['transactionby'] = auth.id;
            individual[0]['transactionorg'] = auth.organization_id;
            individual[0]['org_cana'] = auth.org_cana;

            set_individual(individual[0]);
            set_original_details(individual);
            set_is_guest(individual[0].individual_type === 'GUEST');
        } catch (e) {
            console.log(e);
        }
    }

    async function update_details() {
        set_processing(true);
        try {
            await API_put_indv_details();
            populate_individual();
            populate_function();
            set_ready(false);
        } catch (e) {
            console.log(e);
        }
        set_processing(false);
    }

    async function archive_individual(){
        try {
            if(var_individual.invitestatus !== 'ARCHIVE'){
                await API_put_indv_archive('ARCHIVE');
            }else{
                await API_put_indv_archive('ACTIVE');
            }
            populate_individual();
            populate_function();
        } catch (e) {
            console.log(e);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_individual(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org', '/get-org-individual/' + auth.actingorganization_id + '/' + var_individual_id);
    }

    function API_get_dd_roles(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-individuals', '/get-dd-org-indv-roles/' + auth.actingorganization_id + '/' + var_individual_id);
    }

    function API_put_indv_details(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.put('org', '/put-indv-details/' + var_individual.organizationindividual_id, { body: var_individual });
    }

    function API_put_indv_archive(var_archive){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.put('org', '/put-indv-archive/' + var_individual.organizationindividual_id + '/' + var_archive, { body: var_individual });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_details(e, value, name){
        const individual = { ...var_individual };
        individual[name] = value;
        set_individual(individual);
        set_ready(var_original_details[name] !== value)

        if (name === 'individual_type') {
            set_is_guest(value === 'GUEST');
        }
    }

    function onClick_submitdetails(){
        if(var_ready){
            update_details();
        }
    }

    function onClick_archive(){
        set_mdl_confirmation_open(true);
    }

    function onClick_unarchive(){
        archive_individual();
    }

    function onClick_resendinvite(){
        set_mdl_confirmreinvite_open(true);
    }

    function onClick_revokeinvite(){
        set_mdl_confirmrevoke_open(true);
    }

    function onOpen_mdl_sharing(){
        set_mdl_sharing_open(true);
    }

    // RENDER APP ======================================================================================================

    return (
        <div className="modal-tab">

            {var_individual.invitestatus === 'ARCHIVE' &&
            <div className="modal-archive" onClick={() => onClick_unarchive()}>
                <div className="modal-archive-icon"><img className="btn_icon" src={"/icons/archive 30px (ffffff).svg?ts=" + Date.now()} alt={t("archive icon")}/></div>
                <div className="modal-archive-text">{t("This individual has been archived. Click this bar to make them active again.")}
                </div>
            </div>
            }

            <div id="tab_details">
                <div className="profile">
                    <div className="content-card-content">
                        <div className="detail-item">
                            <div className="detail-item-label">{t("NAME")}</div>
                            <div className="detail-item-text">{var_individual.firstname} {var_individual.lastname}</div>
                        </div>
                        <div className="detail-spacer"></div>
                        <div className="detail-item">
                            <div className="detail-item-label">{t("EMAIL")}</div>
                            <div className="detail-item-text">{var_individual.email}</div>
                        </div>
                        <div className="detail-spacer"></div>
                        <div className="detail-item">
                            <div className="detail-item-label">{t("PHONE")}</div>
                            <div className="detail-item-text">{var_individual.phone}</div>
                        </div>
                        <div className="detail-spacer"></div>
                        <div className="detail-item">
                            <div className="detail-item-label">{t("CITY")}</div>
                            <div className="detail-item-text">
                                {var_individual.city && var_individual.province
                                    ? (var_individual.city + ', ' + var_individual.province)
                                    : var_individual.city ? var_individual.city : var_individual.province}
                            </div>
                        </div>
                        <div className="detail-spacer"></div>
                        <div className="detail-item">
                            <div className="detail-item-label">{t("JOB TITLE")}</div>
                            {[auth.constants.permission_ids.Admin, auth.constants.permission_ids.GlobalAdmin, auth.constants.permission_ids.Superadmin].includes(auth.permission_id) ?
                                <div>
                                    <Form.Field
                                        id='job_title'     
                                        name='job_title'
                                        value={var_individual.job_title || ''}         
                                        control={Input}
                                        onChange={(e, { value }) => onChange_details(e, value, 'job_title')}
                                        placeholder={t('Job Title...')}         
                                    />
                                </div>
                            :
                                <div className="detail-item-text">{var_individual.job_title}</div>
                            }
                        </div>
                    </div>
                </div>
                {var_indv_from_org &&
                <div className="options">
                    <div className="statuswrapper">
                        <div className="statusprogress">
                        {var_individual.invitestatus === 'PENDING INVITE' &&
                            <div className="statusprogress-item">
                                <div className="statusprogress-item-text">{t("PENDING INVITE")}</div>
                                <div className={"statusprogress-item-bar pending invite " + (var_individual.invitestatus === 'PENDING INVITE' ? 'PENDINGINVITE' : var_individual.invitestatus)}>
                                    <img src={"/icons/envelope 60px (ffffff).svg?ts=" + Date.now()} alt={t("envelope icon")}/>
                                </div>
                            </div>
                        }
                            <div className="statusprogress-item">
                                <div className="statusprogress-item-text">{t("INVITED")}</div>
                                <div className={"statusprogress-item-bar invited " + (var_individual.invitestatus === 'PENDING INVITE' ? 'PENDINGINVITE' : var_individual.invitestatus)}>
                                    <img src={"/icons/envelope 60px (ffffff).svg?ts=" + Date.now()} alt={t("envelope icon")}/>
                                </div>
                            </div>
                            
                            <div className="statusprogress-item">
                                <div className="statusprogress-item-text">{t("AUTHENTICATING")}</div>
                                <div className={"statusprogress-item-bar authenticating " + (var_individual.invitestatus === 'PENDING INVITE' ? 'PENDINGINVITE' : var_individual.invitestatus)}>
                                    <img src={"/icons/lock 60px (ffffff).svg?ts=" + Date.now()} alt={t("lock icon")}/>
                                </div>
                            </div>
                            {var_individual.invitestatus === 'INVITEEXPIRED' &&
                            <div className="statusprogress-item">
                                <div className="statusprogress-item-text">{t("INVITE EXPIRED")}</div>
                                <div className={"statusprogress-item-bar inviteexpired " + var_individual.invitestatus}>
                                    <img src={"/icons/x 60px (ffffff).svg?ts=" + Date.now()} alt={t("x icon")}/>
                                </div>
                            </div>
                            }
                            {var_individual.invitestatus === 'DECLINED' &&
                            <div className="statusprogress-item">
                                <div className="statusprogress-item-text">{t("DECLINED")}</div>
                                <div className={"statusprogress-item-bar declined " + var_individual.invitestatus}>
                                    <img src={"/icons/x 60px (ffffff).svg?ts=" + Date.now()} alt={t("x icon")}/>
                                </div>
                            </div>
                            }
                            {(var_individual.invitestatus !== 'DECLINED' && var_individual.invitestatus !== 'INVITEEXPIRED') &&
                            <div className="statusprogress-item">
                                <div className="statusprogress-item-text">{t("ACTIVE")}</div>
                                <div className={"statusprogress-item-bar active " + (var_individual.invitestatus === 'PENDING INVITE' ? 'PENDINGINVITE' : var_individual.invitestatus)}>
                                    <img src={"/icons/checkmark 60px (ffffff).svg?ts=" + Date.now()} alt={t("checkmark icon")}/>
                                </div>
                            </div>
                            }
                            {var_individual.invitestatus === 'INACTIVE' &&
                            <div className="statusprogress-item">
                                <div className="statusprogress-item-text">{t("INACTIVE")}</div>
                                <div className={"statusprogress-item-bar inactive " + var_individual.invitestatus}>
                                    <img src={"/icons/x 60px (ffffff).svg?ts=" + Date.now()} alt={t("x icon")}/>
                                </div>
                            </div>
                            }
                        </div>
                        <div className="statusoptions">

                            {[auth.constants.permission_ids.Manager, auth.constants.permission_ids.Admin, auth.constants.permission_ids.GlobalAdmin, auth.constants.permission_ids.Superadmin].includes(auth.permission_id) &&
                            <>
                                {(var_individual.invitestatus === 'INVITED' || var_individual.invitestatus === 'AUTHENTICATING' || var_individual.invitestatus === 'INVITEEXPIRED') &&
                                <Button className="btn_tertiary" onClick={() => onClick_resendinvite()}><img className="btn_icon" src={"/icons/envelope 60px (4dcbd4).svg?ts=" + Date.now()} alt={t("envelope icon")}></img>{t("RESEND INVITE")}</Button>
                                }
                                {var_individual.invitestatus === 'ACTIVE' &&
                                <Button className="btn_tertiary" onClick={() => onClick_resendinvite()}><img className="btn_icon" src={"/icons/lock 60px (4dcbd4).svg?ts=" + Date.now()} alt={t("lock icon")}></img>{t("SEND PASSWORD RESET LINK")}</Button>
                                }
                                {var_individual.invitestatus === 'PENDING INVITE' &&
                                <Button className="btn_tertiary" onClick={() => onClick_resendinvite()}><img className="btn_icon" src={"/icons/envelope 60px (4dcbd4).svg?ts=" + Date.now()} alt={t("envelope icon")}></img>{t("SEND INVITE")}</Button>
                                }
                            </>
                            }
                            {[auth.constants.permission_ids.Admin, auth.constants.permission_ids.GlobalAdmin, auth.constants.permission_ids.Superadmin].includes(auth.permission_id) &&
                            <>
                                {(var_individual.invitestatus && var_individual.invitestatus !== 'INACTIVE' && var_individual.invitestatus !== 'ACTIVE' && var_individual.invitestatus !== 'PENDING INVITE') &&
                                    <Button className="btn_tertiary" onClick={() => onClick_revokeinvite()}><img className="btn_icon" src={"/icons/x 60px (4dcbd4).svg?ts=" + Date.now()} alt={t("x icon")}></img>{t("REVOKE INVITE")}</Button>
                                }
                            </>
                            }
                        </div>
                    </div>
                    <Form className="user_details_form">
                            {[auth.constants.permission_ids.Admin, auth.constants.permission_ids.GlobalAdmin, auth.constants.permission_ids.Superadmin].includes(auth.permission_id) ?
                            <>
                            <Form.Group>
                            <Form.Select
                                className='permission_id'
                                name='permission_id'
                                label={t("PERMISSION")}
                                options={var_permissionoptions}
                                value={var_individual.permission_id || ''}
                                onChange={(e, { value }) => onChange_details(e, value, 'permission_id')}
                                placeholder={t("Select a permission level...")}
                                disabled={var_is_guest}
                            />
                            </Form.Group>
                            <Form.Group>
                            <Form.Select
                                className='individual_type'
                                name='individual_type'
                                label={t('INDIVIDUAL TYPE')}
                                options={[{key: 1, value: 'USER', text: 'USER'}, {key: 2, value: 'GUEST', text: 'GUEST'}]}
                                value={var_individual.individual_type || ''}
                                onChange={(e, { value }) => onChange_details(e, value, 'individual_type')}
                                placeholder={t("Select an individual type...")}
                            />
                            </Form.Group>
                            </>
                            :
                            <>
                            <div className="detail-item">
                                <div className="detail-item-label">{t("PERMISSION")}</div>
                                <div className="detail-item-text">{var_individual.permission ? t(var_individual.permission.toUpperCase()) : null}</div>
                            </div>
                            <div className="detail-item">
                                <div className="detail-item-label">{t("INDIVIDUAL TYPE")}</div>
                                <div className="detail-item-text">{var_individual.individual_type ? t(var_individual.individual_type.toUpperCase()) : null}</div>
                            </div>
                            </>
                            }
                    </Form>
                </div>
                }
            </div>

            {[auth.constants.permission_ids.Admin, auth.constants.permission_ids.GlobalAdmin, auth.constants.permission_ids.Superadmin].includes(auth.permission_id) && var_indv_from_org &&
            <div className="modal-footer">

                {var_individual.invitestatus !== 'ARCHIVE' && auth.permission_id === auth.constants.permission_ids.Superadmin &&
                <div className="modal-footer-archive">
                    <Button className="btn_tertiary" onClick={() => onClick_archive()}><img className="btn_icon" src={"/icons/archive 30px (4dcbd4).svg?ts=" + Date.now()} alt={t("archive icon")}/>{t("ARCHIVE INDIVIDUAL")}</Button>
                </div>
                }
                { auth.has_access('ORG-INDV', 'share') &&
                <div className="modal-footer-share">
                    <Button className="btn_tertiary" onClick={() => onOpen_mdl_sharing()}><img className="btn_icon" src={"/icons/share 60px (4dcbd4).svg?ts=" + Date.now()} alt={t("share icon")}/>{t("SHARE WALLET")}</Button>
                </div>
                }


                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>{t("CANCEL")}</Button>
                    {!var_processing ?
                        <Button className={"btn_primary " + (var_ready ? 'btn_active' : '')} onClick={() => onClick_submitdetails()}>{t("UPDATE DETAILS")}</Button>
                        :
                        <Button loading className="btn_primary btn_active">{t("UPDATE DETAILS")}</Button>
                    }
                </div>
            </div>
            }


            {/***** MODAL: CONFIRMATION *******************************************************************************/}

            <Modal id="mdl_confirmation"
                   dimmer={'inverted'}
                   open={var_mdl_confirmation_open}
                   onClose={() => set_mdl_confirmation_open(false)}>
                <MDL_CONFIRMATION
                    set_mdl_open={set_mdl_confirmation_open}
                    var_modaltitle={t("Archive Individual")}
                    var_message={t("Are you sure you want to archive this individual?")}
                    confirmation_function={archive_individual}>
                </MDL_CONFIRMATION>
            </Modal>

            {/***** END MODAL: CONFIRMATION **************************************************************************/}



            {/***** MODAL: REINVITE CONFIRMATION *****************************************************************/}

            <Modal id="mdl_org_indv_details_confirmreinvite"
                   dimmer={'inverted'}
                   open={var_mdl_confirmreinvite_open}
                   onClose={() => set_mdl_confirmreinvite_open(false)}>
                <MDL_CONFIRMREINVITE
                    set_mdl_confirmreinvite_open={set_mdl_confirmreinvite_open}
                    var_individual_id={var_individual_id}
                    var_individual={var_individual}
                    set_individual={set_individual}
                    populate_individual={populate_individual}
                >
                </MDL_CONFIRMREINVITE>
            </Modal>

            {/***** END MODAL: REINVITE CONFIRMATION *************************************************************/}



            {/***** MODAL: REVOKE CONFIRMATION *******************************************************************/}

            <Modal id="mdl_org_indv_details_confirmrevoke"
                   dimmer={'inverted'}
                   open={var_mdl_confirmrevoke_open}
                   onClose={() => set_mdl_confirmrevoke_open(false)}>
                <MDL_CONFIRMREVOKE
                    set_mdl_confirmrevoke_open={set_mdl_confirmrevoke_open}
                    var_individual={var_individual}
                    set_individual={set_individual}
                    populate_function={populate_function}
                    set_mdl_open={set_mdl_open}
                >
                </MDL_CONFIRMREVOKE>
            </Modal>

            {/***** END MODAL: REVOKE CONFIRMATION ***************************************************************/}


            {/***** MODAL: SHARING *******************************************************************************/}

            <Modal id="mdl_org_indv_wallet_details_sharing"
                   dimmer={'inverted'}
                   open={var_mdl_sharing_open}
                   onClose={() => set_mdl_sharing_open(false)}>
                <MDL_SHARING
                    set_mdl_sharing_open={set_mdl_sharing_open}
                    var_individual_id={var_individual_id}
                    var_individual={var_individual}
                >
                </MDL_SHARING>
            </Modal>

            {/***** END MODAL: SHARING ***************************************************************************/}



        </div>
    )

};

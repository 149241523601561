import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';
import auth from '../../../../../libs/auth-lib';

import TAB_DETAILS from './tab_details/tab_details';
import TAB_INDIVIDUALS from './tab_individuals/tab_individuals';
import TAB_REQUIREMENTS from './tab_requirements/tab_requirements';
import TAB_CREDENTIALS from './tab_credentials/tab_credentials';
import TAB_DAILYCHECKS from './tab_dailychecks/tab_dailychecks';
import TAB_DOCUMENTS from './tab_documents/tab_documents';
import TAB_FORMS from './tab_forms/tab_forms';

import './mdl_update.css';



export default function({ set_mdl_open, var_target_id, var_target, populate_function }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_activetab, set_activetab] = useState('DETAILS');
    const [ var_org_owns_location, set_org_owns_location ] = useState(false);

    //  variable listeners ---------------------------------------------------------------------------------------------



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_tab(var_tab){
        set_activetab(var_tab);
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{var_target}</div>
                <div className="modal-header-tabs">
                    <div className={"modal-header-tabs-item"  + (var_activetab === 'DETAILS' ? ' active' : '')} onClick={() => onClick_tab(`DETAILS`)}>
                        {t("Details")}
                    </div>
                    <div className={"modal-header-tabs-item"  + (var_activetab === 'INDIVIDUALS' ? ' active' : '')} onClick={() => onClick_tab(`INDIVIDUALS`)}>
                        {t("Individuals")}
                    </div>
                    {auth.org_dailycheck &&
                    <div className={"modal-header-tabs-item" + (var_activetab === 'DAILYCHECKS' ? ' active' : '')} onClick={() => onClick_tab(`DAILYCHECKS`)}>
                        {t("Daily Checks")}
                    </div>
                    }
                    {auth.org_requirements &&
                    <div className={"modal-header-tabs-item" + (var_activetab === 'REQUIREMENTS' ? ' active' : '')} onClick={() => onClick_tab(`REQUIREMENTS`)}>
                        {t("Requirements")}
                    </div>
                    }
                    {auth.org_credentials &&
                    <div className={"modal-header-tabs-item" + (var_activetab === 'CREDENTIALS' ? ' active' : '')} onClick={() => onClick_tab(`CREDENTIALS`)}>
                        {t("Credentials")}
                    </div>
                    }
                    {auth.org_documents &&
                    <div className={"modal-header-tabs-item" + (var_activetab === 'DOCUMENTS' ? ' active' : '')} onClick={() => onClick_tab(`DOCUMENTS`)}>
                        {t("Documents")}
                    </div>
                    }
                    {auth.org_forms &&
                    <div className={"modal-header-tabs-item" + (var_activetab === 'FORMS' ? ' active' : '')} onClick={() => onClick_tab(`FORMS`)}>
                        {t("Forms")}
                    </div>
                    }
                    {/*<div className={"modal-header-tabs-item"  + (var_activetab === 'SHARING' ? ' active' : '')} onClick={() => onClick_tab(`SHARING`)}>
                        Sharing
                    </div>*/}
                </div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
            </div>
            <div className="modal-tabs">
                <div className={(var_activetab !== 'DETAILS' ? 'modal-tab-hide' : '')}>
                    <TAB_DETAILS
                        set_mdl_open={set_mdl_open}
                        var_activetab={var_activetab}
                        var_location_id={var_target_id}
                        populate_function={populate_function}
                        set_activetab={set_activetab}
                        onClick_tab={onClick_tab}
                        set_org_owns_location={set_org_owns_location}
                    />
                </div>
                <div className={(var_activetab !== 'INDIVIDUALS' ? 'modal-tab-hide' : '')}>
                    <TAB_INDIVIDUALS
                        set_mdl_open={set_mdl_open}
                        var_activetab={var_activetab}
                        var_location_id={var_target_id}
                        populate_function={populate_function}
                        var_target={var_target}
                    />
                </div>
                {auth.org_requirements &&
                <div className={(var_activetab !== 'REQUIREMENTS' ? 'modal-tab-hide' : '')}>
                    <TAB_REQUIREMENTS
                        set_mdl_open={set_mdl_open}
                        var_activetab={var_activetab}
                        var_location_id={var_target_id}
                        populate_function={populate_function}
                        var_target={var_target}
                        var_org_owns_location={var_org_owns_location}
                    />
                </div>
                }
                {auth.org_credentials &&
                <div className={(var_activetab !== 'CREDENTIALS' ? 'modal-tab-hide' : '')}>
                    <TAB_CREDENTIALS
                        set_mdl_open={set_mdl_open}
                        var_activetab={var_activetab}
                        var_location_id={var_target_id}
                        populate_function={populate_function}
                    />
                </div>
                }
                {auth.org_dailycheck &&
                <div className={(var_activetab !== 'DAILYCHECKS' ? 'modal-tab-hide' : '')}>
                    <TAB_DAILYCHECKS
                        set_mdl_open={set_mdl_open}
                        var_activetab={var_activetab}
                        var_location_id={var_target_id}
                        populate_function={populate_function}
                    />
                </div>
                }
                {auth.org_documents &&
                <div className={(var_activetab !== 'DOCUMENTS' ? 'modal-tab-hide' : '')}>
                    <TAB_DOCUMENTS
                        set_mdl_open={set_mdl_open}
                        var_activetab={var_activetab}
                        var_location_id={var_target_id}
                        populate_function={populate_function}
                        var_target={var_target}
                        var_target_id={var_target_id}
                        var_org_owns_location={var_org_owns_location}
                    />
                </div>
                }
                {auth.org_forms &&
                <div className={(var_activetab !== 'FORMS' ? 'modal-tab-hide' : '')}>
                    <TAB_FORMS
                        set_mdl_open={set_mdl_open}
                        var_activetab={var_activetab}
                        var_location_id={var_target_id}
                        var_location={var_target}
                        populate_function={populate_function}
                        var_org_owns_location={var_org_owns_location}
                    />
                </div>
                }
            </div>
        </>
    )
}

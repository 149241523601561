//  OUTCOMES -----------------------------------------------------------------------------------------------------------
//
//  - lists all assignments for the requirement / targetentity combination with the following information:
//      -   individual
//      -   status: REQUESTED, PENDING, INVALID, VALID
//      -   credential
//      -   issued by
//      -   issued date
//      -   expires date
//      -   link to open credential document
//
//  - if the status is PENDING and the user has the appropriate access, the user can click on an assignment to take action on it
//
//  - if the targetentity is either LOCATION or PROJECT and the user has the appropriate access, the user can click the add
//    individual button to find and add individuals not assigned to the area but meet the requirement
//  QUESTION: should we provide a way to search for individuals that are assigned to the area but not the requirement, but meet it?
//
//  --------------------------------------------------------------------------------------------------------------------

import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Modal, Table, Button } from 'semantic-ui-react';
import datelib from '../../../../libs/date-lib';
import { useTranslation } from 'react-i18next';
import '../../../../i18n';
import auth from '../../../../libs/auth-lib';

import CMP_TABLECONTROLS, {filter_helper} from '../../../../components/cmp_tablecontrols/cmp_tablecontrols';
import MDL_DOCVIEWER from '../../../../components/cmp_docviewer/cmp_docviewer';
import MDL_PENDING_ASSIGNMENT from './mdl_pending_assignment/mdl_pending_assignment';
import MDL_ADD_REQUIREMENT from './mdl_add_requirement/mdl_add_requirement';
import MDL_ADD_INDIVIDUAL from './mdl_add_individual/mdl_add_individual';
import MDL_PREAPPROVALS from './mdl_preapprovals/mdl_preapprovals';

import './mdl_assignment_summary.css';



export default function({ var_mdl_open, set_mdl_open, populate_function, title, targetentity, targetentity_id, requirement_id, var_org_owns_location }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_assignments, set_assignments ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(10);
    const [ var_offset, set_offset] = useState(0);
    const [ var_sortby, set_sortby ] = useState('individual');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));

    const [ var_canupdateassignment, set_canupdateassignment ] = useState(false);
    const [ var_mdl_pendingassignment_open, set_mdl_pendingassignment_open ] = useState(false);
    const [ var_pending_assignment_id, set_pending_assignment_id ] = useState(null);

    const [ var_canaddindvs, set_canaddindvs ] = useState(false);
    const [ var_mdl_addrequirement_open, set_mdl_addrequirement_open ] = useState(false);
    const [ var_mdl_addindv_open, set_mdl_addindv_open ] = useState(false);
    const [ var_canupdatepreapprovals, set_canupdatepreapprovals ] = useState(false);
    const [ var_mdl_preapprovals_open, set_mdl_preapprovals_open ] = useState(false);

    const [ var_mdl_docviewer_open, set_mdl_docviewer_open ] = useState(false);
    const [ var_docviewerid, set_docviewerid ] = useState(null);

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(()=>{
        // set permission variables
        set_canupdateassignment([auth.constants.permission_ids.Admin, auth.constants.permission_ids.GlobalAdmin, auth.constants.permission_ids.Superadmin].includes(auth.permission_id)); // Admins and above
        set_canaddindvs([auth.constants.permission_ids.Admin, auth.constants.permission_ids.GlobalAdmin, auth.constants.permission_ids.Superadmin].includes(auth.permission_id)); // Admins and above
        set_canupdatepreapprovals([auth.constants.permission_ids.Admin, auth.constants.permission_ids.GlobalAdmin, auth.constants.permission_ids.Superadmin].includes(auth.permission_id) && var_org_owns_location); // Admins and above
    },[]);

    useEffect(()=>{
        if(var_mdl_open) {
            set_filter(filter_helper.initialize(false));
        }
    },[var_mdl_open]);

    useEffect(()=>{
        if(var_filter.load){
            populate_assignments(null,0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_filter]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_assignments(limit,offset,sortby,sortorder,filter){

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_requirement_assignments(limit,offset,sortby,sortorder,filter);
            set_totalrows(results.totalrows);
            set_assignments(results.results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername,sortorder){
        try {
            return (await API_get_requirement_assignments(filter_helper.maxFilterItems,0,filtername,sortorder,{},filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_assignments(limit,offset){
        try {
            return (await API_get_requirement_assignments(limit,offset,var_sortby,var_sortorder,var_filter)).results;
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    async function on_data_updated(){
        populate_assignments();
        populate_function();
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_requirement_assignments(limit,offset,sortby,sortorder,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-credentials',
            '/get-requirement-assignments/'+targetentity+'/'+targetentity_id+'/'+requirement_id,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    tz : datelib.timezone
                },
                body: filter
            }
        );
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        } else {
            sortorder = sortorder === 'ascending' ? 'descending' : 'ascending';
        }
        populate_assignments('',0,sortby,sortorder)
    }

    function onClick_pending(id, status){
        if (status === 'PENDING' && var_canupdateassignment && var_org_owns_location) {
            // Open modal to allow to accept or reject
            set_pending_assignment_id(id);
            set_mdl_pendingassignment_open(true);
        }
    }

    function onClick_paperclip(event, id){
        event.stopPropagation();
        set_docviewerid(id);
        set_mdl_docviewer_open(true);
    }

    function onClick_addrequirement(){
        set_mdl_addrequirement_open(true);
    }

    function onClick_addindv(){
        set_mdl_addindv_open(true);
    }

    function onClick_preapprovals(){
        set_mdl_preapprovals_open(true);
    }

    // RENDER APP ======================================================================================================

    return !auth.org_requirements
        ? null :
        (
        <>
            <div className='modal-header'>
                <div className='modal-header-title'>{title}</div>
                <div className='modal-header-close' onClick={() => set_mdl_open(false)}><img src={'/icons/x 60px (717473).svg?ts=' + Date.now()} alt={t('x icon')} /></div>
            </div>
            <div className='modal-content'>
                <div className='content-filter'>
                    <div className='content-filter-item item-pagination'>
                        <CMP_TABLECONTROLS
                            var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                            total_rows={var_totalrows} populatefunction={populate_assignments}
                            downloadname='Assignment Summary' downloadfunction={download_assignments}
                            var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                            table_config={[
                                {name: 'individual', datatype: 'text', labelKey: 'INDIVIDUAL', download: true, filter: true},
                                {name: 'organization', datatype: 'text', labelKey: 'ORGANIZATION', download: true, filter: true},
                                {name: 'status', datatype: 'text', labelKey: 'STATUS', download: true, filter: true},
                                {name: 'credential', datatype: 'text', labelKey: 'CREDENTIAL', download: true, filter: true},
                                {name: 'issuedby', datatype: 'text', labelKey: 'ISSUED BY', download: true, filter: true},
                                {name: 'issued', datatype: 'date', labelKey: 'ISSUED', download: true, filter: false},
                                {name: 'epochissued', datatype: 'date', labelKey: 'ISSUED', download: false, filter: true},
                                {name: 'expires', datatype: 'date', labelKey: 'EXPIRES', download: true, filter: false},
                                {name: 'epochexpires', datatype: 'date', labelKey: 'EXPIRES', download: false, filter: true}
                            ]}
                        />
                    </div>
                </div>
                <div className='tablewrapper'>
                    <Table sortable compact className={(var_loading ? 'tbl_loading' : '')}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell sorted={var_sortby === 'individual' ? var_sortorder : null} onClick={() => onClick_sort('individual')}>{t('INDIVIDUAL')}</Table.HeaderCell>
                                <Table.HeaderCell sorted={var_sortby === 'organization' ? var_sortorder : null} onClick={() => onClick_sort('organization')}>{t('ORGANIZATION')}</Table.HeaderCell>
                                <Table.HeaderCell sorted={var_sortby === 'status' ? var_sortorder : null} onClick={() => onClick_sort('status')}>{t('STATUS')}</Table.HeaderCell>
                                <Table.HeaderCell sorted={var_sortby === 'credential' ? var_sortorder : null} onClick={() => onClick_sort('credential')}>{t('CREDENTIAL')}</Table.HeaderCell>
                                <Table.HeaderCell sorted={var_sortby === 'issuedby' ? var_sortorder : null} onClick={() => onClick_sort('issuedby')}>{t('ISSUED BY')}</Table.HeaderCell>
                                <Table.HeaderCell sorted={var_sortby === 'epochissued' ? var_sortorder : null} onClick={() => onClick_sort('epochissued')}>{t('ISSUED')}</Table.HeaderCell>
                                <Table.HeaderCell sorted={var_sortby === 'epochexpires' ? var_sortorder : null} onClick={() => onClick_sort('epochexpires')}>{t('EXPIRES')}</Table.HeaderCell>
                                <Table.HeaderCell sorted={var_sortby === 'filename' ? var_sortorder : null} onClick={() => onClick_sort('filename')}><img src={'/icons/paperclip 30px (c9caca).svg?ts=' + Date.now()} alt={t('attachment icon')}/></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        {var_ready && var_assignments && var_assignments.length > 0 &&
                        <Table.Body>
                            {var_assignments.map((item) =>
                                <Table.Row key={item.id} onClick={()=>onClick_pending(item.id,item.status)}>
                                    <Table.Cell>{item.individual}</Table.Cell>
                                    <Table.Cell>{item.organization}</Table.Cell>
                                    <Table.Cell>{item.status}</Table.Cell>
                                    <Table.Cell>{item.credential}</Table.Cell>
                                    <Table.Cell>{item.issuedby}</Table.Cell>
                                    <Table.Cell>{datelib.date_localized_format(item.issued)}</Table.Cell>
                                    <Table.Cell>{datelib.date_localized_format(item.expires)}</Table.Cell>
                                    <Table.Cell>
                                        {(item.filename && item.filename !== 'No file selected...') ?
                                            <div className='td_attachment' onClick={(event)=>onClick_paperclip(event, item.individual_credential_id)}> <img src={'/icons/paperclip 60px (717473).svg?ts=' + Date.now()} alt={t('attachment icon')}/></div>
                                            :
                                            <div className='td_attachment'></div>
                                        }
                                    </Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                        }
                    </Table>
                    {var_ready && var_totalrows === 0 &&
                    <div className='emptytable'><img src={'/icons/alert 60px (e5e5e5).svg?ts=' + Date.now()} alt='alert icon'/>{t('there are no assignments to display')}</div>
                    }
                </div>
                <div className='content-filter'>
                    <div className='content-filter-item item-pagination'>
                        <CMP_TABLECONTROLS
                            var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                            total_rows={var_totalrows} populatefunction={populate_assignments}
                        />
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <div className="modal-footer-archive">
                    {var_canupdateassignment && ['LOCATION','PROJECT'].includes(targetentity) &&
                        <Button className="btn_tertiary" onClick={() => onClick_addrequirement()}><img className="btn_icon" src={"/icons/add 30px (4dcbd4).svg?ts=" + Date.now()} alt={t('add requirement icon')} />{t('ADD REQUIREMENT TO INDIVIDUALS')}</Button>
                    }
                    {var_canaddindvs && ['LOCATION','PROJECT'].includes(targetentity) &&                   
                        <Button className="btn_tertiary" onClick={() => onClick_addindv()}><img className="btn_icon" src={"/icons/add 30px (4dcbd4).svg?ts=" + Date.now()} alt={t("add individual icon")} />{t("ADD QUALIFIED INDIVIDUAL")}</Button>
                    }                
                    {var_canupdatepreapprovals &&
                        <Button className="btn_tertiary" onClick={() => onClick_preapprovals()}><img className="btn_icon" src={"/icons/checkmark 30px (4dcbd4).svg?ts=" + Date.now()} alt={t("pre-approvals icon")}/>{t("PRE-APPROVALS")}</Button>
                    }
                </div>
            </div>
            
            {/***** MODAL: PENDING ASSIGNMENT *******************************************************************/}

            <Modal id='mdl_pendingassignment'
                dimmer={'inverted'}
                open={var_mdl_pendingassignment_open}
                onClose={() => set_mdl_docviewer_open(false)}>
                <MDL_PENDING_ASSIGNMENT
                    var_mdl_open={var_mdl_pendingassignment_open}
                    set_mdl_open={set_mdl_pendingassignment_open}
                    targetentity={targetentity}
                    targetentity_id={targetentity_id}
                    pending_assignment_id={var_pending_assignment_id}
                    populate_function={on_data_updated} />
            </Modal>

            {/***** END MODAL: PENDING ASSIGNMENT ***************************************************************/}

            {/***** MODAL: ADD REQUIREMENT TO INDIVIDUALS ***********************************************************************/}

            <Modal id='mdl_addrequirement'
                dimmer={'inverted'}
                open={var_mdl_addrequirement_open}
                onClose={() => set_mdl_addrequirement_open(false)}>
                <MDL_ADD_REQUIREMENT
                    var_mdl_open={var_mdl_addrequirement_open}
                    set_mdl_open={set_mdl_addrequirement_open}
                    requirement_name={title}
                    targetentity_id={targetentity_id}
                    requirement_id={requirement_id}
                    populate_function={on_data_updated} />
            </Modal>

            {/***** END MODAL: ADD REQUIREMENT TO INDIVIDUALS *******************************************************************/}
            
            {/***** MODAL: ADD INDIVIDUAL ***********************************************************************/}

            <Modal id='mdl_addindv'
                dimmer={'inverted'}
                open={var_mdl_addindv_open}
                onClose={() => set_mdl_addindv_open(false)}>
                <MDL_ADD_INDIVIDUAL
                    var_mdl_open={var_mdl_addindv_open}
                    set_mdl_open={set_mdl_addindv_open}
                    requirement_name={title}                  
                    targetentity_id={targetentity_id}
                    requirement_id={requirement_id}
                    populate_function={on_data_updated} />
            </Modal>

            {/***** END MODAL: ADD INDIVIDUAL *******************************************************************/}
            
            {/***** MODAL: PRE-APPROVALS ************************************************************************/}

            <Modal id='mdl_preapprovals'
                dimmer={'inverted'}
                open={var_mdl_preapprovals_open}
                onClose={() => set_mdl_preapprovals_open(false)}>
                <MDL_PREAPPROVALS
                    set_mdl_open={set_mdl_preapprovals_open}
                    requirement_id={requirement_id}
                    requirement_name={title}
                    populate_function={on_data_updated} />
            </Modal>

            {/***** END MODAL: PRE-APPROVALS ********************************************************************/}
            
            {/***** MODAL: DOCUMENT VIEW ************************************************************************/}

            <Modal id='mdl_docviewer'
                dimmer={'inverted'}
                open={var_mdl_docviewer_open}
                onClose={() => set_mdl_docviewer_open(false)}>
                <MDL_DOCVIEWER
                    set_mdl_open={set_mdl_docviewer_open}
                    targetid={var_docviewerid}
                    classification='credential'
                />
            </Modal>

            {/***** END MODAL: DOCUMENT VIEW *********************************************************************/}
        </>
    )
};
import { Modal, Table, Button} from 'semantic-ui-react'
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import Moment from "moment";
import datelib from '../../../../libs/date-lib';

import CMP_TABLECONTROLS, { filter_helper } from '../../../../components/cmp_tablecontrols/cmp_tablecontrols';
import MDL_HISTORY from "../../../../components/cmp_history/cmp_history";
import MDL_VERIFYCREDENTIAL from "./mdl_verifycredential/mdl_verifycredential";
import MDL_DOCVIEWER from "../../../../components/cmp_docviewer/cmp_docviewer";

import { useTranslation } from 'react-i18next';
import '../../../../i18n';

import './crd_verifications.css';



export default function(appProps){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_verifications, set_verifications ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(10);
    const [ var_offset, set_offset] = useState(0);
    const [ var_search, set_search ] = useState('');
    const [ var_sortby, set_sortby ] = useState('holder');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));


    const [ var_mdl_history_open, set_mdl_history_open ] = useState(false);
    const [ var_modaltitle, set_modaltitle ] = useState('');
    const [ var_target_id, set_target_id ] = useState([]);
    const [ var_selected, set_selected ] = useState([]);

    const [ var_mdl_verifycredential_open, set_mdl_verifycredential_open ] = useState(false);

    const [ var_mdl_docviewer_open, set_mdl_docviewer_open ] = useState(false);
    const [ var_docviewerid, set_docviewerid ] = useState(null);

    //  event listeners ------------------------------------------------------------------------------------------------
    useEffect(()=>{
        set_filter(filter_helper.initialize(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(()=>{
        if(var_filter.load) {
        populate_verifications(null, 0);
        }
       // eslint-disable-next-line react-hooks/exhaustive-deps
       },[var_filter]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_verifications(limit,offset,sortby,sortorder,search,filter){

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        if(search) {
            if(search === '-') {
                search = '';
            }
            set_search(search);
        }else{
            search=var_search
        }

        filter = filter ? filter : var_filter;

        set_selected([]);

        try {
            let results = await API_get_verifications(limit,offset,sortby,sortorder,search,filter);
            set_verifications(results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername,sortorder){
        try {
        return await API_get_verifications(filter_helper.maxFilterItems,0,filtername,sortorder,
        var_search,[],filtername);
        } catch (e) {
        console.log(e);
        }
       }

    async function download_verifications(limit, offset){
        try {
        return await API_get_verifications(limit,offset,var_sortby,var_sortorder,var_search,var_filter);
        } catch (e){
        console.log(e);
        throw e;
        }
       }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_verifications(limit,offset,sortby,sortorder,search,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-credentials','/get-org-verifications/'+JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id,
       {
           queryStringParameters: {
           limit: limit,
           offset: offset,
           sortby: sortby,
           sortorder: sortorder,
           search: search,
           tz: datelib.timezone,
           filtername: filtername
           },
           body: filter
        });

    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_verifications('',0,sortby,sortorder)
    }


    function onClick_verification(item_id){
        if(!var_selected.includes(item_id)){
            set_selected(var_selected => [...var_selected, item_id]);
        } else {
            set_selected(var_selected.filter((i) => i !== item_id));
        }
    }

    function onClick_verify(){
        if (var_selected.length > 0){
            set_target_id(var_selected);
            set_mdl_verifycredential_open(true);
        }
    }

    function onOpen_mdl_history(var_verification_id, verification) {
        set_modaltitle(t('Verification History')+' - '+verification);
        set_target_id(var_verification_id);
        set_mdl_history_open(true)
    }

    function onClick_paperclip(id){
        set_docviewerid(id);
        set_mdl_docviewer_open(true);
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="content-card" id="crd_org_credentials_verifications">
                <div className="content-card-header">
                    <div className="content-card-header-title">{t('Verifications')}</div>
                </div>
                <div className="content-card-gridcontent">
                    <div className="content-filter">
                        <div >
                            <Button className={"btn_primary" + (var_selected.length > 0 ? ' btn_active' : '')} onClick={() => onClick_verify()}>{var_selected.length > 0 ? t('VERIFY SELECTED (')+var_selected.length+')' : t('VERIFY SELECTED')}</Button>
                        </div>
                        <div className="content-filter-item item-pagination">
                        <CMP_TABLECONTROLS
                                var_offset={var_offset} var_limit={var_limit} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_verifications && var_verifications.length > 0 ? var_verifications[0]['totalrows'] : 0} populatefunction={populate_verifications}
                                downloadname='Verifications' downloadfunction={download_verifications}
                                var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                                table_config={[
                                    {name: 'code', datatype: 'text', labelKey: 'CODE', download: true, filter: true},
                                    {name: 'credential', datatype: 'text', labelKey: 'CREDENTIAL', download: true, filter: true},
                                    {name: 'holder', datatype: 'text', labelKey: 'HOLDER', download: true, filter: true},
                                    {name: 'subject_id', datatype: 'text', labelKey: 'SUBJECT ID', download: true, filter: true},
                                    {name: 'issuedby', datatype: 'text', labelKey: 'ISSUED BY', download: true, filter: true},
                                    {name: 'issued', datatype: 'text', labelKey: 'ISSUED', download: true, filter: false},
                                    {name: 'epochissued', datatype: 'date', labelKey: 'ISSUED', download: false, filter: true},
                                    {name: 'submittedfilename', datatype: 'text', labelKey: 'FILE', download: true, filter: true},
                                ]}
                            />

                            </div>
                    </div>
                    <div className="tablewrapper">
                        <Table sortable compact className={(var_loading ? " tbl_loading" : "")}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell sorted={var_sortby === 'code' ? var_sortorder : null} onClick={()=>onClick_sort('code')}>{t('CODE')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'credential' ? var_sortorder : null} onClick={()=>onClick_sort('credential')}>{t('CREDENTIAL')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'holder' ? var_sortorder : null} onClick={()=>onClick_sort('holder')}>{t('HOLDER')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'subject_id' ? var_sortorder : null} onClick={()=>onClick_sort('subject_id')}>{t('SUBJECT ID')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'issuedby' ? var_sortorder : null} onClick={()=>onClick_sort('issuedby')}>{t('ISSUED BY')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'epochissued' ? var_sortorder : null} onClick={()=>onClick_sort('epochissued')}>{t('ISSUED')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_attachment" sorted={var_sortby === 'filename' ? var_sortorder : null} onClick={() => onClick_sort('filename')}><img src={"/icons/paperclip 30px (c9caca).svg?ts=" + Date.now()} alt={t('paperclip icon')}/></Table.HeaderCell>
                                    <Table.HeaderCell className="td_icon_center" >{t('HSTRY')}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {var_ready && var_verifications[0]['totalrows'] !== 0 &&
                            <Table.Body>
                                {var_verifications.map((item, i) =>
                                    <Table.Row key={i} active={var_selected.includes(item.id)}>
                                        <Table.Cell onClick={() => onClick_verification(item.id)}>{item.code}</Table.Cell>
                                        <Table.Cell onClick={() => onClick_verification(item.id)}>{item.credential}</Table.Cell>
                                        <Table.Cell onClick={() => onClick_verification(item.id)}>{item.holder}</Table.Cell>
                                        <Table.Cell onClick={() => onClick_verification(item.id)}>{item.subject_id}</Table.Cell>
                                        <Table.Cell onClick={() => onClick_verification(item.id)}>{item.issuedby}</Table.Cell>
                                        <Table.Cell onClick={() => onClick_verification(item.id)}>{item.epochissued !== null && item.epochissued !== undefined && item.epochissued !== "" ? Moment.unix(item.epochissued).utc(false).format('ll'): ''}</Table.Cell>
                                        <Table.Cell >
                                            {(item.filename !== null && item.filename !== '' && item.filename !== 'No file selected...') ?
                                                <div className='td_attachment' onClick={() => onClick_paperclip(item.id)}> <img src={"/icons/paperclip 60px (717473).svg?ts=" + Date.now()} alt={t('paperclip icon')}/></div>
                                                :
                                                <div className='td_attachment'></div>
                                            }
                                        </Table.Cell>
                                        <Table.Cell className="td_icon_center" onClick={() => onOpen_mdl_history(item.id, item.credential)}><img src={"/icons/history 30px (bcbebe).svg?ts=" + Date.now()} alt={t('history icon')}/></Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                            }
                        </Table>
                        {var_ready && var_verifications[0]['totalrows'] === 0 &&
                        <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t('alert icon')}/>{t('there are no verifications to display')}</div>
                        }
                    </div>
                    <div className="content-filter">
                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                var_offset={var_offset} var_limit={var_limit} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_verifications && var_verifications.length > 0 ? var_verifications[0]['totalrows'] : 0} populatefunction={populate_verifications}
                            />
                        </div>
                    </div>
                </div>



                {/***** MODAL: HISTORY *******************************************************************************/}

                <Modal id="mdl_history"
                       dimmer={'inverted'}
                       open={var_mdl_history_open}
                       onClose={() => set_mdl_history_open(false)}>
                    <MDL_HISTORY
                        set_mdl_open={set_mdl_history_open}
                        var_modaltitle={var_modaltitle}
                        var_target_id={var_target_id}>
                    </MDL_HISTORY>
                </Modal>

                {/***** END MODAL: DETAILS ***************************************************************************/}



                {/***** MODAL: CONFIRM CREDENTIAL ********************************************************************/}

                <Modal id="org-verification_mdl_verifycredential"
                       dimmer={'inverted'}
                       open={var_mdl_verifycredential_open}
                       onClose={() => set_mdl_verifycredential_open(false)}>
                    <MDL_VERIFYCREDENTIAL
                        set_mdl_open={set_mdl_verifycredential_open}
                        populate_verifications={populate_verifications}
                        var_credential_ids={var_target_id}
                    >
                    </MDL_VERIFYCREDENTIAL>
                </Modal>

                {/***** END MODAL: CONFIRM CREDENTIAL ****************************************************************/}


                {/***** MODAL: DOCUMENT VIEW *****************************************************************************/}

                <Modal id="mdl_docviewer"
                    dimmer={'inverted'}
                    open={var_mdl_docviewer_open}
                    onClose={() => set_mdl_docviewer_open(false)}>
                    <MDL_DOCVIEWER
                        set_mdl_open={set_mdl_docviewer_open}
                        targetid={var_docviewerid}
                        classification='credential'
                    />
                </Modal>

                {/***** END MODAL: DOCUMENT VIEW *********************************************************************/}



            </div>
        </>
    )

}
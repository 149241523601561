import React, { useState } from 'react';
import { API } from 'aws-amplify';
import { Form, Button, TextArea } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas';

import '../../../i18n';

import './mdl_review.css';

export default function ({set_mdl_open, target_id, onReviewComplete}) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_sigpad, set_sigpad ] = useState(null);
    const [ var_notes, set_notes ] = useState('');
    const [ var_complete, set_complete ] = useState(false);
    const [ var_processing, set_processing ] = useState(false);

    //  variable listeners ---------------------------------------------------------------------------------------------


    //  async functions ------------------------------------------------------------------------------------------------

    async function post_signature(notes, image) {
        try {
            const body = {
                document_id: target_id,
                organization_id: JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id,
                notes: notes,
                signature: image
            }
            return await API_post_signpdf(body);
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_signpdf(body) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.post('org-forms',
            '/post-signpdf',
            {
                body: body
            }
        );
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_notes_input(event){
        set_notes(event.target.value);
        set_complete(event.target.value.trim().length > 0 && var_sigpad && !var_sigpad.isEmpty());
    }

    function set_signature() {
        set_complete(var_notes.trim() !== '');
    }

    function clear_signature() {
        var_sigpad.clear();
        set_complete(false);
    }

    async function onClick_acknowledge() {
        // Post the details to the endpoint
        set_processing(true);
        await post_signature(var_notes.trim(), var_sigpad.toDataURL('image/png', {}));

        onReviewComplete && onReviewComplete();
        set_processing(false);
        set_mdl_open(false);
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">{t('ACKNOWLEDGE DOCUMENT')}</div>
            <div className="modal-content">
                <form>
                    <Form.Group>
                        <Form.Field
                            id='notes'
                            name='notes'
                            value={var_notes || ''}
                            onChange={onChange_notes_input}
                            control={TextArea}
                            label={t('Supervisor Notes')}
                            placeholder={t('Supervisor Notes...')}
                        />
                    </Form.Group>
                </form>
                <SignatureCanvas penColor='black'
                                 canvasProps={{width: 500, height: 200, className: 'sigCanvas'}}
                                 ref={(ref) => set_sigpad(ref)}
                                 onEnd={set_signature}
                />
                <span className="tiny-text">{t('Sign To Acknowledge')}</span>
                <button className="tiny-button" onClick={clear_signature}>{t('CLEAR')}</button>
            </div>
            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_secondary btn_active" onClick={() => set_mdl_open(false)}>{t('CANCEL')}</Button>
                    <Button className="btn_primary btn_active" disabled={!var_complete || var_processing} onClick={onClick_acknowledge}>{t('ACKNOWLEDGE')}</Button>
                </div>
            </div>
        </>
    )
}

import { Button, Modal } from 'semantic-ui-react'
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { usePlaidLink } from "react-plaid-link";
import { API } from "aws-amplify";
import '../../../../../../i18n';
import CMP_SUCCESS from '../../../../../../components/cmp_success/cmp_success';

import './mdl_plaid.css';


export default function({set_mdl_open, var_link_token}){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();

    const { open, ready } = usePlaidLink({ token: var_link_token, onSuccess: set_access_token});

    const [ var_plaid_success, set_plaid_success ] = useState(false);
    const [ var_mdl_success_open, set_mdl_success_open ] = useState(false);

    //  variable listeners ---------------------------------------------------------------------------------------------
        useEffect(()=> {
            if(var_plaid_success && !var_mdl_success_open) {
                set_mdl_open(false);
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [var_mdl_success_open]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function set_access_token(public_token, metadata) {
        set_plaid_success(true);
        set_mdl_success_open(true);
        await API_plaid_bank_details_verification(public_token, metadata);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_plaid_bank_details_verification(_public_token, metadata) {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('integrations', '/plaid-bank-details-verification/', {
            headers: {
                'Content-Type': 'application/json',
            },
            body: {
                metadata,
                "organization_id": JSON.parse(sessionStorage.getItem('authentication')).organization_id
            }
        });
      }


    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">{t('Bank Account Ownership Details')}</div>
            { !var_plaid_success &&
            <>
            <div className="modal-content">
                <p>{t("Credivera uses an integrated financial services authentication partner to allow you to securely log into your bank and verify the personal identity information your bank has on file, while keeping other banking details private from Credivera.")}</p>
                <p>{t("Upon clicking the button below, you will be taken through our partner’s secure authentication flow.")}</p>
                <p>{t("If your bank is not listed in the authentication flow, we are unable to complete this verification process.")}</p>
            </div>
            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>{t('CANCEL')}</Button>
                    <Button className={'btn_primary' + (ready ? ' btn_active' : '')} onClick={() => open()}>{t('CONTINUE')}</Button>
                </div>
            </div>
            </>
            }

            {/***** MODAL: Success *******************************************************************************/}

            <Modal
                id="mdl_info"
                dimmer={'inverted'}
                open={var_mdl_success_open}
                onClose={() => set_mdl_success_open(false)}>
                <CMP_SUCCESS
                    title={t('Bank Account Ownership Details')}
                    set_mdl_open={set_mdl_success_open}
                    success_or_fail='SUCCESS'
                    body={t('You have completed the verification. A credential has been added to the wallet')}
                />
            </Modal>

            {/***** END MODAL: INFO **************************************************************************/}

        </>
    )
}
import React, {useState} from "react";
import { useTranslation } from 'react-i18next';
import Moment from "moment";

import {
    Text,
    StyleSheet,
    View,
    Image,
  } from '@react-pdf/renderer';


export default function({var_summary}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();
    // eslint-disable-next-line no-unused-vars
    const [ var_date, set_date ] = useState(Moment().utcOffset(new Date().getTimezoneOffset()*-1).format('MMM DD, YYYY'));


    const styles = StyleSheet.create({
        summaryContainer: {
            backgroundColor: '#fafbfb',
            paddingVertical: 8,
            paddingHorizontal: 20,
            borderBottom: 1,
            borderBottomColor: '#ebebeb',
            borderBottomStyle: 'dashed',
        },
        summaryHeader: {
            paddingVertical: 4,
            paddingBottom: 8,
            flexDirection: 'row',
        },
        summaryHeaderTitle: {
            fontSize: 11,
            fontWeight: 500,
            color: '#5c6b70',
        },
        summaryHeaderSubtitle: {
            fontSize: 10,
            fontWeight: 400,
            color: '#969fa2',
            paddingLeft: 3,
        },
        summaryWrapper: {
            flexDirection: 'row',
            display: 'flex',
        },
        summaryHolder: {
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: '10px',
        },
        summaryIcon: {
            width: 16,
            marginRight: 5,
        },
        summaryItem: {
            paddingLeft: 4,
            paddingRight: 4,
        },
        summaryItemNumber: {
            fontSize: 18,
            fontWeight: 700,
            color: '8a8d8c',
            lineHeight: 1,
        },
        summaryItemNumberGreen: {
            color: '#69b536'
        },
        summaryItemNumberRed: {
            color: '#e0454f'
        },
        summaryItemNumberGrey: {
            color: '#8a8d8c'
        },
        summaryItemNumberYellow: {
            color: '#f2Ae02'
        },
        summaryItemText: {
            fontSize: 8,
            fontWeight: 500,
            color: '#cfd3d5',
            lineHeight: 1,
            textTransform: 'uppercase',
            letterSpacing: 0.25
        },
    });

    // RENDER APP ======================================================================================================
    return(
        <View style={styles.summaryContainer}>

            <View style={styles.summaryHeader}>
                <Text style={styles.summaryHeaderTitle}>{t('Location/Project Summary')}</Text>
                <Text style={styles.summaryHeaderSubtitle}>{t('as of ')}{var_date}</Text>
            </View>

            <View style={styles.summaryWrapper}>

                {/* DAILYCHECKS SUMMARY */}
                {var_summary && var_summary.dailycheck != null &&
                <View style={styles.summaryHolder}>
                    <View>
                        <Image src='/pdf/checkmark 60px (717473).png' style={styles.summaryIcon} />
                    </View>
                    <View style={styles.summaryItem}>
                        <View style={styles.summaryItemNumber}>
                            <Text style={styles.summaryItemNumberGreen}>{var_summary.dailycheck[0].pass}</Text>
                        </View>
                        <View style={styles.summaryItemText}>
                            <Text>{t('PASSED')}</Text>
                        </View>
                        <View style={styles.summaryItemText}>
                            <Text>{t('CHECKS')}</Text>
                        </View>
                    </View>
                    <View style={styles.summaryItem}>
                        <View style={styles.summaryItemNumber}>
                            <Text style={styles.summaryItemNumberRed}>{var_summary.dailycheck[0].fail}</Text>
                        </View>
                        <View style={styles.summaryItemText}>
                            <Text>{t('FAILED')}</Text>
                        </View>
                        <View style={styles.summaryItemText}>
                            <Text>{t('CHECKS')}</Text>
                        </View>
                    </View>
                    <View style={styles.summaryItem}>
                        <View style={styles.summaryItemNumber}>
                            <Text style={styles.summaryItemNumberGrey}>{var_summary.dailycheck[0].missing}</Text>
                        </View>
                        <View style={styles.summaryItemText}>
                            <Text>{t('MISSING')}</Text>
                        </View>
                        <View style={styles.summaryItemText}>
                            <Text>{t('CHECKS')}</Text>
                        </View>
                    </View>
                </View>
                }

                {/* CREDENTIALS SUMMARY */}
                {var_summary && var_summary.credential != null &&
                <View style={styles.summaryHolder}>
                    <View style={styles.summaryIcon}>
                        <Image src='/pdf/credential 60px (717473).png' />
                    </View>
                    <View style={styles.summaryItem}>
                        <View style={styles.summaryItemNumber}>
                            <Text style={styles.summaryItemNumberGreen}>{var_summary.credential[0].valid}</Text>
                        </View>
                        <View style={styles.summaryItemText}>
                            <Text>{t('VALID')}</Text>
                        </View>
                        <View style={styles.summaryItemText}>
                            <Text>{t('CREDENTIALS')}</Text>
                        </View>
                    </View>
                    <View style={styles.summaryItem}>
                        <View style={styles.summaryItemNumber}>
                            <Text style={styles.summaryItemNumberGreen}>{var_summary.credential[0].comingdue}</Text>
                        </View>
                        <View style={styles.summaryItemText}>
                            <Text>{t('CREDENTIALS')}</Text>
                        </View>
                        <View style={styles.summaryItemText}>
                            <Text>{t('COMING DUE')}</Text>
                        </View>
                    </View>
                    <View style={styles.summaryItem}>
                        <View style={styles.summaryItemNumber}>
                            <Text style={styles.summaryItemNumberRed}>{var_summary.credential[0].invalid}</Text>
                        </View>
                        <View style={styles.summaryItemText}>
                            <Text>{t('INVALID')}</Text>
                        </View>
                        <View style={styles.summaryItemText}>
                            <Text>{t('CREDENTIALS')}</Text>
                        </View>
                    </View>
                </View>
                }

                {/* ONSITE SUMMARY */}
                {var_summary && var_summary.onsite != null &&
                <View style={styles.summaryHolder}>
                    <View>
                        <Image src='/pdf/flag 60px (717473).png' style={styles.summaryIcon} />
                    </View>
                    <View style={styles.summaryItem}>
                        <View style={styles.summaryItemNumber}>
                            <Text style={styles.summaryItemNumberGreen}>{var_summary.onsite[0].onsite}</Text>
                        </View>
                        <View style={styles.summaryItemText}>
                            <Text>{t('CONFIRM')}</Text>
                        </View>
                        <View style={styles.summaryItemText}>
                            <Text>{t('ONSITE')}</Text>
                        </View>
                    </View>
                    <View style={styles.summaryItem}>
                        <View style={styles.summaryItemNumber}>
                            <Text style={styles.summaryItemNumberYellow}>{var_summary.onsite[0].offsite}</Text>
                        </View>
                        <View style={styles.summaryItemText}>
                            <Text>{t('CONFIRM')}</Text>
                        </View>
                        <View style={styles.summaryItemText}>
                            <Text>{t('OFFSITE')}</Text>
                        </View>
                    </View>
                    <View style={styles.summaryItem}>
                        <View style={styles.summaryItemNumber}>
                            <Text style={styles.summaryItemNumberGrey}>{var_summary.onsite[0].missing}</Text>
                        </View>
                        <View style={styles.summaryItemText}>
                            <Text>{t('CONFIRM')}</Text>
                        </View>
                        <View style={styles.summaryItemText}>
                            <Text>{t('MISSING')}</Text>
                        </View>
                    </View>
                </View>
                }

            </View>

        </View>
    )
}

import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import auth from '../../../libs/auth-lib';
import "./individuals.css";
import { useTranslation } from 'react-i18next';
import '../../../i18n';

import CRD_INDIVIDUALS from "./crd_individuals/crd_individuals";


export default function(appProps) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const history = useHistory();



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if (auth.is_loggedin && auth.permission_id === auth.constants.permission_ids.Superadmin) {
            appProps.set_visibleframe(true);
            appProps.set_visiblemenu('ORGANIZATION');
            appProps.set_activemenu('/org/individuals');
        }else{
            history.push('/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);



    //  async functions ------------------------------------------------------------------------------------------------

    //  API calls ------------------------------------------------------------------------------------------------------

    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return(
        <>
            {auth.is_loggedin &&
            <div className="content">
                <div
                    className="content-topmenu">{auth.organization} {t("Individuals")}
                </div>
                <div className="content-wrapper">

                    <CRD_INDIVIDUALS></CRD_INDIVIDUALS>

                </div>
            </div>
            }
        </>
    )
};

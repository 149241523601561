import React from "react";
import { useTranslation } from 'react-i18next';
import '../../../../i18n';

import './sta_dailycheck.css';

export default function({ var_dailyCheckSummary }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    //  event listeners ------------------------------------------------------------------------------------------------


    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------


    // RENDER APP ======================================================================================================

    return (
        <>
            <div id="dailychecksummary" className="summary_wrapper">
                <div className="summary_icon">
                    <img src={"/icons/checkmark 60px (717473).svg?ts=" + Date.now()} alt={t('checkmark icon')}/>
                </div>
                <div className="summary_item">
                    <div className="summary_item_number green">{var_dailyCheckSummary === undefined? '' : (var_dailyCheckSummary.pass || 0)}</div>
                    <div className="summary_item_text">{t('PASSED')}</div>
                    <div className="summary_item_text">{t('CHECKS')}</div>
                </div>
                <div className="summary_item">
                    <div className="summary_item_number red">{var_dailyCheckSummary === undefined? '' : (var_dailyCheckSummary.fail || 0)}</div>
                    <div className="summary_item_text">{t('FAILED')}</div>
                    <div className="summary_item_text">{t('CHECKS')}</div>
                </div>
                <div className="summary_item">
                    <div className="summary_item_number">{var_dailyCheckSummary === undefined? '' : (var_dailyCheckSummary.missing || 0)}</div>
                    <div className="summary_item_text">{t('MISSING')}</div>
                    <div className="summary_item_text">{t('CHECKS')}</div>
                </div>
            </div>
        </>
    )

}
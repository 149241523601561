import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, TextArea, Checkbox, Input } from 'semantic-ui-react';

import MDL_CONFIRMATION from '../../../../../../../components/cmp_confirmation/cmp_confirmation';
import { useTranslation } from 'react-i18next';
import '../../../../../../../i18n';

import './mdl_question.css';



export default function({set_mdl_question_open, question, can_fail_on_yes, can_fail_on_no, on_save}){

    //  variable declarations ------------------------------------------------------------------------------------------

    const DEFAULT_QUARANTINE_DAYS = 14;

    const [ var_question, set_question ] = useState({});
    const [ var_ready, set_ready] = useState(false);
    const [ var_processing, set_processing] = useState(false);
    const [ var_errors, set_errors ] = useState([]);
    const { t } = useTranslation();

    const [ var_mdl_delete_open, set_mdl_delete_open ] = useState(false);


    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        set_question((question === null || question.id === null) ? { ...(question === null ? {} : question), fail_on_yes: 'NO', fail_on_no: 'NO' } : { ...question });
        set_ready(false);
    }, [question]);

    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_category(e, { value }) {
        set_question({ ...var_question, category: value });
        set_errors([]);
        set_ready(true);
    }

    function onChange_visible_to_individual_type(value) {
        set_question({ ...var_question, visible_to_individual_type: value });
        set_errors([]);
        set_ready(true);
    }

    function onChange_question_text(event) {
        set_question({ ...var_question, question: event.target.value });
        set_errors([]);
        set_ready(true);
    }

    function onChange_failtrigger(yes_or_no, value) {
        if (yes_or_no === 'YES') {
            set_question({ ...var_question, fail_on_yes: value ? 'YES' : 'NO' });
        } else {
            set_question({ ...var_question, fail_on_no: value ? 'YES' : 'NO' });
        }
        set_errors([]);
        set_ready(true);
    }

    function onChange_quarantinedays(yes_or_no, value) {
        if (yes_or_no === 'YES') {
            set_question({ ...var_question, yes_quarantine_days: value });
        } else {
            set_question({ ...var_question, no_quarantine_days: value });
        }
        set_errors([]);
        set_ready(true);
    }

    function onClick_submit(){
        if (var_ready && !var_processing) {
            set_processing(true);
            // Error check
            let errors = validate_question();
            set_errors(errors);
            if (errors.length > 0) {
                set_ready(false);
                set_processing(false);
                return;
            }

            // Clean up the question
            let question = { ...var_question };
            question.question = question.question.trim();
            question.yes_quarantine_days = question.fail_on_yes === 'YES' ? Number(question.yes_quarantine_days) : null;
            question.no_quarantine_days = question.fail_on_no === 'YES' ? Number(question.no_quarantine_days) : null;

            set_question(question);
            on_save(question, false);
            set_mdl_question_open(false);

            set_processing(false);
        }
    }

    function validate_question() {
        let errors = [];
        if (!var_question.category) {
            errors.push('Please select a category for this question');
        }
        if (!var_question.visible_to_individual_type) {
            errors.push('Please select the visibility for this question');
        }
        if (!var_question.question || var_question.question.trim().length === 0) {
            errors.push('Please provide the text for this question');
        }
        let num, valid;
        if (var_question.fail_on_yes === 'YES') {
            if (var_question.yes_quarantine_days === undefined || var_question.yes_quarantine_days === null || var_question.yes_quarantine_days.toString().trim().length === 0) {
                errors.push("Please enter the number of quarantine days if the answer is 'YES'");
            } else {
                num = Number(var_question.yes_quarantine_days);
                valid = !Number.isNaN(num) && Math.floor(num) === num && num >= 0 && num < 100;
                if (!valid) {
                    errors.push('The number of quarantine days is invalid');
                }
            }
        }
        if (var_question.fail_on_no === 'YES') {
            if (var_question.no_quarantine_days === undefined || var_question.no_quarantine_days === null || var_question.no_quarantine_days.toString().trim().length === 0) {
                errors.push("Please enter the number of quarantine days if the answer is 'NO'");
            } else {
                num = Number(var_question.no_quarantine_days);
                valid = !Number.isNaN(num) && Math.floor(num) === num && num >= 0 && num < 100;
                if (!valid) {
                    errors.push('The number of quarantine days is invalid');
                }
            }
        }
        return errors;
    }

    function onClick_delete() {
        set_mdl_delete_open(true);
    }

    function on_delete() {
        set_question(null);
        on_save(null, true);
        set_mdl_question_open(false);
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='modal-header'>{question !== null ? t('Edit Question') : t('Add Question') }</div>
            <div className='modal-content'>
                <>
                    <Form>
                        <Form.Group>
                            <Form.Select
                                className='category'
                                name='category'
                                label={t('CATEGORY')}
                                options={[  {text: t('Symptoms'), value: t('Symptoms')},
                                            {text: t('Close Contact'), value: t('Close Contact')},
                                            {text: t('Travel'), value: t('Travel')},
                                            {text: t('COVID-19 Testing'), value: 'COVID-19 Testing'},
                                            {text: t('COVID-19 Vaccination'), value: 'COVID-19 Vaccination'}]}
                                value={var_question.category || ''}
                                onChange={onChange_category}
                                placeholder={t('Select a category...')}
                            />
                            <div className='field visible_to_individual_type'>
                                <label>{t('VISIBILITY')}</label>
                                <Button.Group>
                                    <Button
                                        onClick={() => onChange_visible_to_individual_type('GUEST')}
                                        className={var_question.visible_to_individual_type === 'GUEST' ? 'btn_primary btn_active' : 'btn_secondary'}>{t('GUEST')}</Button>
                                    <Button.Or text={t('OR')} />
                                    <Button
                                        onClick={() => onChange_visible_to_individual_type('USER')}
                                        className={var_question.visible_to_individual_type === 'USER' ? 'btn_primary btn_active' : 'btn_secondary'}>{t('USER')}</Button>
                                    <Button.Or text={t('OR')} />
                                    <Button
                                        onClick={() => onChange_visible_to_individual_type('ALL')}
                                        className={var_question.visible_to_individual_type === 'ALL' ? 'btn_primary btn_active' : 'btn_secondary'}>{t('ALL')}</Button>
                                </Button.Group>
                            </div>
                        </Form.Group>
                        <Form.Field
                            id='question'
                            name='question'
                            value={var_question.question || ''}
                            onChange={onChange_question_text}
                            control={TextArea}
                            label={t('QUESTION')}
                            placeholder={t('Question...')}
                        />
                        <p>{t(`Every question can be answered with 'YES' or 'NO'. Select if the response would cause the individual
                            to fail their daily check and how many days they will need to remain in quarantine if they fail this question.`)}</p>
                        <Form.Group className='yes_no_group'>
                            <div className='yes_no_label'><label>{t('YES')}</label></div>
                            {can_fail_on_yes ?
                                <>
                                    <Form.Field>
                                        <Checkbox label={t('FAIL')} toggle
                                        onChange={(e, data) => onChange_failtrigger('YES', data.checked)}
                                        checked={var_question.fail_on_yes === 'YES'}/>
                                    </Form.Field>
                                    {var_question.fail_on_yes === 'YES' &&
                                        <Form.Field
                                            className='quarantinedays'
                                            value={var_question.yes_quarantine_days || ''}
                                            onChange={(e, data) => onChange_quarantinedays('YES', data.value)}
                                            control={Input}
                                            type='number'
                                            min={0}
                                            max={99}
                                            label={t('QUARANTINE DAYS')}
                                            placeholder={t('Quarantine Days...')}
                                            inline={true}
                                        />
                                    }
                                </>
                                :
                                <div className='sub_question_message'>{t("Answering 'YES' will display child questions")}</div>
                            }
                        </Form.Group>
                        <Form.Group className='yes_no_group'>
                            <div className='yes_no_label'><label>{t('NO')}</label></div>
                            {can_fail_on_no ?
                                <>
                                    <Form.Field>
                                        <Checkbox label={t('FAIL')} toggle
                                        onChange={(e, data) => onChange_failtrigger('NO', data.checked)}
                                        checked={var_question.fail_on_no === 'YES'}/>
                                    </Form.Field>
                                    {var_question.fail_on_no === 'YES' &&
                                        <Form.Field
                                            className='quarantinedays'
                                            value={var_question.no_quarantine_days || ''}
                                            onChange={(e, data) => onChange_quarantinedays('NO', data.value)}
                                            control={Input}
                                            type='number'
                                            min={0}
                                            max={99}
                                            label={t('QUARANTINE DAYS')}
                                            placeholder={t('Quarantine Days...')}
                                            inline={true}
                                        />
                                    }
                                </>
                                :
                                <div className='sub_question_message'>{t("Answering 'NO' will display child questions")}</div>
                            }
                        </Form.Group>
                    </Form>
                    {var_errors.length > 0 &&
                        <div id ='reqfielderror' className='message warning'>
                            <div className='message_icon'><img src={'/icons/warning 60px (d91e18).svg?ts=' + Date.now()} alt={t('warning icon')} /></div>
                            <div className='message_text_wrapper'>
                            {var_errors.map((message, i) => (
                                <div key={i} className='message_text cause'>{t(message)}</div>
                            ))}
                            </div>
                        </div>
                    }
                </>
            </div>
            <div className='modal-footer'>
                <div className='modal-footer-buttons'>
                    <Button className='btn_secondary' onClick={() => set_mdl_question_open(false)}>{t('CANCEL')}</Button>
                    {question !== null && <Button className='btn_secondary' onClick={() => onClick_delete()}>{t('DELETE QUESTION')}</Button>}
                    <Button loading={var_processing} className={'btn_primary ' + (var_ready ? ' btn_active' : '')} onClick={() => onClick_submit()}>{t('SUBMIT QUESTION')}</Button>
                </div>
            </div>

            {/***** MODAL: DELETE ************************************************************************************/}

            <Modal id='dailycheck_mdl_deletequestion'
                   dimmer={'inverted'}
                   open={var_mdl_delete_open}
                   onClose={() => set_mdl_delete_open(false)}>
                <MDL_CONFIRMATION
                    set_mdl_open={set_mdl_delete_open}
                    var_modaltitle={t('Delete Question')}
                    var_message={
                        var_question.has_children
                        ? <>{t('Do you want to delete this question?')}<br /><br />{t('This will also delete all dependent questions.')}</>
                        : t('Do you want to delete this question?')
                    }
                    confirmation_function={on_delete}>
                </MDL_CONFIRMATION>
            </Modal>

            {/***** END MODAL: DELETE ********************************************************************************/}

        </>
    )
}
import { useEffect, useState } from "react";
import Dropzone from 'react-dropzone';
import jsPDF from 'jspdf'
import {API, Storage} from "aws-amplify";
import { Button, Form, Input, Progress, Icon } from 'semantic-ui-react'
import { v4 as uuidv4 } from "uuid";
import React from "react";
import { useTranslation } from 'react-i18next';
import '../../i18n';

import './cmp_fileupload.css';


export default function({ var_fileupload, set_fileupload, var_fileuploaddirectory, var_fileuploadlabel, var_fileuploadstatus, set_fileuploadstatus, var_fileuploadname, set_fileuploadname, var_fileuploaddisplayname, set_fileuploaddisplayname, var_placeholder, var_filetype, var_fileextension, var_filesize, var_ready, set_ready, var_multiple_files }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_progress, set_progress ] = useState(0);
    const [ var_filetypeerror, set_filetypeerror ] = useState(false);
    const [ var_filesizeerror, set_filesizeerror ] = useState(false);
    const [var_pdf_generated, set_pdf_generated] = useState(false);



    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_fileuploadstatus === 'START'){
            if (var_multiple_files && var_fileupload && !var_pdf_generated) {
                generatePdfFromImages();
            } else {
                uploadfile();
            }
        } else if (var_fileuploadstatus === 'VALIDATING'){
            set_fileuploadstatus('PENDING');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_fileuploadstatus]);

    useEffect(() => {
        if (var_pdf_generated) {
            uploadfile();
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_fileupload, var_pdf_generated])

    //  async functions ------------------------------------------------------------------------------------------------

    async function uploadfile(){

        set_fileuploadstatus('UPLOADING');

        const CustomFunc = progress => {
            set_progress(Math.round((progress.loaded/progress.total)*100))
        };

        try {
            await Storage.put(var_fileuploaddirectory+'/'+var_fileuploadname, var_fileupload, {
                progressCallback: progress => {
                    CustomFunc(progress);
                },
            });
            // set_fileuploadstatus('SCANNING');
            // await asyncWait(1000);
            await API_post_scanfile();
            set_fileuploadstatus('COMPLETE');
        } catch (e) {
            set_fileuploadstatus('FAIL');
        }
    }

    const asyncWait = ms => new Promise(resolve => setTimeout(resolve, ms))


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_scanfile(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        let file_type;
        switch(var_fileuploaddirectory) {
            case 'forms':
                file_type = 'form';
                break;
            case 'certificate':
                file_type = 'credential';
                break;
            case 'courses':
                file_type = 'course';
                break;
            case 'documents':
                file_type = 'document';
                break;
            case 'logo':
                file_type = 'logo';
                break;
            case 'orgcredential':
                file_type = 'orgcredential';
                break;
            default:
                file_type = 'credential';
        }
        let post_body = [
            {
                "type": file_type,
                "file": var_fileuploadname
            }
        ]

        return API.post('scanner', '/post-scanfile', { body: post_body});
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function generatePdfFromImages() {

        const pdf = new jsPDF();
        const pdf_width = pdf.internal.pageSize.getWidth();
        const pdf_height = pdf.internal.pageSize.getHeight();
        // delete the first default page
        pdf.deletePage(1);
        let images_to_load = var_fileupload.map(obj => obj.src);
        loadImages(images_to_load, pdf, pdf_width, pdf_height);

    }

    function loadImages(images_to_load, pdf, pdf_width, pdf_height) {
        if (images_to_load.length === 0) {
            generatePDF(pdf);
            set_pdf_generated(true);
            return;
        }
        let url = images_to_load.shift();
        const img = new Image();
        img.src = url;
        img.onload = function() {
            const widthRatio = pdf_width / img.naturalWidth;
            const heightRatio = pdf_height / img.naturalHeight;
            const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

            pdf.addPage();
            pdf.addImage(
                img.src,
                (img.type === 'image/png' ? 'PNG' : 'JPEG'),
                // Image is positioned on top left corner
                0,
                0,
                img.naturalWidth * ratio,
                img.naturalHeight * ratio
            );
            loadImages(images_to_load, pdf, pdf_width, pdf_height);
        };
    }

    function generatePDF(pdf) {
        const pdfObject = pdf.output("blob");
        const file = new File([pdfObject], "filename.pdf");
        set_fileupload(file);
    }

    function onClick_selectfile(){
        set_filetypeerror(false);
        set_filesizeerror(false);
        document.getElementById('btn_hiddenfileinput').click();
    }

    function onChange_selectfile(event){
        if (event.target.files[0]) {
            set_ready(false);
            set_fileuploaddisplayname(event.target.files[0].name);

            let max_allowedsize = var_filesize * 1024 * 1024;
            if(var_fileextension && var_fileextension.indexOf(event.target.files[0].name.split('.').pop().toLowerCase()) < 0){
                set_fileupload(null);
                set_fileuploaddisplayname(null);
                set_fileuploadname(null);
                set_filetypeerror(true);
            }else if (event.target.files[0].size > max_allowedsize){
                set_fileupload(null);
                set_fileuploaddisplayname(null);
                set_fileuploadname(null);
                set_filesizeerror(true);
            }else {
                set_fileupload(event.target.files[0]);
                // Always use a uuid to upload with
                set_fileuploadname(uuidv4() + '.' + event.target.files[0].name.split('.').pop());
                set_fileuploadstatus('VALIDATING');
                set_ready(true);
            }
        }
    }

    function onDrop(acceptedFiles){
        if (acceptedFiles.length !== 0){

            let sum1, sum2 = 0;
            sum1 = acceptedFiles.reduce(function(sum, file) {
                return sum + file.size;
            }, 0);
            if (var_fileupload) {
                sum2 = var_fileupload.reduce(function(sum, file) {
                    return sum + file.size;
                }, 0);
            }
            if (sum1 + sum2 > var_filesize * 1024 * 1024) {
                set_filesizeerror(true);
                return
            }

            if (var_fileupload) {
            set_fileupload(var_fileupload.concat(acceptedFiles.map(file => Object.assign(file, {
                src: URL.createObjectURL(file)
            }))));
            } else {
                set_fileupload(acceptedFiles.map(file => Object.assign(file, {
                    src: URL.createObjectURL(file)
                })));
            }
            set_filetypeerror(false);
            set_filesizeerror(false);
            set_fileuploadstatus('VALIDATING');
            set_fileuploadname(uuidv4() + '.pdf');
        }
    }

    function onDropRejected(event) {
        if (event[0].errors[0].code === 'file-too-large') {
            set_filesizeerror(true);
        }
    }

    function removeListItem(event, file) {
        event.stopPropagation();
        const index = var_fileupload.indexOf(file);
        if (var_fileupload.length === 1) {
            set_fileupload(null);
        } else {
            set_fileupload(var_fileupload.slice(0, index).concat(var_fileupload.slice(index + 1, var_fileupload.length)));
        }
    }


    // RENDER APP ======================================================================================================

    return (
        <div className="cmp_fileupload">
            {(var_fileuploadstatus === '' || var_fileuploadstatus === 'VALIDATING' || var_fileuploadstatus === 'PENDING') &&
            <>
            {var_multiple_files ?
            <>
            {var_fileuploadlabel && <label>{t(var_fileuploadlabel)}</label>}
            <Dropzone onDrop={onDrop} onDropRejected={onDropRejected} multiple accept={var_filetype} maxSize={var_filesize * 1024 * 1024}>
                {({getRootProps, getInputProps, acceptedFiles, isDragActive, isDragReject, fileRejections}) => (
                    <div className="file-dropzone" {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className='dropzone-user-instruction'>
                            <img src={'/icons/upload 30px (000000).svg?ts=' + Date.now()} alt={t('upload icon')}/>
                            <div>{t('Drag and Drop your files here')}</div>
                            <div>{t('OR')}</div>
                            <Button className="btn_secondary btn_active">{t('SELECT FILES')}</Button>
                        </div>
                        {isDragReject && set_filetypeerror(true)}
                        <ul className="file-upload-list">
                            {var_fileupload && var_fileupload.length > 0 && var_fileupload.map(file => (
                                <li key={file.name + var_fileupload.indexOf(file)}>
                                <div className="modal-header-close" onClick={(event) => removeListItem(event, file)}>
                                    {file.name} {'-'} {(file.size / 1024).toFixed(2) + ' KB'}
                                    <Icon name="close"></Icon>
                                </div>
                                </li>
                            ))
                            }
                        </ul>
                    </div>
                )}
            </Dropzone>
            </>
            :
            <Form className="fileupload">
                <Form.Group>
                    <input
                        id="btn_hiddenfileinput"
                        type="file"
                        style={{display: "none"}}
                        onChange={onChange_selectfile}
                        accept={var_filetype}
                    />

                    <Form.Field
                        className='selectfile'
                        name='selectfile'
                        control={Input}
                        action={{
                            content: t('SELECT FILE'),
                            onClick: onClick_selectfile
                        }}
                        label={var_fileuploadlabel}
                        value={var_fileuploaddisplayname || ''}
                        placeholder={var_placeholder}
                    />
                </Form.Group>
            </Form>

            }
            </>
            }

            {var_fileuploadstatus === 'UPLOADING' &&
            <Progress className="mdl_createresource_progressbar" percent={var_progress} progress/>
            }

            {var_fileuploadstatus === 'SCANNING' &&
            <div className="message success">
                <div className="message_icon"><img src={"/icons/clock 60px (8a8d8c).svg?ts=" + Date.now()} alt="clock icon"/></div>
                <div className="message_text_wrapper">
                    <div className="message_text resolution">{t('Processing file...')}</div>
                </div>
            </div>
            }

            {var_fileuploadstatus === 'COMPLETE' &&
            <div className="message success">
                <div className="message_icon"><img src={"/icons/checkmark 60px (69b536).svg?ts=" + Date.now()} alt="checkmark icon"/></div>
                <div className="message_text_wrapper">
                    <div className="message_text resolution">{t('File processing complete!')}</div>
                </div>
            </div>
            }

            {(var_fileuploadstatus === 'FAIL' || var_filetypeerror || var_filesizeerror) &&
            <div className="message warning">
                <div className="message_icon"><img src={"/icons/x 60px (e0454f).svg?ts=" + Date.now()} alt="x icon"/></div>
                <div className="message_text_wrapper">
                    <div className="message_text resolution">
                        {var_filetypeerror && 
                        t('You have selected an invalid file type')
                        }
                        {var_filesizeerror &&
                        t('File size larger than 10 MB. Please reduce the file size and try again')
                        }
                        {var_fileuploadstatus === 'FAIL' &&
                        t('File upload failed')
                        } 
                    </div>
                </div>
            </div>
            }


        </div>
    )
}
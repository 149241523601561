import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import '../../../../i18n';
import auth from '../../../../libs/auth-lib';

import TAB_DETAILS from "./tab_details/tab_details";
import TAB_CREDENTIALS from "./tab_credentials/tab_credentials";
import TAB_REQUIREMENTS from "./tab_requirements/tab_requirements";
import TAB_DAILYCHECKS from "./tab_dailychecks/tab_dailychecks";
import TAB_LOCATIONS from "./tab_locations/tab_locations";
import TAB_HISTORY from "./tab_history/tab_history";

import './mdl_indv.css';



export default function({ set_mdl_open, var_individual_id, populate_function }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_activetab, set_activetab] = useState('DETAILS');
    const [ var_indv_from_org, set_indv_from_org ] = useState(false);


    //  variable listeners ---------------------------------------------------------------------------------------------



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_tab(var_tab){
        set_activetab(var_tab);
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{t("Individual Details")}</div>
                <div className="modal-header-tabs">
                    <div className={"modal-header-tabs-item"  + (var_activetab === 'DETAILS' ? ' active' : '')} onClick={() => onClick_tab(`DETAILS`)}>
                        {t("Details")}
                    </div>
                    {JSON.parse(sessionStorage.getItem('authentication')).org_credentials === 'YES' &&
                    <div className={"modal-header-tabs-item" + (var_activetab === 'CREDENTIALS' ? ' active' : '')} onClick={() => onClick_tab(`CREDENTIALS`)}>
                        {t("Credentials")}
                    </div>
                    }
                    {auth.org_requirements &&
                    <div className={"modal-header-tabs-item" + (var_activetab === 'REQUIREMENTS' ? ' active' : '')} onClick={() => onClick_tab(`REQUIREMENTS`)}>
                        {t("Requirements")}
                    </div>
                    }
                    {JSON.parse(sessionStorage.getItem('authentication')).org_dailycheck === 'YES' &&
                    <div className={"modal-header-tabs-item" + (var_activetab === 'DAILYCHECKS' ? ' active' : '')} onClick={() => onClick_tab(`DAILYCHECKS`)}>
                        {t("Daily Checks")}
                    </div>
                    }
                    <div className={"modal-header-tabs-item"  + (var_activetab === 'LOCATIONS' ? ' active' : '')} onClick={() => onClick_tab(`LOCATIONS`)}>
                        {t("Locations & Projects")}
                    </div>
                    <div className={"modal-header-tabs-item"  + (var_activetab === 'HISTORY' ? ' active' : '')} onClick={() => onClick_tab(`HISTORY`)}>
                        {t("History")}
                    </div>
                </div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
            </div>
            <div className="modal-tabs">
                <div className={(var_activetab !== 'DETAILS' ? 'modal-tab-hide' : '')}>
                    <TAB_DETAILS
                        set_mdl_open={set_mdl_open}
                        var_activetab={var_activetab}
                        var_individual_id={var_individual_id}
                        populate_function={populate_function}
                        set_indv_from_org={set_indv_from_org}
                        var_indv_from_org={var_indv_from_org}>
                    </TAB_DETAILS>
                </div>
                <div className={(var_activetab !== 'CREDENTIALS' ? 'modal-tab-hide' : '')}>
                    <TAB_CREDENTIALS
                        set_mdl_open={set_mdl_open}
                        var_activetab={var_activetab}
                        var_individual_id={var_individual_id}
                        populate_function={populate_function}
                        var_indv_from_org={var_indv_from_org}>
                    </TAB_CREDENTIALS>
                </div>
                <div className={(var_activetab !== 'REQUIREMENTS' ? 'modal-tab-hide' : '')}>
                    <TAB_REQUIREMENTS
                        activetab={var_activetab}
                        individual_id={var_individual_id}>
                    </TAB_REQUIREMENTS>
                </div>
                <div className={(var_activetab !== 'DAILYCHECKS' ? 'modal-tab-hide' : '')}>
                    <TAB_DAILYCHECKS
                        set_mdl_open={set_mdl_open}
                        var_activetab={var_activetab}
                        var_individual_id={var_individual_id}
                        populate_function={populate_function}>
                    </TAB_DAILYCHECKS>
                </div>
                <div className={(var_activetab !== 'LOCATIONS' ? 'modal-tab-hide' : '')}>
                    <TAB_LOCATIONS
                        set_mdl_open={set_mdl_open}
                        var_activetab={var_activetab}
                        var_individual_id={var_individual_id}
                        populate_function={populate_function}>
                    </TAB_LOCATIONS>
                </div>
                <div className={(var_activetab !== 'HISTORY' ? 'modal-tab-hide' : '')}>
                    <TAB_HISTORY
                        set_mdl_open={set_mdl_open}
                        var_activetab={var_activetab}
                        var_individual_id={var_individual_id}
                        populate_function={populate_function}>
                    </TAB_HISTORY>
                </div>
            </div>
        </>
    )
}
import { API } from "aws-amplify";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import './sta_issuer.css';
import datelib from '../../../../libs/date-lib';
import { useTranslation } from 'react-i18next';
import '../../../../i18n';



export default function(){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const history = useHistory();
    const [ var_verifications, set_verifications ] = useState([]);
    const [ var_limit, set_limit] = useState(100);
    const [ var_offset, set_offset] = useState(0);
    const [ var_search, set_search ] = useState('');
    const [ var_sortby, set_sortby ] = useState('holder');
    const [ var_sortorder, set_sortorder ] = useState('ascending');



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        populate_verifications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_verifications(limit,offset,sortby,sortorder,search){

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        if(search) {
            if(search === '-') {
                search = '';
            }
            set_search(search);
        }else{
            search=var_search
        }

        try {
            let results = await API_get_verifications(limit,offset,sortby,sortorder,search);
            set_verifications(results[0]['totalrows']);
        } catch (e) {
            console.log(e);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_verifications(limit,offset,sortby,sortorder,search){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-credentials', '/get-org-verifications/'+JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id+'?limit='+limit+'&offset='+offset+'&sortby='+sortby+'&sortorder='+sortorder+'&search='+search+'&tz='+datelib.querystring_timezone);
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_verifications(){
        history.push('/org/verifications');
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="stat-card" id="sta_org_overview_issuer" onClick={()=>onClick_verifications()}>
                <div className="stat-card-header">
                    <div className="content-card-subheader_text">{t("Verifications")}</div>
                </div>
                <div className="stat-card-stat">
                    {var_verifications}
                </div>
                <div className="stat-card-text">
                    {t("PENDING VERIFICATIONS")}
                </div>
            </div>
        </>
    )

}

import React, { useState } from "react";

import './mdl_update.css';
import TAB_DETAILS from "./tab_details/tab_details";
import TAB_MISSING from "./tab_missing/tab_missing";
import TAB_ACKNOWLEDGED from "./tab_acknowledged/tab_acknowledged";
import TAB_LOCATIONS from "./tab_locations/tab_locations";

import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

export default function({ set_mdl_open, var_target_id, populate_function,var_ack_req,var_check_id}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();
    const [ var_activetab, set_activetab] = useState('DETAILS');


    //  variable listeners ---------------------------------------------------------------------------------------------



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_tab(var_tab){
        set_activetab(var_tab);
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{t('Update Document')}</div>
                <div className="modal-header-tabs">
                    <div className={"modal-header-tabs-item"  + (var_activetab === 'DETAILS' ? ' active' : '')} onClick={() => onClick_tab(`DETAILS`)}>
                    {t('Details')}
                    </div>
                    {var_ack_req==="YES" && <div className={"modal-header-tabs-item"  + (var_activetab === 'ACKNOWLEDGED' ? ' active' : '')} onClick={() => onClick_tab(`ACKNOWLEDGED`)}>
                    {t('Acknowledged')}
                    </div>}
                    {var_ack_req==="YES" && <div className={"modal-header-tabs-item"  + (var_activetab === 'MISSING' ? ' active' : '')} onClick={() => onClick_tab(`MISSING`)}>
                    {t('Missing')}
                    </div>}
                    <div className={"modal-header-tabs-item"  + (var_activetab === 'LOCATIONS' ? ' active' : '')} onClick={() => onClick_tab(`LOCATIONS`)}>
                    {t('Locations & Projects')}
                    </div>
                </div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t('close icon')}/></div>
            </div>
            <div className="modal-tabs">
                <div className={(var_activetab !== 'DETAILS' ? 'modal-tab-hide' : '')}>
                    <TAB_DETAILS
                        var_activetab={var_activetab}
                        var_document_id={var_target_id}
                        populate_function={populate_function}>
                    </TAB_DETAILS>
                </div>
                <div className={(var_activetab !== 'MISSING' ? 'modal-tab-hide' : '')}>
                    <TAB_MISSING
                        var_activetab={var_activetab}
                        var_document_id={var_target_id}>
                    </TAB_MISSING>
                </div>
                <div className={(var_activetab !== 'ACKNOWLEDGED' ? 'modal-tab-hide' : '')}>
                    <TAB_ACKNOWLEDGED
                        var_activetab={var_activetab}
                        var_document_id={var_target_id}>
                    </TAB_ACKNOWLEDGED>
                </div>
                <div className={(var_activetab !== 'LOCATIONS' ? 'modal-tab-hide' : '')}>
                    <TAB_LOCATIONS
                        var_activetab={var_activetab}
                        var_document_id={var_target_id}
                        populate_function={populate_function}>
                    </TAB_LOCATIONS>
                </div>
            </div>

        </>
    )
}
export default {
    s3: {
        REGION: "us-east-1",
        BUCKET: "terrahub-credi-resources"
    },
    apiGateway_admin: {
        REGION: "us-east-1",
        URL: "https://1uns1fnial.execute-api.us-east-1.amazonaws.com/prod"
    },
    apiGateway_indv: {
        REGION: "us-east-1",
        URL: "https://6u0v8hsov3.execute-api.us-east-1.amazonaws.com/prod"
    },
    apiGateway_indv_credentials: {
        REGION: "us-east-1",
        URL: "https://njh5pzbzoj.execute-api.us-east-1.amazonaws.com/prod"
    },
    apiGateway_indv_dailychecks: {
        REGION: "us-east-1",
        URL: "https://fry2x18cbe.execute-api.us-east-1.amazonaws.com/prod"
    },
    apiGateway_indv_documents: {
        REGION: "us-east-1",
        URL: "https://89lgvd87wa.execute-api.us-east-1.amazonaws.com/prod"
    },
    apiGateway_indv_forms: {
        REGION: "us-east-1",
        URL: "https://5b26mehehi.execute-api.us-east-1.amazonaws.com/prod"
    },
    apiGateway_integrations: {
        REGION: "us-east-1",
        URL: "https://dxp3u73xsl.execute-api.us-east-1.amazonaws.com/prod"
    },
    apiGateway_org: {
        REGION: "us-east-1",
        URL: "https://y7xcb6zzb4.execute-api.us-east-1.amazonaws.com/prod"
    },
    apiGateway_org_credentials: {
        REGION: "us-east-1",
        URL: "https://8dmz58alyh.execute-api.us-east-1.amazonaws.com/prod"
    },
    apiGateway_org_dailychecks: {
        REGION: "us-east-1",
        URL: "https://0qa323lsgb.execute-api.us-east-1.amazonaws.com/prod"
    },
    apiGateway_org_documents: {
        REGION: "us-east-1",
        URL: "https://iyk619tmbi.execute-api.us-east-1.amazonaws.com/prod"
    },
    apiGateway_org_forms: {
        REGION: "us-east-1",
        URL: "https://a9utn22bqg.execute-api.us-east-1.amazonaws.com/prod"
    },
    apiGateway_org_individuals: {
        REGION: "us-east-1",
        URL: "https://bd2vtrrm9k.execute-api.us-east-1.amazonaws.com/prod"
    },
    apiGateway_org_locations: {
        REGION: "us-east-1",
        URL: "https://cbe26e99mk.execute-api.us-east-1.amazonaws.com/prod"
    },
    apiGateway_org_onsites: {
        REGION: "us-east-1",
        URL: "https://dwm8cpcq5k.execute-api.us-east-1.amazonaws.com/prod"
    },
    apiGateway_org_orgcredentials: {
        REGION: "us-east-1",
        URL: "https://h8mmfjm9je.execute-api.us-east-1.amazonaws.com/prod"
    },
    apiGateway_org_requirements: {
        REGION: "us-east-1",
        URL: "https://hgybkko33a.execute-api.us-east-1.amazonaws.com/prod"
    },
    apiGateway_org_wallet: {
        REGION: "us-east-1",
        URL: "https://0t31pirav3.execute-api.us-east-1.amazonaws.com/prod"
    },
    apiGateway_verifiable_credentials: {
        REGION: "us-east-1",
        URL: "https://c2ydupr5ll.execute-api.us-east-1.amazonaws.com/prod"
    },
    apiGateway_translation: {
        REGION: "ca-central-1",
        URL: "https://api.credivera.com/translation"
    },
    apiGateway_scanner: {
        REGION: "us-east-1",
        URL: "https://46hg9uevm5.execute-api.us-east-1.amazonaws.com/prod"
    },
    apiGateway_storage: {
        REGION: "us-east-1",
        URL: "https://ma9c0v5g2k.execute-api.us-east-1.amazonaws.com/prod"
    },
    partner_api_domain: {
        URL: "https://api.credivera.com"
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_7pkPzUpQ5",
        APP_CLIENT_ID: "a0r4qbd3hjs818beu4uep40qb",
        IDENTITY_POOL_ID: "us-east-1:ff1b864d-2496-4394-aa0c-6635da9cbd2f",
        redirect_uri: 'https://7wvzzl4d8h.execute-api.us-east-1.amazonaws.com/prod/get-saml/',
        client_id: 'nnf9dl466bu0s190mqtpe29hk',
        oauth_fetch: 'https://a7f27797-fed2-41cd-b9be-21097c45df4c.auth.us-east-1.amazoncognito.com/oauth2/token',
        org_id: 'DFACF8D4-19A4-4373-88D5-4912465F90EA',
        idp: 'cognito-idp.us-east-1.amazonaws.com/us-east-1_7pkPzUpQ5',
        post_indiv: 'https://gwm03y7zt6.execute-api.us-east-1.amazonaws.com/prod/post-individual-org/'
    },
    language: {
        en: {
            name: "English",
            i18n: "en-US",
            moment: "en-ca",
            datepicker: "en-US"
        },
        fr: {
            name: "French",
            i18n: "fr-CA",
            moment: "fr-ca",
            datepicker: "fr-FR"
        }
    },
    general: {
        alpha_indiv_url: 'https://my.credivera.com'
    }
};

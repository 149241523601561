import { useEffect } from "react";
import React from "react";
import { Table, TableBody } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';
import datelib from '../../../../../libs/date-lib';

import "./crd_dailychecks.css";

export default function({var_dailychecks}){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <>
            <section className="profile_section">

                <div className="profile_section_header">{t('Daily Checks')}</div>

                <div className="profile_section_body">

                    <div className="tablewrapper remove_table_pointer_event">
                        <Table unstackable compact>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell className="td_loc_icon_center" textAlign='center'>{t('Status')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_name">{t('QUESTIONNAIRE')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_issued">{t('ISSUED')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_mgr_ovrd">{t('MANAGER OVERRIDE')}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <TableBody>
                            {var_dailychecks.map((item, i) =>
                                <Table.Row key={i}>
                                    <Table.Cell className="td_loc_icon_center" textAlign='center'>
                                        {item.status === 'PASS' &&
                                        <img src={"/icons/checkmark 30px (69b536).svg?ts=" + Date.now()} alt={t('active icon')}/>
                                        }
                                        {item.status === 'FAIL' &&
                                        <img src={"/icons/x 30px (e0454f).svg?ts=" + Date.now()} alt={t('inactive icon')}/>
                                        }
                                        {(item.status !== 'PASS' && item.status !== 'FAIL') &&
                                        <img src={"/icons/circle 30px (bcbebe).svg?ts=" + Date.now()} alt={t('inactive icon')}/>
                                        }
                                    </Table.Cell>
                                    <Table.Cell className="td_name">{item.name}</Table.Cell>
                                    <Table.Cell className="td_issued">{datelib.datetime_localized_format(item.issued)}</Table.Cell>
                                    <Table.Cell className="td_mgr_ovrd">{item.mgr_ovrd}</Table.Cell>
                                </Table.Row>
                            )}
                            </TableBody>
                        </Table>
                        {var_dailychecks.length === 0 &&
                            <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t('alert icon')}/>{t('there are no daily checks to display')}</div>
                        }
                    </div>
                </div>
            </section>
        </>
    )

}
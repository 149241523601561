import { Button, Dropdown } from "semantic-ui-react";
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import React from "react";
import "./mdl_add.css";
import { useTranslation } from 'react-i18next';
import '../../../../../../../i18n';

export default function ({set_mdl_open,populate_function,populate_document,document_id}) {
  //  variable declarations ------------------------------------------------------------------------------------------
  const { t } = useTranslation();
  const [var_location, set_location] = useState([]);
  const [var_ready, set_ready] = useState(false);
  const [var_processing, set_processing] = useState(false);



  const [var_dd_availablelocations, set_dd_availablelocations] = useState([]);


  //  variable listeners ---------------------------------------------------------------------------------------------

  useEffect(() => {
    populate_dd_availablelocations();
    const location = { ...var_location };
    location["organization_id"] = JSON.parse(sessionStorage.getItem("authentication")).actingorganization_id;
    location["transactionby"] = JSON.parse(sessionStorage.getItem("authentication")).id;
    location["transactionorg"] = JSON.parse(sessionStorage.getItem("authentication")).organization_id;
    location["document_id"]= document_id
    set_location(location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //  async functions ------------------------------------------------------------------------------------------------
  async function populate_dd_availablelocations() {
    try {
      let results = await API_get_dd_availablelocations();

      set_dd_availablelocations(results);
    } catch (e) {
      console.log(e);
    }
  }

  async function insert_locations() {

    set_processing(true);
    try {
      await API_post_document_locations()
      .then(res=>{
          set_ready(false);
          set_processing(false);
          set_mdl_open(false);
          populate_function();
          populate_document();
        }).catch(err=>{
          console.log(err)
        })



    } catch (e) {
      console.log(e);
    }
  }

  //  API calls ------------------------------------------------------------------------------------------------------

  //get avialable locations
  function API_get_dd_availablelocations() {
    localStorage.setItem("activetime", Math.floor(Date.now() / 1000));
    return API.get("org-documents",'/get-available-doc-locations/'+JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id+'/'+JSON.parse(sessionStorage.getItem('authentication')).id +'/'+ document_id);

  }

  //post locationss
  function API_post_document_locations() {
    localStorage.setItem("activetime", Math.floor(Date.now() / 1000));
    return API.post("org-locations","/post-loc-document", {body: var_location})
  }


  //  event functions ------------------------------------------------------------------------------------------------
  function onChange_location(e, { value }) {
    const location = { ...var_location };
    location["locations"] = value;
    set_location(location);
    set_ready(true);
  }

  function onClick_submit(){
    insert_locations();
}
  // RENDER APP ======================================================================================================

  return (
    <>
      <div className="modal-header">
        <div className="modal-header-title">
          {t("Add Locations/Projects to Document")}
        </div>
        <div className="modal-header-close" onClick={() => set_mdl_open(false)}>
          <img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt="close icon" />
        </div>
      </div>

      <div className="modal-content">

      <Dropdown
          className="location_id"
          name="locations"
          label={t("LOCATION/PROJECT TO ADD")}
          options={var_dd_availablelocations}
          value={var_location.locations || []}
          onChange={onChange_location}
          placeholder="Select location/project to add documents..."
          fluid
          multiple
          selection
        />
        <br />

      </div>

      <div className="modal-footer">
        <div className="modal-footer-buttons">
          <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>
            {t('CANCEL')}
          </Button>
          {!var_processing ? (
            <Button
              className={"btn_primary " + (var_ready ? " btn_active" : "")}
              onClick={() => onClick_submit()}
            >
              {t("ADD LOCATIONS/PROJECTS")}
            </Button>
          ) : (
            <Button loading className="btn_primary btn_active">
              {t("ADD LOCATIONS/PROJECTS")}
            </Button>
          )}
        </div>
      </div>
    </>
  );
}

import {Button, Table} from 'semantic-ui-react'
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import '../../i18n';
import datelib from '../../libs/date-lib';

import CMP_TABLECONTROLS, { filter_helper } from '../cmp_tablecontrols/cmp_tablecontrols';
import './cmp_history.css';



export default function({set_mdl_open, var_modaltitle, var_target_id }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_history, set_history] = useState({});
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(10);
    const [ var_offset, set_offset] = useState(0);
    const [ var_search, set_search ] = useState('');
    const [ var_sortby, set_sortby ] = useState('epochtransactiondate');
    const [ var_sortorder, set_sortorder ] = useState('descending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));



    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_history()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=>{
        if(var_filter.load){
            populate_history(null,0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_filter]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_history(limit,offset,sortby,sortorder,search,filter) {

        set_loading(true);

        if (limit) {
            set_limit(limit);
        } else {
            limit = var_limit
        }

        if (offset || offset === 0) {
            set_offset(offset);
        } else {
            offset = var_offset
        }

        if (sortby) {
            set_sortby(sortby);
        } else {
            sortby = var_sortby
        }

        if (sortorder) {
            set_sortorder(sortorder);
        } else {
            sortorder = var_sortorder
        }

        if (search) {
            if (search === '-') {
                search = '';
            }
            set_search(search);
        } else {
            search = var_search
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_history(limit, offset, sortby, sortorder, search, filter);
            set_totalrows(results.totalrows);
            set_history(results.results === undefined ? [] : results.results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);

    }

    async function populate_filters(filtername,sortorder){
        try {
            return (await API_get_history(filter_helper.maxFilterItems, 0, filtername, sortorder, var_search,{}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_history(limit, offset){
        try {
            return (await API_get_history(limit, offset, var_sortby, var_sortorder, var_search, var_filter)).results;
        }  catch (e) {
             console.log(e);
             throw e;
         }
     }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_history(limit, offset, sortby, sortorder, search, filter, filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('admin',
                        '/get-history/'+ var_target_id,
                        {
                            queryStringParameters: {
                            limit: limit,
                            offset: offset,
                            sortby: sortby,
                            sortorder: sortorder,
                            search : search,
                            tz : datelib.timezone,
                            filtername: filtername
                            },
                            body: filter
                        }
                    );
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_history('',0,sortby,sortorder)
    }

    function onClick_mdl_history_close() {
        set_mdl_open(false);
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">{var_modaltitle}</div>
            <div className="modal-content">
                <div className="content-filter">
                    <div className="content-filter-item item-pagination">
                        <CMP_TABLECONTROLS
                            var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                            total_rows={var_history && var_history.length > 0 ? var_totalrows : 0} populatefunction={populate_history}
                            downloadname='Transaction History' downloadfunction={download_history}
                            var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                            table_config={[
                                {name: 'description', datatype: 'text', labelKey: 'DESCRIPTION', download: true, filter: true},
                                {name: 'epochtransactiondate', datatype: 'epoch', labelKey: 'TRANSACTIONDATE', download: true, filter: true},
                                {name: 'transactionby', datatype: 'text', labelKey: 'TRANSACTIONBY', download: true, filter: true},
                                {name: 'transactionorg', datatype: 'text', labelKey: 'TRANSACTIONORG', download: true, filter: true},
                                {name: 'transactionid', datatype: 'text', labelKey: 'TRANSACTION ID', download: true, filter: true}
                            ]}
                        />
                    </div>
                </div>
                <div className="tablewrapper">
                    <Table sortable compact className={(var_loading ? " tbl_loading" : "")}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell sorted={var_sortby === 'description' ? var_sortorder : null} onClick={() => onClick_sort('description')}>{t('DESCRIPTION')}</Table.HeaderCell>
                                <Table.HeaderCell sorted={var_sortby === 'epochtransactiondate' ? var_sortorder : null} onClick={() => onClick_sort('epochtransactiondate')}>{t('TRANSACTION DATE')}</Table.HeaderCell>
                                <Table.HeaderCell sorted={var_sortby === 'transactionby' ? var_sortorder : null} onClick={() => onClick_sort('transactionby')}>{t('TRANSACTION BY')}</Table.HeaderCell>
                                <Table.HeaderCell sorted={var_sortby === 'transactionorg' ? var_sortorder : null} onClick={() => onClick_sort('transactionorg')}>{t('ORGANIZATION')}</Table.HeaderCell>
                                <Table.HeaderCell sorted={var_sortby === 'transactionid' ? var_sortorder : null} onClick={() => onClick_sort('transactionid')}>{t('TRANSACTION ID')}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        {var_ready && var_totalrows !== 0 &&
                        <Table.Body>
                            {var_history.map((item, i) =>
                                <Table.Row key={i}>
                                    <Table.Cell>{t(item.description)}</Table.Cell>
                                    <Table.Cell>{datelib.datetime_localized_format(item.transactiondate)}</Table.Cell>
                                    <Table.Cell>{item.transactionby}</Table.Cell>
                                    <Table.Cell>{item.transactionorg}</Table.Cell>
                                    <Table.Cell className="td_transactionid">{item.transactionid}</Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                        }
                    </Table>
                    {var_ready && var_totalrows === 0 &&
                        <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t('alert icon')} />{t('there are no history transactions to display')}</div>
                    }
                </div>
                <div className="content-filter">
                    <div className="content-filter-item item-pagination">
                        <CMP_TABLECONTROLS
                            var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                            total_rows={var_history && var_history.length > 0 ? var_totalrows : 0} populatefunction={populate_history}
                        />
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_primary btn_active" onClick={() => onClick_mdl_history_close()}>{t('CLOSE')}</Button>
                </div>
            </div>
        </>
    )
}

import {Button, Form, Table, Modal, Header  } from 'semantic-ui-react'
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import React from "react";
import XLSX from "xlsx";
import FileSaver from "file-saver";
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';
import MDL_UPDATE_BULKINVITE from "../mdl_update_bulkinvite/mdl_update_bulkinvite";

import './mdl_bulkinvite.css';
import CMP_FILEPROCESS from "../../../../../components/cmp_fileprocess/cmp_fileprocess";



export default function({ set_mdl_open, populate_function, var_location }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();
    const [ var_ready, set_ready] = useState(false);
    const [ var_processing, set_processing] = useState(false);
    const [ var_bulkid, set_bulkid ] = useState([]);
    const [ var_bulkinvite, set_bulkinvite ] = useState([]);
    const [ var_invalidbulkinvite, set_invalidbulkinvite ] = useState([]);
    const [ var_updatebulkinvite, set_updatebulkinvite ] = useState([]);
    const [ var_invalidbulkinvited, set_invalidbulkinvited ] = useState(0);
    const [ var_bulkinvited, set_bulkinvited ] = useState(0);
    const [ var_inviteindividual, set_inviteindividual ] = useState([]);
    const [ var_mdl_update_bulkinvite_open, set_mdl_update_bulkinvite_open ] = useState(false);

    const [ var_fileuploadstatus, set_fileuploadstatus ] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [ var_fileuploadlabel, set_fileuploadlabel ] = useState('DATA FILE');
    const [ var_fileuploaddisplayname, set_fileuploaddisplayname ] = useState(null);
    const [ var_filedata, set_filedata ] = useState(null);



    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        const inviteindividual = { ...var_inviteindividual };
        inviteindividual['individual_type'] = 'USER';
        set_inviteindividual(inviteindividual); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(var_fileuploadstatus === 'PENDING'){
            set_ready(true);
        }
        if(var_fileuploadstatus === 'COMPLETE'){
            insert_bulkinvite();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_fileuploadstatus]);

    //  async functions ------------------------------------------------------------------------------------------------
    async function insert_bulkinvite(){
        let var_records = [];
        let invaliddetails = [];
        let validData = var_filedata;
        for (let data of var_filedata) {
            let item = {};
            const emailregix = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                let check = true;
            if(data['FIRST NAME'] === undefined || data['FIRST NAME'] === null){
                check= false;
            }
            if(data['LAST NAME'] === undefined || data['LAST NAME'] === null){ 
                check= false;
            }
            if(data['EMAIL ADDRESS'] === undefined || emailregix.test(data['EMAIL ADDRESS']) === false){
                check= false;
            }
            if(!check){
                invaliddetails.push(data);
                delete validData[data];
            }else{
                item['firstname'] = data['FIRST NAME'];
                item['lastname'] = data['LAST NAME'];
                item['email'] = data['EMAIL ADDRESS'];
                item['individual_type'] = var_inviteindividual.individual_type;
                item['permission_id'] = '4EBEB4CF-4B68-4A29-A8FF-01FBC744A898';
                item['location_id'] = var_location;
                item['invitedby'] = JSON.parse(sessionStorage.getItem('authentication')).firstname + ' ' + JSON.parse(sessionStorage.getItem('authentication')).lastname;
                item['invitedbyemail'] = JSON.parse(sessionStorage.getItem('authentication')).email;
                item['organization'] = JSON.parse(sessionStorage.getItem('authentication')).actingorganization;
                item['organization_id'] = JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id;
                item['transactionby'] = JSON.parse(sessionStorage.getItem('authentication')).id;
                item['transactionorg'] = JSON.parse(sessionStorage.getItem('authentication')).organization_id;

                var_records.push(item);
            }
        }
        try {
            await API_post_bulkinvite(var_records);
            set_bulkinvite(var_records);
            set_bulkinvited(var_records.length);
            set_invalidbulkinvited(invaliddetails.length);
            set_invalidbulkinvite(invaliddetails);
            set_processing(false);
        } catch (e) {
            console.log(e);
        }
    }
    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_bulkinvite(var_records) {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-individuals', '/post-bulk-invite', { body: var_records });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_type(e, { value }){
        const inviteindividual = { ...var_inviteindividual };
        inviteindividual['individual_type'] = value;
        set_inviteindividual(inviteindividual);
        //set_ready(true);
    }

    function onClick_downloadstudenttemplate(){

        let headers = [["FIRST NAME", "LAST NAME", "EMAIL ADDRESS"]];

        //  create excel spreadsheet -----------------------------------------------------------------------------------

        const wb = XLSX.utils.book_new();
        var ws = XLSX.utils.aoa_to_sheet(headers);
        XLSX.utils.book_append_sheet(wb, ws, 'Invite Template');

        //  write file -------------------------------------------------------------------------------------------------

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
        FileSaver.saveAs(data, 'Bulk invite template.xlsx');

    }
    function onClick_closemodal(){
        populate_function();
        set_mdl_open(false);
    }

    function onOpen_mdl_update_bulkinvite(id){
        set_bulkid(id);
        set_updatebulkinvite(var_invalidbulkinvite[id]);
        set_mdl_update_bulkinvite_open(true);
    }

    function onOpen_mdl_remove_bulkinvite(id,isProcessed){
        let var_invalid_bulkinvite = [...var_invalidbulkinvite];
        var_invalid_bulkinvite.splice(id,1);
        if (isProcessed) {
            let var_bulkinvitelength = var_bulkinvite.length+1;
            set_bulkinvited(var_bulkinvitelength);
        }
        set_invalidbulkinvited(var_invalid_bulkinvite.length);
        set_invalidbulkinvite(var_invalid_bulkinvite);
        set_mdl_update_bulkinvite_open(false);
    }
    function onClick_submit(){
        if(var_ready){
            if(var_fileuploadstatus === 'PENDING') {
                set_fileuploadstatus('START');
                set_processing(true);
            }else{
                set_processing(true);
                insert_bulkinvite();
            }
        }
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">{t("Bulk Invites")}</div>

            <div className="modal-content">
                {var_bulkinvited === 0 ?
                    <Form>
                        {JSON.parse(sessionStorage.getItem('authentication')).org_guest === 'YES' &&
                        <Form.Group>
                            <Form.Select
                                className='individual_type'
                                name='individual_type'
                                label={t("INVITE TYPE")}
                                options={[  {text: 'USERS', value: 'USER'},
                                    {text: 'GUESTS', value: 'GUEST'}]}
                                value={var_inviteindividual.individual_type || ''}
                                onChange={onChange_type}
                                placeholder={t("Select an invite type...")}
                            />
                        </Form.Group>
                        }
                        <Form.Group id="file">
                            {var_bulkinvited === 0 ?
                        <CMP_FILEPROCESS
                                set_filedata={set_filedata}
                                var_fileuploadlabel={var_fileuploadlabel}
                                var_fileuploadstatus={var_fileuploadstatus}
                                set_fileuploadstatus={set_fileuploadstatus}
                                var_fileuploaddisplayname={var_fileuploaddisplayname}
                                set_fileuploaddisplayname={set_fileuploaddisplayname}
                                var_placeholder={'No file selected...'}>
                                </CMP_FILEPROCESS>
                                :
                                <></>
                        }
                        </Form.Group>
                    </Form>
                    :
                    <>
                    <div className="message info">
                        <div className="message_icon"><img src={"/icons/info 60px (4dcbd4).svg?ts=" + Date.now()} alt={t("info icon")}/></div>
                        <div className="message_text_wrapper">
                            <div className="message_text resolution">{var_bulkinvited} {t("invites were processed! ")} {var_invalidbulkinvited} {t("invites were rejected!")}</div>
                            <div className="message_text resolution">{t("To update invites information click on rejected invite.")}</div>
                        </div>
                    </div>
                    <Header as='h4'>Rejected Invites</Header>
                    <div className="tablewrapper">
                        <Table sortable compact>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{t("FIRST NAME")}</Table.HeaderCell>
                                    <Table.HeaderCell>{t("LAST NAME")}</Table.HeaderCell>
                                    <Table.HeaderCell>{t("EMAIL")}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {var_invalidbulkinvite[0] !== undefined  &&
                            <Table.Body>
                            {var_invalidbulkinvite.map((item,i) =>
                                    <Table.Row onClick={() => onOpen_mdl_update_bulkinvite(i)} key={i}>
                                        <Table.Cell>{item["FIRST NAME"]}</Table.Cell>
                                        <Table.Cell>{item["LAST NAME"]}</Table.Cell>
                                        <Table.Cell>{item["EMAIL ADDRESS"]}</Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                            }
                        </Table>
                        {var_invalidbulkinvite[0]=== undefined &&
                        <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t('alert icon')} />{t('there are no invalid invites to display')}</div>
                        }
                        
                    </div>
                    </>
                }
            </div>

            <div className="modal-footer">
                {var_bulkinvited === 0 ?
                    <div className="modal-footer-buttons">
                        <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>{t("CANCEL")}</Button>
                        <Button className="btn_secondary" onClick={() => onClick_downloadstudenttemplate()}>{t("DOWNLOAD TEMPLATE")}</Button>
                        {!var_processing ?
                            <Button className={"btn_primary " + (var_ready ? ' btn_active' : '')} onClick={() => onClick_submit()}>SEND INVITES</Button>
                            :
                            <Button loading className="btn_primary btn_active">SEND INVITES</Button>
                        }
                    </div>
                    :
                    <div className="modal-footer-buttons">
                        <Button className="btn_secondary" onClick={() => onClick_closemodal()}>{t("CLOSE")}</Button>
                    </div>
                }
            </div>
             {/***** MODAL: UPDATE BULK INVITE ***************************************************************************/}

             <Modal id="mdl_update_bulkinvite"
                    dimmer={'inverted'}
                    open={var_mdl_update_bulkinvite_open}
                    onClose={() => set_mdl_update_bulkinvite_open(false)}>
                <MDL_UPDATE_BULKINVITE
                    set_mdl_open={set_mdl_update_bulkinvite_open}
                    var_updatebulkinvite = {var_updatebulkinvite}
                    onOpen_mdl_remove_bulkinvite = {onOpen_mdl_remove_bulkinvite}
                    var_bulkid = {var_bulkid}
                    populate_function={populate_function}
                    var_location={''}
                />
            </Modal>

            {/***** END MODAL: UPDATE BULK INVITE ***********************************************************************/}
        </>
    )
}
import React, { useEffect, useState } from 'react';
import { Modal, Table } from 'semantic-ui-react';
import { API } from 'aws-amplify';
import datelib from '../../../../libs/date-lib';

import { useTranslation } from 'react-i18next';
import '../../../../i18n';

import MDL_REPORT from './mdl_report/mdl_report';

import './crd_reports.css';
import auth from '../../../../libs/auth-lib';



export default function(){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();

    const [ var_mdl_indv_open, set_mdl_indv_open ] = useState(false);
    const [ var_report, set_report ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------


    //  async functions ------------------------------------------------------------------------------------------------

    //  API calls -----------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_open_report_mdl(report) {
        set_mdl_indv_open(true);
        set_report(report)
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="content-card" id="crd_org_reports">
            <div className="content-card-header">
                    <div className="content-card-header-title">{t('Reports')}</div>
                </div>
                <div className="content-card-gridcontent">
                    <div className="content-filter">
                    <div className="content-filter-item item-pagination">    
                    </div>
                    </div>
                    <div className="tablewrapper">
                        <Table sortable compact>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{t('REPORT')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('LAST VIEWED')}</Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {(auth.org_dailycheck || auth.org_forms || auth.org_requirements || auth.org_credentials) &&
                                <Table.Body>
                                    {auth.org_credentials &&
                                        <Table.Row>
                                            <Table.Cell >{t('Credential History')}</Table.Cell>
                                            <Table.Cell >{t('Last Viewed')}</Table.Cell>
                                            <Table.Cell className='td_link' onClick={() => onClick_open_report_mdl('placeholder')}>{t('View report')}</Table.Cell>
                                        </Table.Row>
                                    }
                                    {auth.org_dailycheck &&
                                        <Table.Row>
                                            <Table.Cell >{t('Daily Check History')}</Table.Cell>
                                            <Table.Cell >{t('Last Viewed')}</Table.Cell>
                                            <Table.Cell className='td_link' onClick={() => onClick_open_report_mdl('placeholder')}>{t('View report')}</Table.Cell>
                                        </Table.Row>
                                    }
                                    {auth.org_forms &&
                                        <Table.Row>
                                            <Table.Cell >{t('Form Signature History')}</Table.Cell>
                                            <Table.Cell >{t('Last Viewed')}</Table.Cell>
                                            <Table.Cell className='td_link' onClick={() => onClick_open_report_mdl('placeholder')}>{t('View report')}</Table.Cell>
                                        </Table.Row>
                                    }
                                    {auth.org_requirements &&
                                        <Table.Row>
                                            <Table.Cell >{t('Requirement Status')}</Table.Cell>
                                            <Table.Cell>{t('Last Viewed')}</Table.Cell>
                                            <Table.Cell className='td_link' onClick={() => onClick_open_report_mdl('placeholder')}>{t('View report')}</Table.Cell>

                                        </Table.Row>
                                    }
                                    {auth.org_forms &&
                                        <Table.Row>
                                            <Table.Cell >{t('Safety Form History')}</Table.Cell>
                                            <Table.Cell>{t('Last Viewed')}</Table.Cell>
                                            <Table.Cell className='td_link' onClick={() => onClick_open_report_mdl('placeholder')}>{t('View report')}</Table.Cell>

                                        </Table.Row>
                                    }
                                </Table.Body>
                            }
                        </Table>
                        {(!auth.org_dailycheck && !auth.org_credentials && !auth.org_forms && !auth.org_requirements) && 
                            <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt="alert icon"/>{t('There are no reports to display.')}</div>
                        }
                    </div>
                </div>



                {/***** MODAL: REPORT ********************************************************************************/}

                <Modal id="mdl_org_report"
                       dimmer={'inverted'}
                       open={var_mdl_indv_open}
                       onClose={() => set_mdl_indv_open(false)}>
                    <MDL_REPORT
                        set_mdl_open={set_mdl_indv_open}
                        var_report={var_report}>
                    </MDL_REPORT>
                </Modal>

                {/***** END MODAL: REPORT ****************************************************************************/}



            </div>
        </>
    )

}
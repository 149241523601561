import { Table, Modal } from 'semantic-ui-react';
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import datelib from '../../../../libs/date-lib';
import { useTranslation } from 'react-i18next';
import '../../../../i18n';

import CMP_TABLECONTROLS, { filter_helper } from '../../../../components/cmp_tablecontrols/cmp_tablecontrols';
import MDL_INDV_REQUIREMENT from '../../../common/requirements/mdl_indv_requirement/mdl_indv_requirement';

import './tab_requirements.css';


export default function({var_activetab}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_requirements, set_requirements ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(10);
    const [ var_offset, set_offset] = useState(0);
    const [ var_sortby, set_sortby ] = useState('requirement');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));

    const [ var_mdl_indv_requirement_open, set_mdl_indv_requirement_open ] = useState(false);
    const [ var_mdl_indv_requirement_source, set_mdl_indv_requirement_source ] = useState('ORG');
    const [ var_mdl_indv_requirement_target_id, set_mdl_indv_requirement_target_id ] = useState('');

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_activetab === 'REQUIREMENTS') {
            set_filter(filter_helper.initialize(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_activetab]);

   useEffect(()=>{
        if(var_filter.load) {
        populate_overview(null, 0);
        }
       // eslint-disable-next-line react-hooks/exhaustive-deps
       },[var_filter]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_overview(limit,offset,sortby,sortorder,filter){
        if (var_activetab !== 'REQUIREMENTS') return; // Don't load data if tab is not visible

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_org_overview(limit,offset,sortby,sortorder,filter);
            set_requirements(results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername,sortorder){
        try {
            return await API_get_org_overview(filter_helper.maxFilterItems, 0, filtername, sortorder,{}, filtername);
        } catch (e) {
            console.log(e);
        }
    }

    async function download_org_requirements(limit, offset){
        try {
            return await API_get_org_overview(limit, offset, var_sortby, var_sortorder, var_filter);
        }  catch (e) {
             console.log(e);
             throw e;
         }
     }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_overview(limit,offset,sortby,sortorder,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-credentials',
                        '/get-org-requirements/'+JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id,
                        {queryStringParameters: {
                            limit: limit,
                            offset: offset,
                            sortby: sortby,
                            sortorder: sortorder,
                            tz : datelib.timezone,
                            filtername: filtername
                        },
                        body: filter
                    }
                    );
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_overview('',0,sortby,sortorder)
    }

    function onClick_existingrequirement(id){
        set_mdl_indv_requirement_source('ORG');
        set_mdl_indv_requirement_target_id(id);
        set_mdl_indv_requirement_open(true);
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="content-card-noheader" id="tab_org_overview_requirements">
                <div className="content-card-gridcontent">
                    <div className="content-filter">
                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                    var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                                    total_rows={var_requirements && var_requirements.length > 0 ? var_requirements[0]['totalrows'] : 0} populatefunction={populate_overview}
                                    downloadname='Requirements Overview' downloadfunction={download_org_requirements}
                                    var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                                    table_config={[
                                        {name: 'requirement', datatype: 'text', labelKey: 'REQUIREMENT', download: true, filter: true},
                                        {name: 'individual', datatype: 'text', labelKey: 'INDIVIDUAL', download: true, filter: true},
                                        {name: 'organization', datatype: 'text', labelKey: 'ORGANIZATION', download: true, filter: true},
                                        {name: 'locations', datatype: 'text', labelKey: 'LOC/PROJ', download: true, filter: true},
                                        {name: 'businessunits', datatype: 'text', labelKey: 'BUSINESS UNIT', download: true, filter: true},
                                        {name: 'issuedby', datatype: 'text', labelKey: 'ISSUER', download: true, filter: true},
                                        {name: 'credential', datatype: 'text', labelKey: 'CREDENTIAL', download: true, filter: true},
                                        {name: 'epochissued', datatype: 'date', labelKey: 'ISSUED', download: true, filter: true},
                                        {name: 'epochexpires', datatype: 'date', labelKey: 'EXPIRES', download: true, filter: true},
                                        {name: 'status', datatype: 'text', labelKey: 'STATUS', download: true, filter: true}
                                    ]}
                                />
                        </div>
                    </div>
                    <div className="tablewrapper">
                        <Table sortable compact className={(var_loading ? " tbl_loading" : "")}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell sorted={var_sortby === 'requirement' ? var_sortorder : null} onClick={() => onClick_sort('requirement')}>{t("REQUIREMENT")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'individual' ? var_sortorder : null} onClick={() => onClick_sort('individual')}>{t("INDIVIDUAL")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'organization' ? var_sortorder : null} onClick={() => onClick_sort('organization')}>{t("ORGANIZATION")}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_location" sorted={var_sortby === 'locations' ? var_sortorder : null} onClick={()=>onClick_sort('locations')}>{t('LOC/PROJ')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_businessunit" sorted={var_sortby === 'businessunits' ? var_sortorder : null} onClick={()=>onClick_sort('businessunits')}>{t('BUSINESS UNIT')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'issuedby' ? var_sortorder : null} onClick={() => onClick_sort('issuedby')}>{t("ISSUER")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'credential' ? var_sortorder : null} onClick={() => onClick_sort('credential')}>{t("CREDENTIAL")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'epochissued' ? var_sortorder : null} onClick={()=>onClick_sort('epochissued')}>{t('ISSUED')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_expires" sorted={var_sortby === 'epochexpires' ? var_sortorder : null} onClick={() => onClick_sort('epochexpires')}>{t("EXPIRES")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'status' ? var_sortorder : null} onClick={() => onClick_sort('status')}>{t("STATUS")}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {var_ready && var_requirements[0]['totalrows'] !== 0 &&
                            <Table.Body>
                                {var_requirements.map((item, i) =>
                                    <Table.Row key={item.individualrequirement_id} onClick={() => onClick_existingrequirement(item.individualrequirement_id)}>
                                        <Table.Cell>{item.requirement}</Table.Cell>
                                        <Table.Cell>{item.individual}</Table.Cell>
                                        <Table.Cell>{item.organization}</Table.Cell>
                                        <Table.Cell className="td_location">{item.locations}</Table.Cell>
                                        <Table.Cell className="td_businessunit" >{item.businessunits}</Table.Cell>
                                        <Table.Cell>{item.issuedby}</Table.Cell>
                                        <Table.Cell className="td_overflow">{item.credential}</Table.Cell>
                                        <Table.Cell>{item.issued}</Table.Cell>
                                        <Table.Cell className="td_expires">{item.expires}</Table.Cell>
                                        <Table.Cell>{item.status}</Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                            }
                        </Table>
                        {var_ready && var_requirements[0]['totalrows'] === 0 &&
                        <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t("alert icon")}/>{t("there are no requirements to display")}</div>
                        }
                    </div>
                    <div className="content-filter">
                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_requirements && var_requirements.length > 0 ? var_requirements[0]['totalrows'] : 0} populatefunction={populate_overview}
                            />
                        </div>
                    </div>
                </div>
            </div>
        {/***** MODAL: ADD REQUIREMENT ***********************************************************************/}

        <Modal id='mdl_indv_requirement'
                dimmer={'inverted'}
                open={var_mdl_indv_requirement_open}
                onClose={() => set_mdl_indv_requirement_open(false)}
                closeOnEscape={false}
                closeOnDimmerClick={false}>
            <MDL_INDV_REQUIREMENT
                var_mdl_open={var_mdl_indv_requirement_open}
                set_mdl_open={set_mdl_indv_requirement_open}
                source={var_mdl_indv_requirement_source}
                var_target_id={var_mdl_indv_requirement_target_id}
                populate_function={populate_overview} />
        </Modal>

        {/***** END MODAL: ADD REQUIREMENT *******************************************************************/}

        </>
    )
}
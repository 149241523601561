import { API, Auth, Amplify } from 'aws-amplify';
import config from '../config';

const auth = {
    // general features
    authentication: null,
    get_authentication: function() {
        if (window.location.pathname.toUpperCase() === '/LOGIN/AUTO' && window.location.search.startsWith('?t=')) {
            return null;
        }
        if (!this.authentication) {
            let sessionAuthentication = sessionStorage.getItem('authentication');
            sessionAuthentication && (this.authentication = JSON.parse(sessionAuthentication));
        }
        return this.authentication;
    },
    clear: function() { this.authentication = null; },
    get is_loggedin() { return !!this.get_authentication(); },
    logout: async function(history) {
        let sso_logout = null;
        let sso_config = sessionStorage.getItem('sso_config');
        if (sso_config && JSON.parse(sso_config).hasOwnProperty('sso_logout')) {
            sso_logout = JSON.parse(sso_config).sso_logout; // sso logout url
        }
        sessionStorage.clear();
        this.clear();
        await Auth.signOut();
        // Ensure user pool is reset to default for sso users
        Amplify.configure({
            userPoolWebClientId: config.cognito.APP_CLIENT_ID
        });
        if (sso_logout) {
            window.location.href = sso_logout;
        } else if (history) {
            window.location.href = config.general.alpha_indiv_url;
        }
    },

    // user attributes
    get id() { return get_property(this, 'id'); },
    get individual_type() { return get_property(this, 'individual_type'); },
    get organization_id() { return get_property(this, 'organization_id'); },
    get organization() { return get_property(this, 'organization'); },
    get actingorganization_id() { return get_property(this, 'actingorganization_id'); },
    get firstname() { return get_property(this, 'firstname'); },
    get lastname() { return get_property(this, 'lastname'); },
    get email() { return get_property(this, 'email'); },
    get language() { return get_property(this, 'language'); },
    get permission_id() { return get_property(this, 'permission_id'); },
    get org_host() { return get_property(this, 'org_host'); },
    // calculated attributes
    get individualtype_guest() { return get_property(this, 'individual_type') === 'GUEST'; },
    get individualtype_user() { return get_property(this, 'individual_type') === 'USER'; },
    get org_cana() { let org_id = this.organization_id; return org_id === this.constants.org_ids.CanaQA || org_id === this.constants.org_ids.CanaProd; },
    get org_nasdaq() { return this.organization_id === this.constants.org_ids.Nasdaq; },

    // individual components
    get indv_credentials() { return get_flag(this, 'indv_credentials'); },
    get indv_dailycheck() { return get_flag(this, 'indv_dailycheck'); },
    get indv_documents() { return get_flag(this, 'indv_documents'); },
    get indv_forms() { return get_flag(this, 'indv_forms'); },
    get indv_guest() { return get_flag(this, 'indv_guest'); },
    get indv_issuer() { return get_flag(this, 'indv_issuer'); },
    get indv_r2w() { return get_flag(this, 'indv_r2w'); },
    get indv_requirements() { return get_flag(this, 'indv_requirements'); },

    // org components
    get org_credentials() { return get_flag(this, 'org_credentials'); },
    get org_dailycheck() { return get_flag(this, 'org_dailycheck'); },
    get org_documents() { return get_flag(this, 'org_documents'); },
    get org_forms() { return get_flag(this, 'org_forms'); },
    get org_guest() { return get_flag(this, 'org_guest'); },
    get org_issuer() { return get_flag(this, 'org_issuer'); },
    get org_networks() { return get_flag(this, 'org_networks'); },
    get org_wallet() { return get_flag(this, 'org_wallet'); },
    get org_reports() { return get_flag(this, 'org_reports'); },
    get org_qraccess() { return get_flag(this, 'org_qraccess'); },
    get org_r2w() { return get_flag(this, 'org_r2w'); },
    get org_subcontractor() { return get_flag(this, 'org_subcontractor'); },
    get org_requirements() { return get_flag(this, 'org_requirements'); },
    get org_verifiable_credentials() { return get_flag(this, 'org_verifiable_credentials'); },
    get org_inspection_report() { return get_flag(this, 'org_inspection_report'); },

    // access
    has_access: function(module, access) { return check_access(this, module, access); },

    // parsing info from back end
    parse_authentication_result: async function(result) {
        let parsed_auth = {};
        parsed_auth['id'] = result.id;
        parsed_auth['individual_type'] = result.individual_type;
        parsed_auth['actingorganization'] = result.organization;
        parsed_auth['actingorganization_id'] = result.organization_id;
        parsed_auth['actingpermission_id'] = result.permission_id;
        parsed_auth['firstname'] = result.firstname;
        parsed_auth['lastname'] = result.lastname;
        parsed_auth['email'] = result.email;
        parsed_auth['organization'] = result.organization;
        parsed_auth['organization_id'] = result.organization_id;
        parsed_auth['permission_id'] = result.permission_id;
        parsed_auth['defaultlocation_id'] = result.defaultlocation_id;
        parsed_auth['indv_dailycheck'] = result.dailycheck;
        parsed_auth['indv_credentials'] = result.credentials;
        parsed_auth['indv_issuer'] = result.issuer;
        parsed_auth['indv_r2w'] = result.r2w;
        parsed_auth['indv_documents'] = result.documents;
        parsed_auth['indv_forms'] = result.forms;
        parsed_auth['indv_guest'] = result.guest;
        parsed_auth['indv_requirements'] = result.requirements;
        parsed_auth['org_dailycheck'] = result.org_dailycheck;
        parsed_auth['org_credentials'] = result.org_credentials;
        parsed_auth['org_issuer'] = result.org_issuer;
        parsed_auth['org_r2w'] = result.org_r2w;
        parsed_auth['org_documents'] = result.org_documents;
        parsed_auth['org_guest'] = result.org_guest;
        parsed_auth['org_qraccess'] = result.org_qraccess;
        parsed_auth['org_subcontractor'] = result.org_subcontractor;
        parsed_auth['org_forms'] = result.org_forms;
        parsed_auth['org_networks'] = result.org_networks;
        parsed_auth['org_wallet'] = result.org_wallet;
        parsed_auth['org_reports'] = result.org_reports;
        parsed_auth['org_requirements'] = result.org_requirements;
        parsed_auth['org_verifiable_credentials'] = result.org_verifiable_credentials;
        parsed_auth['org_inspection_report'] = result.org_inspection_report;
        parsed_auth['org_host'] = result.org_host;
        parsed_auth['logo'] = (result.logo && !result.logo.toLowerCase().startsWith('https://') && !result.logo.toLowerCase().startsWith('http://')) ?
            ((await API.endpoint('storage')) + '/logo/' + result.organization_id + '/' + result.logo) : result.logo;
        parsed_auth['language'] = result.default_language;
        parsed_auth['access'] = result.access;
        return parsed_auth;
    },

    // constants
    constants: {
        permission_ids: {
            Superadmin: '3935DEC1-0FDF-4A44-B2A4-3BCFB57D1C24',
            GlobalAdmin: 'B3ECF0CF-10D7-4F6A-A103-8FEC0E86DA61',
            Admin: 'E449DF20-6399-45A1-AF6C-400F06E04C0D',
            Manager: '7A001FF4-4D16-4D53-AED9-7747164D3015',
            Auditor: '708B16DB-D69B-4346-ADD8-ADCF71DF7BAB',
            User: '4EBEB4CF-4B68-4A29-A8FF-01FBC744A898'
        },
        org_ids: {
            CanaQA: 'FB7CE330-CCE7-4A11-B50F-42F8AAB0B278',
            CanaProd: 'FD794D9A-95AC-4DF5-B280-5C71846C3960',
            Nasdaq: 'E781F687-E755-45A8-9420-63A7A0E1E55F'
        }
    }
};


//#region internal functions

const get_property = (auth, property_name) => {
    let authentication = auth.get_authentication();
    return authentication && authentication[property_name];
}

const get_flag = (auth, flag_name) => {
    let authentication = auth.get_authentication();
    return authentication ? authentication[flag_name] === 'YES' : false;
}

const check_access = (auth, module, module_access) => {
    let authentication = auth.get_authentication();
    if (!authentication) return false;
    return authentication.access.some(item => item.module === module && JSON.parse(item.module_access).includes(module_access));
}

//#endregion

export default auth;
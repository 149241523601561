import { API } from "aws-amplify";
import React, { useEffect, useState} from "react";
import {Button, Checkbox, Form, Input} from 'semantic-ui-react';
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';
import datelib from '../../../../../libs/date-lib';
import { enCA, frCA } from 'date-fns/locale';
import auth from "../../../../../libs/auth-lib";

import './mdl_add.css';



export default function({ set_mdl_open, populate_function }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_location, set_location] = useState([]);
    const [ var_default, set_default ] = useState(false);
    const [ var_ready, set_ready] = useState(false);
    const [ var_processing, set_processing] = useState(false);
    const [var_required_field,set_required_field] = useState(false);
    const [ var_startdate, set_startdate ] = useState(null);
    const [ var_enddate, set_enddate ] = useState(null);



    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_location.transactionby === undefined){
            set_default(false);
            const location = {...var_location};
            location['default_org'] = '';
            location['organization_id'] = JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id;
            location['transactionby'] = JSON.parse(sessionStorage.getItem('authentication')).id;
            location['transactionorg'] = JSON.parse(sessionStorage.getItem('authentication')).organization_id;
            set_location(location);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_location]);

    useEffect(() => {
        if(var_location.default_org !== undefined) {

            const location = {...var_location};

            if (var_default) {
                location['default_org'] = 'YES';
            } else {
                location['default_org'] = 'NO';
            }
            set_location(location);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_default]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function insert_location() {
        set_processing(true);
        try {
            await API_post_org_location();
            set_ready(false);
            set_processing(false);
            set_mdl_open(false);
            populate_function();
        } catch (e) {
            console.log(e);
        }

    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_org_location(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-locations', '/post-org-location/', { body: var_location });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_default(){
        set_default(var_default => !var_default);
        set_ready(true);
    }

    function onChange_input(event){
        const location = { ...var_location };
        location[event.target.name] = event.target.value;
        if(event.target.name==="location_name"){
            set_required_field(false)
        }
        set_location(location);
        set_ready(true);
    }

    
    function onChange_startdate(event, data){
        if(data.value !== var_startdate) {
            set_startdate(data.value);
            const location = { ...var_location };
            location['startdate'] = data.value ? datelib.localdate_to_utc_midnight_epoch(data.value) : null;
            set_location(location);
            set_ready(true);
        }
    }

    function onChange_enddate(event, data){
        if(data.value !== var_enddate) {
            set_enddate(data.value);
            const location = { ...var_location };
            location['enddate'] = data.value ? datelib.localdate_to_utc_midnight_epoch(data.value) : null;
            set_location(location);
            set_ready(true);
        }
    }

    function onClick_submit(){
        if(var_ready){
            if (!var_location.location_name || var_location.location_name === '') {
                set_processing(false);
                set_required_field(true);
            }
            else{
            set_processing(true);
            insert_location();
            }
        }
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{t("Add Location or Project")}</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
            </div>
            <div className="modal-content">
                <Form>
                    <Form.Group>
                        <Checkbox
                            toggle
                            className={"default " + (var_default ? ' toggle_active' : '')}
                            label={JSON.parse(sessionStorage.getItem('authentication')).organization + ' default location/project'}
                            onChange={onChange_default}
                            checked={var_default || false}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                            className='code'
                            name='code'
                            value={var_location.code || ''}
                            control={Input}
                            onChange={onChange_input}
                            label={t("CODE")}
                            placeholder={t("Code...")}
                        />
                        <div className="form-spacer"></div>
                        <Form.Field
                            className='location_name'
                            name='location_name'
                            value={var_location.location_name || ''}
                            control={Input}
                            onChange={onChange_input}
                            label={t("LOCATION/PROJECT NAME")}
                            placeholder={t("Location/Project name...")}
                        />
                    </Form.Group>
                    <Form.Group className="date_fields">
                    <SemanticDatepicker className='startdate'
                                        locale={auth.language.datepicker}
                                        format={auth.language.name === 'French' ? 'D MMM YYYY' : 'MMM DD, YYYY'}
                                        formatOptions={{locale: auth.language.name === 'French' ? frCA: enCA}}
                                        name='startdate'
                                        onChange={onChange_startdate}
                                        clearable={true}
                                        datePickerOnly={true}
                                        showToday={false}
                                        maxDate = { var_enddate ? new Date(var_enddate) : null}
                                        label={t('START DATE')}
                                        placeholder={t('Start date...')}
                                        value={var_startdate}/>
                    <SemanticDatepicker className='enddate'
                                        locale={auth.language.datepicker}
                                        format={auth.language.name === 'French' ? 'D MMM YYYY' : 'MMM DD, YYYY'}
                                        formatOptions={{locale: auth.language.name === 'French' ? frCA: enCA}}
                                        name='enddate'
                                        onChange={onChange_enddate}
                                        clearable={true}
                                        datePickerOnly={true}
                                        showToday={false}
                                        minDate = { new Date(var_startdate) }
                                        label={t('END DATE')}
                                        placeholder={t('End date...')}
                                        value={var_enddate}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                            className='area'
                            name='area'
                            value={var_location.area || ''}
                            control={Input}
                            onChange={onChange_input}
                            label={t("AREA")}
                            placeholder={t("Area...")}
                        />
                        <Form.Field
                            className='businessunit'
                            name='businessunit'
                            value={var_location.businessunit || ''}
                            control={Input}
                            onChange={onChange_input}
                            label={t("BUSINESS UNIT")}
                            placeholder={t("Business unit...")}
                        />
                        <Form.Field
                            className='costobject'
                            name='costobject'
                            value={var_location.costobject || ''}
                            control={Input}
                            onChange={onChange_input}
                            label={t("COST OBJECT")}
                            placeholder={t("Cost object...")}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                            className='address'
                            name='address'
                            value={var_location.address || ''}
                            control={Input}
                            onChange={onChange_input}
                            label={t("ADDRESS")}
                            placeholder={t("Address...")}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                            className='city'
                            name='city'
                            value={var_location.city || ''}
                            control={Input}
                            onChange={onChange_input}
                            label={t("CITY")}
                            placeholder={t("City...")}
                        />
                        <div className="form-spacer"></div>
                        <Form.Field
                            className='province'
                            name='province'
                            value={var_location.province || ''}
                            control={Input}
                            onChange={onChange_input}
                            label={t("STATE/PROVINCE")}
                            placeholder={t("State/Province...")}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                            className='country'
                            name='country'
                            value={var_location.country || ''}
                            control={Input}
                            onChange={onChange_input}
                            label={t("COUNTRY")}
                            placeholder={t("Country...")}
                        />
                    </Form.Group>
                </Form>
                {var_required_field=== true &&
                            <div id="reqfielderror" className="message warning">
                                <div className="message_icon"><img src={"/icons/warning 60px (d91e18).svg?ts=" + Date.now()} alt={t('warning icon')} /></div>
                                <div className="message_text_wrapper">
                                    <div className="message_text cause">{t('Please provide a name for this location/project')}</div>
                                </div>
                            </div>
                            }
            </div>

            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>{t("CANCEL")}</Button>
                    {!var_processing ?
                        <Button className={"btn_primary " + (var_ready ? ' btn_active' : '')} onClick={() => onClick_submit()}>{t("ADD LOCATION/PROJECT")}</Button>
                        :
                        <Button loading className="btn_primary btn_active">{t("ADD LOCATION/PROJECT")}</Button>
                    }
                </div>
            </div>
        </>
    )
}
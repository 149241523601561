import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import auth from '../../../libs/auth-lib';
import "./onsite.css";

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import CRD_ONSITE from "./crd_onsite/crd_onsite";


export default function(appProps) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();
    const history = useHistory();



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if (auth.is_loggedin && auth.permission_id === auth.constants.permission_ids.Superadmin) {
            appProps.set_visibleframe(true);
            appProps.set_visiblemenu('ORGANIZATION')
            appProps.set_activemenu('/org/onsite');
        }else{
            history.push('/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);



    //  async functions ------------------------------------------------------------------------------------------------

    //  API calls ------------------------------------------------------------------------------------------------------

    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return(
        <>
            {auth.is_loggedin &&
            <div className="content">
                <div
                    className="content-topmenu">{auth.organization} {t('Onsite')}
                </div>
                <div className="content-wrapper">

                    <CRD_ONSITE></CRD_ONSITE>

                </div>
            </div>
            }
        </>
    )
};

import React, { useEffect, useState } from 'react';
import { Button, Modal, Table } from 'semantic-ui-react';
import auth from '../../../../../libs/auth-lib';
import { API } from "aws-amplify";
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import CMP_SUCCESS from '../../../../../components/cmp_success/cmp_success';
import MDL_PLAID from './mdl_plaid/mdl_plaid';

import './crd_orgprofile.css';

export default function() {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();

    const [ var_link_token, set_link_token ] = useState('');
    const [ var_mdl_plaid_open, set_mdl_plaid_open ] = useState(false);

    const [ var_assetinspection_processing, set_assetinspection_processing ] = useState(false);
    const [ var_mdl_success_open, set_mdl_success_open ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        set_mdl_plaid_open(var_link_token !== '');
    },[var_link_token]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function create_link_token() {
        try {
            let data = await API_plaid_create_link_token();
            set_link_token(data.link_token);
        } catch (e) {
          console.log(e);
        }
    }

    async function create_asset_inspection_report() {
        try {
            set_assetinspection_processing(true);
            let credential = await asset_inspection_report();
            await API_post_asset_inspection_report(credential);
            set_mdl_success_open(true);
            set_assetinspection_processing(false);
        } catch (e) {
            console.log(e);
        }
    }
  
    async function asset_inspection_report() {
        let configurations = await API_get_org_configurations();
        return JSON.parse(configurations[0].config_value);
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_plaid_create_link_token() {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('integrations', '/plaid-create-link-token');
    }

    function API_post_asset_inspection_report(credential) {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-orgcredentials', '/post-asset-inspection-report/', { body: {"organization_id": auth.organization_id, "unsigned_credential": credential }});
    }
  
    function API_get_org_configurations() {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org', '/get-org-configurations/' + auth.organization_id, {queryStringParameters: {category: 'Asset Inspection'}});
  }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_plaid(){
        create_link_token();
    }

    function onClick_assetinspection(){
        create_asset_inspection_report();
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='content-card-noheader' id='crd_orgprofile'>
                <div className='content-card-gridcontent'>
                    <div className='content-card-header'>
                        {auth.organization} {t('Business Profile')}
                    </div>

                    <div className='crd_details'>
                        <p>{t('Verifying your business profile is important to create trust and certainty with your potential business partners and employees.')}</p>
                    </div>

                    <div className='tablewrapper'>
                        <Table unstackable compact>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{t('DESCRIPTION')}</Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {auth.org_inspection_report &&
                                <Table.Row>
                                    <Table.Cell>{t('Asset Inspection Report')}</Table.Cell>
                                    <Table.Cell ><Button className="btn_primary btn_active"  loading={var_assetinspection_processing} disabled={var_assetinspection_processing} onClick={() => onClick_assetinspection()}>{t('GENERATE NOW')}</Button></Table.Cell>
                                </Table.Row>
                                }
                                <Table.Row>
                                    <Table.Cell>{t('Bank Account Ownership Details')}</Table.Cell>
                                    <Table.Cell ><Button className="btn_primary btn_active" onClick={() => onClick_plaid()}>{t('VERIFY NOW')}</Button></Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </div>
                </div>


                {/***** MODAL: PLAID *********************************************************************************/}

                <Modal id='mdl_plaid'
                       dimmer={'inverted'}
                       open={var_mdl_plaid_open}
                       onClose={() => set_mdl_plaid_open(false)}>
                    <MDL_PLAID
                        set_mdl_open={set_mdl_plaid_open}
                        var_link_token={var_link_token}
                    />
                </Modal>

                {/***** END MODAL: PLAID *****************************************************************************/}

                {/***** MODAL: Success *******************************************************************************/}

                <Modal
                    id="mdl_info"
                    dimmer={'inverted'}
                    open={var_mdl_success_open}
                    onClose={() => set_mdl_success_open(false)}>
                    <CMP_SUCCESS
                        title={t('Asset Inspection Report')}
                        set_mdl_open={set_mdl_success_open}
                        success_or_fail='SUCCESS'
                        body={t('An Asset Inspectiopn Report has been generated and added to the wallet')}
                    />
                </Modal>

                {/***** END MODAL: INFO **************************************************************************/}

            </div>
        </>
    )

}
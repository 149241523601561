import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, StyleSheet, View } from '@react-pdf/renderer';

export default function({var_detail, var_org}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

	const styles = StyleSheet.create({
		profileHeader: {
			justifyContent: 'space-between',
			padding: '12px 16px',
			borderBottom: 1,
			borderStyle: 'solid',
			borderColor: '#ebebeb'
		},
		profileHeaderTitle: {
			fontSize: 24,
			fontWeight: 500,
		},
		profileHeaderItem: {
			flexDirection: 'row',
			alignItems: 'center',
			fontSize: 9,
			padding: '3px 0',
		},
		profileHeaderItemIcon: {
			height: 10,
			width: 10,
			marginRight: 5
		},
		headerInfo: {
			flexDirection: 'row',
			paddingTop: 4,
		},
		headerInfoItem: {
			marginRight: 10,
		},
		headerInfoItemLabel: {
			color: '#cfd3d5',
			fontWeight: 500,
			fontSize: 8,
			textTransform: 'capitalize'
		},
		headerInfoItemText: {
			fontSize: 9,
			paddingTop: 2
		}
	});

    // RENDER APP ======================================================================================================
    return(
        <View style={styles.profileHeader}>
			<View style={styles.profileHeaderLeft}>
				<Text style={styles.profileHeaderTitle}>{var_detail.individual}</Text>
			</View>
			<View style={styles.headerInfo}>
				{var_org && var_org.name != null && var_org.name !== '' &&
					<View style={styles.headerInfoItem}>
						<View style={styles.headerInfoItemLabel}>
							<Text>{t('ORGANIZATION')}</Text>
						</View>
						<View style={styles.headerInfoItemText}>
							<Text>{var_org.name}</Text>
						</View>
					</View>
				}
			</View>
        </View>
    )

}

import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from 'semantic-ui-react';
import '../../../../../../i18n';
import auth from '../../../../../../libs/auth-lib';
import Moment from "moment";
import datelib from '../../../../../../libs/date-lib';

import { useTranslation } from 'react-i18next';

import CMP_TABLECONTROLS, { filter_helper } from '../../../../../../components/cmp_tablecontrols/cmp_tablecontrols';
import MDL_HISTORY from "../../../../../../components/cmp_history/cmp_history";

import './tab_documents.css';

import MDL_CONFIRMATION from "../../../../../../components/cmp_confirmation/cmp_confirmation";
import MDL_UPDATE from "../../../../../org-documents/documents/crd_documents/mdl_update/mdl_update";
import MDL_ADD from "../../../../../org-documents/documents/crd_documents/mdl_add/mdl_add"
import MDL_DOCVIEWER from "../../../../../../components/cmp_docviewer/cmp_docviewer";

export default function({ var_activetab, var_location_id, var_target, var_org_owns_location }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_documents, set_documents ] = useState([]);
    const [ var_document, set_document ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(10);
    const [ var_offset, set_offset] = useState(0);
    const [ var_archive, set_archive ] = useState('NO');
    const [ var_sortby, set_sortby ] = useState('name');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));

    const [ var_mdl_add_open, set_mdl_add_open ] = useState(false);
    const [ var_mdl_update_open, set_mdl_update_open ] = useState(false);
    const [ var_target_id, set_target_id ] = useState(false);
    const [ var_target_text, set_target_text ] = useState(false);
    const [ var_ack_req, set_ack_req ] = useState('');

    const [ var_mdl_confirmation_open, set_mdl_confirmation_open ] = useState(false);

    const [ var_mdl_history_open, set_mdl_history_open ] = useState(false);
    const [ var_modaltitle, set_modaltitle ] = useState('');
    const [ var_mdl_docviewer_open, set_mdl_docviewer_open ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_activetab === 'DOCUMENTS') {
            populate_documents()
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_activetab, var_archive]);

    useEffect(()=>{
        if(var_filter.load) {
        populate_documents(null, 0);
        }
       // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_filter]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_documents(limit,offset,sortby,sortorder,archive,filter){
        if (var_activetab !== 'DOCUMENTS') return; // Don't load data if tab is not visible

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }
        if(archive) {
            set_archive(archive);
        }else{
            archive=var_archive
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_loc_documents(limit,offset,sortby,sortorder, archive, filter);
            set_totalrows(results.totalrows);
            set_documents(results.results === undefined ? [] : results.results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername,sortorder){
        try {
            return (await API_get_loc_documents(filter_helper.maxFilterItems, 0, filtername, sortorder, var_archive,{}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_documents(limit,offset){
        try {
            return (await API_get_loc_documents(limit, offset, var_sortby, var_sortorder, var_archive, var_filter)).results;
        } catch(e) {
            console.log(e);
            throw e;
        }
    }

    async function remove_document(){
        set_ready(false);
        set_loading(true);
        try {
            await API_delete_loc_document();
            populate_documents();
        } catch (e) {
            console.log(e);
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_loc_documents(limit,offset,sortby,sortorder,archive,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-documents',
                        '/get-loc-documents/'+var_location_id,
                        {
                            queryStringParameters: {
                            limit: limit,
                            offset: offset,
                            sortby: sortby,
                            sortorder: sortorder,
                            archive: archive,
                            tz: datelib.timezone,
                            filtername: filtername
                            },
                            body: filter
                        }
                        );
    }

    function API_delete_loc_document(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.del("org-locations",'/delete-loc-document/'+var_target_id, {body: var_document});

    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(e,sortby){
        var sortorder = var_sortorder;
        if (e.target === e.currentTarget){
            if (var_sortby !== sortby) {
                sortorder = 'ascending';
            }else {
                if(sortorder === 'ascending'){
                    sortorder = 'descending';
                }else if(sortorder === 'descending'){
                    sortorder = 'ascending';
                }
            }
            set_sortorder(sortorder);
            populate_documents('',0,sortby,sortorder);
        }
    }

    function onClick_adddocument(){
        set_mdl_add_open(true);
    }

    function onClick_update(var_target_id,var_ack_req){
        if (auth.permission_id === auth.constants.permission_ids.Superadmin && var_org_owns_location) {
            set_target_id(var_target_id);
            set_ack_req(var_ack_req);
            set_mdl_update_open(true)
        }
    }

    function onOpen_mdl_history(target_id, target_name) {
        set_modaltitle('Credential History - '+target_name);
        set_target_id(target_id);
        set_mdl_history_open(true)
    }

    function onClick_removedocument(var_target_id, var_target_text, var_locationdocument_id){
        const document = {...var_document};
        document['locationdocument_id'] = var_locationdocument_id;
        document['organization_id'] = auth.actingorganization_id;
        document['transactionby'] = auth.id;
        document['transactionorg'] = auth.organization_id;
        set_document(document);

        set_target_id(var_locationdocument_id);
        set_target_text(var_target_text);
        set_mdl_confirmation_open(true);
    }

    function onClick_opendocument(var_target_id){
        set_target_id(var_target_id);
        set_mdl_docviewer_open(true);
    }

    // RENDER APP ======================================================================================================

    return (
        <div className="modal-tab" id="org_location_doc_tab">
            <div className="content-filter">
                <div className="content-filter-archive">
                    {var_archive === 'NO' ?
                        <Button className="btn_tertiary" onClick={() => set_archive('YES')}><img className="btn_icon" src={"/icons/archive 30px (4dcbd4).svg?ts=" + Date.now()} alt={t('archive icon')}/>{t('VIEW ARCHIVED DOCUMENTS')}</Button>
                        :
                        <Button className="btn_tertiary" onClick={() => set_archive('NO')}><img className="btn_icon" src={"/icons/checkmark 30px (4dcbd4).svg?ts=" + Date.now()} alt={t('checkmark icon')}/>{t('VIEW ACTIVE DOCUMENTS')}</Button>
                    }
                </div>
                <div className="content-filter-item item-pagination">
                    <CMP_TABLECONTROLS
                        var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                        total_rows={var_documents && var_documents.length > 0 ? var_totalrows : 0} populatefunction={populate_documents}
                        downloadname='Documents' downloadfunction={download_documents}
                        var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                        table_config={[
                            {name: 'name', datatype: 'text', labelKey: 'NAME', download: true, filter: true},
                            {name: 'epochissued', datatype: 'epoch', labelKey: 'ISSUED', download: true, filter: true},
                            {name: 'ack_req', datatype: 'text', labelKey: 'ACK REQ', download: true, filter: true},
                            {name: 'acknowledgements', datatype: 'int', labelKey: 'ACKS', download: true, filter: true}
                        ]}
                    />
                </div>
            </div>
            <div className="tablewrapper">
                <Table sortable compact className={(var_loading ? " tbl_loading" : "")}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell sorted={var_sortby === 'name' ? var_sortorder : null} onClick={(e)=>onClick_sort(e,'name')}>{t("NAME")}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'epochissued' ? var_sortorder : null} onClick={(e)=>onClick_sort(e,'epochissued')}>{t("ISSUED")}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'ack_req' ? var_sortorder : null} onClick={(e)=>onClick_sort(e,'ack_req')}>{t("ACK REQ")}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'acknowledgements' ? var_sortorder : null} onClick={(e)=>onClick_sort(e,'acknowledgements')}>{t("ACKS")}</Table.HeaderCell>
                            <Table.HeaderCell className="td_attachment" sorted={var_sortby === 'name' ? var_sortorder : null} onClick={() => onClick_sort("NAME")}><img src={"/icons/paperclip 30px (c9caca).svg?ts=" + Date.now()} alt="paperclip icon"/></Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'status' ? var_sortorder : null} onClick={(e)=>onClick_sort(e,'status')}>{t("STATUS")}</Table.HeaderCell>
                            {var_org_owns_location && <Table.HeaderCell className="td_icon_center" >{t("HSTRY")}</Table.HeaderCell>}
                            {auth.has_access('ORG-DOC', 'write') && var_org_owns_location &&
                            <Table.HeaderCell ></Table.HeaderCell>
                            }
                        </Table.Row>

                    </Table.Header>
                    {var_ready && var_totalrows !== 0 &&
                    <Table.Body>
                        {var_documents.map((item, i) =>
                            <Table.Row key={i} >
                                <Table.Cell onClick={() => onClick_update(item.id,item.ack_req)}>{item.name}</Table.Cell>
                                <Table.Cell onClick={() => onClick_update(item.id,item.ack_req)}>{item.epochissued !== null && item.epochissued !== undefined && item.epochissued !== "" ? Moment.unix(item.epochissued).utc(false).format('ll'): ''}</Table.Cell>
                                <Table.Cell onClick={() => onClick_update(item.id,item.ack_req)}>{item.ack_req}</Table.Cell>
                                <Table.Cell onClick={() => onClick_update(item.id,item.ack_req)}>{item.acknowledgements}</Table.Cell>
                                <Table.Cell className='td_attachment' onClick={() => onClick_opendocument(item.id)}>
                                    {(item.filename !== null && item.filename !== '') ?
                                        <img src={"/icons/paperclip 60px (717473).svg?ts=" + Date.now()} alt={t('paperclip icon')}/>
                                        :
                                        ''
                                    }
                                </Table.Cell>
                                <Table.Cell onClick={() => onClick_update(item.id,item.ack_req)}>{item.status}</Table.Cell>
                                {var_org_owns_location && <Table.Cell className="td_icon_center" onClick={() => onOpen_mdl_history(item.id, item.name)}><img src={"/icons/history 60px (bcbebe).svg?ts=" + Date.now()} alt="archive icon"></img></Table.Cell>}
                                {auth.has_access('ORG-DOC', 'write') && var_org_owns_location &&
                                <Table.Cell className="td_icon_remove" onClick={() => onClick_removedocument(item.id, item.name, item.locationdocument_id, )}><img src={"/icons/delete 18px (6B7280).svg?ts=" + Date.now()} alt="delete icon"/></Table.Cell>
                                }
                            </Table.Row>
                        )}
                    </Table.Body>
                    }
                </Table>
                {var_ready && var_totalrows === 0 &&
                <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt="alert icon"/>{t("there are no documents to display")}</div>
                }
            </div>
            <div className="content-filter">
                <div className="content-filter-item item-pagination">
                    <CMP_TABLECONTROLS
                        var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                        total_rows={var_documents && var_documents.length > 0 ? var_totalrows : 0} populatefunction={populate_documents}
                    />
                </div>
            </div>
            {auth.has_access('ORG-DOC', 'insert') && var_org_owns_location &&
            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className={"btn_primary " + (var_ready ? 'btn_active' : '')} onClick={() => onClick_adddocument()}>{t("ADD DOCUMENTS")}</Button>
                </div>
            </div>
            }



            {/***** MODAL: DOCUMENT VIEW *****************************************************************************/}

            <Modal id="mdl_docviewer"
                   dimmer={'inverted'}
                   open={var_mdl_docviewer_open}
                   onClose={() => set_mdl_docviewer_open(false)}>
                <MDL_DOCVIEWER
                    set_mdl_open={set_mdl_docviewer_open}
                    targetid={var_target_id}
                    classification='document'
                />
            </Modal>

            {/***** END MODAL: DOCUMENT VIEW *************************************************************************/}

            {/***** MODAL: ADD ***************************************************************************************/}

            <Modal id="org-documents_mdl_adddocument"
                   dimmer={'inverted'}
                   open={var_mdl_add_open}
                   onClose={() => set_mdl_add_open(false)}>
                <MDL_ADD
                    set_mdl_open={set_mdl_add_open}
                    populate_function={populate_documents}
                    var_location_id={var_location_id}
                    var_target={var_target}
                    var_target_id={var_location_id}
                    >

                </MDL_ADD>
            </Modal>

            {/***** END MODAL: ADD ***********************************************************************************/}

            {/***** MODAL: CONFIRMATION *******************************************************************************/}

            <Modal id="mdl_confirmation"
                   dimmer={'inverted'}
                   open={var_mdl_confirmation_open}
                   onClose={() => set_mdl_confirmation_open(false)}>
                <MDL_CONFIRMATION
                    set_mdl_open={set_mdl_confirmation_open}
                    var_modaltitle={'Remove Document'}
                    var_message={'Are you sure you want to remove ' + var_target_text + ' from ' + var_target + '?'}
                    confirmation_function={remove_document}>
                </MDL_CONFIRMATION>
            </Modal>

            {/***** END MODAL: CONFIRMATION **************************************************************************/}


            {/***** MODAL: UPDATE ********************************************************************************/}

            <Modal id="org-documents_mdl_updatedocument"
                       dimmer={'inverted'}
                       open={var_mdl_update_open}
                       onClose={() => set_mdl_update_open(false)}>
                    <MDL_UPDATE
                        set_mdl_open={set_mdl_update_open}
                        var_target_id={var_target_id}
                        var_ack_req={var_ack_req}
                        var_check_id={var_location_id}
                        populate_function={populate_documents}>
                    </MDL_UPDATE>
                </Modal>

                {/***** END MODAL: UPDATE ***************************************************************************/}


                {/***** MODAL: HISTORY *******************************************************************************/}

                <Modal id="mdl_history"
                       dimmer={'inverted'}
                       open={var_mdl_history_open}
                       onClose={() => set_mdl_history_open(false)}>
                    <MDL_HISTORY
                        set_mdl_open={set_mdl_history_open}
                        var_modaltitle={var_modaltitle}
                        var_target_id={var_target_id}>
                    </MDL_HISTORY>
                </Modal>

                {/***** END MODAL: HISTORY ***************************************************************************/}
        </div>
    )
};

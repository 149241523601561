//  OUTCOMES -----------------------------------------------------------------------------------------------------------
//
//  - Adds a credential to requirement pre-approved list 
//
//  --------------------------------------------------------------------------------------------------------------------

import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { Button, Form, Modal } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';
import auth from '../../../../../libs/auth-lib';
import datelib from '../../../../../libs/date-lib';

import { filter_helper } from '../../../../../components/cmp_tablecontrols/cmp_tablecontrols';
import CMP_SUCCESS from '../../../../../components/cmp_success/cmp_success';

import './mdl_add_credential.css';

export default function({ set_mdl_add_credential_open, var_credential, populate_function }) {

    //  variable declarations ----, --------------------------------------------------------------------------------------

    const { t } = useTranslation();  
  
    const [ var_processing, set_processing ] = useState(false);
    const [ var_dd_requirements, set_dd_requirements ] = useState([]);
    const [ var_preapproved, set_preapproved ] = useState(false);
    const [ var_selected_requirement, set_selected_requirement ] = useState(null);    
    const [ var_mdl_success_open, set_mdl_success_open ] = useState(false);

    const [ var_limit, set_limit] = useState(1000);
    const [ var_offset, set_offset] = useState(0);
    const [ var_sortby, set_sortby ] = useState('requirement');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true)); 

    //  variable listeners ---------------------------------------------------------------------------------------------
    useEffect(() => {        
        var_credential && populate_requirements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //  async functions ------------------------------------------------------------------------------------------------
    async function populate_requirements() {
        try {
            let results = await API_get_requirements_credential_preapproved();
            set_dd_requirements(results.results.map(item => ({ value: item.id, text: item.requirement })));
        } catch (e) {
            console.log(e);
        }
    }

    async function add_requirement_historical() {
        set_processing(true);
        try {            
            await API_post_requirement_historical();
            populate_function && await populate_function();
            await populate_requirements(null, 0);
            set_processing(false);
            set_mdl_success_open(true); 
        } catch (e) {
            console.log(e);
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_requirements_credential_preapproved() {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-requirements', '/get-requirements-credential-preapproved/'+auth.actingorganization_id+'/'+
            var_credential.credential_id+'/'+var_credential.issuer_id,
            {
                queryStringParameters: {
                    limit: var_limit,
                    offset: var_offset,
                    sortby: var_sortby,
                    sortorder: var_sortorder,
                    preapproved: var_preapproved
                },
                body: var_filter
            }           
        );
    } 

    function API_post_requirement_historical() {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-requirements','/post-requirement-historical',
         {
            queryStringParameters: {
                tz : datelib.timezone
            },
            body: {
                requirement_id: var_selected_requirement?.value,
                issuer_id: var_credential?.issuer_id,
                credential_id: var_credential?.credential_id,
                status: 'VALID',
                transactionorg: auth.actingorganization_id
            }
        });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_requirement(event, data) {
        set_selected_requirement({
            value: data.value,
            text: var_dd_requirements.find(item => item.value === data.value)?.text
        });
    }   
    
    async function onClick_addcredential() {      
        if (var_credential?.credential_id && var_selected_requirement) {
            await add_requirement_historical();
        }
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div>
                <div className="modal-header">
                    <div className="modal-header-title">{t('Add Credendial')}</div>
                </div>                
                <div className="modal-content">
                    <div>{`${t('Select the requirement you want to add')} ${var_credential?.credential} ${t('as a pre-approved credential and click "ADD CREDENTIAL"')}`}</div>
                    <div className="detail-spacer"></div>
                    <Form>
                        <Form.Group>
                            <Form.Select
                                className='select_field'
                                id="requirements"
                                name="requirements"
                                label={t('REQUIREMENT')}
                                placeholder={t('Requirements...')}
                                options={var_dd_requirements}
                                onChange={onChange_requirement}
                                value={var_selected_requirement?.value || null}
                                fluid
                                search
                            />
                        </Form.Group>
                    </Form>
                    <div className='modal-footer-buttons'>
                        <Button className="btn_secondary" onClick={() => set_mdl_add_credential_open(false)}>{t('CANCEL')}</Button>
                        <Button disabled={!var_selected_requirement} loading={var_processing} className={'btn_primary' + (var_selected_requirement ? ' btn_active' : '')} 
                            onClick={onClick_addcredential}>{t('ADD CREDENTIAL')}</Button>
                    </div>                   
                </div>
            </div>

            {/***** MODAL: Success *******************************************************************************/}
            
            <Modal
                id="mdl_info"
                dimmer={'inverted'}
                open={var_mdl_success_open}
                onClose={() => set_mdl_success_open(false)}>
                <CMP_SUCCESS
                    title={t('Credential Added')}
                    set_mdl_open={set_mdl_add_credential_open}
                    success_or_fail='SUCCESS'
                    body={`${var_credential?.credential} ${t('has successfully been added to the')} ${var_selected_requirement?.text} ${t('pre-approval accepted list.')}`}
                />
            </Modal>

             {/***** END MODAL: INFO **************************************************************************/}   
        
        </>
    )  
}
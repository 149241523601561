import { API, Auth, Amplify } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { Button, Form, Input, Icon, Modal, Message, Checkbox } from 'semantic-ui-react'
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import auth from '../../../libs/auth-lib';

import config from '../../../config';

import './crd_login.css';


export default function ({ var_login, set_login, set_login_step, var_location_id, var_qrdata, set_language, var_params}) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const history = useHistory();

    const [var_processing, set_processing] = useState(false);
    const [var_ready, set_ready] = useState(false);
    const [var_error, set_error] = useState(false);
    const [var_passwordShown, set_passwordShown] = useState(false);
    const [var_validationerror, set_validationerror] = useState(false);
    const [var_validationerrormessage, set_validationerrormessage] = useState('');
    const [ var_ssoenabled, set_ssoenabled ] = useState(false);
    const [ var_sso_url, set_sso_url ] = useState('');
    const [ var_remember_me, set_remember_me ] = useState(JSON.parse(localStorage.getItem('rememberme')) || false);

    const [ var_mdl_select_lang_open, set_mdl_select_lang_open ] = useState(false);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_params.code) {
            if (var_params.code === 'auto') {
                auto_authenticate();
                return;
            } else {
                let params = JSON.parse(atob(var_params.code));
                if ('email' in params && 'password' in params) {
                    login_from_code(var_params.code);
                    return;
                }
            }
        }
        window.location.replace(config.general.alpha_indiv_url + '/login');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_params]);

    useEffect(() => {
        if (var_params && var_params.code) return;

        let var_emptyvalidation = true;
        if (!var_login.password || var_login.password === '') {
            var_emptyvalidation = false;
        }

        if (!var_login.email || var_login.email === '') {
            var_emptyvalidation = false;
        }
        set_ready(var_emptyvalidation);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_login]);

    useEffect(() => {
        if (var_params && var_params.code) return;

        const timeout = setTimeout(() => {
            execute_search(var_login.email);
        }, 500);
        return () => clearTimeout(timeout);
    }, [var_login.email]);

    useEffect(() => {
        if (var_params && var_params.code) return;

        let login = { ...var_login };

        if (JSON.parse(localStorage.getItem('rememberme'))) {
            set_remember_me(true);
            login['email'] = localStorage.getItem('emailaddress');
        } else {
            set_remember_me(false);
            login['email'] = '';
        }

        set_login(login);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //  async functions ------------------------------------------------------------------------------------------------


    async function auto_authenticate() {
        let token = new URLSearchParams(window.location.search).get('t');
        let tokendata = JSON.parse(window.atob(token));
        // make sure that there is no saved session
        sessionStorage.clear();
        await auth.logout();
        // set cognito token
        localStorage.setItem('amplify-signin-with-hostedUI', 'false');
        for (let key of Object.keys(tokendata)) {
            if (key.startsWith('CognitoIdentityServiceProvider')) {
                localStorage.setItem(key, tokendata[key]);
            }
        }
        // set sso configuration
        if (tokendata.sso_config) {
            sessionStorage.setItem('sso_config', tokendata.sso_config);
            let sso_config = JSON.parse(tokendata.sso_config);
            if (sso_config.hasOwnProperty('sso_client')) {
                Amplify.configure({
                    userPoolWebClientId: sso_config.sso_client
                });
            }
        }
        // set API to use the new session
        await Auth.currentUserInfo();
        await API.Credentials.set(await Auth.currentSession(), 'session');
        await authenticate_user(null, tokendata.org_menu ? '/org/overview' : null);
    }

    async function login_from_code(code) {
        let params = JSON.parse(window.atob(code));
        if ('email' in params && 'password' in params) {
            let aws_auth = await Auth.signIn(var_login.email, var_login.password);
            await authenticate_user(aws_auth);
        }
    }

    async function login(e) {
        if (var_ready) {
            let var_validation = false;
            let var_validmessage = '';
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|([\w-]+(?:\+[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*)|([\w-]+(?:\.[\w-]+)*)([\w-]+(?:\+[\w-]+)*)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(var_login.email)) {
                var_validation = true;
                var_validmessage = 'Please provide a valid email address'
            }
            set_validationerrormessage(var_validmessage);
            set_validationerror(var_validation);
            if (var_validation === false) {
                set_error(false);
                set_processing(true);
                try {
                    // save email address if remember me enabled
                    await handle_remember_me();

                    //  check cognito authentication
                    let aws_auth = await Auth.signIn(var_login.email, var_login.password);

                    await authenticate_user(aws_auth);
                } catch (error) {
                    if (error.message !== "Cannot read property 'username' of undefined") {
                        console.log(error.message);
                        set_error(true);
                        set_processing(false);
                    }
                }
            }
        }
    }

    async function authenticate_user(aws_auth, initial_page) {
        try {
            if (aws_auth && aws_auth.challengeName === 'NEW_PASSWORD_REQUIRED') {
                set_login_step('NEWPASSWORD');
            } else {

                //  get cognito user details
                let AWSuser = await Auth.currentUserInfo();
                sessionStorage.setItem('username', AWSuser.username);

                if (var_login.inviteid) {
                    await API_put_invite_accept(var_login.inviteid);
                } else if (var_location_id !== '-') {
                    await API_post_invite_qrcode(var_location_id);
                }

                //  get TerraHub user details
                let result = await API_get_authentication();

                //check if account has been archived
                if (result === 'NO ACTIVE ACCOUNT'){
                    set_error(true);
                    set_processing(false);
                }

                let authentication = await auth.parse_authentication_result(result);
                authentication.authenticated = true;
                authentication.cognito_id = AWSuser.username;
                if (authentication.language !== undefined) {
                    authentication.language = config.language[authentication.language];
                }
                auth.clear();
                sessionStorage.setItem('authentication', JSON.stringify(authentication));
                history.push(initial_page || '/indv/wallet');
            }
        } catch (error) {
            if (error.message !== "Cannot read property 'username' of undefined") {
                console.log(error.message);
                set_error(true);
                set_processing(false);
            }
        }
    }

    async function execute_search(value){
        let org_sso;
        var regex = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|([\w-]+(?:\+[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*)|([\w-]+(?:\.[\w-]+)*)([\w-]+(?:\+[\w-]+)*)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        if (value !== undefined && value !== '' && regex.test(value)) {
            org_sso = await API_get_org_sso(value);
            if (org_sso[0]?.ssoenabled === 'YES') {
                set_ssoenabled(true);
                set_sso_url(org_sso[0].sso_url);
            } else {
                set_ssoenabled(false);
                set_sso_url('');
            }
        } else {
            set_ssoenabled(false);
            set_sso_url('');
        }
    }

    async function handle_remember_me() {
        if (var_remember_me && var_login.email) {
            localStorage.setItem('rememberme', JSON.stringify(var_remember_me));
            localStorage.setItem('emailaddress', var_login.email);
        } else {
            localStorage.removeItem('rememberme');
            localStorage.removeItem('emailaddress');
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_put_invite_accept(inviteid) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.put('admin', '/put-invite-accept/' + inviteid);
    }

    function API_post_invite_qrcode(location_id) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.post('admin', '/post-invite-qrcode', {body: {location_id: location_id}});
    }

    function API_get_authentication() {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.get('admin', '/get-authentication');
    }

    function API_get_org_sso(email) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.get('org', '/get-org-sso/' + email);
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(event) {
        if (event.target.name === 'email') {
            set_validationerror(false);
            event.target.value = event.target.value.toLowerCase().trim();
        }
        if (event.target.name === 'password') {
            event.target.value = event.target.value.trim()
        }
        const login = { ...var_login };
        login[event.target.name] = event.target.value;
        set_login(login);
        set_ready(true);
        set_error(false);
    }

    function onClick_forgotpassword() {
        history.push('/reset')
    }

    function onClick_togglePassword() {
        set_passwordShown(var_passwordShown ? false : true);
    }

    function onClick_createaccount() {
        set_login_step('CREATEACCOUNT');
    }

    function onChange_remember_me() {
        set_remember_me(!var_remember_me);
    }

    // RENDER APP ======================================================================================================
    return (
        <div className="crd_login">
            {var_qrdata.name &&
            <>
                <div className="logo"><img src={var_qrdata.logo_path} alt={t('Organization logo')} /> </div>
                {var_location_id !== '-' &&
                <div className="locationlabel">{var_qrdata.organization.toUpperCase()} {t('LOCATION/PROJECT')}</div>
                }
                <div className="location">{var_qrdata.name}</div>
            </>
            }
            {var_login.inviteid &&
                <Message>
                    {t('Thank you for accepting the')} {var_login.name} {t('invite! Please login to complete the acceptance.')}
                </Message>
            }
            <Form onSubmit={(event) => event.preventDefault()}>
                <Form.Group>
                    <Form.Field
                        className='email'
                        id='email'
                        name='email'
                        value={var_login.email || ''}
                        control={Input}
                        onChange={onChange_input}
                        label={t('EMAIL')}
                        placeholder={t('Email...')}
                        autoComplete='email'
                        readOnly={var_login.emaildisabled}
                    />
                </Form.Group>
                {!var_ssoenabled &&
                    <Form.Group>
                        <Form.Field
                            className='password'
                            id='password'
                            name='password'
                            autoComplete='new-password'
                            value={var_login.password || ''}
                            control={Input}
                            type={!var_passwordShown ? 'password' : 'text'}
                            onChange={onChange_input}
                            label={t('PASSWORD')}
                            placeholder={t('Password...')}
                            icon={<Icon className="password_icon" name={!var_passwordShown ? 'eye' : 'eye slash'} link onClick={() => onClick_togglePassword()} size="large" />}
                        />
                    </Form.Group>
                }
                {var_error &&
                <div id="loginerror" className="message warning">
                    <div className="message_icon"><img src={"/icons/warning 60px (d91e18).svg?ts=" + Date.now()} alt={t('warning icon')} /></div>
                    <div className="message_text_wrapper">
                        <div className="message_text cause">{t('We were unable to log you in.')}</div>
                        <div className="message_text resolution">{t('Please check your email and password and try again.')}</div>
                    </div>
                </div>
                }

                        {var_validationerror &&
                            <div id="loginerror" className="message warning">
                                <div className="message_icon"><img
                                    src={"/icons/warning 60px (d91e18).svg?ts=" + Date.now()} alt={t('warning icon')}/>
                                </div>
                                <div className="message_text_wrapper">
                                    <div className="message_text cause">{t(var_validationerrormessage)}</div>
                                </div>
                            </div>
                        }

                        <Form.Group>
                            <div className='rememberme_checkbox'>
                                <Checkbox
                                    toggle
                                    className={"default " + (var_remember_me ? ' toggle_active' : '')}
                                    label={t('Remember me')}
                                    onChange={onChange_remember_me}
                                    checked={var_remember_me || false}
                                />
                            </div>
                        </Form.Group>

                        {(!var_processing && !var_ssoenabled) ?
                            <Button type='submit' id="btn_login"
                                    className={"btn_primary " + (var_ready ? 'btn_active' : '')}
                                    onClick={(login)}>{t('LOGIN')}</Button>
                            : (!var_processing && var_ssoenabled) ?
                                <Button type='submit' id="btn_login" className="btn_primary btn_active" onClick={() => {
                                    // save email address if remember me enabled
                                    handle_remember_me();
                                    window.location.href = var_sso_url
                                }}>{t('CONTINUE')}</Button>
                                :
                                <Button loading id="btn_login" className="btn_primary btn_active">{t('LOGIN')}</Button>
                        }

                    </Form>
                    {!var_ssoenabled &&
                        <div className="btn_forgotpassword"
                             onClick={() => onClick_forgotpassword()}>{t('Forgot Password')}</div>
                    }

                    {var_login.termsofservice &&
                        <p className="toc">{t('By creating an account you are agreeing to these')} <a
                            href={var_login.termsofservice} target="_blank"
                            rel="noopener noreferrer">{t('Terms and Conditions')}</a></p>
                    }

                    {(var_location_id !== '-' && !var_login.inviteid) &&
                        <Button id="btn_createaccount" className="btn_secondary"
                                onClick={() => onClick_createaccount()}>{t('CREATE AN ACCOUNT')}</Button>
                    }

                    {/***** MODAL: LOGIN *********************************************************************************/}

                    <Modal id="mdl_select_language"
                           dimmer={'inverted'}
                           closeOnEscape={false}
                           closeOnDimmerClick={false}
                           open={var_mdl_select_lang_open}
                           onClose={() => set_mdl_select_lang_open(false)}>
                    </Modal>

                    {/***** END MODAL: LOGIN *****************************************************************************/}


                </div>
    )

}

import { API } from "aws-amplify";
import { useEffect, useState} from "react";
import {Button, Checkbox, Dropdown, Form, Input} from 'semantic-ui-react'
import React from "react";
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import './mdl_add.css';



export default function({ set_mdl_open, populate_function }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_organization, set_organization] = useState([]);
    const [ var_organizationid, set_organizationid] = useState('');
    const [ var_ready, set_ready] = useState(false);
    const [ var_processing, set_processing] = useState(false);

    const [ var_dd_organizations, set_dd_organizations ] = useState([]);


    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_organization.transactionby === undefined){
            const organization = {...var_organization};
            organization['invitingorg_id'] = JSON.parse(sessionStorage.getItem('authentication')).organization_id;
            organization['transactionby'] = JSON.parse(sessionStorage.getItem('authentication')).id;
            organization['transactionorg'] = JSON.parse(sessionStorage.getItem('authentication')).organization_id;
            set_organization(organization);
            populate_dd_organizations();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_organization]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_dd_organizations(){
        try {
            let results = await API_get_dd_organizations();
            set_dd_organizations(results);
        } catch (e) {
            console.log(e);
        }
    }

    async function insert_organization() {
        set_processing(true);
        try {

            await API_post_org_organization();
            set_ready(false);
            set_processing(false);
            set_mdl_open(false);
            populate_function();
        } catch (e) {
            console.log(e);
        }

    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_dd_organizations(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org', '/get-dd-orgavailablenetworks/'+JSON.parse(sessionStorage.getItem('authentication')).organization_id);
    }

    function API_post_org_organization(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org', '/post-org-network/', { body: var_organization });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_organization(e, { value }){
        set_organizationid(value);
        const organization = {...var_organization};
        organization['organization_id'] = value;
        set_organization(organization);
        set_ready(true);
    }

    function onClick_submit(){
        if(var_ready){
            set_processing(true);
            insert_organization();
        }
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{t("Add Organization")}</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
            </div>
            <div className="modal-content">
                <Form>
                    <Form.Select
                        className='organization_id'
                        id='organization_id'
                        name='organization_id'
                        label={t('ORGANIZATION')}
                        search
                        options={var_dd_organizations}
                        value={var_organizationid || ''}
                        onChange={onChange_organization}
                        placeholder={t('Select an organization...')}
                    />
                </Form>
            </div>

            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>{t("CANCEL")}</Button>
                    {!var_processing ?
                        <Button className={"btn_primary " + (var_ready ? ' btn_active' : '')} onClick={() => onClick_submit()}>{t("ADD ORGANIZATION")}</Button>
                        :
                        <Button loading className="btn_primary btn_active">{t("ADD ORGANIZATION")}</Button>
                    }
                </div>
            </div>
        </>
    )
}
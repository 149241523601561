import React, { useEffect, useState } from 'react';
import { Button, Form, Input } from 'semantic-ui-react';
import { API } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import '../../../../../../i18n';

import './tab_details.css';

export default function({ set_mdl_open, var_activetab, package_id, populate_function }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_package, set_package ] = useState({name:'',status:null});
    const [ var_name, set_name ] = useState('');
    const [ var_ready, set_ready ] = useState(false);
    const [ var_processing, set_processing ] = useState(false);

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_activetab === 'DETAILS') {
            load_package();
        }
    },[var_activetab]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function load_package() {
        set_ready(false);
        let reqpackage = (await API_get_package())[0];
        set_package(reqpackage);
        set_name(reqpackage.name);
        set_ready(true);
    }

    async function update_package() {
        set_processing(true);
        try {
            let reqpackage = {
                organization_id: JSON.parse(sessionStorage.getItem('authentication')).organization_id,
                name: var_name.trim(),
                status: var_package.status
            };
            let updated_package = await API_put_package(reqpackage);
            set_package(updated_package[0]);
            set_name(updated_package[0].name);
            set_processing(false);
            populate_function();
        } catch (e) {
            console.log(e);
        }
    }

    async function archive_package() {
        set_processing(true);
        try {
            let reqpackage = {
                organization_id: JSON.parse(sessionStorage.getItem('authentication')).organization_id,
                name: var_package.name,
                status: var_package.status === 'ACTIVE' ? 'ARCHIVE' : 'ACTIVE'
            };
            let updated_package = await API_put_package(reqpackage);
            set_package(updated_package[0]);
            set_name(updated_package[0].name);
            set_processing(false);
            populate_function();
        } catch (e) {
            console.log(e);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_package() {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-requirements', '/get-package/'+package_id);
    }

    function API_put_package(reqpackage) {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.put('org-requirements', '/put-package/'+package_id, { body: reqpackage });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_submit() {
        if (!var_processing && var_name.trim().length > 0) {
            update_package();
        }
    }

    function onClick_archive() {
        if (!var_processing) {
            archive_package();
        }
    }

    // RENDER APP ======================================================================================================

    return (
        <div className='packagesetup_detailscontainer modal-tab'>
            {var_package.status === 'ARCHIVE' &&
            <div className='modal-archive' onClick={() => onClick_archive()}>
                <div className='modal-archive-icon'><img className='btn_icon' src={'/icons/archive 30px (ffffff).svg?ts=' + Date.now()} alt={t('archive icon')}/></div>
                <div className='modal-archive-text'>{t('This package has been archived. Click this bar to make it active again.')}</div>
            </div>
            }

            <div id='tab_details'>
                <Form>
                    <Form.Group>
                        <Form.Field
                            className='package_name'
                            name='package_name'
                            value={var_name}
                            control={Input}
                            onChange={(event) => set_name(event.target.value)}
                            label={t('PACKAGE NAME')}
                            placeholder={t('Package name...')}
                            disabled={var_package.status === 'ARCHIVE'}
                        />
                    </Form.Group>
                </Form>
            </div>
            <div className='modal-footer'>
                {var_package.status === 'ACTIVE' &&
                <div className='modal-footer-archive'>
                    <Button className='btn_tertiary' onClick={() => onClick_archive()}><img className='btn_icon' src={'/icons/archive 30px (4dcbd4).svg?ts=' + Date.now()} alt={t("archive icon")}/>{t('ARCHIVE PACKAGE')}</Button>
                </div>
                }
                <div className='modal-footer-buttons'>
                    <Button className='btn_secondary' onClick={() => set_mdl_open(false)}>{t('CANCEL')}</Button>
                    {var_package.status === 'ACTIVE' &&
                    <Button loading={var_processing} className={'btn_primary' + (var_name.trim().length > 0 ? ' btn_active' : '')} onClick={onClick_submit}>{t('UPDATE PACKAGE')}</Button>
                    }
                </div>
            </div>
        </div>
    )
}
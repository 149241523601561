import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { Button, Modal, Popup } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import '../../../../../../i18n';
import auth from '../../../../../../libs/auth-lib';

import MDL_QUESTION from './mdl_question/mdl_question';
import MDL_CONFIRMATION from '../../../../../../components/cmp_confirmation/cmp_confirmation';
import MDL_PREVIEW from './mdl_preview/mdl_preview';

import './tab_questions.css';

export default function({ set_mdl_open, var_onClose_mdl_update, set_onClose_mdl_update, var_activetab, set_activetab, var_onChange_activetab, set_onChange_activetab,
    var_questionnaire_id, populate_function }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_questions, set_questions ] = useState([]);
    const [ var_deleted_questions, set_deleted_questions ] = useState([]);

    const [ var_mdl_question_open, set_mdl_question_open ] = useState(false);
    const [ var_edit_question, set_edit_question ] = useState(false);
    const [ var_dirty, set_dirty ] = useState(false);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_validation_error, set_validation_error ] = useState(false);
    const [ var_mdl_confirmdiscardchanges_open, set_mdl_confirmdiscardchanges_open ] = useState(false);
    const [ var_mdl_preview_open, set_mdl_preview_open ] = useState(false);
    const [ var_cancelaction, set_cancelaction ] = useState(null);

    const { t } = useTranslation();
    
    const scroll_container = React.useRef(null);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_onClose_mdl_update) {
            onClick_cancel('close');
        }
    }, [var_onClose_mdl_update]);

    useEffect(() => {
        if (var_onChange_activetab) {
            onClick_cancel(var_onChange_activetab);
        }
    }, [var_onChange_activetab]);

    useEffect(() => {
        if (var_activetab === 'QUESTIONS') {
            // reset state variables
            set_questions([]);
            set_deleted_questions([]);
            set_edit_question(null);
            set_dirty(false);
            set_processing(false);
            set_validation_error(false);
            // load questions from db
            populate_questions();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_activetab]);

    //  functions ------------------------------------------------------------------------------------------------


    async function populate_questions(){
        if (var_activetab !== 'QUESTIONS') return; // Don't load data if tab is not visible
        try {
            let results = await API_get_org_questions();
            results.forEach(item => item.questionorder = Number(item.questionorder));
            set_questions(results);
        } catch (e) {
            console.log(e);
        }
    }

    function validate_question_has_path_to_pass(question) {
        if (question.fail_on_yes === 'NO' && !var_questions.some(item => item.dependent_on_question_id === question.id && item.dependent_on_response === 'YES')) return true; // YES is a pass
        if (question.fail_on_no === 'NO' && !var_questions.some(item => item.dependent_on_question_id === question.id && item.dependent_on_response === 'NO')) return true;   // NO is a pass
        let children = var_questions.filter(item => item.dependent_on_question_id === question.id);
        for (let index = 0; index < children.length; index++) {
            if (validate_question_has_path_to_pass(children[index])) return true;           // Child has a path to a pass
        }
        return false;
    }

    function validate_question_has_path_to_fail(question) {
        if (question.fail_on_yes === 'YES' || question.fail_on_no === 'YES') return true;   // YES or NO is a fail
        let children = var_questions.filter(item => item.dependent_on_question_id === question.id);
        for (let index = 0; index < children.length; index++) {
            if (validate_question_has_path_to_fail(children[index])) return true;           // Child has a path to a fail
        }
        return false;
    }

    function validate_visible_to_individual_type(question) {
        if (question.dependent_on_question_id === null) return true;
        let parent_visibility = var_questions.find(item => item.id === question.dependent_on_question_id).visible_to_individual_type;
        return (parent_visibility === 'ALL' || parent_visibility === question.visible_to_individual_type);
    }

    function delete_question(id, questions, deleted_questions) {
        let question = questions.find(item => item.id === id);
        // add id to the deleted list
        if (!question.is_new) {
            deleted_questions.push(id);
        }
        // remove the question
        questions.splice(questions.findIndex(item => item.id === id), 1);
        // now delete all children
        questions.filter(item => item.dependent_on_question_id === id).forEach(item => delete_question(item.id, questions, deleted_questions));
    }

    async function save_questions() {
        try {
            let data = {
                template_id: var_questionnaire_id,
                transactionorg: auth.organization_id,
                deleted: var_deleted_questions,
                inserted: var_questions.filter(item => item.is_new),
                updated: var_questions.filter(item => item.is_updated)
            };
            await API_put_org_questions(data);
            // clear all flags indicating record status
            set_deleted_questions([]);
            let questions = var_questions.map(item => ({...item}));
            questions.forEach(item => {
                item.is_new = false;
                item.is_updated = false;
            });
            set_questions(questions);
            set_dirty(false);
            populate_function();
        } catch (error) {
            console.log(error);
        } finally {
            set_processing(false);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_questions() {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-dailychecks', '/get-org-questions/'+var_questionnaire_id);
    }

    function API_put_org_questions(data) {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.put('org-dailychecks', '/put-org-questions',{ body: data });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_updatequestion(question) {
        if (var_processing) return;
        set_edit_question({ ...question, has_children: var_questions.some(item => item.dependent_on_question_id === question.id) });
        set_mdl_question_open(true);
    }

    function onClick_addquestion(parent_id, parent_response, questionorder) {
        if (var_processing) return;
        if (!parent_id) {
            set_edit_question(null);
        } else {
            set_edit_question({ id: null, dependent_on_question_id: parent_id, dependent_on_response: parent_response, questionorder });
        }
        set_mdl_question_open(true);
    }

    function on_update_question(question, was_deleted) {
        let questions = [...var_questions];
        if (was_deleted) {
            let deleted_questions = [...var_deleted_questions];
            delete_question(var_edit_question.id, questions, deleted_questions);
            set_deleted_questions(deleted_questions);
            // change the questionorder for those below it
            questions.filter(item => item.dependent_on_question_id === var_edit_question.dependent_on_question_id &&
                item.dependent_on_response === var_edit_question.dependent_on_response &&
                item.questionorder > var_edit_question.questionorder).forEach(item => item.questionorder--);
        } else {
            if (var_edit_question === null || var_edit_question.id === null) {
                let new_question = { ...question, is_new: true, id: uuidv4() };
                if (question.dependent_on_question_id === undefined) {
                    new_question.dependent_on_question_id = null;
                    new_question.dependent_on_response = null;
                    new_question.questionorder = var_questions.filter(item => item.dependent_on_question_id === null).length + 1;
                }
                questions.push(new_question);
            } else {
                questions = questions.filter(item => item.id !== var_edit_question.id);
                questions.push({ ...question, is_updated: true });
            }
        }
        set_questions(questions);
        set_dirty(true);
        set_validation_error(false);
        set_edit_question(null);
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
    }

    function on_drop_question(question, parent_id, parent_response, questionorder) {
        let questions = var_questions.map(item => ({ ...item }));
        if (question.dependent_on_question_id === parent_id && question.dependent_on_response === parent_response && question.questionorder > questionorder) {
            // user is just moving item up.  change question orders
            questions.filter(item => item.dependent_on_question_id === parent_id && item.dependent_on_response === parent_response).forEach(item => {
                let original_questionorder = item.questionorder;
                item.questionorder = item.id === question.id
                    ? questionorder
                    : (item.questionorder >= questionorder && item.questionorder < question.questionorder)
                    ? item.questionorder + 1
                    : item.questionorder;
                item.is_updated = !item.is_new && (item.is_updated || item.questionorder !== original_questionorder);
            });
        } else if (question.dependent_on_question_id === parent_id && question.dependent_on_response === parent_response) {
            // user is just moving item down.  change question orders
            questions.filter(item => item.dependent_on_question_id === parent_id && item.dependent_on_response === parent_response).forEach(item => {
                let original_questionorder = item.questionorder;
                item.questionorder = item.id === question.id
                    ? questionorder
                    : (item.questionorder <= questionorder && item.questionorder > question.questionorder)
                    ? item.questionorder - 1
                    : item.questionorder;
                item.is_updated = !item.is_new && (item.is_updated || item.questionorder !== original_questionorder);
            });
        } else {
            // Moving to a different section
            // fix order in old section first
            questions.filter(item => item.dependent_on_question_id === question.dependent_on_question_id && item.dependent_on_response === question.dependent_on_response &&
                item.questionorder > question.questionorder).forEach(item => {
                    item.questionorder--;
                    item.is_updated = !item.is_new;
                });
            // make space in new section
            questions.filter(item => item.dependent_on_question_id === parent_id && item.dependent_on_response === parent_response &&
                item.questionorder >= questionorder).forEach(item => {
                    item.questionorder++;
                    item.is_updated = !item.is_new;
                });
            // now update the item being dragged
            let current_item = questions.find(item => item.id === question.id);
            current_item.dependent_on_question_id = parent_id;
            current_item.dependent_on_response = parent_response;
            current_item.questionorder = questionorder;
            current_item.is_updated = !current_item.is_new;
        }
        set_questions(questions);
        set_dirty(true);
        set_validation_error(false);
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
    }

    function onClick_cancel(action) {
        if (var_activetab === 'QUESTIONS' && var_dirty) {
            // User has unsaved changes, add confirmation
            set_cancelaction(action);
            set_mdl_confirmdiscardchanges_open(true);
        } else {
            if (action === 'close') {
                set_mdl_open(false);
            } else {
                set_activetab(action);
            }
        }
    }

    function onConfirm_discardchanges_yes() {
        if (var_cancelaction === 'close') {
            set_mdl_open(false);
        } else {
            set_activetab(var_cancelaction);
        }
    }

    function onConfirm_discardchanges_no() {
        if (var_cancelaction === 'close') {
            set_onClose_mdl_update(false);
        } else {
            set_onChange_activetab(null);
        }
        set_mdl_confirmdiscardchanges_open(false);
    }

    function onClick_save() {
        if (var_processing || !var_dirty) return;

        set_processing(true);
        // validation check
        for (let index = 0; index < var_questions.length; index++) {
            let question = var_questions[index];
            let valid = (question.dependent_on_question_id === null || validate_question_has_path_to_pass(question)) &&
                validate_question_has_path_to_fail(question) &&
                validate_visible_to_individual_type(question);
            if (!valid) {
                set_validation_error(true);
                set_processing(false);
                return;
            }
        }
        // all good, save now
        save_questions();
    }

    // RENDER APP ======================================================================================================

    return (
        <div className='modal-tab'>
            <div id='tab_questions'>
                <DndProvider backend={HTML5Backend}>
                    <div className='scrolling content' ref={scroll_container}>
                        {var_questions.filter(item => item.dependent_on_question_id === null).sort((a,b) => a.questionorder - b.questionorder).map(item =>
                            <QUESTION question={item} key={item.id} />
                        )}
                    </div>
                </DndProvider>
                <Button className='btn_secondary btn_active' onClick={() => onClick_addquestion(null, null, null)}>{t('ADD QUESTION')}</Button>
            </div>
            {var_validation_error &&
                <div className='message warning questions-validation-error'>
                    <div className='message_icon'><img src={'/icons/warning 60px (d91e18).svg?ts=' + Date.now()} alt={t('warning icon')} /></div>
                    <div className='message_text_wrapper'>
                        <div className='message_text cause'>{t('Please fix all validation errors before saving')}</div>
                    </div>
                </div>
            }
            <div className='modal-footer'>
                <div className='modal-footer-buttons'>
                    <Button className='btn_tertiary' onClick={() => set_mdl_preview_open(true)}>{t('PREVIEW QUESTIONNAIRE')}</Button>
                    <Button className='btn_secondary' onClick={() => onClick_cancel('close')}>{t('CANCEL')}</Button>
                    <Button className={'btn_primary' + (var_dirty ? ' btn_active' : '')} onClick={onClick_save} loading={var_processing}>{t('SAVE')}</Button>
                </div>
            </div>

            {/***** MODAL: QUESTION **********************************************************************************/}

            <Modal id='org-dailycheck_mdl_question'
                   dimmer={'inverted'}
                   open={var_mdl_question_open}
                   onClose={() => set_mdl_question_open(false)}>
                <MDL_QUESTION
                    set_mdl_question_open={set_mdl_question_open}
                    question={var_edit_question}
                    can_fail_on_yes={var_edit_question === null || !var_questions.some(item => item.dependent_on_question_id === var_edit_question.id && item.dependent_on_response === 'YES')}
                    can_fail_on_no={var_edit_question === null || !var_questions.some(item => item.dependent_on_question_id === var_edit_question.id && item.dependent_on_response === 'NO')}
                    on_save={on_update_question}
                    />
            </Modal>

            {/***** END MODAL: QUESTION ******************************************************************************/}

            {/***** MODAL: CONFIRMATION ******************************************************************************/}

            <Modal id='dailycheck_mdl_confirmdiscardchanges'
                   dimmer={'inverted'}
                   open={var_mdl_confirmdiscardchanges_open}
                   onClose={() => set_mdl_confirmdiscardchanges_open(false)}>
                <MDL_CONFIRMATION
                    set_mdl_open={set_mdl_confirmdiscardchanges_open}
                    var_modaltitle={t('Unsaved Changes')}
                    var_message={t('You have unsaved changes, are you sure you want to lose them?')}
                    confirmation_function={onConfirm_discardchanges_yes}
                    cancel_function={onConfirm_discardchanges_no}
                    >
                </MDL_CONFIRMATION>
            </Modal>

            {/***** END MODAL: CONFIRMATION **************************************************************************/}

            {/***** MODAL: PREVIEW ***********************************************************************************/}

            <Modal id='dailycheck_mdl_preview'
                   dimmer={'inverted'}
                   open={var_mdl_preview_open}
                   onClose={() => set_mdl_preview_open(false)}>
                <MDL_PREVIEW
                    var_mdl_open={var_mdl_preview_open}
                    set_mdl_open={set_mdl_preview_open}
                    var_questions={var_questions} />
            </Modal>

            {/***** END MODAL: PREVIEW *******************************************************************************/}


        </div>
    )

    function QUESTION({question}) {
        const [{}, drop] = useDrop(() => ({
            accept: 'question',
            canDrop: () => { return false; },
            hover(item, monitor) {
                const mouse_y = monitor.getClientOffset().y;
                const {top: container_top, bottom: container_bottom, height: container_height} = scroll_container.current.getBoundingClientRect();
                if (mouse_y - container_top < 25 && scroll_container.current.scrollTop > 0) {
                    scroll_container.current.scrollTop--;
                } else if (container_bottom - mouse_y < 25 && scroll_container.current.scrollTop + container_height < scroll_container.current.scrollHeight) {
                    scroll_container.current.scrollTop++;
                }
        }}));

        const [{is_dragging}, drag, preview] = useDrag(() => ({
            type: 'question',
            item: question,
            canDrag: !var_processing,
            collect: monitor => ({
                is_dragging: !!monitor.isDragging(),
            }),
        }));

        const end_with_empty = () => question.dependent_on_question_id !== null &&
            !var_questions.some(item => item.dependent_on_question_id === question.dependent_on_question_id &&
                item.dependent_on_response === question.dependent_on_response && item.questionorder > question.questionorder);

        return (
            <div ref={drop}>
                {question.questionorder === 1 &&
                    <DROPZONE parent_id={question.dependent_on_question_id} parent_response={question.dependent_on_response} questionorder={1} />
                }
                <div className={'dailycheck-question' + (is_dragging ? ' dragging' : '')} ref={preview}>
                    <div className='question-toolbar'>
                        <div className='question-drag-button' ref={drag}>
                            <img className='btn_icon' src={'/icons/drag 30px (000000).svg?ts=' + Date.now()} alt={t('drag icon')} />
                        </div>
                        <div className='dailycheck-question-title'>{t('QUESTION')}</div>
                        <div className='question-validation'>
                            {(question.dependent_on_question_id === null && !validate_question_has_path_to_pass(question))
                                ? <Popup content={t('All top level questions must have a path to a pass')} trigger={<img className='btn_icon' src={'/icons/alert 30px (e0454f).svg?ts=' + Date.now()} alt={t('validation error icon')} />} />
                                : !validate_question_has_path_to_fail(question)
                                ? <Popup content={t('All questions must have a path to a fail')} trigger={<img className='btn_icon' src={'/icons/alert 30px (e0454f).svg?ts=' + Date.now()} alt={t('validation error icon')} />} />
                                : !validate_visible_to_individual_type(question)
                                ? <Popup content={t('The visibility of this question can not contain individual types not included in the parent question')} trigger={<img className='btn_icon' src={'/icons/alert 30px (e0454f).svg?ts=' + Date.now()} alt={t('validation error icon')} />} />
                                : null
                            }
                        </div>
                        <Button className='btn_secondary question-edit-button' onClick={() => onClick_updatequestion(question)}>{t('EDIT')}</Button>
                    </div>
                    <div className='dailycheck-question-wrapper'>
                        <div className='dailycheck-question-text'>{question.question}</div>
                        <div className='question-option-container'>
                            <div className='question-option-text'>{t('If the answer is yes')}:</div>
                            <div className='question-option-values'>
                                {question.fail_on_yes === 'YES' ?
                                    t('FAIL (quarantine for ') + question.yes_quarantine_days + t(` day${question.yes_quarantine_days === 1 ? '' : 's'})`)
                                    :
                                    var_questions.some(item => item.dependent_on_question_id === question.id && item.dependent_on_response === 'YES')
                                    ?
                                    <>
                                        <div className='question-followup-text'>{t('Display follow-up question(s)')}</div>
                                        {var_questions.filter(item => item.dependent_on_question_id === question.id && item.dependent_on_response === 'YES').sort((a,b) => a.questionorder - b.questionorder).map(item =>
                                            <QUESTION question={item} key={item.id} />)}
                                    </>
                                    :
                                    question.dependent_on_question_id !== null && var_questions.find(item => item.id === question.dependent_on_question_id).dependent_on_question_id !== null
                                    ?
                                    t('No action')
                                    :
                                    <DROPZONE parent_id={question.id} parent_response='YES' questionorder={1} is_empty_container={true} />
                                }
                            </div>
                        </div>
                        <div className='question-option-container'>
                            <div className='question-option-text'>{t('If the answer is no')}:</div>
                            <div className='question-option-values'>
                                {question.fail_on_no === 'YES' ?
                                    t('FAIL (quarantine for ') + question.no_quarantine_days + t(` day${question.no_quarantine_days === 1 ? '' : 's'})`)
                                    :
                                    var_questions.some(item => item.dependent_on_question_id === question.id && item.dependent_on_response === 'NO')
                                    ?
                                    <>
                                        <div className='question-followup-text'>{t('Display follow-up question(s)')}</div>
                                        {var_questions.filter(item => item.dependent_on_question_id === question.id && item.dependent_on_response === 'NO').sort((a,b) => a.questionorder - b.questionorder).map(item =>
                                            <QUESTION question={item} key={item.id} />)}
                                    </>
                                    :
                                    question.dependent_on_question_id !== null && var_questions.find(item => item.id === question.dependent_on_question_id).dependent_on_question_id !== null
                                    ?
                                    t('No action')
                                    :
                                    <DROPZONE parent_id={question.id} parent_response='NO' questionorder={1} is_empty_container={true} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <DROPZONE parent_id={question.dependent_on_question_id} parent_response={question.dependent_on_response} questionorder={question.questionorder + 1} is_empty_container={end_with_empty()} />
            </div>
        );
    }

    function DROPZONE({parent_id, parent_response, questionorder, is_empty_container}) {
        const [{ is_over, can_drop }, drop] = useDrop(() => ({
            accept: 'question',
            drop(item) {
                on_drop_question(item, parent_id, parent_response, questionorder);
            },
            canDrop: (item) => {
                if (item.id === parent_id) return false;                                                                    // Can not make question a child of itself
                if (item.dependent_on_question_id === parent_id &&
                    item.dependent_on_response === parent_response &&
                    (item.questionorder === questionorder || item.questionorder === questionorder - 1)) return false;       // Can not move re-order to same position
                let check_id = parent_id;
                while (check_id !== null) {
                    if (item.id === check_id) return false;
                    check_id = var_questions.find(item => item.id === check_id).dependent_on_question_id;                   // Can not make question a child of one of its children
                }
                let depth = 1; // dragged question
                if (var_questions.some(child => child.dependent_on_question_id === item.id)) {
                    depth++; // add one for child of dragged question
                    if (var_questions.some(child => child.dependent_on_question_id === item.id && var_questions.some(grandchild => grandchild.dependent_on_question_id === child.id))) {
                        depth++; // add one for grandchild of dragged question
                    }
                }
                if (parent_id !== null) {
                    depth++; // add one for parent
                    if (var_questions.find(parent => parent.id === parent_id).dependent_on_question_id !== null) {
                        depth++; // add one for grandparent
                    }
                }
                if (depth > 3) return false;                                                                                // Can not have a depth greater than 3
                return true;
            },
            collect: (monitor) => ({
                is_over: !!monitor.isOver(),
                can_drop: !!monitor.canDrop()
            })
        }),[]);

        return (
            <div className={'question-dropzone' + (questionorder === 1 && !is_empty_container ? ' is-first' : '') + (is_empty_container ? ' empty' : '') + (is_over && can_drop ? ' over' : '')} ref={drop}>
                {is_empty_container ?
                    <>
                        {t('Drag and drop a question here or')}
                        <a href='#' onClick={() => {onClick_addquestion(parent_id, parent_response, questionorder); return false;}} className='question-add-question-link'>{t('add a new question')}</a>
                    </>
                    : <>&nbsp;</>
                }
            </div>
        );
    }
};
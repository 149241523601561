import { Button, Modal, Table } from 'semantic-ui-react'
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import React from "react";

import CMP_TABLECONTROLS, { filter_helper } from "../../../../components/cmp_tablecontrols/cmp_tablecontrols";
import MDL_BULKINVITE from "./mdl_bulkinvite/mdl_bulkinvite";
import MDL_INDV from "../../../org/individuals/mdl_indv/mdl_indv";
import '../../../../i18n';

import './crd_holders.css';



export default function(appProps){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();
    const [ var_holders, set_holders ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(100);
    const [ var_offset, set_offset] = useState(0);
    const [ var_search, set_search ] = useState('');
    const [ var_sortby, set_sortby ] = useState('individual');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));
    const [ var_target_id, set_target_id ] = useState('');

    const [ var_mdl_bulkinvite_open, set_mdl_bulkinvite_open ] = useState(false);

    const [ var_mdl_indv_open, set_mdl_indv_open ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        set_filter(filter_helper.initialize(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(()=>{
        if (var_filter.load){
            populate_holders(null,0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_filter]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_holders(limit,offset,sortby,sortorder,search,filter){

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        if(search) {
            if(search === '-') {
                search = '';
            }
            set_search(search);
        }else{
            search=var_search
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_holders(limit,offset,sortby,sortorder,search,filter);
            set_holders(results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername,sortorder){
        try {
            return await API_get_holders(filter_helper.maxFilterItems, 0, filtername, var_search,sortorder,[], filtername);
        } catch (e) {
            console.log(e);
        }
    }

    async function download_holders(limit, offset,){
       try {
           return await API_get_holders(limit, offset, var_sortby, var_sortorder, var_search, var_filter);
       }  catch (e) {
            console.log(e);
            throw e;
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_holders(limit,offset,sortby,sortorder,search,filter, filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-credentials', '/get-org-holders/'+JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id,
                        {
                            queryStringParameters: {
                                limit: limit,
                                offset: offset,
                                sortby: sortby,
                                sortorder: sortorder,
                                search: search,
                                filtername: filtername
                            },
                            body: filter
                        }
                    );
                }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_holders('',0,sortby,sortorder)
    }

    function onOpen_mdl_bulkinvite(){
        set_mdl_bulkinvite_open(true);
    }

    function onClick_individual(var_target_id){
        set_target_id(var_target_id);
        set_mdl_indv_open(true);
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="content-card" id="crd_org_individuals_individuals">
                <div className="content-card-header">
                    <div className="content-card-header-title">{t('Holders')}</div>
                    <Button className="btn_tertiary" onClick={() => onOpen_mdl_bulkinvite()}><img className="btn_icon" src={"/icons/upload 30px (4dcbd4).svg?ts=" + Date.now()} alt={t('upload icon')}></img>{t('BULK INVITE HOLDERS')}</Button>
                </div>
                <div className="content-card-gridcontent">
                    <div className="content-filter">
                        <div className="content-filter-item item-pagination">
                        <CMP_TABLECONTROLS
                            var_offset={var_offset} var_limit={var_limit} var_ready={var_ready} var_loading={var_loading}
                            total_rows={var_holders && var_holders.length > 0 ? var_holders[0]['totalrows'] : 0} populatefunction={populate_holders}
                            downloadname='Organization Holders' downloadfunction={download_holders}
                            var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                            table_config={[
                                {name: 'subject_id', datatype: 'text', labelKey: 'HOLDER ID', download: true, filter: true},
                                {name: 'individual', datatype: 'text', labelKey: 'NAME', download: true, filter: true},
                                {name: 'email', datatype: 'text', labelKey: 'EMAIL', download: true, filter: true},
                                {name: 'city', datatype: 'text', labelKey: 'CITY', download: true, filter: true},
                                {name: 'invitestatus', datatype: 'text', labelKey: 'INVITESTATUS', download: true, filter: true}
                            ]}
                            />
                        </div>
                    </div>
                    <div className="tablewrapper">
                        <Table sortable compact className={(var_loading ? " tbl_loading" : "")}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell sorted={var_sortby === 'subject_id' ? var_sortorder : null} onClick={()=>onClick_sort('subject_id')}>{t('HOLDER ID')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'individual' ? var_sortorder : null} onClick={()=>onClick_sort('individual')}>{t('NAME')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'email' ? var_sortorder : null} onClick={()=>onClick_sort('email')}>{t('EMAIL')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'city' ? var_sortorder : null} onClick={()=>onClick_sort('city')}>{t('CITY')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'invitestatus' ? var_sortorder : null} onClick={()=>onClick_sort('invitestatus')}>{t('INVITE STATUS')}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {var_ready && var_holders[0]['totalrows'] !== 0 &&
                            <Table.Body>
                                {var_holders.map((item, i) =>
                                    <Table.Row key={i} onClick={()=>onClick_individual(item.id)}>
                                        <Table.Cell>{item.subject_id}</Table.Cell>
                                        <Table.Cell>{item.individual}</Table.Cell>
                                        <Table.Cell>{item.email}</Table.Cell>
                                        <Table.Cell>{item.city}</Table.Cell>
                                        <Table.Cell>{item.invitestatus}</Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                            }
                        </Table>
                        {var_ready && var_holders[0]['totalrows'] === 0 &&
                        <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t('alert icon')}/>{t('there are no holders to display')}</div>
                        }
                    </div>
                    <div className="content-filter">
                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                var_offset={var_offset} var_limit={var_limit} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_holders && var_holders.length > 0 ? var_holders[0]['totalrows'] : 0} populatefunction={populate_holders}
                            />
                        </div>
                    </div>
                </div>


                {/***** MODAL: BULK INVITE ***************************************************************************/}

                <Modal id="org-credentials_mdl_bulkinvite"
                       dimmer={'inverted'}
                       open={var_mdl_bulkinvite_open}
                       onClose={() => set_mdl_bulkinvite_open(false)}>
                    <MDL_BULKINVITE
                        set_mdl_open={set_mdl_bulkinvite_open}
                        populate_function={populate_holders}
                        var_location={''}
                    />
                </Modal>

                {/***** END MODAL: BULK INVITE ***********************************************************************/}



                {/***** MODAL: INDIVIDUAL ****************************************************************************/}

                <Modal id="mdl_org_individuals_indv"
                       dimmer={'inverted'}
                       open={var_mdl_indv_open}
                       onClose={() => set_mdl_indv_open(false)}>
                    <MDL_INDV
                        set_mdl_open={set_mdl_indv_open}
                        var_individual_id={var_target_id}
                        populate_function={populate_holders}>
                    </MDL_INDV>
                </Modal>

                {/***** END MODAL: INDIVIDUAL ************************************************************************/}


            </div>
        </>
    )

}

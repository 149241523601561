/* eslint-disable react/jsx-pascal-case */
import {Button, Modal, Popup, Table} from 'semantic-ui-react';
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';
import datelib from '../../../../../libs/date-lib';

import CMP_TABLECONTROLS, { filter_helper } from "../../../../../components/cmp_tablecontrols/cmp_tablecontrols";
import MDL_manageroverride from "../mdl_manageroverride/mdl_manageroverride";

import './mdl_indv_dailychecks.css';



export default function({var_individual, var_individual_id, set_mdl_indv_dailychecks_open, populate_dailychecks}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_indv_dailychecks, set_indv_dailychecks] = useState({});
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(10);
    const [ var_offset, set_offset] = useState(0);
    const [ var_search, set_search ] = useState('');
    const [ var_sortby, set_sortby ] = useState('issuedepoch');
    const [ var_sortorder, set_sortorder ] = useState('descending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));

    const [ var_dailycheck_id, set_dailycheck_id ] = useState('');
    const [ var_mdl_manageroverride_open, set_mdl_manageroverride_open ] = useState(false);



    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        set_filter(filter_helper.initialize(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(()=>{
        if (var_filter.load){
            populate_indv_dailychecks(null,0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_filter])

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_indv_dailychecks(limit,offset,sortby,sortorder,search,filter){

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        if(search) {
            if(search === '-') {
                search = '';
            }
            set_search(search);
        }else{
            search=var_search
        }

        filter = filter ? filter : var_filter;

        try {
            let indv_dailychecks = await API_get_dailychecks(limit,offset,sortby,sortorder,search,filter);
            set_indv_dailychecks(indv_dailychecks);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);

    }

    async function populate_filters(filtername,sortorder){
        try {
        return await API_get_dailychecks(filter_helper.maxFilterItems,0,filtername,var_search,sortorder,{},filtername);
        } catch (e) {
        console.log(e);
        }
    }

    async function download_indv_dailychecks(limit, offset){
        try {
        return await API_get_dailychecks(limit,offset,var_sortby,var_sortorder,var_search,var_filter);
        } catch (e){
        console.log(e);
        throw e;
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_dailychecks(limit,offset,sortby,sortorder,search,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-dailychecks',
            '/get-indv-dailychecks/'+JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id+'/'+var_individual_id,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    search: search,
                    tz: datelib.timezone,
                    filtername: filtername
                },
                body: filter
                 }
                  );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_indv_dailychecks('',0,sortby,sortorder)
    }

    function onOpen_mdl_manageroverride(var_id){
        set_dailycheck_id(var_id);
        set_mdl_manageroverride_open(true);
    }

    function onClick_mdl_manageroverride_close(){
        populate_dailychecks();
        set_mdl_indv_dailychecks_open(false)
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">{t("Daily Check History")} - {var_individual}</div>
            <div className="modal-content">
                <div className="content-filter">
                    <div className="content-filter-item item-pagination">
                        <CMP_TABLECONTROLS
                            var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                            total_rows={var_indv_dailychecks && var_indv_dailychecks.length > 0 ? var_indv_dailychecks[0]['totalrows'] : 0} populatefunction={populate_indv_dailychecks}
                            downloadname='Daily Checks History' downloadfunction={download_indv_dailychecks}
                            var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                            table_config={[
                                {name: 'status', datatype: 'text', labelKey: 'STATUS', download: true, filter: true},
                                {name: 'issued', datatype: 'text', labelKey: 'ISSUED', download: true, filter: false},
                                {name: 'issuedepoch', datatype: 'date', labelKey: 'ISSUED', download: false, filter: true},
                                {name: 'name', datatype: 'text', labelKey: 'TEMPLATE', download: true, filter: true},
                                {name: 'location_name', datatype: 'text', labelKey: 'LOC/PROJ', download: true, filter: true},
                                {name: 'mgr_ovrd', datatype: 'text', labelKey: 'OVERRIDE', download: true, filter: true},
                                {name: 'mgr', datatype: 'text', labelKey: 'OVERRIDE MANAGER', download: true, filter: true}
                            ]}
                        />
                    </div>
                </div>
                <div className="tablewrapper">
                    {var_ready &&
                    <Table sortable compact className={(var_loading ? " tbl_loading" : "")}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell className="td_icon_center" sorted={var_sortby === 'status' ? var_sortorder : null} onClick={() => onClick_sort('status')}>{t("STATUS")}</Table.HeaderCell>
                                <Table.HeaderCell sorted={var_sortby === 'issuedepoch' ? var_sortorder : null} onClick={() => onClick_sort('issuedepoch')}>{t("ISSUED")}</Table.HeaderCell>
                                <Table.HeaderCell sorted={var_sortby === 'name' ? var_sortorder : null} onClick={() => onClick_sort('name')}>{t("TEMPLATE")}</Table.HeaderCell>
                                <Table.HeaderCell sorted={var_sortby === 'location_name' ? var_sortorder : null} onClick={() => onClick_sort('location_name')}>{t("LOC/PROJ")}</Table.HeaderCell>
                                <Table.HeaderCell sorted={var_sortby === 'mgr' ? var_sortorder : null} onClick={() => onClick_sort('mgr')}>{t("MANAGER OVERRIDE")}</Table.HeaderCell>
                                <Table.HeaderCell className="td_icon_center">{t("OVRD")}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {var_indv_dailychecks.map((item, i) =>
                                <Table.Row key={i}>
                                    <Table.Cell className="td_icon_center">
                                        {item.status === 'PASS' &&
                                        <img src={"/icons/checkmark 60px (549E17).svg?ts=" + Date.now()} alt={t("pass daily check icon")}></img>
                                        }
                                        {item.status === 'FAIL' &&
                                        <img src={"/icons/x 60px (DB2430).svg?ts=" + Date.now()} alt={t("fail daily check icon")}></img>
                                        }
                                    </Table.Cell>
                                    <Table.Cell>{datelib.datetime_localized_format(item.issued)}</Table.Cell>
                                    <Table.Cell>{item.name}</Table.Cell>
                                    <Table.Cell>{item.location_name}</Table.Cell>
                                    <Table.Cell>{item.mgr && <Popup trigger={<img className="td_icon_incell" src={"/icons/chat 60px (bcbebe).svg?ts=" + Date.now()} alt="pass icon"></img>}
                                                                    flowing
                                                                    hoverable
                                                                    basic>
                                        <div className="pop_admin">
                                            {item.mgr_note}
                                        </div>
                                    </Popup>}{item.mgr}</Table.Cell>
                                    <Table.Cell className="td_icon_center" >{item.status === 'FAIL'
                                                        ? <img onClick={() => onOpen_mdl_manageroverride(item.id)} src={"/icons/reset 60px (bcbebe).svg?ts=" + Date.now()} alt={t("reset icon")}></img>
                                                        : <div className="td_override_spacer"></div>}</Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                    }
                </div>
                <div className="content-filter">
                    <div className="content-filter-item item-pagination">
                        <CMP_TABLECONTROLS
                            var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                            total_rows={var_indv_dailychecks && var_indv_dailychecks.length > 0 ? var_indv_dailychecks[0]['totalrows'] : 0} populatefunction={populate_indv_dailychecks}
                        />
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_primary btn_active" onClick={() => onClick_mdl_manageroverride_close()}>{t("CLOSE")}</Button>
                </div>
            </div>


            {/***** MODAL: MANAGER OVERRIDE **********************************************************************/}

            <Modal id="mdl_manageroverride"
                   dimmer={'inverted'}
                   open={var_mdl_manageroverride_open}
                   onClose={() => set_mdl_manageroverride_open(false)}>
                <MDL_manageroverride
                    var_dailycheck_id={var_dailycheck_id}
                    set_mdl_manageroverride_open={set_mdl_manageroverride_open}
                    populate_indv_dailychecks={populate_indv_dailychecks}>
                </MDL_manageroverride>
            </Modal>

            {/***** END MODAL: MANAGER OVERRIDE ******************************************************************/}

        </>
    )
}

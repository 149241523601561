import React, { useState, useEffect } from "react";

import TAB_DETAILS from "./tab_details/tab_details";
import TAB_QUESTIONS from "./tab_questions/tab_questions";
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import './mdl_update.css';



export default function({ set_mdl_open, var_onClose_mdl_update, set_onClose_mdl_update, var_target_id, populate_function }){

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_activetab, set_activetab] = useState('DETAILS');
    const [ var_onchange_tab, set_onchange_tab ] = useState(null);
    const { t } = useTranslation();


    //  variable listeners ---------------------------------------------------------------------------------------------

    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_tab(var_tab) {
        if (var_tab !== var_activetab) {
            set_onchange_tab(var_tab);
        }
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{t('Update Questionnaire')}</div>
                <div className="modal-header-tabs">
                    <div className={"modal-header-tabs-item"  + (var_activetab === 'DETAILS' ? ' active' : '')} onClick={() => onClick_tab(`DETAILS`)}>
                    {t('Details')}
                    </div>
                    <div className={"modal-header-tabs-item"  + (var_activetab === 'QUESTIONS' ? ' active' : '')} onClick={() => onClick_tab(`QUESTIONS`)}>
                    {t('Questions')}
                    </div>
                </div>
                <div className="modal-header-close" onClick={() => set_onClose_mdl_update(true)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
            </div>
            <div className="modal-tabs">
                <div className={(var_activetab !== 'DETAILS' ? 'modal-tab-hide' : '')}>
                    <TAB_DETAILS
                        var_activetab={var_activetab}
                        var_questionnaire_id={var_target_id}
                        populate_function={populate_function}
                        set_mdl_open={set_mdl_open}>
                    </TAB_DETAILS>
                </div>
                <div className={(var_activetab !== 'QUESTIONS' ? 'modal-tab-hide' : '')}>
                    <TAB_QUESTIONS
                        set_mdl_open={set_mdl_open}
                        var_onClose_mdl_update={var_onClose_mdl_update}
                        set_onClose_mdl_update={set_onClose_mdl_update}
                        var_activetab={var_activetab}
                        set_activetab={set_activetab}
                        var_onChange_activetab={var_onchange_tab}
                        set_onChange_activetab={set_onchange_tab}
                        var_questionnaire_id={var_target_id}
                        populate_function={populate_function}>
                    </TAB_QUESTIONS>
                </div>
            </div>

        </>
    )
}

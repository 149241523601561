import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Button, Form } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import '../../../../../../i18n';

import './mdl_add.css';



export default function({ set_mdl_open, populate_function, var_item }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_assignment, set_assignment ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_buttontext, set_buttontext ] = useState('ADD LOCATION/PROJECT');

    const [ var_dd_locations, set_dd_locations ] = useState([]);
    const [ var_locationid, set_locationid ] = useState('');


    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_assignment.transactionby === undefined){
            const assignment = {...var_assignment};
            assignment['invitingorg_id'] = JSON.parse(sessionStorage.getItem('authentication')).organization_id;
            assignment['organization_id'] = var_item.organization_id;
            assignment['transactionby'] = JSON.parse(sessionStorage.getItem('authentication')).id;
            assignment['transactionorg'] = JSON.parse(sessionStorage.getItem('authentication')).organization_id;
            set_assignment(assignment);
            populate_dd_locations();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_assignment]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_dd_locations(){
        try {
            let results = await API_get_dd_locations();
            set_dd_locations(results);
        } catch (e) {
            console.log(e);
        }
    }

    async function insert_assignment() {
        set_processing(true);
        try {
            await API_post_org_assignment();
            set_ready(false);
            set_processing(false);
            set_mdl_open(false);
            populate_function();
        } catch (e) {
            console.log(e);
        }

    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_dd_locations(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org', '/get-dd-org-networklocations/'+var_item.organization_id+'/'+JSON.parse(sessionStorage.getItem('authentication')).organization_id);
    }

    function API_post_org_assignment(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org', '/post-org-networkassignment', { body: var_assignment });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_location(e, { value }){
        set_locationid(value);
        set_buttontext('ADD LOCATION/PROJECT')
        const assignment = {...var_assignment};
        assignment['type'] = 'LOCATION';
        assignment['location_id'] = value;
        set_assignment(assignment);
        set_ready(true);
    }

    function onClick_submit(){
        if(var_ready){
            set_processing(true);
            insert_assignment();
        }
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{t("Add Assignment")}</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
            </div>
            <div className="modal-content">
                <Form>
                    <Form.Select
                        className='location_id'
                        id='location_id'
                        name='location_id'
                        label={t('LOCATION/PROJECT')}
                        search
                        options={var_dd_locations}
                        value={var_locationid || ''}
                        onChange={onChange_location}
                        placeholder={t('Select a location/project...')}
                    />
                </Form>
            </div>

            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>{t("CANCEL")}</Button>
                    {!var_processing ?
                        <Button className={"btn_primary " + (var_ready ? ' btn_active' : '')} onClick={() => onClick_submit()}>{var_buttontext}</Button>
                        :
                        <Button loading className="btn_primary btn_active">{t("ADD ASSIGNMENT")}</Button>
                    }
                </div>
            </div>
        </>
    )
}
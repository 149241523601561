import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { Button, Form, Dropdown } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';
import auth from '../../../../../libs/auth-lib';
import datelib from '../../../../../libs/date-lib';

import './mdl_add_requirement.css';

export default function({ var_mdl_open, set_mdl_open, requirement_name, targetentity_id, requirement_id, populate_function }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_dd_individuals, set_dd_individuals ] = useState([]);
    const [ var_processing, set_processing] = useState(false);
    const [ var_selected_individual_ids, set_selected_individual_ids ] = useState([]);


    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_mdl_open) {          
            populate_dd_loc_indvs_requirement();
        }
    }, [var_mdl_open]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_dd_loc_indvs_requirement() {
        try {
            let results = await API_get_dd_loc_indvs_requirement();
            set_dd_individuals(results);
        } catch (e) {
            console.log(e);
        }
    }

    async function insert_individuals() {
        set_processing(true);
        try {
            for (let individual_id of var_selected_individual_ids) {
                let data = {
                    requirement_id: requirement_id,
                    targetentity: 'LOCATION',
                    targetentity_id: targetentity_id,
                    individual_id: individual_id,
                    organization_id: auth.actingorganization_id,
                    transactionby: auth.id,
                    transactionorg: auth.actingorganization_id               
                };
                await API_post_indv_requirement_assignment(data);
                await API_post_indv_requirement(data);
            }

            populate_function();
            set_mdl_open(false);
        } catch (e) {
            console.log(e);
        }

        set_processing(false);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_dd_loc_indvs_requirement() {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-locations', '/get-dd-loc-indvs-requirement/'+targetentity_id+'/'+requirement_id);                                       
    }

    function API_post_indv_requirement_assignment(data){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('indv-credentials', '/post-indv-requirement-assignment', { body: data});
    }

    function API_post_indv_requirement(data){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('indv-credentials', '/post-indv-requirement', 
        {
            queryStringParameters: {
                tz : datelib.timezone
            },
            body: data
        });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_individuals(e, { value }) {
        set_selected_individual_ids(value);
    }

    function onClick_submit() {
        if (var_selected_individual_ids.length > 0) {
            insert_individuals();
        }
    }
 

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{t('Add Requirement')} - {requirement_name}</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
            </div>

            <div className="modal-content">
                <div>
                    {`${t('This allows you to add')} ${requirement_name} ${t('to all of the individuals selected from the list below')}`}
                </div>
                <Form>
                    <Form.Select
                        className='individual_ids'
                        name='individual_ids'
                        label={t('INDIVIDUALS TO ADD')}
                        options={var_dd_individuals}
                        value={var_selected_individual_ids}
                        onChange={onChange_individuals}
                        placeholder={t('Select an individual...')}
                        fluid
                        multiple
                        selection
                        search
                    />
                </Form>
            </div>

            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>{t('CANCEL')}</Button>
                    <Button loading={var_processing} className={'btn_primary' + (var_selected_individual_ids.length > 0 ? ' btn_active' : '')} onClick={onClick_submit}>
                        {t('ADD REQUIREMENT')}
                    </Button>
                </div>
            </div>
        </>
    )
}
import { useEffect } from "react";
import React from "react";
import { Table } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';
import datelib from '../../../../../libs/date-lib';

import './crd_onsite.css';


export default function({var_onsite}){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <>
            <section className="profile_section">

            <div className="profile_section_header">{t('Onsite')}</div>

                <div className="profile_section_body">
                    <div className="tablewrapper remove_table_pointer_event">
                        <Table unstackable compact>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell className="td_loc_icon_center" textAlign='center'>{t('Status')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('LAST ENTRY')}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {var_onsite.map((item, i) =>
                                    <Table.Row key={i}>
                                        <Table.Cell className="td_loc_icon_center" textAlign='center'>
                                            <div className="profile_onsite_status">
                                                {item.onsite === 'YES' &&
                                                <img src={"/icons/checkmark 30px (69b536).svg?ts=" + Date.now()} alt={t("active icon")}/>
                                                }
                                                {item.onsite === 'NO' &&
                                                <img src={"/icons/x 30px (e0454f).svg?ts=" + Date.now()} alt={t("inactive icon")}/>
                                                }
                                                {item.onsite === 'NONE' &&
                                                <img src={"/icons/circle 30px (bcbebe).svg?ts=" + Date.now()} alt={t("no result icon")}/>
                                                }
                                                {item.onsite}
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>{datelib.datetime_localized_format(item.onsitelastdone)}</Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </section>
        </>
    )

}
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from 'semantic-ui-react';
import Moment from "moment";
import datelib from '../../../../libs/date-lib';

import { useTranslation } from 'react-i18next';
import '../../../../i18n';

import CMP_TABLECONTROLS, { filter_helper } from '../../../../components/cmp_tablecontrols/cmp_tablecontrols';
import MDL_HISTORY from "../../../../components/cmp_history/cmp_history";
import MDL_ADD from "./mdl_add/mdl_add";
import MDL_UPDATE from "./mdl_update/mdl_update";
import MDL_DOCVIEWER from "../../../../components/cmp_docviewer/cmp_docviewer";

import './crd_documents.css';



export default function(appProps){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();
    const [ var_documents, set_documents ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(100);
    const [ var_offset, set_offset] = useState(0);
    const [ var_archive, set_archive ] = useState('NO');
    const [ var_sortby, set_sortby ] = useState('name');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));

    const [ var_mdl_history_open, set_mdl_history_open ] = useState(false);
    const [ var_modaltitle, set_modaltitle ] = useState('');
    const [ var_target_id, set_target_id ] = useState('');
    const [ var_ack_req, set_ack_req ] = useState('');

    const [ var_mdl_add_open, set_mdl_add_open ] = useState(false);
    const [ var_mdl_update_open, set_mdl_update_open ] = useState(false);
    const [ var_mdl_docviewer_open, set_mdl_docviewer_open ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_archive !== undefined) {
            set_filter(filter_helper.initialize(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_archive]);

    useEffect(()=>{
        if(var_filter.load) {
        populate_documents(null, 0);
        }
       // eslint-disable-next-line react-hooks/exhaustive-deps
       },[var_filter]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_documents(limit,offset,sortby,sortorder,archive,filter){

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        if(archive) {
            set_archive(archive);
        }else{
            archive=var_archive
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_org_documents(limit,offset,sortby,sortorder,archive,filter);
            set_documents(results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername,sortorder){
        try {
            return await API_get_org_documents(filter_helper.maxFilterItems, 0, filtername, sortorder, var_archive,{}, filtername);
        } catch (e) {
            console.log(e);
        }
    }

    async function download_documents(limit,offset){
        try {
            return await API_get_org_documents(limit, offset, var_sortby, var_sortorder, var_archive, var_filter);
        } catch(e) {
            console.log(e);
            throw e;
        }
    }
    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_documents(limit,offset,sortby,sortorder,archive,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-documents',
                        '/get-org-documents/'+JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id,
                        {
                            queryStringParameters: {
                            limit: limit,
                            offset: offset,
                            sortby: sortby,
                            sortorder: sortorder,
                            archive: archive,
                            tz: datelib.timezone,
                            filtername: filtername
                            },
                            body: filter
                        }
                        );
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_documents('',0,sortby,sortorder)
    }

    function onClick_add(){
        set_mdl_add_open(true)
    }

    function onClick_update(var_target_id,var_ack_req){
        set_target_id(var_target_id);
        set_ack_req(var_ack_req);
        set_mdl_update_open(true)
    }

    function onOpen_mdl_history(target_id, target_name) {
        set_modaltitle('Credential History - '+target_name);
        set_target_id(target_id);
        set_mdl_history_open(true)
    }

    function onClick_opendocument(var_target_id){
        set_target_id(var_target_id);
        set_mdl_docviewer_open(true);
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="content-card" id="crd_org_individuals_individuals">
                <div className="content-card-header">

                    {var_archive === 'NO' ?
                        <div className="content-card-header-title">{t('All Active Documents')}</div>
                        :
                        <div className="content-card-header-title">{t('All Archived Documents')}</div>
                    }

                    <Button className="btn_tertiary" onClick={() => onClick_add()}><img className="btn_icon" src={"/icons/documents 30px (4dcbd4).svg?ts=" + Date.now()} alt={t('document icon')}></img>{t('ADD DOCUMENT')}</Button>
                </div>
                <div className="content-card-gridcontent">
                    <div className="content-filter">

                        <div className="content-filter-archive">
                            {var_archive === 'NO' ?
                                <Button className="btn_tertiary" onClick={() => set_archive('YES')}><img className="btn_icon" src={"/icons/archive 30px (4dcbd4).svg?ts=" + Date.now()} alt={t('archive icon')}/>{t('VIEW ARCHIVED DOCUMENTS')}</Button>
                                :
                                <Button className="btn_tertiary" onClick={() => set_archive('NO')}><img className="btn_icon" src={"/icons/checkmark 30px (4dcbd4).svg?ts=" + Date.now()} alt={t('checkmark icon')}/>{t('VIEW ACTIVE DOCUMENTS')}</Button>
                            }
                        </div>

                        <div className="content-filter-item item-pagination">
                        <CMP_TABLECONTROLS
                                var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_documents && var_documents.length > 0 ? var_documents[0]['totalrows'] : 0} populatefunction={populate_documents}
                                downloadname='Organization Documents' downloadfunction={download_documents}
                                var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                                table_config={[
                                    {name: 'name', datatype: 'text', labelKey: 'NAME', download: true, filter: true},
                                    {name: 'issued', datatype: 'text', labelKey: 'ISSUED', download: true, filter: false},
                                    {name: 'epochissued', datatype: 'date', labelKey: 'ISSUED', download: false, filter: true},
                                    {name: 'ack_req', datatype: 'text', labelKey: 'ACK REQ', download: true, filter: true},
                                    {name: 'acknowledgements', datatype: 'int', labelKey: 'ACKS', download: true, filter: true},
                                    {name: 'locations', datatype: 'int', labelKey: 'LOC/PROJ', download: true, filter: true}
                                ]}
                            />
                        </div>
                    </div>
                    <div className="tablewrapper">
                        <Table sortable compact className={(var_loading ? " tbl_loading" : "")}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell sorted={var_sortby === 'name' ? var_sortorder : null} onClick={()=>onClick_sort('name')}>{t('NAME')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'epochissued' ? var_sortorder : null} onClick={()=>onClick_sort('epochissued')}>{t('ISSUED')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'ack_req' ? var_sortorder : null} onClick={()=>onClick_sort('ack_req')}>{t('ACK REQ')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'acknowledgements' ? var_sortorder : null} onClick={()=>onClick_sort('acknowledgements')}>{t('ACKS')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'locations' ? var_sortorder : null} onClick={()=>onClick_sort('locations')}>{t('LOC/PROJ')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_attachment" sorted={var_sortby === 'name' ? var_sortorder : null} onClick={() => onClick_sort("NAME")}><img src={"/icons/paperclip 30px (c9caca).svg?ts=" + Date.now()} alt="paperclip icon"/></Table.HeaderCell>
                                    <Table.HeaderCell className="td_icon_center" >{t('HSTRY')}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {var_ready && var_documents[0]['totalrows'] !== 0 &&
                            <Table.Body>
                                {var_documents.map((item, i) =>
                                    <Table.Row key={i} >
                                        <Table.Cell onClick={() => onClick_update(item.id,item.ack_req)}>{item.name}</Table.Cell>
                                        <Table.Cell onClick={() => onClick_update(item.id,item.ack_req)}>{item.epochissued !== null && item.epochissued !== undefined && item.epochissued !== "" ? Moment.unix(item.epochissued).utc(false).format('ll'): ''}</Table.Cell>
                                        <Table.Cell onClick={() => onClick_update(item.id,item.ack_req)}>{item.ack_req}</Table.Cell>
                                        <Table.Cell onClick={() => onClick_update(item.id,item.ack_req)}>{item.ack_req==="YES" && item.acknowledgements}</Table.Cell>
                                        <Table.Cell onClick={() => onClick_update(item.id,item.ack_req)}>{item.locations}</Table.Cell>
                                        <Table.Cell className='td_attachment' onClick={() => onClick_opendocument(item.id)}>
                                            {(item.filename !== null && item.filename !== '') ?
                                                <img src={"/icons/paperclip 60px (717473).svg?ts=" + Date.now()} alt={t('paperclip icon')}/>
                                                :
                                                ''
                                            }
                                        </Table.Cell>
                                        <Table.Cell className="td_icon_center" onClick={() => onOpen_mdl_history(item.id, item.name)}><img src={"/icons/history 30px (bcbebe).svg?ts=" + Date.now()} alt={t('history icon')}></img></Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                            }
                        </Table>
                        {var_ready && var_documents[0]['totalrows'] === 0 &&
                        <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t('alert icon')}/>{t('there are no documents to display')}</div>
                        }
                    </div>
                    <div className="content-filter">
                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_documents && var_documents.length > 0 ? var_documents[0]['totalrows'] : 0} populatefunction={populate_documents}
                            />
                        </div>
                    </div>
                </div>



                {/***** MODAL: DOCUMENT VIEW *****************************************************************************/}

                <Modal id="mdl_docviewer"
                    dimmer={'inverted'}
                    open={var_mdl_docviewer_open}
                    onClose={() => set_mdl_docviewer_open(false)}>
                    <MDL_DOCVIEWER
                        set_mdl_open={set_mdl_docviewer_open}
                        targetid={var_target_id}
                        classification='document'
                    />
                </Modal>

                {/***** END MODAL: DOCUMENT VIEW *************************************************************************/}

                {/***** MODAL: HISTORY *******************************************************************************/}

                <Modal id="mdl_history"
                       dimmer={'inverted'}
                       open={var_mdl_history_open}
                       onClose={() => set_mdl_history_open(false)}>
                    <MDL_HISTORY
                        set_mdl_open={set_mdl_history_open}
                        var_modaltitle={var_modaltitle}
                        var_target_id={var_target_id}>
                    </MDL_HISTORY>
                </Modal>

                {/***** END MODAL: HISTORY ***************************************************************************/}



                {/***** MODAL: ADD ***********************************************************************************/}

                <Modal id="org-documents_mdl_adddocument"
                       dimmer={'inverted'}
                       open={var_mdl_add_open}
                       onClose={() => set_mdl_add_open(false)}>
                    <MDL_ADD
                        set_mdl_open={set_mdl_add_open}
                        populate_function={populate_documents}>
                    </MDL_ADD>
                </Modal>

                {/***** END MODAL: ADD *******************************************************************************/}



                {/***** MODAL: UPDATE ********************************************************************************/}

                <Modal id="org-documents_mdl_updatedocument"
                       dimmer={'inverted'}
                       open={var_mdl_update_open}
                       onClose={() => set_mdl_update_open(false)}>
                    <MDL_UPDATE
                        set_mdl_open={set_mdl_update_open}
                        var_target_id={var_target_id}
                        var_ack_req={var_ack_req}
                        populate_function={populate_documents}>
                    </MDL_UPDATE>
                </Modal>

                {/***** END MODAL: UPDATE ***************************************************************************/}



            </div>
        </>
    )

}

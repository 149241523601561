import { Button, Form } from 'semantic-ui-react';
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import '../../../../../../../i18n';
import Moment from 'moment';
import datelib from '../../../../../../../libs/date-lib';
import auth from '../../../../../../../libs/auth-lib';

import './mdl_assign.css';



export default function({set_mdl_open, populate_function, var_location_id}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_selectedform, set_selectedform ] = useState('');
    const [ var_selectedformname, set_selectedformname ] = useState('');
    const [ var_selectedindividual, set_selectedindividual ] = useState([]);
    const [ var_selectedsupervisor, set_selectedsupervisor ] = useState(null);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_existingform, set_existingform ] = useState([]);
    const [ var_today ] = useState(new Date()/1000);

    const [ var_dd_forms, set_dd_forms ] = useState([]);
    const [ var_dd_individuals, set_dd_individuals ] = useState([]);
    const [ var_dd_supervisors, set_dd_supervisors ] = useState([]);
    const [ var_requires_acknowledgement, set_requires_acknowledgement ] = useState(false);


    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_dd_forms();
        populate_dd_individuals();
        populate_dd_supervisors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_dd_forms(){
        try {
            let results = await API_get_dd_forms();
            set_dd_forms(results);
        } catch (e) {
            console.log(e);
        }
    }

    async function populate_dd_individuals(){
        try {
            let results = await API_get_dd_individuals();
            set_dd_individuals(results);
        } catch (e) {
            console.log(e);
        }
    }

    async function populate_dd_supervisors() {
        try {
            set_dd_supervisors(await API_get_dd_reviewers());
        } catch (e) {
            console.log(e);
        }
    }

    async function assign_form() {
        set_processing(true);
        try {
            let data = {};
            data['form_id'] = var_selectedform;
            data['individuals'] = [...var_selectedindividual];
            var_requires_acknowledgement && !data['individuals'].includes(var_selectedsupervisor) && data.individuals.push(var_selectedsupervisor);
            data['location_id'] = var_location_id;
            data['organization_id'] = auth.actingorganization_id;
            data['transactionby'] = auth.id;
            data['transactionorg'] = auth.actingorganization_id;

            await API_post_activeform(data);

            set_ready(false);
            set_processing(false);
            populate_function();
            set_mdl_open(false);
        } catch (e) {
            console.log(e);
        }

    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_dd_forms(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-forms', '/get-dd-loc-forms/'+var_location_id);
    }

    function API_get_dd_individuals(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-locations', '/get-dd-individuals/'+var_location_id);
    }

    function API_get_dd_reviewers() {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-forms', '/get-dd-reviewers/' + var_location_id);
    }

    function API_post_activeform(data){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-forms', '/post-activeform', { body: data});
    }

    function API_get_loc_form_assigned(form_id){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-forms','/get-loc-form-assigned/'+var_location_id+'/'+form_id+'/'+Moment(var_today).valueOf(), {queryStringParameters:{tz: datelib.timezone}});
    }


    //  event functions ------------------------------------------------------------------------------------------------

    async function onChange_form(e, { value }){
        set_processing(true);
        set_selectedindividual([]);
        set_selectedsupervisor(null);
        set_selectedform(value);
        set_selectedformname(e.target.textContent);
        set_requires_acknowledgement(var_dd_forms.find(item => item.value === value).requires_acknowledgement === 'YES');
        let result = await API_get_loc_form_assigned(value);
        set_existingform(result);
        set_processing(false);
        set_ready(false);
    }

    function onChange_individual(e, { value }){
        set_ready(false);
        set_selectedindividual(value);
        if (value.length > 0 && var_selectedform !== '' && (!var_requires_acknowledgement || var_selectedsupervisor)) {
            set_ready(true);
        }
    }

    async function onChange_supervisor(e, { value }) {
        set_selectedsupervisor(value);
        set_ready(value && var_selectedform && var_selectedindividual);
    }

    function onClick_submit(){
        if(var_ready) {
            assign_form();
        }
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{t("Assign A Form")}</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
            </div>

            <div className="modal-content">
                <Form>
                    <Form.Select
                        className='form_id'
                        id='form_id'
                        name='form_id'
                        label={t("FORM")}
                        search
                        options={var_dd_forms}
                        value={var_selectedform || ''}
                        onChange={onChange_form}
                        placeholder={t("Select a form...")}
                    />
                    <Form.Select
                        className='individual_id'
                        id='individual_id'
                        name='individual_id'
                        label={t("INDIVIDUAL")}
                        options={var_dd_individuals}
                        value={var_selectedindividual || []}
                        onChange={onChange_individual}
                        placeholder={t("Select an individual...")}
                        multiple
                        selection
                        search
                        fluid
                    />
                    { var_requires_acknowledgement &&
                        <Form.Select
                            className='supervisor_id'
                            id='supervisor_id'
                            name='supervisor_id'
                            label={t("SELECT SUPERVISOR TO REVIEW")}
                            search
                            options={var_dd_supervisors}
                            value={var_selectedsupervisor || ''}
                            onChange={onChange_supervisor}
                            placeholder={t("Select a supervisor...")}
                        />
                    }
                </Form>
                { (var_existingform.length > 0 && !var_processing) &&
                    <div className="ui warning message"><p>A <em>{var_selectedformname}</em> already exists for today. Are you sure you want to open a new form?</p></div>
                }
            </div>

            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>{t("CANCEL")}</Button>
                    {!var_processing ?
                        <Button className={"btn_primary " + (var_ready ? ' btn_active' : '')} disabled={(var_ready ? false : true)} onClick={() => onClick_submit()}>{t("ASSIGN FORM")}</Button>
                        :
                        <Button loading className="btn_primary btn_active">{t("ASSIGN FORM")}</Button>
                    }
                </div>
            </div>
        </>
    )
}
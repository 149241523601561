import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import CMP_PAGINATION from './cmp_pagination/cmp_pagination';
import CMP_DOWNLOAD from './cmp_download/cmp_download';
import CMP_SEARCH from './cmp_search/cmp_search';
import CMP_FILTER from './cmp_filter/cmp_filter';

import './cmp_tablecontrols.css';
import {Button} from "semantic-ui-react";
import {useTranslation} from "react-i18next";

export const filter_helper = {
    initialize: function(initialLoad) {
        return {
            search_term: null,
            search_fields: [],
            filters: [],
            refresh: true,
            load: true
        };
    },
    maxFilterItems: 1000
};

function CMP_TABLECONTROLS ({ var_offset, var_limit, var_ready, var_loading, total_rows, populatefunction, downloadname, downloadfunction,
    var_filter, set_filter, populatefilterfunction, table_config, display_paging, display_download, display_search, display_filter }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_refreshsearch, set_refreshsearch ] = useState(false);
    const [ var_refreshfilter, set_refreshfilter ] = useState(false);
    const [ var_activepage, set_activepage ] = useState(1);
    const [ var_displayrows, set_displayrows ] = useState(var_limit);
    const [ var_filter_forsearch, set_filter_forsearch ] = useState();
    const [ var_filter_forfilter, set_filter_forfilter ] = useState();
    //  const [var_mediaquery, set_mediaquery] = useState(window.matchMedia("(max-width: 650px)"));

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_filter && var_filter.refresh){
            set_filter({...var_filter, refresh: false, load: false});
            set_refreshsearch(!var_refreshsearch);
            set_refreshfilter(!var_refreshfilter);
            set_filter_forsearch({...var_filter, updated: false});
            set_filter_forfilter({...var_filter, updated: false});
        }
        // let mediaQuery = window.matchMedia("(max-width: 650px)");
        // mediaQuery.addEventListener("change",set_mediaquery);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_filter]);

    useEffect(() => {
        if (!var_filter_forsearch || !var_filter_forfilter) {
            return;
        }
        if (!var_filter_forsearch.updated && !var_filter_forfilter.updated){
            return;
        }
        let filter = {
            search_term: var_filter_forsearch.search_term,
            search_fields: var_filter_forsearch.search_fields,
            filters: var_filter_forfilter.filters,
            refresh: false,
            load: true
        };
        set_filter(filter);
    }, [var_filter_forsearch, var_filter_forfilter]);

    //  methods to make some basic checks before showing child components
    const download = () => display_download && downloadfunction && table_config && table_config.some(item => item.download);
    const search = () => var_filter_forsearch && display_search && table_config && table_config.some(item => item.filter && item.datatype === 'text');
    const filter = () => var_filter_forfilter && display_filter && table_config && table_config.some(item => item.filter);


    function onClick_refresh(){
        populatefunction(null,((var_activepage-1)*var_displayrows));
    }


    // RENDER ==========================================================================================================

    return (
        <>
            {(display_paging || display_download) &&
                <div className='tablecontrols_container_row'>
                    {display_paging && <CMP_PAGINATION var_offset={var_offset} var_limit={var_limit.toString()} var_ready={var_ready} var_loading={var_loading} total_rows={total_rows} populatefunction={populatefunction} />}
                    <div className='pg_refreshdownload'>
                        <Button id='btn_refresh' className={'btn_icononly pg_refresh' + (var_loading ? ' pg_loading' : '')} onClick={() => onClick_refresh()}><img className='btn_icon' src={'/icons/refresh 30px (a2a2a1).svg?ts=' + Date.now()} alt={t('refresh icon')}></img></Button>
                        {download() && <CMP_DOWNLOAD var_ready={var_ready} total_rows={total_rows} downloadname={downloadname} downloadfunction={downloadfunction} table_config={table_config} />}
                    </div>
                </div>
            }

            <div className='pg_refreshdownload_mobile'>
                <Button id='btn_refresh' className={'btn_icononly pg_refresh' + (var_loading ? ' pg_loading' : '')} onClick={() => onClick_refresh()}><img className='btn_icon' src={"/icons/refresh 30px (a2a2a1).svg?ts=" + Date.now()} alt={t('refresh icon')}></img></Button>
                {download() && <CMP_DOWNLOAD var_ready={var_ready} total_rows={total_rows} downloadname={downloadname} downloadfunction={downloadfunction} table_config={table_config} />}
            </div>

            {(display_search || display_filter) &&
                <div className='tablecontrols_container_row tablecontrols_search_container'>
                    {search() && <CMP_SEARCH refresh={var_refreshsearch} var_filter={var_filter_forsearch} set_filter={set_filter_forsearch} table_config={table_config} />}
                    {filter() && <CMP_FILTER refresh={var_refreshfilter} var_filter={var_filter_forfilter} set_filter={set_filter_forfilter} populatefilterfunction={populatefilterfunction} table_config={table_config} />}
                </div>
            }
        </>
    );
}

CMP_TABLECONTROLS.propTypes = {
    var_offset: propTypes.number,
    var_limit: propTypes.number,
    var_ready: propTypes.bool,
    var_loading: propTypes.bool,
    total_rows: propTypes.number,
    populatefunction: propTypes.func,
    downloadname: propTypes.string,
    downloadfunction: propTypes.func,
    var_filter: propTypes.object,
    set_filter: propTypes.func,
    populatefilterfunction: propTypes.func,
    table_config: propTypes.arrayOf(propTypes.shape({
        name: propTypes.string.isRequired,
        datatype: propTypes.oneOf(['text','int','date','epoch','function']),
        labelKey: propTypes.string,
        label: propTypes.string,
        filter: propTypes.bool,
        download: propTypes.bool,
        downloadfunction: propTypes.func
    })),
    display_paging: propTypes.bool.isRequired,
    display_download: propTypes.bool.isRequired,
    display_search: propTypes.bool.isRequired,
    display_filter: propTypes.bool.isRequired
};
CMP_TABLECONTROLS.defaultProps = {
    display_paging: true,
    display_download: true,
    display_search: true,
    display_filter: true
}

export default CMP_TABLECONTROLS;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import CRD_DETAILS from "./crd_details/crd_details";
import STA_DAILYCHECK from "./sta_dailycheck/sta_dailycheck";
import STA_CREDENTIALS from "./sta_credentials/sta_credentials";
import STA_DOCUMENT from "./sta_document/sta_document";
import STA_FORMS from "./sta_forms/sta_forms";
import STA_REQUIREMENTS from "./sta_requirements/sta_requirements";
import TAB_CREDENTIALS from "./tab_credentials/tab_credentials";
import TAB_HISTORY from "./tab_history/tab_history";
import TAB_REQUIREMENTS from "./tab_requirements/tab_requirements";

import { useTranslation } from 'react-i18next';
import '../../../i18n';
import auth from '../../../libs/auth-lib';

import "./wallet.css";


export default function(appProps) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();
    const history = useHistory();

    const [ var_activetab, set_activetab ] = useState("");
    const [ var_pageready, set_pageready ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(auth.is_loggedin){
            appProps.set_visibleframe(true);
            appProps.set_visiblemenu('INDIVIDUAL');
            appProps.set_activemenu('/indv/wallet');

            if(auth.indv_credentials){
                set_activetab('CREDENTIALS');
            } else {
                set_activetab('HISTORY');
            }
            set_pageready(true);
        }else{
            history.push('/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);



    //  async functions ------------------------------------------------------------------------------------------------

    //  API calls ------------------------------------------------------------------------------------------------------

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_tab(var_tab){
        set_activetab(var_tab);
    }


    // RENDER APP ======================================================================================================


    return(
        <>
        {(auth.is_loggedin) &&
        <div className="content">

            <div className="content-topmenu"><img src={"/icons/wallet 60px (585b5a).svg?ts=" + Date.now()} alt={t('wallet icon')}></img>{auth.firstname} {auth.lastname} {t('Wallet')}</div>
            <div className="content-wrapper" id="cw_indv_overview">
                {var_pageready &&
                <>
                    <div className="statswrapper">
                        <CRD_DETAILS
                            var_pageready={var_pageready}
                            set_language={appProps.set_appDefaultLanguage}>
                        </CRD_DETAILS>
                        {auth.indv_dailycheck &&
                        <STA_DAILYCHECK
                            var_pageready={var_pageready}>
                        </STA_DAILYCHECK>
                        }
                        {auth.indv_credentials &&
                        <STA_CREDENTIALS
                            var_pageready={var_pageready}
                            set_activetab={set_activetab}
                            var_indvcredentialsrefresh={appProps.var_indvcredentialsrefresh}
                            set_indvcredentialsrefresh={appProps.set_indvcredentialsrefresh}>
                        </STA_CREDENTIALS>
                        }
                        {auth.indv_documents &&
                            <STA_DOCUMENT
                                var_pageready={var_pageready}
                                var_documentsrefresh={appProps.var_documentsrefresh}>
                            </STA_DOCUMENT>
                        }
                        {auth.indv_forms &&
                            <STA_FORMS
                                var_pageready={var_pageready}
                                var_formsrefresh={appProps.var_formsrefresh}>
                            </STA_FORMS>
                        }
                        {(  auth.indv_requirements &&
                            (auth.individual_type !== 'GUEST' && auth.individual_type !== 'STUDENT')) &&
                                <STA_REQUIREMENTS
                                    var_pageready={var_pageready}
                                    set_activetab={set_activetab}
                                    var_formsrefresh={appProps.var_formsrefresh}>
                                </STA_REQUIREMENTS>
                        }
                        {/*{JSON.parse(sessionStorage.getItem('authentication')).indv_r2w &&
                        <STA_R2K
                            var_pageready={var_pageready}>
                        </STA_R2K>
                        }*/}
                    </div>

                    <div className="content-tabs">
                        {(auth.indv_credentials) &&
                        <div
                            className={"content-tabs-item " + (var_activetab === 'CREDENTIALS' ? 'content-tabs-item-active' : '')}
                            onClick={() => onClick_tab('CREDENTIALS')}><img src={"/icons/credential 60px (717473).svg?ts=" + Date.now()} alt={t('credential icon')}/>
                            {t('My Credentials')}
                        </div>
                        }
                        {(  auth.indv_requirements &&
                            (auth.individual_type !== 'GUEST' && auth.individual_type !== 'STUDENT')) &&
                        <div
                            className={"content-tabs-item " + (var_activetab === 'REQUIREMENTS' ? 'content-tabs-item-active' : '')}
                            onClick={() => onClick_tab('REQUIREMENTS')}><img src={"/icons/credential 60px (717473).svg?ts=" + Date.now()} alt={t('requirements icon')}/>
                                {t('Requirements')}
                        </div>
                        }
                        <div
                            className={"content-tabs-item " + (var_activetab === 'HISTORY' ? 'content-tabs-item-active' : '')}
                            onClick={() => onClick_tab('HISTORY')}><img src={"/icons/history 60px (717473).svg?ts=" + Date.now()} alt={t('history icon')}/>
                                {t('History')}
                        </div>
                    </div>

                    {auth.indv_credentials &&
                    <div className={(var_activetab !== 'CREDENTIALS' ? 'content-card-hide' : '')}>
                        <TAB_CREDENTIALS
                            var_activetab={var_activetab}
                            set_indvcredentialsrefresh={appProps.set_indvcredentialsrefresh}>
                        </TAB_CREDENTIALS>
                    </div>
                    }

                    {(  auth.indv_requirements &&
                        !auth.individual_type_guest) &&
                    <div className={(var_activetab !== 'REQUIREMENTS' ? 'content-card-hide' : '')}>
                        <TAB_REQUIREMENTS
                            var_activetab={var_activetab}
                            set_documentsrefresh={appProps.set_documentsrefresh}>
                        </TAB_REQUIREMENTS>
                    </div>
                    }
                    <div className={(var_activetab !== 'HISTORY' ? 'content-card-hide' : '')}>
                        <TAB_HISTORY
                            var_activetab={var_activetab}>
                        </TAB_HISTORY>
                    </div>

                    <div className={"content-card " + (var_activetab !== 'R2K' ? 'content-card-hide' : '')}>
                        <div className="content-card-content">
                        {t('R2K')}
                        </div>
                    </div>

                    <div className={"content-card " + (var_activetab !== 'CERTIFICATES' ? 'content-card-hide' : '')}>
                        <div className="content-card-content">
                        {t('Certificates')}
                        </div>
                    </div>
                </>
                }
            </div>
        </div>
        }
        </>
    )
};

import React from "react";
import { useTranslation } from 'react-i18next';
import '../../../../i18n';

import './sta_onsite.css';

export default function({ var_onsiteSummary }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    //  event listeners ------------------------------------------------------------------------------------------------


    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------


    // RENDER APP ======================================================================================================

    return (
        <>
            <div id="onsitesummary" className="summary_wrapper">
                <div className="summary_icon">
                    <img src={"/icons/flag 60px (717473).svg?ts=" + Date.now()} alt={t('flag icon')}/>
                </div>
                <div className="summary_item">
                    <div className="summary_item_number green">{var_onsiteSummary === undefined? '' : (var_onsiteSummary.onsite || 0)}</div>
                    <div className="summary_item_text">{t('CONFIRM')}</div>
                    <div className="summary_item_text">{t('ONSITE')}</div>
                </div>
                <div className="summary_item">
                    <div className="summary_item_number red">{var_onsiteSummary === undefined? '' : (var_onsiteSummary.offsite || 0)}</div>
                    <div className="summary_item_text">{t('CONFIRM')}</div>
                    <div className="summary_item_text">{t('OFFSITE')}</div>
                </div>
                <div className="summary_item">
                    <div className="summary_item_number">{var_onsiteSummary === undefined? '' : (var_onsiteSummary.missing || 0)}</div>
                    <div className="summary_item_text">{t('CONFIRM')}</div>
                    <div className="summary_item_text">{t('MISSING')}</div>
                </div>
            </div>
        </>
    )

}
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Table } from 'semantic-ui-react';
import datelib from '../../../../libs/date-lib';

import { useTranslation } from 'react-i18next';
import '../../../../i18n';

import './crd_forms.css';
import {filter_helper} from "../../../../components/cmp_tablecontrols/cmp_tablecontrols";
import CMP_TABLECONTROLS from "../../../../components/cmp_tablecontrols/cmp_tablecontrols";



export default function({var_activetab}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();
    const [ var_forms, set_forms ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(10);
    const [ var_offset, set_offset] = useState(0);
    const [ var_search, set_search ] = useState('');
    const [ var_sortby, set_sortby ] = useState('formname');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_activetab === 'FORMS') {
            set_filter(filter_helper.initialize(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_activetab]);

    useEffect(()=>{
        if(var_filter.load){
            populate_forms(null,0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_filter]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_forms(limit,offset,sortby,sortorder,search,filter){
        if (var_activetab !== 'FORMS') return; // Don't load data if tab is not visible

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        if(search) {
            if(search === '-') {
                search = '';
            }
            set_search(search);
        }else{
            search=var_search
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_org_forms(limit,offset,sortby,sortorder,search,filter);
            set_forms(results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername,sortorder){
        try {
            return await API_get_org_forms(filter_helper.maxFilterItems,0,filtername,sortorder,var_search,[],filtername);
        } catch (e) {
            console.log(e);
        }
    }

    async function download_forms(limit,offset){
        try {
            return await API_get_org_forms(limit,offset,var_sortby,var_sortorder,var_search,var_filter);
        } catch (e) {
            console.log(e);
            throw e;
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_forms(limit,offset,sortby,sortorder,search,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-forms',
            '/get-org-forms/'+JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id,
            {
                queryStringParameters: {
                    limit : limit,
                    offset : offset,
                    sortby : sortby,
                    sortorder: sortorder,
                    search : search,
                    tz : datelib.timezone,
                    filtername : filtername
                },
                body : filter
            }
        );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_forms('',0,sortby,sortorder)
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="content-card" id="crd_org_forms">
                <div className="content-card-header">
                    <div className="content-card-header-title">{t('Forms')}</div>
                </div>
                <div className="content-card-gridcontent">
                    <div className="content-filter">
                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_forms && var_forms.length > 0 ? var_forms[0]['totalrows'] : 0}  populatefunction={populate_forms}
                                downloadname='Forms' downloadfunction={download_forms}
                                var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                                table_config={[
                                    {name: 'formname', datatype: 'text', labelKey: 'FORM', download: true, filter: true},
                                    {name: 'status', datatype: 'text', labelKey: 'STATE', download: true, filter: true}
                                ]}
                            />
                        </div>
                    </div>
                    <div className="tablewrapper">
                        <Table sortable compact className={(var_loading ? " tbl_loading" : "")}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell sorted={var_sortby === 'formname' ? var_sortorder : null} onClick={() => onClick_sort('formname')}>{t("FORM")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'status' ? var_sortorder : null} onClick={() => onClick_sort('status')}>{t("STATE")}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {var_ready && var_forms[0]['totalrows'] !== 0 &&
                            <Table.Body>
                                {var_forms.map((item, i) =>
                                    <Table.Row key={i}>
                                        <Table.Cell>{item.formname}</Table.Cell>
                                        <Table.Cell>{item.status}</Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                            }
                        </Table>
                        {var_ready && var_forms[0]['totalrows'] === 0 &&
                        <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt="alert icon"/>{t("there are no forms to display")}</div>
                        }
                    </div>
                    <div className="content-filter">
                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_forms && var_forms.length > 0 ? var_forms[0]['totalrows'] : 0}  populatefunction={populate_forms}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { API } from "aws-amplify";
import datelib from '../../../../libs/date-lib';
import '../../../../i18n';

import './sta_credentials.css';


export default function({var_pageready, set_activetab, var_indvcredentialsrefresh, set_indvcredentialsrefresh}){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();

    const [ var_indv_credential_stat, set_indv_credential_stat ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_pageready) {
            populate_indv_credentials_stat();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_pageready]);

    useEffect(()=>{
        if(var_indvcredentialsrefresh) {
            populate_indv_credentials_stat();
            set_indvcredentialsrefresh(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_indvcredentialsrefresh])


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_indv_credentials_stat(){
        try {
            let results = await API_get_indv_credentials_stat();
            results[0]['alerts']=results[0]['invalid']+results[0]['expired'];
            set_indv_credential_stat(results[0]);
        } catch (e) {
            console.log(e);
        }

        set_ready(true);
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_indv_credentials_stat(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('indv-credentials', '/get-indv-credentials-stat/'+JSON.parse(sessionStorage.getItem('authentication')).id, {
                queryStringParameters: {
                    tz : datelib.timezone
                }
            })
            .then(response => response)
            .catch(error => {
                console.log(error.response);
                return [{"invalid":0,"expired":0,"comingdue":0,"total":0}];  // on error, return a sane result
            });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_credentials(){
        set_activetab('CREDENTIALS');
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="stat-card" id="sta_indv_overview_credential" onClick={()=>onClick_credentials()}>
                {var_ready &&
                <>
                    {(var_indv_credential_stat.total === 0) &&
                    <div className="credential-bg credential-none">
                        <div className="credential-icon"><img src={"/icons/credential 60px (ffffff).svg?ts=" + Date.now()} alt={t('credential icon')}></img></div>
                        <div className="credential-text-wrapper">
                            <div className="credential-title">{t('MY CREDENTIALS')}</div>
                            <div className="credential-subtitle">{t('You have no credentials in your wallet')}</div>
                        </div>
                    </div>
                    }
                    {(var_indv_credential_stat.total > 0 && var_indv_credential_stat.alerts === 0 && var_indv_credential_stat.comingdue === 0) &&
                    <div className="credential-bg credential-pass">
                        <div className="credential-icon"><img src={"/icons/credential 60px (ffffff).svg?ts=" + Date.now()} alt={t('credential icon')}></img></div>
                        <div className="credential-text-wrapper">
                            <div className="credential-title">{t('MY CREDENTIALS')}</div>
                            <div className="credential-subtitle">{t('No credentials require your attention')}</div>
                        </div>
                    </div>
                    }
                    {((var_indv_credential_stat.alerts > 0) && var_indv_credential_stat.comingdue === 0) &&
                    <div className="credential-bg credential-fail">
                        <div className="credential-icon"><img src={"/icons/credential 60px (ffffff).svg?ts=" + Date.now()} alt={t('credential icon')}></img></div>
                        <div className="credential-title">{t('MY CREDENTIALS')}</div>
                        {var_indv_credential_stat.alerts === 1 &&
                        <div className="credential-subtitle">{t('You have 1 credential that requires your attention')}</div>
                        }
                        {var_indv_credential_stat.alerts > 1 &&
                        <div className="credential-subtitle">{t('You have')} {var_indv_credential_stat.alerts} {t('credentials that require your attention')}</div>
                        }
                    </div>
                    }
                    {((var_indv_credential_stat.alerts === 0) && var_indv_credential_stat.comingdue > 0) &&
                    <div className="credential-bg credential-none">
                        <div className="credential-icon"><img src={"/icons/credential 60px (ffffff).svg?ts=" + Date.now()} alt={t('credential icon')}></img></div>
                        <div className="credential-title">{t('MY CREDENTIALS')}</div>
                        {var_indv_credential_stat.comingdue === 1 &&
                        <div className="credential-subtitle">{t('You have 1 credential coming due')}</div>
                        }
                        {var_indv_credential_stat.comingdue > 1 &&
                        <div className="credential-subtitle">{t('You have')} {var_indv_credential_stat.alerts} {t('credentials coming due')}</div>
                        }
                    </div>
                    }
                    {((var_indv_credential_stat.alerts > 0) && var_indv_credential_stat.comingdue > 0) &&
                    <div className="credential-bg credential-fail">
                        <div className="credential-icon"><img src={"/icons/credential 60px (ffffff).svg?ts=" + Date.now()} alt={t('credential icon')}></img></div>
                        <div className="credential-title">{t('MY CREDENTIALS')}</div>
                        {var_indv_credential_stat.alerts === 1 &&
                        <div className="credential-subtitle">{t('You have 1 credential that requires your attention')}</div>
                        }
                        {var_indv_credential_stat.alerts > 1 &&
                        <div className="credential-subtitle">{t('You have')} {var_indv_credential_stat.alerts} {t('credentials that require your attention')}</div>
                        }
                        {var_indv_credential_stat.comingdue === 1 &&
                        <div className="credential-subtitle">{t('You have 1 credential coming due')}</div>
                        }
                        {var_indv_credential_stat.comingdue > 1 &&
                        <div className="credential-subtitle">{t('You have')} {var_indv_credential_stat.comingdue} {t('credentials coming due')}</div>
                        }
                    </div>
                    }
                </>
                }
            </div>
        </>
    )

}

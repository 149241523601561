import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Button, Modal, Table } from 'semantic-ui-react';
import datelib from '../../../../libs/date-lib';
import { useTranslation } from 'react-i18next';
import '../../../../i18n';
import auth from '../../../../libs/auth-lib';

import CMP_TABLECONTROLS, { filter_helper } from '../../../../components/cmp_tablecontrols/cmp_tablecontrols';
import MDL_HISTORY from '../../../../components/cmp_history/cmp_history';
import MDL_ADD from './mdl_add/mdl_add';
import MDL_UPDATE from './mdl_update/mdl_update';

import './crd_locations.css';



export default function(appProps){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_locations, set_locations ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(100);
    const [ var_offset, set_offset] = useState(0);
    const [ var_archive, set_archive ] = useState('NO');
    const [ var_sortby, set_sortby ] = useState('code');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));

    const [ var_mdl_history_open, set_mdl_history_open ] = useState(false);
    const [ var_modaltitle, set_modaltitle ] = useState('');
    const [ var_target_id, set_target_id ] = useState('');
    const [ var_target, set_target ] = useState('');

    const [ var_mdl_add_open, set_mdl_add_open ] = useState(false);
    const [ var_mdl_update_open, set_mdl_update_open ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_archive !== undefined) {
            set_filter(filter_helper.initialize(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_archive]);

    useEffect(()=>{
        if (var_filter.load){
            populate_locations(null,0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_filter]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_locations(limit,offset,sortby,sortorder,archive,filter){

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        if(archive) {
            set_archive(archive);
        }else{
            archive=var_archive
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_locations(limit,offset,sortby,sortorder,archive,filter);
            set_locations(results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername,sortorder){
        try {
            return await API_get_locations(filter_helper.maxFilterItems,0,filtername,sortorder,var_archive,[],filtername);
        } catch (e) {
            console.log(e);
        }
    }

    async function download_locations(limit, offset){
        try {
            return await API_get_locations(limit,offset,var_sortby,var_sortorder,var_archive,var_filter);
        } catch (e){
            console.log(e);
            throw e;
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_locations(limit,offset,sortby,sortorder,archive,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-locations',
            '/get-org-locations/' + auth.actingorganization_id + '/' + auth.id,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    archive: archive,
                    tz: datelib.timezone,
                    filtername: filtername
                },
                body: filter
            }
        );
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_locations(null,0,sortby,sortorder)
    }

    function onClick_addlocation(){
        set_mdl_add_open(true);
    }

    function onClick_update(var_target_id, var_target){
        set_target(var_target);
        set_target_id(var_target_id);
        set_mdl_update_open(true);
    }

    function onOpen_mdl_history(var_location_id, location) {
        set_modaltitle(t("Location/Project History") + ' - ' + location);
        set_target_id(var_location_id);
        set_mdl_history_open(true);
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="content-card" id="crd_org_individuals_individuals">
                <div className="content-card-header">
                    {var_archive === 'NO' ?
                        <div className="content-card-header-title">{t("All Active Locations & Projects")}</div>
                        :
                        <div className="content-card-header-title">{t("All Archived Locations & Projects")}</div>
                    }
                    {[auth.constants.permission_ids.GlobalAdmin, auth.constants.permission_ids.Superadmin].includes(auth.permission_id) &&
                        <Button className="btn_tertiary" onClick={() => onClick_addlocation()}><img className="btn_icon" src={"/icons/add 30px (4dcbd4).svg?ts=" + Date.now()} alt="add icon"/>{t("ADD LOCATION/PROJECT")}</Button>
                    }
                </div>
                <div className="content-card-gridcontent">
                    <div className="content-filter">

                        <div className="content-filter-archive">
                            {var_archive === 'NO' ?
                                <Button className="btn_tertiary" onClick={() => set_archive('YES')}><img className="btn_icon" src={"/icons/archive 30px (4dcbd4).svg?ts=" + Date.now()} alt={t("archive icon")}/>{t("VIEW ARCHIVED LOCATIONS & PROJECTS")}</Button>
                                :
                                <Button className="btn_tertiary" onClick={() => set_archive('NO')}><img className="btn_icon" src={"/icons/checkmark 30px (4dcbd4).svg?ts=" + Date.now()} alt={t("checkmark icon")}/>{t("VIEW ACTIVE LOCATIONS & PROJECTS")}</Button>
                            }
                        </div>

                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                var_offset={var_offset} var_limit={var_limit} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_locations && var_locations.length > 0 ? var_locations[0]['totalrows'] : 0} populatefunction={populate_locations}
                                downloadname='Locations & Projects' downloadfunction={download_locations}
                                var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                                table_config={[
                                    {name: 'code', datatype: 'text', labelKey: 'CODE', download: true, filter: true},
                                    {name: 'location_name', datatype: 'text', labelKey: 'LOC/PROJ', download: true, filter: true},
                                    {name: 'organization', datatype: 'text', labelKey: 'ORGANIZATION', download: true, filter: true},
                                    {name: 'default_org', datatype: 'text', labelKey: 'DEFAULT', download: true, filter: true},
                                    {name: 'address', datatype: 'text', labelKey: 'ADDRESS', download: true, filter: false},
                                    {name: 'businessunit', datatype: 'text', labelKey: 'BUSINESS UNIT', download: true, filter: true},
                                    {name: 'city', datatype: 'text', labelKey: 'CITY', download: true, filter: false},
                                    {name: 'province', datatype: 'text', labelKey: 'PROV/STATE', download: true, filter: false},
                                    {name: 'city_province', datatype: 'text', labelKey: 'CITY', download: false, filter: true},
                                    {name: 'country', datatype: 'text', labelKey: 'COUNTRY', download: true, filter: false},
                                    ...auth.org_dailycheck ?
                                        [{name: 'questionnaire', datatype: 'text', labelKey: 'QUESTIONNAIRE', download: true, filter: true}] : [],
                                    ...auth.org_networks ?
                                        [{name: 'network_orgs', datatype: 'int', labelKey: 'NET ORGS', download: true, filter: true}] : []
                                ]}
                            />
                        </div>
                    </div>
                    <div className="tablewrapper">
                        <Table sortable compact className={(var_loading ? " tbl_loading" : "")}>
                            <Table.Header>
                                <Table.Row>
                                    {renderHeaderCell('code','CODE','')}
                                    {renderHeaderCell('location_name','LOC/PROJ','')}
                                    {renderHeaderCell('organization','ORGANIZATION','')}
                                    {renderHeaderCell('default_org','DEFAULT','')}
                                    {renderHeaderCell('businessunit','BUSINESS UNIT', 'td_businessunit')}
                                    {renderHeaderCell('city_province','CITY', 'td_city')}
                                    {auth.org_dailycheck &&
                                        renderHeaderCell('questionnaire','QUESTIONNAIRE','td_questionnaire')
                                    }
                                    {auth.org_networks &&
                                        renderHeaderCell('network_orgs', 'NET ORGS','')
                                    }
                                    <Table.HeaderCell className="td_icon_center">{t("HSTRY")}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {var_ready && var_locations[0]['totalrows'] !== 0 &&
                                <Table.Body>
                                   {var_locations.map((item, i) =>
                                        <Table.Row key={i}>
                                            <Table.Cell onClick={() => onClick_update(item.id, item.location_name)}>{item.code}</Table.Cell>
                                            <Table.Cell className="td_overflow" onClick={() => onClick_update(item.id, item.location_name)}>{item.location_name}</Table.Cell>
                                            <Table.Cell onClick={() => onClick_update(item.id, item.location_name)}>{item.organization}</Table.Cell>
                                            <Table.Cell onClick={() => onClick_update(item.id, item.location_name)}>{item.default_org}</Table.Cell>
                                            <Table.Cell className="td_businessunit" onClick={() => onClick_update(item.id, item.location_name)}>{item.businessunit}</Table.Cell>
                                            <Table.Cell className="td_overflow td_city" onClick={() => onClick_update(item.id, item.location_name)}>{item.city_province}</Table.Cell>
                                            {auth.org_dailycheck &&
                                                <Table.Cell className="td_questionnaire" onClick={() => onClick_update(item.id, item.location_name)}>{item.questionnaire}</Table.Cell>
                                            }
                                            {auth.org_networks &&
                                                <Table.Cell onClick={() => onClick_update(item.id, item.location_name)}>{item.network_orgs}</Table.Cell>
                                            }
                                            <Table.Cell className="td_icon_center" onClick={() => onOpen_mdl_history(item.id, item.location_name)}><img src={"/icons/history 30px (bcbebe).svg?ts=" + Date.now()} alt={t("history icon")}></img></Table.Cell>
                                        </Table.Row>
                                    )}
                                </Table.Body>
                            }
                        </Table>
                        {var_ready && var_locations[0]['totalrows'] === 0 &&
                        <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t("alert icon")}/>{t("there are no locations/projects to display")}</div>
                        }
                    </div>
                    <div className="content-filter">
                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                var_offset={var_offset} var_limit={var_limit} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_locations && var_locations.length > 0 ? var_locations[0]['totalrows'] : 0} populatefunction={populate_locations}
                            />
                        </div>
                    </div>
                </div>



                {/***** MODAL: HISTORY *******************************************************************************/}

                <Modal id="mdl_history"
                       dimmer={'inverted'}
                       open={var_mdl_history_open}
                       onClose={() => set_mdl_history_open(false)}>
                    <MDL_HISTORY
                        set_mdl_open={set_mdl_history_open}
                        var_modaltitle={var_modaltitle}
                        var_target_id={var_target_id}>
                    </MDL_HISTORY>
                </Modal>

                {/***** END MODAL: DETAILS ***************************************************************************/}



                {/***** MODAL: ADD ***********************************************************************************/}

                <Modal id="org-locations_mdl_addlocation"
                       dimmer={'inverted'}
                       open={var_mdl_add_open}
                       onClose={() => set_mdl_add_open(false)}>
                    <MDL_ADD
                        set_mdl_open={set_mdl_add_open}
                        populate_function={populate_locations}>
                    </MDL_ADD>
                </Modal>

                {/***** END MODAL: ADD *******************************************************************************/}



                {/***** MODAL: UPDATE ********************************************************************************/}

                <Modal id="org-locations_mdl_updatelocation"
                       dimmer={'inverted'}
                       open={var_mdl_update_open}
                       onClose={() => set_mdl_update_open(false)}>
                    <MDL_UPDATE
                        set_mdl_open={set_mdl_update_open}
                        var_target={var_target}
                        var_target_id={var_target_id}
                        populate_function={populate_locations}>
                    </MDL_UPDATE>
                </Modal>

                {/***** END MODAL: UPDATE ***************************************************************************/}



            </div>
        </>
    )

    function renderHeaderCell(field,labelkey,classname){
        return (
            <Table.HeaderCell className={classname} sorted={var_sortby === field ? var_sortorder : null} onClick={()=>onClick_sort(field)}>
                {t(labelkey)}
            </Table.HeaderCell>
        );
    }

}

import { API } from 'aws-amplify';
import {Dimmer, Loader, Button, Modal} from 'semantic-ui-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { bookmarkPlugin } from '@react-pdf-viewer/bookmark';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import auth from '../../libs/auth-lib';

import MDL_REVIEW from './mdl_review/mdl_review';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

import '../../i18n';
import './cmp_docviewer.css';

export default function MDL_DOCVIEWER({ set_mdl_open, targetid, classification, ispublic = false, show_acknowledge = false, function_documentacknowledge, var_review, onComplete_review }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_file_info, set_file_info ] = useState({});

    const [ var_filetype, set_filetype ] = useState('');
    const [ var_loading, set_loading ] = useState(true);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_error, set_error ] = useState(false);
    const [ var_bookmarks_open, set_bookmarks_open ] = useState(false);
    const [ var_rotate, set_rotate ] = useState(0);
    const [ var_viewerHeight, set_viewerHeight ] = useState(null);
    const [ var_viewerWidth, set_viewerWidth ] = useState(null);
    const [ var_scanpollcount, set_scanpollcount ] = useState(0);
    const [ var_img_load_error, set_img_load_error ] = useState(false);
    const [ var_review_open, set_review_open ] = useState(false);
    const [ var_review_complete, set_review_complete ] = useState(false);
    const [ var_icon_ts ] = useState(Date.now());

    const toolbarPluginInstance = toolbarPlugin();
    const { Toolbar } = toolbarPluginInstance;
    const bookmarkPluginInstance = bookmarkPlugin();
    const { Bookmarks } = bookmarkPluginInstance;

    const MAX_POLL_COUNT = 100;


    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        set_filetype(null);
        set_loading(false);
        set_ready(true);
        set_file_info({});
        if (targetid){
            get_file_info(true);
        }
    }, [targetid, var_review_complete]);

    useEffect(() => {
        if (var_scanpollcount > 0 && var_scanpollcount < MAX_POLL_COUNT){
            setTimeout(() => get_file_info(false), 1500);
        }
    }, [var_scanpollcount]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function insert_acknowledge() {
        try {
            if (show_acknowledge) {
                set_loading(true);
                set_ready(false);
                await function_documentacknowledge(targetid);
                set_mdl_open(false);
            }
        } catch (e) {
            console.log(e);
        }
    }

    async function get_file_info(firstload) {
        let file_info = await API_get_file_info();
        set_file_info(file_info);
        const file_ext = file_info.display_name.split('.').pop().toLowerCase();

        if (file_ext === 'pdf') {

            set_filetype('pdf');

        } else if (file_ext === 'png' || file_ext === 'jpeg' || file_ext === 'gif' || file_ext === 'jpg' || file_ext === 'bmp') {

            set_viewerHeight(window.screen.height * .55);
            set_filetype('image');
            set_loading(false);
            set_ready(true);

        } else {
            set_error(true);
            set_loading(false);
            set_ready(true);
        }

        if (file_info.scan_status === 'SCANNING' || file_info.scan_status === 'INFECTED') {
            set_loading(false);
        }

        set_scanpollcount(file_info.scan_status === 'SCANNING' ? (firstload ? 1 : (var_scanpollcount + 1)) : 0);
    }

    async function S3_download_file() {
        let file_info = await API_get_file_info();
        if (file_info.url){
            download_file(file_info.url);
        }

        set_loading(false);
        set_ready(true);
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_file_info(){
        localStorage.setItem('activetime',Math.floor(Date.now()/1000));
        return API.get('storage', (ispublic ? '/get-public-file-info/' : '/get-file-info/')+classification+'/'+targetid);
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function download_file(url) {
        const a = document.createElement('a');
        a.href = url;
        a.click();
    }

    function onClick_download() {
        set_loading(true);
        set_ready(false);
        S3_download_file();
    }

    function onClick_bookmarks() {
        set_bookmarks_open(!var_bookmarks_open);
    }

    function onClick_zoomin_image(){
        let imageWidth = document.getElementById('docPreviewImg').offsetWidth;
        document.getElementById('docPreviewImg').removeAttribute('height');
        document.getElementById('docPreviewImg').removeAttribute("style");
        set_viewerWidth(imageWidth + 20);
    }

    function onClick_zoomout_image(){
        let imageWidth = document.getElementById('docPreviewImg').offsetWidth;
        document.getElementById('docPreviewImg').removeAttribute('height');
        set_viewerWidth(imageWidth - 20);
    }

    function onClick_rotate() {
        if (var_filetype === 'image') {
            document.querySelector("#docPreviewImg").style.transform = `rotate(${var_rotate + 90}deg)`;
        }
        var_rotate === 270 ? set_rotate(0) : set_rotate(var_rotate + 90);
    }

    function onClick_acknowledge() {
        insert_acknowledge();
    }

    function onReviewComplete() {
        set_review_complete(true);
        set_scanpollcount(0);
        onComplete_review && onComplete_review();
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{var_file_info.display_name}</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + var_icon_ts} alt="close" /></div>
            </div>
            <div className="modal-content">
                {var_loading &&
                <Dimmer active inverted>
                    <Loader />
                </Dimmer>
                }

                {var_error &&
                <div className="docviewer_error">
                    <div className="message warning">
                        <div className="message_icon"><img src={"/icons/warning 60px (d91e18).svg?ts=" + var_icon_ts} alt={t('warning icon')} /></div>
                        <div className="message_text_wrapper">
                            <div className="message_text cause">{t('A preview of this document could not be created due its file format.')}</div>
                            <div className="message_text resolution">{t('To view it, please click the download button')}</div>
                        </div>
                    </div>
                </div>
                }

                {var_file_info.scan_status === 'INFECTED'
                    ? <div className="docviewer_error">
                        <div className="message warning">
                            <div className="message_icon"><img src={"/icons/warning 60px (d91e18).svg?ts=" + var_icon_ts} alt={t('warning icon')} /></div>
                            <div className="message_text_wrapper">
                                <div className="message_text cause">{t('A preview of this document is not currently available.')}</div>
                                <div className="message_text resolution">{t('THE SYSTEM HAS DETECTED A VIRUS SIGNATURE IN THIS FILE')}</div>
                            </div>
                        </div>
                    </div>
                    : var_file_info.scan_status === 'SCANNING' && var_scanpollcount < MAX_POLL_COUNT
                        ? <div className="docviewer_error">
                            <div className="message warning">
                                <div className="message_icon"><img src={"/icons/warning 60px (d91e18).svg?ts=" + var_icon_ts} alt={t('warning icon')} /></div>
                                <div className="message_text_wrapper">
                                    <div className="message_text cause">{t('A preview of this document is not currently available.')}</div>
                                    <div className="message_text resolution">{t('This file is currently being scanned for viruses by the system. Please wait...')}</div>
                                </div>
                                <div style={{position: "relative", width: 50}}><Loader  /></div>
                            </div>
                        </div>
                        : var_file_info.scan_status === 'SCANNING'
                            ? <div className="docviewer_error">
                                <div className="message warning">
                                    <div className="message_icon"><img src={"/icons/warning 60px (d91e18).svg?ts=" + var_icon_ts} alt={t('warning icon')} /></div>
                                    <div className="message_text_wrapper">
                                        <div className="message_text cause">{t('A preview of this document is not currently available.')}</div>
                                        <div className="message_text resolution">{t('This file is currently being scanned for viruses by the system.')}</div>
                                    </div>
                                </div>
                            </div>
                        : var_filetype === 'image'
                        ? <figure id="image_preview">
                            <div id="imgPreviewContainer">
                            {var_img_load_error
                            ?
                                <div className="img-load-error">
                                {t('Failed to load image file.')}
                                </div>
                            :
                                <img onError={(e)=>{e.target.onerror = null;set_img_load_error(true)}} id="docPreviewImg" height={var_viewerHeight} width={var_viewerWidth} style={{maxWidth: "100%"}} className="contain" src={var_file_info.url} alt={t('image preview')} />

                            }
                            </div>
                        </figure>
                        :  var_filetype === 'pdf'
                        ? <div className="pdf_preview" id="docPreviewPdf">
                            <Worker workerUrl="/pdf/pdf.worker.js">
                                <div className='pdf-container'>
                                    <div className={'pdf-bookmarks' + (var_bookmarks_open ? ' open' : '')}>
                                        <Bookmarks />
                                    </div>
                                    <div className={'pdf-viewer' + (var_bookmarks_open ? ' bookmarks-open' : '')}>
                                        <Viewer
                                            fileUrl={var_file_info.url}
                                            plugins={[
                                                toolbarPluginInstance,
                                                bookmarkPluginInstance
                                            ]}
                                        />
                                    </div>
                                </div>
                            </Worker>
                        </div>
                        : var_file_info.not_found
                        ? <div></div>
                    : null
                }
            </div>
            {var_filetype === 'pdf' ? renderPdfToolbar() : var_filetype === 'image' ? renderImageToolbar() : renderOtherToolbar()}

            {/***** MODAL: REVIEW ***************************************************************************************/}

            <Modal id="mdl_acknowledge_document"
                   dimmer={'inverted'}
                   open={var_review_open}
                   onClose={() => set_review_open(false)}>
                <MDL_REVIEW
                    set_mdl_open={set_review_open}
                    onReviewComplete={onReviewComplete}
                    target_id={targetid}
                />
            </Modal>

            {/***** END MODAL: REVIEW ********************************************************************************/}
        </>
    )

    function renderPdfToolbar() {
        return (<Toolbar>
            {(slots) => {
                const {
                    CurrentPageLabel,
                    CurrentPageInput,
                    Rotate,
                    GoToNextPage,
                    GoToPreviousPage,
                    ShowSearchPopover,
                    ZoomIn,
                    ZoomOut
                } = slots;
                return (
                    <div className='modal-footer'>
                        <div className='modal-footer-archive'>
                            <div className='modal-footer-page-control'>
                                <CurrentPageLabel>
                                    {(props) => (
                                        <>
                                            <p>{props.numberOfPages > 1 ? props.numberOfPages + ' Pages' : '1 Page'}</p>
                                            {props.numberOfPages > 1 &&
                                                <>
                                                    <GoToPreviousPage>
                                                        {(previousPageProps) => (
                                                            <Button className="btn_tertiary previous_page" disabled={(previousPageProps.isDisabled || !var_ready)} onClick={previousPageProps.onClick}><img src={"/icons/previous 30px (29719a).svg?ts=" + var_icon_ts} alt={t('previous page icon')} /></Button>
                                                        )}
                                                    </GoToPreviousPage>
                                                    <CurrentPageInput />
                                                    <GoToNextPage>
                                                        {(nextPageProps) => (
                                                            <Button className="btn_tertiary next_page" disabled={(nextPageProps.isDisabled || !var_ready)} onClick={nextPageProps.onClick}><img src={"/icons/next 30px (29719a).svg?ts=" + var_icon_ts} alt={t('next page icon')} /></Button>
                                                        )}
                                                    </GoToNextPage>
                                                </>
                                            }
                                        </>
                                    )}
                                </CurrentPageLabel>
                            </div>
                            <div className="modal-footer-page-tools">
                                <ZoomIn>
                                    {(props) => (
                                        <Button className="btn_tertiary" disabled={props.isDisabled || !var_ready} onClick={props.onClick}><img src={"/icons/zoomin 30px (29719a).svg?ts=" + var_icon_ts} alt={t('zoom in icon')} /><label>{t("ZOOM-IN")}</label></Button>
                                    )}
                                </ZoomIn>
                                <ZoomOut>
                                    {(props) => (
                                        <Button className="btn_tertiary" disabled={props.isDisabled || !var_ready} onClick={props.onClick}><img src={"/icons/zoomout 30px (29719a).svg?ts=" + var_icon_ts} alt={t('zoom out icon')} /><label>{t("ZOOM-OUT")}</label></Button>
                                    )}
                                </ZoomOut>
                                <Rotate>
                                    {(props) => (
                                        <Button className="btn_tertiary" disabled={props.isDisabled || !var_ready} onClick={props.onClick}><img src={"/icons/rotate 30px (29719a).svg?ts=" + var_icon_ts} alt={t('rotate page icon')} /><label>{t("ROTATE")}</label></Button>
                                    )}
                                </Rotate>
                                <ShowSearchPopover>
                                    {(props) => (
                                        <Button className="btn_tertiary" disabled={props.isDisabled || !var_ready} onClick={props.onClick}><img src={"/icons/search 30px (29719a).svg?ts=" + var_icon_ts} alt={t('search icon')} /><label>{t("SEARCH")}</label></Button>
                                    )}
                                </ShowSearchPopover>
                                <Button className='btn_tertiary' disabled={!var_ready} onClick={onClick_bookmarks}><img src={'/icons/bookmark 30px (29719a).svg?ts=' + var_icon_ts} alt={t('bookmarks icon')} /><label>{t('BOOKMARKS')}</label></Button>
                                {(var_file_info.scan_status === 'CLEAN') &&
                                <Button className="btn_tertiary" disabled={!var_ready} onClick={onClick_download}><img
                                    src={"/icons/download 30px (4dcbd4).svg?ts=" + var_icon_ts}
                                    alt="download"/><label>{t("DOWNLOAD")}</label></Button>
                                }
                                <div className="modal-footer-buttons">
                                    {!show_acknowledge &&
                                        <Button className='btn_secondary' onClick={() => set_mdl_open(false)}>{t('CLOSE')}</Button>
                                    }
                                    {targetid !== undefined && targetid !== null && classification !== 'credential' && show_acknowledge &&
                                        <Button disabled={!var_ready} className="btn_primary btn_active" onClick={onClick_acknowledge}>{t('ACKNOWLEDGE')}</Button>
                                    }
                                    {var_review && !var_review_complete && auth.has_access('ORG-FORMS', 'review') &&
                                        <Button className='btn_primary btn_active' onClick={() => set_review_open(true)}>{t('REVIEW')}</Button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }}
        </Toolbar>
        );
    }

    function renderImageToolbar() {
        return (
            <div className='modal-footer img-footer'>
                <div className="modal-footer-archive">
                    <div className="modal-footer-page-tools">
                        <Button className="btn_tertiary" disabled={!var_ready} onClick={onClick_zoomin_image}><img src={"/icons/zoomin 30px (29719a).svg?ts=" + var_icon_ts} alt={t('zoom in icon')} /><label>{t("ZOOM-IN")}</label></Button>
                        <Button className="btn_tertiary" disabled={!var_ready} onClick={onClick_zoomout_image}><img src={"/icons/zoomout 30px (29719a).svg?ts=" + var_icon_ts} alt={t('zoom out icon')} /><label>{t("ZOOM-OUT")}</label></Button>
                        <Button className={"btn_tertiary"+(show_acknowledge ? " docviewer_rotate" : "")} disabled={!var_ready} onClick={onClick_rotate}><img src={"/icons/rotate 30px (29719a).svg?ts=" + var_icon_ts} alt={t('rotate page icon')} /><label>{t("ROTATE")}</label></Button>
                        {(var_file_info.scan_status === 'CLEAN') &&
                        <Button className="btn_tertiary" disabled={!var_ready} onClick={onClick_download}><img
                            src={"/icons/download 30px (4dcbd4).svg?ts=" + var_icon_ts}
                            alt="download"/><label>{t("DOWNLOAD")}</label></Button>
                        }
                        <div className="modal-footer-buttons">
                            {!show_acknowledge &&
                                <Button className='btn_secondary' onClick={() => set_mdl_open(false)}>{t('CLOSE')}</Button>
                            }
                            {targetid !== undefined && targetid !== null && classification !== 'credential' && show_acknowledge &&
                                <Button disabled={!var_ready} className="btn_primary btn_active" onClick={onClick_acknowledge}>{t('ACKNOWLEDGE')}</Button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function renderOtherToolbar() {
        return (
            <div className='modal-footer img-footer'>
                <div className="modal-footer-archive">
                    <div className="modal-footer-page-tools">
                        {(var_file_info.scan_status === 'CLEAN') &&
                        <Button className="btn_tertiary" disabled={!var_ready} onClick={onClick_download}><img
                            src={"/icons/download 30px (4dcbd4).svg?ts=" + var_icon_ts}
                            alt="download"/><label>{t("DOWNLOAD")}</label></Button>
                        }
                        <div className="modal-footer-buttons">
                            {!show_acknowledge &&
                                <Button className='btn_secondary' onClick={() => set_mdl_open(false)}>{t('CLOSE')}</Button>
                            }
                            {targetid !== undefined && targetid !== null && classification !== 'credential' && show_acknowledge &&
                                <Button disabled={!var_ready} className="btn_primary btn_active" onClick={onClick_acknowledge}>{t('ACKNOWLEDGE')}</Button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

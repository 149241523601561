import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import auth from '../../../../../../libs/auth-lib';

import CMP_CUSTOMSECURITY from '../../../../../../components/cmp_customsecurity/cmp_customsecurity';

import './tab_customsecurity.css';

export default function({ var_activetab, var_credential_id, set_mdl_open, populate_function }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const [ var_has_customsecurity, set_has_customsecurity ] = useState(false);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_activetab === 'CUSTOMSECURITY') {
            load_credential_info();
        }
    }, [ var_activetab ]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function load_credential_info() {
        if (var_activetab !== 'CUSTOMSECURITY') return; // Don't load data if tab is not visible

        set_has_customsecurity(false);
        let credentials = await API_get_org_credential();
        if (credentials[0] && credentials[0].has_customsecurity === 'YES') {
            set_has_customsecurity(true);
        }
    }

    async function togglesecurity(has_security) {
        await API_put_org_credential_has_security(has_security);
        set_has_customsecurity(has_security === 'YES');
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_credential() {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-credentials', '/get-org-credential/'+var_credential_id);
    }

    function API_put_org_credential_has_security(has_security) {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.put('org-credentials', '/put-org-credential-has-security/'+var_credential_id+'/'+has_security, {
            body: {
                organization_id: auth.organization_id,
                transactionby: auth.id,
                transactionorg: auth.organization_id
            }
        });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    async function onChange_userAccess(useraccess) {
        if (!useraccess.includes('read')) {
            populate_function && await populate_function();
            set_mdl_open(false);
        }
    }

    // RENDER APP ======================================================================================================

    return (
        <div className="modal-tab">
            <CMP_CUSTOMSECURITY
                targetentity='CREDENTIAL'
                targetentity_id={var_credential_id}
                modalinfo={{ display: 'CONTENT' }}
                togglesecurityfunction={togglesecurity}
                onChange_userAccess={onChange_userAccess}
                var_has_customsecurity={var_has_customsecurity}
                accessoptions={[
                    {code: 'read', description: 'Allows viewing of the credential (required)', isrequired: true},
                    {code: 'view attachment', description: 'Allows viewing of any supporting attachments to the credential'},
                    {code: 'write', description: 'Allows assigning the credential to an individual'},
                    {code: 'security', description: 'Allows configuring the security on the credential'}
                    ]} />
        </div>
    );
}
import { Button, Form, Modal } from 'semantic-ui-react'
import { API } from "aws-amplify";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import Moment from "moment";
import datelib from '../../../../libs/date-lib';
import auth from '../../../../libs/auth-lib';

import '../../../../i18n';
import './crd_questions.css';

import CRD_QUESTIONNAIRE from '../../../common/questionnaires/crd_questionnaire/crd_questionnaire.js';



export default function(){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();

    const history = useHistory();

    const [ var_questions, set_questions ] = useState([]);
    const [ var_check, set_check ] = useState([]);
    const [ var_check_ready, set_check_ready ] = useState(false);
    const [ var_check_processing, set_check_processing ] = useState(false);
    const [ var_onsite, set_onsite ] = useState(true);

    const [ var_templates_dd, set_templates_dd ] = useState([]);
    const [ var_templates_dd_value, set_templates_dd_value ] = useState('');
    const [ var_template_id, set_template_id ] = useState('');
    const [ var_dailylimit, set_dailylimit ] = useState(0);
    const [ var_location_id, set_location_id ] = useState('');
    const [ var_template, set_template ] = useState([]);
    const [ var_wrn_dailycheckconfirmation_open, set_wrn_dailycheckconfirmation_open ] = useState(false);
    const [ var_wrn_dailycheckconfirmation_processing, set_wrn_dailycheckconfirmation_processing ] = useState(false);

    const [ var_consent, set_consent ] = useState('');

    const [ var_complete, set_complete ] = useState(false);
    const [ var_passfail, set_passfail ] = useState('');
    const [ var_quarantinedays, set_quarantinedays ] = useState(0);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        populate_templates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        set_check_ready(var_complete);
    }, [var_complete]);

    useEffect(() => {
        if(var_check.status!=='' && var_check.status!==undefined) {
            if(var_template.failwarning === 'YES') {
                if (var_check.status === 'FAIL') {
                    set_check_processing(false);
                    set_wrn_dailycheckconfirmation_open(true);
                } else {
                    insert_dailycheck();
                }
            }else{
                insert_dailycheck();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_check.status, var_template.failwarning]);

    useEffect(()=>{
        if(var_template_id !== '') {
            set_check_ready(false);
            populate_dailylimit();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_location_id]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_templates(){
        try {
            let templates_dd = await API_get_org_templates_dd();
            if(templates_dd[0]) {
                set_templates_dd_value(templates_dd[0]['value']);
                set_templates_dd(templates_dd);
                set_template_id('');
                set_location_id('');
                let temp_ids=templates_dd[0]['value'].split('.');
                set_template_id(temp_ids[0]);
                if(temp_ids[1] === 'L'){
                    set_location_id(temp_ids[2]);
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    async function populate_dailylimit(){
        try {
            let dailylimit = await API_get_dailylimit();
            console.log(dailylimit[0]['checks']);

            if(dailylimit[0]['checks'] > 0){
                set_dailylimit(dailylimit[0]['checks']);
            }else{
                set_dailylimit(0);
                populate_template();
                populate_questions();
            }

        } catch (e) {
            console.log(e);
        }
    }

    async function populate_template(){
        try {
            let template = await API_get_template();
            set_template(template[0]);
        } catch (e) {
            console.log(e);
        }
    }

    async function populate_questions(){
        try {
            let questions = await API_get_questions();
            set_questions(questions);
        } catch (e) {
            console.log(e);
        }
    }

    async function insert_dailycheck(){
        if(!var_template_id) {
            return;
        }

        set_check_processing(true);
        set_wrn_dailycheckconfirmation_processing(true);
        try {
            await API_post_dailycheck();

            await API_post_onsite();

            set_check_ready(false);
            set_check_processing(false);
            set_wrn_dailycheckconfirmation_processing(false);
            set_check([]);
            history.push("/indv/wallet");
        } catch (e) {
            console.log(e);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_templates_dd(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        if(!sessionStorage.getItem('qrdata')){
            return API.get('indv-dailychecks', '/get-dd-indv-questionnaires/'+auth.id+'?qrlocation_id=');
        }
        if(JSON.parse(sessionStorage.getItem('qrdata')).qrlocation_id !== '-'){
            return API.get('indv-dailychecks', '/get-dd-indv-questionnaires/'+auth.id+'?qrlocation_id='+JSON.parse(sessionStorage.getItem('qrdata')).qrlocation_id);
        }
    }

    function API_get_dailylimit(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('indv-dailychecks', '/get-indv-dailycheck-dailylimit/'+auth.id+'?location_id='+var_location_id+'&tz='+datelib.querystring_timezone);
    }

    function API_get_template(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-dailychecks', '/get-org-questionnaire/'+var_template_id);
    }

    function API_get_questions(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-dailychecks', '/get-org-questions/'+var_template_id);
    }

    function API_post_dailycheck(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('indv-dailychecks', '/post-indv-dailycheck', { body: var_check });
    }

    function API_post_onsite(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-onsites', '/post-indv-onsite', { body: var_check });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_teamplate_dd(e, { value }){
        let temp_ids=value.split('.');
        set_template_id('');
        set_location_id('');
        set_template_id(temp_ids[0]);
        if(temp_ids[1] === 'L'){
            set_location_id(temp_ids[2]);
        }
        set_templates_dd_value(value);
    }

    function answer_consent(consent){
        set_consent(consent);
        set_check_ready(consent === 'NO');
    }

    async function submitcheck(){
        if(var_check_ready && var_template_id) {
            const check = {...var_check};

            check['status'] = var_passfail;
            check['quarantinedays'] = var_quarantinedays;

            if(var_onsite) {
                check['onsite'] = 'YES';
            }else{
                check['onsite'] = 'NO';
            }

            if(var_consent === 'NO') {
                check['consent'] = 'NO';
            }else{
                check['consent'] = '';
            }

            check['firstname'] = auth.firstname;
            check['lastname'] = auth.lastname;
            check['email'] = auth.email;
            check['individual_id'] = auth.id;
            check['organization_id'] = auth.organization_id;
            check['transactionby'] = auth.id;
            check['transactionorg'] = auth.organization_id;
            check['template_id']=var_template_id;
            check['location_id']=var_location_id;
            check['tz'] = datelib.utc_offset;

            set_check(check);
        }
    }

    function canceldailycheck(){
        const check = { ...var_check };
        check['status'] = '';
        set_check(check);
        set_wrn_dailycheckconfirmation_open(false);
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="content-card" id="crd_indv_dailycheck">

                <div className="content-card-header">
                    <div className="content-card-header-title">{t('Daily Check Questions')}: {var_template.name}</div>
                </div>
                <div className="content-filter">
                    <Form>
                        <Form.Group>
                            <Form.Select
                                className='templates'
                                id='templates'
                                name='templates'
                                label={t('QUESTIONS')}
                                search
                                options={var_templates_dd}
                                value={var_templates_dd_value || ''}
                                onChange={onChange_teamplate_dd}
                                placeholder={t('Select a questionnaire...')}
                            />
                        </Form.Group>
                    </Form>
                </div>

                {var_dailylimit === 0 ?
                    <>
                        {var_template_id &&
                            <div className="content-card-content">
                                {var_template.guestconsent === 'YES' && auth.individualtype_guest &&
                                <div className="dailycheck-question">
                                    <div className="dailycheck-question-wrapper">
                                        <div className="dailycheck-question-category">{t('GUEST CONSENT')}</div>
                                        <div className="dailycheck-question-text">{var_template.guestconsenttext}</div>
                                    </div>
                                    <div className="dailycheck-question-answer">
                                        <div
                                            className={"dailycheck-question_answer_yes" + (var_consent === 'YES' ? ' answer_active' : '')}
                                            onClick={() => answer_consent('YES')}>{t('YES')}</div>
                                        <div
                                            className={"dailycheck-question_answer_no" + (var_consent === 'NO' ? ' answer_active' : '')}
                                            onClick={() => answer_consent('NO')}>{t('NO')}</div>
                                    </div>
                                </div>
                                }
                                {((var_template.guestconsent === 'YES' && var_consent === 'YES') || var_template.guestconsent !== 'YES' || !auth.individualtype_guest) &&
                                <CRD_QUESTIONNAIRE 
                                    var_questions={var_questions}
                                    var_indv_type={auth.individual_type}
                                    set_complete={set_complete}
                                    set_passfail={set_passfail}
                                    set_quarantinedays={set_quarantinedays}
                                />
                                }
                                {auth.org_r2w &&
                                <div className="onsite">
                                    <div className="onsite_header">
                                        <div className="onsite_title">{t('Onsite Declaration')}</div>
                                        <div className="onsite_subtitle">{t('for')} {Moment().format('ll')}</div>
                                    </div>
                                    <div className='dailycheck-question'>
                                        <div className="dailycheck-question-wrapper">
                                            <div className="dailycheck-question-text">{t('I am working onsite today')}</div>
                                        </div>
                                        <div className="dailycheck-question-answer">
                                            <div
                                                className={"dailycheck-question_answer_yes" + (var_onsite ? ' answer_active' : '')}
                                                onClick={() => set_onsite(true)}>{t('YES')}</div>
                                            <div
                                                className={"dailycheck-question_answer_no" + (!var_onsite ? ' answer_active' : '')}
                                                onClick={() => set_onsite(false)}>{t('NO')}</div>
                                        </div>
                                    </div>
                                </div>
                                }

                                <div
                                    className="certify">{t('I understand that my answers above could have a direct effect on the health and welfare of my colleagues and others onsite today.')}</div>
                                {auth.organization_id === 'E781F687-E755-45A8-9420-63A7A0E1E55F' &&
                                    <>
                                        <div className="certify">{t('Privacy Notice: The Aware360 application asks everyone to respond individually “Yes” or “No” to the series of questions above, based on our criteria for attending the office or special event.  Your responses to each of these questions are not individually stored or transmitted to Nasdaq in any way.  Aware360 only transmits the aggregate outcome of your cumulative responses (“green” or “red”) to Nasdaq, which determines whether you can attend the office/event or not.')}</div>
                                        <div className="certify">{t('To the extent that the information contained herein constitutes sensitive or special categories of personal data under applicable law, your submission of this form indicates that you hereby provide your explicit consent to our processing of this information for the limited purposes of providing for the health, safety, and welfare of Nasdaq employees, vendors and visitors in response to the coronavirus outbreak, which has been declared a global health emergency by the World Health Organization. The information provided may be processed by our vendors and affiliates for these purposes and, if required by applicable law or to the extent Nasdaq deems necessary in response to this virus outbreak, such information may be provided to government or other health agencies. This information will not be retained longer than necessary for these purposes. Please note, that if you do not provide your explicit consent to our processing of this information, you may be barred from visiting Nasdaq facilities during this outbreak.')}</div>
                                    </>
                                }
                                {(auth.organization_id === 'FD794D9A-95AC-4DF5-B280-5C71846C3960' ||
                                auth.organization_id === 'FB7CE330-CCE7-4A11-B50F-42F8AAB0B278') &&
                                <div className="footer">
                                    <p><b>By submitting this document, I acknowledge that the CANA COVID-19 expectations and
                                        provisions outlined below have been explained to me and I agree to adhere to them,
                                        specifically:</b></p>
                                    <p>1. All projects and work sites must provide access to hand washing or hand sanitizing
                                        stations.</p>
                                    <p>2. All frequently touched objects and surfaces must be cleaned and disinfected.</p>
                                    <p>3. Physical Distancing requirements of 2 meters/6 feet must be observed.</p>
                                    <p>4. Limit size of gatherings - conduct meetings in spaces that allow for physical
                                        distancing between meeting attendees and conduct virtual meetings whenever
                                        possible.</p>
                                    <p>5. Remind everyone to stay home when they are sick and report any confirmed COVID-19
                                        diagnosis to CANA immediately.</p>
                                    <p>6. Communicate and adhere to basic illness prevention hygiene - post all required
                                        CANA COVID-19 signage and posters.</p>
                                    <p><b>I understand that the CANA has implemented policies and protocols in order to
                                        mitigate the spread of COVID-19. I have read and agree to abide by CANA’s policies
                                        and protocols for COVID-19 at all times while on CANA worksites, including head
                                        office. <a
                                            href="/assets/CANA COVID-19 Response Plan - 2020-12-17.pdf">CANA
                                            COVID-19 Response Plan</a></b></p>
                                    <p><b>By submitting this COVID declaration, I confirm that I have been provided access
                                        to, and understand the contents of, the COVID-19 hazard assessments pertaining to
                                        the CANA jobsite(s) where I am working, including head office as applicable, and
                                        that I agree to follow the outlined control measures at all times.</b></p>
                                </div>
                                }
                                {auth.organization_id === 'E781F687-E755-45A8-9420-63A7A0E1E55F' &&
                                <div className="privacypolicy">
                                    <div
                                        className="privacypolicy-item">{t('For employees / consultants: Personal data is processed in accordance with the relevant employee privacy notice posted ')}
                                        <a
                                            href="https://www.nasdaqomxintranet.com/Working+Here/Other+Policies+%26+Guidelines/Global+Policy+Directory/Associate+Privacy+Information/"
                                            target="_blank" rel='noopener noreferrer' >{t('here')}.</a>
                                    </div>
                                    <div
                                        className="privacypolicy-item">{t('For non-employees: Personal data is processed in accordance with the relevant non-employee privacy notice posted ')}
                                        <a
                                            href="https://www.nasdaq.com/privacy-statement" target="_blank" rel='noopener noreferrer' >{t('here')}.</a>
                                    </div>
                                </div>
                                }
                            </div>
                        }
                        <div className="content-card-footer">
                            <div className="content-card-footer-buttons">
                                {!var_check_processing ?
                                    <Button className={"btn_primary " + (var_check_ready ? 'btn_active' : '')}
                                            disabled={!var_template_id}
                                            onClick={() => submitcheck()}>{t('SUBMIT DAILY CHECK')}</Button>
                                    :
                                    <Button loading
                                            className="btn_primary btn_active">{t('SUBMIT DAILY CHECK')}</Button>
                                }
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="message warning">
                            <div className="message_icon"><img src={"/icons/warning 60px (d91e18).svg?ts=" + Date.now()} alt={t('warning icon')} /></div>
                            <div className="message_text_wrapper">
                                {var_location_id !== '' &&
                                <div className="message_text cause">{t('You have already submitted a daily check for this location/project today')}</div>
                                }
                            </div>
                        </div>
                    </>
                }
            </div>


            {/***** MODAL: DAILY CHECK CONFIRMATION ******************************************************************/}

            <Modal id="wrn_dailycheckconfirmation"
                   dimmer={'inverted'}
                   open={var_wrn_dailycheckconfirmation_open}
                   onClose={() => set_wrn_dailycheckconfirmation_open(false)}
            >
                <div className="modal-header">{t('Daily check confirmation')}</div>
                <div className="modal-content">
                    <p>{var_template.failwarningtext}</p>
                </div>
                <div className="modal-footer">
                    <div className="modal-footer-buttons">
                        <Button className="btn_secondary" onClick={() => canceldailycheck()}>{t('NO')}</Button>
                        {!var_wrn_dailycheckconfirmation_processing ?
                            <Button className="btn_primary btn_active" onClick={() => insert_dailycheck()}>{t('YES')}</Button>
                            :
                            <Button loading className="btn_primary btn_active">{t('YES')}</Button>
                        }
                    </div>
                </div>
            </Modal>

            {/***** END MODAL: DAILY CHECK CONFIRMATION **************************************************************/}

        </>
    )

}

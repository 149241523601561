import React from "react";
import './notfound.css';
import { useTranslation } from 'react-i18next';
import '../../i18n';


export default function NotFound() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    return (
        <div className="NotFound">
            <h3>{t('Sorry, page not found!')}</h3>
        </div>
    );
}
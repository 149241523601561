import {Modal, Popup, Table} from 'semantic-ui-react'
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import datelib from '../../../../libs/date-lib';

import { useTranslation } from 'react-i18next';
import '../../../../i18n';

import CMP_TABLECONTROLS, { filter_helper } from "../../../../components/cmp_tablecontrols/cmp_tablecontrols";
import MDL_HISTORY from "../../../../components/cmp_history/cmp_history";

import './crd_dailycheck.css';


export default function({var_activetab}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();
    const [ var_indv_dailychecks, set_indv_dailychecks] = useState({});
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(100);
    const [ var_offset, set_offset] = useState(0);
    const [ var_search, set_search ] = useState('');
    const [ var_sortby, set_sortby ] = useState('issuedepoch');
    const [ var_sortorder, set_sortorder ] = useState('descending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));

    const [ var_mdl_history_open, set_mdl_history_open ] = useState(false);
    const [ var_modaltitle, set_modaltitle ] = useState('');
    const [ var_target_id, set_target_id ] = useState('');



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_activetab === 'DAILYCHECKHISTORY') {
           set_filter(filter_helper.initialize(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_activetab]);

    useEffect(()=>{
        if (var_filter.load){
            populate_indv_dailychecks(null,0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_filter])

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_indv_dailychecks(limit,offset,sortby,sortorder,search,filter){

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        if(search) {
            if(search === '-') {
                search = '';
            }
            set_search(search);
        }else{
            search=var_search
        }

        filter = filter ? filter : var_filter;

        try {
            let indv_dailychecks = await API_get_dailychecks(limit,offset,sortby,sortorder,search,filter);
            set_indv_dailychecks(indv_dailychecks);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);

    }

    async function populate_filters(filtername,sortorder){
        try {
            return await API_get_dailychecks(filter_helper.maxFilterItems,0,filtername,var_search,sortorder,{},filtername);
            } catch (e) {
            console.log(e);
            }
    }

    async function download_indv_dailychecks(limit, offset){
        try {
        return await API_get_dailychecks(limit,offset,var_sortby,var_sortorder,var_search,var_filter);
        } catch (e){
        console.log(e);
        throw e;
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_dailychecks(limit,offset,sortby,sortorder,search,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
       return API.post('indv-dailychecks',
                      '/get-indv-dailychecks/'+JSON.parse(sessionStorage.getItem('authentication')).id,
                         {
                            queryStringParameters: {
                                limit: limit,
                                offset: offset,
                                sortby: sortby,
                                sortorder: sortorder,
                                search: search,
                                tz: datelib.timezone,
                                filtername: filtername
                            },
                            body: filter
                         }
                     );
    }





    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_indv_dailychecks('',0,sortby,sortorder)
    }

    function onOpen_mdl_history(var_dailycheck_id, issued ) {
        set_modaltitle(t('Daily Check History -')+datelib.date_localized_format(issued));
        set_target_id(var_dailycheck_id);
        set_mdl_history_open(true)
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="content-card-noheader" id="tab_indv_overview_dailycheck">
                <div className="content-card-gridcontent">
                    <div className="content-card-header mobile_tab_name">
                        {t('Daily Check History')}
                    </div>
                    <div className="indv-dailychecks">
                        <div className="content-filter">
                            <div className="content-filter-item item-pagination">
                                <CMP_TABLECONTROLS
                                    var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                                    total_rows={var_indv_dailychecks && var_indv_dailychecks.length > 0 ? var_indv_dailychecks[0]['totalrows'] : 0} populatefunction={populate_indv_dailychecks}
                                    downloadname='Daily Checks' downloadfunction={download_indv_dailychecks}
                                    var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                                    table_config={[
                                        {name: 'status', datatype: 'text', labelKey: 'STATUS', download: true, filter: true},
                                        {name: 'issued', datatype: 'text', labelKey: 'ISSUED', download: true, filter: false},
                                        {name: 'issuedepoch', datatype: 'date', labelKey: 'ISSUED', download: false, filter: true},
                                        {name: 'name', datatype: 'text', labelKey: 'QUESTIONNAIRE', download: true, filter: true},
                                        {name: 'location_name', datatype: 'text', labelKey: 'LOC/PROJ', download: true, filter: true},
                                        {name: 'mgr_ovrd', datatype: 'text', labelKey: 'MANAGER OVERRIDE', download: true, filter: true}
                                    ]}
                                />
                            </div>
                        </div>
                        <div className="tablewrapper">
                            <Table unstackable sortable compact className={(var_loading ? " tbl_loading" : "")}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell className="td_icon_center" sorted={var_sortby === 'status' ? var_sortorder : null} onClick={() => onClick_sort('status')}>{t('STATUS')}</Table.HeaderCell>
                                        <Table.HeaderCell sorted={var_sortby === 'issuedepoch' ? var_sortorder : null} onClick={() => onClick_sort('issuedepoch')}>{t('ISSUED')}</Table.HeaderCell>
                                        <Table.HeaderCell className="td_template" sorted={var_sortby === 'name' ? var_sortorder : null} onClick={() => onClick_sort('name')}>{t('QUESTIONNAIRE')}</Table.HeaderCell>
                                        <Table.HeaderCell className="td_location" sorted={var_sortby === 'location_name' ? var_sortorder : null} onClick={() => onClick_sort('location_name')}>{t('LOC/PROJ')}</Table.HeaderCell>
                                        <Table.HeaderCell className="td_manageroverride" sorted={var_sortby === 'mgr' ? var_sortorder : null} onClick={() => onClick_sort('mgr')}>{t('MANAGER OVERRIDE')}</Table.HeaderCell>
                                        <Table.HeaderCell className="td_history">{t('HSTRY')}</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                {var_ready && var_indv_dailychecks[0]['totalrows'] !== 0 &&
                                <Table.Body>
                                    {var_indv_dailychecks.map((item, i) =>
                                        <Table.Row key={i}>
                                            <Table.Cell className="td_icon_center">
                                                {item.status === 'PASS' &&
                                                <img src={"/icons/checkmark 30px (69b536).svg?ts=" + Date.now()} alt={t('pass icon')}/>
                                                }
                                                {item.status === 'FAIL' &&
                                                <img src={"/icons/x 30px (e0454f).svg?ts=" + Date.now()} alt={t('fail icon')}/>
                                                }
                                            </Table.Cell>
                                            <Table.Cell>{datelib.datetime_localized_format(item.issued)}</Table.Cell>
                                            <Table.Cell className="td_template">{item.name}</Table.Cell>
                                            <Table.Cell className="td_location">{item.location_name}</Table.Cell>
                                            <Table.Cell className="td_manageroverride">{item.mgr && <Popup
                                                trigger={<img className="td_icon_incell" src={"/icons/chat 30px (bcbebe).svg?ts=" + Date.now()} alt={t('chat icon')}/>}
                                                flowing
                                                hoverable
                                                basic>
                                                <div className="pop_admin">
                                                    {item.mgr_note}
                                                </div>
                                            </Popup>}{item.mgr}</Table.Cell>
                                            <Table.Cell className="td_icon_center td_history"
                                                        onClick={() => onOpen_mdl_history(item.id, item.issued)}><img
                                                src={"/icons/history 30px (bcbebe).svg?ts=" + Date.now()}
                                                alt={t('archive icon')}></img></Table.Cell>
                                        </Table.Row>
                                    )}
                                </Table.Body>
                                }
                            </Table>
                            {var_ready && var_indv_dailychecks[0]['totalrows'] === 0 &&
                                <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t('alert icon')}/>{t('there are no daily checks to display')}</div>
                            }
                        </div>
                        <div className="content-filter">
                            <div className="content-filter-item item-pagination">
                                <CMP_TABLECONTROLS
                                    var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                                    total_rows={var_indv_dailychecks && var_indv_dailychecks.length > 0 ? var_indv_dailychecks[0]['totalrows'] : 0} populatefunction={populate_indv_dailychecks}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            {/***** MODAL: HISTORY *******************************************************************************/}

            <Modal id="mdl_history"
                   dimmer={'inverted'}
                   open={var_mdl_history_open}
                   onClose={() => set_mdl_history_open(false)}>
                <MDL_HISTORY
                    set_mdl_open={set_mdl_history_open}
                    var_modaltitle={var_modaltitle}
                    var_target_id={var_target_id}>
                </MDL_HISTORY>
            </Modal>

            {/***** END MODAL: DETAILS ***************************************************************************/}



        </>
    )

}

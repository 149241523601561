import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { API } from 'aws-amplify';
import { Modal, Form, Checkbox, Button, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import '../../i18n';
import auth from '../../libs/auth-lib';

import CMP_TABLECONTROLS, { filter_helper } from '../cmp_tablecontrols/cmp_tablecontrols';
import MDL_EDIT from './mdl_edit/mdl_edit';
import MDL_CONFIRMATION from '../cmp_confirmation/cmp_confirmation';

import './cmp_customsecurity.css';

function CMP_CUSTOMSECURITY ({ targetentity, targetentity_id, modalinfo, togglesecurityfunction, onChange_userAccess, var_has_customsecurity, accessoptions }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_user_hasaccess, set_user_hasaccess ] = useState(false);
    const [ var_useraccess, set_useraccess ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_records, set_records ] = useState(null);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_display_last_security_error, set_display_last_security_error ] = useState(false);
    const [ var_limit, set_limit] = useState(10);
    const [ var_offset, set_offset] = useState(0);
    const [ var_sortby, set_sortby ] = useState('individual');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));
    const [ var_editrecord_id, set_editrecord_id ] = useState(null);
    const [ var_mdl_edit_open, set_mdl_edit_open ] = useState(false);
    const [ var_mdl_confirmation_open, set_mdl_confirmation_open ] = useState(false);

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_has_customsecurity) {
            populate_records();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_has_customsecurity]);

    useEffect(()=>{
        if (var_filter.load){
            populate_records(null,0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_filter]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_records(limit,offset,sortby,sortorder,filter){
        if (!var_has_customsecurity) return;

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_custom_security_records(limit,offset,sortby,sortorder,filter);
            set_totalrows(results.totalrows);
            set_records(results.results);
            set_user_hasaccess(true);
            set_useraccess_from_populate(results.useraccess);
        } catch (e) {
            if (e.response && e.response.status === 401) {
                set_user_hasaccess(false);
                set_useraccess_from_populate(e.response.data.useraccess);
            } else {
                console.log(e);
            }
        }
        set_loading(false);
    }

    async function populate_filters(filtername,sortorder){
        try {
            return (await API_get_custom_security_records(filter_helper.maxFilterItems,0,filtername,sortorder,{},filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_results(limit,offset){
        try {
            return (await API_get_custom_security_records(limit,offset,var_sortby,var_sortorder,var_filter)).results;
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    async function delete_record(id) {
        set_processing(true);
        await API_delete_custom_security(id);
        // repopulate records.  If user was on the last page and the only item on the page was removed then display the previous page
        if (var_offset < (var_totalrows - 1) || var_offset === 0) {
            await populate_records();
        } else {
            await populate_records(null, var_offset - var_limit);
        }
        set_processing(false);
    }

    function set_useraccess_from_populate(useraccess) {
        let oldaccess = var_useraccess;
        set_useraccess(useraccess);
        let changesexist = oldaccess.length !== useraccess.length;
        oldaccess.forEach(item => changesexist = (changesexist || !useraccess.includes(item)));
        changesexist && onChange_userAccess && onChange_userAccess(useraccess);
    }

    function transaction_info() {
        return {
            transactionby: auth.id,
            transactionorg: auth.organization_id
        };
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_custom_security_records(limit,offset,sortby,sortorder,filter,filtername) {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org', '/get-custom-security-records/'+targetentity+'/'+targetentity_id+'/'+auth.organization_id,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername
                },
                body: filter
            }
        );
    }

    function API_delete_custom_security(id) {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.del('org', '/delete-custom-security/'+id, { body: transaction_info() });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        } else {
            sortorder = sortorder === 'ascending' ? 'descending' : 'ascending';
        }
        populate_records('',0,sortby,sortorder)
    }

    async function onChange_has_customsecurity() {
        if (var_has_customsecurity) {
            set_mdl_confirmation_open(true);
        } else {
            togglesecurityfunction && await togglesecurityfunction('YES');
        }
    }

    function onClick_editrecord(id) {
        set_editrecord_id(id);
        set_mdl_edit_open(true);
    }

    function onClick_removerecord(event, id, is_only_security_access) {
        event.stopPropagation();
        if (is_only_security_access === 'NO') {
            delete_record(id);
        } else {
            set_display_last_security_error(true);
            setTimeout(() => set_display_last_security_error(false), 2500);
        }
    }

    // RENDER APP ======================================================================================================

    return (
        <div className='cmp_customsecurity-container'>
            {modalinfo.display === 'FULL' ? render_modal_full() : modalinfo.display === 'CONTENT' ? render_modal_content() : render_content()}

            {/***** MODAL: EDIT ***************************************************************************************/}

            <Modal id='mdl_edit'
                   dimmer={'inverted'}
                   open={var_mdl_edit_open}
                   onClose={() => set_mdl_edit_open(false)}>
                <MDL_EDIT
                    var_mdl_open={var_mdl_edit_open}
                    set_mdl_open={set_mdl_edit_open}
                    targetentity={targetentity}
                    targetentity_id={targetentity_id}
                    customsecurity_id={var_editrecord_id}
                    accessoptions={accessoptions}
                    refreshfunction={populate_records} />
            </Modal>

            {/***** END MODAL: EDIT ***********************************************************************************/}

            {/***** MODAL: CONFIRMATION *******************************************************************************/}

            <Modal id='mdl_confirmation'
                   dimmer={'inverted'}
                   open={var_mdl_confirmation_open}
                   onClose={() => set_mdl_confirmation_open(false)}>
                <MDL_CONFIRMATION
                    set_mdl_open={set_mdl_confirmation_open}
                    var_modaltitle={t('Remove Security')}
                    var_message={t('Are you sure you want to remove security from this object')}
                    confirmation_function={() => togglesecurityfunction('NO')}
                    var_call_to_function={true} />
            </Modal>

            {/***** END MODAL: CONFIRMATION **************************************************************************/}
        </div>
    );

    function render_modal_full() {
        return (
            <>
                <div className='modal-header'><div className='modal-header-title'>{modalinfo.title}</div>
                    <div className='modal-header-close' onClick={() => modalinfo.set_mdl_open(false)}><img src={'/icons/x 60px (717473).svg?ts=' + Date.now()} alt={t('x icon')} /></div>
                </div>
                {render_modal_content()}
            </>
        );
    }

    function render_modal_content() {
        return (
            <>
                <div className='modal-content'>
                    {render_content()}
                </div>
                {var_has_customsecurity && var_user_hasaccess &&
                <div className='modal-footer'>
                    <div className='modal-footer-buttons'>
                        <Button className='btn_primary btn_active' onClick={() => onClick_editrecord()}>{t('ADD SECURITY')}</Button>
                    </div>
                </div>
                }
            </>
        );
    }

    function render_content() {
        return (
            <>
                <div className='has_customsecurity_checkbox'>
                    <Form>
                        <Form.Group>
                            <Form.Field>
                                <Checkbox
                                    label={t('Has Security')}
                                    onChange={onChange_has_customsecurity}
                                    checked={var_has_customsecurity || false}
                                    toggle
                                    readOnly={var_has_customsecurity && !var_user_hasaccess}
                                />
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </div>
                {var_has_customsecurity && var_user_hasaccess && render_security_records()}
            </>
        );
    }

    function render_security_records() {
        return (
            <>
                {modalinfo.display === 'NONE' &&
                <div className='content-card-header'>
                    <Button className='btn_tertiary' onClick={() => onClick_editrecord()}><img className='btn_icon' src={'/icons/add 30px (4dcbd4).svg?ts=' + Date.now()} alt={t('add icon')}/>{t('ADD SECURITY')}</Button>
                </div>
                }
                <div className='content-filter'>
                    <div className='content-filter-item item-pagination'>
                        <CMP_TABLECONTROLS
                            var_limit={var_limit} var_offset={var_offset} var_ready={var_records !== null} var_loading={var_loading}
                            total_rows={var_totalrows} populatefunction={populate_records}
                            downloadname='Security' downloadfunction={download_results}
                            var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                            table_config={[
                                {name: 'individual', datatype: 'text', labelKey: 'INDIVIDUAL', download: true, filter: true},
                                {name: 'permission', datatype: 'text', labelKey: 'ROLE', download: true, filter: true},
                                {name: 'area', datatype: 'text', labelKey: 'LOC/PROJ', download: true, filter: true},
                                {name: 'assignedaccess', datatype: 'text', labelKey: 'ACCESS', download: true, filter: true}
                            ]}
                        />
                    </div>
                </div>
                <div className='tablewrapper'>
                    <Table sortable compact className={(var_loading ? 'tbl_loading' : '')}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell sorted={var_sortby === 'individual' ? var_sortorder : null} onClick={() => onClick_sort('individual')}>{t('INDIVIDUAL')}</Table.HeaderCell>
                                <Table.HeaderCell sorted={var_sortby === 'permission' ? var_sortorder : null} onClick={() => onClick_sort('permission')}>{t('ROLE')}</Table.HeaderCell>
                                <Table.HeaderCell sorted={var_sortby === 'area' ? var_sortorder : null} onClick={() => onClick_sort('area')}>{t('LOC/PROJ')}</Table.HeaderCell>
                                <Table.HeaderCell sorted={var_sortby === 'assignedaccess' ? var_sortorder : null} onClick={() => onClick_sort('assignedaccess')}>{t('ACCESS')}</Table.HeaderCell>
                                <Table.HeaderCell className='td_icon_center'></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        {var_records && var_records.length > 0 &&
                        <Table.Body>
                            {var_records.map((item) =>
                                <Table.Row key={item.id} onClick={() => onClick_editrecord(item.id)}>
                                    <Table.Cell>{item.individual}</Table.Cell>
                                    <Table.Cell>{item.permission}</Table.Cell>
                                    <Table.Cell>{item.area}</Table.Cell>
                                    <Table.Cell>{item.assignedaccess}</Table.Cell>
                                    <Table.Cell className='td_icon_center' onClick={(event) => onClick_removerecord(event, item.id, item.is_only_security_access)}><img src={'/icons/delete 18px (6B7280).svg?ts=' + Date.now()} alt={t('delete icon')}/></Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                        }
                    </Table>
                    {!var_loading && var_totalrows === 0 &&
                    <div className='emptytable'><img src={'/icons/alert 60px (e5e5e5).svg?ts=' + Date.now()} alt='alert icon'/>{t('there are no records to display')}</div>
                    }
                </div>
                <div className='content-filter'>
                    <div className='content-filter-item item-pagination'>
                        <CMP_TABLECONTROLS
                            var_limit={var_limit} var_offset={var_offset} var_ready={var_records !== null} var_loading={var_loading}
                            total_rows={var_totalrows} populatefunction={populate_records}
                        />
                    </div>
                </div>
                {var_display_last_security_error &&
                <div className="message warning">
                    <div className="message_icon"><img src={"/icons/warning 60px (d91e18).svg?ts=" + Date.now()} alt={t('warning icon')} /></div>
                    <div className="message_text_wrapper">
                        <div className="message_text cause">{t('Unable to remove the last security access')}</div>
                    </div>
                </div>}
            </>
        )
    }
}

CMP_CUSTOMSECURITY.propTypes = {
    targetentity: propTypes.string.isRequired,
    targetentity_id: propTypes.string.isRequired,
    modalinfo: propTypes.shape({
        display: propTypes.oneOf(['FULL', 'CONTENT', 'NONE']),
        var_mdl_open: propTypes.bool,
        set_mdl_open: propTypes.func,
        title: propTypes.string
    }),
    togglesecurityfunction: propTypes.func.isRequired,
    onChange_userAccess: propTypes.func,
    var_has_customsecurity: propTypes.bool.isRequired,
    accessoptions: propTypes.arrayOf(propTypes.shape({
        code: propTypes.string.isRequired,
        description: propTypes.string,
        isrequired: propTypes.bool
    })).isRequired
};
CMP_CUSTOMSECURITY.defaultProps = {
    modalinfo: {display: 'NONE'}
}

export default CMP_CUSTOMSECURITY;
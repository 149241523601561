import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import {Button, Modal, Table} from 'semantic-ui-react';
import Moment from 'moment';
import datelib from '../../../../../libs/date-lib';
import auth from '../../../../../libs/auth-lib';
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import CMP_TABLECONTROLS, {filter_helper} from '../../../../../components/cmp_tablecontrols/cmp_tablecontrols';
import CMP_CREDENTIALSTATUS from '../../../../../components/cmp_credentialstatus';
import CMP_LEGEND from '../../../../../components/cmp_legend/cmp_credential_legend/cmp_credential_legend';
import MDL_ADD from '../../../../indv/wallet/tab_credentials/mdl_add/mdl_add';
import MDL_CREDENTIAL_DETAILS from '../../../../common/credentials/mdl_credential_details/mdl_credential_details';
import MDL_DOCVIEWER from '../../../../../components/cmp_docviewer/cmp_docviewer';

import './tab_credentials.css';



export default function({ var_activetab, var_individual_id, var_indv_from_org }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_credentials, set_credentials ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(10);
    const [ var_offset, set_offset] = useState(0);
    const [ var_search, set_search ] = useState('');
    const [ var_sortby, set_sortby ] = useState('epochexpires');
    const [ var_sortorder, set_sortorder ] = useState('descending');
    const [ var_archive, set_archive ] = useState('NO');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));

    const [ var_target_id, set_target_id ] = useState('');

    const [ var_mdl_add_open, set_mdl_add_open ] = useState(false);
    const [ var_mdl_update_open, set_mdl_update_open ] = useState(false);

    const [ var_mdl_docviewer_open, set_mdl_docviewer_open ] = useState(false);
    const [ var_docviewerid, set_docviewerid ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_activetab === 'CREDENTIALS' && var_archive !== undefined) {
            set_filter(filter_helper.initialize(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_activetab, var_archive]);

    useEffect(()=>{
        if (var_filter.load){
            populate_credentials(null,0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_filter]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_credentials(limit,offset,sortby,sortorder,search,archive,filter){
        if (var_activetab !== 'CREDENTIALS') return; // Don't load data if tab is not visible

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        if(search) {
            if(search === '-') {
                search = '';
            }
            set_search(search);
        }else{
            search=var_search
        }
        if(archive) {
            set_archive(archive);
        }else{
            archive=var_archive
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_indv_credentials(limit,offset,sortby,sortorder,search,archive,filter);
            set_credentials(results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername,sortorder){
        try {
            return await API_get_indv_credentials(filter_helper.maxFilterItems,0,filtername,sortorder,var_search,var_archive,[],filtername);
        } catch (e) {
            console.log(e);
        }
    }

    async function download_indv_credentials(limit, offset){
        try {
            return await API_get_indv_credentials(limit, offset, var_sortby, var_sortorder, var_search, var_archive, var_filter);
        } catch (e) {
            console.log(e);
            throw e;
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_indv_credentials(limit,offset,sortby,sortorder,search,archive,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('indv-credentials',
            '/get-indv-credentials/'+var_individual_id,
            {
                queryStringParameters: {
                    limit : limit,
                    offset : offset,
                    sortby : sortby,
                    sortorder : sortorder,
                    search : search,
                    archive : archive,
                    tz: datelib.timezone,
                    filtername : filtername
                },
                body: filter
            }
        );

    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_credentials('',0,sortby,sortorder)
    }

    function onClick_paperclip(id){
        set_docviewerid(id);
        set_mdl_docviewer_open(true);
    }

    function onClick_update(var_target_id){
        if (var_indv_from_org && auth.has_access('ORG-INDV', 'write')) {
            set_target_id(var_target_id);
            set_mdl_update_open(true)
        }
    }

    function onClick_addcredential(){
        set_mdl_add_open(true);
    }



    // RENDER APP ======================================================================================================

    return (
        <div className="modal-tab" id="tab_org_indv_credentials">
            <div className="content-filter">
                <div className="content-filter-archive">
                    {var_archive === 'NO' ?
                        <Button className="btn_tertiary" onClick={() => set_archive('YES')}><img className="btn_icon" src={"/icons/archive 30px (4dcbd4).svg?ts=" + Date.now()} alt={t("archive icon")} />{t('VIEW ARCHIVED CREDENTIALS')}</Button>
                        :
                        <Button className="btn_tertiary" onClick={() => set_archive('NO')}><img className="btn_icon" src={"/icons/checkmark 30px (4dcbd4).svg?ts=" + Date.now()} alt={t("active credential icon")}/>{t('VIEW ACTIVE CREDENTIALS')}</Button>
                    }
                </div>
                <div className="content-filter-item item-pagination">
                    <CMP_TABLECONTROLS
                        var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                        total_rows={var_credentials && var_credentials.length > 0 ? var_credentials[0]['totalrows'] : 0} populatefunction={populate_credentials}
                        downloadname='Individual Credentials' downloadfunction={download_indv_credentials}
                        var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                        table_config={[
                            {name: 'credential', datatype: 'text', labelKey: 'CREDENTIAL', download: true, filter: true},
                            {name: 'issuedby', datatype: 'text', labelKey: 'ISSUED BY', download: true, filter: true},
                            {name: 'issued', datatype: 'text', labelKey: 'ISSUED', download: true},
                            {name: 'epochissued', datatype: 'date', labelKey: 'ISSUED', filter: true},
                            {name: 'expires', datatype: 'text', labelKey: 'EXPIRES', download: true},
                            {name: 'epochexpires', datatype: 'date', labelKey: 'EXPIRES', filter: true},
                            {name: 'daysleft', datatype: 'int', labelKey: 'DAYS LEFT', download: true, filter: true},
                            {name: 'submittedfilename', datatype: 'text', labelKey: 'FILENAME', download: true, filter: true},
                            {name: 'confirmation_status', datatype: 'text', labelKey: 'VERIFICATION', download: true, filter: true}
                        ]}
                    />

                </div>
            </div>
            <div className="tablewrapper">
                <Table sortable compact className={(var_loading ? " tbl_loading" : "")}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell sorted={var_sortby === 'credential' ? var_sortorder : null} onClick={() => onClick_sort('credential')}>{t("CREDENTIAL")}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'issuedby' ? var_sortorder : null} onClick={() => onClick_sort('issuedby')}>{t("ISSUED BY")}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'epochissued' ? var_sortorder : null} onClick={() => onClick_sort('epochissued')}>{t("ISSUED")}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'epochexpires' ? var_sortorder : null} onClick={() => onClick_sort('epochexpires')}>{t("EXPIRES")}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'sortorder' ? var_sortorder : null} onClick={() => onClick_sort('sortorder')}>{t("DAYS LEFT")}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'filename' ? var_sortorder : null} onClick={() => onClick_sort('filename')}></Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'confirmation_status' ? var_sortorder : null} onClick={() => onClick_sort('confirmation_status')}>{t("VERIFICATION")}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    {var_ready && var_credentials[0]['totalrows'] !== 0 &&
                    <Table.Body>
                        {var_credentials.map((item, i) =>
                            <Table.Row key={i} className={(item.verification_status === 'VALID' && item.confirmation_status === 'SELF VERIFIED')  ? "self_verified" : ''}>
                                <Table.Cell className="td_overflow" onClick={() => onClick_update(item.id)}>{item.credential}</Table.Cell>
                                <Table.Cell onClick={() => onClick_update(item.id)}>{item.issuedby}</Table.Cell>
                                <Table.Cell onClick={() => onClick_update(item.id)}>{item.epochissued !== null && item.epochissued !== undefined && item.epochissued !== "" && item.epochissued !== 0 ? Moment.unix(item.epochissued).utc(false).format('ll'): ''}</Table.Cell>
                                <Table.Cell onClick={() => onClick_update(item.id)}>{item.epochexpires !== null && item.epochexpires !== undefined && item.epochexpires !== "" && item.epochexpires !== 0? Moment.unix(item.epochexpires).utc(false).format('ll') : ''}</Table.Cell>
                                <Table.Cell onClick={() => onClick_update(item.id)}>{item.daysleft}</Table.Cell>
                                <Table.Cell>
                                        {(item.filename !== null && item.filename !== '') ?
                                            <div className='td_attachment' onClick={() => onClick_paperclip(item.id)}> <img src={"/icons/paperclip 60px (717473).svg?ts=" + Date.now()} alt={t("attachment icon")}/></div>
                                            :
                                            <div className='td_attachment'></div>
                                        }
                                </Table.Cell>
                                <Table.Cell className="tbl_credentialicon">
                                    <CMP_CREDENTIALSTATUS confirmation_status={item.confirmation_status} verification_status={item.verification_status}
                                        clickfor_publiccert={true} id={item.id} has_customsecurity={item.has_customsecurity} />
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                    }
                </Table>
                {var_ready && var_credentials[0]['totalrows'] === 0 &&
                <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t("alert icon")}/>{t("there are no credentials to display")}</div>
                }
            </div>
            <div className="content-filter">
                <div className="content-filter-item item-pagination">
                    <CMP_TABLECONTROLS
                        var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                        total_rows={var_credentials && var_credentials.length > 0 ? var_credentials[0]['totalrows'] : 0} populatefunction={populate_credentials}
                    />
                </div>
            </div>
            {[auth.constants.permission_ids.Admin, auth.constants.permission_ids.GlobalAdmin, auth.constants.permission_ids.Superadmin].includes(auth.permission_id) &&
                <div className="modal-footer">
                    <div className="modal-footer-buttons">
                        <CMP_LEGEND />
                        {var_indv_from_org &&
                        <Button className="btn_primary btn_active" onClick={() => onClick_addcredential()}>{t("ADD CREDENTIAL")}</Button>
                        }
                    </div>
                </div>
            }


            {/***** MODAL: ADD ***************************************************************************************/}

            <Modal id="mdl_indv_addcredential"
                   dimmer={'inverted'}
                   open={var_mdl_add_open}
                   onClose={() => set_mdl_add_open(false)}
                   closeOnEscape={false}
                   closeOnDimmerClick={false}>
                <MDL_ADD
                    set_mdl_open={set_mdl_add_open}
                    var_individual_id={var_individual_id}
                    populate_function={populate_credentials}
                    is_org_side={true}>
                </MDL_ADD>
            </Modal>

            {/***** END MODAL: ADD ***********************************************************************************/}



            {/***** MODAL: UPDATE ************************************************************************************/}

            <MDL_CREDENTIAL_DETAILS
                var_mdl_open={var_mdl_update_open}
                set_mdl_open={set_mdl_update_open}
                var_target_id={var_target_id}
                populate_function={populate_credentials} />

            {/***** END MODAL: UPDATE ********************************************************************************/}


            {/***** MODAL: DOCUMENT VIEW ************************************************************************/}

            <Modal id="mdl_docviewer"
                   dimmer={'inverted'}
                   open={var_mdl_docviewer_open}
                   onClose={() => set_mdl_docviewer_open(false)}>
                <MDL_DOCVIEWER
                    set_mdl_open={set_mdl_docviewer_open}
                    targetid={var_docviewerid}
                    classification='credential'
                />
            </Modal>

            {/***** END MODAL: DOCUMENT VIEW *********************************************************************/}


        </div>
    )

};

import React, {useEffect, useState} from "react";
import { Modal } from 'semantic-ui-react';
import { API } from "aws-amplify";
import datelib from '../../../libs/date-lib';
import "./orgcertificate.css";
import { useTranslation } from 'react-i18next';
import '../../../i18n';


import MDL_DOCVIEWER from "../../../components/cmp_docviewer/cmp_docviewer";
import CMP_VERIFIABLEPRESENTATION_VIEWER from '../../../components/cmp_verifiablepresentationviewer/cmp_verifiablepresentationviewer';

export default function(appProps) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [var_certificate, set_certificate] = useState([]);
    const [var_certificate_ready, set_certificate_ready] = useState(false);
    const [var_courselogo_url, set_courselogo_url] = useState(null);
    const { t } = useTranslation();

    const [ var_mdl_docviewer_open, set_mdl_docviewer_open ] = useState(false);
    const [ var_mdl_verifiablepresentationviewer_open, set_mdl_verifiablepresentationviewer_open ] = useState(false);
    const [ var_vc, set_vc ] = useState(false);
    const [ var_vc_json, set_vc_json ] = useState({});



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        appProps.set_visibleframe(false);
        window.scrollTo(0, 0);
        populatecertificate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populatecertificate(){
        try {
            let certificate = await API_get_certificate();
            let logo = certificate.verifying_organizationlogo;
            certificate.verifying_organizationlogo = (logo && !logo.toLowerCase().startsWith('https://') && !logo.toLowerCase().startsWith('http://')) ?
                ((await API.endpoint('storage')) + '/logo/' + certificate.verifying_organization_id + '/' + logo) : logo;
            set_certificate(certificate);
            if (certificate.json?.includes('VerifiableCredential')) {  // is the JSON a VC?
                let vc_json = JSON.parse(certificate.json);
                set_vc(true);
                set_vc_json(vc_json);
            }
            set_courselogo_url((await API.endpoint('storage'))+'/course/');
            set_certificate_ready(true);
        } catch (e) {
            console.log(e);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_certificate(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-orgcredentials', '/get-public-credential/' +appProps.match.params.credentialid, {
            queryStringParameters: {
                tz : datelib.timezone
            }
        });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_document() {
        set_mdl_docviewer_open(true);
    }

    function onClick_verifiablecredential() {
        set_mdl_verifiablepresentationviewer_open(true);
    }

    // RENDER APP ======================================================================================================

    return(
        <div className="digitalcertificate_wrapper">
            <div className="content-wrapper">
                {var_certificate_ready &&
                    <>
                        <div className="content-card" id="digitalcertificate">
                            <div className="digitalcertificate_content">
                                <div className="digitalcertificate_content_left">
                                    <div className="digitalcertificate_header">
                                        {(var_certificate.verification_status === 'INVALID' || var_certificate.confirmation_status === 'EXPIRED')?
                                            <>
                                                <img className="digitalcertificate_icon invalid" src={"/icons/certificate warning 30px (e0454f).svg?ts=" + Date.now()} alt={t('warning icon')}/>
                                                <div className="digitalcertificate_title invalid">{var_certificate.confirmation_status}</div>
                                            </>
                                            :
                                            var_certificate.confirmation_status === 'PENDING' ?
                                                <>
                                                    <img className="digitalcertificate_icon pending" src={"/icons/certificate pending 30px (f2ae02).svg?ts=" + Date.now()} alt={t('pending icon')}/>
                                                    <div className="digitalcertificate_title pending">{var_certificate.confirmation_status}</div>
                                                </>
                                                :
                                                <>
                                                    <img className="digitalcertificate_icon" src={(var_certificate.verification_status === 'VALID' && var_certificate.confirmation_status === 'SELF VERIFIED') ? "/icons/certificate selfverified checkmark 30px (29719a).svg?ts=" + Date.now() : "/icons/certificate checkmark 30px (69b536).svg?ts=" + Date.now()} alt={t('active icon')}/>
                                                    <div className={"digitalcertificate_title" + ((var_certificate.verification_status === 'VALID' && var_certificate.confirmation_status === 'SELF VERIFIED') ? " self_verified" : "")}>{var_certificate.confirmation_status}</div>
                                                </>
                                        }
                                    </div>
                                    <div className="digitalcertificate_content_1">
                                        <div className="digitalcertificate_content_1_organization">{var_certificate.organization}</div>
                                        <div className="digitalcertificate_content_1_credential">{var_certificate.dependant_credential}</div>
                                    </div>
                                    <div className="digitalcertificate_content_2">
                                        <div className="digitalcertificate_content_2_issuedby">{var_certificate.dependant_organization}</div>
                                        {(var_certificate.dependant_detail !== '' && var_certificate.dependant_detail !== undefined && var_certificate.dependant_detail !== null) &&
                                            <div className="digitalcertificate_content_2_issued_date">{var_certificate.dependant_detail}</div>
                                        }
                                        <div className="digitalcertificate_content_2_issued_date">{t('Issued')} {var_certificate.credential_issued}</div>
                                    </div>
                                    {var_vc &&
                                        <div className="digitalcertificate_content_3">
                                            <div className="digitalcertificate_content_3_view_vc" onClick={onClick_verifiablecredential}><img className='btn_icon digitalcertificate_icon_small' src={'/icons/public credential 30px (000000).svg?ts=' + Date.now()} alt={t('verifiable credential icon')}/>{t('View VC details')}</div>
                                        </div>
                                    }
                                    {(var_certificate.credential_document !== '' && var_certificate.credential_document !== undefined && var_certificate.credential_document !== null && var_certificate.credential_document !== 'No file selected...') &&
                                        <div className="digitalcertificate_content_3">
                                            <div className="digitalcertificate_content_3_view_document" onClick={() => onClick_document()}>{t('View supporting document')}</div>
                                        </div>
                                    }
                                    {(var_vc) &&
                                        <>
                                            <div className="digitalcertificate_detail">
                                                <div className="digitalcertificate_detail_list">
                                                    <div className="digitalcertificate_detail_item">
                                                        <span className="detail_item_label">{t('CREDENTIAL')}: </span>
                                                        <span>{var_certificate.credential}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="digitalcertificate_detail">
                                                <div className="digitalcertificate_detail_list">
                                                    <div className="digitalcertificate_detail_item">
                                                        <span className="detail_item_label">{t('VERIFICATION ID')}: </span>
                                                        <span>
                                                            <img className="digitalcertificate_icon_small" src={"/icons/certificate checkmark 30px (69b536).svg?ts=" + Date.now()} alt={t('warning icon')}/>
                                                            <a href={"https://dev.uniresolver.io/#did=" + var_vc_json.issuer}>{var_vc_json.issuer}</a>
                                                        </span>
                                                    </div>
                                                    <div className="digitalcertificate_detail_item">
                                                        <span className="detail_item_label">{t('ISSUING DOMAIN')}: </span>
                                                        <span>
                                                            <img className="digitalcertificate_icon_small" src={"/icons/certificate checkmark 30px (69b536).svg?ts=" + Date.now()} alt={t('warning icon')}/>
                                                            <a href={"https://credivera.io"}>https://credivera.io</a>
                                                        </span>
                                                    </div>
                                                    <div className="digitalcertificate_detail_item">
                                                        <span className="detail_item_label">{t('SIGNATURE')}: </span>
                                                        <span>
                                                            <img className="digitalcertificate_icon_small" src={"/icons/certificate checkmark 30px (69b536).svg?ts=" + Date.now()} alt={t('warning icon')}/>
                                                            Signature is verified ({var_vc_json.proof.type})
                                                        </span>
                                                    </div>
                                                    <div className="digitalcertificate_detail_item">
                                                        <span className="detail_item_label">{t('SIGNATURE')}: </span>
                                                        <span className="signature-data">
                                                            <img className="digitalcertificate_icon_small" src={"/icons/certificate checkmark 30px (69b536).svg?ts=" + Date.now()} alt={t('warning icon')}/>
                                                            {var_vc_json.proof.jws}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {(!var_vc) &&
                                        <div className="digitalcertificate_detail">
                                            {(var_certificate.verifying_organizationlogo !== '' && var_certificate.verifying_organizationlogo !== null) &&
                                                <div className="digitalcertificate_detail_logo">
                                                    <img src={var_certificate.verifying_organizationlogo}
                                                         alt={t('logo')}/>
                                                </div>
                                            }
                                            <div className="digitalcertificate_detail_list">
                                                <div className="digitalcertificate_detail_item">
                                                    <span className="detail_item_label">{t('CREDENTIAL')}: </span>
                                                    <span>{var_certificate.credential}</span>
                                                </div>
                                                <div className="digitalcertificate_detail_item">
                                                    <span className="detail_item_label">{t('VERIFICATION ID')}: </span>
                                                    <span>{var_certificate.verificationid}</span>
                                                </div>
                                                <div className="digitalcertificate_detail_item">
                                                    <span
                                                        className="detail_item_label">{t('VERIFICATION ISSUED BY')}: </span>
                                                    <span>{var_certificate.verifying_organization}</span>
                                                </div>
                                                <div className="digitalcertificate_detail_item">
                                                    <span
                                                        className="detail_item_label">{t('VERIFICATION VALID FROM')}: </span>
                                                    <span>{var_certificate.credential_issued}</span>
                                                </div>
                                                <div className="digitalcertificate_detail_item">
                                                    <span
                                                        className="detail_item_label">{t('VERIFICATION VALID UNTIL')}: </span>
                                                    <span>{var_certificate.verification_expires}</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {(var_certificate.credential_description || var_certificate.credential_skills) &&
                                        <div className="digitalcertificate_description">
                                            {(var_certificate.credential_logo !== '' && var_certificate.credential_logo !== null) &&
                                                <div className="digitalcertificate_description_logo">
                                                    {(var_certificate.credential_logo.match(/.(jpg|jpeg|png|bmp|gif)$/i) || var_certificate.credential_logo.startsWith('data')) &&
                                                        <img src={var_certificate.credential_logo.startsWith('https://') ||
                                                        var_certificate.credential_logo.startsWith('http://') ||
                                                        var_certificate.credential_logo.startsWith('data') ||
                                                        !var_certificate.credential_logo.match(/.(jpg|jpeg|png|bmp|gif)$/i) ? var_certificate.credential_logo :
                                                            var_courselogo_url + var_certificate.credential_id + '/' + var_certificate.credential_logo}
                                                             alt={t('certificate logo')}>
                                                        </img>
                                                    }
                                                </div>
                                            }
                                            <div className="digitalcertificate_description_detail">
                                                {var_certificate.credential_description !== '' && var_certificate.credential_description !== null  &&
                                                    <div className="digitalcertificate_description_detail_item">
                                                        <div className="description_detail_title">{t('Description')}</div>
                                                        <div className="description_detail_desc">
                                                            {var_certificate.credential_description.replace(/(<([^>]+)>)|\r\n|\n|\r|\\n|\\r/gi, '')}
                                                        </div>
                                                    </div>
                                                }
                                                {var_certificate.credential_skills !== '' && var_certificate.credential_skills !== null  &&
                                                    <div className="digitalcertificate_description_detail_item">
                                                        <div className="description_detail_title">{t('Skills')}</div>
                                                        <div className="description_detail_desc">
                                                            {var_certificate.credential_skills}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>

                            <div className="digitalcertificate_footer">
                                {t('Powered by Credivera')}
                            </div>
                        </div>
                    </>
                }
            </div>

            {/***** MODAL: DOCUMENT VIEW ************************************************************************/}

            <Modal id="mdl_docviewer"
                   dimmer={'inverted'}
                   open={var_mdl_docviewer_open}
                   onClose={() => set_mdl_docviewer_open(false)}>
                <MDL_DOCVIEWER
                    set_mdl_open={set_mdl_docviewer_open}
                    targetid={appProps.match.params.credentialid}
                    classification='orgcredential'
                    ispublic={true}
                />
            </Modal>

            {/***** END MODAL: DOCUMENT VIEW *********************************************************************/}

            {/***** MODAL: PRESENTATION VIEWER *******************************************************************/}

            <Modal id="mdl_cmp_verifiablepresentationviewer"
                   dimmer={'inverted'}
                   open={var_mdl_verifiablepresentationviewer_open}
                   onClose={() => set_mdl_verifiablepresentationviewer_open(false)}
                   closeOnEscape={false}
                   closeOnDimmerClick={false}>
                <CMP_VERIFIABLEPRESENTATION_VIEWER
                    set_mdl_open={set_mdl_verifiablepresentationviewer_open}
                    title={var_certificate.credential}
                    var_json={var_vc_json}>
                </CMP_VERIFIABLEPRESENTATION_VIEWER>
            </Modal>

            {/***** END MODAL: PRESENTATION VIEWER ***************************************************************/}

        </div>
    )
};


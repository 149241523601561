//  OUTCOMES -----------------------------------------------------------------------------------------------------------
//
//  - displays historical versions of a credential
//
//  --------------------------------------------------------------------------------------------------------------------

import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { Modal, Button, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';
import datelib from '../../../../../libs/date-lib';

import MDL_DOCVIEWER from '../../../../../components/cmp_docviewer/cmp_docviewer';

import './tab_historical_versions.css';

export default function({ var_activetab, var_target_id, credential_type }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();
    const [ var_loading, set_loading ] = useState(true);
    const [ var_results, set_results ] = useState([]);
    const [ var_last_loaded_revision, set_last_loaded_revision ] = useState(0);

    const [ var_mdl_docviewer_open, set_mdl_docviewer_open ] = useState(false);
    const [ var_docviewerid, set_docviewerid ] = useState(null);

    const PAGE_SIZE = 3;

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        set_results([]);
        var_activetab === 'HISTORICAL' && var_target_id && populate_results();
    }, []);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_results() {
        set_loading(true);
        let current_results = var_results;
        let new_results = credential_type === 'indv' ? await API_get_indv_credentials_revisions() : await API_get_org_credential_versions();
        new_results.forEach(item => item.is_expanded = false);
        set_results(current_results.map(item => ({...item})).concat(new_results));
        if (new_results.length > 0) {
            set_last_loaded_revision(new_results[new_results.length - 1].revision);
        }
        set_loading(false);
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_indv_credentials_revisions() {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.get('indv-credentials', '/get-indv-credential-versions/' + var_target_id + '/' + var_last_loaded_revision + '/' + PAGE_SIZE,
        {
            queryStringParameters: {
                tz : datelib.timezone
            },
        });
    }

    function API_get_org_credential_versions() {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.get('org-orgcredentials', '/get-org-credential-versions/' + var_target_id + '/' + var_last_loaded_revision + '/' + PAGE_SIZE,
        {
            queryStringParameters: {
                tz : datelib.timezone
            },
        });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_revision_header(revision_id) {
        let results = var_results.map(item => ({...item}));
        let revision = results.find(item => item.id === revision_id);
        revision.is_expanded = !revision.is_expanded;
        set_results(results);
    }

    function onClick_load_more() {
        populate_results();
    }

    function onClick_open_attachment(revision_id) {
        set_docviewerid(revision_id);
        set_mdl_docviewer_open(true);
    }

    // RENDER APP ======================================================================================================

    return (
        <div className='credential-historical'>
            <div className='scrolling content'>
                {var_results.map(item => render_revision(item))}
            </div>
            {
                var_loading
                ? <Loader active inline='centered' size='small'/>
                : var_results.length === 0
                ? <div className='revision-no-results'>{t('There is no history to display')}</div>
                : var_results[var_results.length - 1].revision > 1
                ? <div className='modal-footer-button-bar revision-load-more'><Button className='btn_tertiary' onClick={onClick_load_more}>{t('Load more')}</Button></div>
                : null
            }
        
            {/***** MODAL: DOCUMENT VIEW *****************************************************************************/}

            <Modal id='mdl_docviewer'
                    dimmer={'inverted'}
                    open={var_mdl_docviewer_open}
                    onClose={() => set_mdl_docviewer_open(false)}>
                <MDL_DOCVIEWER
                    set_mdl_open={set_mdl_docviewer_open}
                    targetid={var_docviewerid}
                    classification={credential_type === 'indv' ? 'credential-revision' : 'orgcredential-revision'}
                />
            </Modal>

            {/***** END MODAL: DOCUMENT VIEW *************************************************************************/}
        </div>
    );

    function render_revision(revision) {
        return (
            <div key={revision.revision} className={'credential-revision-container ' + (revision.is_expanded ? 'revision-expanded' : 'revision-collapsed')}>
                <div className='revision-header' onClick={() => onClick_revision_header(revision.id)}>
                    <img src={'/icons/credential revision 30px (6b7280).svg?ts=' + Date.now()} alt={t('credential revision icon')}/>
                    <div>
                        {revision.issued
                            ? revision.expires ? datelib.date_localized_format(revision.issued) + ' - ' + datelib.date_localized_format(revision.expires) : datelib.date_localized_format(revision.issued)
                            : datelib.datetime_localized_format(revision.lastupdated)
                        }
                    </div>
                    {
                        revision.is_expanded
                        ? <img src={'/icons/collapse 16px (000000).svg?ts=' + Date.now()} alt={t('collapse icon')}/>
                        : <img src={'/icons/expand 16px (000000).svg?ts=' + Date.now()} alt={t('expand icon')}/>
                    }
                </div>
                <div className='revision-body'>
                    {credential_type === 'indv' &&
                        <div className='revision-body-row'>
                            <div className='revision-label'>{t('Holder')}:</div>
                            <div className='revision-value'>{revision.individual_name}</div>
                        </div>
                    }
                    <div className='revision-body-row'>
                        <div className='revision-label'>{t('Status')}:</div>
                        <div className='revision-value'>{revision.status}</div>
                    </div>
                    <div className='revision-body-row'>
                        <div className='revision-label'>{t('Verification')}:</div>
                        <div className='revision-value'>{revision.confirmation_status}</div>
                    </div>
                    {revision.issued &&
                        <div className='revision-body-row'>
                            <div className='revision-label'>{t('Issued')}:</div>
                            <div className='revision-value'>{datelib.date_localized_format(revision.issued)}</div>
                        </div>
                    }
                    {revision.expires &&
                        <div className='revision-body-row'>
                            <div className='revision-label'>{t('Expired')}:</div>
                            <div className='revision-value'>{datelib.date_localized_format(revision.expires)}</div>
                        </div>
                    }
                    {revision.submittedfilename &&
                        <div className='revision-body-row'>
                            <div className='revision-label'>{t('Document')}:</div>
                            <div className='revision-document-value'>
                                <Button className='btn_tertiary_link' onClick={() => onClick_open_attachment(revision.id)}>
                                    <img className='btn_icon' src={'/icons/file 30px (000000).svg?ts=' + Date.now()} alt={t('file icon')}/>{revision.submittedfilename}
                                </Button>
                            </div>
                        </div>
                    }
                    {revision.transaction_id &&
                        <div className='revision-body-row'>
                            <div className='revision-label'>{t('Transaction ID')}:</div>
                            <div className='revision-icon-value'>
                                <img src={'/icons/transaction 30px (000000).svg?ts=' + Date.now()} alt={t('transaction icon')}/>
                                <div className='credential-value'>{revision.transaction_id}</div>
                            </div>
                        </div>
                    }
                    <div className='revision-body-row'>
                        <div className='revision-label'>{t('Transaction Date')}:</div>
                        <div className='credential-value'>{datelib.datetime_localized_format(revision.lastupdated)}</div>
                    </div>
                </div>
            </div>
        );
    }
}
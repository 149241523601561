import { Modal, Table } from 'semantic-ui-react'
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import Moment from "moment";
import datelib from '../../../../libs/date-lib';

import { useTranslation } from 'react-i18next';
import '../../../../i18n';

import CMP_TABLECONTROLS, {filter_helper} from '../../../../components/cmp_tablecontrols/cmp_tablecontrols';
import MDL_DOCVIEWER from "../../../../components/cmp_docviewer/cmp_docviewer";
import MDL_UPDATE from "../../../common/requirements/mdl_indv_requirement/mdl_indv_requirement";

import './tab_requirements.css';

export default function({var_activetab}){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();
    const [ var_requirements, set_requirements] = useState({});
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(100);
    const [ var_offset, set_offset] = useState(0);
    const [ var_sortby, set_sortby ] = useState('requirement');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));

    const [ var_mdl_updaterequirement_open, set_mdl_updaterequirement_open ] = useState(false);
    const [ var_individualrequirement_id, set_individualrequirement_id] = useState('');

    const [ var_mdl_docviewer_open, set_mdl_docviewer_open ] = useState(false);
    const [ var_docviewerid, set_docviewerid ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_activetab === 'REQUIREMENTS') {
            set_filter(filter_helper.initialize(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_activetab]);

    useEffect(()=>{
        if (var_filter.load){
            populate_indv_requirements(null,0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_filter]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_indv_requirements(limit,offset,sortby,sortorder,filter){
        if (var_activetab !== 'REQUIREMENTS') return; // Don't load data if tab is not visible

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_requirements(limit,offset,sortby,sortorder,filter);
            set_requirements(results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);

    }

    async function populate_filters(filtername,sortorder){
        try {
            return await API_get_requirements(filter_helper.maxFilterItems,0,filtername,sortorder,[],filtername);
        } catch (e) {
            console.log(e);
        }
    }

    async function download_requirements(limit,offset){
        try {
            return await API_get_requirements(limit,offset,var_sortby,var_sortorder,var_filter);
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_requirements(limit,offset,sortby,sortorder,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('indv-credentials',
            '/get-indv-requirements/'+JSON.parse(sessionStorage.getItem('authentication')).id,
            {
                queryStringParameters: {
                    limit : limit,
                    offset : offset,
                    sortby : sortby,
                    sortorder : sortorder,
                    tz : datelib.timezone,
                    filtername : filtername
                },
                body : filter
            }
        );
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_indv_requirements('',0,sortby,sortorder)
    }

    function onOpen_mdl_updaterequirement(id){
        set_individualrequirement_id(id);
        set_mdl_updaterequirement_open(true);
    }

    function onClick_paperclip(id){
        set_docviewerid(id);
        set_mdl_docviewer_open(true);
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="content-card-noheader" id="tab_indv_wallet_requirements">
                <div className="content-card-gridcontent">
                    <div className="content-card-header mobile_tab_name">
                        {t('Requirements')}
                    </div>
                    <div className="content-filter">
                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_requirements && var_requirements.length > 0 ? var_requirements[0]['totalrows'] : 0} populatefunction={populate_indv_requirements}
                                downloadname='Requirements' downloadfunction={download_requirements}
                                var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                                table_config={[
                                    {name:'requirement', datatype:'text', labelKey:'REQUIREMENT', download: true, filter: true},
                                    {name:'status', datatype:'text', labelKey:'STATUS', download: true, filter: true},
                                    {name:'organization', datatype:'text', labelKey:'ORGANIZATION', download: true, filter: true},
                                    {name:'assignedfrom', datatype:'text', labelKey:'ASSIGNED FROM', download: true, filter: true},
                                    {name:'issuedby', datatype:'text', labelKey:'ISSUED BY', download: true, filter: true},
                                    {name:'credential', datatype:'text', labelKey:'CREDENTIAL', download: true, filter: true},
                                    {name:'expires', datatype:'text', labelKey:'EXPIRES', download: true, filter: false},
                                    {name:'epochexpires', datatype:'date', labelKey:'EXPIRES', download: false, filter: true},
                                    {name:'submittedfilename', datatype:'text', labelKey:'FILE', download: true, filter: true},
                                ]}
                            />
                        </div>
                    </div>

                    <div className="tablewrapper">
                        <Table unstackable sortable compact className={(var_loading ? " tbl_loading" : "")}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell className="td_status" sorted={var_sortby === 'status' ? var_sortorder : null} onClick={() => onClick_sort('status')}>{t('STATUS')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_requirement" sorted={var_sortby === 'requirement' ? var_sortorder : null} onClick={() => onClick_sort('requirement')}>{t('REQUIREMENT')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_organization" sorted={var_sortby === 'organization' ? var_sortorder : null} onClick={() => onClick_sort('organization')}>{t('ORGANIZATION')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_assignedfrom" sorted={var_sortby === 'assignedfrom' ? var_sortorder : null} onClick={() => onClick_sort('assignedfrom')}>{t('ASSIGNED FROM')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_issuedby" sorted={var_sortby === 'issuedby' ? var_sortorder : null} onClick={() => onClick_sort('issuedby')}>{t('ISSUED BY')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_credential" sorted={var_sortby === 'credential' ? var_sortorder : null} onClick={() => onClick_sort('credential')}>{t('CREDENTIAL')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_expires" sorted={var_sortby === 'epochexpires' ? var_sortorder : null} onClick={() => onClick_sort('epochexpires')}>{t('EXPIRES')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_paperclip" sorted={var_sortby === 'filename' ? var_sortorder : null} onClick={() => onClick_sort('filename')}><img src={"/icons/paperclip 30px (c9caca).svg?ts=" + Date.now()} alt={t('paperclip icon')}/></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {var_ready && var_requirements[0]['totalrows'] !== 0 &&
                            <Table.Body>
                                {var_requirements.map((item, i) =>
                                    <Table.Row key={i}>
                                        <Table.Cell className="td_status" onClick={() => onOpen_mdl_updaterequirement(item.individualrequirement_id)}>
                                            {(item.status === 'MISSING' || item.status === 'REQUESTED' || item.status === 'EXPIRED' || item.status ==='INVALID') &&
                                                <div className='td_statusicon'><img src={"/icons/warning 60px (db2430).svg?ts=" + Date.now()} alt={t("warning icon")}/></div>
                                            }
                                            {item.status === 'VALID' &&
                                                <div className='td_statusicon'><img src={"/icons/checkmark 30px (69b536).svg?ts=" + Date.now()} alt={t("checkmark icon")}/></div>
                                            }
                                            {item.status === 'PENDING' &&
                                                <div className='td_statusicon'><img src={"/icons/circle 60px (717473).svg?ts=" + Date.now()} alt={t("circle icon")}/></div>
                                            }
                                            {item.status === 'COMING DUE' &&
                                            <div className='td_statusicon'><img src={"/icons/warning 60px (ffae00).svg?ts=" + Date.now()} alt={t("warning icon")}/></div>
                                            }
                                            <div>{item.status}</div>
                                        </Table.Cell>
                                        <Table.Cell className="td_overflow td_requirement" onClick={() => onOpen_mdl_updaterequirement(item.individualrequirement_id)}>{item.requirement}</Table.Cell>
                                        <Table.Cell className="td_organization" onClick={() => onOpen_mdl_updaterequirement(item.individualrequirement_id)}>{item.organization}</Table.Cell>
                                        <Table.Cell className="td_assignedfrom" onClick={() => onOpen_mdl_updaterequirement(item.individualrequirement_id)}>{item.assignedfrom}</Table.Cell>
                                        <Table.Cell className="td_issuedby" onClick={() => onOpen_mdl_updaterequirement(item.individualrequirement_id)}>{item.issuedby}</Table.Cell>
                                        <Table.Cell className="td_credential" onClick={() => onOpen_mdl_updaterequirement(item.individualrequirement_id)}>{item.credential}</Table.Cell>
                                        <Table.Cell className="td_expires" onClick={() => onOpen_mdl_updaterequirement(item.individualrequirement_id)}>{item.epochexpires !== null && item.epochexpires !== undefined && item.epochexpires !== "" ? Moment.unix(item.epochexpires).utc(false).format('ll') : ''}</Table.Cell>
                                        <Table.Cell className="td_paperclip">
                                            {(item.filename !== null && item.filename !== '' && item.filename !== 'No file selected...') ?
                                                <div className='td_attachment' onClick={() => onClick_paperclip(item.individualcredential_id)}> <img src={"/icons/paperclip 60px (717473).svg?ts=" + Date.now()} alt={t('paperclip icon')}/></div>
                                                :
                                                <div className='td_attachment'></div>
                                            }
                                        </Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                            }
                        </Table>
                        {var_ready && var_requirements[0]['totalrows'] === 0 &&
                        <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t('alert icon')}/>{t('there are no requirements to display')}</div>
                        }
                    </div>
                    <div className="content-filter">
                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_requirements && var_requirements.length > 0 ? var_requirements[0]['totalrows'] : 0} populatefunction={populate_indv_requirements}
                            />
                        </div>
                    </div>
                </div>


                {/***** MODAL: UPDATE REQUIREMENT *********************************************************************/}

                <Modal id="mdl_indv_requirement"
                       dimmer={'inverted'}
                       open={var_mdl_updaterequirement_open}
                       onClose={() => set_mdl_updaterequirement_open(false)}
                       closeOnEscape={false}
                       closeOnDimmerClick={false}>
                <MDL_UPDATE
                        var_mdl_open={var_mdl_updaterequirement_open}
                        set_mdl_open={set_mdl_updaterequirement_open}
                        source='INDV'
                        var_target_id={var_individualrequirement_id}
                        populate_function={populate_indv_requirements}>
                    </MDL_UPDATE>
                </Modal>

                {/***** END MODAL: UPDATE REQUIREMENT *****************************************************************/}


                {/***** MODAL: DOCUMENT VIEW *****************************************************************************/}

                <Modal id="mdl_docviewer"
                       dimmer={'inverted'}
                       open={var_mdl_docviewer_open}
                       onClose={() => set_mdl_docviewer_open(false)}>
                    <MDL_DOCVIEWER
                        set_mdl_open={set_mdl_docviewer_open}
                        targetid={var_docviewerid}
                        classification='credential'
                    />
                </Modal>

                {/***** END MODAL: DOCUMENT VIEW *********************************************************************/}


            </div>
        </>
    )

}
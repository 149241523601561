import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { Button, Modal, Table } from 'semantic-ui-react';
import Moment from 'moment';
import datelib from '../../../../../libs/date-lib';
import auth from '../../../../../libs/auth-lib';

import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import CMP_TABLECONTROLS, { filter_helper } from '../../../../../components/cmp_tablecontrols/cmp_tablecontrols';
import CMP_CREDENTIALSTATUS from '../../../../../components/cmp_credentialstatus';
import CMP_LEGEND from '../../../../../components/cmp_legend/cmp_credential_legend/cmp_credential_legend';
import MDL_HISTORY from '../../../../../components/cmp_history/cmp_history';
import MDL_DOCVIEWER from '../../../../../components/cmp_docviewer/cmp_docviewer';
import MDL_ADD from './mdl_add/mdl_add';
import MDL_CREDENTIAL_DETAILS from '../../../../common/credentials/mdl_credential_details/mdl_credential_details';
import CMP_VERIFIABLEPRESENTATION_VIEWER from "../../../../../components/cmp_verifiablepresentationviewer/cmp_verifiablepresentationviewer";
import MDL_SHARE_VC from './mdl_share_vc/mdl_share_vc'

import './tab_orgcredentials.css';

export default function() {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();
    const [ var_credentials, set_credentials] = useState({});
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(100);
    const [ var_offset, set_offset] = useState(0);
    const [ var_archive, set_archive ] = useState('NO');
    const [ var_sortby, set_sortby ] = useState('credential');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));

    const [ var_mdl_history_open, set_mdl_history_open ] = useState(false);
    const [ var_modaltitle, set_modaltitle ] = useState('');
    const [ var_target_id, set_target_id ] = useState('');

    const [ var_mdl_add_open, set_mdl_add_open ] = useState(false);

    const [ var_mdl_updatecredential_open, set_mdl_updatecredential_open ] = useState(false);
    const [ var_credential_id, set_credential_id] = useState(null);

    const [ var_mdl_docviewer_open, set_mdl_docviewer_open ] = useState(false);
    const [ var_docviewerid, set_docviewerid ] = useState(null);

    const [ var_mdl_verifiablepresentationviewer_open, set_mdl_verifiablepresentationviewer_open ] = useState(false);
    const [ var_target, set_target ] = useState({});
    const [ var_json, set_json ] = useState({});

    const [ var_mdl_sharevc_open, set_mdl_sharevc_open ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        set_filter(filter_helper.initialize(false));
    }, [var_archive]);

    useEffect(()=>{
        if (var_filter.load){
            populate_records(null,0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_filter]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_records(limit, offset, sortby, sortorder, archive, filter) {
        set_loading(true);

        if (limit) {
            set_limit(limit);
        } else {
            limit = var_limit
        }

        if (offset || offset === 0) {
            set_offset(offset);
        } else {
            offset = var_offset
        }

        if (sortby) {
            set_sortby(sortby);
        } else {
            sortby = var_sortby
        }

        if (sortorder) {
            set_sortorder(sortorder);
        } else {
            sortorder = var_sortorder
        }

        if (archive) {
            set_archive(archive);
        } else {
            archive = var_archive
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_orgcredentials(limit, offset, sortby, sortorder, archive, filter);
            set_totalrows(results.totalrows);
            set_credentials(results.results ? results.results : []);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername,sortorder) {
        try {
            return await API_get_orgcredentials(filter_helper.maxFilterItems, 0, filtername, sortorder, var_archive, {}, filtername);
        } catch (e) {
            console.log(e);
        }
    }

    async function download_records(limit, offset){
        try {
            return await API_get_orgcredentials(limit, offset, var_sortby, var_sortorder, var_archive, var_filter);
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_orgcredentials(limit, offset, sortby, sortorder, archive, filter, filtername) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.post('org-orgcredentials', '/get-org-credentials/' + auth.organization_id,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    archive: archive,
                    tz: datelib.timezone,
                    filtername: filtername
                },
                body: filter
            }
        );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        let sortorder = var_sortorder;
        sortorder = var_sortby !== sortby ? 'ascending' : sortorder === 'ascending' ? 'descending' : 'ascending';
        set_sortorder(sortorder);
        populate_records('', 0, sortby, sortorder);
    }

    function onOpen_mdl_history(var_credential_id, issuedby, credential) {
        set_modaltitle(t('Credential History - ') + credential + ' (' + issuedby + ')');
        set_target_id(var_credential_id);
        set_mdl_history_open(true);
    }

    function onClick_paperclip(id) {
        set_docviewerid(id);
        set_mdl_docviewer_open(true);
    }

    function onClick_verifiablecredential(item) {
        set_target(item);
        set_json(JSON.parse(item.json));
        set_mdl_verifiablepresentationviewer_open(true);
    }

    function onClick_share(item) {
        set_target(item);
        set_json(JSON.parse(item.json));
        set_mdl_sharevc_open(true);
    }

    function onOpen_mdl_addcredential() {
        set_mdl_add_open(true);
    }

    function onOpen_mdl_updatecredential(var_credential_id){
        set_credential_id(var_credential_id);
        set_mdl_updatecredential_open(true);
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='content-card-noheader' id='tab_orgcredentials'>
                <div className='content-card-gridcontent'>
                    <div className='content-card-options'>
                        <Button className='btn_primary btn_active' onClick={() => onOpen_mdl_addcredential()}><img className='btn_icon' src={'/icons/add 30px (ffffff).svg?ts=' + Date.now()} alt={t('add icon')}></img>{t('ADD A CREDENTIAL')}</Button>
                    </div>

                    <div className='content-filter'>

                        <div className='content-filter-archive'>
                            {var_archive === 'NO' ?
                                <Button className='btn_tertiary' onClick={() => set_archive('YES')}><img className='btn_icon' src={'/icons/archive 30px (4dcbd4).svg?ts=' + Date.now()} alt={t('archive icon')}/>{t('VIEW ARCHIVED CREDENTIALS')}</Button>
                                :
                                <Button className='btn_tertiary' onClick={() => set_archive('NO')}><img className='btn_icon' src={'/icons/checkmark 30px (4dcbd4).svg?ts=' + Date.now()} alt={t('checkmark icon')}/>{t('VIEW ACTIVE CREDENTIALS')}</Button>
                            }
                        </div>

                        <div className='content-filter-item item-pagination'>
                            <CMP_TABLECONTROLS
                                var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_totalrows} populatefunction={populate_records}
                                downloadname='Credentials' downloadfunction={download_records}
                                var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                                table_config={[
                                    {name:'verification_status', datatype:'text', labelKey:'STATUS', download: true, filter: true},
                                    {name:'credential', datatype:'text', labelKey:'CREDENTIAL', download: true, filter: true},
                                    {name:'issuedby', datatype:'text', labelKey:'ISSUED BY', download: true, filter: true},
                                    {name:'epochissued', datatype:'epoch', labelKey:'ISSUED', download: true, filter: true},
                                    {name:'epochexpires', datatype:'epoch', labelKey:'EXPIRES', download: true, filter: true},
                                    {name:'daysleft', datatype:'int', labelKey:'DAYS LEFT', download: true, filter: true},
                                    {name:'submittedfilename', datatype:'text', labelKey:'FILE', download: true, filter: true},
                                    {name:'json', datatype:'text', labelKey:'json', download: false, filter: false},
                                    {name:'confirmation_status', datatype:'text', labelKey:'STATUS', download: true, filter: true}
                                ]}
                            />
                        </div>
                    </div>

                    <div className='tablewrapper'>
                        <Table unstackable sortable compact className={(var_loading ? ' tbl_loading' : '')}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell className='td_icon_center' sorted={var_sortby === 'verification_status' ? var_sortorder : null} onClick={() => onClick_sort('verification_status')}>{t('STATUS')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'credential' ? var_sortorder : null} onClick={() => onClick_sort('credential')}>{t('CREDENTIAL')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'issuedby' ? var_sortorder : null} onClick={() => onClick_sort('issuedby')}>{t('ISSUED BY')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'epochissued' ? var_sortorder : null} onClick={() => onClick_sort('epochissued')}>{t('ISSUED')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'epochexpires' ? var_sortorder : null} onClick={() => onClick_sort('epochexpires')}>{t('EXPIRES')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'sortorder' ? var_sortorder : null} onClick={() => onClick_sort('sortorder')}>{t('DAYS LEFT')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'filename' ? var_sortorder : null} onClick={() => onClick_sort('filename')}><img src={'/icons/paperclip 30px (c9caca).svg?ts=' + Date.now()} alt={t('paperclip icon')}/></Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'json' ? var_sortorder : null} onClick={() => onClick_sort('json')}><img src={'/icons/public credential 30px (c9caca).svg?ts=' + Date.now()} alt={t('verifiable credential icon')}/></Table.HeaderCell>
                                    {auth.org_inspection_report && <Table.HeaderCell><img src={'/icons/share 60px (8a8d8c).svg?ts=' + Date.now()} alt={t('share icon')}/></Table.HeaderCell>}
                                    <Table.HeaderCell sorted={var_sortby === 'confirmation_status' ? var_sortorder : null} onClick={() => onClick_sort('confirmation_status')}>{t('VERIFICATION')}</Table.HeaderCell>
                                    <Table.HeaderCell className='td_icon_center' >{t('HSTRY')}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {var_ready && var_totalrows !== 0 &&
                            <Table.Body>
                                {var_credentials.map((item, i) =>
                                    <Table.Row key={item.id} className={(item.confirmation_status === 'SELF VERIFIED' && item.verification_status === 'VALID') ? 'self_verified' : ''}>
                                        <Table.Cell className='td_icon_center' onClick={() => onOpen_mdl_updatecredential(item.id)}>
                                            {(item.verification_status === 'VALID' && item.confirmation_status !== 'SELF VERIFIED') &&
                                            <img src={'/icons/checkmark 30px (69b536).svg?ts=' + Date.now()} alt={t('active icon')}/>
                                            }
                                            {item.verification_status === 'INVALID' &&
                                            <img src={'/icons/x 30px (e0454f).svg?ts=' + Date.now()} alt={t('inactive icon')}/>
                                            }
                                            {item.verification_status === 'PENDING' &&
                                            <img src={'/icons/warning 30px (ffae00).svg?ts=' + Date.now()} alt={t('missing icon')}/>
                                            }
                                            {(item.verification_status === 'VALID' && item.confirmation_status === 'SELF VERIFIED') &&
                                            <img src={'/icons/checkmark 30px (4dcbd4).svg?ts=' + Date.now()} alt={t('self verified icon')}/>
                                            }
                                            {item.verification_status !== 'VALID' && item.verification_status !== 'INVALID' &&
                                            item.verification_status !== 'PENDING' && <img src={'/icons/circle 30px (bcbebe).svg?ts=' + Date.now()} alt={t('inactive icon')}/>}
                                        </Table.Cell>
                                        <Table.Cell onClick={() => onOpen_mdl_updatecredential(item.id)}>{item.credential}</Table.Cell>
                                        <Table.Cell onClick={() => onOpen_mdl_updatecredential(item.id)}>{item.issuedby}</Table.Cell>
                                        <Table.Cell onClick={() => onOpen_mdl_updatecredential(item.id)}>{!!item.epochissued ? Moment.unix(item.epochissued).utc(false).format('ll'): ''}</Table.Cell>
                                        <Table.Cell onClick={() => onOpen_mdl_updatecredential(item.id)}>{!!item.epochexpires ? Moment.unix(item.epochexpires).utc(false).format('ll') : ''}</Table.Cell>
                                        <Table.Cell onClick={() => onOpen_mdl_updatecredential(item.id)}>{item.daysleft}</Table.Cell>
                                        <Table.Cell className='td_paperclip'>
                                            {(item.filename !== null && item.filename !== '' && item.filename !== 'No file selected...') ?
                                                <div className='td_attachment' onClick={() => onClick_paperclip(item.id)}> <img src={'/icons/paperclip 60px (717473).svg?ts=' + Date.now()} alt={t('paperclip icon')}/></div>
                                                :
                                                <div className='td_attachment'></div>
                                            }
                                        </Table.Cell>
                                        <Table.Cell className='td_verifiablecredential'>
                                            {(item?.json?.includes('VerifiableCredential')) ?
                                                <div className='td_attachment' onClick={() => onClick_verifiablecredential(item)}> <img src={'/icons/public credential 30px (000000).svg?ts=' + Date.now()} alt={t('verifiable credential icon')}/></div>
                                                :
                                                <div className='td_attachment'></div>
                                            }
                                        </Table.Cell>
                                        {auth.org_inspection_report && 
                                        <Table.Cell className='td_paperclip'>
                                            {(item?.json?.includes('VerifiableCredential') && item?.json.includes('InspectionReport')) ?
                                                <div className='td_attachment' onClick={() => onClick_share(item)}> <img src={'/icons/share 60px (8a8d8c).svg?ts=' + Date.now()} alt={t('share icon')}/></div>
                                                :
                                                <div className='td_attachment'></div>
                                            }
                                        </Table.Cell>}
                                        <Table.Cell>
                                            <CMP_CREDENTIALSTATUS confirmation_status={item.confirmation_status} verification_status={item.verification_status}
                                                clickfor_publiccert={true} id={item.id} has_customsecurity={item.has_customsecurity} is_organization={true} />
                                        </Table.Cell>
                                        <Table.Cell className='td_icon_center' onClick={() => onOpen_mdl_history(item.id, item.issuedby, item.credential)}><img className='icon_history' src={'/icons/history 30px (bcbebe).svg?ts=' + Date.now()} alt={t('archive icon')}/></Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                            }
                        </Table>
                        {var_ready && var_totalrows === 0 &&
                            <div className='emptytable'><img src={'/icons/alert 60px (e5e5e5).svg?ts=' + Date.now()} alt={t('alert icon')}/>{t('there are no credentials to display')}</div>
                        }
                    </div>
                    <div className='content-filter'>
                        <div className='content-filter-item item-pagination'>
                            <CMP_TABLECONTROLS
                                var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_totalrows} populatefunction={populate_records}
                            />
                        </div>
                    </div>
                    <div className='legend_footer'>
                        <CMP_LEGEND />
                    </div>
                </div>



                {/***** MODAL: HISTORY *******************************************************************************/}

                <Modal id='mdl_history'
                       dimmer={'inverted'}
                       open={var_mdl_history_open}
                       onClose={() => set_mdl_history_open(false)}>
                    <MDL_HISTORY
                        set_mdl_open={set_mdl_history_open}
                        var_modaltitle={var_modaltitle}
                        var_target_id={var_target_id}>
                    </MDL_HISTORY>
                </Modal>

                {/***** END MODAL: DETAILS ***************************************************************************/}



                {/***** MODAL: ADD ***********************************************************************************/}

                {<Modal id='mdl_org_addcredential'
                       dimmer={'inverted'}
                       open={var_mdl_add_open}
                       onClose={() => set_mdl_add_open(false)}
                       closeOnEscape={false}
                       closeOnDimmerClick={false}>
                    <MDL_ADD
                        set_mdl_open={set_mdl_add_open}
                        var_individual_id={JSON.parse(sessionStorage.getItem('authentication')).id}
                        populate_function={populate_records}>
                    </MDL_ADD>
                </Modal>}

                {/***** END MODAL: ADD *******************************************************************************/}



                {/***** MODAL: UPDATE CREDENTIAL *********************************************************************/}

                <MDL_CREDENTIAL_DETAILS
                    var_mdl_open={var_mdl_updatecredential_open}
                    set_mdl_open={set_mdl_updatecredential_open}
                    var_target_id={var_credential_id}
                    populate_function={populate_records}
                    credential_type='org' />

                {/***** END MODAL: UPDATE CREDENTIAL *****************************************************************/}


                {/***** MODAL: DOCUMENT VIEW *****************************************************************************/}

                <Modal id='mdl_docviewer'
                       dimmer={'inverted'}
                       open={var_mdl_docviewer_open}
                       onClose={() => set_mdl_docviewer_open(false)}>
                    <MDL_DOCVIEWER
                        set_mdl_open={set_mdl_docviewer_open}
                        targetid={var_docviewerid}
                        classification='orgcredential'
                    />
                </Modal>

                {/***** END MODAL: DOCUMENT VIEW *********************************************************************/}


                {/***** MODAL: PRESENTATION VIEWER *******************************************************************/}

                <Modal id="mdl_cmp_verifiablepresentationviewer"
                       dimmer={'inverted'}
                       open={var_mdl_verifiablepresentationviewer_open}
                       onClose={() => set_mdl_verifiablepresentationviewer_open(false)}
                       closeOnEscape={false}
                       closeOnDimmerClick={false}>
                    <CMP_VERIFIABLEPRESENTATION_VIEWER
                        set_mdl_open={set_mdl_verifiablepresentationviewer_open}
                        title={var_target.credential}
                        var_json={var_json}>
                    </CMP_VERIFIABLEPRESENTATION_VIEWER>
                </Modal>

                {/***** END MODAL: PRESENTATION VIEWER ***************************************************************/}

                {/***** MODAL: SHARE VERIFIABLE CREDENTIAL ***********************************************************/}

                <Modal id="mdl_share_vc"
                       dimmer={'inverted'}
                       open={var_mdl_sharevc_open}
                       onClose={() => set_mdl_sharevc_open(false)}>
                    <MDL_SHARE_VC
                        set_mdl_open={set_mdl_sharevc_open}
                        target={var_target}>
                    </MDL_SHARE_VC>
                </Modal>

                {/***** END MODAL: SHARE VERIFIABLE CREDENTIAL *******************************************************/}

            </div>
        </>
    )

}

import React from "react";
import { useTranslation } from 'react-i18next';
import '../../../i18n';

import './cmp_forms_legend.css';


export default function({ var_indv_view }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();



    //  variable listeners ---------------------------------------------------------------------------------------------


    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <>
        <div className="legend-footer">
        {var_indv_view &&
            <>
            <div className="legend-item">
                <img src={"/icons/warning 60px (db2430).svg?ts=" + Date.now()} alt={t('warning icon')}/>
                <div className="legend-item-text">
                    {t(' = Form requires your attention')}
                </div>
            </div>
            <div className="legend-item">
                <img src={"/icons/circle 60px (717473).svg?ts=" + Date.now()} alt={t('circle icon')}/>
                <div className="legend-item-text">
                    {t(' = Form does not require your attention')}
                </div>
            </div>
            </>
        }
            <div className="legend-item">
                <div className="legend-item-text">
                    {t('NEW = Form has not been started')}
                </div>
            </div>
          <div className="legend-item">
                <div className="legend-item-text">
                    {t('IN-PROGRESS = Partially completed form')}
                </div>
            </div>
            <div className="legend-item">
                <div className="legend-item-text">
                    {t('IN-REVIEW = Closed form requiring Supervisor review')}
                </div>
            </div>
          <div className="legend-item">
                <div className="legend-item-text">
                    {t('CLOSED = Completed form')}
                </div>
            </div>
        </div>
        </>
    )
}

import {Button, Form, Modal} from 'semantic-ui-react'
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';
import Moment from "moment";
import datelib from '../../../../../libs/date-lib';
import auth from '../../../../../libs/auth-lib';

import MDL_UPLOAD from "./mdl_upload/mdl_upload";


import './mdl_add.css';



export default function({set_mdl_open, populate_function}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_selectedlocation, set_selectedlocation ] = useState('');
    const [ var_selectedlocationname, set_selectedlocationname ] = useState('');
    const [ var_selectedform, set_selectedform ] = useState('');
    const [ var_selectedformname, set_selectedformname ] = useState('');
    const [ var_selectedsupervisor, set_selectedsupervisor ] = useState(null);

    const [ var_today ] = useState(new Date()/1000);
    const [ var_existingform, set_existingform ] = useState(false);

    const [ var_ready, set_ready ] = useState(false);
    const [ var_processing, set_processing ] = useState(false);

    const [ var_dd_locations, set_dd_locations ] = useState([]);
    const [ var_dd_forms, set_dd_forms ] = useState([]);
    const [ var_dd_supervisors, set_dd_supervisors ] = useState([]);

    const [ var_mdl_upload_open, set_mdl_upload_open ] = useState(false);
    const [ var_form, set_form ] = useState([]);
    const [ var_requires_acknowledgement, set_requires_acknowledgement ] = useState(false);


    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_dd_locations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(var_selectedlocation !== '') {
            populate_dd_forms();
            populate_dd_supervisors();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_selectedlocation]);

    useEffect(() => {
        if(var_form.formname){
            set_mdl_upload_open(true);
        }
    },[var_form]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_dd_locations(){
        try {
            let results = await API_get_dd_locations();
            set_dd_locations(results);
        } catch (e) {
            console.log(e);
        }
    }

    async function populate_dd_forms(){
        try {
            let results = [];
            if (var_selectedlocation !== '') {
                results = await API_get_dd_loc_forms();
            }
            set_dd_forms(results);
        } catch (e) {
            console.log(e);
        }
    }

    async function populate_dd_supervisors() {
        try {
            if (var_selectedlocation !== '') {
                let supervisors = await API_get_dd_reviewers();
                // supervisors = supervisors.filter(item => item.value !== auth.id); // Cannot select self as supervisor
                set_dd_supervisors(supervisors);
                set_selectedsupervisor(null);
            } else {
                set_dd_supervisors([]);
            }
        } catch (e) {
            console.log(e);
        }
    }

    async function assign_form() {
        set_processing(true);
        try {
            let data = {};
            data.form_id = var_selectedform;
            data.individuals = [auth.id];
            var_requires_acknowledgement && (auth.id !== var_selectedsupervisor) && data.individuals.push(var_selectedsupervisor); //only add "supervisor" if not self
            data.location_id = var_selectedlocation;
            data.organization_id = auth.actingorganization_id;
            data.transactionby = auth.id;
            data.transactionorg = auth.actingorganization_id;

            let postresult = await API_post_activeform(data);

            set_ready(false);
            set_processing(false);
            populate_function();

            let activeform = await API_get_activeform(postresult);

            let formurl = activeform[0]['formurl'] + '?entry=' + encodeURIComponent(JSON.stringify({'Site': activeform[0]['location_name'], 'UID': activeform[0]['id'],'Number': activeform[0]['location_code']}));
            window.open(formurl);
            set_mdl_open(false);
        } catch (e) {
            console.log(e);
        }

    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_dd_locations(){
        const qrdata = JSON.parse(sessionStorage.getItem('qrdata'));
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('indv', '/get-dd-indv-locations-forms/'+auth.id,
        {
            queryStringParameters: {
                qrlocation_id: qrdata ? qrdata.qrlocation_id : null
            }
        });
    }

    function API_get_dd_loc_forms(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-forms', '/get-dd-loc-forms/'+var_selectedlocation);
    }

    function API_get_dd_reviewers() {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-forms', '/get-dd-reviewers/' + var_selectedlocation);
    }

    function API_post_activeform(data){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-forms', '/post-activeform', { body: data});
    }

    function API_get_activeform(activeform_id){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('indv-forms', '/get-activeform/'+activeform_id, {queryStringParameters:{tz: datelib.timezone}});
    }

    function API_get_loc_form_assigned(form_id){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-forms','/get-loc-form-assigned/'+var_selectedlocation+'/'+form_id+'/'+Moment(var_today).valueOf(), {queryStringParameters:{tz: datelib.timezone}});
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_location(e, { value }){
        set_selectedform('');
        set_selectedformname('');
        set_selectedlocation(value);
        const location = var_dd_locations.filter(location => location.value === value)
        if (location && location !== []) {
            set_selectedlocationname(location[0].location_name);
        }
        set_ready(false);
    }

    async function onChange_form(e, { value }){
        set_processing(true);
        set_selectedsupervisor(null);
        set_selectedform(value);
        set_selectedformname(e.target.textContent);
        if (var_selectedlocation !== '') {
            let result = await API_get_loc_form_assigned(value);
            console.log(result);
            set_existingform(result.length > 0);
            let form_item = var_dd_forms.find(item => item.value === value);
            let requires_acknowledgement = form_item.requires_acknowledgement === 'YES';
            set_requires_acknowledgement(requires_acknowledgement);
            set_ready(!requires_acknowledgement);
        }
        set_processing(false);
    }

    async function onChange_supervisor(e, { value }) {
        set_selectedsupervisor(value);
        set_ready(true);
    }

    function onClick_submit(){
        if (var_ready) {
            assign_form();
        }
    }

    function onClick_upload(){
        if (var_ready) {
            const form = { ...var_form };
            form['locationname'] = var_selectedlocationname;
            form['locationid'] = var_selectedlocation;
            form['formname'] = var_selectedformname;
            form['formid'] = var_selectedform;
            set_form(form);
        }
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{t("Fill Out A Form")}</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
            </div>

            <div className="modal-content">
                <Form>
                <Form.Select
                        className='location_id'
                        id='location_id'
                        name='location_id'
                        label={t("LOCATIONS & PROJECTS")}
                        search
                        options={var_dd_locations}
                        value={var_selectedlocation || ''}
                        onChange={onChange_location}
                        placeholder={t("Select a location or project...")}
                    />
                    <Form.Select
                        className='form_id'
                        id='form_id'
                        name='form_id'
                        label={t("FORM")}
                        search
                        options={var_dd_forms}
                        value={var_selectedform || ''}
                        onChange={onChange_form}
                        placeholder={t("Select a form...")}
                    />
                    { var_requires_acknowledgement &&
                        <Form.Select
                            className='supervisor_id'
                            id='supervisor_id'
                            name='supervisor_id'
                            label={t("SELECT SUPERVISOR TO REVIEW")}
                            search
                            options={var_dd_supervisors}
                            value={var_selectedsupervisor || ''}
                            onChange={onChange_supervisor}
                            placeholder={t("Select a supervisor...")}
                        />
                    }
                </Form>
                { (var_existingform && !var_processing) &&
                    <div class="ui warning message"><p>A <em>{var_selectedformname}</em> already exists for <em>{var_selectedlocationname}</em>. Are you sure you want to open a new form?</p></div>
                }
            </div>

            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className={"btn_secondary" + (var_ready ? ' btn_active' : '')} onClick={() => onClick_upload()}>{t("UPLOAD COMPLETED FORM")}</Button>
                    {!var_processing ?
                        <Button className={"btn_primary " + (var_ready ? ' btn_active' : '')} disabled={(var_ready ? false : true)} onClick={() => onClick_submit()}>{t("OPEN NEW FORM")}</Button>
                        :
                        <Button loading className="btn_primary btn_active">{t("OPEN NEW FORM")}</Button>
                    }
                </div>
            </div>

            {/***** MODAL: UPLOAD ************************************************************************************/}

            <Modal id="indv-forms_mdl_uploadform"
                   dimmer={'inverted'}
                   open={var_mdl_upload_open}
                   onClose={() => set_mdl_upload_open(false)}>
                <MDL_UPLOAD
                    set_mdl_open={set_mdl_upload_open}
                    set_parentmdl_open={set_mdl_open}
                    var_form={var_form}
                    set_form={set_form}
                    populate_function={populate_function}>
                </MDL_UPLOAD>
            </Modal>

            {/***** END MODAL: UPLOAD ********************************************************************************/}

        </>
    )
}

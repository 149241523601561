import React from "react";
import { useTranslation } from 'react-i18next';
import { Text,StyleSheet, View, Image } from '@react-pdf/renderer';



export default function({var_onsite}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

	const styles = StyleSheet.create({
		section: {
			paddingHorizontal: 15,
			marginTop: 15,
		},
		sectionTitle: {
			fontSize: 12,
			fontWeight: 700,
			paddingBottom: 5,
		},
		table: {
			borderStyle: 'solid',
			borderColor: '#ebebeb',
			borderWidth: 1,
		},
		tableHeaderRow: {
			flexDirection: "row",
		},
		tableRow: {
			flexDirection: "row",
			borderTopWidth: 1,
			borderTopStyle: 'solid',
			borderTopColor: '#ebebeb'
		},
		tableHeaderCol: {
			width: '10%',
			color: '#cfd3d5',
		},
		tableRowCol: {
			width: '10%',
		},
		tableHeaderCellText: {
			padding: 6,
			fontSize: 9,
			fontWeight: 500,
		},
		tableCellText: {
			padding: 6,
			fontSize: 9,
		},
		Icon: {
			width: '12px',
			display: 'inline-block'
		},
		alert: {
			paddingHorizontal: '6px',
			paddingVertical: '6px',
			color: '#cdcecd',
			fontSize: 9,
			flexDirection: "row",
			alignItems: 'center',
			justifyContent: 'center',
			borderTopWidth: 1,
			borderTopStyle: 'solid',
			borderTopColor: '#ebebeb'
		},
		alertIcon: {
			width: '12px',
			marginRight: '4px'
		},
	});

    // RENDER APP ======================================================================================================
    return(
		<View style={styles.section}>

			<Text style={styles.sectionTitle}>{t('Onsite')}</Text>

			<View style={styles.table}>

				<View style={styles.tableHeaderRow} wrap={false}>
					<View style={[styles.tableHeaderCol, {width: '30%'}]}>
						<Text style={styles.tableHeaderCellText}>{t('STATUS')}</Text>
					</View>
					<View style={[styles.tableHeaderCol, {width: '70%'}]}>
						<Text style={styles.tableHeaderCellText}>{t('LAST ENTRY')}</Text>
					</View>
				</View>

				{var_onsite &&
				<View>
					{var_onsite.map((item, i) =>
						<View key={i} style={styles.tableRow} wrap={false}>
							<View style={[styles.tableRowCol, {width: '30%'}]}>
								<View style={[styles.tableCellText, {flexDirection: 'row', alignItems: 'center'}]}>
									{item.onsite === 'YES' &&
										<Image src="/pdf/checkmark 30px (69b536).png" style={[styles.Icon, {marginRight: '4px'}]} />
									}
									{item.onsite === 'NO' &&
										<Image src="/pdf/x 30px (e0454f).png" style={[styles.Icon, {marginRight: '4px'}]} />
									}
									{item.onsite === 'NONE' &&
										<Image src="/pdf/circle 30px (bcbebe).png" style={[styles.Icon, {marginRight: '4px'}]} />
									}
									<Text>{item.onsite}</Text>
								</View>
							</View>
							<View style={[styles.tableRowCol, {width: '70%'}]}>
								<Text style={styles.tableCellText}>{item.onsitelastdone}</Text>
							</View>
						</View>
					)}
				</View>
				}

				{var_onsite.length === 0 &&
					<View style={styles.alert} wrap={false}>
						<Image src="/pdf/alert 60px (e5e5e5).png" style={styles.alertIcon} />
						<Text>{t('there are no onsite to display')}</Text>
					</View>
				}

			</View>
        </View>
    )

}
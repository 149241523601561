import React from "react";
import { useTranslation } from 'react-i18next';
import {Text,StyleSheet, View, Image, Link} from '@react-pdf/renderer';


export default function({var_credentials, credential_url}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

	const styles = StyleSheet.create({
		section: {
			paddingHorizontal: 15,
			marginTop: 15,
		},
		sectionTitle: {
			fontSize: 12,
			fontWeight: 700,
			paddingBottom: 5,
		},
		attachmentRow: {
			flexDirection: 'row',
			flexWrap: 'wrap',
		},
		attachmentItem: {
			width: '32.30%',
			height: '150px',
			marginRight: '5px',
			marginBottom: '15px',
		},
		attachmentThumb: {
			borderStyle: 'solid',
			borderWidth: 1,
			borderColor: '#ebebeb',
			padding: '2px',
			minHeight:'150px',
		},
		credentialTitle: {
			fontSize: 8,
			fontWeight: 500,
			color: '#cfd3d5',
			paddingTop: '3px',
			paddingBottom: '1px',
			textAlign: 'center',
		},
		imageContainer: {
            width: '100%',
            height: '140px',
			padding: '5px',
			
        },
        image: {
            maxWidth: '186px',
            maxHeight: '140px',
            margin: 'auto'
 
        },
		attachmentPdf: {
			fontSize: 20,
			margin: 'auto',
		}
	});

    // RENDER APP ======================================================================================================
    return(
		<>
		{var_credentials.filter(function(obj) { return (obj.filename !== null && obj.filename !== '' && obj.filename !== 'No file selected...'); }).length > 0 &&
        <View style={styles.section}>
			
			<Text style={styles.sectionTitle}>{t('Credentials Attachments')}</Text>
			{var_credentials &&
				<View style={styles.attachmentRow}>
					{var_credentials.map((item, i) =>
					<>
					{(item.filename !== null && item.filename !== '' && item.filename !== 'No file selected...') &&
					<View key={i} wrap={false} style={styles.attachmentItem}>

						<View style={styles.attachmentThumb}>
                            <View style={styles.imageContainer}>
								{item.filename.split('.').pop().toLowerCase() === 'pdf' &&
								<Link src={credential_url + item.id + '/' + item.filename} style={styles.attachmentPdf}>
									<Image src="/pdf/pdf-document.png" alt="pdf" />
								</Link>
								}
								{item.filename.split('.').pop().toLowerCase() !== 'pdf' &&
								<Image style={styles.image}
									src={credential_url + item.id + '/' + item.filename} alt={item.filename} />
								}
							</View>
							<Text style={styles.credentialTitle}>{item.credential}</Text>
						</View>

					</View>
					}
					</>
					)}
				</View>
			} 
		  </View>
		  }	
</>
    )

}

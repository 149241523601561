import React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { API } from "aws-amplify";
import datelib from '../../../../libs/date-lib';
import '../../../../i18n';

import './sta_requirements.css';


export default function({var_pageready, set_activetab}){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();

    const [ var_indv_requirement_stat, set_indv_requirement_stat ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_pageready) {
            populate_indv_requirement_stat();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_pageready]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_indv_requirement_stat(){
        try {
            let results = await API_get_indv_requirements_stat();
            results[0]['alerts']=results[0]['invalid']+results[0]['expired'];
            set_indv_requirement_stat(results[0]);
        } catch (e) {
            console.log(e);
        }

        set_ready(true);
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_indv_requirements_stat(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('indv-credentials', '/get-indv-requirements-stat/'+JSON.parse(sessionStorage.getItem('authentication')).id,
        {
            queryStringParameters: {
                tz : datelib.timezone
            }
        });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_requirements(){
        set_activetab('REQUIREMENTS');
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="stat-card" id="sta_indv_overview_requirement" onClick={()=>onClick_requirements()}>
                {var_ready &&
                <>
                    {(var_indv_requirement_stat.total === 0) &&
                    <div className="requirement-bg requirement-none">
                        <div className="requirement-icon"><img src={"/icons/credential 60px (ffffff).svg?ts=" + Date.now()} alt={t('requirement icon')}></img></div>
                        <div className="requirement-text-wrapper">
                            <div className="requirement-title">{t('REQUIREMENTS')}</div>
                            <div className="requirement-subtitle">{t('You have no requirements')}</div>
                        </div>
                    </div>
                    }
                    {(var_indv_requirement_stat.total > 0 && var_indv_requirement_stat.invalid === 0 && var_indv_requirement_stat.comingdue === 0) &&
                    <div className="requirement-bg requirement-pass">
                        <div className="requirement-icon"><img src={"/icons/credential 60px (ffffff).svg?ts=" + Date.now()} alt={t('requirement icon')}></img></div>
                        <div className="requirement-text-wrapper">
                            <div className="requirement-title">{t('REQUIREMENTS')}</div>
                            <div className="requirement-subtitle">{t('No requirements require your attention')}</div>
                        </div>
                    </div>
                    }
                    {((var_indv_requirement_stat.invalid > 0) && var_indv_requirement_stat.comingdue === 0) &&
                    <div className="requirement-bg requirement-fail">
                        <div className="requirement-icon"><img src={"/icons/credential 60px (ffffff).svg?ts=" + Date.now()} alt={t('requirement icon')}></img></div>
                        <div className="requirement-title">{t('REQUIREMENTS')}</div>
                        {var_indv_requirement_stat.invalid === 1 &&
                        <div className="requirement-subtitle">{t('You have 1 requirement that requires your attention')}</div>
                        }
                        {var_indv_requirement_stat.invalid > 1 &&
                        <div className="requirement-subtitle">{t('You have')} {var_indv_requirement_stat.invalid} {t('requirements that require your attention')}</div>
                        }
                    </div>
                    }
                    {((var_indv_requirement_stat.invalid === 0) && var_indv_requirement_stat.comingdue > 0) &&
                    <div className="requirement-bg requirement-none">
                        <div className="requirement-icon"><img src={"/icons/credential 60px (ffffff).svg?ts=" + Date.now()} alt={t('requirement icon')}></img></div>
                        <div className="requirement-title">{t('REQUIREMENTS')}</div>
                        {var_indv_requirement_stat.comingdue === 1 &&
                        <div className="requirement-subtitle">{t('You have 1 requirement coming due')}</div>
                        }
                        {var_indv_requirement_stat.comingdue > 1 &&
                        <div className="requirement-subtitle">{t('You have')} {var_indv_requirement_stat.invalid} {t('requirements coming due')}</div>
                        }
                    </div>
                    }
                    {((var_indv_requirement_stat.invalid > 0) && var_indv_requirement_stat.comingdue > 0) &&
                    <div className="requirement-bg requirement-fail">
                        <div className="requirement-icon"><img src={"/icons/credential 60px (ffffff).svg?ts=" + Date.now()} alt={t('requirement icon')}></img></div>
                        <div className="requirement-title">{t('REQUIREMENTS')}</div>
                        {var_indv_requirement_stat.invalid === 1 &&
                        <div className="requirement-subtitle">{t('You have 1 requirement that requires your attention')}</div>
                        }
                        {var_indv_requirement_stat.invalid > 1 &&
                        <div className="requirement-subtitle">{t('You have')} {var_indv_requirement_stat.invalid} {t('requirements that require your attention')}</div>
                        }
                        {var_indv_requirement_stat.comingdue === 1 &&
                        <div className="requirement-subtitle">{t('You have 1 requirement coming due')}</div>
                        }
                        {var_indv_requirement_stat.comingdue > 1 &&
                        <div className="requirement-subtitle">{t('You have')} {var_indv_requirement_stat.comingdue} {t('requirements coming due')}</div>
                        }
                    </div>
                    }
                </>
                }
            </div>
        </>
    )

}
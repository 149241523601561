import {Button } from 'semantic-ui-react'
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import React from "react";
import XLSX from "xlsx";
import FileSaver from "file-saver";
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import './mdl_bulkinvite.css';
import CMP_FILEPROCESS from "../../../../../components/cmp_fileprocess/cmp_fileprocess";



export default function({ set_mdl_open, populate_function }){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();

    const [ var_bulkinvited, set_bulkinvited ] = useState(0);
    const [ var_inviteindividual, set_inviteindividual ] = useState([]);
    const [ var_ready, set_ready] = useState(false);
    const [ var_processing, set_processing] = useState(false);

    const [ var_fileuploadstatus, set_fileuploadstatus ] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [ var_fileuploadlabel, set_fileuploadlabel ] = useState('DATA FILE');
    const [ var_fileuploaddisplayname, set_fileuploaddisplayname ] = useState(null);
    const [ var_filedata, set_filedata ] = useState(null);



    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        const inviteindividual = { ...var_inviteindividual };
        inviteindividual['individual_type'] = 'USER';
        set_inviteindividual(inviteindividual);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(var_fileuploadstatus === 'PENDING'){
            set_ready(true);
        }
        if(var_fileuploadstatus === 'COMPLETE'){
            insert_bulkinvite();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_fileuploadstatus]);

    useEffect(()=>{
        if(var_bulkinvited.length>0){
            populate_function();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_bulkinvited]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function insert_bulkinvite(){

        let var_records = [];

        for(let data of var_filedata){
            let item = {};
            item['firstname'] = data['FIRST NAME'];
            item['lastname'] = data['LAST NAME'];
            item['email'] = data['EMAIL ADDRESS'];
            item['individual_type'] = 'STUDENT';
            item['subject_id'] = data['SUBJECT ID'];
            item['invitedby'] = JSON.parse(sessionStorage.getItem('authentication')).firstname + ' ' + JSON.parse(sessionStorage.getItem('authentication')).lastname;
            item['invitedbyemail'] = JSON.parse(sessionStorage.getItem('authentication')).email;
            item['organization'] = JSON.parse(sessionStorage.getItem('authentication')).actingorganization;
            item['organization_id'] = JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id;
            item['transactionby'] = JSON.parse(sessionStorage.getItem('authentication')).id;
            item['transactionorg'] = JSON.parse(sessionStorage.getItem('authentication')).organization_id;

            var_records.push(item);

        }

        try {
            await API_post_bulkinvite(var_records);
            set_bulkinvited(var_filedata.length);
            set_processing(false);
        } catch (e) {
            console.log(e);
        }

    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_bulkinvite(var_records){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-individuals', '/post-bulk-invite', { body: var_records });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_downloadtemplate(){

        let headers = [["FIRST NAME", "LAST NAME", "EMAIL ADDRESS", "SUBJECT ID"]];

        //  create excel spreadsheet -----------------------------------------------------------------------------------

        const wb = XLSX.utils.book_new();
        var ws = XLSX.utils.aoa_to_sheet(headers);
        XLSX.utils.book_append_sheet(wb, ws, 'Credential Holder Template');

        //  write file -------------------------------------------------------------------------------------------------

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
        FileSaver.saveAs(data, 'Bulk invite credential holder template.xlsx');

    }

    function onClick_submit(){
        if(var_ready){
            if(var_fileuploadstatus === 'PENDING') {
                set_fileuploadstatus('START');
                set_processing(true);
            }else{
                set_processing(true);
                insert_bulkinvite();
            }
        }
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{t('Bulk Invite Holders')}</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
            </div>
            <div className="modal-content">
                {var_bulkinvited === 0 ?
                    <CMP_FILEPROCESS
                        set_filedata={set_filedata}
                        var_fileuploadlabel={var_fileuploadlabel}
                        var_fileuploadstatus={var_fileuploadstatus}
                        set_fileuploadstatus={set_fileuploadstatus}
                        var_fileuploaddisplayname={var_fileuploaddisplayname}
                        set_fileuploaddisplayname={set_fileuploaddisplayname}
                        var_placeholder={'No file selected...'}>
                    </CMP_FILEPROCESS>
                    :
                    <div className="message success">
                        <div className="message_icon"><img src={"/icons/checkmark 60px (69b536).svg?ts=" + Date.now()} alt={t("info icon")}/></div>
                        <div className="message_text_wrapper">
                            <div className="message_text resolution">{var_bulkinvited} {t("invites were processed!")}</div>
                        </div>
                    </div>
                }
            </div>

            <div className="modal-footer">
                {var_bulkinvited === 0 ?
                    <div className="modal-footer-buttons">
                        <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>{t("CANCEL")}</Button>
                        <Button className="btn_secondary" onClick={() => onClick_downloadtemplate()}>{t("DOWNLOAD TEMPLATE")}</Button>
                        {!var_processing ?
                            <Button className={"btn_primary " + (var_ready ? ' btn_active' : '')} onClick={() => onClick_submit()}>SEND INVITES</Button>
                            :
                            <Button loading className="btn_primary btn_active">SEND INVITES</Button>
                        }
                    </div>
                    :
                    <div className="modal-footer-buttons">
                        <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>{t("CLOSE")}</Button>
                    </div>
                }
            </div>
        </>
    )
}

//  OUTCOMES -----------------------------------------------------------------------------------------------------------
//
//  - displays a requirement assignment / individual requirement and allows the user to accept or reject it
//
//  --------------------------------------------------------------------------------------------------------------------

import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Checkbox, Input } from 'semantic-ui-react';
import datelib from '../../../../../libs/date-lib';
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';
import auth from '../../../../../libs/auth-lib';

import CMP_CREDENTIALSTATUS from '../../../../../components/cmp_credentialstatus';
import CMP_SUCCESS from '../../../../../components/cmp_success/cmp_success';
import MDL_DOCVIEWER from '../../../../../components/cmp_docviewer/cmp_docviewer';

import './mdl_pending_assignment.css';



export default function({ var_mdl_open, set_mdl_open, populate_function, targetentity, targetentity_id, pending_assignment_id }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_pendingassignment, set_pendingassignment ] = useState({});
    const [ var_processing, set_processing ] = useState(false);

    const [ var_stage, set_stage ] = useState('REVIEW');
    const [ var_action, set_action ] = useState(null);
    const [ var_comments, set_comments ] = useState('');
    const [ var_transaction_id, set_transaction_id ] = useState(null);

    const [ var_mdl_docviewer_open, set_mdl_docviewer_open ] = useState(false);
    const [ var_docviewerid, set_docviewerid ] = useState(null);

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_mdl_open) {
            populate_pendingassignment();
        }
    },[var_mdl_open]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_pendingassignment(){
        let assignment = await API_get_pending_requirement_assignment();
        set_pendingassignment(assignment || {});
    }

    async function process_action(){
        set_processing(true);
        let data = {
            action: var_action.includes('ACCEPT') ? 'ACCEPT' : 'REJECT',
            comments: var_action.includes('ACCEPT') ? null : var_comments.trim(),
            always: var_action.includes('ALWAYS'),
            always_detail: (var_action.includes('ALWAYS') && var_action.includes('ISSUER')) ? 'ISSUER'
                : var_action.includes('ALWAYS') ? 'CREDENTIAL' : null
        };
        let results = await API_put_indv_requirement_acceptance(data);
        results && results.transaction_id && set_transaction_id(results.transaction_id);
        populate_function();
        set_stage(var_stage + ' COMPLETE');
        set_processing(false);
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_pending_requirement_assignment(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-credentials', '/get-pending-requirement-assignment/'+targetentity+'/'+targetentity_id+'/'+pending_assignment_id, {
            queryStringParameters: {
                tz : datelib.timezone,
                organization_id: auth.organization_id
            }
        });
    }

    function API_put_indv_requirement_acceptance(data){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.put('org-credentials', '/put-indv-requirement-acceptance/'+targetentity+'/'+targetentity_id+'/'+pending_assignment_id,
            {body: { ...data, transactionorg: JSON.parse(sessionStorage.getItem('authentication')).organization_id }}
        );
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_accept(){
        if (var_stage === 'REVIEW') {
            set_stage('ACCEPT');
            set_action((var_pendingassignment.credential_id && var_pendingassignment.credential_id !== '00000000-0000-0000-0000-000000000000') ? 'ACCEPT ALWAYS' : 'ACCEPT');
            set_comments('');
        } else if (!var_processing) {
            process_action();
        }
    }

    function onClick_reject(){
        if (var_stage === 'REVIEW') {
            set_stage('REJECT');
            set_action('REJECT');
            set_comments('');
        } else if (!var_processing && var_comments.trim().length > 0) {
            process_action();
        }
    }

    function onClick_back(){
        !var_processing && set_stage('REVIEW');
    }

    function onClick_close(){
        set_mdl_open(false);
    }

    function onChange_toggle(toggle){
        let credential, issuer;
        switch (toggle) {
            case 'ACCEPT CREDENTIAL':
                set_action(var_action === 'ACCEPT' ? 'ACCEPT ALWAYS' : 'ACCEPT');
                return;
            case 'REJECT CREDENTIAL':
                credential = !var_action.includes('CREDENTIAL');
                issuer = var_action.includes('ISSUER');
                break;
            case 'REJECT ISSUER':
                credential = var_action.includes('CREDENTIAL');
                issuer = !var_action.includes('ISSUER');
                break;
        }
        set_action('REJECT' + ((credential || issuer) ? ' ALWAYS' : '') + (credential ? ' CREDENTIAL' : '') + (issuer ? ' ISSUER' : ''));
    }

    function onChange_comments(event){
        set_comments(event.target.value)
    }

    function onClick_viewdocument(){
        set_docviewerid(var_pendingassignment.individual_credential_id);
        set_mdl_docviewer_open(true);
    }

    // RENDER APP ======================================================================================================

    return !auth.org_requirements
        ? null :
        (
        <>
            {!var_stage.endsWith('COMPLETE') && render_header()}
            {
                var_stage === 'REVIEW' ? render_review()
                    : var_stage === 'ACCEPT' ? render_accept()
                    : var_stage === 'REJECT' ? render_reject()
                    : var_stage === 'ACCEPT COMPLETE' ? render_accept_complete()
                    : var_stage === 'REJECT COMPLETE' ? render_reject_complete()
                    : null
            }
            
            {/***** MODAL: DOCUMENT VIEW ************************************************************************/}

            <Modal id='mdl_docviewer'
                dimmer={'inverted'}
                open={var_mdl_docviewer_open}
                onClose={() => set_mdl_docviewer_open(false)}>
                <MDL_DOCVIEWER
                    set_mdl_open={set_mdl_docviewer_open}
                    targetid={var_docviewerid}
                    classification='credential'
                />
            </Modal>

            {/***** END MODAL: DOCUMENT VIEW *********************************************************************/}
        </>
    )

    function render_header(){
        let title = var_stage === 'REVIEW' ? 'Credential Review' : var_stage === 'ACCEPT' ? 'Accept Credential' : var_stage === 'REJECT' ? 'Reject Credential' : 'Credential Review Complete';
        return (
            <div className='modal-header'>
                <div className='modal-header-title'>{title}</div>
                <div className='modal-header-close' onClick={() => set_mdl_open(false)}><img src={'/icons/x 60px (717473).svg?ts=' + Date.now()} alt={t('x icon')} /></div>
            </div>
        );
    }

    function render_review(){
        return (
            <>
                <div className='modal-content'>
                    <div className='content-card-content'>
                        <div className='detail-item'>
                            <div className='detail-item-label'>{t('INDIVIDUAL')}</div>
                            <div className='detail-item-text'>{var_pendingassignment.individual}</div>
                        </div>
                        <div className='detail-spacer'></div>
                        <div className='detail-item'>
                            <div className='detail-item-label'>{t('CREDENTIAL')}</div>
                            <div className='detail-item-text'>{var_pendingassignment.credential}</div>
                        </div>
                        <div className='detail-spacer'></div>
                        <div className='detail-item'>
                            <div className='detail-item-label'>{t('ISSUER')}</div>
                            <div className='detail-item-text'>{var_pendingassignment.issuedby}</div>
                        </div>
                        <div className='detail-spacer'></div>
                        <div className='detail-item'>
                            <div className='detail-item-label'>{t('STATUS')}</div>
                            <div className='detail-item-text detail-item-status'>
                                <CMP_CREDENTIALSTATUS confirmation_status={var_pendingassignment.confirmation_status} verification_status={var_pendingassignment.verification_status}
                                    clickfor_publiccert={true} id={var_pendingassignment.individual_credential_id} has_customsecurity={var_pendingassignment.has_customsecurity} />
                            </div>
                        </div>
                        {var_pendingassignment.filename && var_pendingassignment.filename !== '' && var_pendingassignment.filename !== 'No file selected...'  &&
                            <Button className='btn_tertiary' onClick={onClick_viewdocument}> {t('VIEW DOCUMENT')}</Button>
                        }
                    </div>
                </div>
                <div className='modal-footer'>
                    <div className='modal-footer-buttons'>
                        <Button loading={var_processing} className={'btn_primary ' + (var_pendingassignment ? 'btn_active' : '')} onClick={onClick_accept}>{t('ACCEPT')}</Button>
                        <Button loading={var_processing} className={'btn_primary ' + (var_pendingassignment ? 'btn_active' : '')} onClick={onClick_reject}>{t('REJECT')}</Button>
                    </div>
                </div>
            </>
        );
    }

    function render_accept(){
        let button_text = var_action === 'ACCEPT' ? 'ACCEPT THIS TIME ONLY' : 'ALWAYS ACCEPT';
        return (
            <>
                <div className='modal-content'>
                    <div className='content-card-content'>
                        {(var_pendingassignment.credential_id && var_pendingassignment.credential_id !== '00000000-0000-0000-0000-000000000000') ?
                        <Checkbox
                            toggle
                            className={(var_action === 'ACCEPT ALWAYS' ? 'toggle_active' : '')}
                            label={t('Always accept ') + ' ' + var_pendingassignment.credential}
                            onChange={() => onChange_toggle('ACCEPT CREDENTIAL')}
                            checked={var_action === 'ACCEPT ALWAYS'}
                        />
                        :
                        <div>
                            {t('Accept ') + ' ' + var_pendingassignment.credential}
                        </div>
                        }
                    </div>
                </div>
                <div className='modal-footer'>
                    <div className="modal-footer-archive">
                        <Button className="btn_tertiary" onClick={() => onClick_back()}>{t("GO BACK")}</Button>
                    </div>
                    <div className='modal-footer-buttons'>
                        <Button loading={var_processing} className='btn_primary btn_active' onClick={onClick_accept}>{t(button_text)}</Button>
                    </div>
                </div>
            </>
        );
    }

    function render_reject(){
        let button_text = var_action === 'REJECT' ? 'REJECT THIS TIME ONLY' : 'ALWAYS REJECT';
        return (
            <>
                <div className='modal-content'>
                    <div className='content-card-content'>
                        <div>
                            {t('Please provide more information as to why you are rejecting this credential:')}
                        </div>
                        <Form>
                            <Form.Group>
                                <Form.Field
                                    className='reject_comments'
                                    id='reject_comments'
                                    name='reject_comments'
                                    autoComplete='off'
                                    value={var_comments}
                                    control={Input}
                                    onChange={onChange_comments}
                                    label={t('REJECTION REASON')}
                                    placeholder={t('Rejection reason...')}
                                    maxLength={100}
                                />
                            </Form.Group>
                            {(var_pendingassignment.credential_id && var_pendingassignment.credential_id !== '00000000-0000-0000-0000-000000000000') &&
                            <>
                                <Form.Group>
                                    <Form.Field
                                        id='reject_credential'
                                        name='reject_credential'
                                        control={Checkbox}
                                        toggle
                                        className={(var_action.includes('CREDENTIAL') ? 'toggle_active' : '')}
                                        label={t('Always reject ') + ' ' + var_pendingassignment.credential}
                                        onChange={() => onChange_toggle('REJECT CREDENTIAL')}
                                        checked={var_action.includes('CREDENTIAL')}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field
                                        id='reject_issuer'
                                        name='reject_issuer'
                                        control={Checkbox}
                                        toggle
                                        className={(var_action.includes('ISSUER') ? 'toggle_active' : '')}
                                        label={t('Always reject any credentials from ') + ' ' + var_pendingassignment.issuedby}
                                        onChange={() => onChange_toggle('REJECT ISSUER')}
                                        checked={var_action.includes('ISSUER')}
                                    />
                                </Form.Group>
                            </>
                            }
                        </Form>
                    </div>
                </div>
                <div className='modal-footer'>
                    <div className="modal-footer-archive">
                        <Button className="btn_tertiary" onClick={() => onClick_back()}>{t("GO BACK")}</Button>
                    </div>
                    <div className='modal-footer-buttons'>
                        <Button loading={var_processing} className={'btn_primary' + (var_comments.trim().length > 0 ? ' btn_active' : '')} onClick={onClick_reject}>{t(button_text)}</Button>
                    </div>
                </div>
            </>
        );
    }

    function render_accept_complete(){
        return (
            <CMP_SUCCESS title={t('Credential Review Complete')} set_mdl_open={set_mdl_open} success_or_fail='SUCCESS' header_text={t('CREDENTIAL ACCEPTED')}
                transaction_id={var_transaction_id} body={
                    <>
                        <div>{t('You have accepted') + ' ' + var_pendingassignment.credential + ' ' + t('from') + ' ' + var_pendingassignment.individual}</div>
                        {var_action === 'ACCEPT ALWAYS' &&
                            <div>{t("Because you selected the 'Always accept' option, this credential will be automatically approved the next time anyone submits it.")}</div>
                        }
                    </>
                }
            />
        );
    }

    function render_reject_complete(){
        return (
            <CMP_SUCCESS title={t('Credential Review Complete')} set_mdl_open={set_mdl_open} success_or_fail='FAIL' header_text={t('CREDENTIAL REJECTED')}
                transaction_id={var_transaction_id} body={
                    <>
                        <div>{t('You have rejected') + ' ' + var_pendingassignment.credential + ' ' + t('from') + ' ' + var_pendingassignment.individual}</div>
                        {var_action === 'REJECT ALWAYS CREDENTIAL' ?
                            <div>{t("Because you selected the 'Always reject' option, this credential will be automatically rejected the next time it is submitted.")}</div>
                            : (var_action === 'REJECT ALWAYS ISSUER' || var_action === 'REJECT ALWAYS CREDENTIAL ISSUER') ?
                            <div>{
                                t("Becuase you selected the 'Always reject any credentials from") + ' ' + var_pendingassignment.issuedby + t("' option, all credentials from ") +
                                var_pendingassignment.issuedby + ' ' + t('will be automatically rejected the next time they are submitted.')}
                            </div>
                            : null
                        }
                    </>
                }
            />
        );
    }

};
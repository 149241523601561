/* eslint-disable react/jsx-pascal-case */
import {Button, Modal, Popup, Table} from 'semantic-ui-react';
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import '../../../../../../../i18n';
import datelib from '../../../../../../../libs/date-lib';

import CMP_TABLECONTROLS, { filter_helper } from "../../../../../../../components/cmp_tablecontrols/cmp_tablecontrols";

import './mdl_dailycheckotherloc.css';



export default function({var_individual, var_individual_id, set_mdl_open, populate_dailychecks}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_indv_dailychecks, set_indv_dailychecks] = useState({});
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(10);
    const [ var_offset, set_offset] = useState(0);
    const [ var_search, set_search ] = useState('');
    const [ var_sortby, set_sortby ] = useState('issuedepoch');
    const [ var_sortorder, set_sortorder ] = useState('descending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_dailycheck_id, set_dailycheck_id ] = useState('');

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        set_filter(filter_helper.initialize(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(()=>{
        if (var_filter.load){
            populate_indv_dailychecks(null,0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_filter])

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_indv_dailychecks(limit,offset,sortby,sortorder,search,filter){

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        if(search) {
            if(search === '-') {
                search = '';
            }
            set_search(search);
        }else{
            search=var_search
        }

        filter = filter ? filter : var_filter;

        try {
            let indv_dailychecks = await API_get_dailychecks(limit,offset,sortby,sortorder,search,filter);
            set_totalrows(indv_dailychecks[0].totalrows);
            set_indv_dailychecks(indv_dailychecks);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);

    }

    async function populate_filters(filtername,sortorder){
        try {
            return await API_get_dailychecks(filter_helper.maxFilterItems,0,filtername,sortorder,var_search,{},filtername);
        } catch (e) {
            console.log(e);
        }
    }

    async function download_indv_dailychecks(limit, offset){
        try {
            return await API_get_dailychecks(limit,offset,var_sortby,var_sortorder,var_search,var_filter);
        } catch (e){
            console.log(e);
            throw e;
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_dailychecks(limit,offset,sortby,sortorder,search,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-dailychecks',
            '/get-indv-dailychecks/'+JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id+'/'+var_individual_id,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    search: search,
                    tz: datelib.timezone,
                    filtername: filtername,
                    today: true
                },
                body: filter
            }
        );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_indv_dailychecks('',0,sortby,sortorder)
    }

    function onClick_mdl_close(){
        set_mdl_open(false)
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">{t("All Daily Checks (today)")} - {var_individual}</div>
            <div className="modal-content">
                <div className="content-filter">
                    <div className="content-filter-item item-pagination">
                        {var_ready && var_totalrows >= 1 &&
                            <CMP_TABLECONTROLS
                                var_limit={var_limit} var_offset={var_offset} var_ready={var_ready}
                                var_loading={var_loading}
                                total_rows={var_indv_dailychecks && var_indv_dailychecks.length > 0 ? var_indv_dailychecks[0]['totalrows'] : 0}
                                populatefunction={populate_indv_dailychecks}
                                downloadname='Daily Checks History' downloadfunction={download_indv_dailychecks}
                                var_filter={var_filter} set_filter={set_filter}
                                populatefilterfunction={populate_filters}
                                table_config={[
                                    {
                                        name: 'status',
                                        datatype: 'text',
                                        labelKey: 'STATUS',
                                        download: true,
                                        filter: true
                                    },
                                    {
                                        name: 'issued',
                                        datatype: 'text',
                                        labelKey: 'ISSUED',
                                        download: true,
                                        filter: false
                                    },
                                    {
                                        name: 'issuedepoch',
                                        datatype: 'date',
                                        labelKey: 'ISSUED',
                                        download: false,
                                        filter: true
                                    },
                                    {
                                        name: 'name',
                                        datatype: 'text',
                                        labelKey: 'TEMPLATE',
                                        download: true,
                                        filter: true
                                    },
                                    {
                                        name: 'location_name',
                                        datatype: 'text',
                                        labelKey: 'LOC/PROJ',
                                        download: true,
                                        filter: true
                                    },
                                ]}
                            />
                        }
                    </div>
                </div>
                <div className="tablewrapper">
                    {var_ready && var_totalrows >= 1 &&
                        <Table sortable compact className={(var_loading ? " tbl_loading" : "")}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell className="td_icon_center" sorted={var_sortby === 'status' ? var_sortorder : null} onClick={() => onClick_sort('status')}>{t("STATUS")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'issuedepoch' ? var_sortorder : null} onClick={() => onClick_sort('issuedepoch')}>{t("ISSUED")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'name' ? var_sortorder : null} onClick={() => onClick_sort('name')}>{t("TEMPLATE")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'location_name' ? var_sortorder : null} onClick={() => onClick_sort('location_name')}>{t("LOC/PROJ")}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {var_indv_dailychecks.map((item, i) =>
                                    <Table.Row key={i}>
                                        <Table.Cell className="td_icon_center">
                                            {item.status === 'PASS' &&
                                                <img src={"/icons/checkmark 60px (549E17).svg?ts=" + Date.now()} alt={t("pass daily check icon")}></img>
                                            }
                                            {item.status === 'FAIL' &&
                                                <img src={"/icons/x 60px (DB2430).svg?ts=" + Date.now()} alt={t("fail daily check icon")}></img>
                                            }
                                        </Table.Cell>
                                        <Table.Cell>{datelib.datetime_localized_format(item.issued)}</Table.Cell>
                                        <Table.Cell>{item.name}</Table.Cell>
                                        <Table.Cell>{item.location_name}</Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
                    }
                    {!var_loading && var_totalrows === 0 &&
                        <div className='emptytable'><img src={'/icons/alert 60px (e5e5e5).svg?ts=' + Date.now()} alt='alert icon'/>{t('there are no records to display')}</div>
                    }
                </div>
                <div className="content-filter">
                    <div className="content-filter-item item-pagination">
                        <CMP_TABLECONTROLS
                            var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                            total_rows={var_indv_dailychecks && var_indv_dailychecks.length > 0 ? var_indv_dailychecks[0]['totalrows'] : 0} populatefunction={populate_indv_dailychecks}
                        />
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_primary btn_active" onClick={() => onClick_mdl_close()}>{t("CLOSE")}</Button>
                </div>
            </div>

        </>
    )
}

// import { API } from "aws-amplify";
import { useEffect } from "react";
import React from "react";
import { Table, TableBody } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';

import './crd_documents.css';

export default function({var_documents}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------




    //  API calls ------------------------------------------------------------------------------------------------------




    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <>
            <section className="public_location_section">
                <div className="public_location_section_header">{t('Documents')}</div>
                <div className="public_location_section_body">
                    <div className="tablewrapper remove_table_pointer_event">
                        <Table unstackable compact>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell className="td_name">{t('NAME')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_issued1">{t('ISSUED')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_ack">{t("ACKNOWLEDGEMENTS")}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <TableBody>
                                {var_documents.map((item, i) =>
                                <Table.Row key={i}>
                                    <Table.Cell className="td_name">{item.name}</Table.Cell>
                                    <Table.Cell className="td_issued1">{item.issued}</Table.Cell>
                                    <Table.Cell className="td_ack">{item.acknowledgements}</Table.Cell>
                                </Table.Row>
                                )}
                            </TableBody>
                        </Table>
                        {var_documents.length === 0 &&
                            <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t('alert icon')}/>{t('there are no documents to display')}</div>
                        }
                    </div>
                </div>
            </section>
        </>
    )

}
//  OUTCOMES -----------------------------------------------------------------------------------------------------------
//
//  - displays list of pre-approvals/pre-rejects for a requirement, with the ability to add/remove a credential to the list
//
//  --------------------------------------------------------------------------------------------------------------------

import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { API } from 'aws-amplify';
import { Table, Button, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import '../../../../../../i18n';

import CMP_TABLECONTROLS, {filter_helper} from '../../../../../../components/cmp_tablecontrols/cmp_tablecontrols';
import MDL_ADDCREDENTIAL from './mdl_addcredential/mdl_addcredential';
import MDL_REMOVECREDENTIAL from './mdl_removecredential/mdl_removecredential';

import './tab_acceptreject.css';

function TAB_ACCEPTREJECT({ activetab, requirement_id, requirement_name, accept_or_reject, populate_function }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_records, set_records ] = useState(null);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(10);
    const [ var_offset, set_offset] = useState(0);
    const [ var_sortby, set_sortby ] = useState('issuer');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));

    const [ var_mdl_addcredential_open, set_mdl_addcredential_open ] = useState(false);
    const [ var_mdl_removecredential_open, set_mdl_removecredential_open ] = useState(false);
    const [ var_requirement_historical_id, set_requirement_historical_id ] = useState(null);

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(()=>{
        if (activetab === accept_or_reject) {
            set_filter(filter_helper.initialize(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activetab]);

    useEffect(()=>{
        if (var_filter.load){
            populate_records(null,0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_filter]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_records(limit,offset,sortby,sortorder,filter){
        if (activetab !== accept_or_reject) return; // Don't load data if tab is not visible

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_requirement_historical(limit,offset,sortby,sortorder,filter);
            set_totalrows(results.totalrows);
            set_records(results.results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
    }

    async function populate_filters(filtername,sortorder){
        try {
            return (await API_get_requirement_historical(filter_helper.maxFilterItems,0,filtername,sortorder,{},filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_results(limit,offset){
        try {
            return (await API_get_requirement_historical(limit,offset,var_sortby,var_sortorder,var_filter)).results;
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    async function on_data_updated(){
        set_requirement_historical_id(null);
        populate_records();
        populate_function && populate_function();
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_requirement_historical(limit,offset,sortby,sortorder,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-requirements',
            '/get-requirement-historical/'+requirement_id+'/'+(accept_or_reject === 'ACCEPT' ? 'VALID' : 'INVALID'),
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername
                },
                body: filter
            }
        );
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        } else {
            sortorder = sortorder === 'ascending' ? 'descending' : 'ascending';
        }
        populate_records('',0,sortby,sortorder)
    }

    function onClick_removecredential(id){
        set_requirement_historical_id(id);
        set_mdl_removecredential_open(true);
    }

    function onClick_addcredential(){
        set_mdl_addcredential_open(true);
    }

    // RENDER APP ======================================================================================================

    return (
        <div className="modal-tab">
            <div className='content-filter'>
                <div className='content-filter-item item-pagination'>
                    <CMP_TABLECONTROLS
                        var_limit={var_limit} var_offset={var_offset} var_ready={var_records !== null} var_loading={var_loading}
                        total_rows={var_totalrows} populatefunction={populate_records}
                        downloadname={'Pre-approvals - ' + (accept_or_reject === 'ACCEPT' ? 'Accept' : 'Reject')} downloadfunction={download_results}
                        var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                        table_config={[
                            {name: 'issuer', datatype: 'text', labelKey: 'ISSUER', download: true, filter: true},
                            {name: 'credential', datatype: 'text', labelKey: 'CREDENTIAL', download: true, filter: true}
                        ]}
                    />
                </div>
            </div>
            <div className='tablewrapper'>
                <Table sortable compact className={(var_loading ? 'tbl_loading' : '')}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell sorted={var_sortby === 'issuer' ? var_sortorder : null} onClick={() => onClick_sort('issuer')}>{t('ISSUER')}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'credential' ? var_sortorder : null} onClick={() => onClick_sort('credential')}>{t('CREDENTIAL')}</Table.HeaderCell>
                            <Table.HeaderCell className="td_icon_center"></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    {var_records && var_records.length > 0 &&
                    <Table.Body>
                        {var_records.map((item) =>
                            <Table.Row key={item.id}>
                                <Table.Cell>{item.issuer}</Table.Cell>
                                <Table.Cell>{item.credential}</Table.Cell>
                                <Table.Cell className="td_icon_center" onClick={() => onClick_removecredential(item.id)}><img src={"/icons/delete 18px (6B7280).svg?ts=" + Date.now()} alt={t("delete icon")}/></Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                    }
                </Table>
                {!var_loading && var_totalrows === 0 &&
                <div className='emptytable'><img src={'/icons/alert 60px (e5e5e5).svg?ts=' + Date.now()} alt='alert icon'/>{t('there are no pre-approvals to display')}</div>
                }
            </div>
            <div className='content-filter'>
                <div className='content-filter-item item-pagination'>
                    <CMP_TABLECONTROLS
                        var_limit={var_limit} var_offset={var_offset} var_ready={var_records !== null} var_loading={var_loading}
                        total_rows={var_totalrows} populatefunction={populate_records}
                    />
                </div>
            </div>
            <div className="modal-footer">
                <div className='modal-footer-buttons'>
                    <Button className='btn_primary btn_active' onClick={onClick_addcredential}>{t('ADD CREDENTIAL')}</Button>
                </div>
            </div>
            
            {/***** MODAL: ADD CREDENTIAL ***********************************************************************/}

            <Modal id='mdl_addcredential'
                dimmer={'inverted'}
                open={var_mdl_addcredential_open}
                onClose={() => set_mdl_addcredential_open(false)}>
                <MDL_ADDCREDENTIAL
                    var_mdl_open={var_mdl_addcredential_open}
                    set_mdl_open={set_mdl_addcredential_open}
                    requirement_id={requirement_id}
                    requirement_name={requirement_name}
                    accept_or_reject={accept_or_reject}
                    populate_function={on_data_updated} />
            </Modal>

            {/***** END MODAL: ADD CREDENTIAL *******************************************************************/}
            
            {/***** MODAL: REMOVE CREDENTIAL ********************************************************************/}

            <Modal id='mdl_removecredential'
                dimmer={'inverted'}
                open={var_mdl_removecredential_open}
                onClose={() => set_mdl_removecredential_open(false)}>
                <MDL_REMOVECREDENTIAL
                    var_mdl_open={var_mdl_removecredential_open}
                    set_mdl_open={set_mdl_removecredential_open}
                    requirement_name={requirement_name}
                    accept_or_reject={accept_or_reject}
                    issuer_credential_record={var_requirement_historical_id && var_records.find(item => item.id === var_requirement_historical_id)}
                    populate_function={on_data_updated} />
            </Modal>

            {/***** END MODAL: REMOVE CREDENTIAL ****************************************************************/}
        </div>
    )
}

TAB_ACCEPTREJECT.propTypes = {
    activetab: propTypes.oneOf(['ACCEPT', 'REJECT']).isRequired,
    requirement_id: propTypes.string.isRequired,
    requirement_name: propTypes.string.isRequired,
    accept_or_reject: propTypes.oneOf(['ACCEPT', 'REJECT']).isRequired,
    populate_function: propTypes.func
}

export default TAB_ACCEPTREJECT;
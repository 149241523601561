import React from 'react';
import propTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import '../../i18n';

import './cmp_success.css';

function CMP_SUCCESS ({ title, set_mdl_open, success_or_fail, header_text, body, transaction_id, footer }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    // RENDER ==========================================================================================================

    return (
        <div id='cmp_success_container'>
            <div className='modal-header'>
                <div className='modal-header-title'>{title}</div>
                <div className='modal-header-close' onClick={() => set_mdl_open(false)}><img src={'/icons/x 60px (717473).svg?ts=' + Date.now()} alt={t('x icon')} /></div>
            </div>
            <div className='modal-content'>
                <div className='content-card-content success_container'>
                    {
                        success_or_fail === 'SUCCESS'
                        ? <div className='success_icon'><img src={"/icons/checkmark 60px (69b536).svg?ts=" + Date.now()} alt={t('checkmark icon')}/></div>
                        : success_or_fail === 'FAIL'
                        ? <div className='success_icon'><img src={"/icons/x 60px (DB2430).svg?ts=" + Date.now()} alt={t('fail icon')}/></div>
                        : <div className='success_icon'><img src={"/icons/alert 60px (42afb6).svg?ts=" + Date.now()} alt={t('info icon')}/></div>
                    }
                    <div>
                        {header_text !== null && <div className={success_or_fail.toLowerCase() + '_text'}>{header_text}</div>}
                        {typeof body === 'string' ? <div>{body}</div> : body}
                        {transaction_id && <div className='transaction_id_text'>{t('Ledger Transaction ID') + ': ' + transaction_id}</div>}
                    </div>
                </div>
            </div>
            <div className='modal-footer'>
                {footer ||
                <div className='modal-footer-buttons'>
                    <Button className='btn_secondary btn_active' onClick={() => set_mdl_open(false)}>{t('CLOSE')}</Button>
                </div>}
            </div>
        </div>
    )
}

CMP_SUCCESS.propTypes = {
    title: propTypes.string.isRequired,
    set_mdl_open: propTypes.func.isRequired,
    success_or_fail: propTypes.oneOf(['SUCCESS', 'FAIL', 'INFO']).isRequired,
    header_text: propTypes.string,
    body: propTypes.oneOfType([propTypes.string, propTypes.element]).isRequired,
    transaction_id: propTypes.string,
    footer: propTypes.element
}

CMP_SUCCESS.defaultProps = {
    title: 'SUCCESS',
    success_or_fail: 'SUCCESS'
}

export default CMP_SUCCESS;
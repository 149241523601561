import {Button,Modal, Form, Input } from 'semantic-ui-react'
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import React from "react";
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import './mdl_update_bulkinvite.css';
import MDL_CONFIRMATION from "../../../../../components/cmp_confirmation/cmp_confirmation"



export default function({ var_bulkid,set_mdl_open,var_updatebulkinvite,onOpen_mdl_remove_bulkinvite, var_location }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const [ var_update_bulkinvite, set_update_bulkinvite ] = useState(var_updatebulkinvite);
    const { t } = useTranslation();
    const [ var_mdl_confirmation, set_mdl_confirmation ] = useState(false);
    const [ var_checkinviteindividual_exists, set_checkinviteindividual_exists ] = useState(false);
    const [ var_ready, set_ready] = useState(false);
    const [ var_processing, set_processing] = useState(false);




    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        const inviteindividual = { ...var_update_bulkinvite };
        inviteindividual['individual_type'] = 'USER';
        inviteindividual['permission_id'] = '4EBEB4CF-4B68-4A29-A8FF-01FBC744A898';
        inviteindividual['invitedby'] = JSON.parse(sessionStorage.getItem('authentication')).firstname + ' ' + JSON.parse(sessionStorage.getItem('authentication')).lastname;
        inviteindividual['location_id'] = var_location;
        inviteindividual['invitedbyemail'] = JSON.parse(sessionStorage.getItem('authentication')).email;
        inviteindividual['organization'] = JSON.parse(sessionStorage.getItem('authentication')).actingorganization;
        inviteindividual['organization_id'] = JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id;
        inviteindividual['transactionby'] = JSON.parse(sessionStorage.getItem('authentication')).id;
        inviteindividual['transactionorg'] = JSON.parse(sessionStorage.getItem('authentication')).organization_id;
        set_update_bulkinvite(inviteindividual);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=> {
        let var_validation = true;

        if (!var_update_bulkinvite["FIRST NAME"] || var_update_bulkinvite["FIRST NAME"] === '') {
            var_validation = false;
        }

        if (!var_update_bulkinvite["LAST NAME"] || var_update_bulkinvite["LAST NAME"] === '') {
            var_validation = false;
        }

        if (!var_update_bulkinvite["EMAIL ADDRESS"] || var_update_bulkinvite["EMAIL ADDRESS"] === '') {
            var_validation = false;
        } else {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|([\w-]+(?:\+[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*)|([\w-]+(?:\.[\w-]+)*)([\w-]+(?:\+[\w-]+)*)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(var_update_bulkinvite["EMAIL ADDRESS"])) {
                var_validation = false;
            }
        }

        set_ready(var_validation);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_update_bulkinvite]);


    //  async functions ------------------------------------------------------------------------------------------------
    async function checkinviteindividual(){
        set_checkinviteindividual_exists(false);
        set_processing(true);

        try {
            let result = await API_post_checkinviteindividual();
            if(result[0]['emails']>0){
                set_checkinviteindividual_exists(true);
                set_ready(false);
                set_processing(false);
            }else{
                invite_individual();
            }
        } catch (e) {
            console.log(e);
        }
    }

    async function invite_individual(){
        try {
            let inviteresult = await API_post_inviteindividual();
            if(inviteresult[0] === 'FAILED'){
                alert(t("There was a problem creating the invite.  Please log out and log back into the system and try again."))
            }else{
                set_ready(false);
                set_processing(false);
                onOpen_mdl_remove_bulkinvite(var_bulkid,true);
                set_mdl_open(false);
            }
        } catch (e) {
            console.log(e);
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_checkinviteindividual(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('admin', '/post-checkinviteindividual/'+JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id, { body: var_update_bulkinvite });
    }

    function API_post_inviteindividual(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-individuals', '/post-indv-invite', { body: var_update_bulkinvite });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_invite_input(event){
        
        if(event.target.name==='email'){
            event.target.value=event.target.value.toLowerCase().trim()
        }
        const inviteindividual = { ...var_update_bulkinvite };
        inviteindividual[event.target.name] = event.target.value;
        inviteindividual['email'] = inviteindividual['EMAIL ADDRESS'];
        inviteindividual['firstname'] = inviteindividual['FIRST NAME'];
        inviteindividual['lastname'] = inviteindividual['LAST NAME'];
        set_update_bulkinvite(inviteindividual);
        
    }

    function onClick_submit(){
        if(var_ready){
            checkinviteindividual();
        }
    }

    function reject_bulkinvite() {
        onOpen_mdl_remove_bulkinvite(var_bulkid)
    }

     function onClick_mdl_confirmation_open(){
         set_mdl_confirmation(true)
     }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{t("Update Individual Invite")}</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t('close icon')}/></div>
            </div>

            <div className="modal-content">
            {var_checkinviteindividual_exists &&
                <div className="message warning">
                    <div className="message_icon"><img src={"/icons/warning 60px (d91e18).svg?ts=" + Date.now()} alt={t("warning icon")}/></div>
                    <div className="message_text resolution">{JSON.parse(sessionStorage.getItem('authentication')).actingorganization} {t("already has an active individual with this email address.")}</div>
                </div>
                }
                <Form>
                    <Form.Field
                        id='firstname'
                        name='FIRST NAME'
                        value={var_update_bulkinvite["FIRST NAME"] || ''}
                        onChange={onChange_invite_input}
                        control={Input}
                        label={t("FIRST NAME")}
                        placeholder={t("First Name...")}
                    />
                    <Form.Field
                        id='lastname'
                        name='LAST NAME'
                        value={var_update_bulkinvite["LAST NAME"] || ''}
                        onChange={onChange_invite_input}
                        control={Input}
                        label={t("LAST NAME")}
                        placeholder={t("Last Name...")}
                    />
                    <Form.Field
                        id='email'
                        name='EMAIL ADDRESS'
                        value={var_update_bulkinvite["EMAIL ADDRESS"] || ''}
                        onChange={onChange_invite_input}
                        control={Input}
                        label={t("EMAIL")}
                        placeholder={t("Email...")}
                    />
                </Form>
            </div>

            <div className="modal-footer">
                <div className="modal-footer-buttons">
                <Button className="btn_secondary" onClick={() => onClick_mdl_confirmation_open(true)}>{t("Reject")}</Button>
                {!var_processing ?
                        <Button className={"btn_primary " + (var_ready ? ' btn_active' : '')} onClick={() => onClick_submit()}>{t("INVITE INDIVIDUAL")}</Button>
                        :
                        <Button loading className="btn_primary btn_active">{t("INVITE INDIVIDUAL")}</Button>
                    }
                </div>
            </div>
            
            {/***** MODAL: REJECT INVITE  ***************************************************************************/}

            <Modal id="mdl_confirmation"
                    dimmer={'inverted'}
                    open={var_mdl_confirmation}
                    onClose={() => set_mdl_confirmation(false)}>
                <MDL_CONFIRMATION
                    set_mdl_open={set_mdl_confirmation}
                    var_modaltitle="Comfirmation: Reject Invite"
                    var_message= "Are you sure want to reject this invite."
                    confirmation_function={reject_bulkinvite}
                />
            </Modal>

            {/***** END MODAL: REJECT INVITE  ***********************************************************************/}
        </>
        
    )
}


//  OUTCOMES -----------------------------------------------------------------------------------------------------------
// 
//  - the user can update an exisiting ORG requirement
//
//  --------------------------------------------------------------------------------------------------------------------

import { API } from 'aws-amplify';
import { Button, Checkbox, Form, Input, Modal } from 'semantic-ui-react';
import React, { useEffect, useState } from 'react';
import form_helper from '../../../../../../libs/form-lib';
import auth from '../../../../../../libs/auth-lib';
import { useTranslation } from 'react-i18next';
import '../../../../../../i18n';

import './tab_details.css';

import MDL_PREAPPROVALS from '../../../mdl_assignment_summary/mdl_preapprovals/mdl_preapprovals';


export default function({ set_mdl_open, var_activetab, var_requirement_id, populate_function }){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();

    const [ var_requirement, set_requirement ] = useState([]);
    const [ var_status, set_status ] = useState(false);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_validationerror, set_validationerror ] = useState(false);
    const [ var_validationerrormessage, set_validationerrormessage ] = useState('');
    const [ var_descriptioncount, set_descriptioncount ] = useState(0);

    const [ var_canupdaterequirement, set_canupdaterequirement ] = useState(false);
    const [ var_mdl_preapprovals_open, set_mdl_preapprovals_open ] = useState(false);
    const [ var_canupdatepreapprovals, set_canupdatepreapprovals ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        // set permission variables
        set_canupdaterequirement(auth.permission_id === auth.constants.permission_ids.Superadmin);
        set_canupdatepreapprovals([auth.constants.permission_ids.Admin, auth.constants.permission_ids.GlobalAdmin, auth.constants.permission_ids.Superadmin].includes(auth.permission_id)); // Admins and above
    },[]);

    useEffect(()=>{
        if(var_activetab === 'DETAILS') {
            populate_requirement();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_activetab]);

    useEffect(() => {
        if(var_requirement.id !== undefined) {
            const requirement = {...var_requirement};
            if (var_status) {
                requirement['status'] = 'ACTIVE';
            } else {
                requirement['status'] = 'ARCHIVE';
            }
            set_requirement(requirement);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_status]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_requirement(){
        try {
            let result = await API_get_requirement();
            if(result['status'] === 'ACTIVE'){
                set_status(true);
            }else{
                set_status(false);
            }
            result['organization_id'] = auth.actingorganization_id;
            result['transactionby'] = auth.id;
            result['transactionorg'] = auth.organization_id;
            set_requirement(result);
            set_descriptioncount(result.description?.length || 0);
        } catch (e) {
            console.log(e);
        }
    }

    async function update_requirement(){
        let requirement = { ...var_requirement };
        form_helper.cleanse_string_property(requirement, 'name');
        form_helper.cleanse_string_property(requirement, 'description');
        set_processing(true);
        try {
            await API_put_requirement(requirement);
            set_ready(false);
            set_processing(false);
            populate_requirement();
            populate_function();
        } catch (e) {
            console.log(e);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_requirement(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-requirements', '/get-requirement/'+var_requirement_id);
    }

    function API_put_requirement(requirement){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.put('org-requirements', '/put-requirement/'+var_requirement_id, { body: requirement });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(event){
        const requirement = { ...var_requirement };
        if (event.target.value === ''){
            requirement[event.target.name] = null;
        }else{
            requirement[event.target.name] = event.target.value.substring(0,500);
        }
        
        set_requirement(requirement);
        set_validationerror(false);
        set_ready(true);
    }

    function onChange_status(){
        set_status(!var_status);
        set_ready(true);
    }

    function onClick_preapprovals(){
        set_mdl_preapprovals_open(true);
    }

    function onClick_submit(){
        if(var_ready) {
            let var_validation = false;
            let var_validmessage = '';
            if (!form_helper.validate_required_string(var_requirement.name)) {
                var_validation = true;
                var_validmessage = 'Please provide a requirement name';
            }
            set_validationerrormessage(var_validmessage);
            set_validationerror(var_validation);
            if (var_validation === false) {
                update_requirement();
            }
        }
    }



    // RENDER APP ======================================================================================================

    return (
        <>
        <div className="modal-tab">
            <div id="tab_details">
                <Form>
                    <Form.Group>
                        <Checkbox
                            toggle
                            className={"status " + (var_status ? ' toggle_active' : '')}
                            label={var_status ? t('ACTIVE') : t('ARCHIVE')}
                            onChange={onChange_status}
                            checked={var_status || false}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                            className='name'
                            name='name'
                            value={var_requirement.name || ''}
                            control={Input}
                            onChange={onChange_input}
                            label={t('REQUIREMENT NAME')}
                            placeholder={t('Requirement name...')}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.TextArea
                            className='description'
                            name='description'
                            value={var_requirement.description || ''}
                            onChange={e => {onChange_input(e); set_descriptioncount(e.target.value.length)}}
                            label={t('DESCRIPTION')}
                            placeholder={t('Description...')}
                            maxLength={500}
                        />
                    </Form.Group>
                    <div className='count'>{var_descriptioncount}/500</div>
                </Form>
                {var_validationerror &&
                    <div className="message warning">
                        <div className="message_icon"><img src={"/icons/warning 60px (d91e18).svg?ts=" + Date.now()} alt={t('warning icon')} /></div>
                        <div className="message_text_wrapper">
                            <div className="message_text cause">{t(var_validationerrormessage)}</div>
                        </div>
                    </div>
                }
            </div>
            <div className="modal-footer">
                <div className="modal-footer-share">
                    {var_canupdatepreapprovals &&
                        <Button className="btn_tertiary" onClick={() => onClick_preapprovals()}><img className="btn_icon" src={"/icons/checkmark 30px (4dcbd4).svg?ts=" + Date.now()} alt={t("pre-approvals icon")}/>{t("PRE-APPROVALS")}</Button>
                    }
                </div>
                <div className="modal-footer-buttons">

                    <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>{t('CANCEL')}</Button>
                    {var_canupdaterequirement &&
                    !var_processing ?
                        <Button className={"btn_primary " + (var_ready ? 'btn_active' : '')} disabled={!var_ready} onClick={() => onClick_submit()}>{t('UPDATE REQUIREMENT')}</Button>
                        :
                        <Button loading className="btn_primary btn_active">{t('UPDATE REQUIREMENT')}</Button>
                    
                }
                </div>
            </div>
        </div>


        {/***** MODAL: PRE-APPROVALS ************************************************************************/}

        <Modal id='mdl_preapprovals'
            dimmer={'inverted'}
            open={var_mdl_preapprovals_open}
            onClose={() => set_mdl_preapprovals_open(false)}>
        <MDL_PREAPPROVALS
            set_mdl_open={set_mdl_preapprovals_open}
            requirement_id={var_requirement_id}
            requirement_name={var_requirement.name}
            populate_function={populate_requirement} />
        </Modal>

        {/***** END MODAL: PRE-APPROVALS ********************************************************************/}

        </>
    )

}
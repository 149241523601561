import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import Moment from 'moment';
import datelib from '../../../../../../../libs/date-lib';
import { useTranslation } from 'react-i18next';
import '../../../../../../../i18n';

import './sta_onsite.css';



export default function({ var_activetab, var_location_id }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_stat, set_stat] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [var_onsitedate, set_onsitedate ] = useState(new Date());


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_activetab === 'DETAILS') {
            populate_loc_stat()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_activetab]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_loc_stat(){
        try {
            let result = await API_get_loc_stat();
            set_stat(result);
        } catch (e) {
            console.log(e);
        }

    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_loc_stat(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-onsites', '/get-loc-stat/'+var_location_id+'/'+Moment(var_onsitedate).valueOf()+'?tz='+datelib.querystring_timezone);
    }



    //  event functions ------------------------------------------------------------------------------------------------


    // RENDER APP ======================================================================================================

    return (
        <>
            <div id="onsitesummary" className="summary_wrapper">
                <div className="summary_icon">
                    <img src={"/icons/flag 60px (717473).svg?ts=" + Date.now()} alt={t("location icon")}/>
                </div>
                <div className="summary_item">
                    <div className="summary_item_number green">{var_stat.onsite || 0}</div>
                    <div className="summary_item_text">{t("CONFIRM")}</div>
                    <div className="summary_item_text">{t("ONSITE")}</div>
                </div>
                <div className="summary_item">
                    <div className="summary_item_number red">{var_stat.offsite || 0}</div>
                    <div className="summary_item_text">{t("CONFIRM")}</div>
                    <div className="summary_item_text">{t("OFFSITE")}</div>
                </div>
                <div className="summary_item">
                    <div className="summary_item_number">{var_stat.missing || 0}</div>
                    <div className="summary_item_text">{t("CONFIRM")}</div>
                    <div className="summary_item_text">{t("MISSING")}</div>
                </div>
            </div>
        </>
    )

}
import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { Form, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import '../../../../../../../i18n';

import './mdl_addrequirement.css';

export default function({ var_mdl_open, set_mdl_open, package_id, addrequirement_function }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_requirements, set_requirements ] = useState([]);
    const [ var_selected_requirements, set_selected_requirements ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_processing, set_processing ] = useState(false);

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(()=>{
        if (var_mdl_open) {
            set_processing(false);
            populate_requirements();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_open]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_requirements() {
        try {
            let requirements = await API_get_dd_package_available_requirements();
            set_requirements(requirements);
        } catch (e) {
            console.log(e);
        }
    }

    async function add_requirements() {
        set_processing(true);
        try {
            if (addrequirement_function) {
                for (let requirement of var_selected_requirements) {
                    await addrequirement_function(requirement);
                }
            }
            set_processing(false);
            set_mdl_open(false);
        } catch (e) {
            console.log(e);
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    async function API_get_dd_package_available_requirements() {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-requirements','/get-dd-package-available-requirements/'+package_id);
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_submit() {
        if (var_selected_requirements.length>0) {
            add_requirements();
        }
    }

    function onChange_requirement(e, {value}) {
        set_selected_requirements(value);
        set_ready(value.length>0);
    }

    // RENDER APP ======================================================================================================

    return (
        <div id='mdl_addrequirement_container'>
            <div className="modal-header">
                <div className="modal-header-title">{t('Add Requirements')}</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
            </div>
            <div className='modal-content'>
                <div>{t('Select the requirement you want to add to the package.')}</div>
                <Form>
                    <Form.Group>
                        <Form.Select
                            className='requirement_ids'
                            name='requirement_ids'
                            label={t('REQUIREMENT')}
                            options={var_requirements}
                            value={var_selected_requirements || []}
                            onChange={onChange_requirement}
                            placeholder={t('Requirement...')}
                            fluid
                            multiple
                            selection
                            search
                            disabled={var_processing}
                        />
                    </Form.Group>
                </Form>
            </div>
            <div className='modal-footer'>
                <div className='modal-footer-buttons'>
                    <Button disabled={var_processing} className='btn_secondary btn_active' onClick={() => set_mdl_open(false)}>{t('CANCEL')}</Button>
                    <Button disabled={!var_ready} loading={var_processing} className={'btn_primary' + (var_ready ? ' btn_active' : '')} onClick={onClick_submit}>{t('ADD')}</Button>
                </div>
            </div>
        </div>
    )
}
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import Moment from "moment";
import datelib from '../../../../libs/date-lib';
import { useTranslation } from 'react-i18next';
import '../../../../i18n';

import './sta_credentials.css';



export default function(){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_credentialstat, set_credentialstat ] = useState({'valid': 0, 'comingdue': 0, 'invalid': 0, 'expired': 0, 'pending': 0});
    // eslint-disable-next-line no-unused-vars
    const [ var_date, set_date ] = useState(Moment().format('ll'));
    const [ var_credentialstat_chart, set_credentialstat_chart ] = useState([['title', 'value'], ['No expired certificates', 0], ['Expires in 30 days', 0], ['Pending verification', 0], ['Expired/missing', 0]]);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        populate_credentialstats();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_credentialstats(){
        try {
            let result = (await API_get_org_credentials_stat())[0];
            let credentialstat_chart =[
                ["title", "value"],
                ['No expired certificates', result.valid],
                ['Expires in 30 days', result.comingdue],
                ['Pending verification', result.pending],
                ['Expired/missing', result.invalid + result.expired]
            ];
            set_credentialstat_chart(credentialstat_chart);
            set_credentialstat(result);
        } catch (e) {
            console.log(e);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_credentials_stat(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-credentials', '/get-org-credentials-stat/'+JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id, {
            queryStringParameters: {
                tz : datelib.timezone
            }
        });
    }



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="stat-card" id="sta_org_overview_credentials">
                <div className="stat-card-header">
                    <div className="content-card-subheader_text">{t("Credentials as of")} {var_date}</div>
                </div>
                <div className="chartwrapper">
                    <div className="chart">
                        <Chart
                            width={'120px'}
                            height={'120px'}
                            chartType="PieChart"
                            data={var_credentialstat_chart}
                            options={{
                                'chartArea': {'width': '90%', 'height': '90%'},
                                colors: ['#549E17', '#549E17', '#F2AE02', '#DB2430'],
                                legend: {position: 'none'},
                                pieSliceText: "none",
                                pieHole: 0.8,
                            }}
                            rootProps={{'data-testid': '3'}}
                        />
                    </div>
                    <div className="chartlegend">
                        <div className="chartlegend-item">
                            <div className="chartlegend-item_icon"><img src={"/icons/circle 60px (549E17).svg?ts=" + Date.now()} alt={t("valid credentials icon")}></img></div>
                            <div className="chartlegend-item_text">{t("Valid credentials")} ({var_credentialstat.valid})</div>
                        </div>
                        <div className="chartlegend-item">
                            <div className="chartlegend-item_icon"><img src={"/icons/circle 60px (549E17).svg?ts=" + Date.now()} alt={t("expire soon credentials icon")}></img></div>
                            <div className="chartlegend-item_text">{t("Expires in 30 days")} ({var_credentialstat.comingdue})</div>
                        </div>
                        <div className="chartlegend-item">
                            <div className="chartlegend-item_icon"><img src={"/icons/circle 60px (F2AE02).svg?ts=" + Date.now()} alt={t("pending credentials icon")}></img></div>
                            <div className="chartlegend-item_text">{t("Pending verifications")} ({var_credentialstat.pending})</div>
                        </div>
                        <div className="chartlegend-item">
                            <div className="chartlegend-item_icon"><img src={"/icons/circle 60px (DB2430).svg?ts=" + Date.now()} alt={t("invalid credentials icon")}></img></div>
                            <div className="chartlegend-item_text">{t("Invalid credentials")} ({var_credentialstat.expired+var_credentialstat.invalid})</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
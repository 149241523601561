import {Modal, Table} from 'semantic-ui-react'
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import Moment from "moment";
import datelib from '../../../../libs/date-lib';
import { enCA, frCA } from 'date-fns/locale';
import auth from '../../../../libs/auth-lib';

import CMP_TABLECONTROLS, { filter_helper } from '../../../../components/cmp_tablecontrols/cmp_tablecontrols';
import MDL_INDV_DAILYCHECKS from "../../../org/overview/tab_dailycheck/mdl_indv_dailychecks/mdl_indv_dailychecks";
import MDL_INDV from "../../../org/individuals/mdl_indv/mdl_indv";
import { useTranslation } from 'react-i18next';
import '../../../../i18n';

import './crd_dailychecks.css';



export default function(appProps){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();
    const [ var_dailychecks, set_dailychecks ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(100);
    const [ var_offset, set_offset] = useState(0);
    const [ var_search, set_search ] = useState('');
    const [ var_sortby, set_sortby ] = useState('individual');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));
    const [ var_totalrows, set_totalrows ] = useState(0);

    const [var_dailycheckdates, set_dailycheckdates ] = useState([Moment().startOf('day').toDate(), Moment().startOf('day').toDate()]);

    const [ var_mdl_open, set_mdl_open ] = useState(false);
    const [ var_individual, set_individual ] = useState('');
    const [ var_individual_id, set_individual_id ] = useState('');

    const [ var_mdl_indv_open, set_mdl_indv_open ] = useState(false);
    const [ var_target_id, set_target_id ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        let filter = filter_helper.initialize(false);
            set_filter(filter);
            populate_dailychecks(null, null, null, null, null, filter);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_dailycheckdates]);

    useEffect(()=>{
        if(var_filter.load) {
        populate_dailychecks(null, 0);
        }
       },[var_filter]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_dailychecks(limit,offset,sortby,sortorder,search,filter){

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        if(search) {
            if(search === '-') {
                search = '';
            }
            set_search(search);
        }else{
            search=var_search
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_dailychecks(limit,offset,sortby,sortorder,search,filter);
            set_totalrows(results.totalrows);
            set_dailychecks(results.results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }


    async function populate_filters(filtername,sortorder){
        let results = {};
        try {
            results = await API_get_dailychecks(filter_helper.maxFilterItems,0,filtername,var_search,sortorder,{},filtername);                 
        } catch (e) {
            console.log(e);
        }
        return results?.results ? results.results : results;
    }

    async function download_org_dailychecks(limit, offset){
        let results = {};
        try {
            results = await API_get_dailychecks(limit,offset,var_sortby,var_sortorder,var_search,var_filter);
        } catch (e){
        console.log(e);
        throw e;
        }
        return results?.results ? results.results : results;
    }
    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_dailychecks(limit,offset,sortby,sortorder,search,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000)); 

        return API.post('org-dailychecks',
                        '/get-org-dailychecks-overall/'+JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id,
                        {
                            queryStringParameters: {
                                limit: limit,
                                offset: offset,
                                sortby: sortby,
                                sortorder: sortorder,
                                search:search,
                                tz: datelib.timezone,
                                filtername: filtername,
                                from_dailycheckdate: Moment(var_dailycheckdates[0]).valueOf(),
                                to_dailycheckdate: Moment(var_dailycheckdates[1]).valueOf()
                                },
                                body: filter
                        }
                        );             
    }




    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_dailychecks('',0,sortby,sortorder)
    }
  
    function onChange_dailycheckdates(event, data){
        if (data.value !== var_dailycheckdates) {
            set_dailycheckdates(data.value);
        }
    }

    function onClick_individual(var_target_id){
        set_target_id(var_target_id);
        set_mdl_indv_open(true);
    }

    function onOpen_mdl_history(var_individual_id,individual) {
        set_individual(individual);
        set_individual_id(var_individual_id);
        set_mdl_open(true);
    }

    function onApply_filter(filter){
        populate_dailychecks(null,0,null,null,null,filter);
    }
    

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="content-card" id="crd_org_dailychecks_dailychecks">
                <div className="content-card-header">
                    <div className="content-card-header-title">{t('Daily Checks')}</div>
                </div>
                <div className="content-card-gridcontent">
                    <div className="content-filter">
                        <SemanticDatepicker id='dailycheckdate'
                                            format={auth.language.name === 'French' ? 'D MMM YYYY' : 'MMM DD, YYYY'}
                                            formatOptions={{locale: auth.language.name === 'French' ? frCA: enCA}}
                                            locale={auth.language.datepicker}
                                            onChange={onChange_dailycheckdates}
                                            clearable={false}
                                            datePickerOnly={true}
                                            showToday={false}
                                            value={var_dailycheckdates}
                                            type="range"/>
                        <div className="content-filter-item item-pagination">
                        <CMP_TABLECONTROLS
                            var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                            total_rows={var_totalrows} populatefunction={populate_dailychecks}
                            downloadname='Organization Daily Checks' downloadfunction={download_org_dailychecks}
                            var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters} onApply_filter={onApply_filter}
                            table_config={[
                                {name: 'dailycheck', datatype: 'text', labelKey: 'TODAY', download: true, filter: true},
                                {name: 'individual', datatype: 'text', labelKey: 'NAME', download: true, filter: true},
                                {name: 'email', datatype: 'text', labelKey: 'EMAIL', download: true, filter: true},
                                {name: 'organization', datatype: 'text', labelKey: 'ORGANIZATION', download: true, filter: true},
                                {name: 'phone', datatype: 'text', labelKey: 'PHONE', download: true, filter: true},
                                {name: 'location', datatype: 'text', labelKey: 'LOC/PROJ', download: true, filter: true},
                                {name: 'businessunit', datatype: 'text', labelKey: 'BUSINESS UNIT', download: true, filter: true},
                                {name: 'fittoreturn', datatype: 'text', labelKey: 'RETURN DATE', download: true, filter: true},
                                {name: 'datecompleted', datatype:'text', labelKey: 'DATE COMPLETED', download: true, filter: false},
                                {name: 'issued', datatype: 'epoch', labelKey: 'DATE COMPLETED', download: false, filter: true}
                            ]}
                        />
                        </div>
                    </div>
                    <div className="tablewrapper">
                        <Table sortable compact className={(var_loading ? " tbl_loading" : "")}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell className="td_icon_center" sorted={var_sortby === 'dailycheck' ? var_sortorder : null} onClick={()=>onClick_sort('dailycheck')}>{t('TODAY')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'individual' ? var_sortorder : null} onClick={()=>onClick_sort('individual')}>{t('NAME')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_email" sorted={var_sortby === 'email' ? var_sortorder : null} onClick={()=>onClick_sort('email')}>{t('EMAIL')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_phone" sorted={var_sortby === 'phone' ? var_sortorder : null} onClick={()=>onClick_sort('phone')}>{t('PHONE')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'organization' ? var_sortorder : null} onClick={() => onClick_sort('organization')}>{t("ORGANIZATION")}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_location" sorted={var_sortby === 'location' ? var_sortorder : null} onClick={()=>onClick_sort('location')}>{t('LOC/PROJ')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_businessunit" sorted={var_sortby === 'businessunit' ? var_sortorder : null} onClick={()=>onClick_sort('businessunit')}>{t('BUSINESS UNIT')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'epochfittoreturn' ? var_sortorder : null} onClick={()=>onClick_sort('epochfittoreturn')}>{t('RETURN DATE')}</Table.HeaderCell>
                                    <Table.HeaderCell className="datecompleted" sorted={var_sortby === 'issued' ? var_sortorder: null} onClick={()=>onClick_sort('issued')}>{t('DATE COMPLETED')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_icon_center">{t('HSTRY')}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {var_ready && var_dailychecks && var_dailychecks.length > 0 &&
                            <Table.Body>
                                {var_dailychecks.map((item, i) =>
                                    <Table.Row key={i}>
                                        <Table.Cell  onClick={()=>onClick_individual(item.individual_id)} className="td_icon_center">
                                            {item.dailycheck === 'PASS' &&
                                            <div className='td_icon_center'> <img src={"/icons/checkmark 30px (69b536).svg?ts=" + Date.now()} alt={t('active icon')}/></div>
                                            }
                                            {item.dailycheck === 'FAIL' &&
                                            <div className='td_icon_center'><img src={"/icons/x 30px (e0454f).svg?ts=" + Date.now()} alt={t('inactive icon')} /></div>
                                            }
                                            {item.dailycheck === 'NONE' &&
                                            <div className='tbl_circle'><img src={"/icons/circle 30px (bcbebe).svg?ts=" + Date.now()} alt={t('no result icon')}/></div>
                                            }
                                        </Table.Cell>
                                        <Table.Cell className='td_individual'  onClick={()=>onClick_individual(item.individual_id)}><div className='tooltip'>{item.individual}</div></Table.Cell>
                                        <Table.Cell className="td_email" onClick={()=>onClick_individual(item.individual_id)}>{item.email}</Table.Cell>
                                        <Table.Cell className="td_phone" onClick={()=>onClick_individual(item.individual_id)}>{item.phone}</Table.Cell>
                                        <Table.Cell onClick={()=>onClick_individual(item.individual_id)}>{item.organization}</Table.Cell>
                                        <Table.Cell className="td_location" onClick={()=>onClick_individual(item.individual_id)}>{item.location}</Table.Cell>
                                        <Table.Cell className="td_businessunit" onClick={()=>onClick_individual(item.individual_id)}>{item.businessunit}</Table.Cell>
                                        <Table.Cell  onClick={()=>onClick_individual(item.individual_id)}>
                                            {item.fittoreturn !== null &&
                                            <>
                                                <div className='tbl_checkmark tbl_icon'><img src={"/icons/alert 30px (e0454f).svg?ts=" + Date.now()} alt={t('alert icon')}/></div>
                                                {datelib.date_localized_format(item.fittoreturn)}
                                            </>
                                            }
                                        </Table.Cell>
                                        <Table.Cell className='td_date_completed' onClick={()=>onClick_individual(item.individual_id)}>{item.datecompleted}</Table.Cell>
                                        <Table.Cell className="td_icon_center" onClick={() => onOpen_mdl_history(item.individual_id, item.individual)}><img src={"/icons/history 30px (bcbebe).svg?ts=" + Date.now()} alt={t('history icon')}></img></Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                            }
                        </Table>
                        {var_ready && var_totalrows === 0 &&
                        <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t('alert icon')}/>{t('there are no daily checks to display')}</div>
                        }
                    </div>
                    <div>
                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_totalrows} populatefunction={populate_dailychecks}
                            />
                        </div>
                    </div>
                </div>
            </div>



            {/***** MODAL: INDIVIDUAL DAILY CHECKS ***************************************************************/}

            <Modal id="mdl_indv_dailychecks"
                   dimmer={'inverted'}
                   open={var_mdl_open}
                   onClose={() => set_mdl_open(false)}>
                <MDL_INDV_DAILYCHECKS
                    var_individual={var_individual}
                    var_individual_id={var_individual_id}
                    set_mdl_indv_dailychecks_open={set_mdl_open}
                    populate_dailychecks={populate_dailychecks}>
                </MDL_INDV_DAILYCHECKS>
            </Modal>

            {/***** END MODAL: INDIVIDUAL DAILY CHECKS ***********************************************************/}




            {/***** MODAL: INDIVIDUAL ****************************************************************************/}

            <Modal id="mdl_org_individuals_indv"
                   dimmer={'inverted'}
                   open={var_mdl_indv_open}
                   onClose={() => set_mdl_indv_open(false)}>
                <MDL_INDV
                    set_mdl_open={set_mdl_indv_open}
                    var_individual_id={var_target_id}
                    populate_function={populate_dailychecks}>
                </MDL_INDV>
            </Modal>

            {/***** END MODAL: INDIVIDUAL ************************************************************************/}



        </>
    )

}
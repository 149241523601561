import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { Button, Dropdown } from 'semantic-ui-react'
import auth from '../../../../../../libs/auth-lib';
import datelib from "../../../../../../libs/date-lib";
import { v4 as uuidv4 } from "uuid";

import { useTranslation } from 'react-i18next';
import '../../../../../../i18n';


import './mdl_share_vc.css';



export default function({ set_mdl_open, target }){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();
    const [ var_credentials, set_credentials ] = useState([]);
    const [ var_inspector, set_inspector ] = useState({});
    const [ var_sharewith, set_sharewith ] = useState();

    const [ var_ready, set_ready] = useState(false);
    const [ var_processing, set_processing] = useState(false);




    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
       get_inspector();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let credentials = [{
            "type": "VerifiableCredential",
            "id": JSON.parse(target.json).id
        },
        {
            "type": "TrustedDigitalIdentity",
            "id": var_inspector.wallet_user_id
        }];

        set_credentials(credentials);
        set_ready(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_inspector]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function get_inspector() {
        try {
            let inspector = (await API_get_inspector(auth.id)).results[0];
            set_inspector(inspector);
        } catch (e) {
            console.log(e);
        }     
    }

    async function share_credentials() {
        try {
            let response = await API_post_org_credential_external();
            console.log(response);
            set_ready(false);
            set_processing(false);
            set_mdl_open(false);
        } catch (e) {
            console.log(e);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_inspector(inspector_id){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('verifiable-credentials', '/vc-wallet/indv/' + inspector_id,
        {
            queryStringParameters: {
                limit: "10",
                offset: "0",
                sortby: "clusterkey",
                sortorder: "descending",
                archive: "NO",
                tz: datelib.timezone
            }
        }
    );    }

    function API_post_org_credential_external(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('verifiable-credentials', '/post-asset-inspection-send/' + auth.organization_id,
            {
                body: {
                    credentials: var_credentials,
                    target: var_sharewith,
                    transactionId: uuidv4()
                }
            }
        );
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_submit() {
        set_processing(true);
        share_credentials();
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='modal-header'>
                <div className='modal-header-title'>
                    <div className='modal-header-img'>
                        <img src={'/icons/share 60px (8a8d8c).svg?ts=' + Date.now()} alt={t('share icon')} />
                    </div>
                    <div className='modal-header-text'>
                        {t('Send Inspection Presentation')}
                    </div>
                </div>
                <div className='modal-header-close' onClick={() => set_mdl_open(false)}><img src={'/icons/x 60px (717473).svg?ts=' + Date.now()} alt={t('x icon')} /></div>
            </div>
            <div className='modal-content'>
                <div>
                    <div className="detail-item">
                        <div className="detail-item-label">{t('ASSET INSPECTION REPORT ID')}</div>
                        <div className="detail-item-text">{target.id}</div>
                    </div>
                    <div className="detail-item">
                        <div className="detail-item-label">{t('INSPECTOR ID')}</div>
                        <div className="detail-item-text">{var_inspector?.wallet_user_id}</div>
                    </div>
                    <div className="detail-item">
                        <div className="detail-item-label">{t('SHARE WITH')}</div>
                        <Dropdown
                            className="detail-item-text"
                            placeholder={t('Share with...')}
                            fluid
                            selection
                            options={[{key: '1', value: 'https://api-staging.supplier.metaltrail.com', text: 'Metal Trail'}]}
                            onChange={(event, data) => set_sharewith(data.value)}
                        />
                    </div>

                </div>
            </div>
            <div className='modal-footer'>
                <div className='modal-footer-buttons'>
                    <Button className='btn_primary btn_active' disabled={!var_ready} loading={var_processing} onClick={onClick_submit}>{t('SEND PRESENTATION')}</Button>
                </div>
            </div>
        </>
    )
}

//  OUTCOMES -----------------------------------------------------------------------------------------------------------
//
//  - list all active requirements for the location along with the following stats:
//      -   indvs: number of individuals assigned to req (may not be equal to the number of indvs assigned to location)
//      -   valid: valid reqs that dont expire or dont expire within 30 days
//      -   invalid: credential proposals that have been rejected and not resubmitted or credentials that have been updated
//      -   requested: reqs that have not received a credential proposal
//      -   pending: reqs that have received a credential proposal but need a manual approval
//      -   comingdue: valid reqs expiring within 30 days
//      -   expired: valid reqs that have expired
//      -   missing: reqs for which the user declares not to have a credential
//
//  --------------------------------------------------------------------------------------------------------------------


import { API } from 'aws-amplify';
import React, {useEffect, useState} from 'react';
import {Button, Modal, Table} from 'semantic-ui-react';
import datelib from '../../../../../../libs/date-lib';
import { useTranslation } from 'react-i18next';
import '../../../../../../i18n';
import auth from '../../../../../../libs/auth-lib';

import CMP_TABLECONTROLS, {filter_helper} from '../../../../../../components/cmp_tablecontrols/cmp_tablecontrols';
import MDL_CONFIRMATION from '../../../../../../components/cmp_confirmation/cmp_confirmation';
import MDL_ASSIGNMENT_SUMMARY from '../../../../../org-credentials/requirements/mdl_assignment_summary/mdl_assignment_summary';
import MDL_ADD from './mdl_add/mdl_add';

import './tab_requirements.css';

export default function({ var_activetab, var_location_id, var_target, var_org_owns_location }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_requirements, set_requirements ] = useState([]);
    const [ var_requirement, set_requirement ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(10);
    const [ var_offset, set_offset] = useState(0);
    const [ var_search, set_search ] = useState('');
    const [ var_sortby, set_sortby ] = useState('requirement');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));

    const [ var_mdl_add_open, set_mdl_add_open ] = useState(false);

    const [ var_target_id, set_target_id ] = useState(false);
    const [ var_target_text, set_target_text ] = useState(false);
    const [ var_mdl_confirmation_open, set_mdl_confirmation_open ] = useState(false);

    const [ var_mdl_assignment_summary_open, set_mdl_assignment_summary_open ] = useState(false);
    const [ var_mdl_assignment_summary_details, set_mdl_assignment_summary_details ] = useState({});

    const [ var_mdl_id, set_mdl_id ] = useState('org-locations_mdl_addrequirement');


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_activetab === 'REQUIREMENTS') {
            set_filter(filter_helper.initialize(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_activetab]);

    useEffect(()=>{
        if(var_filter.load){
            populate_requirements(null,0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_filter]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_requirements(limit,offset,sortby,sortorder,search,filter){
        if (var_activetab !== 'REQUIREMENTS') return; // Don't load data if tab is not visible

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        if(search) {
            if(search === '-') {
                search = '';
            }
            set_search(search);
        }else{
            search=var_search
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_loc_requirements(limit,offset,sortby,sortorder,search,filter);
            set_requirements(results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername,sortorder){
        try {
            return await API_get_loc_requirements(filter_helper.maxFilterItems,0,filtername,sortorder,var_search,[],filtername);
        } catch (e) {
            console.log(e);
        }
    }

    async function download_loc_requirements(limit, offset){
        try {
            return await API_get_loc_requirements(limit, offset, var_sortby, var_sortorder, var_search, var_filter);
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    async function remove_requirement(){
        set_ready(false);
        set_loading(true);
        try {
            await API_delete_loc_requirement();
            populate_requirements();
        } catch (e) {
            console.log(e);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_loc_requirements(limit,offset,sortby,sortorder,search,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-credentials',
            '/get-loc-requirements/'+var_location_id,
            {
                queryStringParameters: {
                    limit : limit,
                    offset : offset,
                    sortby : sortby,
                    sortorder : sortorder,
                    search : search,
                    tz : datelib.timezone,
                    filtername : filtername
                },
                body: filter
            }
        );
    }

    function API_delete_loc_requirement(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.del('org-locations', '/delete-loc-requirement/' + var_target_id, { body: var_requirement });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_requirements('',0,sortby,sortorder)
    }

    function onOpen_mdl_addrequirement(){
        set_mdl_id('org-locations_mdl_addrequirement');
        set_mdl_add_open(true);
    }

    function onClick_removerequirement(event, var_target_id, var_target_text){
        event.stopPropagation();
        const requirement = {...var_requirement};
        requirement['location_id'] = var_location_id;
        requirement['organization_id'] = auth.actingorganization_id;
        requirement['transactionby'] = auth.id;
        requirement['transactionorg'] = auth.organization_id;
        set_requirement(requirement);

        set_target_id(var_target_id);
        set_target_text(var_target_text);
        set_mdl_confirmation_open(true);
    }

    function onClick_viewassignmentsummary(id, name){
        set_mdl_assignment_summary_details({ id, name });
        set_mdl_assignment_summary_open(true);
    }


    // RENDER APP ======================================================================================================

    return (
        <div className="modal-tab">
            <div className="content-filter">
                <div className="content-filter-item item-pagination">
                    <CMP_TABLECONTROLS
                        var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                        total_rows={var_requirements && var_requirements.length > 0 ? var_requirements[0]['totalrows'] : 0} populatefunction={populate_requirements}
                        downloadname='Requirements' downloadfunction={download_loc_requirements}
                        var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                        table_config={[
                            {name: 'requirement', datatype: 'text', labelKey: 'REQUIREMENT', download: true, filter: true},
                            {name: 'indvs', datatype: 'int', labelKey: 'INDVS', download: true, filter: true},
                            {name: 'requested', datatype: 'int', labelKey: 'REQUESTED', download: true, filter: true},
                            {name: 'validreq', datatype: 'int', labelKey: 'VALID', download: true, filter: true},
                            {name: 'pending', datatype: 'int', labelKey: 'PENDING', download: true, filter: true},
                            {name: 'comingdue', datatype: 'int', labelKey: '30 DAYS', download: true, filter: true},
                            {name: 'expired', datatype: 'int', labelKey: 'EXPIRED', download: true, filter: true},
                            {name: 'missing', datatype: 'int', labelKey: 'MISSING', download: true, filter: true},
                            {name: 'invalid', datatype: 'int', labelKey: 'INVALID', download: true, filter: true},
                            {name: 'organization_name', datatype: 'text', labelKey: 'ORGANIZATION', download: true, filter: true}
                        ]}
                    />
                </div>
            </div>

            <div className="tablewrapper">
                <Table id="tbl_requirements" sortable compact className={(var_loading ? " tbl_loading" : "")}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell sorted={var_sortby === 'requirement' ? var_sortorder : null} onClick={()=>onClick_sort('requirement')}>{t("REQUIREMENT")}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'indvs' ? var_sortorder : null} onClick={()=>onClick_sort('indvs')}>{t("INDVS")}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'validreq' ? var_sortorder : null} onClick={()=>onClick_sort('validreq')}>{t("VALID")}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'comingdue' ? var_sortorder : null} onClick={()=>onClick_sort('comingdue')}>{t("30 DAYS")}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'pending' ? var_sortorder : null} onClick={()=>onClick_sort('pending')}>{t("PENDING")}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'expired' ? var_sortorder : null} onClick={()=>onClick_sort('expired')}>{t("EXPIRED")}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'requested' ? var_sortorder : null} onClick={()=>onClick_sort('requested')}>{t("REQUESTED")}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'missing' ? var_sortorder : null} onClick={()=>onClick_sort('missing')}>{t("MISSING")}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'invalid' ? var_sortorder : null} onClick={()=>onClick_sort('invalid')}>{t("INVALID")}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'organization_name' ? var_sortorder : null} onClick={()=>onClick_sort('organization_name')}>{t("ORGANIZATION")}</Table.HeaderCell>
                            {[auth.constants.permission_ids.Admin, auth.constants.permission_ids.GlobalAdmin, auth.constants.permission_ids.Superadmin].includes(auth.permission_id) &&
                                var_org_owns_location &&
                            <Table.HeaderCell className="td_icon_center"></Table.HeaderCell>
                            }
                        </Table.Row>
                    </Table.Header>
                    {var_ready && var_requirements[0]['totalrows'] !== 0 &&
                    <Table.Body>
                        {var_requirements.map((item, i) =>
                            <Table.Row key={i} onClick={()=>onClick_viewassignmentsummary(item.id, item.requirement)}>
                                <Table.Cell >{item.requirement}</Table.Cell>
                                <Table.Cell >{item.indvs}</Table.Cell>
                                <Table.Cell className="td_green">{item.validreq}</Table.Cell>
                                <Table.Cell className="td_green">{item.comingdue}</Table.Cell>
                                <Table.Cell className="td_yellow">{item.pending}</Table.Cell>
                                <Table.Cell className="td_red">{item.expired}</Table.Cell>
                                <Table.Cell className="td_red">{item.requested}</Table.Cell>
                                <Table.Cell className="td_red">{item.missing}</Table.Cell>
                                <Table.Cell className="td_red">{item.invalid}</Table.Cell>
                                <Table.Cell>{item.organization_name}</Table.Cell>
                                {[auth.constants.permission_ids.Admin, auth.constants.permission_ids.GlobalAdmin, auth.constants.permission_ids.Superadmin].includes(auth.permission_id) &&
                                    var_org_owns_location &&
                                <Table.Cell className="td_icon_center" onClick={(event) => onClick_removerequirement(event, item.id, item.requirement)}><img src={"/icons/delete 18px (6B7280).svg?ts=" + Date.now()} alt={t("delete icon")}/></Table.Cell>
                                }
                            </Table.Row>
                        )}
                    </Table.Body>
                    }
                </Table>
                {var_ready && var_requirements[0]['totalrows'] === 0 &&
                <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t("alert icon")}/>{t("there are no requirements to display")}</div>
                }
            </div>

            <div className="content-filter">
                <div className="content-filter-item item-pagination">
                    <CMP_TABLECONTROLS
                        var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                        total_rows={var_requirements && var_requirements.length > 0 ? var_requirements[0]['totalrows'] : 0} populatefunction={populate_requirements}
                    />
                </div>
            </div>
            {(auth.has_access('ORG-LOC', 'write') && var_org_owns_location) &&
            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className={"btn_primary " + (var_ready ? 'btn_active' : '')} onClick={() => onOpen_mdl_addrequirement()}>{t("ADD REQUIREMENTS")}</Button>
                </div>
            </div>
            }
 
            {/***** MODAL: CONFIRMATION *******************************************************************************/}

            <Modal id="mdl_confirmation"
                   dimmer={'inverted'}
                   open={var_mdl_confirmation_open}
                   onClose={() => set_mdl_confirmation_open(false)}>
                <MDL_CONFIRMATION
                    set_mdl_open={set_mdl_confirmation_open}
                    var_modaltitle={t("Remove Requirement")}
                    var_message={t("Are you sure you want to remove") + ' ' + var_target_text + ' ' + t("from everyone in") + ' ' + var_target + t("?")}
                    confirmation_function={remove_requirement}>
                </MDL_CONFIRMATION>
            </Modal>

            {/***** END MODAL: CONFIRMATION **************************************************************************/}

            {/***** MODAL: SUMMARY ***********************************************************************************/}

            <Modal id="mdl_assignment_summary"
                   dimmer={'inverted'}
                   open={var_mdl_assignment_summary_open}
                   onClose={() => set_mdl_assignment_summary_open(false)}>
                <MDL_ASSIGNMENT_SUMMARY
                    var_mdl_open={var_mdl_assignment_summary_open}
                    set_mdl_open={set_mdl_assignment_summary_open}
                    populate_function={populate_requirements}
                    title={var_mdl_assignment_summary_details.name}
                    targetentity='LOCATION'
                    targetentity_id={var_location_id}
                    requirement_id={var_mdl_assignment_summary_details.id}
                    var_org_owns_location={var_org_owns_location}
                    />
            </Modal>

            {/***** END MODAL: SUMMARY *******************************************************************************/}

            {/***** MODAL: ADD ***************************************************************************************/}

            <Modal id={var_mdl_id}
                   dimmer={'inverted'}
                   open={var_mdl_add_open}
                   onClose={() => set_mdl_add_open(false)}
                   closeOnEscape={false}
                   closeOnDimmerClick={false}>
                <MDL_ADD
                    set_mdl_open={set_mdl_add_open}
                    populate_function={populate_requirements}
                    var_location_id={var_location_id}
                    set_mdl_id={set_mdl_id}>
                </MDL_ADD>
            </Modal>

            {/***** END MODAL: ASSIGN ********************************************************************************/}

        </div>
    )

};

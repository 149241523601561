import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Table, Modal } from 'semantic-ui-react';
import Moment from "moment";
import datelib from '../../../../../../libs/date-lib';
import { useTranslation } from 'react-i18next';
import '../../../../../../i18n';

import CMP_TABLECONTROLS, { filter_helper } from '../../../../../../components/cmp_tablecontrols/cmp_tablecontrols';
import CMP_CREDENTIALSTATUS from '../../../../../../components/cmp_credentialstatus';
import CMP_LEGEND from '../../../../../../components/cmp_legend/cmp_credential_legend/cmp_credential_legend';
import MDL_DOCVIEWER from "../../../../../../components/cmp_docviewer/cmp_docviewer";

import './tab_credentials.css';



export default function({ var_activetab, var_location_id }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_credentials, set_credentials ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(10);
    const [ var_offset, set_offset] = useState(0);
    const [ var_search, set_search ] = useState('');
    const [ var_sortby, set_sortby ] = useState('sortorder');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));

    const [ var_mdl_docviewer_open, set_mdl_docviewer_open ] = useState(false);
    const [ var_docviewerid, set_docviewerid ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_activetab === 'CREDENTIALS') {
            set_filter(filter_helper.initialize(false));
        }
    },[var_activetab]);

    useEffect(()=>{
        if(var_filter.load){
            populate_credentials(null,0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_filter]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_credentials(limit,offset,sortby,sortorder,search,filter){
        if (var_activetab !== 'CREDENTIALS') return; // Don't load data if tab is not visible

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        if(search) {
            if(search === '-') {
                search = '';
            }
            set_search(search);
        }else{
            search=var_search
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_loc_credentials(limit,offset,sortby,sortorder,search,filter);
            set_credentials(results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername,sortorder){
        try {
            return await API_get_loc_credentials(filter_helper.maxFilterItems,0,filtername,sortorder,var_search,[],filtername);
        } catch (e) {
            console.log(e);
        }
    }

    async function download_credentials(limit,offset){
        try {
            return await API_get_loc_credentials(limit,offset,var_sortby,var_sortorder,var_search,var_filter);
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_loc_credentials(limit,offset,sortby,sortorder,search,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-credentials',
            '/get-loc-credentials/'+var_location_id,
            {
                queryStringParameters: {
                    limit : limit,
                    offset : offset,
                    sortby : sortby,
                    sortorder: sortorder,
                    search : search,
                    tz : datelib.timezone,
                    filtername : filtername
                },
                body : filter
            }
        );
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_credentials('',0,sortby,sortorder)
    }

    function onClick_paperclip(id){
        set_docviewerid(id);
        set_mdl_docviewer_open(true);
    }

    // RENDER APP ======================================================================================================

    return (
        <div className="modal-tab" id="tab_credentials">
            <div className="content-filter">
                <div className="content-filter-item item-pagination">
                    <CMP_TABLECONTROLS
                        var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                        total_rows={var_credentials && var_credentials.length > 0 ? var_credentials[0]['totalrows'] : 0}  populatefunction={populate_credentials}
                        downloadname='Credentials' downloadfunction={download_credentials}
                        var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                        table_config={[
                            {name: 'verification_status', datatype: 'text', labelKey: 'STATUS', download: true, filter: true},
                            {name: 'individual', datatype: 'text', labelKey: 'INDIVIDUAL', download: true, filter: true},
                            {name: 'credential', datatype: 'text', labelKey: 'CREDENTIAL', download: true, filter: true},
                            {name: 'issuedby', datatype: 'text', labelKey: 'ISSUED BY', download: true, filter: true},
                            {name: 'epochissued', datatype: 'epoch', labelKey: 'ISSUED', download: true, filter: true},
                            {name: 'epochexpires', datatype: 'epoch', labelKey: 'EXPIRES', download: true, filter: true},
                            {name: 'daysleft', datatype: 'int', labelKey: 'DAYS LEFT', download: true, filter: true},
                            {name: 'submittedfilename', datatype: 'text', labelKey: 'FILE NAME', download: true, filter: true},
                            {name: 'confirmation_status', datatype: 'text', labelKey: 'VERIFICATION', download: true, filter: true}
                        ]}
                    />
                </div>
            </div>
            <div className="tablewrapper">
                <Table sortable compact className={(var_loading ? " tbl_loading" : "")}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell className="td_icon_center" sorted={var_sortby === 'verification_status' ? var_sortorder : null} onClick={() => onClick_sort('verification_status')}>{t("STATUS")}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'individual' ? var_sortorder : null} onClick={() => onClick_sort('individual')}>{t("INDIVIDUAL")}</Table.HeaderCell>
                            <Table.HeaderCell className="td_overflow" sorted={var_sortby === 'credential' ? var_sortorder : null} onClick={() => onClick_sort('credential')}>{t("CREDENTIAL")}</Table.HeaderCell>
                            <Table.HeaderCell className="td_issuedby" sorted={var_sortby === 'issuedby' ? var_sortorder : null} onClick={() => onClick_sort('issuedby')}>{t("ISSUED BY")}</Table.HeaderCell>
                            <Table.HeaderCell className="td_issued" sorted={var_sortby === 'epochissued' ? var_sortorder : null} onClick={() => onClick_sort('epochissued')}>{t("ISSUED")}</Table.HeaderCell>
                            <Table.HeaderCell className="td_expires" sorted={var_sortby === 'epochexpires' ? var_sortorder : null} onClick={() => onClick_sort('epochexpires')}>{t("EXPIRES")}</Table.HeaderCell>
                            <Table.HeaderCell className="td_daysleft" sorted={var_sortby === 'sortorder' ? var_sortorder : null} onClick={() => onClick_sort('sortorder')}>{t("DAYS LEFT")}</Table.HeaderCell>
                            <Table.HeaderCell className="td_paperclip" sorted={var_sortby === 'filename' ? var_sortorder : null} onClick={() => onClick_sort('filename')}><img src={"/icons/paperclip 30px (c9caca).svg?ts=" + Date.now()} alt={t("attachment icon")}/></Table.HeaderCell>
                            <Table.HeaderCell className="td_verification" sorted={var_sortby === 'confirmation_status' ? var_sortorder : null} onClick={() => onClick_sort('confirmation_status')}>{t("VERIFICATION")}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    {var_ready && var_credentials[0]['totalrows'] !== 0 &&
                    <Table.Body>
                        {var_credentials.map((item, i) =>
                            <Table.Row key={i} className={(item.verification_status === 'VALID' && item.confirmation_status === 'SELF VERIFIED') ? "self_verified" : ''}>
                                <Table.Cell className="td_icon_center" >
                                    {(item.verification_status === 'VALID' && item.confirmation_status !== 'SELF VERIFIED') &&
                                    <img src={"/icons/checkmark 30px (69b536).svg?ts=" + Date.now()} alt={t('active icon')}/>
                                    }
                                    {item.verification_status === 'INVALID' &&
                                    <img src={"/icons/x 30px (e0454f).svg?ts=" + Date.now()} alt={t('inactive icon')}/>
                                    }
                                    {item.verification_status === 'PENDING' &&
                                    <img src={"/icons/warning 30px (ffae00).svg?ts=" + Date.now()} alt={t('missing icon')}/>
                                    }
                                    {(item.verification_status === 'VALID' && item.confirmation_status === 'SELF VERIFIED') &&
                                    <img src={"/icons/checkmark 30px (4dcbd4).svg?ts=" + Date.now()} alt={t('self verified icon')}/>
                                    }
                                    {item.verification_status !== 'VALID' && item.verification_status !== 'INVALID' && 
                                    item.verification_status !== 'PENDING' && <img src={"/icons/circle 30px (bcbebe).svg?ts=" + Date.now()} alt={t('inactive icon')}/>}
                                </Table.Cell>
                                <Table.Cell>{item.individual}</Table.Cell>
                                <Table.Cell className="td_overflow">{item.credential}</Table.Cell>
                                <Table.Cell className="td_issuedby" >{item.issuedby}</Table.Cell>
                                <Table.Cell className="td_issued" >{item.epochissued !== null && item.epochissued !== undefined && item.epochissued !== "" ? Moment.unix(item.epochissued).utc(false).format('ll'): ''}</Table.Cell>
                                <Table.Cell className="td_expires" >{item.epochexpires !== null && item.epochexpires !== undefined && item.epochexpires !== "" ? Moment.unix(item.epochexpires).utc(false).format('ll') : ''}</Table.Cell>
                                <Table.Cell className="td_daysleft" >{item.daysleft}</Table.Cell>
                                <Table.Cell className="td_paperclip">
                                    {(item.filename !== null && item.filename !== '' && item.filename !== 'No file selected...') ?
                                        <div className='td_attachment' onClick={() => onClick_paperclip(item.id)}> <img src={"/icons/paperclip 60px (717473).svg?ts=" + Date.now()} alt={t("attachment icon")}/></div>
                                        :
                                        <div className='td_attachment'></div>
                                    }
                                </Table.Cell>
                                <Table.Cell className='td_verification tbl_credentialicon'>
                                    <CMP_CREDENTIALSTATUS confirmation_status={item.confirmation_status} verification_status={item.verification_status}
                                        clickfor_publiccert={true} id={item.id} has_customsecurity={item.has_customsecurity} />
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                    }
                </Table>
                {var_ready && var_credentials[0]['totalrows'] === 0 &&
                <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt="alert icon"/>{t("there are no required credentials to display")}</div>
                }
            </div>
            <div className="content-filter">
                <div className="content-filter-item item-pagination">
                    <CMP_TABLECONTROLS
                        var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                        total_rows={var_credentials && var_credentials.length > 0 ? var_credentials[0]['totalrows'] : 0}  populatefunction={populate_credentials}
                    />
                </div>
            </div>
            <div className="modal-footer">
                <CMP_LEGEND />
            </div>

            {/***** MODAL: DOCUMENT VIEW ************************************************************************/}

            <Modal id="mdl_docviewer"
                dimmer={'inverted'}
                open={var_mdl_docviewer_open}
                onClose={() => set_mdl_docviewer_open(false)}>
                <MDL_DOCVIEWER
                    set_mdl_open={set_mdl_docviewer_open}
                    targetid={var_docviewerid}
                    classification='credential'
                />
            </Modal>

            {/***** END MODAL: DOCUMENT VIEW *********************************************************************/}


        </div>
    )

};
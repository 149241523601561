import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Table } from 'semantic-ui-react';
import datelib from '../../../../../libs/date-lib';
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import CMP_TABLECONTROLS, { filter_helper } from "../../../../../components/cmp_tablecontrols/cmp_tablecontrols";

import './tab_locations.css';



export default function({ var_activetab, var_individual_id }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_locations, set_locations ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(10);
    const [ var_offset, set_offset] = useState(0);
    const [ var_search, set_search ] = useState('');
    const [ var_sortby, set_sortby ] = useState('code');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_archive, set_archive ] = useState('NO');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_activetab === 'LOCATIONS' && var_archive !== undefined) {
            set_filter(filter_helper.initialize(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_activetab, var_archive]);

    useEffect(()=>{
        if (var_filter.load){
            populate_locations(null,0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_filter]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_locations(limit,offset,sortby,sortorder,search,archive,filter){
        if (var_activetab !== 'LOCATIONS') return; // Don't load data if tab is not visible

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        if(search) {
            if(search === '-') {
                search = '';
            }
            set_search(search);
        }else{
            search=var_search
        }
        if(archive) {
            set_archive(archive);
        }else{
            archive=var_archive
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_indv_locations(limit,offset,sortby,sortorder,search,archive,filter);
            set_locations(results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername,sortorder){
        try {
            return await API_get_indv_locations(filter_helper.maxFilterItems,0,filtername,var_search,sortorder,var_archive,[],filtername);
        } catch (e) {
            console.log(e);
        }
    }

    async function download_locations(limit, offset){
        try {
            return await API_get_indv_locations(limit,offset,var_sortby,var_sortorder,var_search,var_archive,var_filter);
        } catch (e){
            console.log(e);
            throw e;
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_indv_locations(limit,offset,sortby,sortorder,search,archive,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-individuals',
                       '/get-indv-locations/'+JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id+'/'+var_individual_id,
                       {
                        queryStringParameters: {
                            limit: limit,
                            offset: offset,
                            sortby: sortby,
                            sortorder: sortorder,
                            search: search,
                            archive: archive,
                            tz: datelib.timezone,
                            filtername: filtername
                        },
                        body: filter
                    }
                );
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_locations('',0,sortby,sortorder)
    }


    // RENDER APP ======================================================================================================

    return (
        <div className="modal-tab">
            <div id="tab_locations">
                <div className="content-filter">
                    <div className="content-filter-item item-pagination">
                        <CMP_TABLECONTROLS
                            var_offset={var_offset} var_limit={var_limit} var_ready={var_ready} var_loading={var_loading}
                            total_rows={var_locations && var_locations.length > 0 ? var_locations[0]['totalrows'] : 0} populatefunction={populate_locations}
                            downloadname='Individual Locations & Projects' downloadfunction={download_locations}
                            var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                            table_config={[
                                {name: 'code', datatype: 'text', labelKey: 'CODE', download: true, filter: true},
                                {name: 'location_name', datatype: 'text', labelKey: 'LOC/PROJ', download: true, filter: true},
                                {name: 'address', datatype: 'text', labelKey: 'ADDRESS', download: true, filter: true},
                                {name: 'city', datatype: 'text', labelKey: 'CITY', download: true, filter: true},
                                {name: 'province', datatype: 'text', labelKey: 'PROV/STATE', download: true, filter: true},
                                {name: 'country', datatype: 'text', labelKey: 'COUNTRY', download: true, filter: true},
                                {name: 'individuals', datatype: 'int', labelKey: 'INDVS', download: true, filter: true}
                            ]}
                        />
                    </div>
                </div>
                <div className="tablewrapper">
                <Table sortable compact className={(var_loading ? " tbl_loading" : "")}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell sorted={var_sortby === 'code' ? var_sortorder : null} onClick={()=>onClick_sort('code')}>{t("CODE")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'location_name' ? var_sortorder : null} onClick={()=>onClick_sort('location_name')}>{t("LOC/PROJ")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'address' ? var_sortorder : null} onClick={()=>onClick_sort('address')}>{t("ADDRESS")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'city' ? var_sortorder : null} onClick={()=>onClick_sort('city')}>{t("CITY")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'province' ? var_sortorder : null} onClick={()=>onClick_sort('province')}>{t("PROV/STATE")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'country' ? var_sortorder : null} onClick={()=>onClick_sort('country')}>{t("COUNTRY")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'individuals' ? var_sortorder : null} onClick={()=>onClick_sort('individuals')}>{t("INDVS")}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {var_ready && var_locations[0]['totalrows'] !== 0 &&
                                <Table.Body>
                                   {var_locations.map((item, i) =>
                                        <Table.Row key={i}>
                                            <Table.Cell>{item.code}</Table.Cell>
                                            <Table.Cell>{item.location_name}</Table.Cell>
                                            <Table.Cell>{item.address}</Table.Cell>
                                            <Table.Cell>{item.city}</Table.Cell>
                                            <Table.Cell>{item.province}</Table.Cell>
                                            <Table.Cell>{item.country}</Table.Cell>
                                            <Table.Cell>{item.individuals}</Table.Cell>
                                        </Table.Row>
                                    )}
                                </Table.Body>
                            }
                        </Table>
                    {var_ready && var_locations[0]['totalrows'] === 0 &&
                    <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t("alert icon")}/>{t("there are no locations/projects to display")}</div>
                    }
                </div>
                <div className="content-filter">
                    <div className="content-filter-item item-pagination">
                        <CMP_TABLECONTROLS
                            var_offset={var_offset} var_limit={var_limit} var_ready={var_ready} var_loading={var_loading}
                            total_rows={var_locations && var_locations.length > 0 ? var_locations[0]['totalrows'] : 0} populatefunction={populate_locations}
                        />
                    </div>
                </div>
            </div>
        </div>
    )

};
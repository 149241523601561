import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import auth from '../../../libs/auth-lib';
import "./networks.css";

import CRD_NETWORKS from "./crd_networks/crd_networks";

import { useTranslation } from 'react-i18next';
import '../../../i18n';

export default function(appProps) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const history = useHistory();

    const { t } = useTranslation();

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if (auth.is_loggedin && auth.permission_id === auth.constants.permission_ids.Superadmin) {
            appProps.set_visibleframe(true);
            appProps.set_visiblemenu('ORGANIZATION')
            appProps.set_activemenu('/org/networks');
        }else{
            history.push('/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);



    //  async functions ------------------------------------------------------------------------------------------------

    //  API calls ------------------------------------------------------------------------------------------------------

    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return(
        <>
            {auth.is_loggedin &&
            <div className="content">
                <div
                    className="content-topmenu">{auth.organization} {t('Networks')}
                </div>
                <div className="content-wrapper">

                    <CRD_NETWORKS set_networksrefresh={appProps.set_networksrefresh} />

                </div>
            </div>
            }
        </>
    )
};

import {Button, Modal, Table} from 'semantic-ui-react';
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import '../../../../../../../i18n';
import datelib from '../../../../../../../libs/date-lib';

import CMP_TABLECONTROLS, { filter_helper } from '../../../../../../../components/cmp_tablecontrols/cmp_tablecontrols';
import MDL_CONFIRMATION from "../../../../../../../components/cmp_confirmation/cmp_confirmation";
import MDL_ADD from "./mdl_add/mdl_add";

import './mdl_manage.css';



export default function({set_mdl_open, var_modaltitle, populate_function, var_location, var_location_id }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_forms, set_forms] = useState({});
    const [ var_form, set_form ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(10);
    const [ var_offset, set_offset] = useState(0);
    const [ var_archive, set_archive ] = useState('NO');
    const [ var_sortby, set_sortby ] = useState('formname');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));

    const [ var_target_id, set_target_id ] = useState(false);
    const [ var_target_text, set_target_text ] = useState(false);
    const [ var_mdl_confirmation_open, set_mdl_confirmation_open ] = useState(false);

    const [ var_mdl_add_open, set_mdl_add_open ] = useState(false);


    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_filter.load){
            populate_forms(null,0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_filter]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_forms(limit,offset,sortby,sortorder,archive,filter) {

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        if(archive) {
            set_archive(archive);
        }else{
            archive=var_archive
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_forms(limit,offset,sortby,sortorder,archive,filter);
            set_forms(results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);

    }

    async function populate_filters(filtername,sortorder){
        try {
            return await API_get_forms(filter_helper.maxFilterItems, 0, filtername, sortorder, var_archive,{}, filtername);
        } catch (e) {
            console.log(e);
        }
    }

    async function download_forms(limit, offset){
        try {
            return await API_get_forms(limit, offset, var_sortby, var_sortorder, var_archive, var_filter);
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }

    async function remove_form(){
        set_ready(false);
        set_loading(true);
        try {
            await API_delete_loc_form();
            populate_forms();
        } catch (e) {
            console.log(e);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_forms(limit,offset,sortby,sortorder,archive,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-forms', '/get-loc-forms/'+var_location_id,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    archive: archive,
                    tz: datelib.timezone,
                    filtername: filtername
                },
                body: filter
            }
        );

    }

    function API_delete_loc_form(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.del('org-forms', '/delete-loc-form/'+var_form.form_id, { body: var_form });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_forms('',0,sortby,sortorder)
    }

    function onClick_removeform(var_form_id, var_target_text ){
        const form = {...var_form};
        form['form_id'] = var_form_id;
        form['location_id'] = var_location_id;
        form['individual_id'] = '00000000-0000-0000-0000-000000000000';
        form['organization_id'] = JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id;
        form['transactionby'] = JSON.parse(sessionStorage.getItem('authentication')).id;
        form['transactionorg'] = JSON.parse(sessionStorage.getItem('authentication')).organization_id;
        set_form(form);

        set_target_id(var_location_id);
        set_target_text(var_target_text);
        set_mdl_confirmation_open(true);
    }

    
    function onOpen_mdl_addform(){
        set_mdl_add_open(true);
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">{var_modaltitle}</div>
            <div className="modal-content">
                <div className="content-filter">
                    <div className="content-filter-item item-pagination">
                        <CMP_TABLECONTROLS
                            var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                            total_rows={var_forms && var_forms.length > 0 ? var_forms[0]['totalrows'] : 0} populatefunction={populate_forms}
                            downloadname='Location/Project Forms' downloadfunction={download_forms}
                            var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                            table_config={[
                                {name: 'formname', datatype: 'text', labelKey: 'FORM', download: true, filter: true},
                            ]}
                        />
                    </div>
                </div>
                <div className="tablewrapper">
                    <Table sortable compact className={(var_loading ? " tbl_loading" : "")}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell sorted={var_sortby === 'formname' ? var_sortorder : null} onClick={() => onClick_sort('formname')}>{t('FORM')}</Table.HeaderCell>
                                <Table.HeaderCell className="td_icon_center"></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        {var_ready && var_forms[0]['totalrows'] !== 0 &&
                        <Table.Body>
                            {var_forms.map((item, i) =>
                                <Table.Row key={i}>
                                    <Table.Cell>{item.formname}</Table.Cell>
                                    <Table.Cell className="td_icon_center" onClick={() => onClick_removeform(item.id, item.formname)}><img src={"/icons/delete 18px (6B7280).svg?ts=" + Date.now()} alt={t("delete icon")}/></Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                        }
                    </Table>
                    {var_ready && var_forms[0]['totalrows'] === 0 &&
                        <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t('alert icon')} />{t('there are no forms assigned to this location/project')}</div>
                    }
                </div>
                <div className="content-filter">
                    <div className="content-filter-item item-pagination">
                        <CMP_TABLECONTROLS
                            var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                            total_rows={var_forms && var_forms.length > 0 ? var_forms[0]['totalrows'] : 0} populatefunction={populate_forms}
                        />
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <div className="modal-footer-share">
                    <Button className="btn_tertiary" onClick={() => onOpen_mdl_addform()}><img className="btn_icon" src={"/icons/add 30px (4dcbd4).svg?ts=" + Date.now()} alt={t("add icon")}/>{t("ADD FORM")}</Button>
                </div>
                <div className="modal-footer-buttons">
                    <Button className="btn_primary btn_active" onClick={() => set_mdl_open(false)}>{t('CLOSE')}</Button>
                </div>
            </div>


            {/***** MODAL: CONFIRMATION *******************************************************************************/}

            <Modal id="mdl_confirmation"
                   dimmer={'inverted'}
                   open={var_mdl_confirmation_open}
                   onClose={() => set_mdl_confirmation_open(false)}>
                <MDL_CONFIRMATION
                    set_mdl_open={set_mdl_confirmation_open}
                    var_modaltitle={t("Remove Form")}
                    var_message={t("Are you sure you want to remove") + ' ' + var_target_text + ' ' + t("from") + ' ' + var_location + t("?")}
                    confirmation_function={remove_form}>
                </MDL_CONFIRMATION>
            </Modal>

            {/***** END MODAL: CONFIRMATION **************************************************************************/}

            {/***** MODAL: ADD ***************************************************************************************/}

            <Modal id="org-locations_mdl_addform"
                   dimmer={'inverted'}
                   open={var_mdl_add_open}
                   onClose={() => set_mdl_add_open(false)}>
                <MDL_ADD
                    set_mdl_open={set_mdl_add_open}
                    populate_function={populate_forms}
                    var_location_id={var_location_id}>
                </MDL_ADD>
            </Modal>

            {/***** END MODAL: ADD ***********************************************************************************/}

        </>
    )
}
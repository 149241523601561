import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import '../i18n';

function CMP_CREDENTIALSTATUS ({ verification_status, confirmation_status, clickfor_publiccert, id, has_customsecurity, var_linkid, is_location, is_organization = false }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();
    const containerstyle = {display: 'flex', alignItems: 'center'};
    const iconstyle = {width: 17, height: 17, margin: '1px 4px -3px 0'};

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_publiccertificate(event) {
        if (!clickfor_publiccert) return;
        event.stopPropagation();
        if(confirmation_status && confirmation_status !== 'MISSING' && confirmation_status !== 'PENDING' && has_customsecurity !== 'YES') {
            if (var_linkid === undefined) {
                if (is_organization) {
                    window.open('/public/orgcertificate/' + id);
                } else {
                    window.open('/public/certificate/' + id);
                }
            } else if (is_location) {
                window.open('/public/certificate/' + id + '/' + var_linkid + '/' + is_location);
            } else {
                window.open('/public/certificate/' + id + '/' + var_linkid);
            }
        }
    }

    // RENDER ==========================================================================================================

    return (
        <div className='cmp_confirmation' style={containerstyle} onClick={onClick_publiccertificate}>
            <div>
                {verification_status === 'VALID' && confirmation_status === 'SELF VERIFIED'
                    ? <img style={iconstyle} src={'/icons/credential 30px (4dcbd4).svg?ts=' + Date.now()} alt={t('self verified pass credential icon')} />
                    : verification_status === 'VALID'
                    ? <img style={iconstyle} src={'/icons/credential 30px (69b536).svg?ts=' + Date.now()} alt={t('pass credential icon')} />
                    : verification_status === 'INVALID'
                    ? <img style={iconstyle} src={'/icons/credential 30px (e0454f).svg?ts=' + Date.now()} alt={t('fail credential icon')} />
                    : verification_status === 'PENDING'
                    ? <img style={iconstyle} src={'/icons/credential 30px (ffae00).svg?ts=' + Date.now()} alt={t('missing icon')} />
                    : null
                }
            </div>
            {confirmation_status !== null &&
                <div className='cmp_confirmation_status_txt'>{t(confirmation_status)}</div>
            }
        </div>
    )
}

CMP_CREDENTIALSTATUS.propTypes = {
    verification_status: propTypes.string,
    confirmation_status: propTypes.string,
    clickfor_publiccert: propTypes.bool.isRequired,
    id: propTypes.string,
    has_customsecurity: propTypes.string
};
CMP_CREDENTIALSTATUS.defaultProps = {
    clickfor_publiccert: false,
    has_customsecurity: 'NO'
};

export default CMP_CREDENTIALSTATUS;

import React, { useEffect, useState } from "react";
import auth from "../../../../../libs/auth-lib"
import { API } from "aws-amplify";
import { Button, Form } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import './mdl_verifycredential.css';


export default function({set_mdl_open, populate_verifications, var_credential_ids}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_confirmation, set_confirmation ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_validationerror, set_validationerror ] = useState(false);
    const [ var_validationerrormessage, set_validationerrormessage ] = useState('');
    const [ var_isvalid, set_isvalid ] = useState(true);


    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        const confirmation = { ...var_confirmation };
        confirmation['organization_id'] = auth.actingorganization_id;
        confirmation['transactionby'] = auth.id;
        confirmation['transactionorg'] = auth.organization_id;
        confirmation['verification_status'] = 'VALID';
        confirmation['confirmation_status'] = 'VERIFIED';
        set_confirmation(confirmation);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Only run after initial render
        if (var_confirmation.organization_id) {
            let confirmation = { ...var_confirmation };

            if(var_isvalid) {
                confirmation['verification_status'] = 'VALID';
                confirmation['confirmation_status'] = 'VERIFIED';
            } else {
                confirmation['verification_status'] = 'INVALID';
                confirmation['confirmation_status'] = 'REJECTED';
            }
            set_confirmation(confirmation);
            set_validationerror(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_isvalid]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function update_confirmation(confirmation) {
        set_processing(true);
        try {
            await API_put_org_confirmation(confirmation);
            set_mdl_open(false);
            populate_verifications();
        } catch (e) {
            console.log(e);
        }
        set_processing(false);
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    async function API_put_org_confirmation(confirmation){
        for (let credential_id of var_credential_ids) {
            localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
            await API.put('org-credentials', '/put-org-verification/' + credential_id, { body: confirmation });
        }
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_status(e, { value }){
        const confirmation = { ...var_confirmation };
        confirmation['confirmation_status'] = value;
        set_confirmation(confirmation);
        set_validationerror(false);
    }

    function onClick_submit(){
        const confirmation = { ...var_confirmation };
        confirmation.confirmation_status = confirmation.confirmation_status?.trim();
        set_confirmation(confirmation);
        let var_validation = false;
        let var_validmessage = '';
        if (!var_confirmation.confirmation_status) {
            var_validation = true;
            var_validmessage = 'Please enter a confirmation status';
        }
        
        set_validationerrormessage(var_validmessage);
        set_validationerror(var_validation);
        if (!var_validation) {
            update_confirmation(confirmation);
        }
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{t('Verify Credentials')}</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
            </div>

            <div className="modal-content">
                <Form>
                    <Form.Group inline>
                        <Form.Radio
                            label={t('VALID')}
                            value='VALID'
                            checked={var_confirmation.verification_status === 'VALID'}
                            onChange={() => set_isvalid(true)}
                        />
                        < Form.Radio
                            label={t('INVALID')}
                            value='INVALID'
                            checked={var_confirmation.verification_status === 'INVALID'}
                            onChange={() =>  set_isvalid(false)}
                            />
                    </Form.Group>
                    <Form.Group>
                        <Form.Select
                            className='confirmation_status'
                            name='confirmation_status'
                            label={t('CONFIRMATION STATUS')}
                            options={var_confirmation.verification_status === 'VALID' ?
                                        [  
                                            {text: t('VERIFIED'), value: 'VERIFIED'},
                                            {text: t('PASS'), value: 'PASS'}
                                        ]
                                        :
                                        [
                                            {text: t('REJECTED'), value: 'REJECTED'},
                                            {text: t('UNABLE TO VERIFY'), value: 'UNABLE TO VERIFY'},
                                            {text: t('INCOMPLETE'), value: 'INCOMPLETE'}
                                        ]
                                    }
                            value={var_confirmation.confirmation_status || ''}
                            onChange={onChange_status}
                        />
                    </Form.Group>
                </Form>
                {var_validationerror &&
                    <div className="message warning">
                        <div className="message_icon"><img src={"/icons/warning 60px (d91e18).svg?ts=" + Date.now()} alt={t('warning icon')} /></div>
                        <div className="message_text_wrapper">
                            <div className="message_text cause">{t(var_validationerrormessage)}</div>
                        </div>
                    </div>
                }
            </div>
            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>{t('CANCEL')}</Button>
                    {!var_processing ?
                        <Button className={"btn_primary btn_active"} onClick={() => onClick_submit()}>{var_credential_ids.length > 0 ? t('VERIFY CREDENTIALS (')+var_credential_ids.length+')' : t('VERIFY CREDENTIALS')}</Button>
                        :
                        <Button loading className="btn_primary btn_active">{t('VERIFY CREDENTIALS')}</Button>
                    }
                </div>
            </div>
        </>
    )
}
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from 'semantic-ui-react';
import '../../../../../i18n';
import auth from '../../../../../libs/auth-lib';
import datelib from '../../../../../libs/date-lib';

import { useTranslation } from 'react-i18next';

import './mdl_update.css';

import CMP_TABLECONTROLS, { filter_helper } from '../../../../../components/cmp_tablecontrols/cmp_tablecontrols';
import MDL_CONFIRMATION from "../../../../../components/cmp_confirmation/cmp_confirmation";
import MDL_ADD from "./mdl_add/mdl_add"


export default function({ set_mdl_open, var_package_id, var_package_name, populate_function }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_forms, set_forms ] = useState([]);
    const [ var_deleteform, set_deleteform ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(false);
    const [ var_limit, set_limit] = useState(10);
    const [ var_offset, set_offset] = useState(0);
    const [ var_sortby, set_sortby ] = useState('form_name');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));
    const [ var_mdl_add_open, set_mdl_add_open ] = useState(false);
    const [ var_mdl_confirmation_open, set_mdl_confirmation_open ] = useState(false);
    const [ var_target_id, set_target_id ] = useState(false);
    const [ var_target_text, set_target_text ] = useState(false);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_filter.load) {
        populate_forms(null, 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_filter]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_forms(limit, offset, sortby, sortorder, filter){

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_package_forms(limit,offset,sortby,sortorder, filter);
            set_totalrows(results.totalrows);
            set_forms(results.results === undefined ? [] : results.results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername,sortorder){
        try {
            return (await API_get_package_forms(filter_helper.maxFilterItems, 0, filtername, sortorder,{}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_forms(limit,offset){
        try {
            return (await API_get_package_forms(limit, offset, var_sortby, var_sortorder, var_filter)).results;
        } catch(e) {
            console.log(e);
            throw e;
        }
    }

    async function remove_form(){
        set_ready(false);
        set_loading(true);
        try {
            await API_delete_package_form();
            populate_forms();
        } catch (e) {
            console.log(e);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_package_forms(limit,offset,sortby,sortorder,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-forms',
                        '/get-package-forms/' + var_package_id,
                        {
                            queryStringParameters: {
                            limit: limit,
                            offset: offset,
                            sortby: sortby,
                            sortorder: sortorder,
                            tz: datelib.timezone,
                            filtername: filtername
                            },
                            body: filter
                        }
                        );
    }

    function API_delete_package_form(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.del("org-forms",'/delete-package-form/' + var_target_id + '/' + var_package_id, {body: var_deleteform});

    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(e,sortby){
        var sortorder = var_sortorder;
        if (e.target === e.currentTarget){
            if (var_sortby !== sortby) {
                sortorder = 'ascending';
            }else {
                if(sortorder === 'ascending'){
                    sortorder = 'descending';
                }else if(sortorder === 'descending'){
                    sortorder = 'ascending';
                }
            }
            set_sortorder(sortorder);
            populate_forms('',0,sortby,sortorder);
        }
    }

    function onClick_addform(){
        set_mdl_add_open(true);
    }

    function onClick_removeform(var_target_id, var_target_text){
        const form = {...var_deleteform};
        form['form_id'] = var_target_id;
        form['organization_id'] = auth.actingorganization_id;
        form['transactionby'] = auth.id;
        form['transactionorg'] = auth.organization_id;
        set_deleteform(form);

        set_target_id(var_target_id);
        set_target_text(var_target_text);
        set_mdl_confirmation_open(true);
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{t('Update Form Package')}</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t('close icon')}/></div>
            </div>
            <div className="modal-tab" id="org_location_doc_tab">
            <div className="content-filter">
                <div className="content-filter-item item-pagination">
                        <CMP_TABLECONTROLS
                                var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_forms && var_forms.length > 0 ? var_totalrows : 0} populatefunction={populate_forms}
                                downloadname='Package Forms' downloadfunction={download_forms}
                                var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                                table_config={[
                                    {name: 'form_name', datatype: 'text', labelKey: 'NAME', download: true, filter: true},
                                    {name: 'form_status', datatype: 'text', labelKey: 'STATE', download: true, filter: true}
                                ]}
                            />
                        </div>
            </div>
            <div className="tablewrapper">
                <Table sortable compact className={(var_loading ? " tbl_loading" : "")}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell sorted={var_sortby === 'name' ? var_sortorder : null} onClick={(e)=>onClick_sort(e,'name')}>{t("NAME")}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'name' ? var_sortorder : null} onClick={(e)=>onClick_sort(e,'name')}>{t("STATE")}</Table.HeaderCell>
                            <Table.HeaderCell ></Table.HeaderCell>
                        </Table.Row>

                    </Table.Header>
                    {var_ready && var_totalrows !== 0 &&
                    <Table.Body>
                        {var_forms.map((item, i) =>
                            <Table.Row key={i} >
                                <Table.Cell>{item.form_name}</Table.Cell>
                                <Table.Cell>{item.form_status}</Table.Cell>
                                <Table.Cell className="td_icon_remove" onClick={() => onClick_removeform(item.form_id, item.form_name )}><img src={"/icons/delete 18px (6B7280).svg?ts=" + Date.now()} alt="delete icon"/></Table.Cell>

                            </Table.Row>
                        )}
                    </Table.Body>
                    }
                </Table>
                {var_ready && var_totalrows === 0 &&
                <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt="alert icon"/>{t("there are no forms to display")}</div>
                }
            </div>
            <div className="content-filter">
                <div className="content-filter-item item-pagination">
                    <CMP_TABLECONTROLS
                        var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                        total_rows={var_forms && var_forms.length > 0 ? var_totalrows : 0} populatefunction={populate_forms}
                    />
                </div>
            </div>
            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    {[auth.constants.permission_ids.Admin, auth.constants.permission_ids.GlobalAdmin, auth.constants.permission_ids.Superadmin].includes(auth.permission_id) &&
                    <Button className={'btn_primary ' + (var_ready ? ' btn_active' : '')} onClick={onClick_addform}>{t('ADD FORMS')}</Button>
                    }
                </div>
            </div>

            {/***** MODAL: ADD ***************************************************************************************/}

            <Modal id="org-form-packages_mdl_addform"
                   dimmer={'inverted'}
                   open={var_mdl_add_open}
                   onClose={() => set_mdl_add_open(false)}>
                <MDL_ADD
                    set_mdl_open={set_mdl_add_open}
                    populate_function={populate_forms}
                    var_package_id={var_package_id}
                    >

                </MDL_ADD>
            </Modal>

            {/***** END MODAL: ADD ***********************************************************************************/}

            {/***** MODAL: CONFIRMATION *******************************************************************************/}

            <Modal id="mdl_confirmation"
                   dimmer={'inverted'}
                   open={var_mdl_confirmation_open}
                   onClose={() => set_mdl_confirmation_open(false)}>
                <MDL_CONFIRMATION
                    set_mdl_open={set_mdl_confirmation_open}
                    var_modaltitle={'Remove Form'}
                    var_message={'Are you sure you want to remove ' + var_target_text + ' from ' + var_package_name + '?'}
                    confirmation_function={remove_form}>
                </MDL_CONFIRMATION>
            </Modal>

            {/***** END MODAL: CONFIRMATION **************************************************************************/}

            </div>

        </>
    )
}

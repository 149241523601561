import React from 'react';
//import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from '@react-pdf/renderer';

import Header from './Header';
import Credentials from './Credentials';
import Dailychecks from './Dailychecks';
import Onsite from './Onsite';
import CredentialsAttachments from './CredentialsAttachments';


export default function({var_indv, var_org, credential_url, var_linkid}){

    //  variable declarations ------------------------------------------------------------------------------------------
    //const { t, i18n } = useTranslation();

	const styles = StyleSheet.create({
		profileWrapper: {
			paddingHorizontal: 20,
			marginTop: 20,
		},
		profileContainer: {
			borderStyle: 'solid',
			borderWidth: 0,
			borderColor: '#ebebeb',
			paddingBottom: 15,
		}
	});

    // RENDER APP ======================================================================================================
    return(
		<View style={styles.profileWrapper} break>
			<View style={styles.profileContainer}>
				<Header var_detail = {var_indv.detail} var_org = {var_org} />

				{var_indv.credentials &&
					<Credentials var_credentials = {var_indv.credentials} var_org = {var_org} var_linkid={var_linkid}/>
				}

				{var_indv.dailychecks &&
					<Dailychecks var_dailycheck = {var_indv.dailychecks} />
				}

				{var_indv.onsite &&
					<Onsite var_onsite = {var_indv.onsite} />
				}

				{var_indv.credentials &&
					<CredentialsAttachments var_credentials={var_indv.credentials} credential_url={credential_url} />
				}
			</View>
		</View>
    )

}
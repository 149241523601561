import React from 'react';

import {Document, Page, Text, StyleSheet, Font} from '@react-pdf/renderer';

import Header from './report/Header';
import Summary from './report/Summary';
import Individuals from "./report/Individuals";
// import Requirements from "./report/Requirements";
import Documents from "./report/Documents";
import Profile from "./report/crd_profile/profile"


export default function({var_location, var_qrCodeURL, credential_url, var_linkid}){

  //  variable declarations ------------------------------------------------------------------------------------------
  Font.register({
		family: 'Roboto',
		fonts: [
			{ src: '/pdf/Roboto/Roboto-Regular.ttf', fontStyle: 'normal', fontWeight: 400 },
			{ src: '/pdf/Roboto/Roboto-Medium.ttf', fontStyle: 'normal', fontWeight: 500 },
			{ src: '/pdf/Roboto/Roboto-Bold.ttf', fontStyle: 'normal', fontWeight: 700 }
		]
	});

	Font.registerHyphenationCallback(word => (
		[word]
	  ));
	  
	const styles = StyleSheet.create({
		page: {
			backgroundColor: 'white',
			color: '#36484e',
			fontFamily: 'Roboto',
			paddingTop: 20,
			paddingBottom: 35,
		},
		pageNumber: {
			position: 'absolute',
			fontSize: 9,
			bottom: 10,
			left: 0,
			right: 0,
			textAlign: 'center',
			color: 'grey',
		},
	})

  //  event listeners ------------------------------------------------------------------------------------------------



  //  event functions ------------------------------------------------------------------------------------------------



  // RENDER APP ======================================================================================================

	return (
		<>
			<Document author="TerraHub" keywords="report" subject="Location/Project Report" title="Location/Project Report">
					<Page style={styles.page} size="A4" wrap>

						<Header detail = {var_location.detail} var_org={var_location.org[0]} var_qrCode = {var_qrCodeURL} />

						<Summary var_summary = {var_location.summary} />

						<Individuals var_indv = {var_location.individuals} />

						{/* <Requirements var_req = {var_location.requirements} /> */}

						{var_location.documents && var_location.documents != null &&
							<Documents var_doc = {var_location.documents} />
						}

						{var_location.individuals.map((item, i) =>
							<Profile key={i} var_indv={item} var_org={var_location.org[0]} credential_url={credential_url} var_linkid={var_linkid} />
						)}

						<Text 
							fixed
							style={styles.pageNumber}
							render={({ pageNumber, totalPages }) => (
								`${pageNumber} / ${totalPages}`
							)} 
						/>

					</Page>
			</Document>
		</>
  	)
}
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import auth from '../../../libs/auth-lib';
import "./forms.css";

import { useTranslation } from 'react-i18next';
import '../../../i18n';


import CRD_ACTIVE_FORMS from "./crd_active_forms/crd_active_forms";
import CRD_PACKAGES from "./crd_packages/crd_packages";
import CRD_FORMS from "./crd_forms/crd_forms"


export default function(appProps) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();
    const history = useHistory();
    const [ var_activetab, set_activetab ] = useState('ACTIVEFORMS');



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if (auth.is_loggedin && auth.permission_id === auth.constants.permission_ids.Superadmin) {
            appProps.set_visibleframe(true);
            appProps.set_visiblemenu('ORGANIZATION')
            appProps.set_activemenu('/org/forms');
        }else{
            history.push('/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    //  async functions ------------------------------------------------------------------------------------------------

    //  API calls ------------------------------------------------------------------------------------------------------

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_tab(var_tab){
        set_activetab(var_tab);
    }


    // RENDER APP ======================================================================================================

    return(
        <>
            {auth.is_loggedin &&
            <div className="content">
                <div className="content-topmenu">
                    {auth.organization} {t('Forms')}
                </div>
                <div className="content-sidemenu-wrapper">
                    <div className="content-sidemenu">
                        <div className={"content-sidemenu-item " + (var_activetab === 'ACTIVEFORMS' ? 'content-sidemenu-item-active' : '')} onClick={() => onClick_tab('ACTIVEFORMS')}>
                            <div className="content-sidemenu-item_header">{t('All Active Forms')}</div>
                            <div className="content-sidemenu-item_subheader">{t('All active forms and their assignee')}</div>
                        </div>
                        <div className={"content-sidemenu-item " + (var_activetab === 'FORMS' ? 'content-sidemenu-item-active' : '')} onClick={() => onClick_tab('FORMS')}>
                            <div className="content-sidemenu-item_header">{t('Forms')}</div>
                            <div className="content-sidemenu-item_subheader">{t('All forms linked to Credivera')}</div>
                        </div>
                        <div className={"content-sidemenu-item " + (var_activetab === 'PACKAGES' ? 'content-sidemenu-item-active' : '')} onClick={() => onClick_tab('PACKAGES')}>
                            <div className="content-sidemenu-item_header">{t('Packages')}</div>
                            <div className="content-sidemenu-item_subheader">{t('Manage forms packages')}</div>
                        </div>
                    </div>
                    <div className="content-wrapper">
                        <div className={(var_activetab !== 'ACTIVEFORMS' ? 'content-card-hide' : '')}>
                            <CRD_ACTIVE_FORMS
                                var_activetab={var_activetab} 
                            />
                        </div>
                        <div className={(var_activetab !== 'FORMS' ? 'content-card-hide' : '')}>
                            <CRD_FORMS
                                var_activetab={var_activetab}
                            />
                        </div>
                        <div className={(var_activetab !== 'PACKAGES' ? 'content-card-hide' : '')}>
                            <CRD_PACKAGES
                                var_activetab={var_activetab}
                            />
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    )
};

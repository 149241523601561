/* eslint-disable array-callback-return */
import { Button, Form, Input } from 'semantic-ui-react';
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import datelib from '../../../../../../../libs/date-lib';

import { useTranslation } from 'react-i18next';
import '../../../../../../../i18n';

import './mdl_addlink.css';

export default function({ set_mdl_sharing_open, var_individual_id, populate_function }){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();
    const [ var_link, set_link] = useState(false);
    const [ var_ready, set_ready] = useState(false);
    const [ var_processing, set_processing] = useState(false);

    const [ var_locations, set_locations ] = useState([]);
    const [ var_indv_credentials, set_indv_credentials ] = useState([]);
    const [ var_credentials, set_credentials ] = useState([])

    const [ var_selected_locations, set_selected_locations ] = useState([]);
    const [ var_selected_credentials, set_selected_credentials] = useState([]);

    // eslint-disable-next-line no-unused-vars
    const [ var_loc_credentials, set_loc_credentials ] = useState([]);

    // eslint-disable-next-line no-unused-vars
    const [ var_limit, set_limit] = useState(100);
    // eslint-disable-next-line no-unused-vars
    const [ var_offset, set_offset] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [ var_sortby_cred, set_sortby_cred ] = useState('sortorder');
    // eslint-disable-next-line no-unused-vars
    const [ var_sortorder, set_sortorder ] = useState('ascending');

    // eslint-disable-next-line no-unused-vars
    const [ var_status, set_status ] = useState('ACTIVE');

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_link.transactionby === undefined){
            const link = {...var_link};
            link['individual_id'] = var_individual_id;
            link['organization_id'] = JSON.parse(sessionStorage.getItem('authentication')).organization_id;
            link['transactionby'] = JSON.parse(sessionStorage.getItem('authentication')).id;
            link['transactionorg'] = JSON.parse(sessionStorage.getItem('authentication')).organization_id;
            set_link(link);
        }
        populate_locations();
        populate_indv_credentials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_link.transactionby]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function insert_profilelink(){
        set_processing(true);
        try {
            await API_post_indv_profilelink();
            set_ready(false);
            set_processing(false);
            set_mdl_sharing_open(false);
            populate_function();
        } catch (e) {
            console.log(e);
        }
    }

    async function populate_locations(){

        let locations = await API_get_indv_locations();

        let locations_options = [];
        if(locations.length > 0){
            locations_options.push({ text:'Select All', value:'selectall'});
            locations.map((item, i) =>
                item.location_name !== 'undefined' && item.location_name !== null &&
                locations_options.push({  text:item.text, value:item.value })
            )
        }

        set_locations(locations_options);
    }

    async function populate_credentials(selected_options, credential_type){

        let limit = var_limit;
        let offset = var_offset;
        let sortby = var_sortby_cred;
        let sortorder = var_sortorder;
        let search = '';
        let filter = '';
        let locCred = [];

        for(let option of selected_options) {
            try {
                let results = '';
                if(credential_type === 'location'){
                    results = await API_get_loc_credentials(limit,offset,sortby,sortorder,search,option,filter);
                }

                let creds = [];
                if(results[0].totalrows !== 0){

                    let res = [];

                    if (results.length > 0)
                    {
                        results.map((item, i)=>{
                            if(item.credential !== 'undefined' && item.credential !== null && !var_credentials.hasOwnProperty(item.id))
                            {
                                let found = false;
                                if(credential_type === 'location'){
                                    found = locCred.some(el => el.id === item.id);
                                }

                                if(!found) { res.push(item); }
                            }
                        });

                    }

                    res.map((item, i) =>
                        item.credential !== 'undefined' && item.credential !== null && !var_credentials.hasOwnProperty(item.id) &&
                        creds.push({
                            'value':credential_type+'##'+option+'##'+item.id,
                            'text':item.credential,
                            'id':item.id,
                            'content': (
                                <div className="dropdown_option">
                                    <div className="option_icon">
                                        {item.verification_status === 'VALID' &&
                                        <img src={"/icons/checkmark 30px (69b536).svg?ts=" + Date.now()} alt={t("checkmark icon")}/>
                                        }
                                        {item.verification_status === 'INVALID' &&
                                        <img src={"/icons/x 30px (e0454f).svg?ts=" + Date.now()} alt={t("x icon")}/>
                                        }
                                        {item.verification_status === 'PENDING' &&
                                        <img src={"/icons/warning 30px (ffae00).svg?ts=" + Date.now()} alt={t("warning icon")}/>
                                        }
                                        {(item.verification_status !== 'VALID' && item.verification_status !== 'INVALID' && item.verification_status !== 'PENDING') &&
                                        <img src={"/icons/circle 30px (bcbebe).svg?ts=" + Date.now()} alt={t("grey_circle")}/>
                                        }
                                    </div>
                                    <div className="option_content">
                                        <div className="option_title">{item.credential}</div>
                                        <div className="option_meta_list">
                                            <div><b>ISSUED BY: </b>{item.issuedby}</div>
                                            <div><b>ISSUED : </b>{item.issued}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    )
                }

                let credentials = creds.reduce((cred, current) => {
                    const x = cred.find(item => item.value === current.value);
                    if (!x) {
                      return cred.concat([current]);
                    } else {
                      return cred;
                    }
                  }, []);

                if (credentials.length > 0){

                    credentials.map((item,i)=>{
                        if(credential_type === 'location')
                        {
                            locCred.push(item);
                        }

                    });
                }

            } catch (e) {
                console.log(e);
            }
        }

        if(credential_type === 'location'){
            if (locCred.length > 0){
                set_loc_credentials(locCred);
            }else{
                set_loc_credentials([]);
            }
        }

        if(selected_options.length === 0){
            if(credential_type === 'location'){
                set_loc_credentials([]);
            }
        }

        let _credentials = [];
        if(selected_options.length === 0 && credential_type === 'location'){
            populate_indv_credentials();
            _credentials = [...var_indv_credentials];
        }

        if(credential_type === 'location')
        {
            locCred.map((item, i)=>
            {
                let found = _credentials.some(el => el.id === item.id);
                if(!found){
                    _credentials.push(item);
                    if (!var_selected_credentials.some(el => el === item.value)){
                        var_selected_credentials.push(item.value);
                    }

                }
            });
        }

        let selectedCred = [];
        let selectedCred_ids = [];
        var_selected_credentials.map((item, i)=>{
            let data_arr = item.split('##');
            let cred_id = data_arr[2];

            let found = _credentials.some(el => el.id === cred_id);
            if(found){
                selectedCred.push(item);
                selectedCred_ids.push(cred_id);
            }
        });
        const link = { ...var_link };
        link['credentials'] = JSON.stringify(selectedCred_ids);
        set_link(link);

        set_selected_credentials(selectedCred);
        set_credentials(_credentials);
    }


    async function populate_indv_credentials(){

        let limit = var_limit;
        let offset = var_offset;
        let sortby = var_sortby_cred;
        let sortorder = var_sortorder;
        let archive = 'NO';

        let creds = [];
        let option= '0';
        let credential_type = 'indv';

        try {
            let results = '';
            results = await API_get_credentials(limit,offset,sortby,sortorder,archive);

            if(results[0].totalrows !== 0){
                creds.push({
                    'value':'Select All',
                    'text':'Select All',
                    'id':'select_all',
                    'content': (
                        <div className="dropdown_option">
                            <div className="option_icon">
                            </div>
                            <div className="option_content">
                                <div className="option_title">Select All</div>
                                <div className="option_meta_list"></div>
                            </div>
                        </div>
                    )
                });
                results.map((item, i) =>
                    item.credential !== 'undefined' && item.credential !== null &&
                    creds.push({
                        'value':credential_type+'##'+option+'##'+item.id,
                        'text':item.credential,
                        'id':item.id,
                        'content': (
                            <div className="dropdown_option">
                                <div className="option_icon">
                                    {item.verification_status === 'VALID' &&
                                    <img src={"/icons/checkmark 30px (69b536).svg?ts=" + Date.now()} alt={t("checkmark icon")}/>
                                    }
                                    {item.verification_status === 'INVALID' &&
                                    <img src={"/icons/x 30px (e0454f).svg?ts=" + Date.now()} alt={t("x icon")}/>
                                    }
                                    {item.verification_status === 'PENDING' &&
                                    <img src={"/icons/warning 30px (ffae00).svg?ts=" + Date.now()} alt={t("warning icon")}/>
                                    }
                                    {(item.verification_status !== 'VALID' && item.verification_status !== 'INVALID' && item.verification_status !== 'PENDING') &&
                                    <img src={"/icons/circle 30px (bcbebe).svg?ts=" + Date.now()} alt={t("grey circle")}/>
                                    }
                                </div>
                                <div className="option_content">
                                    <div className="option_title">{item.credential}</div>
                                    <div className="option_meta_list">
                                        <div><b>{t('ISSUED BY')}: </b>{item.issuedby}</div>
                                        <div><b>{t('ISSUED')} : </b>{item.issued}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                )
            }
            set_credentials(creds);
            set_indv_credentials(creds);
        } catch (e) {
            console.log(e);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_indv_profilelink(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('indv', '/post-indv-profilelink/'+var_individual_id, { body: var_link });
    }

    function API_get_indv_locations(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-individuals', '/get-dd-org-indv-locations/'+JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id+'/'+var_individual_id);
    }

    function API_get_loc_credentials(limit,offset,sortby,sortorder,search,selected_id){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('indv-credentials', '/get-indv-loc-credentials/'+selected_id+'/'+var_individual_id+'?limit='+limit+'&offset='+offset+'&sortby='+sortby+'&sortorder='+sortorder+'&search='+search+'&tz='+datelib.querystring_timezone);
    }
    function API_get_credentials(limit,offset,sortby,sortorder,archive){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('indv-credentials', '/get-indv-credentials/'+var_individual_id+'?limit='+limit+'&offset='+offset+'&sortby='+sortby+'&sortorder='+sortorder+'&archive='+archive+'&status='+var_status+'&tz='+datelib.querystring_timezone);
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(event){
        const link = { ...var_link };
        link[event.target.name] = event.target.value;
        set_link(link);
        set_ready(true);
    }

    function onChange_location(event, data){
        let dataOptions = data.options.slice(1);
        let location_values;
        if(event.target.textContent === 'Select All') {
            location_values = dataOptions.map(data => data.value);
        } else {
            location_values = data.value;
        }
        set_selected_locations(location_values);
        populate_credentials(location_values, 'location');
    }

    function onChange_credential(event, data){
        let dataOptions = data.options.slice(1);
        let values;
        if(event.target.textContent === 'Select All') {
            values = dataOptions.map(data => data.value);
        } else {
            values = data.value;
        }

        let credential_value = values;
        set_selected_credentials(credential_value);

        let selected_data = [];
        values.map((item,i)=>{
            let data_arr = item.split('##');
            let cred_id = data_arr[2];

            selected_data.push(cred_id);
        });
        let selected_creds = JSON.stringify(selected_data);
        const link = { ...var_link };
        link['credentials'] = selected_creds;
        set_link(link);
        if(var_link.description !== '' && var_link.description !== null && var_link.description !== undefined){
            set_ready(true);
        }
    }

    function onClick_submit(){
        if(var_ready && var_link){
            insert_profilelink();
        }
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{t('Add Profile Link')}</div>
                <div className="modal-header-close" onClick={() => set_mdl_sharing_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t('close')}/></div>
            </div>
            <div className="modal-content">
            <p>{t('Adding a description will help you remember who you sent this link to. The link itself will be automatically created using this description.')}</p>
                <Form>
                    <Form.Group>
                        <Form.Field
                            className='description'
                            id='description'
                            name='description'
                            value={var_link.description || ''}
                            control={Input}
                            onChange={onChange_input}
                            label={t('DESCRIPTION')}
                            placeholder={t('Description...')}
                        />
                    </Form.Group>
                    {JSON.parse(sessionStorage.getItem('authentication')).indv_credentials === 'YES' &&
                    <Form.Group>
                        <Form.Select
                            className='multiple'
                            id="multiple"
                            name="multiple"
                            label={t('CREDENTIALS')}
                            placeholder={t('Credentials')}
                            options={var_credentials}
                            onChange={onChange_credential}
                            value={var_selected_credentials}
                            fluid
                            multiple
                            search
                        />
                    </Form.Group>
                    }
                    <Form.Group>
                        <Form.Select
                            className="location"
                            id="location"
                            name="location"
                            label={t("LOCATION/PROJECT")}
                            options={var_locations}
                            onChange={onChange_location}
                            placeholder={t("Location/Project...")}
                            value={var_selected_locations}
                            fluid
                            multiple
                            search
                        />
                    </Form.Group>
                </Form>
            </div>
            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_sharing_open(false)}>{t('Cancel')}</Button>
                    {!var_processing ?
                        <Button className={"btn_primary " + (var_ready ? ' btn_active' : '')} onClick={() => onClick_submit()}>{t('Create')}</Button>
                        :
                        <Button loading className="btn_primary btn_active">{t('Create')}</Button>
                    }

                </div>
            </div>
        </>
    )
}

import { Button, Grid } from 'semantic-ui-react';
import SemanticDatepicker from "react-semantic-ui-datepickers";
import Moment from "moment";
import datelib from '../../../../../../../libs/date-lib';
import { enCA, frCA } from 'date-fns/locale';
import XLSX from "xlsx";
import FileSaver from "file-saver";
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import '../../../../../../../i18n';
import auth from '../../../../../../../libs/auth-lib';

import './mdl_dailycheckreport.css';



export default function({set_mdl_open, var_location_id}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_datefrom, set_datefrom] = useState(new Date());
    const [ var_dateto, set_dateto] = useState(new Date());
    const [ var_ready, set_ready] = useState(true);
    const [ var_range_error, set_range_error ] = useState(false);
    const [ var_no_results, set_no_results ] = useState(false);
    const [ var_processing, set_processing] = useState(false);
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';


    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------

    async function generate_report() {
        set_processing(true);
        try {
            let results = await API_get_loc_dailychecks_excel();
            if (results.length === 0) {
                set_no_results(true);
                set_ready(false);
            } else {
                // Export to Excel
                await generate_excel(results);
                set_mdl_open(false);
            }
            set_processing(false);
        } catch (e) {
            console.log(e);
        }
    }

    async function generate_excel(results) {
        let excel_name='daily checks';

        //  create filename --------------------------------------------------------------------------------------------

        const file_name = Moment(var_datefrom).format('ll')+' - '+Moment(var_dateto).format('ll')+' daily checks.xlsx';

        //  create spreadsheet headers ---------------------------------------------------------------------------------
        let headers = [];
        let headersarray = [];
        for (let [value] of Object.entries(results[0])) {
            headersarray.push(t(value.toUpperCase()));
        }
        headers.push(headersarray);


        //  create excel spreadsheet -----------------------------------------------------------------------------------

        const wb = XLSX.utils.book_new();
        var ws = XLSX.utils.aoa_to_sheet(headers);
        XLSX.utils.sheet_add_json(ws, results, {skipHeader: true, origin: "A2"});
        XLSX.utils.book_append_sheet(wb, ws, excel_name);

        //  write file -------------------------------------------------------------------------------------------------

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, file_name);
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_loc_dailychecks_excel(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-dailychecks', '/get-loc-dailychecks-excel/'+var_location_id, {
            queryStringParameters: {
                datefrom: Moment.unix(var_datefrom) / 1000,
                dateto: Moment.unix(var_dateto) / 1000,
                tz: datelib.timezone
            }
        });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_datefrom(value){
        set_datefrom(value);
        const range_error = Moment(var_dateto).isBefore(value);
        set_range_error(range_error);
        set_no_results(false);
        set_ready(!range_error);
    }

    function onChange_dateto(value){
        set_dateto(value);
        const range_error = Moment(var_datefrom).isAfter(value);
        set_range_error(range_error);
        set_no_results(false);
        set_ready(!range_error);
    }

    function onClick_submit(){
        var_ready && generate_report();
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{t("Daily Checks Report")}</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
            </div>

            <div className="modal-content">
                <Grid columns={1}>
                    <Grid.Row>
                        <Grid.Column>
                            <SemanticDatepicker
                                locale={auth.language.datepicker}
                                format={auth.language.name === 'French' ? 'D MMM YYYY' : 'MMM DD, YYYY'}
                                formatOptions={{locale: auth.language.name === 'French' ? frCA: enCA}}
                                label={t('START DATE')}
                                id="datefrom"
                                value={var_datefrom}
                                onChange={(e, data) => onChange_datefrom(data.value)}
                                clearable={false}
                                datePickerOnly={true}
                                showToday={false}
                                iconPosition='left'
                                error={var_range_error}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <SemanticDatepicker
                                locale={auth.language.datepicker}
                                format={auth.language.name === 'French' ? 'D MMM YYYY' : 'MMM DD, YYYY'}
                                formatOptions={{locale: auth.language.name === 'French' ? frCA: enCA}}
                                label={t('END DATE')}
                                id="dateto"
                                value={var_dateto}
                                onChange={(e, data) => onChange_dateto(data.value)}
                                clearable={false}
                                datePickerOnly={true}
                                showToday={false}
                                iconPosition='left'
                                error={var_range_error}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    {var_no_results &&
                        <Grid.Row>
                            <Grid.Column>
                                <div>{t('there are no daily checks to display')}</div>
                            </Grid.Column>
                        </Grid.Row>
                    }
                </Grid>
            </div>

            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>{t("CANCEL")}</Button>
                    {!var_processing ?
                        <Button className={"btn_primary " + (var_ready ? ' btn_active' : '')} onClick={() => onClick_submit()}>{t("GENERATE")}</Button>
                        :
                        <Button loading className="btn_primary btn_active">{t("GENERATE")}</Button>
                    }
                </div>
            </div>
        </>
    )
}
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import auth from '../../../../libs/auth-lib';
import './sharing.css';

import { useTranslation } from 'react-i18next';
import '../../../../i18n';


import CRD_SHARING from './crd_sharing/crd_sharing';


export default function(appProps) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();
    const history = useHistory();



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if (auth.is_loggedin && auth.permission_id === auth.constants.permission_ids.Superadmin) {
            appProps.set_visibleframe(true);
            appProps.set_visiblemenu('ORGANIZATION')
            appProps.set_activemenu('/org/wallet/wallet');
        }else{
            history.push('/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);



    //  async functions ------------------------------------------------------------------------------------------------

    //  API calls ------------------------------------------------------------------------------------------------------

    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return(
        <>
            {auth.is_loggedin &&
            <div className="content">
                <div
                    className="content-topmenu"><div><img src={'/icons/share 60px (717473).svg?ts=1646773041595?ts=' + Date.now()} alt='share icon'></img></div>{t('Shared credentials')}
                </div>
                <div className="content-wrapper">

                    <CRD_SHARING></CRD_SHARING>

                </div>
            </div>
            }
        </>
    )
};

import { Button, Form, Pagination } from 'semantic-ui-react'
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../i18n';

import './cmp_pagination.css'


export default function({var_offset, var_limit, var_ready, var_loading, total_rows, populatefunction}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_activepage, set_activepage ] = useState(1);
    const [ var_displayrows, set_displayrows ] = useState(var_limit);
    const var_totalpages = () => Math.ceil(total_rows/var_displayrows);
    const var_displaystart = () => (var_activepage-1)*var_displayrows+1;
    const var_displayend = () => var_activepage*var_displayrows > total_rows ? total_rows : var_activepage*var_displayrows;



    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        set_activepage(Math.floor(var_offset/var_displayrows)+1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_offset]);

    useEffect(() => {
        set_displayrows(var_limit);
    },[var_limit]);

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_pg_show(e, { value }){
        set_displayrows(value);
        set_activepage(1);
        populatefunction(Number(value),0);
    }

    function onChange_page(e, { activePage }){
        set_activepage(activePage);
        populatefunction(null,((activePage-1)*var_displayrows));
    }

    // function onClick_refresh(){
    //     populatefunction(null,((var_activepage-1)*var_displayrows));
    // }



    // RENDER APP ======================================================================================================

        return (
            <>
                <div className={'pg_group pg_showing ' + (total_rows < var_displayrows ? ' pg_showing_onerow' : '')}>
                    {total_rows > 0 && var_limit && var_ready &&
                        <>
                                {total_rows <= var_displayrows ?
                                    <div className='pg_results'>{t('Showing')} <div className='pg_results_emph'>{total_rows}</div> {total_rows !== 1 ? t('Results') : t('Result')}</div>
                                    :
                                    <div className='pg_results'>{t('Showing')} <div className='pg_results_emph'>{var_displaystart()}</div>
                                        {t('to')} <div className='pg_results_emph'>{var_displayend()}</div>
                                        {t('of')} <div className='pg_results_emph'>{total_rows}</div> {t('Results')}</div>
                                }

                                <div className='pg_paginationelements'>
                                    <Form className='pg_show'>
                                        <Form.Select
                                            compact
                                            id='pg_show'
                                            name='pg_show'
                                            placeholder='10'
                                            onChange={onChange_pg_show}
                                            value={var_displayrows || '10'}
                                            options={[{'text': '10', 'value': '10'},
                                                {'text': '15', 'value': '15'},
                                                {'text': '25', 'value': '25'},
                                                {'text': '50', 'value': '50'},
                                                {'text': '100', 'value': '100'}]}
                                        />
                                    </Form>
                                    {total_rows > var_displayrows &&
                                    <Pagination
                                        activePage={var_activepage}
                                        boundaryRange={0}
                                        siblingRange={1}
                                        size='mini'
                                        totalPages={var_totalpages()}
                                        onPageChange={onChange_page}
                                        ellipsisItem={false ? undefined : null}
                                        firstItem={true ? undefined : null}
                                        lastItem={true ? undefined : null}
                                        prevItem={true ? undefined : null}
                                        nextItem={true ? undefined : null}
                                    />
                                    }
                                </div>
                        </>
                    }
                </div>
                {/*<Button id='btn_refresh' className={'btn_icononly pg_refresh' + (var_loading ? ' pg_loading' : '')} onClick={() => onClick_refresh()}><img className='btn_icon' src='/icons/refresh 30px (a2a2a1).svg?ts=' + Date.now()} alt={t('refresh icon')}></img></Button>*/}
            </>
        )
}
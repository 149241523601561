import React, { useEffect, useState } from 'react';
import { Button, Form, Input } from 'semantic-ui-react';
import { API } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import './mdl_add.css';

export default function({ set_mdl_open, populate_function }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_name, set_name ] = useState('');
    const [ var_processing, set_processing ] = useState(false);

    //  variable listeners ---------------------------------------------------------------------------------------------

    //  async functions ------------------------------------------------------------------------------------------------

    async function insert_package() {
        set_processing(true);
        try {
            let reqpackage = {
                organization_id: JSON.parse(sessionStorage.getItem('authentication')).organization_id,
                name: var_name.trim(),
                status: 'ACTIVE'
            };
            await API_post_package(reqpackage);
            set_processing(false);
            populate_function();
            set_mdl_open(false);
        } catch (e) {
            console.log(e);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_package(reqpackage) {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-requirements', '/post-package/', { body: reqpackage });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_submit() {
        if (!var_processing && var_name.trim().length > 0) {
            insert_package();
        }
    }



    // RENDER APP ======================================================================================================

    return (
        <div className='packagesetup_addcontainer'>
            <div className='modal-header'>
                <div className='modal-header-title'>{t('Add Package')}</div>
                <div className='modal-header-close' onClick={() => set_mdl_open(false)}><img src={'/icons/x 60px (717473).svg?ts=' + Date.now()} alt={t('x icon')} /></div>
            </div>
            <div className='modal-content'>
                <Form>
                    <Form.Group>
                        <Form.Field
                            className='package_name'
                            name='package_name'
                            value={var_name}
                            control={Input}
                            onChange={(event) => set_name(event.target.value)}
                            label={t('PACKAGE NAME')}
                            placeholder={t('Package name...')}
                        />
                    </Form.Group>
                </Form>
            </div>

            <div className='modal-footer'>
                <div className='modal-footer-buttons'>
                    <Button className='btn_secondary' onClick={() => set_mdl_open(false)}>{t('CANCEL')}</Button>
                    <Button loading={var_processing} className={'btn_primary' + (var_name.trim().length > 0 ? ' btn_active' : '')} onClick={onClick_submit}>{t('ADD PACKAGE')}</Button>
                </div>
            </div>
        </div>
    )
}
import { Button, Dropdown } from 'semantic-ui-react';
import { API } from "aws-amplify";
import React, { useEffect, useState, Component } from "react";
import datelib from '../../../../../../../../libs/date-lib';
import { useTranslation } from 'react-i18next';
import StepZilla from 'react-stepzilla';
import '../../../../../../../../i18n';

import './mdl_add.css';


function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}


export default function({set_mdl_open, populate_function, var_location_id}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_selectedform, set_selectedform] = useState([]);
    const [ var_processing, set_processing] = useState(false);

    const [ var_dd_availableforms, set_dd_availableforms ] = useState([]);
    const [ var_dd_packages, set_dd_packages ] = useState([]);
    const [ var_selectedpackage, set_selectedpackage ] = useState([]);
    const [ var_availableformids, set_availableformids ] = useState([]);
    const [ var_finalforms, set_finalforms ] = useState([]);
    const [ var_dd_selectedforms, set_dd_selectedforms ] = useState([]);

    // eslint-disable-next-line no-unused-vars
    const [ var_previous_step, set_previous_step ] = useState(0);
    const [ var_step2a_ready, set_step2a_ready ] = useState(false);
    const [ var_step2b_ready, set_step2b_ready ] = useState(false);


    class Step1 extends Component {
        render() {
            return <div className="forms-modal">
                <div className="modal-header">
                    <div className="modal-header-title">{t("Add Form")}</div>
                    <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
                </div>
                <div className="modal-content">
                    <div className='step-form'>
                        {t("Do you wish to add single forms or packages of forms?")}
                    </div>
                    <div className='step-action'>
                        <Button
                            className={"btn_primary btn_active"}
                            onClick={() => {this.props.jumpToStep(1); set_previous_step(0);}}
                        >{t("FORM")}</Button>
                        <Button
                            className={""}
                            onClick={() => {this.props.jumpToStep(2); set_previous_step(0);}}
                        >{t("PACKAGE OF FORMS")}</Button>
                    </div>
                </div>
            </div>
        }
    }

    class Step2a extends Component {
        render() {
            return <div className="forms-modal">
                <div className="modal-header">
                    <div className="modal-header-title">{t("Select Form")}</div>
                    <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
                </div>
                <div className="modal-content">
                    <Dropdown
                        className='form_id'
                        id='form_id'
                        name='form_id'
                        label={t("FORM TO ADD TO THIS LOCATION/PROJECT")}
                        options={var_dd_availableforms}
                        value={var_selectedform || []}
                        onChange={onChange_form}
                        placeholder={t("Select a form...")}
                        fluid
                        multiple
                        selection
                        search
                    />
                </div>
                <div className="modal-footer">
                    <div className="modal-footer-buttons">
                        <Button
                            className='btn_secondary go-back'
                            onClick={() => {this.props.jumpToStep(0)}}
                        >{t("GO BACK")}</Button>
                        {!var_processing ?
                            <Button
                                className={"btn_primary " + (var_step2a_ready ? ' btn_active' : '')}
                                disabled={(var_step2a_ready ? false : true)}
                                onClick={() => onClick_submit()}
                            >{t("ADD FORM")}</Button>
                            :
                            <Button
                            className="btn_primary btn_active"
                            loading
                            >{t("ADD FORM")}</Button>
                        }
                    </div>
                </div>
            </div>
        }
    }

    class Step2b extends Component {
        render() {
            return <div className="forms-modal">
                <div className="modal-header">
                    <div className="modal-header-title">{t("Select Package")}</div>
                    <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
                </div>
                <div className="modal-content">
                    <div className="step-form">
                        <Dropdown
                            className='package_id'
                            id='package_id'
                            name='package_id'
                            label={t("PACKAGE TO ADD TO THIS LOCATION/PROJECT")}
                            options={var_dd_packages}
                            value={var_selectedpackage || []}
                            onChange={onChange_package}
                            placeholder={t("Select a package...")}
                            fluid
                            multiple
                            selection
                            search
                        />
                    </div>
                    { var_dd_selectedforms.length > 0 &&
                    <div className="step-form-detail">
                        <ul>
                        { var_dd_selectedforms.map((item, i) =>
                            <li key={i}>{item.formname}</li>
                        )}
                        </ul>
                    </div>
                    }
                    { var_processing &&
                        <div className="ui active centered inline loader"></div>
                    }

                </div>
                <div className="modal-footer">
                    <div className="modal-footer-buttons">
                        <Button
                            className='btn_secondary go-back'
                            onClick={() => {this.props.jumpToStep(0)}}
                        >{t("GO BACK")}</Button>
                        {!var_processing ?
                            <Button
                                className={"btn_primary " + (var_step2b_ready ? ' btn_active' : '')}
                                disabled={(var_step2b_ready ? false : true)}
                                onClick={() => onClick_submit()}
                            >{t("ADD FORM")}</Button>
                            :
                            <Button
                            className="btn_primary btn_active"
                            loading
                            >{t("ADD FORM")}</Button>
                        }
                    </div>
                </div>
            </div>
        }
    }


    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_dd_availableforms();
        populate_dd_packages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_dd_availableforms(){
        try {
            let form_ids = [];
            let results = await API_get_dd_availableforms();
            set_dd_availableforms(results);
            for (let result of results) {
                form_ids.push(result.value);
            }
            set_availableformids(form_ids);
        } catch (e) {
            console.log(e);
        }
    }

    async function populate_dd_packages(){
        try {
            let results = await API_get_dd_packages();
            set_dd_packages(results);
        } catch (e) {
            console.log(e);
        }
    }

    async function add_form() {
        set_processing(true);
        try {
            let data = {};
            data['form_id'] = var_finalforms;
            data['location_id'] = var_location_id;
            data['organization_id'] = JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id;
            data['transactionby'] = JSON.parse(sessionStorage.getItem('authentication')).id;
            data['transactionorg'] = JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id;

            await API_post_loc_form(data);

            populate_function();
            set_processing(false);
            set_mdl_open(false);
        } catch (e) {
            console.log(e);
        }

    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_dd_availableforms(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-forms', '/get-dd-loc-availableforms/'+var_location_id);
    }

    function API_get_dd_packages(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-forms', '/get-dd-loc-availablepackages/'+var_location_id);
    }

    function API_get_package_forms(package_id){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-forms', '/get-package-forms/'+package_id,
        {
            queryStringParameters: {
                limit : "100",
                offset : "0",
                sortby : "form_name",
                sortorder: "descending",
                tz : datelib.timezone
            }});
    }

    function API_get_form(form_id){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-forms', '/get-form/'+form_id);
    }

    function API_post_loc_form(data){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-forms', '/post-loc-form', { body: data});
    }



    //  event functions ------------------------------------------------------------------------------------------------

    async function onChange_form(e, { value }){
        let selected_forms = [];
        set_processing(true);
        set_selectedform(value);
        set_finalforms(value);
        for (let form of value) {
            selected_forms.push(form);
        }
        if(value && value.length > 0) {
            set_step2a_ready(true);
        } else {
            set_step2a_ready(false);
        }
        set_processing(false);
    }

    async function onChange_package(e, {value}) {
        let form_ids = [];
        let selected_forms = [];
        set_processing(true);
        set_selectedpackage(value);
        for (let selected_package of value) {
            let results = await API_get_package_forms(selected_package);
            if(results.totalrows !== 0) {
                for (let form of results.results) {
                    form_ids.push(form.form_id);
                }
            }
        }
        form_ids = form_ids.filter(onlyUnique);
        form_ids = form_ids.filter(id => var_availableformids.includes(id));
        for (let form of form_ids) {
            let result = await API_get_form(form);
            selected_forms.push(result[0]);
        }
        set_dd_selectedforms(selected_forms);
        set_finalforms(form_ids);
        if(selected_forms && selected_forms.length > 0) {
            set_step2b_ready(true);
        } else {
            set_step2b_ready(false);
        }
        set_processing(false);
    }

    function onClick_submit(){
        add_form();
    }



    // RENDER APP ======================================================================================================

    const steps = [
        {name: 'Step1', component:<Step1 />},
        {name: 'Step2a', component: <Step2a />},
        {name: 'Step2b', component: <Step2b />}
    ]
    return (
        <>
            <StepZilla
                steps={steps}
                showSteps={false}
                showNavigation={false}
            />
        </>
    )
}
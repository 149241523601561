import { Button, Form, Input } from 'semantic-ui-react'
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";

import { useTranslation } from 'react-i18next';
// import '../../../../../../i18n';

import './mdl_sharelink.css';



export default function({ set_mdl_open, populate_function, var_link_id }){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();
    const [ var_link, set_link] = useState(false);
    const [ var_ready, set_ready] = useState(false);
    const [ var_processing, set_processing] = useState(false);
    const [var_validationerror, set_validationerror] = useState(false);
    const [var_validationerrormessage, set_validationerrormessage] = useState('');

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_link.transactionby === undefined){
            const link = {...var_link};
            link['individual'] = JSON.parse(sessionStorage.getItem('authentication')).lastname + " " +  JSON.parse(sessionStorage.getItem('authentication')).firstname;
            link['organization_id'] = JSON.parse(sessionStorage.getItem('authentication')).organization_id;
            set_link(link);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //  async functions ------------------------------------------------------------------------------------------------

    async function share_profilelink(){
        set_processing(true);
        try {
            const link = { ...var_link };
            let email_to = link['email'].split(/[\s,;]+/);
            email_to = email_to.filter(function(e){return e});

            let body = {
                emailto: email_to,
                link_prefix: 'location',
                link: var_link_id,
                individual: link['individual'],
                organization_id: link['organization_id']
            }

            await API_post_sharelink(body);
            set_ready(false);
            set_processing(false);
            set_mdl_open(false);
            populate_function();
        } catch (e) {
            console.log(e);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_sharelink(body){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org', '/post-sharelink', { body: body });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_email(event, { value }){
        console.log(event);
        set_validationerror(false);

        // let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        // if(re.test(value))
        // {
        //     console.log("Valid email");
        // }
        // else
        // {
        //     console.log("invalid email");
        // }

        if(event.target.name==='email'){
            event.target.value=event.target.value.toLowerCase().trim()
        }
        const link = { ...var_link };
        link[event.target.name] = event.target.value;
        set_link(link);
        set_ready(true);
    }

    function onClick_submit(){
        if(var_ready){
            let var_errorValidation = false;
            let var_errorMessage = '';
            let emailValidation =new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

            const link = { ...var_link };
            let email_to = link['email'].split(/[\s,;]+/);
            for (let email of email_to) {
                if( email !== '' && !emailValidation.test(email) ){
                    var_errorMessage += email+',';
                    var_errorValidation = true;
                    set_ready(false);
                } 
            }
            set_validationerrormessage(var_errorMessage.slice(0,-1));
            set_validationerror(var_errorValidation);

            if (var_errorValidation === false) {
                share_profilelink();
            }
        }
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{t('Share Profile Link')}</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t('close icon')}/></div>
            </div>
            <div className="modal-content">
                <Form>
                    <Form.Group>
                        <Form.Field
                            className='email'
                            id='email'
                            name='email'
                            type="email"
                            value={var_link.email || ''}
                            control={Input}
                            onChange={onChange_email}
                            label={t('Email')}
                            placeholder={t('Email...')}
                        />
                        {/*<span style={{color: "red"}}>{var_link["email"]}</span>*/}
                    </Form.Group>
                </Form>
                {var_validationerror &&
                    <div className="message warning">
                        <div className="message_icon"><img src={"/icons/warning 60px (d91e18).svg?ts=" + Date.now()} alt={t('warning icon')} /></div>
                        <div className="message_text_wrapper">
                            <div className="message_text cause">
                                {t('Email address(es) that need your attention')}
                                <ul>{(var_validationerrormessage.split(',')).map(email => <li>{email}</li>)}</ul>
                                </div>
                        </div>
                    </div>
                }
            </div>
            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>{t('CANCEL')}</Button>
                    {!var_processing ?
                        <Button className={"btn_primary " + (var_ready ? ' btn_active' : '')} onClick={() => onClick_submit()}>{t('SHARE')}</Button>
                        :
                        <Button loading className="btn_primary btn_active">{t('SHARE')}</Button>
                    }

                </div>
            </div>
        </>
    )

}

import { API } from "aws-amplify";
import { Button, Message, Dimmer, Loader } from 'semantic-ui-react';
import React, { useLayoutEffect, useEffect, useState } from "react";
import { pdf } from '@react-pdf/renderer';
import {saveAs} from 'file-saver';
import datelib from '../../../libs/date-lib';

import CRD_CREDENTIALS from "./crd_credentials/crd_credentials";
import CRD_CREDENTIALSATTACHMENTS from "./crd_credentialsAttachments/crd_credentialsAttachments";
import DOWNLOAD from "./report";
import { useTranslation } from 'react-i18next';
import '../../../i18n';

import "./profile.css";



export default function(appProps) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const QRCode = require('qrcode.react');
    const [var_linkStatusReady, set_linkStatusReady] = useState(false)
    const [ var_indvReady, set_indvReady ] = useState(false);
    const [ var_credReady, set_credReady ] = useState(false);
    const [ var_linkstatus, set_linkstatus ] = useState('');
    const [ var_orgIndv, set_orgIndv ] = useState(false);
    const { t } = useTranslation();

    const [ var_indv, set_indv ] = useState([]);
    const [ var_credentials, set_credentials] = useState([]);
    const [ var_limit, set_limit] = useState(100);
    const [ var_offset, set_offset] = useState(0);
    const [ var_search, set_search ] = useState('');
    const [ var_sortby, set_sortby ] = useState('sortorder');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    // eslint-disable-next-line no-unused-vars
    const [ var_status, set_status ] = useState('ACTIVE');
    const [var_loading, set_loading] = useState(true);

    let url = window.location.href;
    let arr = url.split("/");
    const hostname = arr[0] + "//" + arr[2];
    const qrURL = hostname + '/public/profile/'+appProps.match.params.linkid;


    //  event listeners ------------------------------------------------------------------------------------------------

    useLayoutEffect(()=>{
        set_loading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=>{        
        appProps.set_visibleframe(false);
        populate_public_credentials();
        populate_public_linkstatus();
        populate_public_indv();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_public_linkstatus(){
        try {
            let results = await API_get_public_linkstatus();            
            set_linkstatus(results[0]['status']);
            set_linkStatusReady(true);
        } catch (e) {
            console.log(e);
        }
    }

    async function populate_public_indv(){
        try {
            let indv = await API_get_public_indv();
            if(indv[0]['org_profile'] === 'YES'){
                set_orgIndv(true);
                if (indv[0].org_logo && !indv[0].org_logo.toLowerCase().startsWith('https://') && !indv[0].org_logo.toLowerCase().startsWith('http://')) {
                    indv[0].org_logo = (await API.endpoint('storage')) + '/logo/' + indv[0].org_id + '/' + indv[0].org_logo;
                }
            }
            set_indv(indv[0]);
            set_loading(false);
            set_indvReady(true);
        } catch (e) {
            console.log(e);
        }
    }

    async function populate_public_credentials(limit,offset,sortby,sortorder,search){

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        if(search) {
            if(search === '-') {
                search = '';
            }
            set_search(search);
        }else{
            search=var_search
        }
        try {
            let results = await API_get_public_credentials(limit,offset,sortby,sortorder,search);
            set_credentials(results);
        } catch (e) {
            console.log(e);
            let totalResults = [{'totalrows':0}];
            set_credentials(totalResults);
        }
        set_loading(false);
        set_credReady(true);

    }

    async function Download_PDF(){
        set_loading(true);

        const canvas = document.getElementById("qr-gen");
        const pngUrl = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");

        let indv_name = var_indv.firstname + '-' + var_indv.lastname;

        let credential_url = (await API.endpoint('storage')) + '/credential/';

        let filename = (indv_name != null? indv_name.replace(/[/|\\:*?"<>]/g, " ") : '') + '-' + new Date(new Date().getTime() - new Date().getTimezoneOffset()*60*1000).toISOString().substr(0,19).replace('T', ' ');

        const blob = await pdf(
                <DOWNLOAD var_indv={var_indv} var_credentials={var_credentials} var_qrCode={pngUrl} credential_url={credential_url} var_linkid={appProps.match.params.linkid}/>
            ).toBlob();
        saveAs(blob, filename);
        set_loading(false);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_public_linkstatus(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('indv', '/get-public-linkstatus/'+appProps.match.params.linkid);
    }

    function API_get_public_indv(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('indv', '/get-public-indv/'+appProps.match.params.linkid);
    }

    function API_get_public_credentials(limit,offset,sortby,sortorder,search){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('indv-credentials', '/get-public-credentials/'+appProps.match.params.linkid+'?limit='+limit+'&offset='+offset+'&sortby='+sortby+'&sortorder='+sortorder+'&search='+search+'&status='+var_status+'&tz='+datelib.querystring_timezone);
    }


    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

 
    return(
        <div className="public_content" id="pg_publicprofile">
             {var_loading &&
                <>
                <Dimmer active inverted>
                    <Loader />
                </Dimmer>
                </>
            }

            {var_linkStatusReady && var_indvReady && var_credReady && !var_loading &&
            <div className="public_wrapper">
                {var_linkstatus === 'ACTIVE' &&
                    <>
                       <div className="profile_report_card">
                            <div className="profile_report_header">
                                <div className="profile_report_header_top">
                                    <div className="profile_report_detail">
                                        {var_orgIndv &&
                                        <div className="profile_report_org_info">
                                            {var_indv && var_indv.org_logo && var_indv.org_logo != null && var_indv.org_logo !== '' &&
                                            <div className="profile_report_logo">
                                                <img src={var_indv.org_logo} alt="organization logo" />
                                            </div>
                                            }
                                        </div>
                                        }
                                        <div className="profile_report_title">
                                            <div className="report_title">{var_indv.firstname} {var_indv.lastname}</div>
                                            <Button className="btn_tertiary btn_download" onClick={() => Download_PDF()}>
                                                <img className="btn_icon" src={"/icons/download 30px (4dcbd4).svg?ts=" + Date.now()} alt="download icon" />
                                                {t('Export as PDF')}
                                            </Button>
                                        </div>
                                        <ul className="profile_report_info">
                                            {var_orgIndv && var_indv && var_indv.org_name && var_indv.org_name != null && var_indv.org_name !== '' &&
                                            <li>
                                                    <div className="profile_header_info_th">{t('ORGANIZATION')}</div>
                                                <div className="profile_header_info_td">{var_indv===undefined? '' :(var_indv.org_name===null? '' : var_indv.org_name)}</div>
                                            </li>
                                            }
                                            {var_indv && var_indv.phone && var_indv.phone != null && var_indv.phone !== '' &&
                                                <li>
                                                    <div className="profile_header_info_th">{t('PHONE')}</div>
                                                    <div className="profile_header_info_td">{var_indv===undefined? '' : (var_indv.phone===null? '' : var_indv.phone)}</div>
                                                </li>
                                            }
                                            {var_indv && var_indv.email && var_indv.email != null && var_indv.email !== '' &&
                                                <li>
                                                    <div className="profile_header_info_th">{t('EMAIL')}</div>
                                                    <div className="profile_header_info_td">{var_indv===undefined? '' : (var_indv.email===null? '' : var_indv.email)}</div>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                    <div className="report_qrcode">
                                        <QRCode
                                            className="qrcode"
                                            id="qr-gen"
                                            value={qrURL}
                                            size={120}
                                            level={"H"}
                                            includeMargin={false}
                                        />
                                    </div>
                                </div>
                                <CRD_CREDENTIALS
                                    var_credentials={var_credentials}                                   
                                    var_linkid={appProps.match.params.linkid}
                                />
                                <CRD_CREDENTIALSATTACHMENTS
                                    var_credentials={var_credentials}
                                />
                            </div>
                        </div>
                    </>
                }
            </div>
            }

            {!var_loading && (var_linkstatus === 'INACTIVE' || var_linkstatus === 'REVOKED') &&
                <div className="public_wrapper">
                    <div>
                        <div className="content">
                            <div className="content-wrapper">
                                <Message
                                    color='red'>{t('A valid or active public profile could not be found. Please check the link again or the individual you received it from')}</Message>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
};

import { Modal, Button } from 'semantic-ui-react'
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import '../../../../i18n';

import MDL_DETAILS from "./mdl_details/mdl_details";

import './crd_details.css';
import auth from '../../../../libs/auth-lib';


export default function({var_pageready, set_language}){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();
    const history = useHistory();

    const [ var_indv, set_indv ] = useState([]);
    const [ var_mdl_details_open, set_mdl_details_open ] = useState(false);
    const [ var_mdl_sharing_open, set_mdl_sharing_open ] = useState(false);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_pageready) {
            populate_indv()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_pageready]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_indv(){
        try {
            await API_get_indv().then((indv)=>{
                set_indv(indv[0]);
            });
        } catch (e) {
            console.log(e);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_indv(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('indv', '/get-indv/'+JSON.parse(sessionStorage.getItem('authentication')).id)
            .then(response => response)
            .catch(error => {
                console.log(error.response);
                return [{"id":null}];  // on error, return a sane result
            });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onOpen_mdl_details(){
        set_mdl_details_open(true);
    }

    function onClick_open_sharing(){
        history.push('/indv/wallet/sharing');
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="content-card" id="crd_indv_overview_details">
                <div className="content-card-header">
                    <div className="content-card-header-title">{t('Profile Details')}</div>
                    <div className="content-card-subheader_icon" onClick={() => onClick_open_sharing()}><img src={"/icons/share 60px (717473).svg?ts=" + Date.now()} alt={t('share icon')}/></div>
                    <div className="content-card-subheader_icon" onClick={() => onOpen_mdl_details()}><img src={"/icons/gear 60px (717473).svg?ts=" + Date.now()} alt={t('gear icon')}/></div>
                </div>
                <div className="content-card-content">
                    <div className="detail-item">
                        <div className="detail-item-label">{t('NAME')}</div>
                        <div className="detail-item-text">{var_indv.firstname} {var_indv.lastname}</div>
                    </div>
                    <div className="detail-spacer"></div>
                    <div className="detail-item">
                        <div className="detail-item-label">{t('EMAIL')}</div>
                        <div className="detail-item-text">{var_indv.email}</div>
                    </div>
                    <div className="detail-spacer"></div>
                    <div className="detail-item">
                        <div className="detail-item-label">{t('PHONE')}</div>
                        <div className="detail-item-text">{var_indv.phone}</div>
                    </div>
                    <div className="detail-spacer"></div>
                    <div className="detail-item">
                        <div className="detail-item-label">{t('ORGANIZATION(S)')}</div>
                        <div className="detail-item-text">{var_indv.organizations}</div>
                    </div>
                    {auth.org_wallet &&
                    <>
                        <div className="detail-spacer"></div>
                        <div className="detail-item">
                            <div className="detail-item-label">{t('EMPLOYEE ID')}</div>
                            <Button className="btn_tertiary" onClick={() => window.open('https://vcdemo.credivera.io/employeeidverified.html')}>{t('VERIFIED')}</Button>
                        </div>
                    </>
                    }
                </div>


                {/***** MODAL: DETAILS *******************************************************************************/}

                <Modal id="mdl_indv_wallet_details"
                       dimmer={'inverted'}
                       open={var_mdl_details_open}
                       onClose={() => set_mdl_details_open(false)}>
                    <MDL_DETAILS
                        set_mdl_details_open={set_mdl_details_open}
                        var_indv={var_indv}
                        set_indv={set_indv}
                        populate_indv={populate_indv}
                        set_language={set_language}>
                    </MDL_DETAILS>
                </Modal>

                {/***** END MODAL: DETAILS ***************************************************************************/}

            </div>
        </>
    )

}

import { API } from "aws-amplify";
import { Chart } from "react-google-charts";
import React, { useEffect, useState } from "react";
import {Button} from "semantic-ui-react";
import Moment from "moment";
import datelib from '../../../../libs/date-lib';
import XLSX from "xlsx";
import FileSaver from "file-saver";
import { useTranslation } from 'react-i18next';
import '../../../../i18n';

import './sta_r2k.css';


export default function(){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_onsite, set_onsite ] = useState({'YES': 0, 'NO': 0, 'NONE': 0});
    // eslint-disable-next-line no-unused-vars
    const [ var_date, set_date ] = useState(Moment().format('ll'));
    // eslint-disable-next-line no-unused-vars
    const [var_onsitedate, set_onsitedate ] = useState(new Date());
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const [ var_onsitechart, set_onsitechart ] = useState([['title', 'value'], ['Onsite', 0], ['Offsite', 0], ['Missing', 0]]);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        populate_onsite();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_onsite(){
        try {
            let result = await API_get_onsite();
            let YES = result['YES'];
            let NO = result['NO'];
            let NONE = result['NONE'];
            let onsite =[
                ["title", "value"],
                ['Onsite', YES],
                ['Offsite', NO],
                ['Missing', NONE]
            ];
            set_onsitechart(onsite);
            set_onsite(result);
        } catch (e) {
            console.log(e);
        }

    }

    async function populate_onsites_excel(){
        try {

            let excel_data = await API_get_onsites_excel();
            let excel_name='daily check';

            //  create filename --------------------------------------------------------------------------------------------

            const file_name = var_date+' daily checks.xlsx';

            //  create spreadsheet headers ---------------------------------------------------------------------------------
            let headers = [];
            let headersarray = [];
            for (let [value] of Object.entries(excel_data[0])) {
                headersarray.push(value.toUpperCase());
            }
            headers.push(headersarray);


            //  create excel spreadsheet -----------------------------------------------------------------------------------

            const wb = XLSX.utils.book_new();
            var ws = XLSX.utils.aoa_to_sheet(headers);
            XLSX.utils.sheet_add_json(ws, excel_data, {skipHeader: true, origin: "A2"});
            XLSX.utils.book_append_sheet(wb, ws, excel_name);

            //  write file -------------------------------------------------------------------------------------------------

            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            FileSaver.saveAs(data, file_name);

        } catch (e) {
            console.log(e);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_onsite(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-onsites', '/get-org-stat/'+JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id+'/'+Moment(var_onsitedate).valueOf()+'?tz='+datelib.querystring_timezone);
    }

    function API_get_onsites_excel(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-onsites', '/get-org-onsites-excel/'+JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id+'?tz='+datelib.querystring_timezone);
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_excel(){
        populate_onsites_excel();
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="stat-card" id="sta_org_overview_onsite">
                <div className="stat-card-header">
                    <div className="content-card-subheader_text">{t("Onsite as of")} {var_date}</div>
                    <Button className="btn_tertiary" onClick={()=>onClick_excel()}><img className="btn_icon" src={"/icons/download 30px (4dcbd4).svg?ts=" + Date.now()} alt={t("download icon")}/></Button>
                </div>
                <div className="chartwrapper">
                    <div className="chart">
                        <Chart
                            name='chart_onsite'
                            width={'120px'}
                            height={'120px'}
                            chartType="PieChart"
                            data={var_onsitechart}
                            options={{
                                'chartArea': {'width': '90%', 'height': '90%'},
                                colors: ['#549E17', '#DB2430', '#CACCCB'],
                                legend: {position: 'none'},
                                pieSliceText: "none",
                                pieHole: 0.8,
                            }}
                            rootProps={{'data-testid': '1'}}
                        />
                    </div>
                    <div className="chartlegend">
                        <div className="chartlegend-item">
                            <div className="chartlegend-item_icon"><img src={"/icons/circle 60px (549E17).svg?ts=" + Date.now()} alt={t("onsite icon")}></img></div>
                            <div className="chartlegend-item_text">{t("Onsite")} ({var_onsite.YES})</div>
                        </div>
                        <div className="chartlegend-item">
                            <div className="chartlegend-item_icon"><img src={"/icons/circle 60px (DB2430).svg?ts=" + Date.now()} alt={t("offsite icon")}></img></div>
                            <div className="chartlegend-item_text">{t("Offsite")} ({var_onsite.NO})</div>
                        </div>
                        <div className="chartlegend-item">
                            <div className="chartlegend-item_icon"><img src={"/icons/circle 60px (CACCCB).svg?ts=" + Date.now()} alt={t("missing icon")}></img></div>
                            <div className="chartlegend-item_text">{t("Missing")} ({var_onsite.NONE})</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
//  OUTCOMES -----------------------------------------------------------------------------------------------------------
//
//  - displays details of the current version of a credential
//
//  --------------------------------------------------------------------------------------------------------------------

import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { Modal, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';
import auth from '../../../../../libs/auth-lib';
import datelib from '../../../../../libs/date-lib';

import MDL_CONFIRMATION from '../../../../../components/cmp_confirmation/cmp_confirmation';
import MDL_DOCVIEWER from '../../../../../components/cmp_docviewer/cmp_docviewer';

import './tab_current_version.css';
import CMP_VERIFIABLEPRESENTATION_VIEWER
    from "../../../../../components/cmp_verifiablepresentationviewer/cmp_verifiablepresentationviewer";
import CMP_QRCODE from '../../../../../components/cmp_qrcode/cmp_qrcode';
import config from '../../../../../config';

export default function({ var_activetab, set_activetab, var_target_id, populate_function, credential_type, var_credential, set_credential }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();
    const [ var_mdl_docviewer_open, set_mdl_docviewer_open ] = useState(false);
    const [ var_docviewerid, set_docviewerid ] = useState(null);
    const [ var_mdl_confirmation_open, set_mdl_confirmation_open ] = useState(false);
    const [ var_mdl_verifiablepresentationviewer_open, set_mdl_verifiablepresentationviewer_open ] = useState(false);
    const [ var_mdl_qrcode_open, set_mdl_qrcode_open ] = useState(false);
    //const [ var_mdl_jsonviewer_open, set_mdl_jsonviewer_open ] = useState(false);


    const [ var_json, set_json ] = useState({});

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        set_credential(null);
        var_activetab === 'CURRENT' && var_target_id && populate_credential();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_credential() {
        let results;
        if (credential_type === 'indv'){
            results = await API_get_indv_credential();
            set_credential(results[0]);
        } else {
            results = await API_get_org_credential();
            set_credential(results);
        }
    }

    async function set_archive_status(status) {
        try {
            credential_type === 'indv' ? await API_put_indv_archive(status) : await API_put_org_credential_archive(status);
            await populate_credential();
            populate_function();
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_indv_credential() {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.get('indv-credentials', '/get-indv-credential/' + var_target_id,
        {
            queryStringParameters: {
                tz : datelib.timezone
            }
        });
    }

    function API_get_org_credential() {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.get('org-orgcredentials', '/get-org-credential/' + var_target_id,
        {
            queryStringParameters: {
                tz : datelib.timezone
            }
        });
    }

    function API_put_indv_archive(status) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        let transaction_info = {
            organization_id: auth.actingorganization_id,
            transactionorg: auth.organization_id
        };
        return API.put('indv-credentials', '/put-indv-archive/' + var_target_id + '/' + status, { body: transaction_info });
    }

    function API_put_org_credential_archive(status) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        let transaction_info = {
            organization_id: auth.actingorganization_id,
            transactionorg: auth.organization_id
        };
        return API.put('org-orgcredentials', '/put-org-credential-archive/' + var_target_id + '/' + status, { body: transaction_info });
    }

    /*TODO: Replace this function with a background token generator and add to URL path for backend validation.
    function API_get_credential_issuance_otp(credential_id) {
        return Math.floor(Math.random() * 1000000000) + 1000000000;
    } */

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_archive(archive, confirm = false) {
        if (archive && !confirm) {
            set_mdl_confirmation_open(true);
        } else {
            set_archive_status(archive ? 'ARCHIVE' : 'ACTIVE');
        }
    }

    function onClick_open_attachment() {
        set_docviewerid(var_target_id);
        set_mdl_docviewer_open(true);
    }

    function onClick_verifiablecredential() {
        set_json(JSON.parse(var_credential.json));
        set_mdl_verifiablepresentationviewer_open(true);
    }

    function onClick_publiccertificate() {
        if (credential_type === 'indv') {
            window.open('/public/certificate/' + var_target_id);
        } else {
            window.open('/public/orgcertificate/' + var_target_id);
        }
    }

    function onClick_linkedin() {
        let issueMonth = var_credential.issuedepoch ? datelib.epoch_to_utcmoment(var_credential.issuedepoch).format('M') : '';
        let issueYear = var_credential.issuedepoch ? datelib.epoch_to_utcmoment(var_credential.issuedepoch).format('YYYY') : '';
        let expireMonth = var_credential.expiresepoch ? datelib.epoch_to_utcmoment(var_credential.expiresepoch).format('M') : '';
        let expireYear = var_credential.expiresepoch ? datelib.epoch_to_utcmoment(var_credential.expiresepoch).format('YYYY') : '';

        window.open('https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME/public/certificate/&name='
            + encodeURIComponent(var_credential.credential)
            + '&organizationName=' + encodeURIComponent(var_credential.issuedby)
            + '&issueYear=' + issueYear + '&issueMonth=' + issueMonth + '&expirationYear=' + expireYear + '&expirationMonth=' + expireMonth
            + '&certUrl=' + encodeURIComponent(window.location.protocol + '//' + window.location.hostname + '/public/certificate/' + var_credential.id)
            + '&certId=' + encodeURIComponent(var_credential.code));
    }

    function onClick_renewal() {
        var_credential.website.toLowerCase().startsWith('https://') ? window.open(var_credential.website) : window.open('https://' + var_credential.website);
    }

    function onClick_claimVC() {
        /* NOTE: the below 2 lines are temporary until the "claim VC workflow is implemented */
        set_mdl_qrcode_open(true);
        console.log('Claim VC Button Clicked');
    }

    // RENDER APP ======================================================================================================

    return var_credential === null ? null : (
        <>
            <div>
                {var_credential.status === 'ARCHIVE'
                    ?
                    <div className='modal-archive' onClick={() => onClick_archive(false)}>
                        <div className='modal-archive-icon'><img className='btn_icon' src={'/icons/archive 30px (ffffff).svg?ts=' + Date.now()} alt={t('archive icon')}/></div>
                        <div className='modal-archive-text'>{t('This credential has been archived.  Click this bar to make it active again.')}</div>
                    </div>
                    :
                    var_credential.verification_status === 'INVALID' || var_credential.confirmation_status === 'EXPIRED'
                    ?
                    <div className='credential-status'>
                        <img src={'/icons/certificate warning 30px (e0454f).svg?ts=' + Date.now()} alt={t('warning icon')}/>
                        <div className='credential-status-invalid'>{var_credential.confirmation_status}</div>
                    </div>
                    :
                    var_credential.verification_status === 'VALID' && var_credential.confirmation_status === 'SELF VERIFIED'
                    ?
                    <div className='credential-status'>
                        <img src={'/icons/certificate selfverified checkmark 30px (29719a).svg?ts=' + Date.now()} alt={t('active icon')}/>
                        <div className='credential-status-selfverified'>{var_credential.confirmation_status}</div>
                    </div>
                    :
                    var_credential.verification_status === 'PENDING'
                    ?
                    <div className='credential-status'>
                        <img src={'/icons/certificate pending 30px (f2ae02).svg?ts=' + Date.now()} alt={t('active icon')}/>
                        <div className='credential-status-pending'>{var_credential.confirmation_status}</div>
                    </div>
                    :
                    <div className='credential-status'>
                        <img src={'/icons/certificate checkmark 30px (69b536).svg?ts=' + Date.now()} alt={t('active icon')}/>
                        <div className='credential-status-active'>{var_credential.confirmation_status}</div>
                    </div>
                }
                <div className='credential-info'>
                    {credential_type === 'indv' &&
                        <div className='credential-holder'>{var_credential.individual_name}</div>
                    }
                    <div className='credential-name'>{var_credential.code && (var_credential.code + ': ')}{var_credential.credential}</div>
                    <div className='credential-issuedby'>{var_credential.issuedby}</div>
                    {(var_credential.issued_date || var_credential.expires_date) &&
                        <div className='credential-row'>
                            {var_credential.issued_date &&
                                <>
                                    <div className='credential-label'>{t('Issued')}:</div>
                                    <div className='credential-value'>{datelib.date_localized_format(var_credential.issued_date)}</div>
                                </>
                            }
                            {var_credential.expires_date &&
                                <>
                                    <div className='credential-label'>{t('Expires')}:</div>
                                    <div className='credential-value'>{datelib.date_localized_format(var_credential.expires_date)}</div>
                                </>
                            }
                        </div>
                    }
                    {var_credential.credential_number &&
                        <div className='credential-row'>
                            <div className='credential-label'>{t(var_credential.credential_number_label || 'Credential number')}:</div>
                            <div className='credential-value'>{var_credential.credential_number}</div>
                        </div>
                    }
                    {var_credential.submittedfilename &&
                        <div className='credential-attachment'>
                            <Button className='btn_tertiary_link' onClick={onClick_open_attachment}><img className='btn_icon' src={'/icons/file 30px (000000).svg?ts=' + Date.now()} alt={t('file icon')}/>{var_credential.submittedfilename}</Button>
                        </div>
                    }
                    {var_credential.transaction_id &&
                        <div className='credential-row credential-transaction-row'>
                            <div className='credential-label'>{t('Transaction ID')}:</div>
                            <img src={'/icons/transaction 30px (000000).svg?ts=' + Date.now()} alt={t('transaction icon')}/>
                            <div className='credential-value'>{var_credential.transaction_id}</div>
                        </div>
                    }
                    {var_credential.status === 'ACTIVE' && (var_credential.verification_status === 'VALID' || var_credential.confirmation_status === 'EXPIRED') &&
                        <div className='credential-actionbar'>
                            {var_credential.has_customsecurity !== 'YES' &&
                                <Button className='btn_tertiary_link' onClick={onClick_publiccertificate}><img className='btn_icon' src={'/icons/public credential 30px (000000).svg?ts=' + Date.now()} alt={t('public credential icon')}/>{t('View public credential')}</Button>
                            }
                            {(var_credential?.json?.includes('VerifiableCredential')) &&
                                <Button className='btn_tertiary_link' onClick={onClick_verifiablecredential}><img className='btn_icon' src={'/icons/public credential 30px (000000).svg?ts=' + Date.now()} alt={t('verifiable credential icon')}/>{t('View VC details')}</Button>
                            }
                            {var_credential.verification_status === 'VALID' && var_credential.has_customsecurity !== 'YES' && (var_credential.individual_id === JSON.parse(sessionStorage.getItem('authentication')).id) &&
                                <Button className='btn_tertiary_link' onClick={onClick_linkedin}><img className='btn_icon' src={'/icons/linkedin 30px (4dcbd4).svg?ts=' + Date.now()} alt={t('public credential icon')}/>{t('Add to LinkedIn')}</Button>
                            }
                            {var_credential.website !== null && var_credential.website !== '' && var_credential.expires === 'YES' &&
                                <Button className='btn_tertiary_link' onClick={onClick_renewal}><img className='btn_icon' src={'/icons/link 30px (4dcbd4).svg?ts=' + Date.now()} alt={t('link icon')}/>{t('Renew credential')}</Button>
                            }
                            {auth.org_verifiable_credentials && var_credential.verifiable_credential === 'YES' && var_credential.unsigned_vc &&
                                <Button className='btn_tertiary_link' onClick={onClick_claimVC}><img className='btn_icon' src={'/icons/qr code (000000).svg?ts=' + Date.now()} alt={t('qr icon')}/>{t('Add to VC Wallet')}</Button>
                            }

                        </div>
                    }
                </div>
            </div>
            {var_credential.status === 'ACTIVE' &&
                <div className='modal-footer-button-bar'>
                    <Button className='btn_primary btn_active' onClick={() => set_activetab('NEW')}>{t('CREATE NEW VERSION')}</Button>
                    <Button className='btn_tertiary' onClick={() => onClick_archive(true)}>{t('Archive credential')}</Button>
                </div>
            }



            {/***** MODAL: CONFIRMATION *******************************************************************************/}

            <Modal id='mdl_confirmation'
                   dimmer={'inverted'}
                   open={var_mdl_confirmation_open}
                   onClose={() => set_mdl_confirmation_open(false)}>
                <MDL_CONFIRMATION
                    set_mdl_open={set_mdl_confirmation_open}
                    var_modaltitle={var_credential.requirements ? t('Archive warning') : t('Archive Credential')}
                    var_message={var_credential.requirements ?
                        <>{t('Archiving this credential will result in')} {var_credential.requirements} {t('becoming unmet')}.<br />{t('This may impact your ability to work')}.<br /><br />
                          {t('Are you sure you want to proceed? This action can not be undone')}.</>
                          :
                          t('Are you sure you want to archive this credential?')
                    }
                    confirmation_function={() => onClick_archive(true, true)}
                />
            </Modal>

            {/***** END MODAL: CONFIRMATION **************************************************************************/}

            {/***** MODAL: DOCUMENT VIEW *****************************************************************************/}

            <Modal id='mdl_docviewer'
                    dimmer={'inverted'}
                    open={var_mdl_docviewer_open}
                    onClose={() => set_mdl_docviewer_open(false)}>
                <MDL_DOCVIEWER
                    set_mdl_open={set_mdl_docviewer_open}
                    targetid={var_docviewerid}
                    classification={credential_type === 'indv' ? 'credential' : 'orgcredential'}
                />
            </Modal>

            {/***** END MODAL: DOCUMENT VIEW *************************************************************************/}

            {/***** MODAL: PRESENTATION VIEWER *******************************************************************/}

            <Modal id="mdl_cmp_verifiablepresentationviewer"
                   dimmer={'inverted'}
                   open={var_mdl_verifiablepresentationviewer_open}
                   onClose={() => set_mdl_verifiablepresentationviewer_open(false)}
                   closeOnEscape={false}
                   closeOnDimmerClick={false}>
                <CMP_VERIFIABLEPRESENTATION_VIEWER
                    set_mdl_open={set_mdl_verifiablepresentationviewer_open}
                    title={var_credential.credential}
                    var_json={var_json}>
                </CMP_VERIFIABLEPRESENTATION_VIEWER>
            </Modal>

            {/***** END MODAL: PRESENTATION VIEWER ***************************************************************/}

            <Modal id='mdl_cmp_qrcode'
                    className='modal-container'
                    dimmer={'inverted'}
                    open={var_mdl_qrcode_open}
                    onClose={() => set_mdl_qrcode_open(false)}
                    closeOnEscape={false}
                     closeOnDimmerClick={false}>
                <CMP_QRCODE
                    set_mdl_open={set_mdl_qrcode_open}
                    var_id={"credentialVCIssuanceQRCode"}
                    var_title_text={t("Issuance request")}
                    var_instruction_text={t("Scan the QR code using your credential wallet application.")}
                    var_action_text={t("ADD TO WALLET")}
                    var_action_url={config.partner_api_domain.URL + "/credentials/" + var_credential.id}
                    var_allow_download={false}
                    var_close_text={t("CLOSE")}
                />
            </Modal>
        </>
    );
}

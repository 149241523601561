//  OUTCOMES -----------------------------------------------------------------------------------------------------------
// 
//  - the user can update an exisiting ORG requirement
//
//  --------------------------------------------------------------------------------------------------------------------

import React, { useState } from "react";

import TAB_DETAILS from "./tab_details/tab_details";
// import TAB_HOLDERS from "./tab_holders/tab_holders";

import './mdl_update.css';

import { useTranslation } from 'react-i18next';
import '../../../../../i18n';


export default function({ set_mdl_open, var_target_id, populate_function }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();
    const [ var_activetab, set_activetab] = useState('DETAILS');


    //  variable listeners ---------------------------------------------------------------------------------------------



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_tab(var_tab){
        set_activetab(var_tab);
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{t('Update Requirement')}</div>
                <div className="modal-header-tabs">
                    <div className={"modal-header-tabs-item"  + (var_activetab === 'DETAILS' ? ' active' : '')} onClick={() => onClick_tab(`DETAILS`)}>
                    {t('Details')}
                    </div>
                    {/* <div className={"modal-header-tabs-item"  + (var_activetab === 'HOLDERS' ? ' active' : '')} onClick={() => onClick_tab(`HOLDERS`)}>
                    {t('Holders')}
                    </div> */}
                </div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
            </div>
            <div className="modal-tabs">
                <div className={(var_activetab !== 'DETAILS' ? 'modal-tab-hide' : '')}>
                    <TAB_DETAILS
                        set_mdl_open={set_mdl_open}
                        var_activetab={var_activetab}
                        var_requirement_id={var_target_id}
                        populate_function={populate_function}>
                    </TAB_DETAILS>
                </div>
                {/* <div className={(var_activetab !== 'HOLDERS' ? 'modal-tab-hide' : '')}>
                    <TAB_HOLDERS
                        var_activetab={var_activetab}
                        var_credential_id={var_target_id}>
                    </TAB_HOLDERS>
                </div> */}
            </div>
        </>
    )
}
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { Button , Dropdown ,Form ,Checkbox} from "semantic-ui-react";
import React from "react";

import { useTranslation } from 'react-i18next';
import '../../../../../../i18n';


import './mdl_add.css';



export default function({ set_mdl_open, populate_function, var_package_id }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();
    const [ var_dd_forms, set_dd_forms ] = useState([]);
    const [ var_selectedform, set_selectedform ] = useState('');
    const [ var_ready, set_ready ] = useState(false);
    const [ var_processing, set_processing ] = useState(false);

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_dd_forms();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_dd_forms() {
        try {
          let results = await API_get_dd_org_forms();
          set_dd_forms(results);
        } catch (e) {
          console.log(e);
        }
    }

    async function assign_form(){
        set_processing(true);
        try {
            let data = []
            var_selectedform.map(form_id => data.push({ 'package_id': var_package_id, 'form_id': form_id }) );
            await  API_post_package_form(data);

            set_ready(false);
            set_processing(false);
            populate_function();
            set_mdl_open(false);
        } catch (e) {
            console.log(e);
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

   // post document in location
    function API_post_package_form(data){
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.post('org-forms','/post-package-forms', { body: data});

    }

    //get available loctaions
    function API_get_dd_org_forms() {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.get('org-forms','/get-dd-org-forms/'+JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id + '/' + var_package_id);
    }



    //  event functions ------------------------------------------------------------------------------------------------


    function onChange_form(e, { value }){
        set_processing(true);
        set_selectedform(value);
        set_ready(true);
        set_processing(false);
    }

    function onClick_submit(){
        if(var_ready){
            assign_form();
        }
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{t('Add Form To Package')}</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t('close icon')}/></div>
            </div>
            <div className="modal-content">
                <Form>
                    <Form.Select
                        className='form_id'
                        id='form_id'
                        name='form_id'
                        label={t("FORM")}
                        search
                        options={var_dd_forms}
                        value={var_selectedform || ''}
                        onChange={onChange_form}
                        placeholder={t("Select a form...")}
                        multiple
                        selection
                    />
                </Form>
            </div>
            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>{t('CANCEL')}</Button>
                    {!var_processing ?
                        <Button className={"btn_primary " + (var_ready ? ' btn_active' : '')} onClick={() => onClick_submit()}>{t('ADD FORM')}</Button>
                        :
                        <Button loading className="btn_primary btn_active">{t('ADD FORM')}</Button>
                    }
                </div>
            </div>
        </>
    )
}

import React, { useEffect, useState } from "react";
import { Modal, Message } from 'semantic-ui-react';
import { API } from "aws-amplify";
import datelib from '../../../libs/date-lib';
import "./certificate.css";
import { useTranslation } from 'react-i18next';
import '../../../i18n';

import MDL_DOCVIEWER from "../../../components/cmp_docviewer/cmp_docviewer";

export default function(appProps) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [var_certificate, set_certificate] = useState([]);
    const [var_certificate_ready, set_certificate_ready] = useState(false);
    const [var_courselogo_url, set_courselogo_url] = useState(null);
    const { t } = useTranslation();

    const [ var_mdl_docviewer_open, set_mdl_docviewer_open ] = useState(false);
    const [ var_linkStatusReady, set_linkStatusReady] = useState(false)
    const [ var_linkstatus, set_linkstatus ] = useState('');  

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        appProps.set_visibleframe(false);                  
        window.scrollTo(0, 0);        
       
        if (appProps.match.params.linkid === undefined) {
            populatecertificate();
        // Retrieve location profile link status requested from caller
        } else if (appProps.match.params.islocation) { 
            // Store the request for location profile link from caller in case of appProps.match.params.islocation
            // being undefined due to this page being reloaded or the removal of the display for the path parameter 
            // islocation from URL address bar         
            sessionStorage.setItem('islocation', appProps.match.params.islocation);          

             // Remove the display of the path parameter islocation from URL address bar
             // This causes the appProps.match.params.islocation being undefined
             const myroute = getRoute('/public/certificate/:credentialid/:linkid/:islocation', 
                                       appProps.match.params.credentialid, appProps.match.params.linkid, '');
             appProps.history.push(myroute);

            // Get location profile link status
            populate_public_loc_linkstatus();               
        //  Get location profile link status based on the stored location request in case of this page being reloaed 
        } else if (sessionStorage.getItem('islocation')) {          
            // Get location profile link status                
            populate_public_loc_linkstatus();
        } else { // Get individual profile link status           
            populate_public_linkstatus();
        }     

        if (var_linkStatusReady && (var_linkstatus !== 'INACTIVE' && var_linkstatus !== 'REVOKED')) {                
            populatecertificate();                          
        }        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_linkStatusReady, var_linkstatus]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populatecertificate(){
        try {
            let certificate = (await API_get_certificate())[0];
            let logo = certificate.verifying_organizationlogo;
            certificate.verifying_organizationlogo = (logo && !logo.toLowerCase().startsWith('https://') && !logo.toLowerCase().startsWith('http://')) ?
                ((await API.endpoint('storage')) + '/logo/' + certificate.verifying_organization_id + '/' + logo) : logo;
            set_certificate(certificate);
            set_courselogo_url((await API.endpoint('storage'))+'/course/');
            set_certificate_ready(true);         
        } catch (e) {
            console.log(e);
        }
    }

    async function populate_public_linkstatus(){
        try {
            let results = await API_get_public_linkstatus();           
            set_linkstatus(results[0]['status']);  
            set_linkStatusReady(true);         
        } catch (e) {
            console.log(e);
        }
    }
    
    async function populate_public_loc_linkstatus(){
        try {
            let result = await API_get_pubic_loc_linkstatus();
            set_linkstatus(result[0]['status']);  
            set_linkStatusReady(true);
        } catch (e) {
            console.log(e);
        }
    }

    function getRoute(path) {
        let args = Array.prototype.slice.call(arguments, 1);
        let count = -1;
        return path.replace(/:[a-zA-Z?]+/g, function (match) {               
            count += 1;
            return args[count] !== undefined ? args[count] : match;
        });
    };

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_certificate(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('indv-credentials', '/get-public-certificate/'+appProps.match.params.credentialid, {
            queryStringParameters: {
                tz : datelib.timezone
            }
        });
    }

    function API_get_public_linkstatus(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('indv', '/get-public-linkstatus/'+appProps.match.params.linkid);
    }


    function API_get_pubic_loc_linkstatus(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));        
        return API.get('org-locations', '/get-public-loc-linkstatus/'+appProps.match.params.linkid);
    }    

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_document() {
        set_mdl_docviewer_open(true);
    }

    // RENDER APP ======================================================================================================

    return(
        <div className="digitalcertificate_wrapper">
            <div className="content-wrapper">
                {var_certificate_ready &&
                <>
                    <div className="content-card" id="digitalcertificate">
                        <div className="digitalcertificate_content">
                            <div className="digitalcertificate_content_left">
                                <div className="digitalcertificate_header">
                                {(var_certificate.verification_status === 'INVALID' || var_certificate.confirmation_status === 'EXPIRED')?
                                    <>
                                        <img className="digitalcertificate_icon invalid" src={"/icons/certificate warning 30px (e0454f).svg?ts=" + Date.now()} alt={t('warning icon')}/>
                                        <div className="digitalcertificate_title invalid">{var_certificate.confirmation_status}</div>
                                    </>
                                :
                                var_certificate.confirmation_status === 'PENDING' ?
                                    <>
                                        <img className="digitalcertificate_icon pending" src={"/icons/certificate pending 30px (f2ae02).svg?ts=" + Date.now()} alt={t('pending icon')}/>
                                        <div className="digitalcertificate_title pending">{var_certificate.confirmation_status}</div>
                                    </>
                                :
                                    <>
                                        <img className="digitalcertificate_icon" src={(var_certificate.verification_status === 'VALID' && var_certificate.confirmation_status === 'SELF VERIFIED') ? "/icons/certificate selfverified checkmark 30px (29719a).svg?ts=" + Date.now() : "/icons/certificate checkmark 30px (69b536).svg?ts=" + Date.now()} alt={t('active icon')}/>
                                        <div className={"digitalcertificate_title" + ((var_certificate.verification_status === 'VALID' && var_certificate.confirmation_status === 'SELF VERIFIED') ? " self_verified" : "")}>{var_certificate.confirmation_status}</div>
                                    </>
                                }
                                </div>
                                <div className="digitalcertificate_content_1">
                                    <div className="digitalcertificate_content_1_individual">{var_certificate.individual}</div>
                                    <div className="digitalcertificate_content_1_credential">{var_certificate.dependant_credential}</div>
                                </div>
                                <div className="digitalcertificate_content_2">
                                    <div className="digitalcertificate_content_2_issuedby">{var_certificate.dependant_organization}</div>
                                    {(var_certificate.dependant_detail !== '' && var_certificate.dependant_detail !== undefined && var_certificate.dependant_detail !== null) &&
                                        <div className="digitalcertificate_content_2_issued_date">{var_certificate.dependant_detail}</div>
                                    }
                                    <div className="digitalcertificate_content_2_issued_date">{t('Issued')} {datelib.date_localized_format(var_certificate.credential_issued)}</div>
                                </div>
                                {(var_certificate.credential_document && var_certificate.credential_document !== 'No file selected...') &&
                                    <>
                                        <div className="digitalcertificate_content_3">
                                            <div className="digitalcertificate_content_3_view_document" onClick={() => onClick_document()}>{t('View supporting document')}</div>
                                            {var_certificate.verification_status === 'VALID' && var_certificate.credential_id !== '00000000-0000-0000-0000-000000000000' &&
                                                <div className='digitalcertificate_document_disclaimer'>
                                                    {t('Note') + ': ' + t('Supporting documents have been provided by the holder of the credential and may not not have been verified by the credential issuer.')}
                                                </div>
                                            }
                                        </div>
                                    </>
                                }
                                <div className="digitalcertificate_detail">
                                    {(var_certificate.verifying_organizationlogo !== '' && var_certificate.verifying_organizationlogo !== null) &&
                                        <div className="digitalcertificate_detail_logo">
                                            <img src={var_certificate.verifying_organizationlogo} alt={t('logo')} />
                                        </div>
                                    }
                                    <div className="digitalcertificate_detail_list">
                                        <div className="digitalcertificate_detail_item">
                                            <span className="detail_item_label">{t('CREDENTIAL')}: </span>
                                            <span>{var_certificate.credential}</span>
                                        </div>
                                        <div className="digitalcertificate_detail_item">
                                            <span className="detail_item_label">{t('VERIFICATION ID')}: </span>
                                            <span>{var_certificate.verificationid}</span>
                                        </div>
                                        <div className="digitalcertificate_detail_item">
                                            <span className="detail_item_label">{t('VERIFICATION ISSUED BY')}: </span>
                                            <span>{var_certificate.verifying_organization}</span>
                                        </div>
                                        <div className="digitalcertificate_detail_item">
                                            <span className="detail_item_label">{t('VERIFICATION VALID FROM')}: </span>
                                            <span>{datelib.date_localized_format(var_certificate.credential_issued)}</span>
                                        </div>
                                        <div className="digitalcertificate_detail_item">
                                            <span className="detail_item_label">{t('VERIFICATION VALID UNTIL')}: </span>
                                            <span>{datelib.date_localized_format(var_certificate.verification_expires)}</span>
                                        </div>
                                    </div>
                                </div>
                                {(var_certificate.credential_description || var_certificate.credential_skills) &&
                                    <div className="digitalcertificate_description">
                                        {(var_certificate.credential_logo !== '' && var_certificate.credential_logo !== null) &&
                                            <div className="digitalcertificate_description_logo">
                                                {(var_certificate.credential_logo.match(/.(jpg|jpeg|png|bmp|gif)$/i) || var_certificate.credential_logo.startsWith('data')) &&
                                                <img src={var_certificate.credential_logo.startsWith('https://') ||
                                                var_certificate.credential_logo.startsWith('http://') ||
                                                var_certificate.credential_logo.startsWith('data') ||
                                                !var_certificate.credential_logo.match(/.(jpg|jpeg|png|bmp|gif)$/i) ? var_certificate.credential_logo :
                                                    var_courselogo_url + var_certificate.credential_id + '/' + var_certificate.credential_logo}
                                                     alt={t('certificate logo')}>
                                                </img>
                                                }
                                            </div>
                                        }
                                        <div className="digitalcertificate_description_detail">
                                            {var_certificate.credential_description !== '' && var_certificate.credential_description !== null  &&
                                                <div className="digitalcertificate_description_detail_item">
                                                    <div className="description_detail_title">{t('Description')}</div>
                                                    <div className="description_detail_desc">
                                                        {var_certificate.credential_description.replace(/(<([^>]+)>)|\r\n|\n|\r|\\n|\\r/gi, '')}
                                                    </div>
                                                </div>
                                            }
                                            {var_certificate.credential_skills !== '' && var_certificate.credential_skills !== null  &&
                                                <div className="digitalcertificate_description_detail_item">
                                                    <div className="description_detail_title">{t('Skills')}</div>
                                                    <div className="description_detail_desc">
                                                        {var_certificate.credential_skills}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>

                        <div className="digitalcertificate_footer">
                            {t('Powered by Credivera')}
                        </div>
                    </div>
                </>
                }
            </div>

            {var_linkStatusReady && (var_linkstatus === 'INACTIVE' || var_linkstatus === 'REVOKED') &&
                <div className="public_wrapper">
                    <div>
                        <div className="content">
                            <div className="content-wrapper">
                                <Message
                                    color='red'>{t('Please contact the owner of the profile to request access.')}</Message>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {/***** MODAL: DOCUMENT VIEW ************************************************************************/}

            <Modal id="mdl_docviewer"
                   dimmer={'inverted'}
                   open={var_mdl_docviewer_open}
                   onClose={() => set_mdl_docviewer_open(false)}>
                <MDL_DOCVIEWER
                    set_mdl_open={set_mdl_docviewer_open}
                    targetid={appProps.match.params.credentialid}
                    classification='credential'
                    ispublic={true}
                />
            </Modal>

            {/***** END MODAL: DOCUMENT VIEW *********************************************************************/}

        </div>
    )
};
import "core-js/stable";
import "regenerator-runtime/runtime";
import 'core-js/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import config from './config';
import App from './App';
import './i18n';

import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import './index.css';

let client_id = config.cognito.APP_CLIENT_ID;
let sso_config = sessionStorage.getItem('sso_config');
if (sso_config && JSON.parse(sso_config).hasOwnProperty('sso_client')) {
    client_id = JSON.parse(sso_config).sso_client; //switch to saml client
}

Amplify.configure({
    Storage: {
        region: config.s3.REGION,
        bucket: config.s3.BUCKET,
        identityPoolId: config.cognito.IDENTITY_POOL_ID
    },
    Auth: {
        mandatorySignIn: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: client_id
    },
    API: {
        endpoints: [
            {
                name: "admin",
                endpoint: config.apiGateway_admin.URL,
                region: config.apiGateway_admin.REGION
            },
            {
                name: "indv",
                endpoint: config.apiGateway_indv.URL,
                region: config.apiGateway_indv.REGION
            },
            {
                name: "indv-credentials",
                endpoint: config.apiGateway_indv_credentials.URL,
                region: config.apiGateway_indv_credentials.REGION
            },
            {
                name: "indv-dailychecks",
                endpoint: config.apiGateway_indv_dailychecks.URL,
                region: config.apiGateway_indv_dailychecks.REGION
            },
            {
                name: "indv-documents",
                endpoint: config.apiGateway_indv_documents.URL,
                region: config.apiGateway_indv_documents.REGION
            },
            {
                name: "indv-forms",
                endpoint: config.apiGateway_indv_forms.URL,
                region: config.apiGateway_indv_forms.REGION
            },
            {
                name: "org",
                endpoint: config.apiGateway_org.URL,
                region: config.apiGateway_org.REGION
            },
            {
                name: "org-individuals",
                endpoint: config.apiGateway_org_individuals.URL,
                region: config.apiGateway_org_individuals.REGION
            },
            {
                name: "integrations",
                endpoint: config.apiGateway_integrations.URL,
                region: config.apiGateway_integrations.REGION
            },
            {
                name: "org-credentials",
                endpoint: config.apiGateway_org_credentials.URL,
                region: config.apiGateway_org_credentials.REGION
            },
            {
                name: "org-dailychecks",
                endpoint: config.apiGateway_org_dailychecks.URL,
                region: config.apiGateway_org_dailychecks.REGION
            },
            {
                name: "org-documents",
                endpoint: config.apiGateway_org_documents.URL,
                region: config.apiGateway_org_documents.REGION
            },
            {
                name: "org-forms",
                endpoint: config.apiGateway_org_forms.URL,
                region: config.apiGateway_org_forms.REGION
            },
            {
                name: "org-locations",
                endpoint: config.apiGateway_org_locations.URL,
                region: config.apiGateway_org_locations.REGION
            },
            {
                name: "org-onsites",
                endpoint: config.apiGateway_org_onsites.URL,
                region: config.apiGateway_org_onsites.REGION
            },
            {
                name: "org-orgcredentials",
                endpoint: config.apiGateway_org_orgcredentials.URL,
                region: config.apiGateway_org_orgcredentials.REGION
            },
            {
                name: "org-wallet",
                endpoint: config.apiGateway_org_wallet.URL,
                region: config.apiGateway_org_wallet.REGION
            },
            {
                name: "org-requirements",
                endpoint: config.apiGateway_org_requirements.URL,
                region: config.apiGateway_org_requirements.REGION
            },
            {
                name: "verifiable-credentials",
                endpoint: config.apiGateway_verifiable_credentials.URL,
                region: config.apiGateway_verifiable_credentials.REGION
            },
            {
                name: "translation",
                endpoint: config.apiGateway_translation.URL,
                region: config.apiGateway_translation.REGION
            },
            {
                name: "scanner",
                endpoint: config.apiGateway_scanner.URL,
                region: config.apiGateway_scanner.REGION
            },
            {
                name: "storage",
                endpoint: config.apiGateway_storage.URL,
                region: config.apiGateway_storage.REGION
            }
        ]
    }
});


ReactDOM.render(
    <Router>
        <App />
    </Router>,
    document.getElementById('root')
);

serviceWorker.unregister();

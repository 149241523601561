import { API } from "aws-amplify";
import { Button, Modal, Table } from 'semantic-ui-react'
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import '../../../../i18n';

import CMP_TABLECONTROLS, { filter_helper } from '../../../../components/cmp_tablecontrols/cmp_tablecontrols';
import MDL_INVITEINDIVIDUAL from "./mdl_inviteindividual/mdl_inviteindividual";
import MDL_BULKINVITE from "./mdl_bulkinvite/mdl_bulkinvite";
import MDL_INDV from "../mdl_indv/mdl_indv";

import './crd_individuals.css';



export default function(appProps){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_individuals, set_individuals ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(100);
    const [ var_offset, set_offset] = useState(0);
    const [ var_archive, set_archive ] = useState('NO');
    const [ var_sortby, set_sortby ] = useState('individual');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));

    const [ var_mdl_inviteindividual_open, set_mdl_inviteindividual_open ] = useState(false);
    const [ var_mdl_bulkinvite_open, set_mdl_bulkinvite_open ] = useState(false);
    const [ var_mdl_indv_open, set_mdl_indv_open ] = useState(false);
    const [ var_target_id, set_target_id ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_archive !== undefined) {
            set_filter(filter_helper.initialize(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_archive]);

    useEffect(()=>{
        if (var_filter.load){
            populate_individuals(null,0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_filter]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_individuals(limit,offset,sortby,sortorder,archive,filter){

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        if(archive) {
            set_archive(archive);
        }else{
            archive=var_archive
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_individuals(limit,offset,sortby,sortorder,archive,filter);
            set_individuals(results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername,sortorder){
        try {
            return await API_get_individuals(filter_helper.maxFilterItems, 0, filtername, sortorder,var_archive,[], filtername);
        } catch (e) {
            console.log(e);
        }
    }

    async function download_individuals(limit, offset){
       try {
           return await API_get_individuals(limit, offset, var_sortby, var_sortorder, var_archive, var_filter);
       }  catch (e) {
            console.log(e);
            throw e;
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_individuals(limit,offset,sortby,sortorder,archive, filter, filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org',
            '/get-individuals/'+JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    archive: archive,
                    filtername: filtername
                },
                body: filter
            }
        );
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_individuals(null,0,sortby,sortorder)
    }

    function onOpen_mdl_inviteindividual(){
        set_mdl_inviteindividual_open(true);
    }

    function onClick_individual(var_target_id){
        set_target_id(var_target_id);
        set_mdl_indv_open(true);
    }

    function onOpen_mdl_bulkinvite(){
        set_mdl_bulkinvite_open(true);
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="content-card" id="crd_org_individuals_individuals">
                <div className="content-card-header">
                    {var_archive === 'NO' ?
                        <div className="content-card-header-title">{t("All Active") + " " + JSON.parse(sessionStorage.getItem('authentication')).organization + " " + t("Individuals")}</div>
                        :
                        <div className="content-card-header-title">{t("All Archived") + " " + JSON.parse(sessionStorage.getItem('authentication')).organization + " " + t("Individuals")}</div>
                    }
                    <Button className="btn_tertiary" onClick={() => onOpen_mdl_inviteindividual()}><img className="btn_icon" src={"/icons/invite 30px (4dcbd4).svg?ts=" + Date.now()} alt={t("invite icon")}></img>{t("INVITE INDIVIDUAL")}</Button>
                    <Button className="btn_tertiary" onClick={() => onOpen_mdl_bulkinvite()}><img className="btn_icon" src={"/icons/upload 30px (4dcbd4).svg?ts=" + Date.now()} alt={t("upload icon")}></img>{t("BULK INVITE")}</Button>
                </div>
                <div className="content-card-gridcontent">
                    <div className="content-filter">

                        <div className="content-filter-archive">
                            {var_archive === 'NO' ?
                                <Button className="btn_tertiary" onClick={() => set_archive('YES')}><img className="btn_icon" src={"/icons/archive 30px (4dcbd4).svg?ts=" + Date.now()} alt={t("archive icon")}/>{t("VIEW ARCHIVED INDIVIDUALS")}</Button>
                                :
                                <Button className="btn_tertiary" onClick={() => set_archive('NO')}><img className="btn_icon" src={"/icons/checkmark 30px (4dcbd4).svg?ts=" + Date.now()} alt={t("checkmark icon")}/>{t("VIEW ACTIVE INDIVIDUALS")}</Button>
                            }
                        </div>

                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                var_offset={var_offset} var_limit={var_limit} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_individuals && var_individuals.length > 0 ? var_individuals[0]['totalrows'] : 0} populatefunction={populate_individuals}
                                downloadname='Individuals' downloadfunction={download_individuals}
                                var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                                table_config={[
                                    {name: 'individual', datatype: 'text', labelKey: 'NAME', download: true, filter: true},
                                    {name: 'email', datatype: 'text', labelKey: 'EMAIL', download: true, filter: true},
                                    {name: 'phone', datatype: 'text', labelKey: 'PHONE', download: true, filter: true},
                                    {name: 'organization', datatype: 'text', labelKey: 'ORGANIZATION', download: true, filter: true},
                                    {name: 'locations', datatype: 'text', labelKey: 'LOC/PROJ', download: true, filter: true},
                                    {name: 'permission', datatype: 'text', labelKey: 'PERMISSIONS', download: true, filter: true},
                                    {name: 'individual_type', datatype: 'text', labelKey: 'TYPE', download: true, filter: true},
                                    {name: 'invitestatus', datatype: 'text', labelKey: 'INVITE STATUS', download: true, filter: true},
                                ]}
                            />
                        </div>
                    </div>
                    <div className="tablewrapper">
                        <Table sortable compact className={(var_loading ? " tbl_loading" : "")}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell sorted={var_sortby === 'individual' ? var_sortorder : null} onClick={()=>onClick_sort('individual')}>{t("NAME")}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_email" sorted={var_sortby === 'email' ? var_sortorder : null} onClick={()=>onClick_sort('email')}>{t("EMAIL")}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_phone" sorted={var_sortby === 'phone' ? var_sortorder : null} onClick={()=>onClick_sort('phone')}>{t("PHONE")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'organization' ? var_sortorder : null} onClick={() => onClick_sort('organization')}>{t("ORGANIZATION")}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_location" sorted={var_sortby === 'locations' ? var_sortorder : null} onClick={()=>onClick_sort('locations')}>{t("LOC/PROJ")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'permission' ? var_sortorder : null} onClick={()=>onClick_sort('permission')}>{t("PERMISSIONS")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'individual_type' ? var_sortorder : null} onClick={()=>onClick_sort('individual_type')}>{t("TYPE")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'invitestatus' ? var_sortorder : null} onClick={()=>onClick_sort('invitestatus')}>{t("INVITE STATUS")}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {var_ready && var_individuals[0]['totalrows'] !== 0 &&
                            <Table.Body>
                                {var_individuals.map((item, i) =>
                                    <Table.Row key={i} onClick={()=>onClick_individual(item.id)}>
                                        <Table.Cell>{item.individual}</Table.Cell>
                                        <Table.Cell className="td_email">{item.email}</Table.Cell>
                                        <Table.Cell className="td_phone">{item.phone}</Table.Cell>
                                        <Table.Cell>{item.organization}</Table.Cell>
                                        <Table.Cell className="td_location">{item.locations}</Table.Cell>
                                        <Table.Cell>{item.permission}</Table.Cell>
                                        <Table.Cell>{item.individual_type}</Table.Cell>
                                        <Table.Cell>{item.invitestatus}</Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                            }
                        </Table>
                        {var_ready && var_individuals[0]['totalrows'] === 0 &&
                        <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t("alert icon")}/>{t("there are no individuals to display")}</div>
                        }
                    </div>
                    <div className="content-filter">
                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                var_offset={var_offset} var_limit={var_limit} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_individuals && var_individuals.length > 0 ? var_individuals[0]['totalrows'] : 0} populatefunction={populate_individuals}
                            />
                        </div>
                    </div>
                </div>



                {/***** MODAL: INVITE INDIVIDUAL *********************************************************************/}

                <Modal id="mdl_org_individuals_inviteindividual"
                       dimmer={'inverted'}
                       open={var_mdl_inviteindividual_open}
                       onClose={() => set_mdl_inviteindividual_open(false)}>
                    <MDL_INVITEINDIVIDUAL
                        set_mdl_inviteindividual_open={set_mdl_inviteindividual_open}
                        populate_individuals={populate_individuals}
                    />
                </Modal>

                {/***** END MODAL: INVITE INDIVIDUAL *****************************************************************/}



                {/***** MODAL: BULK INVITE ***************************************************************************/}

                <Modal id="mdl_org_individuals_bulkinvite"
                       dimmer={'inverted'}
                       open={var_mdl_bulkinvite_open}
                       onClose={() => set_mdl_bulkinvite_open(false)}>
                    <MDL_BULKINVITE
                        set_mdl_open={set_mdl_bulkinvite_open}
                        populate_function={populate_individuals}
                    />
                </Modal>

                {/***** END MODAL: BULK INVITE ***********************************************************************/}



                {/***** MODAL: INDIVIDUAL ****************************************************************************/}

                <Modal id="mdl_org_individuals_indv"
                       dimmer={'inverted'}
                       open={var_mdl_indv_open}
                       onClose={() => set_mdl_indv_open(false)}>
                    <MDL_INDV
                        set_mdl_open={set_mdl_indv_open}
                        var_individual_id={var_target_id}
                        populate_function={populate_individuals}>
                    </MDL_INDV>
                </Modal>

                {/***** END MODAL: INDIVIDUAL ************************************************************************/}

            </div>
        </>
    )

}

import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from 'semantic-ui-react'
import Moment from "moment";
import datelib from '../../../../libs/date-lib';

import { useTranslation } from 'react-i18next';
import '../../../../i18n';

import './crd_active_forms.css';
import CMP_LEGEND from "../../../../components/cmp_legend/cmp_forms_legend/cmp_forms_legend"
import CMP_TABLECONTROLS, {filter_helper} from "../../../../components/cmp_tablecontrols/cmp_tablecontrols";
import MDL_DOCVIEWER from "../../../../components/cmp_docviewer/cmp_docviewer";
import MDL_UPDATE from "./mdl_update/mdl_update";



export default function({var_activetab}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();
    const [ var_forms, set_forms ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(10);
    const [ var_offset, set_offset] = useState(0);
    const [ var_sortby, set_sortby ] = useState('assignedepoch');
    const [ var_sortorder, set_sortorder ] = useState('descending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));
    const [ var_closed, set_closed ] = useState(false);

    const [ var_mdl_docviewer_open, set_mdl_docviewer_open ] = useState(false);
    const [ var_docviewerid, set_docviewerid ] = useState(null);
    const [ var_docviewer_review, set_docviewer_review ] = useState(false);
    const [ var_mdl_update_open, set_mdl_update_open ] = useState(false);
    const [ var_form_to_update ,set_form_to_update] = useState(null);
    const [ var_form_location_id ,set_form_location_id] = useState(null);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_activetab === 'ACTIVEFORMS') {
            set_filter(filter_helper.initialize(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_activetab]);

    useEffect(()=>{
        if(var_filter.load){
            populate_forms(null,0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_filter]);

    useEffect(()=>{
        if(var_closed !== undefined) {
            set_filter(filter_helper.initialize(false));
        }
    },[var_closed]);

    useEffect(()=>{
        if (!var_mdl_update_open) {
            populate_forms();
        }
    },[var_mdl_update_open]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_forms(limit,offset,sortby,sortorder,closed,filter){
        if (var_activetab !== 'ACTIVEFORMS') return; // Don't load data if tab is not visible

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        filter = filter ? filter : var_filter;

        if(closed){
            set_closed(closed);
        } else {
            closed = var_closed;
        }

        try {
            let results = await API_get_loc_activeforms(limit,offset,sortby,sortorder,closed,filter);
            set_forms(results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername,sortorder){
        try {
            return await API_get_loc_activeforms(filter_helper.maxFilterItems,0,filtername,sortorder,var_closed,[],filtername);
        } catch (e) {
            console.log(e);
        }
    }

    async function download_forms(limit,offset){
        try {
            return await API_get_loc_activeforms(limit,offset,var_sortby,var_sortorder,var_closed,var_filter);
        } catch (e) {
            console.log(e);
            throw e;
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_loc_activeforms(limit,offset,sortby,sortorder,closed,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-forms',
            '/get-org-activeforms/'+JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id,
            {
                queryStringParameters: {
                    limit : limit,
                    offset : offset,
                    sortby : sortby,
                    sortorder: sortorder,
                    tz : datelib.timezone,
                    closed: closed,
                    filtername : filtername
                },
                body : filter
            }
        );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_forms('',0,sortby,sortorder)
    }

    function onClick_paperclip(event, id, review = false) {
        event.stopPropagation();
        set_docviewerid(id);
        set_docviewer_review(review);
        set_mdl_docviewer_open(true);
    }

    function open_mdl_update(item){
        set_form_location_id(item.location_id);
        set_form_to_update(item);
        set_mdl_update_open(true);
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="content-card" id="crd_org_active_forms">
                <div className="content-card-header">
                    {var_closed ?
                        <div className="content-card-header-title">{t('All Closed Forms')}</div>
                        :
                        <div className="content-card-header-title">{t('All Active Forms')}</div>
                    }
                </div>
                <div className="content-card-gridcontent">
                    <div className="content-filter">
                        <div className="content-filter-item content-filter-archive">
                            {var_closed ?
                                <Button className="btn_tertiary" onClick={() => set_closed(!var_closed)}><img className="btn_icon" src={"/icons/form 60px (29719a).svg?ts=" + Date.now()} alt={t("form icon")}/>{t("VIEW ACTIVE FORMS")}</Button>
                                :
                                <Button className="btn_tertiary" onClick={() => set_closed(!var_closed)}><img className="btn_icon" src={"/icons/form 60px (29719a).svg?ts=" + Date.now()} alt={t("form icon")}/>{t("VIEW CLOSED FORMS")}</Button>
                            }
                        </div>
                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_forms && var_forms.length > 0 ? var_forms[0]['totalrows'] : 0}  populatefunction={populate_forms}
                                downloadname='All Active Forms' downloadfunction={download_forms}
                                var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                                table_config={[
                                    {name: 'formname', datatype: 'text', labelKey: 'FORM', download: true, filter: true},
                                    {name: 'location_name', datatype: 'text', labelKey: 'LOC/PROJ', download: true, filter: true},
                                    {name: 'jobnumber', datatype: 'text', labelKey: 'JOB NUMBER', download: true, filter: true},
                                    {name: 'assignees', datatype: 'text', labelKey: 'ASSIGNED TO', download: true, filter: true},
                                    {name: 'organization', datatype: 'text', labelKey: 'ORGANIZATION', download: true, filter: true},
                                    {name: 'assignedepoch', datatype: 'date', labelKey: 'ASSIGNED', download: true, filter: true},
                                    {name: 'status', datatype: 'text', labelKey: 'STATE', download: true, filter: true}
                                ]}
                            />
                        </div>
                    </div>
                    <div className="tablewrapper">
                        <Table sortable compact className={(var_loading ? " tbl_loading" : "")}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell sorted={var_sortby === 'formname' ? var_sortorder : null} onClick={() => onClick_sort('formname')}>{t("FORM")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'location_name' ? var_sortorder : null} onClick={() => onClick_sort('location_name')}>{t("LOC/PROJ")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'jobnumber' ? var_sortorder : null} onClick={() => onClick_sort('jobnumber')}>{t("JOB NUMBER")}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_assignedto" sorted={var_sortby === 'assignees' ? var_sortorder : null} onClick={() => onClick_sort('assignees')}>{t("ASSIGNED TO")}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_organization" sorted={var_sortby === 'organization' ? var_sortorder : null} onClick={() => onClick_sort('organization')}>{t("ORGANIZATION")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'assignedepoch' ? var_sortorder : null} onClick={() => onClick_sort('assignedepoch')}>{t("ASSIGNED")}</Table.HeaderCell>
                                    {var_closed &&
                                    <Table.HeaderCell className="td_paperclip" sorted={var_sortby === 'PDF' ? var_sortorder : null} onClick={() => onClick_sort('PDF')}><img src={"/icons/paperclip 30px (c9caca).svg?ts=" + Date.now()} alt={t("attachment icon")}/></Table.HeaderCell>
                                    }
                                    <Table.HeaderCell sorted={var_sortby === 'status' ? var_sortorder : null} onClick={() => onClick_sort('status')}>{t("STATE")}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {var_ready && var_forms[0]['totalrows'] !== 0 &&
                            <Table.Body>
                                {var_forms.map((item, i) =>
                                    <Table.Row key={i} onClick={() => open_mdl_update(item)}>
                                        <Table.Cell className="td_overflow">{item.formname}</Table.Cell>
                                        <Table.Cell>{item.location_name}</Table.Cell>
                                        <Table.Cell>{item.jobnumber}</Table.Cell>
                                        <Table.Cell className="td_overflow td_assignedto">{item.assignees}</Table.Cell>
                                        <Table.Cell className="td_overflow td_organization">{item.organization}</Table.Cell>
                                        <Table.Cell className="td_overflow" >{datelib.date_localized_format(item.assigned)}</Table.Cell>
                                        {var_closed &&
                                        <Table.Cell className="td_paperclip">
                                            {(item.status === 'CLOSED' && item.PDF !== null && item.PDF !== '') ?
                                                <div className='td_attachment' onClick={(event) => onClick_paperclip(event, item.id)}> <img src={"/icons/paperclip 60px (717473).svg?ts=" + Date.now()} alt={t("attachment icon")}/></div>
                                                :
                                                <div className='td_attachment'></div>
                                            }
                                        </Table.Cell>
                                        }
                                        <Table.Cell className="td_overflow">{item.status}</Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                            }
                        </Table>
                        {var_ready && var_forms[0]['totalrows'] === 0 &&
                        <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt="alert icon"/>{t("there are no forms to display")}</div>
                        }
                    </div>
                    <div className="content-filter">
                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_forms && var_forms.length > 0 ? var_forms[0]['totalrows'] : 0}  populatefunction={populate_forms}
                            />
                        </div>
                    </div>
                    <div className="legend_footer">
                        <CMP_LEGEND
                            var_indv_view={false}
                        />
                    </div>
                </div>

                {/***** MODAL: DOCUMENT VIEW *****************************************************************************/}

                <Modal id="mdl_docviewer"
                       dimmer={'inverted'}
                       open={var_mdl_docviewer_open}
                       onClose={() => set_mdl_docviewer_open(false)}>
                    <MDL_DOCVIEWER
                        set_mdl_open={set_mdl_docviewer_open}
                        targetid={var_docviewerid}
                        var_review={var_docviewer_review}
                        onComplete_review={populate_forms}
                        classification='form'
                    />
                </Modal>

                {/***** END MODAL: DOCUMENT VIEW *********************************************************************/}

                {/***** MODAL: UPDATE ************************************************************************************/}

                <Modal id="org_mdl_updateform"
                    dimmer={'inverted'}
                    open={var_mdl_update_open}
                    onClose={() => set_mdl_update_open(false)}>
                    <MDL_UPDATE
                        set_mdl_open={set_mdl_update_open}
                        var_location_id={var_form_location_id}
                        onClick_paperclip={onClick_paperclip}
                        form={var_form_to_update}>
                    </MDL_UPDATE>
                </Modal>

                {/***** END MODAL: UPDATE ********************************************************************************/}
            </div>
        </>
    )
}

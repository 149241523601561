import React from "react";
import { useTranslation } from 'react-i18next';
import ReactPDF, {Text,StyleSheet, View, Image, Link} from '@react-pdf/renderer';


export default function({var_credentials, var_indv, var_linkid}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

	const styles = StyleSheet.create({
		section: {
			paddingHorizontal: 15,
			marginTop: 15,
		},
		sectionTitle: {
			fontSize: 12,
			fontWeight: 700,
			paddingBottom: 5,
		},
		table: {
			borderStyle: 'solid',
			borderColor: '#ebebeb',
			borderWidth: 1,
		},
		tableHeaderRow: {
			flexDirection: "row",
		},
		tableRow: {
			flexDirection: "row",
			borderTopWidth: 1,
			borderTopStyle: 'solid',
			borderTopColor: '#ebebeb'
		},
		tableHeaderCol: {
			width: '10%',
			color: '#cfd3d5',
		},
		tableRowCol: {
			width: '10%',
		},
		tableHeaderCellText: {
			padding: 6,
			fontSize: 9,
			fontWeight: 800,
		},
		tableCellText: {
			padding: 6,
			fontSize: 9,
		},
		Icon: {
			width: '12px',
			display: 'inline-block'
		},
		alert: {
			paddingHorizontal: '6px',
			paddingVertical: '6px',
			color: '#cdcecd',
			fontSize: 9,
			flexDirection: "row",
			alignItems: 'center',
			justifyContent: 'center',
			borderTopWidth: 1,
			borderTopStyle: 'solid',
			borderTopColor: '#ebebeb'
		},
		alertIcon: {
			width: '12px',
			marginRight: '4px'
		},
	});

    // RENDER APP ======================================================================================================
    return(

        <View style={styles.section}>

			<Text style={styles.sectionTitle}>{t('Credentials')}</Text>

			<View style={styles.table}>

				<View style={styles.tableHeaderRow} wrap={false}>
					<View style={[styles.tableHeaderCol, {width: '9%'}]}>
						<Text style={styles.tableHeaderCellText}>{t('STATUS')}</Text>
					</View>
					<View style={[styles.tableHeaderCol, {width: '25%'}]}>
						<Text style={styles.tableHeaderCellText}>{t('CREDENTIALS')}</Text>
					</View>
					<View style={[styles.tableHeaderCol, {width: '16%'}]}>
						<Text style={styles.tableHeaderCellText}>{t('ISSUED BY')}</Text>
					</View>
					<View style={[styles.tableHeaderCol, {width: '13%'}]}>
						<Text style={styles.tableHeaderCellText}>{t('ISSUED')}</Text>
					</View>
					<View style={[styles.tableHeaderCol, {width: '13%'}]}>
						<Text style={styles.tableHeaderCellText}>{t('EXPIRES')}</Text>
					</View>
					<View style={[styles.tableHeaderCol, {width: '11%'}]}>
						<Text style={styles.tableHeaderCellText}>{t('DAYS LEFT')}</Text>
					</View>
					<View style={[styles.tableHeaderCol, {width: '13%'}]}>
						<Text style={styles.tableHeaderCellText}>{t('VERIFICATION')}</Text>
					</View>
				</View>

				{var_credentials && var_credentials[0]['totalrows'] !== 0 && 
				<View>
					{var_credentials.map((item, i) =>
						<View key={i} style={styles.tableRow} wrap={false}>
							<View style={[styles.tableRowCol, {width: '9%'}]}>
								<View style={[styles.tableCellText, {marginHorizontal: 'auto'}]}>
									{item.verification_status === 'VALID' &&
										<Image src="/pdf/checkmark 30px (69b536).png" style={styles.Icon} />
									}
									{item.verification_status === 'INVALID' &&
										<Image src="/pdf/x 30px (e0454f).png" style={styles.Icon} />
									}
									{item.verification_status === 'PENDING' &&
										<Image src="/pdf/warning 30px (ffae00).png" style={styles.Icon} />
									}
									{(item.verification_status !== 'VALID' && item.verification_status !== 'INVALID' && item.verification_status !== 'PENDING') &&
										<Image src="/pdf/circle 30px (bcbebe).png" style={styles.Icon} />
									}
								</View>
							</View>
							<View style={[styles.tableRowCol, {width: '25%'}]}>
								<Text style={styles.tableCellText}>{item.credential}</Text>
							</View>
							<View style={[styles.tableRowCol, {width: '16%'}]}>
								<Text style={styles.tableCellText}>{item.issuedby}</Text>
							</View>
							<View style={[styles.tableRowCol, {width: '13%'}]}>
								<Text style={styles.tableCellText}>{item.issued}</Text>
							</View>
							<View style={[styles.tableRowCol, {width: '13%'}]}>
								<Text style={styles.tableCellText}>{item.expires}</Text>
							</View>
							<View style={[styles.tableRowCol, {width: '11%', textAlign: 'center'}]}>
								<Text style={styles.tableCellText}>{item.daysleft}</Text>
							</View>
							<View style={[styles.tableRowCol, {width: '13%'}]}>
								<View style={[styles.tableCellText, {flexDirection: 'row'}]}>
									{(item.confirmation_status === 'PASS' || item.confirmation_status === 'VERIFIED' || item.confirmation_status === 'CLEAR') && (item.confirmation_status !== 'PENDING' &&  item.confirmation_status !== null) &&
										<Link src={var_indv.org_host + "/public/certificate/" + item.id + '/' + var_linkid}>
											<Image src="/pdf/credential 30px (69b536).png" style={[styles.Icon, {marginRight: '2px'}]} />
										</Link>
									}
									{(item.confirmation_status === 'REJECTED' || item.confirmation_status === 'SUSPENDED' || item.confirmation_status === 'REVOKED' || item.confirmation_status === 'EXPIRED' || item.confirmation_status === 'WARNING') && (item.confirmation_status !== 'MISSING' && item.confirmation_status !== 'PENDING' &&  item.confirmation_status !== null)&&
										<Link src={var_indv.org_host + "/public/certificate/" + item.id + '/' + var_linkid}>
											<Image src="/pdf/credential 30px (e0454f).png" style={[styles.Icon, {marginRight: '2px'}]} />
										</Link>
									}
									{(item.confirmation_status === 'MISSING' && item.confirmation_status !== 'PENDING' &&  item.confirmation_status !== null) &&
										<Image src="/pdf/credential 30px (e0454f).png" style={[styles.Icon, {marginRight: '2px'}]} />
									}
									{(item.confirmation_status === 'PENDING' ||  item.confirmation_status === null) &&
										<Image src="/pdf/credential 30px (ffae00).png" style={[styles.Icon, {marginRight: '2px'}]} />
									}

									{(item.confirmation_status !== 'MISSING' && item.confirmation_status !== 'PENDING' &&  item.confirmation_status !== null) &&
										<Link src={var_indv.org_host + "/public/certificate/" + item.id + '/' + var_linkid}>
											<Text>{item.confirmation_status}</Text>
										</Link>
									}
									{(item.confirmation_status === 'MISSING' || item.confirmation_status === 'PENDING' || item.confirmation_status === null) &&
										<Text>{item.confirmation_status}</Text>
									}
								</View>
							</View>
						</View>
					)}
				</View>
				}

				{var_credentials[0]['totalrows'] === 0 && 
					<View style={styles.alert} wrap={false}>
						<Image src="/pdf/alert 60px (e5e5e5).png" style={styles.alertIcon} />
						<Text>{t('there are no credentials to display')}</Text>
					</View>
				}

			</View>
        </View>

    )

}

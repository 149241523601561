import React from "react";
import './sta_credentials.css';

import { useTranslation } from 'react-i18next';
import '../../../../i18n';



export default function({ var_credentialSummary }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    //  event listeners ------------------------------------------------------------------------------------------------

    //  async functions ------------------------------------------------------------------------------------------------

    //  API calls ------------------------------------------------------------------------------------------------------

    //  event functions ------------------------------------------------------------------------------------------------


    // RENDER APP ======================================================================================================

    return (
        <div id="credentialsummary" className="summary_wrapper">
            <div className="summary_icon">
                <img src={"/icons/credential 60px (717473).svg?ts=" + Date.now()} alt={t('credential icon')}/>
            </div>
            <div className="summary_item">
                <div className="summary_item_number green">{var_credentialSummary === undefined? '' : (var_credentialSummary.valid || 0)}</div>
                <div className="summary_item_text">{t('VALID')}</div>
                <div className="summary_item_text">{t('CREDENTIALS')}</div>
            </div>
            <div className="summary_item">
                <div className="summary_item_number green">{var_credentialSummary === undefined? '' : (var_credentialSummary.comingdue || 0)}</div>
                <div className="summary_item_text">{t('CREDENTIALS')}</div>
                <div className="summary_item_text">{t('COMING DUE')}</div>
            </div>
            <div className="summary_item">
                <div className="summary_item_number red">{var_credentialSummary === undefined? '' : (var_credentialSummary.invalid || 0)}</div>
                <div className="summary_item_text">{t('INVALID')}</div>
                <div className="summary_item_text">{t("CREDENTIALS")}</div>
            </div>
        </div>
    )

}
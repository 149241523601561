import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    Text,
    StyleSheet,
    View,
    Image,
} from '@react-pdf/renderer';


export default function({detail, var_org, var_qrCode}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

	const styles = StyleSheet.create({
		headerContainer: {
			flexDirection: 'row',
			alignItems: 'center',
			paddingBottom: 20,
			paddingHorizontal: 20,
		},
		headerLeft: {
			flex: 1,
		},
		headerRight: {},
		headerOrgInfo: {
			flexDirection: 'row',
			alignItems: 'center',
			marginBottom: 10
		},
		headerLogoImg: {
			maxWidth: 120,
			maxHeight: 100,
			width: 100,
			marginRight: 25
		},
		headerTitle: {
			fontSize: 24,
			fontWeight: 500,
		},
		headerInfo: {
			flexDirection: 'row',
			flexWrap: 'wrap',
		},
		headerInfoItem: {
			marginRight: 10,
			paddingTop: 4,
		},
		headerInfoItemLabel: {
			color: '#cfd3d5',
			fontWeight: 500,
			fontSize: 8,
			textTransform: 'capitalize'
		},
		headerInfoItemText: {
			fontSize: 9,
			paddingTop: 2
		},
		image: {
			width: 90,
			height: 90,
		},
	});

    // RENDER APP ======================================================================================================
    return(
		<View style={styles.headerContainer}>
			<View style={styles.headerLeft}>
			<View style={styles.headerOrgInfo}>
					{var_org && var_org.org_logo && var_org.org_logo != null && var_org.org_logo !== '' &&
					<View style={styles.headerLogoImg} >
						<Image style={styles.headerLogoImg} src={ var_org.org_logo + '?noCache=' + Math.random().toString()} source={{ header: {'Access-Control-Allow-Origin': '*'}}}  />
					</View>
					}
					<View style={styles.headerTitle}>
						<Text>{var_org===undefined? '' :(var_org.name===null? '' : var_org.name)}</Text>
					</View>
				</View>
				<View style={styles.headerTitle}>
					<Text>{detail.location_name}</Text>
				</View>
				<View style={styles.headerInfo}>
					<View style={styles.headerInfoItem}>
						<View style={styles.headerInfoItemLabel}>
							<Text>{t('CODE')}</Text>
						</View>
						<View style={styles.headerInfoItemText}>
							<Text>{detail.code}</Text>
						</View>
					</View>
					<View style={styles.headerInfoItem}>
						<View style={styles.headerInfoItemLabel}>
							<Text>{t('CITY')}</Text>
						</View>
						<View style={styles.headerInfoItemText}>
							<Text>{detail.city}</Text>
						</View>
					</View>
					<View style={styles.headerInfoItem}>
						<View style={styles.headerInfoItemLabel}>
							<Text>{t('PROVINCE')}</Text>
						</View>
						<View style={styles.headerInfoItemText}>
							<Text>{detail.province}</Text>
						</View>
					</View>
					<View style={styles.headerInfoItem}>
						<View style={styles.headerInfoItemLabel}>
							<Text>{t('COUNTRY')}</Text>
						</View>
						<View style={styles.headerInfoItemText}>
							<Text>{detail.country}</Text>
						</View>
					</View>
				</View>
			</View>
			{var_qrCode != null &&
				<View style={styles.headerRight}>
					<Image src={ {uri: var_qrCode} } style={styles.image} />
				</View>
			}
		</View>
    )

}
import { Button, Form } from 'semantic-ui-react';
import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../../../../../i18n';

import './mdl_add.css';



export default function({set_mdl_open, populate_function, var_location_id}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_individual, set_individual] = useState([]);
    const [ var_selectedIndv, set_selectedIndv] = useState([]);
    const [ var_ready, set_ready] = useState(false);
    const [ var_processing, set_processing] = useState(false);

    const [ var_dd_availableindividuals, set_dd_availableindividuals ] = useState([]);


    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_dd_availableindividuals();
        const individual = { ...var_individual };
        individual['location_id'] = var_location_id;
        individual['organization_id'] = JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id;
        individual['transactionby'] = JSON.parse(sessionStorage.getItem('authentication')).id;
        individual['transactionorg'] = JSON.parse(sessionStorage.getItem('authentication')).organization_id;
        set_individual(individual);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_dd_availableindividuals(){
        try {
            let results = await API_get_dd_availableindividuals();
            set_dd_availableindividuals(results);
        } catch (e) {
            console.log(e);
        }
    }

    async function insert_individual(individual) {
        set_processing(true);
        try {
            await API_post_loc_individual(individual);

            let data = {};
            data['individual_id'] = individual.individual_id;
            data['location_id'] = var_location_id;
            data['organization_id'] = JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id;
            data['transactionby'] = individual.individual_id;
            data['transactionorg'] = JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id;

            if(JSON.parse(sessionStorage.getItem('authentication')).org_credentials === 'YES') {
                await API_post_locationcredentials(data);
            }

            set_ready(false);
            set_processing(false);
            set_mdl_open(false);
            populate_function();
        } catch (e) {
            console.log(e);
        }

    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_dd_availableindividuals(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-locations', '/get-dd-availableindividuals/'+var_location_id);
    }

    function API_post_loc_individual(individual){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-locations', '/post-loc-individual/', { body: individual });
    }

    function API_post_locationcredentials(data){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('indv-credentials', '/post-loc-requirements', { body: data});
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_status(e, { value }){
        const selectedIndv = { ...var_selectedIndv };
        selectedIndv['individual_id'] = value;
        set_selectedIndv(selectedIndv);
        set_ready(true);
    }

    function onClick_submit(){
        const individual = { ...var_individual };
        const selectedIndv = var_selectedIndv.individual_id;
        if(var_ready) {
            for (var i=0; i < selectedIndv.length; i++){
                individual['individual_id'] = selectedIndv[i];
                set_individual(individual);
                insert_individual(individual);
            }
        }
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{t("Add Individual")}</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
            </div>

            <div className="modal-content">
                <Form>
                    <Form.Group>
                        <Form.Select
                            className='individual_id'
                            name='individual_id'
                            label={t("INDIVIDUAL TO ADD")}
                            options={var_dd_availableindividuals}
                            value={var_selectedIndv.individual_id || []}
                            onChange={onChange_status}
                            placeholder={t("Select an individual...")}
                            fluid
                            multiple
                            selection
                            search
                        />
                    </Form.Group>
                </Form>
            </div>

            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>{t("CANCEL")}</Button>
                    {!var_processing ?
                        <Button className={"btn_primary " + (var_ready ? ' btn_active' : '')} onClick={() => onClick_submit()}>{t("ADD INDIVIDUAL")}</Button>
                        :
                        <Button loading className="btn_primary btn_active">{t("ADD INDIVIDUAL")}</Button>
                    }
                </div>
            </div>
        </>
    )
}
import React from "react";

import { useTranslation } from 'react-i18next';
import '../../../../../../i18n';

import './tab_details.css';
import { Button } from "semantic-ui-react";

export default function({ form, onClick_paperclip }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();


    //  event listeners ------------------------------------------------------------------------------------------------



    //  async functions ------------------------------------------------------------------------------------------------




    //  API calls ------------------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_openform({id, status, formurl, editurl, location_name, location_code}){
        if(status === 'NEW') {
            window.open(formurl + '?entry=' + encodeURIComponent(JSON.stringify({'Site': location_name,'UID': id, 'Number': location_code})));
        } else if (status === 'IN-PROGRESS') {
            window.open(editurl);
        }
    }

    // RENDER APP ======================================================================================================

    return (
        <div className="modals-tab">
            <div className="tab_details">
                <div className="detail-item" onClick={(event) => { (form.PDF !== null && form.PDF !== '' && (form.status === 'CLOSED' || form.status === 'IN-REVIEW')) ? onClick_paperclip(event, form.id, form.status === 'IN-REVIEW') : onClick_openform(form)}}>
                    <div className="detail-item-button">
                        <img src={"/icons/form 60px (29719a).svg?ts=" + Date.now()} alt={t('forms icon')} />
                        <Button className="btn_tertiary">{(form.PDF !== null && form.PDF !== '' && (form.status === 'CLOSED' || form.status === 'IN-REVIEW')) ?  t("OPEN ATTACHMENT") : t("OPEN FORM")}</Button>
                    </div>
                    <div className="detail-item-text">{form.formname}</div>
                </div>
            </div>
        </div>
    )
}
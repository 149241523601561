import { API, Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import Moment from "moment";
import datelib from './libs/date-lib';
import Routes from "./routes";
import { useTranslation } from 'react-i18next';
import "./i18n";
import auth from './libs/auth-lib';

import 'semantic-ui-css/semantic.min.css'
import './App.css';
import config from './config';



function App() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [var_systemstatus, set_systemstatus ] = useState([]);
    const [var_time, set_time] = useState(Moment().format('LT'));
    const [var_date, set_date] = useState(Moment().format('ll'));

    const [var_visibleframe, set_visibleframe] = useState(false);
    const [var_menu, set_menu] = useState([]);
    const [var_visiblemenu, set_visiblemenu] = useState('INDIVIDUAL');
    const [var_activemenu, set_activemenu] = useState('');

    // eslint-disable-next-line no-unused-vars
    const [var_orgname, set_orgname] = useState('');

    const [ var_indv_document_stat, set_indv_document_stat ] = useState(null);
    const [ var_indv_form_stat, set_indv_form_stat ] = useState(null);
    const [ var_networks_stat, set_networks_stat ] = useState(null);
    const [ var_documentsrefresh, set_documentsrefresh ] = useState(false);
    const [ var_formsrefresh, set_formsrefresh ] = useState(false);
    const [ var_networksrefresh, set_networksrefresh ] = useState(false);
    const [ var_indvcredentialsrefresh, set_indvcredentialsrefresh ] = useState(false);
    const [ var_formsinit, set_formsinit ] = useState(false);
    const [ var_documentsinit, set_documentsinit ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (window.location.host === 'cwallet.mintzscreeningservices.com' || window.location.host === 'cwallettest.mintzscreeningservices.com') {
            document.title = "Mintz";
        }

        const var_interval = setInterval(() => {
            set_time(Moment().format('LT'));
            set_date(Moment().format('ll'));
        }, 60000);
        return () => clearInterval(var_interval);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (window.location.pathname.indexOf('saml') >= 0) {
            //  do nothing (SAML handles authentication)
        } else {
            if (!auth.is_loggedin) {
                get_systemstatus();
            } else {
                authenticatepageload();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname]);

    useEffect(() => {
        if (auth.is_loggedin) {
            if (!var_formsinit && auth.indv_forms) {
                populate_indv_forms_stat();
                set_formsinit(true);
            }
            if (!var_documentsinit && auth.indv_documents) {
                populate_indv_documents_stat();
                set_documentsinit(true);
            }
            populate_network_pending_count();
            const var_interval = setInterval(() => {
                if (Math.floor(Date.now() / 1000) - localStorage.getItem('activetime') > 1800) {
                    handleLogout();
                }
                execute_refreshinterval();
            }, 60000);
            return () => clearInterval(var_interval);
        } else {
            const browser_language = window.navigator.language.split('-')[0];
            if (browser_language === "en" || browser_language === "fr") {
                i18n.changeLanguage(config.language[browser_language].i18n);
                set_appDefaultLanguage(browser_language);
            } else {
                i18n.changeLanguage(config.language.en.i18n);
                set_appDefaultLanguage("en");
            }
            // Clear state variables if necessary
            var_menu.length > 0 && set_menu([]);
            var_indv_document_stat && set_indv_document_stat(null);
            var_indv_form_stat && set_indv_form_stat(null);
            set_networks_stat(null);
            var_formsinit && set_formsinit(false);
            var_documentsinit && set_documentsinit(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionStorage.getItem('authentication')]);

    useEffect(()=>{
        if(var_documentsrefresh){
            populate_indv_documents_stat();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_documentsrefresh]);

    useEffect(()=>{
        if(var_formsrefresh){
            populate_indv_forms_stat();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_formsrefresh]);

    useEffect(()=>{
        if (var_networksrefresh) {
            populate_network_pending_count();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_networksrefresh]);

    useEffect(()=>{
        if (var_visiblemenu === 'INDIVIDUAL' && auth.is_loggedin && auth.indv_forms) {
            populate_indv_forms_stat();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_visiblemenu])


    //  async functions ------------------------------------------------------------------------------------------------

    async function authenticatepageload() {
        try {

            //  check system status
            let status = await get_systemstatus();
            if (status.length > 0 && status[0].systemstatus === 'OFFLINE') {
                await handleLogout();
            } else {

                //  check cognito authentication
                let AWSuser = await Auth.currentUserInfo();

                //  get TerraHub user details
                let result = await API_get_authentication();

                //check if account has been archived
                if (result === 'NO ACTIVE ACCOUNT') {
                    alert('NO ACTIVE ACCOUNT');
                }

                // Get stored username
                let storedUserName = null;
                if (sessionStorage.getItem('username') !== null) {
                    storedUserName = sessionStorage.getItem('username');
                }

                let authentication = await auth.parse_authentication_result(result);
                authentication['authenticated'] = true;
                authentication['cognito_id'] = storedUserName ? storedUserName : AWSuser.username;

                set_menu(result.menu);

                let permission = (result.organization_id && result.organization_id !== '') ? await API_get_auth_permission(result.organization_id) : {admin: false};
                if (!permission.admin) {
                    set_orgname('');
                } else {
                    set_orgname(result.organization);
                }

                let default_language = "en";
                if (authentication.language !== undefined && authentication.language !== null && authentication.language !== "" && (authentication.language === "en" || authentication.language === "fr")) {
                    default_language = authentication.language;
                    authentication.language = config.language[authentication.language];
                } else {
                    const browser_language = window.navigator.language.split('-')[0];
                    if (browser_language === "en" || browser_language === "fr") {
                        default_language = browser_language;
                        authentication.language = config.language[browser_language];
                    } else {
                        authentication.language = config.language.en;
                    }
                    if (authentication.id) {
                        await API_put_indv(authentication.id, default_language);
                    }
                }
                set_appDefaultLanguage(default_language);
                sessionStorage.setItem('authentication', JSON.stringify(authentication));
                auth.clear();

            }

        } catch (e) {
            if (e !== 'No current user') {
                alert(e);
            }
        }
    }

    async function get_systemstatus(){
        try {
            let results = await API_get_systemstatus();
            set_systemstatus(results[0]);
            return(results);
        } catch (e) {
            console.log(e);
        }
    }

    async function execute_refreshinterval() {
        let networksrefresh_time = localStorage.getItem('networksrefresh_time');
        if (networksrefresh_time === null || Math.floor(Date.now() / 1000) - networksrefresh_time > 300) {
            set_networksrefresh(true);
        }
    }

    async function populate_indv_documents_stat(){
        try {
            let results = await API_get_indv_documents_stat();
            set_indv_document_stat(results[0]);
        } catch (e) {
            console.log(e);
        }
    }

    async function populate_indv_forms_stat(){
        try {
            let results = await API_get_indv_forms_stat();
            set_indv_form_stat(results[0]);
        } catch (e) {
            console.log(e);
        }
    }

    async function populate_network_pending_count() {
        try {
            set_networksrefresh(false);
            if (auth.is_loggedin && auth.org_networks && auth.has_access('ORG-NETWORK', 'read')) {
                let results = await API_get_org_network_pending_count();
                set_networks_stat(results === 0 ? null : results);
            }
        } catch (e) {
            console.log(e);
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_authentication() {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.get('admin', '/get-authentication')
            .then(response => response)
            .catch(error => {
                console.log(error.response);
                return {'id': null, 'menu': []} // on error, return a sane object with empty values
            });
    }

    function API_get_systemstatus() {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.get('admin', '/get-systemstatus?tz='+datelib.querystring_timezone);
    }

    function API_put_indv(individual_id, default_language) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.put('indv', '/put-indv/' + individual_id, { body: { default_language } });

    }

    function API_get_auth_permission(organization_id) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.get('admin', '/get-auth-permission/' + organization_id);
    }

    function API_get_indv_documents_stat(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('indv-documents', '/get-indv-documents-stat/' + auth.id)
            .then(response => response)
            .catch(error => {
                console.log(error.response);
                return [{"assigned":0}];  // on error, return a sane result
            });
    }

    function API_get_indv_forms_stat(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('indv-forms', '/get-indv-forms-stat/' + auth.id + '?tz='+datelib.querystring_timezone);
    }

    function API_get_org_network_pending_count() {
        localStorage.setItem('networksrefresh_time',Math.floor(Date.now() / 1000));
        return API.get('org', '/get-org-network-pending-count/'+auth.organization_id);
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_topmenu(varTopMenu) {
        let menuitem = var_menu.find(item => item.topmenu_code === varTopMenu).groupings[0].items[0];
        onClick_sidemenu(menuitem.route);
        set_activemenu(menuitem.route);
    }

    function onClick_sidemenu(varSideMenu) {
        if (varSideMenu.substring(0, 4) === 'http') {
            window.open(varSideMenu, "_blank") || window.location.replace(varSideMenu);
        } else {
            set_activemenu(varSideMenu);
            history.push(varSideMenu)
        }
    }

    function set_appDefaultLanguage(default_language) {
        if (default_language === "en" || default_language === "fr") {
            if (default_language === "fr") {
                const locales = require('moment/locale/' + config.language[default_language].moment);
                Moment.updateLocale(config.language[default_language].moment, locales);
            } else {
                Moment.locale(config.language[default_language].moment);
            }
            set_time(Moment().format('LT'));
            set_date(Moment().format('ll'));
            i18n.changeLanguage(config.language[default_language].i18n);
        }
    }

    async function handleLogout() {
        await auth.logout(history);
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            {var_systemstatus && var_systemstatus.systemstatus === 'OFFLINE' ?
                <div className="login_wrapper">
                    <div className="crd_systemalert">
                        <div className='crd_systemalert_text'>{var_systemstatus.outagemessage}</div>
                        <div className='crd_systemalert_timeframe'>{var_systemstatus.outagewindow}</div>
                    </div>
                </div>
                :
                <>
                {var_systemstatus && var_systemstatus.systemstatus === 'PENDING_OFFLINE' && var_systemstatus.preoutagemessage !== '' && var_systemstatus.preoutagemessage != null &&
                    <div className='systemalert'>{var_systemstatus.preoutagemessage} {var_systemstatus.outagewindow}</div>
                }

                {auth.is_loggedin ?
                    <>
                        {var_visibleframe ?
                            <div className="app-container">
                                <div className="app-topmenu">
                                    <div className="app-topmenu-logo"><img
                                        src={JSON.parse(sessionStorage.getItem('authentication')).logo ? JSON.parse(sessionStorage.getItem('authentication')).logo : '/assets/logo.png'}
                                        alt={t('Organization logo')} />
                                    </div>
                                    <div className="app-topmenu-menu">
                                        {var_menu.map(item => (
                                            <div key={'topmenu_'+item.topmenu_code}
                                                className={'app-topmenu-menuitem' + (var_visiblemenu === item.topmenu_code ? ' app-topmenu-menuitem-active' : '')}
                                                onClick={() => onClick_topmenu(item.topmenu_code)}>
                                                {item.translate ? t(item.topmenu_label) : item.topmenu_label}
                                            </div>
                                        ))}
                                    </div>

                                    <div className="app-topmenu-UTC">
                                        <div className="app-topmenu-UTC-icon"><img src={"/icons/clock 60px (8a8d8c).svg?ts=" + Date.now()} alt={t('clock icon')} /></div>
                                        <div className="app-topmenu-UTC-time">{var_time}</div>
                                        <div className="app-topmenu-UTC-date">{var_date}</div>
                                    </div>

                                    <div className="app-topmenu-loggedin">
                                        <div className="app-topmenu-loggedin-icon"><img src={"/icons/individual 60px (8a8d8c).svg?ts=" + Date.now()} alt={t('individual icon')} /></div>
                                        <div className="app-topmenu-loggedin-username">{JSON.parse(sessionStorage.getItem('authentication')).firstname} {JSON.parse(sessionStorage.getItem('authentication')).lastname}</div>
                                        <div className="app-topmenu-loggedin-logout" onClick={() => handleLogout()}>{t('Logout')} </div>
                                    </div>
                                </div>

                                <div className="app-content">
                                    <div className="app-sidenav">
                                        {render_sidenav()}
                                    </div>

                                    <div className="app-sidecontent">
                                        <Routes appProps={{ set_visibleframe, set_visiblemenu, set_activemenu, set_appDefaultLanguage, var_documentsrefresh, set_documentsrefresh, var_formsrefresh, set_formsrefresh, set_networksrefresh, var_indvcredentialsrefresh, set_indvcredentialsrefresh }} />
                                    </div>
                                </div>

                            </div>
                            :
                            <Routes appProps={{ set_visibleframe, set_visiblemenu, set_activemenu, set_appDefaultLanguage, var_documentsrefresh, set_documentsrefresh, var_formsrefresh, set_formsrefresh, set_networksrefresh, var_indvcredentialsrefresh, set_indvcredentialsrefresh }} />
                        }
                    </>
                    :
                    <Routes appProps={{ set_visibleframe, set_visiblemenu, set_activemenu, set_appDefaultLanguage, var_documentsrefresh, set_documentsrefresh, var_formsrefresh, set_formsrefresh, set_networksrefresh, var_indvcredentialsrefresh, set_indvcredentialsrefresh }} />
                }
                </>
            }
        </>
    );

    function render_sidenav(){
        if (var_menu.length === 0){
            return null;
        }
        if (var_visiblemenu === 'INDIVIDUAL'){
            return render_sidenavitems();
        }
        return (
            <div id='app-sidenav-group_organization'>
                {render_sidenavitems()}
            </div>
        )
    }

    function render_sidenavitems(){
        return var_menu.find(topitem => topitem.topmenu_code === var_visiblemenu).groupings.map(grouping => (
            <div key={'groupingmenu_'+grouping.grouping_order} className='app-sidenav-group'>
                <div className='app-sidenav-header'>{grouping.translate ? t(grouping.grouping_label) : grouping.grouping_label}</div>
                {grouping.items.map((item, index) => (
                        <div key={'itemmenu_'+grouping.grouping_order+'_'+index}
                             className={'app-sidenav-item '+(item.route === var_activemenu ? 'active' : '')}
                             onClick={() => onClick_sidemenu(item.route)}>
                            <div className={"app-sidenav-item_icon " + (item.route === var_activemenu ? 'active' : '')}><img src={item.icon} alt={t(item.icon_alt)} /></div>
                            <div className="app-sidenav-item_text">{t(item.item_label)}</div>
                            {(item.route === '/indv/documents' && var_indv_document_stat && var_indv_document_stat.notacknowledged > 0) && <div className="ui mini red circular label app-sidenav-item_label">{var_indv_document_stat.notacknowledged}</div>}
                            {(item.route === '/indv/forms' && var_indv_form_stat && var_indv_form_stat.assigned > 0) && <div className="ui mini red circular label app-sidenav-item_label">{var_indv_form_stat.assigned}</div>}
                            {(item.route === '/org/networks' && var_networks_stat) && <div className="ui mini red circular label app-sidenav-item_label">{var_networks_stat}</div>}
                        </div>
                ))}
                {grouping.grouping_label === 'MY WALLET' &&
                <div className="app-sidenav-item" id="sidenav_logout" onClick={() => handleLogout()}>
                    <div className="app-sidenav-item_icon"><img src={"/icons/power 60px (8a8d8c).svg?ts=" + Date.now()} alt={t('logout icon')} /></div>
                    <div className="app-sidenav-item_text">{t('Logout')}</div>
                </div>}
            </div>
        ));
    }
}

export default withRouter(App);

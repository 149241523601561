import React from "react";
import { Button } from 'semantic-ui-react'
import ReactJson from 'react-json-view'

import { useTranslation } from 'react-i18next';
import '../../i18n';

import datelib from "../../libs/date-lib";

import './cmp_verifiablepresentationviewer.css';

export default function({set_mdl_open, title, var_json, populate_function}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    //  variable listeners ---------------------------------------------------------------------------------------------

    //  async functions ------------------------------------------------------------------------------------------------

    //  API calls ------------------------------------------------------------------------------------------------------

    //  event functions ------------------------------------------------------------------------------------------------


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{title}</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src="https://public-resources-icons.s3.amazonaws.com/icons/x 60px (717473).svg" alt={t('x icon')} /></div>
            </div>

            <div className="modal-content">
                <div className="steps_panel">
                    <div className={'app-sidenav-item active'}>
                        <div className="section-title">
                            {title}
                        </div>
                        <div className="section-description">
                            {var_json.name}
                        </div>
                    </div>

                </div>
                <div className="credentialdetails">
                    <div className="detail-item detail-item-header">
                        <div className="digitalcertificate_detail_list">
                            <div className="digitalcertificate_detail_item">
                                <div className="vc_data_label detail_item_label">{t('METHOD')}:</div>
                                <div className="vc_data">
                                    <img className="verified_icon"
                                         src="https://public-resources-icons.s3.amazonaws.com/icons/checkmark 60px (549E17).svg"
                                         alt={t('verified')}/>
                                    <a target="_blank"
                                       href={"https://dev.uniresolver.io/#did=" + (var_json.proof.verificationMethod.substr(0, var_json.proof.verificationMethod.indexOf('#')))}>
                                        {var_json.proof.verificationMethod}
                                    </a>
                                </div>
                            </div>
                            <div className="digitalcertificate_detail_item">
                                <div className="vc_data_label detail_item_label">{t("ORIGIN")}:</div>
                                <div className="vc_data"><img className="verified_icon"
                                                              src="https://public-resources-icons.s3.amazonaws.com/icons/checkmark 60px (549E17).svg"
                                                              alt={t('verified')}/>
                                    {t('Credential is original')}</div>
                            </div>
                            <div className="digitalcertificate_detail_item">
                                <div className="vc_data_label detail_item_label">{t("ISSUED")}:</div>
                                <div className="vc_data"><img className="verified_icon"
                                                              src="https://public-resources-icons.s3.amazonaws.com/icons/checkmark 60px (549E17).svg"
                                                              alt={t('verified')}/>
                                    {datelib.datetime_localized_format(var_json.proof.created)}</div>
                            </div>
                            <div className="digitalcertificate_detail_item">
                                <div className="vc_data_label detail_item_label">{t("SIGNATURE")}:</div>
                                <div className="vc_data"><img className="verified_icon"
                                                              src="https://public-resources-icons.s3.amazonaws.com/icons/checkmark 60px (549E17).svg"
                                                              alt={t('verified')}/>
                                    {t('Signature is verified') +' (' + var_json.proof.type + ')'}</div>
                            </div>
                        </div>
                    </div>
                    <div className="detail-item json-item">
                        <ReactJson
                            name={false}
                            enableClipboard={false}
                            quotesOnKeys={false}
                            displayDataTypes={false}
                            collapsed={3}
                            src={var_json}
                        />
                    </div>
                </div>
            </div>
            <div className="modal-footer">


                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>{t('CLOSE')}</Button>
                </div>
            </div>

        </>
    )
}

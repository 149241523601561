//  OUTCOMES -----------------------------------------------------------------------------------------------------------
//
//  - handles adding an individual's credential to a requirement's pre-approved list 
//
//  --------------------------------------------------------------------------------------------------------------------

import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { Button, Table, Form, Modal } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';
import auth from '../../../../../libs/auth-lib';

import CMP_TABLECONTROLS, { filter_helper } from '../../../../../components/cmp_tablecontrols/cmp_tablecontrols';
import MDL_ADD_CREDENTIAL from './mdl_add_credential';

import './tab_preapprovals.css';

export default function({ var_activetab, var_target_id, var_credential }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();

    const [ var_loading, set_loading ] = useState(true);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_preapproved, set_preapproved ] = useState(true);
    const [ var_requirements, set_requirements ] = useState([]);
    const [ var_count_requirements_not_preapproved, set_count_requirements_not_preapproved ] = useState(0);
    const [ var_mdl_add_credential_open, set_mdl_add_credential_open ] = useState(false);

    const [ var_limit, set_limit] = useState(100);
    const [ var_offset, set_offset] = useState(0);
    const [ var_sortby, set_sortby ] = useState('requirement');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_activetab === 'PREAPPROVALS') {
            set_filter(filter_helper.initialize(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_activetab]);

    useEffect(()=>{
        if (var_filter.load) {
            var_target_id && var_credential && populate_requirements(null, 0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_filter]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_requirements(limit, offset, sortby, sortorder, filter) {
        if (var_activetab !== 'PREAPPROVALS') return;

        set_loading(true);
        set_ready(false);
        
        if (limit) {
            set_limit(limit);
        } else {
            limit = var_limit
        }

        if (offset || offset === 0) {
            set_offset(offset);
        } else {
            offset = var_offset
        }
    
        if (sortby) {
            set_sortby(sortby);
        } else {
            sortby = var_sortby
        }

        if (sortorder) {
            set_sortorder(sortorder);
        } else {
            sortorder = var_sortorder
        }  
        
        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_requirements_credential_preapproved(limit, offset, sortby, sortorder, filter);
            if (results.results) {
                set_requirements(results.results);
                set_count_requirements_not_preapproved(results.totalrows_not_in_preapproved); 
            } else {
                set_requirements(results);
                set_count_requirements_not_preapproved(results[0].totalrows_not_in_preapproved);
            }
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername, sortorder) {
        try {
            let results = await API_get_requirements_credential_preapproved(filter_helper.maxFilterItems, 0, filtername, sortorder, {}, filtername);
            return results.results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_requirements(limit, offset) {
        try {
            let results = await API_get_requirements_credential_preapproved(limit, offset, var_sortby, var_sortorder, var_filter);
            return results.results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_requirements_credential_preapproved(limit, offset, sortby, sortorder, filter, filtername) {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-requirements', '/get-requirements-credential-preapproved/'+auth.actingorganization_id+'/'+
            var_credential.credential_id+'/'+var_credential.issuer_id,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    preapproved: var_preapproved
                },
                body: filter
            }
        );
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_addcredential() {
        set_mdl_add_credential_open(true);
    }

    function onClick_sort(sortby) {
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        } else {
            if(sortorder === 'ascending') {
                sortorder = 'descending';
            } else if (sortorder === 'descending') {
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_requirements('', 0, sortby, sortorder);
    }

    // RENDER APP ======================================================================================================

    return  (
        <>
        {var_requirements.length > 0 && var_requirements[0]['totalrows'] !== 0 &&
        <div>{t('Requirements that have this credential as a pre-approved credential')}</div>
        }
        <div className="detail-spacer"></div>
        <div className="content-card" id="tab_preapprovals">
            <div className="content-card-gridcontent">    
                <div className="content-filter">
                    <div className="content-filter-item item-pagination">
                        <CMP_TABLECONTROLS
                            var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                            total_rows={var_requirements && var_requirements.length > 0 ? var_requirements[0]['totalrows'] : 0} populatefunction={populate_requirements}
                            downloadname='Requirements' downloadfunction={download_requirements}
                            var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                            table_config={[
                                {name: 'requirement', datatype: 'text', labelKey: 'REQUIREMENT', download: true, filter: true},
                            ]}
                        />
                    </div>
                </div>
                <div className="detail-spacer"></div>
                <div className="tablewrapper">
                    <Table compact className={(var_loading ? 'tbl_loading' : '')}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell sorted={var_sortby === 'requirement' ? var_sortorder : null} onClick={()=>onClick_sort('requirement')}>{t("REQUIREMENT")}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        {var_requirements.length > 0 && var_requirements[0]['totalrows'] !== 0 &&
                        <Table.Body>
                            {var_requirements.map((item) =>
                            <Table.Row key={item.id}>
                                <Table.Cell>{item.requirement}</Table.Cell>
                            </Table.Row>
                            )}
                        </Table.Body>
                        }
                    </Table>
                    {var_ready && var_requirements[0]['totalrows'] === 0 &&
                    <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t('alert icon')} />
                        {t('There are no requirements that have this credential as a pre-approved credential.')}
                    </div>
                    }
                </div>
                <div>
                    <div className="content-filter-item item-pagination">
                        <CMP_TABLECONTROLS
                            var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                            total_rows={var_requirements && var_requirements.length > 0 ? var_requirements[0]['totalrows'] : 0} populatefunction={populate_requirements}
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className="detail-spacer"></div>
        <div className='modal-footer-button-bar'>
            <Button disabled={var_count_requirements_not_preapproved === 0} className={'btn_primary' + (var_count_requirements_not_preapproved !== 0 ? ' btn_active' : '')}
                onClick={onClick_addcredential}>{t('ADD CREDENTIAL')}</Button>
        </div>
        <div className="detail-spacer"></div>
        {var_ready && var_count_requirements_not_preapproved === 0 &&
        <div>{t('There are no requirements that do not have this credential as a pre-approved credential.')}</div>
        }
        <div className="detail-spacer"></div>
        <div className="message_text_wrapper">
            <div className="message_text cause">{t('Additional requirements can be created in the')} <a href={"/org/requirements/setup"}>{t('Requirements Setup')}</a> {t('page.')}</div>
        </div>

        {/***** MODAL: Success *******************************************************************************/}

        <Modal id="mdl_add_credential"
               dimmer={'inverted'}
               open={var_mdl_add_credential_open}
               onClose={() => set_mdl_add_credential_open(false)}
               closeOnEscape={false}
               closeOnDimmerClick={false}>
            <MDL_ADD_CREDENTIAL
                set_mdl_add_credential_open={set_mdl_add_credential_open}                   
                var_credential={var_credential}
                populate_function={populate_requirements}>                    
            </MDL_ADD_CREDENTIAL>
        </Modal>

        {/***** END MODAL: INFO **************************************************************************/}

        </>
    )
}
import { Table, Modal } from 'semantic-ui-react'
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import datelib from '../../../../libs/date-lib';

import { useTranslation } from 'react-i18next';
import '../../../../i18n';

import CMP_TABLECONTROLS, { filter_helper } from '../../../../components/cmp_tablecontrols/cmp_tablecontrols';
import MDL_DOCVIEWER from "../../../../components/cmp_docviewer/cmp_docviewer";

import './crd_documents.css';



export default function({set_documentsrefresh}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();
    
    const [ var_document, set_document ] = useState([]);
    const [ var_documents, set_documents ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(100);
    const [ var_offset, set_offset] = useState(0);
    const [ var_search, set_search ] = useState('');
    const [ var_sortby, set_sortby ] = useState('name');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));

    const [ var_mdl_docviewer_open, set_mdl_docviewer_open ] = useState(false);
    const [ var_docviewerid, set_docviewerid ] = useState(null);
    const [ var_docviewer_showacknowledge, set_docviewer_showacknowledge ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if (var_document.transactionby === undefined){
            const document = {...var_document};
            document['individual_id'] = JSON.parse(sessionStorage.getItem('authentication')).id;
            document['organization_id'] = JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id;
            document['transactionby'] = JSON.parse(sessionStorage.getItem('authentication')).id;
            document['transactionorg'] = JSON.parse(sessionStorage.getItem('authentication')).organization_id;
            set_document(document);
        }
        populate_documents()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
   
    useEffect(()=>{
        set_filter(filter_helper.initialize(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(()=>{
        if(var_docviewer_showacknowledge && var_mdl_docviewer_open === false){
            populate_documents();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_docviewer_open]);

    useEffect(()=>{
        if(var_filter.load){
            populate_documents(null,0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_filter]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_documents(limit,offset,sortby,sortorder,search,filter){

        set_loading(true);
        set_documentsrefresh(false);    

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        if(search) {
            if(search === '-') {
                search = '';
            }
            set_search(search);
        }else{
            search=var_search
        }

        filter = filter ? filter : var_filter;

        set_docviewerid(null);

        try {
            let results = await API_get_documents(limit,offset,sortby,sortorder,search,filter);
            set_documents(results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
        set_documentsrefresh(true);
    }

    async function populate_filters(filtername,sortorder){
        try {
            return await API_get_documents(filter_helper.maxFilterItems,0,filtername,sortorder,var_search,{},filtername);
        } catch (e) {
            console.log(e);
        }
    }

    async function download_documents(limit,offset){
        try {
            return await API_get_documents(limit,offset,var_sortby,var_sortorder,var_search,var_filter);
        } catch (e) {
            console.log(e);
            throw e;
        } 
    }

    async function insert_documentacknowledge(id){
        try {
            await API_post_documentacknowledge(id);
        } catch (e) {
            console.log(e);
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_documents(limit,offset,sortby,sortorder,search,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('indv-documents',
            '/get-indv-documents/'+JSON.parse(sessionStorage.getItem('authentication')).id,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    search: search,
                    tz: datelib.timezone,
                    filtername: filtername
                },
                body: filter
            }
        );
    }

    function API_post_documentacknowledge(document_id){
        localStorage.setItem('activetime',Math.floor(Date.now()/1000));
        return API.post('indv-documents', '/post-indv-acknowledge/'+document_id, { body: var_document});
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_documents('',0,sortby,sortorder)
    }

    function onClick_document(id, acknowledged){
        set_docviewerid(id);
        set_docviewer_showacknowledge(acknowledged === null);
        set_mdl_docviewer_open(true);
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="content-card" id="crd_indv_documents">
                <div className="content-card-header">
                    <div className="content-card-header-title">{t('Documents')}</div>
                </div>
                <div className="content-card-gridcontent">
                    <div className="content-filter">
                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                var_offset={var_offset} var_limit={var_limit} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_documents && var_documents.length > 0 ? var_documents[0]['totalrows'] : 0} populatefunction={populate_documents}
                                downloadname='Documents' downloadfunction={download_documents}
                                var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                                table_config={[
                                    {name: 'name', datatype: 'text', labelKey: 'DOCUMENT', download: true, filter: true},
                                    {name: 'location', datatype: 'text', labelKey: 'LOC/PROJ', download: true, filter: true},
                                    {name: 'organization', datatype: 'text', labelKey: 'ORGANIZATION', download: true, filter: true},
                                    {name: 'acknowledged', datatype: 'text', labelKey: 'ACKNOWLEDGED', download: true, filter: false},
                                    {name: 'epochacknowledged', datatype: 'date', labelKey: 'ACKNOWLEDGED', download: false, filter: true}
                                ]}
                            />
                        </div>
                    </div>
                    <div className="tablewrapper">
                        <Table unstackable sortable compact className={(var_loading ? " tbl_loading" : "")}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell className="td_document" sorted={var_sortby === 'name' ? var_sortorder : null} onClick={()=>onClick_sort('name')}>{t('DOCUMENT')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_location" sorted={var_sortby === 'location' ? var_sortorder : null} onClick={()=>onClick_sort('location')}>{t('LOC/PROJ')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_organization" sorted={var_sortby === 'organization' ? var_sortorder : null} onClick={()=>onClick_sort('organization')}>{t('ORGANIZATION')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_attachment" sorted={var_sortby === 'filename' ? var_sortorder : null} onClick={() => onClick_sort('filename')}><img src={"/icons/paperclip 30px (c9caca).svg?ts=" + Date.now()} alt="paperclip icon"/></Table.HeaderCell>
                                    <Table.HeaderCell className="td_acknowledged" sorted={var_sortby === 'acknowledged' ? var_sortorder : null} onClick={()=>onClick_sort('acknowledged')}>{t('ACKNOWLEDGED')}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {var_ready && var_documents[0]['totalrows'] !== 0 &&
                            <Table.Body>
                                {var_documents.map((item, i) =>
                                    <Table.Row key={i}>
                                        <Table.Cell className="overflow td_document" onClick={() => onClick_document(item.id, item.ack_req==="NO"||item.acknowledged)}>{item.name}</Table.Cell>
                                        <Table.Cell className="td_location" onClick={() => onClick_document(item.id, item.ack_req==="NO"||item.acknowledged)}>{item.location}</Table.Cell>
                                        <Table.Cell className="td_organization" onClick={() => onClick_document(item.id, item.ack_req==="NO"||item.acknowledged)}>{item.organization}</Table.Cell>
                                        <Table.Cell className='td_attachment' onClick={() => onClick_document(item.id, item.ack_req==="NO"||item.acknowledged)}>
                                            {(item.filename !== null && item.filename !== '') ?
                                                <img src={"/icons/paperclip 60px (717473).svg?ts=" + Date.now()} alt={t('paperclip icon')}/>
                                                :
                                                ''
                                            }
                                        </Table.Cell>
                                        <Table.Cell className="td_acknowledge" onClick={() => onClick_document(item.id, item.ack_req==="NO"||item.acknowledged)}>
                                            {item.acknowledged !== null && item.acknowledged !== undefined && item.acknowledged !== "" ?
                                                datelib.date_localized_format(item.acknowledged)
                                                : item.ack_req === "NO" ?
                                                <div>{t('N/A')}</div>
                                                :
                                                <img src={"/icons/warning 30px (e0454f).svg?ts=" + Date.now()} alt={t('missing icon')}/>
                                            }
                                        </Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                            }
                        </Table>
                        {var_ready && var_documents[0]['totalrows'] === 0 &&
                        <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t('alert icon')}/>{t('there are no documents to display')}</div>
                        }
                    </div>
                    <div className="content-filter">
                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                var_offset={var_offset} var_limit={var_limit} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_documents && var_documents.length > 0 ? var_documents[0]['totalrows'] : 0} populatefunction={populate_documents}
                            />
                        </div>
                    </div>
                </div>
            </div>


            {/***** MODAL: DOCUMENT VIEW *****************************************************************************/}

            <Modal id="mdl_docviewer"
                dimmer={'inverted'}
                open={var_mdl_docviewer_open}
                onClose={() => set_mdl_docviewer_open(false)}>
                <MDL_DOCVIEWER
                    set_mdl_open={set_mdl_docviewer_open}
                    targetid={var_docviewerid}
                    classification='document'
                    show_acknowledge={var_docviewer_showacknowledge}
                    function_documentacknowledge={insert_documentacknowledge}
                />
            </Modal>

            {/***** END MODAL: DOCUMENT VIEW *********************************************************************/}


        </>
    )

}
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';
import auth from '../../../../../libs/auth-lib';

import './sharing.css';
import CRD_SHARING from './crd_sharing/crd_sharing';


export default function(appProps) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();
    const history = useHistory();


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(auth.is_loggedin){
            appProps.set_visibleframe(true);
            appProps.set_visiblemenu('INDIVIDUAL')
            appProps.set_activemenu('/indv/wallet/sharing');
        }else{
            history.push('/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    //  async functions ------------------------------------------------------------------------------------------------

    //  API calls ------------------------------------------------------------------------------------------------------

    //  event functions ------------------------------------------------------------------------------------------------


    // RENDER APP ======================================================================================================

    return(
        <>
            {auth.is_loggedin &&
                <div className="content">
                    <div className="content-topmenu">
                        {t('Profile Links')}
                    </div>
                    <div className="content-wrapper">
                        <CRD_SHARING></CRD_SHARING>
                    </div>
                </div>
            }
        </>
    )
};

import React, { useEffect } from "react";
import "./documents.css";

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import CRD_DOCUMENTS from "./crd_documents/crd_documents";


export default function(appProps) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        appProps.set_visibleframe(true);
        appProps.set_visiblemenu('INDIVIDUAL');
        appProps.set_activemenu('/indv/documents');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);



    //  async functions ------------------------------------------------------------------------------------------------

    //  API calls ------------------------------------------------------------------------------------------------------

    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return(
        <>
            {(sessionStorage.getItem('authentication')) &&
            <div className="content">
                <div className="content-topmenu">{t('Documents')} </div>
                <div className="content-wrapper">
                    <CRD_DOCUMENTS
                    set_documentsrefresh={appProps.set_documentsrefresh}>
                    </CRD_DOCUMENTS>

                </div>
            </div>
            }
        </>
    )
};

import { Button, Form, Input } from 'semantic-ui-react'
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import './mdl_manageroverride.css';



export default function({var_dailycheck_id, set_mdl_manageroverride_open, populate_indv_dailychecks}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_indv_dailycheck, set_indv_dailycheck] = useState({});
    const [ var_ready, set_ready] = useState(false);
    const [ var_processing, set_processing] = useState(false);



    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_dailycheck()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_dailycheck(){
        try {
            let indv_dailycheck = await API_get_dailycheck();
            indv_dailycheck[0]['mgr'] = JSON.parse(sessionStorage.getItem('authentication')).firstname+' '+JSON.parse(sessionStorage.getItem('authentication')).lastname;
            set_indv_dailycheck(indv_dailycheck[0]);
        } catch (e) {
            console.log(e);
        }
    }

    async function insert_indv_dailycheck_ovrd(){
        if(var_ready) {
            set_processing(true);
            try {
                await API_post_indv_dailycheck_ovrd();
                populate_indv_dailychecks();
                set_ready(false);
                set_processing(false);
                set_mdl_manageroverride_open(false);
            } catch (e) {
                console.log(e);
            }
        }

    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_dailycheck(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('indv-dailychecks', '/get-indv-dailycheck/'+var_dailycheck_id);
    }

    function API_post_indv_dailycheck_ovrd(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('indv-dailychecks', '/post-indv-dailycheck-ovrd', { body: var_indv_dailycheck });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_manageroverride_input(event){
        const indv_dailycheck = { ...var_indv_dailycheck };
        indv_dailycheck[event.target.name] = event.target.value;
        set_indv_dailycheck(indv_dailycheck);
        set_ready(true);
    }

    function onClick_submit(){
        insert_indv_dailycheck_ovrd();
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">{t("Daily Check Override")}</div>
            <div className="modal-content">
                {t("You are about to override this failed daily check and set the result to pass.  You must provide an explanation as to the reason why before submitting.")}
                <Form>
                    <Form.Group>
                        <Form.Field
                            className='mgr_note'
                            id='mgr_note'
                            name='mgr_note'
                            autocomplete="off"
                            value={var_indv_dailycheck.mgr_note || ''}
                            control={Input}
                            onChange={onChange_manageroverride_input}
                            label={t("OVERRIDE EXPLANATION")}
                            placeholder={t("Override explanation...")}
                        />
                    </Form.Group>
                </Form>
            </div>
            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_manageroverride_open(false)}>{t("CANCEL")}</Button>
                    {!var_processing ?
                        <Button className={"btn_primary " + (var_ready ? ' btn_active' : '')} onClick={() => onClick_submit()}>{t("OVERRIDE DAILY CHECK")}</Button>
                        :
                        <Button loading className="btn_primary btn_active">{t("OVERRIDE DAILY CHECK")}</Button>
                    }

                </div>
            </div>
        </>
    )
}
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { Button , Dropdown ,Form ,Checkbox} from "semantic-ui-react";
import React from "react";

import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import CMP_FILEUPLOAD from "../../../../../components/cmp_fileupload/cmp_fileupload";

import './mdl_add.css';



export default function({ set_mdl_open, populate_function,var_location_id,var_target,var_target_name}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();
    const [ var_document, set_document] = useState([]);
    const [ var_ready, set_ready] = useState(false);
    const [ var_processing, set_processing] = useState(false);
    const [var_validationerror, set_validationerror] = useState(false);
    const [var_validationerrormessage, set_validationerrormessage] = useState('');

    const [var_location, set_location] = useState([]);
    const [var_acknowledgement_required, set_acknowledgementRequired] = useState(true);

    const [var_dd_availablelocations, set_dd_availablelocations] = useState([]);

    const [ var_fileuploadstatus, set_fileuploadstatus ] = useState('');
    const [ var_fileuploadname, set_fileuploadname ] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [ var_fileuploadlabel, set_fileuploadlabel ] = useState('DOCUMENT');
    // eslint-disable-next-line no-unused-vars
    const [ var_fileuploaddirectory, set_fileuploaddirectory ] = useState('documents');
    const [ var_fileuploaddisplayname, set_fileuploaddisplayname ] = useState(null);
    const [ var_filetype, set_filetype ] = useState('application/pdf');
    const [ var_fileextension, set_fileextension ] = useState(['pdf']);
    const [ var_filesize, set_filesize ] = useState('10');
    const [ var_fileupload, set_fileupload ] = useState(null);

    const [ var_orgdocument, set_orgdocument ]= useState(true);

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_document.transactionby === undefined){
            const document = {...var_document};
            document['organization_id'] = JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id;
            document['transactionby'] = JSON.parse(sessionStorage.getItem('authentication')).id;
            document['transactionorg'] = JSON.parse(sessionStorage.getItem('authentication')).organization_id;
            set_document(document);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_document]);

    useEffect(() => {
        if(var_fileuploadstatus === 'PENDING'){
            console.log(var_fileuploadname);
            // set_ready(true);
            if(var_validationerror === true){
                set_validationerror(false);
            }
            const document = {...var_document};
            document['filename'] = var_fileuploadname;
            document['name'] = var_fileuploaddisplayname;
            set_document(document);
        }
        if(var_fileuploadstatus === 'COMPLETE'){
            insert_document();

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_fileuploadstatus]);

    useEffect(()=>{
        //set_ready(true);
        const document = {...var_document};
        document['filename'] = var_fileuploadname;
        document['name'] = var_fileuploaddisplayname;
        set_document(document);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_fileuploaddisplayname]);

    useEffect(() => {

        if(!var_location_id){
        populate_dd_availablelocations();
        }
        const location = { ...var_location };
        location["organization_id"] = JSON.parse(
          sessionStorage.getItem("authentication")
        ).actingorganization_id;
        location["transactionby"] = JSON.parse(
          sessionStorage.getItem("authentication")
        ).id;
        location["transactionorg"] = JSON.parse(
          sessionStorage.getItem("authentication")
        ).organization_id;
        set_location(location);
        var_location_id ? onChange_location(null,{value:[var_location_id]}) :onChange_location(null,{value:[]})
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      useEffect(() => {
        const acknowledgement_required= var_acknowledgement_required===true?"YES":"NO"
        const document = {...var_document};
        document['ack_req']=acknowledgement_required
        set_document(document);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [var_acknowledgement_required]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_dd_availablelocations() {
        try {
          let results = await API_get_dd_availablelocations();
          set_dd_availablelocations(results);
          var_location_id ? onChange_location(null,{value:[var_location_id]}) :onChange_location(null,{value:[]})
        } catch (e) {
          console.log(e);
        }
      }

    async function insert_document() {
        set_processing(true);
        try {
        await API_post_org_document()
        .then(res=>{
            const documentId = res.document_id

        const locationDocuments={...var_location,
          document_id:documentId,
          ack_req:var_acknowledgement_required,
          organization_id:JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id,
          transactionby: JSON.parse(sessionStorage.getItem('authentication')).id,
          transactionorg:JSON.parse(sessionStorage.getItem('authentication')).organization_id}

        if(res.document_id){
            if(var_location.locations[0]){
                API_post_loc_document(locationDocuments)
                set_ready(false);
                set_processing(false);
                set_mdl_open(false);
                populate_function();
            }
            set_ready(false);
            set_processing(false);
            set_mdl_open(false);

            populate_function();
        }
    })
    .catch(err=>console.log(err))
        } catch (e) {
            console.log(e);
        }

    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_org_document(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-documents', '/post-org-document/', { body: var_document });
    }

   // post document in location
    function API_post_loc_document(locationDocuments){
        console.log(locationDocuments)
        localStorage.setItem("activetime", Math.floor(Date.now() / 1000));
        return API.post("org-locations","/post-loc-document", {body: locationDocuments});

    }

    //get available loctaions
    function API_get_dd_availablelocations() {
        localStorage.setItem("activetime", Math.floor(Date.now() / 1000));
        return API.get("org-documents",'/get-dd-availablelocations/'+JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id+'/'+JSON.parse(sessionStorage.getItem('authentication')).id);
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_location(e, { value }) {
        const location = { ...var_location };
        location["locations"] = value;
        set_location(location);
      }

      function onChange_acknowledgementRequired() {
        set_acknowledgementRequired(var_acknowledgement_required => !var_acknowledgement_required);
      }

    function onChange_orgDocument() {
      if(!var_orgdocument){
        // When toggling the orgdocument back on, clear any selected locations
        const location = {...var_location};
        location["locations"] = [];
        set_location(location);
      }
      set_validationerror(false);
      set_orgdocument(!var_orgdocument);
    }

    function onClick_submit(){
        if(var_ready){
            console.log('s+++'+var_fileuploadstatus)
            let var_validation = false;
            let var_validmessage = '';
            if (var_fileuploadstatus === undefined || var_fileuploadstatus === '') {
                var_validation = true;
                var_validmessage = 'Please select a file to upload';
            }
            if (!var_orgdocument && var_location["locations"].length===0){
                var_validation = true;
                var_validmessage = 'Please choose at least 1 location/project';
            } 
            set_validationerrormessage(var_validmessage);
            set_validationerror(var_validation);
            if (var_validation === false) {
                if(var_fileuploadstatus === 'PENDING') {
                    set_fileuploadstatus('START');
                    set_processing(true);
                }else{
                    set_processing(true);
                    insert_document();
                }
            }
        }
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{t('Add Document')}</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t('close icon')}/></div>
            </div>
            <div className="modal-content">

              <Form.Group>
              <label>{t("ACKNOWLEDGEMENT REQUIRED")}</label>
                            <Checkbox
                                toggle
                                className="default"
                                label={ t("")}
                                onChange={onChange_acknowledgementRequired}
                                checked={var_acknowledgement_required || false}
                            />
              </Form.Group>

              {!var_location_id ? (
              <Form.Group>
                <label>{t("ORGANIZATION DOCUMENT")}</label>
                <Checkbox toggle onChange={onChange_orgDocument} checked={var_orgdocument}/>
              </Form.Group>
              ):(<div/>)}
              
                {var_location_id ?(
                <React.Fragment>
                <label>{t("LOCATIONS & PROJECTS")}&ensp;</label>
                <Dropdown
                  className="document_id"
                  name="locations"

                  text={var_target}
                  fluid
                  multiple
                  selection
                  disabled
                /><br />
                </React.Fragment>):(<div/>)
                }

                  {(!var_location_id && !var_orgdocument)?
                  (
                  <React.Fragment>
                  <label>{t("LOCATIONS & PROJECTS")}&ensp;</label>
                  <Dropdown
                  className="document_id"
                  name="locations"
                  options={var_dd_availablelocations}
                  value={var_location.locations || []}
                  onChange={onChange_location}
                  placeholder={t("Select locations & projects to add documents...")}
                  fluid
                  multiple
                  selection
                  />
                  <br />
                  </React.Fragment>


                  ):(<div/>)

                  }

                <p>{t('Only PDF file types accepted.')}</p>
                <CMP_FILEUPLOAD
                    var_fileuploaddirectory={var_fileuploaddirectory}
                    var_fileuploadlabel={var_fileuploadlabel}
                    var_fileuploadstatus={var_fileuploadstatus}
                    set_fileuploadstatus={set_fileuploadstatus}
                    var_fileuploadname={var_fileuploadname}
                    set_fileuploadname={set_fileuploadname}
                    var_fileuploaddisplayname={var_fileuploaddisplayname}
                    set_fileuploaddisplayname={set_fileuploaddisplayname}
                    var_placeholder={t('No file selected...')}
                    var_filetype={var_filetype}
                    var_fileextension={var_fileextension}
                    var_filesize={var_filesize}
                    var_ready={var_ready}
                    set_ready={set_ready}
                    var_fileupload={var_fileupload}
                    set_fileupload={set_fileupload}>
                </CMP_FILEUPLOAD>
                {var_validationerror &&
                    <div className="message warning">
                        <div className="message_icon"><img src={"/icons/warning 60px (d91e18).svg?ts=" + Date.now()} alt={t('warning icon')} /></div>
                        <div className="message_text_wrapper">
                            <div className="message_text cause">{t(var_validationerrormessage)}</div>
                        </div>
                    </div>
                }
            </div>
            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>{t('CANCEL')}</Button>
                    {!var_processing ?
                        <Button className={"btn_primary " + (var_ready ? ' btn_active' : '')} onClick={() => onClick_submit()}>{t('ADD DOCUMENT')}</Button>
                        :
                        <Button loading className="btn_primary btn_active">{t('ADD DOCUMENT')}</Button>
                    }
                </div>
            </div>
        </>
    )
}
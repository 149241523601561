import { Button, Modal, Table } from 'semantic-ui-react'
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import React from "react";
import datelib from '../../../../libs/date-lib';

import CMP_TABLECONTROLS, { filter_helper } from '../../../../components/cmp_tablecontrols/cmp_tablecontrols';
import MDL_ADD from './mdl_add/mdl_add';
import MDL_UPDATE from './mdl_update/mdl_update';

import './crd_packagessetup.css';

import { useTranslation } from 'react-i18next';
import '../../../../i18n';

export default function({var_activetab}){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();

    const [ var_packages, set_packages ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(100);
    const [ var_offset, set_offset] = useState(0);
    const [ var_archive, set_archive ] = useState('NO');
    const [ var_sortby, set_sortby ] = useState('name');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));
    // Add/Update properties
    const [ var_target_id, set_target_id ] = useState(null);
    const [ var_target, set_target ] = useState(null);
    const [ var_mdl_add_open, set_mdl_add_open ] = useState(false);
    const [ var_mdl_update_open, set_mdl_update_open ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_activetab === 'PACKAGESSETUP') {
            set_filter(filter_helper.initialize(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_activetab, var_archive]);

    useEffect(()=>{
        if(var_filter.load) {
            populate_packages(null, 0);
        }
    },[var_filter]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_packages(limit,offset,sortby,sortorder,archive,filter){
        if (var_activetab !== 'PACKAGESSETUP') return; // Don't load data if tab is not visible

        set_loading(true);
        set_ready(false);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        if(archive) {
            set_archive(archive);
        }else{
            archive=var_archive
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_packages(limit,offset,sortby,sortorder,archive,filter);
            set_packages(results);
            console.log(results)
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername,sortorder){
        try {
            return await API_get_packages(filter_helper.maxFilterItems, 0, filtername, sortorder, var_archive, {}, filtername);
        } catch (e) {
            console.log(e);
        }
    }

    async function download_packages(limit, offset){
        try {
            return await API_get_packages(limit, offset, var_sortby, var_sortorder, var_archive, var_filter);
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_packages(limit,offset,sortby,sortorder,archive,filter, filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-requirements',
            '/get-org-packages/'+JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id,
            {queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    archive: archive,
                    filtername: filtername
                },
                body: filter
            }
        );
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_packages('',0,sortby,sortorder)
    }

    function onClick_addPackage() {
        set_mdl_add_open(true);
    }

    function onClick_updatePackage(id, name) {
        set_target(name);
        set_target_id(id);
        set_mdl_update_open(true);
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="content-card" id="crd_org_individuals_individuals">
                <div className="content-card-header">
                    <div className="content-card-header-title">{t('Packages Setup')}</div>
                    <Button className="btn_tertiary" onClick={() => onClick_addPackage()}><img className="btn_icon" src={"/icons/add 30px (4dcbd4).svg?ts=" + Date.now()} alt="add icon"/>{t("ADD PACKAGE")}</Button>
                </div>
                <div className="content-card-gridcontent">
                    <div className="content-filter">
                        <div className="content-filter-archive">
                            {var_archive === 'NO' ?
                                <Button className="btn_tertiary" onClick={() => set_archive('YES')}><img className="btn_icon" src={"/icons/archive 30px (4dcbd4).svg?ts=" + Date.now()} alt={t("archive icon")}/>{t("VIEW ARCHIVED PACKAGES")}</Button>
                                :
                                <Button className="btn_tertiary" onClick={() => set_archive('NO')}><img className="btn_icon" src={"/icons/checkmark 30px (4dcbd4).svg?ts=" + Date.now()} alt={t("checkmark icon")}/>{t("VIEW ACTIVE PACKAGES")}</Button>
                            }
                        </div>

                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_packages && var_packages.length > 0 ? var_packages[0]['totalrows'] : 0} populatefunction={populate_packages}
                                downloadname='Packages' downloadfunction={download_packages}
                                var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                                table_config={[
                                    {name: 'name', datatype: 'text', labelKey: 'NAME', download: true, filter: true},
                                ]}
                            />
                        </div>
                    </div>
                    <div className="tablewrapper">
                        <Table id="tbl_packages" sortable compact className={(var_loading ? " tbl_loading" : "")}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell sorted={var_sortby === 'name' ? var_sortorder : null} onClick={()=>onClick_sort('name')}>{t("NAME")}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {var_ready && var_packages[0]['totalrows'] !== 0 &&
                            <Table.Body>
                                {var_packages.map((item) =>
                                    <Table.Row key={item.id} onClick={() => onClick_updatePackage(item.id, item.name)}>
                                        <Table.Cell >{item.name}</Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                            }
                        </Table>
                        {var_ready && var_packages[0]['totalrows'] === 0 &&
                        <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t("alert icon")}/>{t("there are no packages to display")}</div>
                        }
                    </div>
                    <div className="content-filter">
                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_packages && var_packages.length > 0 ? var_packages[0]['totalrows'] : 0} populatefunction={populate_packages}
                            />
                        </div>
                    </div>
                </div>

                {/***** MODAL: ADD ***********************************************************************************/}

                <Modal id="packagesetup_mdl_addpackage"
                    dimmer={'inverted'}
                    open={var_mdl_add_open}
                    onClose={() => set_mdl_add_open(false)}>
                    <MDL_ADD
                        set_mdl_open={set_mdl_add_open}
                        populate_function={populate_packages}>
                    </MDL_ADD>
                </Modal>

                {/***** END MODAL: ADD *******************************************************************************/}



                {/***** MODAL: UPDATE ********************************************************************************/}

                <Modal id="packagesetup_mdl_updatepackage"
                    dimmer={'inverted'}
                    open={var_mdl_update_open}
                    onClose={() => set_mdl_update_open(false)}>
                    <MDL_UPDATE
                        set_mdl_open={set_mdl_update_open}
                        target={var_target}
                        target_id={var_target_id}
                        populate_function={populate_packages}>
                    </MDL_UPDATE>
                </Modal>

                {/***** END MODAL: UPDATE ***************************************************************************/}

            </div>
        </>
    )

}
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";

import CRD_questions from "./crd_questions/crd_questions";
import CRD_dailycheck from "./crd_dailycheck/crd_dailycheck";

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import "./dailycheck.css";


export default function(appProps) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();
    const [ var_activetab, set_activetab ] = useState('DAILYCHECKQUESTIONS');

    useEffect(()=>{
        appProps.set_visibleframe(true);
        appProps.set_visiblemenu('INDIVIDUAL');
        appProps.set_activemenu('/indv/dailycheck');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    //  async functions ------------------------------------------------------------------------------------------------

    //  API calls ------------------------------------------------------------------------------------------------------

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_tab(var_tab){
        set_activetab(var_tab);
    }

//  RENDER CONTAINER ===============================================================================================

    return(
        <>
            {(sessionStorage.getItem('authentication')) &&
            <div className="content" id="indv_dailycheck_content">
                <div className="content-topmenu">
                    <img src={"/icons/checkmark 60px (585b5a).svg?ts=" + Date.now()} alt={t('location/project icon')}></img>{t('Daily Check')}
                </div>
                <div className="content-sidemenu-wrapper">
                <div className="content-sidemenu">
                    <div className={"content-sidemenu-item " + (var_activetab === 'DAILYCHECKQUESTIONS' ? 'content-sidemenu-item-active' : '')} onClick={() => onClick_tab('DAILYCHECKQUESTIONS')}>
                        <div className="content-sidemenu-item_header">{t('Daily Check Questionnaire')}</div>
                        <div className="content-sidemenu-item_subheader">{t('Fill out a daily check questionnaire')}</div>
                    </div>
                    <div className={"content-sidemenu-item " + (var_activetab === 'DAILYCHECKHISTORY' ? 'content-sidemenu-item-active' : '')} onClick={() => onClick_tab('DAILYCHECKHISTORY')}>
                        <div className="content-sidemenu-item_header">{t('My Daily Check History')}</div>
                        <div className="content-sidemenu-item_subheader">{t('See your daily check history')}</div>
                    </div>
                </div>
                <div className="content-wrapper">
                    <div className={(var_activetab !== 'DAILYCHECKQUESTIONS' ? 'content-card-hide' : '')}>
                        <CRD_questions />
                    </div>
                    <div className={(var_activetab !== 'DAILYCHECKHISTORY' ? 'content-card-hide' : '')}>
                        <CRD_dailycheck
                            var_activetab={var_activetab}
                        />
                    </div>
                </div>
                </div>
            </div>
            }
        </>
    )
};

//  OUTCOMES -----------------------------------------------------------------------------------------------------------
//
//  - displays a single requirement for an individual.  Depending on the state of the requirement, allows the user to
//      go through the workflow to select a credential to submit
//  - Can be called from the individual's wallet, or from the org.  If coming from the org, allows update if existing
//      or allows adding new.
//
//  --------------------------------------------------------------------------------------------------------------------
import { Button, Form, Checkbox } from 'semantic-ui-react';
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import propTypes from 'prop-types';
import Moment from "moment";
import datelib from '../../../../libs/date-lib';

import { useTranslation } from 'react-i18next';
import '../../../../i18n';

import CMP_CONFIRMATION from '../../../../components/cmp_confirmation/cmp_confirmation';
import CMP_SUCCESS from '../../../../components/cmp_success/cmp_success';

import './mdl_indv_requirement.css';

function MDL_INDV_REQUIREMENTS({var_mdl_open, set_mdl_open, source, var_target_id, populate_function}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();   
    const [ var_indv_requirement, set_indv_requirement ] = useState(null);
    const [ var_requirement_options, set_requirement_options ] = useState([]);
    const [ var_dd_selected_requirements, set_dd_selected_requirements ] = useState([]);
    const [ var_selected_requirement_no_preapproved, set_selected_requirement_no_preapproved ] = useState('');
    const [ var_dd_selected_requirements_loading, set_dd_selected_requirements_loading ] = useState(false);
    const [ var_indv_package, set_indv_package ] = useState(null);
    const [ var_package_options, set_package_options ] = useState([]);
    const [ var_preapproved_indv_credentials, set_preapproved_indv_credentials ] = useState([]);
    const [ var_indv_dd_credentials, set_indv_dd_credentials ] = useState([]);
    const [ var_selected_credential, set_selected_credential ] = useState({});
    const [ var_skip_credential, set_skip_credential ] = useState(false);
    const [ var_missing, set_missing ] = useState(false);

    const [ var_credential, set_credential ] = useState('');
    const [ var_step, set_step ] = useState('LOADING');
    const [ var_processing, set_processing ] = useState(false);
    const [ var_transaction_id, set_transaction_id ] = useState('');

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_mdl_open) {
            set_step('LOADING');
            if (source === 'INDV' || source === 'ORG') {
                populate_indv_requirement();
            } else {
                get_indv_details();
                populate_package_list();
                populate_requirement_list();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_mdl_open]);

    useEffect(() => {
        if (source === 'INDV' && var_indv_requirement && !['VALID', 'PENDING'].includes(var_indv_requirement.status)){
            populate_indv_credentials();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_indv_requirement]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function get_indv_details(){
        try {
            let indv_requirement = {
                id: null,
                organization: JSON.parse(sessionStorage.getItem('authentication')).organization,
                individual_id: var_target_id,
                individual_name: '',
                individual_firstname: ''
            };
            let indv_package = {
                id: null,
                organization: JSON.parse(sessionStorage.getItem('authentication')).organization,
                individual_id: var_target_id,
                individual_name: '',
                individual_firstname: ''
            };
            set_indv_requirement(indv_requirement);
            set_indv_package(indv_package);
            let results = await API_get_indv();
            indv_requirement.individual_name = results[0].lastname+', '+results[0].firstname;
            indv_requirement.individual_firstname = results[0].firstname;
            set_indv_requirement(indv_requirement);
            indv_package.individual_name = results[0].lastname+', '+results[0].firstname;
            indv_package.individual_firstname = results[0].firstname;
            set_indv_package(indv_package);
            set_step('SELECT REQ_PCKG');
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    async function populate_package_list(){
        try {
            let results = await API_get_dd_org_packages();
            set_package_options(results);
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    async function populate_requirement_list(){
        try {
            let results = await API_get_dd_indv_availablerequirements();
            set_requirement_options(results);
            set_step('SELECT REQ_PCKG');
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    async function populate_indv_requirement(){
        try {
            let result = await API_get_indv_requirement();
            if (source === 'INDV') {
                result[0]['individual_id'] = JSON.parse(sessionStorage.getItem('authentication')).id;
                result[0]['organization_id'] = JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id;
                result[0]['transactionby'] = JSON.parse(sessionStorage.getItem('authentication')).id;
                result[0]['transactionorg'] = JSON.parse(sessionStorage.getItem('authentication')).organization_id;
            }
            set_indv_requirement(result[0]);
            const individualcredential_id = result[0].individualcredential_id;
            if (individualcredential_id !== null && individualcredential_id !== '') {
                let credential = await API_get_indv_credential(individualcredential_id)
                set_credential(credential[0]);
            }
            (['VALID','PENDING'].includes(result[0].status) || source === 'ORG') && set_step('INFO');
        } catch (e) {
            console.log(e);
        }
    }

    async function populate_indv_credentials(){
        let creds = [];

        try {
            let results = '';
            results = await API_get_dd_requirement_credentials();
            let preapproved = results.filter(item => item.history_status === 'VALID');
            let nonapproved = results.filter(item => item.history_status !== 'VALID');
            set_preapproved_indv_credentials(preapproved);

            if (results.length === 0) {
                set_skip_credential(source !== 'INDV');
                set_step('NO CREDENTIALS');
            } else if (preapproved.length === 1) {
                onChange_credential(null, {value: preapproved[0].id});
                set_step('SINGLE PREAPPROVED');
            } else if (preapproved.length > 1) {
                set_step('MULTIPLE PREAPPROVED');
            } else {
                set_step('NON PREAPPROVED');
            }
            nonapproved.forEach((item, i) =>
                creds.push({
                    'value': item.history_status === 'INVALID' ? 'INVALID' + item.id : item.id,
                    'text':item.credential,
                    'id':item.id,
                    'content': (
                        <div className={"dropdown_option" + (item.history_status === 'INVALID' ? ' invalid': '')}>
                            <div className="option_icon">
                                <img src={"/icons/checkmark 30px (69b536).svg?ts=" + Date.now()} alt={t("checkmark icon")}/>
                            </div>
                            <div className="option_content">
                                <div className="option_title">{item.credential}</div>
                                <div className="option_meta_list">
                                    {item.history_status === 'INVALID'
                                    ?
                                        <div>{item.requesting_org + t(' will not accept this credential')}</div>
                                    :
                                    <>
                                        <div><b>{t('ISSUED BY')}: </b>{item.issuedby}</div>
                                        <div><b>{t('ISSUED')} : </b>{datelib.date_localized_format(item.issued)}</div>
                                    </>
                                    }
                                </div>
                            </div>
                        </div>
                    )
                })
            );
            set_indv_dd_credentials(creds);
        } catch (e) {
            console.log(e);
        }
    }

    async function update_individualrequirement() {
        set_processing(true);
        try {
            let result = source === 'INDV' ? await API_put_indv_requirement() : source === 'ORG' ? await API_org_put_indv_requirement() : await API_post_indv_requirement();
            set_transaction_id(result.transaction_id);
            set_selected_credential({...var_selected_credential, status: result.status});
            populate_function && populate_function();
            set_step('SUBMISSION COMPLETE');
            set_processing(false);
        } catch (e) {
            console.log(e);
        }
    }

    async function remove_requirement() {
        try {
            await API_delete_indv_requirement();
            populate_function && populate_function();
            set_mdl_open(false);
        } catch (e) {
            console.log(e);
        }
    }

    function formatmessage(message) {
        let result = '';
        let partial;
        while (message.length > 0) {
            if (message[0] === '{') {
                partial = message.substring(0, message.indexOf('}') + 1);
                message = message.substring(partial.length);
                result += partial === '{requirement}' ? var_indv_requirement.requirement
                    : partial === '{organization}' ? var_indv_requirement.organization
                    : partial === '{individual}' ? var_indv_requirement.individual_name
                    : partial === '{individual firstname}' ? var_indv_requirement.individual_firstname
                    : partial === '{individual firstname upper}' ? var_indv_requirement.individual_firstname.toUpperCase() : '';
            } else if (message.includes('{')) {
                partial = message.substring(0, message.indexOf('{'));
                message = message.substring(partial.length);
                result += t(partial);
            } else {
                result += message === '.' ? '.' : t(message);
                message = '';
            }
        }
        return result;
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_dd_indv_availablerequirements(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-requirements', '/get-dd-indv-orgavailablerequirements/'+JSON.parse(sessionStorage.getItem('authentication')).organization_id+'/'+var_target_id);
    }

    function API_get_dd_org_packages(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-requirements', '/get-dd-org-packages/'+JSON.parse(sessionStorage.getItem('authentication')).organization_id);
    }

    function API_get_package_requirements(package_id){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-requirements', '/get-package-requirements/'+package_id,
            {
                queryStringParameters: {
                    limit: 10000,
                    offset: 0,
                    sortby: 'id',
                    sortorder: 'ascending'
                }
            }
        );
    }

    function API_get_indv(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org', '/get-indv/'+JSON.parse(sessionStorage.getItem('authentication')).organization_id+'/'+var_target_id);
    }

    function API_get_indv_credential(id){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('indv-credentials', '/get-indv-credential/'+id, {
            queryStringParameters: {
                tz : datelib.timezone
            }
        });
    }

    function API_get_dd_requirement_credentials(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('indv-credentials', '/get-dd-requirement-credentials/'+var_indv_requirement.individual_id+'/'+var_indv_requirement.requirement_id,
            { queryStringParameters: { tz: datelib.timezone } });
    }

    function API_get_indv_requirement(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('indv-credentials', '/get-indv-requirement/'+var_target_id,
            { queryStringParameters: { tz: datelib.timezone } });
    }

    function API_put_indv_requirement(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.put('indv-credentials', '/put-indv-requirement/'+var_target_id, {body: {
            ...var_selected_credential,
            status: var_missing ? 'MISSING' : 'PENDING',
            individual_id: var_indv_requirement.individual_id,
            organization_id: var_indv_requirement.organization_id,
            transactionby: var_indv_requirement.transactionby,
            transactionorg: var_indv_requirement.transactionorg
        }});
    }

    function API_org_put_indv_requirement(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.put('org-requirements', '/put-indv-requirement', {
            body: {
                id: var_target_id,
                individualcredential_id: var_selected_credential.individualcredential_id || null,
                status: var_skip_credential ? 'REQUESTED' : var_missing ? 'MISSING' : 'VALID',
                comments: null,
                transactionorg: JSON.parse(sessionStorage.getItem('authentication')).organization_id
            }
        });
    }

    function API_post_indv_requirement(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        let data = {
            organization_id: JSON.parse(sessionStorage.getItem('authentication')).organization_id,
            individual_id: var_target_id,
            requirement_id: var_indv_requirement.requirement_id,
            transactionorg: JSON.parse(sessionStorage.getItem('authentication')).organization_id,
            individualcredential_id: var_selected_credential.individualcredential_id,
            status: var_skip_credential ? 'REQUESTED' : var_missing ? 'MISSING' : 'VALID'
        };
        return API.post('org-requirements', '/post-indv-orgrequirement', {body: data});
    }

    function API_post_indv_packagerequirement(requirement_id){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        let data = {
            organization_id: JSON.parse(sessionStorage.getItem('authentication')).organization_id,
            individual_id: var_target_id,
            requirement_id: requirement_id,
            transactionorg: JSON.parse(sessionStorage.getItem('authentication')).organization_id,
            status: 'REQUESTED'
        };
        return API.post('org-requirements', '/post-indv-orgrequirement', 
        {
            queryStringParameters: {
                tz : datelib.timezone
            },
            body: data
        });
    }

    function API_delete_indv_requirement(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        let transactionorg = JSON.parse(sessionStorage.getItem('authentication')).organization_id;
        return API.del('org-requirements', '/delete-indv-requirement/'+var_indv_requirement.individualrequirement_id+'/'+transactionorg);
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_requirement(event, data){
        if (data.value) {
            const requirement = var_requirement_options.find(item => item.value === data.value);
            set_indv_requirement({...var_indv_requirement, requirement_id: requirement.value, requirement: requirement.text, description: requirement.description});
            set_selected_requirement_no_preapproved(requirement.total_preapproved === 0 ? requirement.text : '');           
        }
    }

    async function onChange_package(event, data){
        if (data.value) {
            set_dd_selected_requirements_loading(true);
            const packagedetail = var_package_options.find(item => item.value === data.value);
            set_indv_package({...var_indv_package, requirement_id: packagedetail.value, requirement: packagedetail.text, description: packagedetail.description});

            let result = await API_get_package_requirements(data.value);
            set_dd_selected_requirements_loading(false);
            set_dd_selected_requirements(result.results);
        }
    }

    function onChange_credential(event, data){
        if (data.value && !data.value.startsWith('INVALID')) {
            const indv_credential = {};
            indv_credential['individualrequirement_id'] = var_indv_requirement.id;
            indv_credential['comments'] = '';
            indv_credential['individualcredential_id'] = data.value;
            indv_credential['status'] = 'PENDING';
            set_selected_credential(indv_credential);
            set_skip_credential(false);
        } else {
            set_selected_credential({});
        }
    }

    function onClick_skipcredential(){
        onChange_credential(null, {value: null});
        set_skip_credential(!var_skip_credential);
    }

    function onClick_missing(){
        onChange_credential(null, {value: null});
        set_skip_credential(false);
        set_missing(!var_missing);
        set_step(var_missing ? 'NON PREAPPROVED' : 'MISSING');
    }

    function onClick_switch_nonapproved_preapproved() {
        // reset any values
        set_selected_credential({});
        set_skip_credential(false);
        set_missing(false);
        // change step
        set_step(var_step !== 'NON PREAPPROVED' ? 'NON PREAPPROVED' : var_preapproved_indv_credentials.length === 1 ? 'SINGLE PREAPPROVED' : 'MULTIPLE PREAPPROVED');
    }

    function onClick_continue(){
        if (var_indv_requirement) {
            populate_indv_credentials();
        }
    }

    function onClick_delete(){
        if (!var_processing && var_indv_requirement.indv_only === 'YES') {
            set_step('DELETE');
        }
    }

    function onClick_change(){
        populate_indv_credentials();
    }

    async function onClick_submit(){
        if(var_selected_credential.individualcredential_id || var_skip_credential || var_missing) {
            set_processing(true);
            update_individualrequirement();
        }
        if(var_step === 'SELECT PACKAGE'){
            set_processing(true);
            var var_transaction_ids = '';
            for(var requirement of var_dd_selected_requirements){
                let result = await API_post_indv_packagerequirement(requirement.requirement_id);
                var_transaction_ids = var_transaction_ids + result.transaction_id + ' ';
            }

            set_transaction_id(var_transaction_ids);
            populate_function && populate_function();
            set_step('PACKAGE COMPLETE');
            set_processing(false);
        }
    }


    // RENDER APP ======================================================================================================

    return (
        <div className={'mdl-indv-requirements-container ' + source}>
            {var_step === 'LOADING'
            ? render_loading()
            : var_step === 'INFO'
            ? render_submittedcredentialinfo()
            : var_step === 'SELECT REQ_PCKG'
            ? render_selectreq_pckg()
            : var_step === 'SELECT PACKAGE'
            ? render_selectpackage()
            : var_step === 'SELECT REQUIREMENT'
            ? render_selectrequirement()
            : var_step === 'NO CREDENTIALS'
            ? render_nocredentials()
            : var_step === 'MISSING'
            ? render_missing()
            : var_step === 'NON PREAPPROVED'
            ? render_nonpreapprovedcredentials()
            : var_step === 'SINGLE PREAPPROVED'
            ? render_single_preapprovedcredentials()
            : var_step === 'MULTIPLE PREAPPROVED'
            ? render_multiple_preapprovedcredentials()
            : var_step === 'DELETE'
            ? render_delete()
            : (var_step === 'SUBMISSION COMPLETE' && var_selected_credential.status === 'NOCHANGE')
            ? render_submission_nochange()
            : var_step === 'SUBMISSION COMPLETE'
            ? render_submission()
            : var_step === 'PACKAGE COMPLETE'
            ? render_packagesubmission()
            : null}
        </div>);

    //#region render methods for components

    function render_header(title){
        return (
            <div className="modal-header"><div className="modal-header-title">{title}</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t('x icon')} /></div>
            </div>
        );
    };

    function render_buttons(button_options){
        let form_valid = button_options === 'CONTINUE' ? (var_indv_requirement !== null) :
            (var_skip_credential || var_missing || var_selected_credential.individualcredential_id || var_dd_selected_requirements.length > 0);

        return (
            <div className="modal-footer">
                <div className='modal-footer-buttons'>
                    {button_options === 'CANCEL_SUBMIT' ?
                        <>
                            <Button className="btn_secondary" disabled={var_processing} onClick={() => set_mdl_open(false)}>{t('CANCEL')}</Button>
                            <Button loading={var_processing} disabled={var_processing || !form_valid}
                                className={"btn_primary " + (form_valid ? ' btn_active' : '')} onClick={() => onClick_submit()}>{t('SUBMIT')}</Button>
                        </>
                    : button_options === 'CONTINUE' ?
                        <Button loading={var_processing} disabled={var_processing || !form_valid}
                            className={"btn_primary" + (form_valid ? ' btn_active' : '')} onClick={onClick_continue}>{t('CONTINUE')}</Button>
                    : button_options === 'DELETE_CHANGE' ?
                        <>
                            <Button className="btn_secondary" disabled={var_processing || var_indv_requirement.indv_only === 'NO'}
                                onClick={onClick_delete}>{t('DELETE REQUIREMENT')}</Button>
                            <Button loading={var_processing} disabled={var_processing}
                                className="btn_primary btn_active" onClick={onClick_change}>{t(var_indv_requirement.individualcredential_id ? 'CHANGE CREDENTIAL' : 'ADD CREDENTIAL')}</Button>
                        </>
                    : button_options === 'CHANGE' ?
                        <Button loading={var_processing} disabled={var_processing}
                            className='btn_primary btn_active' onClick={onClick_change}>{t('CHANGE CREDENTIAL')}</Button>
                    :
                        <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>{t('CLOSE')}</Button>
                    }
                </div>
            </div>
        );
    };

    function render_requirementinfo(){
        return (
            <div className="credentialdetails">
                <div className="detail-item">
                    <div className="detail-item-label">{t('REQUIREMENT')}</div>
                    <div className="detail-item-text">{var_indv_requirement.requirement}</div>
                </div>
                <div className="detail-item">
                    <div className="detail-item-label">{t('DESCRIPTION')}</div>
                    <div className="detail-item-text">{var_indv_requirement.description}</div>
                </div>
                {source === 'INDV' &&
                <div className="detail-item">
                    <div className="detail-item-label">{t('ORGANIZATION')}</div>
                    <div className="detail-item-text">{var_indv_requirement.organization}</div>
                </div>
                }
                {var_indv_requirement.individualrequirement_id &&
                <>
                    <div className="detail-item">
                        <div className="detail-item-label">{t('ASSIGNMENT')}</div>
                        <div className="detail-item-text">{var_indv_requirement.assignedfrom}</div>
                    </div>
                    <div className="detail-item">
                        <div className="detail-item-label">{t('STATUS')}</div>
                        <div className="detail-item-text">{var_indv_requirement.status}</div>
                    </div>
                </>
                }
                {var_indv_requirement.status === 'INVALID' &&
                    <div className="detail-item">
                        <div className="detail-item-label">{t('COMMENTS')}</div>
                        <div className="detail-item-text">{var_indv_requirement.comments}</div>
                    </div>
                }
                <br/>
            </div>
        );
    };

    function render_skipcredential(){
        if (source === 'INDV') return null;
        return (
            <div>
                <Checkbox checked={var_skip_credential} onClick={onClick_skipcredential} label={t('Skip credential')} />
            </div>
        );
    };

    function render_preapprovedcredential(credential, selected, allowselection){
        return (
            <div key={credential.id} className={'approvedcredential_container' + (allowselection ? ' selectable' : '') + (selected ? ' selected' : '')}
                onClick={() => allowselection && !selected && onChange_credential(null, {value: credential.id})}>
                <div className='approvedcredential_icon'>
                    {selected ?
                    <img src={'/icons/checkmark 60px (69b536).svg?ts=' + Date.now()} alt={t('checkmark icon')}/>
                    :
                    <img src={'/icons/circle 60px (CACCCB).svg?ts=' + Date.now()} alt={t('circle icon')}/>
                    }
                </div>
                <div className='approvedcredentialdetail_container'>
                    <div className='approvedcredential_title'>{credential.credential}</div>
                    <div className='approvedcredentialissued_container'>
                        <div className='approvedcredentialdetail_name'>{t('ISSUED BY')}</div>
                        <div className='approvedcredentialdetail_detail'>{credential.issuedby}</div>
                        <div className='approvedcredentialdetail_name'>{t('ISSUED')}</div>
                        <div className='approvedcredentialdetail_detail'>{datelib.date_localized_format(credential.issued)}</div>
                    </div>
                </div>
            </div>
        );
    };

    //#endregion

    //#region render methods for steps

    function render_loading(){
        return (
            <>
            {render_header(t('Loading...'))}
            <div className="modal-content"></div>
            <div className="modal-footer"></div>
            </>
        );
    };

    function render_selectreq_pckg(){
        return (
            <>
                {render_header(t('Add Requirement or Package'))}
                <div id="selectreq_pckg" className="modal-content">
                    <div>{formatmessage("Do you wish to add a single requirement or a package of requirements?")}</div>
                    <Button
                        className={"btn_primary btn_active"}
                        onClick={() => {set_step('SELECT REQUIREMENT')}}
                    >{t("REQUIREMENT")}</Button>
                    <Button
                        className={"btn_secondary"}
                        onClick={() => {set_step('SELECT PACKAGE')}}
                    >{t("PACKAGE")}</Button>
                </div>
            </>
        );
    };

    function render_selectpackage(){
        return (
            <>
                {render_header(t('Add Package'))}
                <div id="selectpackage" className="modal-content">
                    <div>{formatmessage("Select the package you want to add to {individual} and click 'CONTINUE'.")}</div>
                    <Form>
                        <Form.Group>
                            <Form.Select
                                className='select_field'
                                id="packages"
                                name="packages"
                                label={t('PACKAGE')}
                                placeholder={t('Packages...')}
                                options={var_package_options}
                                onChange={onChange_package}
                                value={var_indv_package ? var_indv_package.package_id : null}
                                fluid
                                search
                            />
                        </Form.Group>
                    </Form>

                    { var_dd_selected_requirements.length > 0 &&
                        <>
                            {var_dd_selected_requirements_loading ?
                                <div className="detail-item-label">{t('LOADING REQUIREMENTS IN THIS PACKAGE...')}</div>
                            :
                                <div className='selectedrequirements'>
                                    <div className="detail-item-label">{t('REQUIREMENTS IN THIS PACKAGE')}</div>
                                    <ul>
                                    { var_dd_selected_requirements.map((item, i) =>
                                            <li key={i}>{item.requirement}</li>
                                        )}
                                    </ul>
                                </div>
                            }
                        </>
                    }
                </div>
                {render_buttons('CANCEL_SUBMIT')}
            </>
        );
    };

    function render_selectrequirement(){
        return (
            <>
                {render_header(t('Add Requirement'))}
                <div className="modal-content">
                    <div>{formatmessage("Select the requirement you want to add to {individual} and click 'CONTINUE'.")}</div>
                    <Form>
                        <Form.Group>
                            <Form.Select
                                className='select_field'
                                id="requirements"
                                name="requirements"
                                label={t('REQUIREMENT')}
                                placeholder={t('Requirements...')}
                                options={var_requirement_options}
                                onChange={onChange_requirement}
                                value={var_indv_requirement ? var_indv_requirement.requirement_id : null}
                                fluid
                                search
                            />
                        </Form.Group>
                    </Form>
                </div>
                {var_selected_requirement_no_preapproved.length > 0 &&
                <div id="requirement_warning" className="message warning">
                    <div className="message_icon"><img src={"/icons/warning 60px (d91e18).svg?ts=" + Date.now()} alt={t('warning icon')} /></div>
                    <div className="message_text_wrapper">
                        <div className="message_text cause">{t('The requirement')} {var_selected_requirement_no_preapproved} {t('does not have any pre-approved credentials so automatic validation is not possible. Pre-approved credentials can be added in the ')}
                        <a href={"/org/requirements/setup"}>{t('Requirements Setup')}</a> {t('page')}.
                        </div>
                    </div>
                </div>}
                {render_buttons('CONTINUE')}
            </>
        );
    };

    function render_submittedcredentialinfo(){
        return (
            <>
                {render_header(t('View Requirement'))}
                <div className="modal-content">
                    {render_requirementinfo()}
                    <div>
                        <div className="detail-item">
                            <div className="detail-item-label">{t('CREDENTIAL')}</div>
                            <div className="detail-item-text">{var_credential.credential}</div>
                        </div>
                        <div className="detail-item">
                            <div className="detail-item-label">{t('ISSUED BY')}</div>
                            <div className="detail-item-text">{var_credential.issuedby}</div>
                        </div>
                        <div className="detail-item">
                            <div className="detail-item-label">{t('ISSUED')}</div>
                            <div className="detail-item-text">{var_credential.issuedepoch !== null && var_credential.issuedepoch !== undefined && var_credential.issuedepoch !== "" ? Moment.unix(var_credential.issuedepoch).utc(false).format('ll'): ''}</div>
                        </div>
                        <div className="detail-item">
                            <div className="detail-item-label">{t('EXPIRES')}</div>
                            <div className="detail-item-text">{var_credential.expiresepoch !== null && var_credential.expiresepoch !== undefined && var_credential.expiresepoch !== "" ? Moment.unix(var_credential.expiresepoch).utc(false).format('ll') : ''}</div>
                        </div>
                    </div>
                </div>
                {render_buttons(source === 'INDV' ? 'CHANGE' : 'DELETE_CHANGE')}
            </>
        );
    };

    function render_nocredentials(){
        return (
            <>
                {render_header(t(var_indv_requirement.individualcredential_id ? 'Change Credential' : 'Submit Credential'))}
                <div className="modal-content">
                    {render_requirementinfo()}
                    <div>
                        {source === 'INDV' ? t('You have no valid credentials to submit') :
                        source === 'ORG-NEW' ? formatmessage("{individual} does not have any valid credentials to submit. Click 'SUBMIT' to add the requirement to {individual firstname}.") :
                        formatmessage('{individual} does not have any valid credentials to submit.')}
                    </div>
                </div>
                {render_buttons(source === 'ORG-NEW' ? 'CANCEL_SUBMIT' : 'CLOSE')}
            </>
        );
    };

    function render_missing(){
        return (
            <>
                {render_header(t(var_indv_requirement.individualcredential_id ? 'Change Credential' : 'Submit Credential'))}
                <div className="modal-content">
                    {render_requirementinfo()}
                    <div>
                        {source === 'INDV' ?
                        formatmessage("If you do not currently have a suitable credential in your wallet to meet the {requirement} requirement, please click 'SUBMIT' now and your requirement status will be set to 'MISSING'. You will be able to submit a credential at any time.") :
                        source === 'ORG-NEW' ? formatmessage("If {individual} does not have a suitable credential to meet the {requirement} requirement. click 'SUBMIT' to add the requirement to {individual firstname} with a status of 'MISSING'. They will be able to submit a credential at any time.") :
                        formatmessage("If {individual} does not have a suitable credential to meet the {requirement} requirement. click 'SUBMIT' to set the requirement with a status of 'MISSING'. They will be able to submit a credential at any time.") }
                    </div>
                    <div className='credentialoptions_othercreds'>
                        <Button className="btn_tertiary" onClick={onClick_missing}>
                            <img className="btn_icon" src={"/icons/previous_arrow 30px (29719a).svg?ts=" + Date.now()}
                                alt="public credential icon"/>{t('SELECT A CREDENTIAL')}
                        </Button>
                    </div>
                </div>
                {render_buttons('CANCEL_SUBMIT')}
            </>
        );
    };

    function render_nonpreapprovedcredentials(){
        let instruction_text = source === 'ORG-NEW' || source === 'ORG'
            ? formatmessage("Select the credential that meets the {requirement} requirement. If {individual firstname} does not have an appropriate credential, select 'SKIP CREDENTIAL' and click 'SUBMIT'.")
            : formatmessage("Select one of your credentials from the list below to meet the {requirement} requirement for {organization} and click 'SUBMIT' to continue.");
        return (
            <>
                {render_header(t(var_indv_requirement.individualcredential_id ? 'Change Credential' : 'Submit Credential'))}
                <div className="modal-content">
                    {render_requirementinfo()}
                    <div className='credentialoptions_nonpreapproved'>
                        <div className='credentialoptions_instructions'>{instruction_text}</div>
                        <div>
                            <Form>
                                <Form.Group>
                                    <Form.Select
                                        className='select_field'
                                        id="credentials"
                                        name="credentials"
                                        label={t('CREDENTIAL')}
                                        placeholder={t('Credentials...')}
                                        options={var_indv_dd_credentials}
                                        onChange={onChange_credential}
                                        value={var_indv_dd_credentials.some(item => item.value === var_selected_credential.individualcredential_id)
                                            ? var_selected_credential.individualcredential_id : null}
                                        fluid
                                        search
                                    />
                                </Form.Group>
                            </Form>
                            {render_skipcredential()}
                        </div>
                    </div>

                    <div className='credentialoptions_othercreds'>
                        {var_preapproved_indv_credentials.length === 0 ?
                            <Button className="btn_tertiary" onClick={onClick_missing}>
                                <img className="btn_icon" src={"/icons/alert 30px (29719a).svg?ts=" + Date.now()}
                                    alt="public credential icon"/>{formatmessage(source === 'INDV' ? "I DON'T HAVE A MATCHING CREDENTIAL" : "{individual firstname upper} DOESN'T HAVE A MATCHING CREDENTIAL")}
                            </Button>
                        : var_preapproved_indv_credentials.length === 1 ?
                            <Button className="btn_tertiary" onClick={onClick_switch_nonapproved_preapproved}>
                                <img className="btn_icon" src={"/icons/next_arrow 30px (29719a).svg?ts=" + Date.now()}
                                    alt="public credential icon"/>{t('RETURN TO PRE-APPROVED CREDENTIAL')}
                            </Button>
                        :
                            <Button className="btn_tertiary" onClick={onClick_switch_nonapproved_preapproved}>
                                <img className="btn_icon" src={"/icons/next_arrow 30px (29719a).svg?ts=" + Date.now()}
                                    alt="public credential icon"/>{t('RETURN TO PRE-APPROVED CREDENTIALS')}
                            </Button>
                        }
                    </div>
                </div>
                {render_buttons('CANCEL_SUBMIT')}
            </>
        );
    };

    function render_single_preapprovedcredentials(){
        let nonapproved_exist = var_indv_dd_credentials.length > 0;
        return (
            <>
                {render_header(t(var_indv_requirement.individualcredential_id ? 'Change Credential' : 'Submit Credential'))}
                <div className="modal-content">
                    {render_requirementinfo()}
                    <div className='credentialoptions'>
                        <div className='credentialoptions_instructions'>{formatmessage("This credential is pre-approved by {organization} to meet the {requirement} requirement. Click 'SUBMIT' to continue or select a different credential.")}</div>
                        {render_preapprovedcredential(var_preapproved_indv_credentials[0], var_preapproved_indv_credentials[0].id === var_selected_credential.individualcredential_id, nonapproved_exist && !var_processing)}
                        <div className='credentialoptions_othercreds'>
                            <Button className="btn_tertiary" onClick={onClick_switch_nonapproved_preapproved}>
                                <img className="btn_icon" src={"/icons/previous_arrow 30px (29719a).svg?ts=" + Date.now()} alt="public credential icon" />{t('SELECT A DIFFERENT CREDENTIAL')}
                            </Button>
                        </div>
                    </div>
                </div>
                {render_buttons('CANCEL_SUBMIT')}
            </>
        );
    };

    function render_multiple_preapprovedcredentials(){
        return (
            <>
                {render_header(t(var_indv_requirement.individualcredential_id ? 'Change Credential' : 'Submit Credential'))}
                <div className="modal-content">
                    {render_requirementinfo()}
                    <div className='credentialoptions'>
                        <div className='credentialoptions_instructions'>{formatmessage("The following credentials are pre-approved by {organization} to meet the {requirement} requirement. Select one of them and click 'SUBMIT' to continue.")}</div>
                        <div>
                            {var_preapproved_indv_credentials.map(item => render_preapprovedcredential(item, item.id === var_selected_credential.individualcredential_id, !var_processing))}
                        </div>
                        <div className='credentialoptions_othercreds'>
                            <Button className="btn_tertiary" onClick={onClick_switch_nonapproved_preapproved}>
                                <img className="btn_icon" src={"/icons/previous_arrow 30px (29719a).svg?ts=" + Date.now()} alt="public credential icon" />{t('SELECT A DIFFERENT CREDENTIAL')}
                            </Button>
                        </div>
                    </div>
                </div>
                {render_buttons('CANCEL_SUBMIT')}
            </>
        );
    };

    function render_delete(){
        return (
            <CMP_CONFIRMATION var_modaltitle={t('Remove Requirement')} var_message={formatmessage('Are you sure you want to remove {requirement} from {individual}?')}
                cancel_function={() => set_step('INFO')} confirmation_function={remove_requirement} var_call_to_function={true} success_function={() => {}} />
        );
    };

    function render_submission(){
        let body = source === 'INDV' && !var_missing
            ? (
                <>
                    <div>{formatmessage('You have successfully submitted your credential to meet the {requirement} requirement.')}</div>
                    {var_selected_credential.status === 'PENDING' &&
                    <div>{formatmessage('The credential is now pending review by {organization}.')}</div>
                    }
                </>
              )
            : source === 'INDV'
            ? (
                <>
                    <div>{formatmessage('You have successfully responded to the {requirement} requirement but without providing a credential.')}</div>
                    <div>{t("The requirement will be marked as 'MISSING' in your wallet.")}</div>
                </>
              )
            : source === 'ORG-NEW' && (var_skip_credential || var_missing)
            ? formatmessage('The {requirement} requirement has successfully been added to {individual} but they will have to add an appropriate credential to their wallet and manually submit it before the requirement can become valid.')
            : source === 'ORG-NEW'
            ? formatmessage('The {requirement} requirement has successfully been added to {individual}.')
            : source === 'ORG' && (var_skip_credential || var_missing)
            ? formatmessage('The credential for {requirement} has successfully been removed.')
            : formatmessage('The credential for {requirement} has successfully been changed.');
        return (
            <CMP_SUCCESS title={t(var_skip_credential || var_missing ? 'Submitted Without Credentials' : 'Credential Submitted')} set_mdl_open={set_mdl_open} success_or_fail='SUCCESS'
                header_text={t(var_skip_credential || var_missing ? 'SUBMITTED WITHOUT CREDENTIALS' : 'CREDENTIAL SUBMITTED')}
                body={body} transaction_id={var_transaction_id} />
        );
    };

    function render_packagesubmission(){
        let body = formatmessage('The package requirements have successfully been added.');
        return (
            <CMP_SUCCESS title={t('Package Submitted')}
                         set_mdl_open={set_mdl_open}
                         success_or_fail='SUCCESS'
                         header_text={t('PACKAGE SUBMITTED')}
                         body={body} transaction_id={var_transaction_id} />
        );
    };

    function render_submission_nochange(){
        return (
            <CMP_SUCCESS title={t('No Change')} set_mdl_open={set_mdl_open} success_or_fail='INFO'
                body={t('Your request was submitted but there were no changes.')} />
        );
    }

    //#endregion
}

MDL_INDV_REQUIREMENTS.propTypes = {
    var_mdl_open: propTypes.bool.isRequired,
    set_mdl_open: propTypes.func.isRequired,
    source: propTypes.oneOf(['INDV', 'ORG', 'ORG-NEW']).isRequired,
    var_target_id: propTypes.string.isRequired,
    populate_function: propTypes.func
};

export default MDL_INDV_REQUIREMENTS;

import React, { useEffect, useState} from 'react';
import { API } from 'aws-amplify';
import { Button, Card, CardContent, CardMeta, Checkbox, Form, Input } from 'semantic-ui-react';
import auth from '../../../../../libs/auth-lib';

import CMP_FILEUPLOAD from "../../../../../components/cmp_fileupload/cmp_fileupload";

import './mdl_add.css';


import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

export default function({ set_mdl_open, populate_function, var_organization_id, var_issuer_id }){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();

    const [ var_credential, set_credential ] = useState([]);
    const [ var_dd_categories, set_dd_categories ] = useState([]);
    const [ var_status, set_status ] = useState(false);
    const [ var_issuedrequired, set_issuedrequired ] = useState(true);
    const [ var_expires, set_expires ] = useState(false);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_validationerror, set_validationerror] = useState(false);
    const [ var_validationerrormessage, set_validationerrormessage] = useState('');
    const [ var_descriptioncount, set_descriptioncount] = useState(0);
    const [ var_skillscount, set_skillscount] = useState(0);

    const [ var_fileuploadstatus, set_fileuploadstatus ] = useState('');
    const [ var_fileuploadname, set_fileuploadname ] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [var_fileuploadlabel, set_fileuploadlabel] = useState(t('CREDENTIAL IMAGE'));
    // eslint-disable-next-line no-unused-vars
    const [ var_fileuploaddirectory, set_fileuploaddirectory ] = useState('courses');
    const [ var_fileuploaddisplayname, set_fileuploaddisplayname ] = useState(null);

    const [ var_filetype, set_filetype ] = useState('application/pdf, image/png, image/jpg, image/jpeg');
    const [ var_fileextension, set_fileextension ] = useState(['pdf','png','jpg','jpeg']);
    const [ var_filesize, set_filesize ] = useState('10');
    const [ var_fileupload, set_fileupload ] = useState(null);


    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_credential.transactionby === undefined){
            const credential = {...var_credential};
            credential['status'] = 'PENDING';
            credential['issuedrequired'] = 'NO';
            credential['expires_amount'] = null;
            if (auth.org_wallet){
                credential['indv_or_org'] = null;
            }else{
                credential['indv_or_org'] = 'INDV';
            }
            credential['verifiable_credential'] = 'NO';
            credential['organization_id'] = var_organization_id;
            credential['issuer_id'] = var_issuer_id;
            credential['transactionby'] = JSON.parse(sessionStorage.getItem('authentication')).id;
            credential['transactionorg'] = JSON.parse(sessionStorage.getItem('authentication')).organization_id;
            set_credential(credential);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_credential]);

    useEffect(() => {
        const credential = { ...var_credential };
        if(var_status) {
            credential['status'] = 'ACTIVE';
        }else{
            credential['status'] = 'PENDING';
        }
        set_credential(credential);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_status]);

    useEffect(() => {
        const credential = { ...var_credential };
        if(var_issuedrequired) {
            credential['issuedrequired'] = 'YES';
        }else{
            credential['issuedrequired'] = 'NO';
        }
        set_credential(credential);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_issuedrequired]);

    useEffect(() => {
        const credential = { ...var_credential };
        if(var_expires) {
            credential['expires'] = 'YES';
            credential['expires_amount'] = null;
            credential['expires_unit'] = 'day';
        }else{
            credential['expires'] = 'NO';
            credential['expires_amount'] = null;
            credential['expires_unit'] = null;
        }
        set_credential(credential);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_expires]);

    useEffect(() => {
        if(var_fileuploadstatus === 'PENDING'){
            const credential = {...var_credential};
            credential['imagefile'] = var_fileuploadname;
            credential['submittedfilename'] = var_fileuploaddisplayname;
            set_credential(credential);
        }
        if(var_fileuploadstatus === 'COMPLETE'){
            insert_credential();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_fileuploadstatus]);
    
    useEffect(()=> {
        populate_credential_categories();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function insert_credential(credential) {
        set_processing(true);
        try {
            await API_post_org_credential(credential || var_credential);
            set_ready(false);
            set_processing(false);
            set_mdl_open(false);
            populate_function();
        } catch (e) {
            console.log(e);
        }
    }

    async function populate_credential_categories() {
        try {
            let result = await API_get_credential_categories();
            if (result && result.length > 0) {
                set_dd_categories(result.map(item => ({ value: item.id, text: item.id })));
            }
        } catch (e) {
            console.log(e);
        }
    }

   
    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_org_credential(credential) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.post('org-credentials', '/post-org-credential/', { body: credential });
    }

    function API_get_credential_categories() {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.get('org-credentials', '/get-credential-categories/'+var_organization_id);
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_submit(){
        if(var_ready){
            let var_validation = false;
            let var_validmessage = '';
            if (!var_credential.name || var_credential.name.trim() === '') {
                var_validation = true;
                var_validmessage = 'Please provide a name for this credential';
            }
            if (var_credential.expires === 'YES') {
                if (!var_credential.expires_amount || var_credential.expires_amount.trim().length === 0) {
                    var_validation = true;
                    var_validmessage = 'Please provide the expires amount';
                } else {
                    let num = Number(var_credential.expires_amount.trim());
                    if (Number.isNaN(num) || Math.floor(num) !== num || num < 1 || num > 5000) {
                        var_validation = true;
                        var_validmessage = 'Please provide an expires amount between 1 and 5000';
                    }
                }
            }
            if (!var_credential.credential_category_id) {
                var_validation = true;
                var_validmessage = 'Please provide a category for this credential';
            }
            if(var_status !== null && var_credential.indv_or_org === null){
                var_validation = true
                var_validmessage = 'Please select a type'
            }
            if(var_credential.indv_or_org !== null && var_status === null){
                var_validation = true
                var_validmessage = 'Please select a status' 
            }
            if(var_credential.indv_or_org === null && var_status === null){
                var_validation = true
                var_validmessage = 'Please select a type and status'
            }
            set_validationerrormessage(var_validmessage);
            set_validationerror(var_validation);
            if (var_validation === false) {
                // update/clear info for credential number
                let credential = var_credential;
                if (credential.has_credential_number === 'YES') {
                    credential.credential_number_required = credential.credential_number_required || 'NO';
                    if (!credential.credential_number_label || credential.credential_number_label.trim().length === 0) {
                        credential.credential_number_label = null;
                    }
                } else {
                    credential.has_credential_number = 'NO';
                    credential.credential_number_required = null;
                    credential.credential_number_label = null;
                }
                set_credential(credential);

                // start save process now
                if(var_fileuploadstatus === 'PENDING') {
                    set_fileuploadstatus('START');
                    set_processing(true);
                }else{
                    set_processing(true);
                    insert_credential(credential);
                }   
            }
        }
    }

    function onChange_input(event){
        const credential = { ...var_credential };
        if (event.target.value === '') {
            credential[event.target.name] = null;
        }else {

            credential[event.target.name] = event.target.value.substring(0, 500);
         } 
        set_credential(credential);
        set_validationerror(false);
        set_ready(true);
        
    }

    function onChange_category(event, data) {
        const credential = { ...var_credential };
        credential.credential_category_id = data.value;
        set_credential(credential);
        set_validationerror(false);
        set_ready(true);
    }

    function onChange_indv_or_org(value) {
        const credential = { ...var_credential };
        if (value === 'INDV'){
        credential.indv_or_org = 'INDV';
        }else if (value === 'ORG'){
            credential.indv_or_org = 'ORG'
        }
        set_credential(credential);
    }

    function onChange_status(){
        set_status(var_status => !var_status);
        set_ready(true);
    }

    function onChange_issuedrequired(){
        set_issuedrequired(var_issuedrequired => !var_issuedrequired);
        set_ready(true);
    }

    function onChange_expires(){
        set_expires(var_expires => !var_expires);
        set_ready(true);
    }

    function onChange_expires_unit(event, { value }){
        const credential = { ...var_credential };
        credential['expires_unit'] = value;
        set_credential(credential);
        set_ready(true);
    }

    function onChange_toggle(property) {
        const credential = { ...var_credential };
        credential[property] = (credential[property] === 'YES' ? 'NO' : 'YES');
        set_credential(credential);
        set_ready(true);
    }
    

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{t('Add Credential')}</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
            </div>
            <div className="modal-content">
                <div className="modal-content-title"><b>{t('Details')}</b></div>
                <Form>
                    <Form.Group>
                        <Form.Field
                            className='name'
                            name='name'
                            value={var_credential.name || ''}
                            control={Input}
                            onChange={onChange_input}
                            label={t('CREDENTIAL NAME')}
                            placeholder={t('Name')}
                            maxLength="100"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Select
                            className='category'
                            name='category'
                            label={t('CATEGORY')}
                            options={var_dd_categories}
                            value={var_credential.credential_category_id}
                            onChange={onChange_category}
                            placeholder={t('Select a category...')}
                            fluid                            
                            selection
                            search
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                            className='code'
                            name='code'
                            value={var_credential.code || ''}
                            control={Input}
                            onChange={onChange_input}
                            label={t('CODE')}
                            placeholder={t('Code')}
                            maxLength="50"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.TextArea
                            
                            className='description'
                            name='description'
                            value={var_credential.description || ''}
                            onChange={e => {set_descriptioncount(e.target.value.length); onChange_input(e)}}
                            label={t('DESCRIPTION')}
                            placeholder={t('Description')}
                            maxLength={500}
                        />
                    
                    </Form.Group>
                    <div className='the-count'>
                        <p>{var_descriptioncount}/500</p>
                    </div>
                    <Form.Group>
                        <Form.TextArea
                            className='skills'
                            name='skills'
                            value={var_credential.skills || ''}
                            onChange={e => {set_skillscount(e.target.value.length); onChange_input(e)}}
                            label={t('SKILLS')}
                            placeholder={t('Skills')}
                            maxLength={500}

                        />
                        
                    </Form.Group>
                    <div className='the-count'>
                            <p>{var_skillscount}/500</p>
                    </div>
                    <CMP_FILEUPLOAD
                        var_fileuploaddirectory={var_fileuploaddirectory}
                        var_fileuploadlabel={var_fileuploadlabel}
                        var_fileuploadstatus={var_fileuploadstatus}
                        set_fileuploadstatus={set_fileuploadstatus}
                        var_fileuploadname={var_fileuploadname}
                        set_fileuploadname={set_fileuploadname}
                        var_fileuploaddisplayname={var_fileuploaddisplayname}
                        set_fileuploaddisplayname={set_fileuploaddisplayname}
                        var_placeholder={t('No file selected')}
                        var_filetype={var_filetype}
                        var_fileextension={var_fileextension}
                        var_filesize={var_filesize}
                        var_ready={var_ready}
                        set_ready={set_ready}
                        var_fileupload={var_fileupload}
                        set_fileupload={set_fileupload}>
                    </CMP_FILEUPLOAD>
                    <p>{t('Only PDF, JPG, PNG file types accepted. Uploaded file will be unavailable for viewing until the virus scan is complete')}</p>
                    
                    <div className = 'settings_title'><b>{t('Settings')}</b></div>

                    {auth.org_wallet &&
                    <div className='setting_sub_categories'><b>{t('TYPE')}</b></div>
                    }
                    {auth.org_wallet &&
                        <Form.Group>
                            
                            <Checkbox
                                    radio
                                    name='IndivCheckbox'
                                    className={var_credential.indv_or_org === 'INDV'? '' : 'toggle_active'}
                                    label={t('Individual')}
                                    checked={var_credential.indv_or_org === 'INDV'}
                                    onChange={e => {onChange_indv_or_org('INDV')}}
                                
                            />
                            
                            <Checkbox
                                    radio
                                    label={t('Organization')}
                                    name='OrgCheckbox'
                                    className={var_credential.indv_or_org === 'ORG'? '' : 'toggle_active'}
                                    checked={var_credential.indv_or_org === 'ORG'}
                                    onChange={e => {onChange_indv_or_org('ORG')}}
                                
                            />
                            
                        </Form.Group>
                    }
                    <div className='setting_sub_categories'><b>{t('STATUS')}</b></div>
                    
                    <Form.Group>
                        <div className='form_spacing'>
                            <Checkbox
                                radio
                                label={t('Active')}
                                checked={var_status === true}
                                onChange={e => {onChange_status(); set_status(true)}}
                            />
                        </div>
                        <Checkbox
                            radio
                            label={t('Pending')}
                            checked={var_status === false}
                            onChange={e => {onChange_status(); set_status(false)}}

                        />
                    </Form.Group>
                    
                    <div className = 'setting_sub_categories'><b>{t('OTHER')}</b></div>

                    {auth.org_verifiable_credentials &&
                    <div className='toggle_container'>
                        <Form.Group className='primary_toggle_group'>
                            <Checkbox
                                className={var_credential.verifiable_credential === 'YES'  ? 'toggle_active' : ''}
                                label={t('Available as a Verifiable Credential')}
                                onChange={() => onChange_toggle('verifiable_credential')}
                                checked={var_credential.verifiable_credential === 'YES' || false}
                            />
                        </Form.Group>
                    </div>
                    }

                    <Form.Group>
                        <Checkbox
                            className={"issuedrequired " + (var_issuedrequired ? ' toggle_active' : '')}
                            label={t('Issued date is required')}
                            onChange={onChange_issuedrequired}
                            checked={var_issuedrequired || false}
                        />
                    </Form.Group>
                    
                    <div className='toggle_container'>
                        <Form.Group className='primary_toggle_group'>
                            <Checkbox
                                className={var_expires ? 'toggle_active' : ''}
                                label={t('Credential expires')}
                                onChange={onChange_expires}
                                checked={var_expires || false}
                            />
                        </Form.Group>
                    </div>
                        {var_expires &&
                        <div>
                        <Card className='card'>
                            <Card.Content>
                                <Card.Meta>
                                    <div className='card_spacing'>{t('Specify the timeframe for expiry')}</div>
                                </Card.Meta>
                                 
                                <Form.Group>
                                    <Form.Field
                                        className='expires_amount'
                                        name='expires_amount'
                                        value={var_credential.expires_amount || ''}
                                        control={Input}
                                        onChange={onChange_input}
                                        label={t('AMOUNT')}
                                        placeholder={t('Number')}
                                    />
                                    <Form.Select
                                        className='expires_unit'
                                        compact
                                        name='expires_unit'
                                        label={t('UNIT')}
                                        placeholder={t('day(s)')}
                                        onChange={onChange_expires_unit}
                                        value={var_credential.expires_unit || 'day'}
                                        options={[{"text": t('day(s)'), "value": "day"},
                                            {"text": t('month(s)'), "value": "month"},
                                            {"text": t('year(s)'), "value": "year"}]}
                                    />
                                </Form.Group>
                            </Card.Content>
                        </Card>
                        </div>
                        }
                    <div className='toggle_container'>
                        <Form.Group className='secondary_toggle_group'>
                            <Checkbox
                                className={var_credential.has_credential_number === 'YES' ? 'toggle_active' : ''}
                                label={t('Has credential number, course ID, student ID, etc.')}
                                onChange={() => onChange_toggle('has_credential_number')}
                                checked={var_credential.has_credential_number === 'YES'}
                            />
                        </Form.Group>
                    </div>
                        {var_credential.has_credential_number === 'YES' &&
                        <div>
                            <Card>
                                <CardContent>
                                    <CardMeta>
                                        <div className='card_spacing'>{t('Specify the label to be displayed for this field.')}</div>
                                    </CardMeta>
                                    <Form.Group>
                                        <Form.Field
                                            name='credential_number_label'
                                            value={var_credential.credential_number_label || ''}
                                            control={Input}
                                            onChange={onChange_input}
                                            label={t('Field Label')}
                                            placeholder={t('Name')}
                                            />
                                    </Form.Group>
                                    <Form.Group>
                                        <Checkbox
                                                className={var_credential.credential_number_required === 'YES' ? 'toggle_active' : ''}
                                                label={t('This is a required field')}
                                                onChange={() => onChange_toggle('credential_number_required')}
                                                checked={var_credential.credential_number_required === 'YES'}
                                            />
                                    </Form.Group>
                                </CardContent>
                            </Card>
                        </div>
                        
                        }
                    
                </Form>
                {var_validationerror &&
                    <div className="message warning">
                        <div className="message_icon"><img src={"/icons/warning 60px (d91e18).svg?ts=" + Date.now()} alt={t('warning icon')} /></div>
                        <div className="message_text_wrapper">
                            <div className="message_text cause">{t(var_validationerrormessage)}</div>
                        </div>
                    </div>
                }
            </div>

            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>{t('CANCEL')}</Button>
                    {!var_processing ?
                        <Button className={"btn_primary " + (var_ready ? ' btn_active' : '')} onClick={() => onClick_submit()}>{t('ADD CREDENTIAL')}</Button>
                        :
                        <Button loading className="btn_primary btn_active">{t('ADD CREDENTIAL')}</Button>
                    }
                </div>
            </div>
        </>
    )
}
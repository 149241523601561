import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import datelib from '../../../../../../../libs/date-lib';
import { useTranslation } from 'react-i18next';
import '../../../../../../../i18n';

import './sta_dailycheck.css';


export default function({ var_activetab, var_location_id }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_stat, set_stat] = useState([]);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_activetab === 'DETAILS') {
            populate_loc_stat()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_activetab]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_loc_stat(){
        try {
            let result = await API_get_loc_stat();
            set_stat(result);
        } catch (e) {
            console.log(e);
        }

    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_loc_stat(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-dailychecks', '/get-loc-stat/'+var_location_id+'?tz='+datelib.querystring_timezone);
    }



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <>
            <div id="dailychecksummary" className="summary_wrapper">
                <div className="summary_icon">
                    <img src={"/icons/checkmark 60px (717473).svg?ts=" + Date.now()} alt={t("daily check icon")}/>
                </div>
                <div className="summary_item">
                    <div className="summary_item_number green">{var_stat.pass || '0'}</div>
                    <div className="summary_item_text">{t("PASSED")}</div>
                    <div className="summary_item_text">{t("CHECKS")}</div>
                </div>
                <div className="summary_item">
                    <div className="summary_item_number red">{var_stat.fail || '0'}</div>
                    <div className="summary_item_text">{t("FAILED")}</div>
                    <div className="summary_item_text">{t("CHECKS")}</div>
                </div>
                <div className="summary_item">
                    <div className="summary_item_number">{var_stat.missing || '0'}</div>
                    <div className="summary_item_text">{t("MISSING")}</div>
                    <div className="summary_item_text">{t("CHECKS")}</div>
                </div>
            </div>
        </>
    )

}
import { Button, Modal, Table } from 'semantic-ui-react'
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import auth from '../../../../libs/auth-lib';
import datelib from '../../../../libs/date-lib';

import CMP_TABLECONTROLS, { filter_helper } from '../../../../components/cmp_tablecontrols/cmp_tablecontrols';

import MDL_ADD from './mdl_add/mdl_add';
import MDL_UPDATE from './mdl_update/mdl_update';


import './crd_requirementssetup.css';

import { useTranslation } from 'react-i18next';
import '../../../../i18n';

export default function({var_activetab}){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();

    const [ var_requirements, set_requirements ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(100);
    const [ var_offset, set_offset] = useState(0);
    const [ var_archive, set_archive ] = useState('NO');
    const [ var_sortby, set_sortby ] = useState('requirement');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));

    const [ var_mdl_add_open, set_mdl_add_open ] = useState(false);
    const [ var_mdl_update_requirement_open, set_mdl_update_requirement_open ] = useState(false);
    const [ var_mdl_update_requirement_details, set_mdl_update_requirement_details ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_activetab === 'REQUIREMENTSSETUP') {
            set_filter(filter_helper.initialize(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_activetab]);

    useEffect(()=>{
        if(var_archive !== undefined) {
            set_filter(filter_helper.initialize(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_archive]);

    useEffect(()=>{
        if(var_filter.load) {
            populate_requirements(null, 0);
        }
    },[var_filter]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_requirements(limit,offset,sortby,sortorder,archive,filter){
        if (var_activetab !== 'REQUIREMENTSSETUP') return; // Don't load data if tab is not visible

        set_loading(true);
        set_ready(false);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        if(archive) {
            set_archive(archive);
        }else{
            archive=var_archive
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_requirements(limit,offset,sortby,sortorder,archive,filter);
            set_requirements(results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername,sortorder){
        try {
            return await API_get_requirements(filter_helper.maxFilterItems, 0, filtername, sortorder, var_archive, {}, filtername);
        } catch (e) {
            console.log(e);
        }
    }

    async function download_requirements(limit, offset){
        try {
            return await API_get_requirements(limit, offset, var_sortby, var_sortorder, var_archive, var_filter);
        }  catch (e) {
             console.log(e);
             throw e;
         }
     }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_requirements(limit,offset,sortby,sortorder,archive,filter, filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-requirements',
            '/get-requirements/' + auth.actingorganization_id,
                        {queryStringParameters: {
                            limit: limit,
                            offset: offset,
                            sortby: sortby,
                            sortorder: sortorder,
                            archive: archive,
                            tz : datelib.timezone,
                            filtername: filtername
                        },
                        body: filter
                    }
                    );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_requirements('',0,sortby,sortorder)
    }

    function onClick_addrequirement(filter){
        set_mdl_add_open(true);
    }

    function onClick_requirement( id, name){
        set_mdl_update_requirement_details({ id, name });
        set_mdl_update_requirement_open(true);

    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="content-card" id="crd_org_individuals_individuals">
                <div className="content-card-header">
                    <div className="content-card-header-title">{t('Requirements Setup')}</div>
                    {auth.permission_id === auth.constants.permission_ids.Superadmin &&
                    <Button className="btn_tertiary" onClick={()=>onClick_addrequirement()}><img className="btn_icon" src={"/icons/add 30px (4dcbd4).svg?ts=" + Date.now()} alt={t('add icon')}/>{t('ADD REQUIREMENT')}</Button>
                    }
                </div>
                <div className="content-card-gridcontent">
                    <div className="content-filter">
                        <div className="content-filter-archive">
                            {var_archive === 'NO' ?
                                <Button className="btn_tertiary" onClick={() => set_archive('YES')}><img className="btn_icon" src={"/icons/archive 30px (4dcbd4).svg?ts=" + Date.now()} alt={t("archive icon")}/>{t("VIEW ARCHIVED REQUIREMENTS")}</Button>
                                :
                                <Button className="btn_tertiary" onClick={() => set_archive('NO')}><img className="btn_icon" src={"/icons/checkmark 30px (4dcbd4).svg?ts=" + Date.now()} alt={t("checkmark icon")}/>{t("VIEW ACTIVE REQUIREMENTS")}</Button>
                            }
                        </div>

                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_requirements && var_requirements.length > 0 ? var_requirements[0]['totalrows'] : 0} populatefunction={populate_requirements}
                                downloadname='Requirements' downloadfunction={download_requirements}
                                var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                                table_config={[
                                    {name: 'requirement', datatype: 'text', labelKey: 'REQUIREMENT', download: true, filter: true},
                                    {name: 'indvs', datatype: 'int', labelKey: 'INDVS', download: true, filter: true},
                                    {name: 'requested', datatype: 'int', labelKey: 'REQUESTED', download: true, filter: true},
                                    {name: 'validreq', datatype: 'int', labelKey: 'VALID', download: true, filter: true},
                                    {name: 'pending', datatype: 'int', labelKey: 'PENDING', download: true, filter: true},
                                    {name: 'comingdue', datatype: 'int', labelKey: '30 DAYS', download: true, filter: true},
                                    {name: 'expired', datatype: 'int', labelKey: 'EXPIRED', download: true, filter: true},
                                    {name: 'missing', datatype: 'int', labelKey: 'MISSING', download: true, filter: true},
                                    {name: 'invalid', datatype: 'int', labelKey: 'INVALID', download: true, filter: true}
                                ]}
                            />
                        </div>
                    </div>
                    <div className="tablewrapper">
                        <Table id="tbl_requirements" sortable compact className={(var_loading ? " tbl_loading" : "")}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell sorted={var_sortby === 'requirement' ? var_sortorder : null} onClick={()=>onClick_sort('requirement')}>{t("REQUIREMENT")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'indvs' ? var_sortorder : null} onClick={()=>onClick_sort('indvs')}>{t("INDVS")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'validreq' ? var_sortorder : null} onClick={()=>onClick_sort('validreq')}>{t("VALID")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'comingdue' ? var_sortorder : null} onClick={()=>onClick_sort('comingdue')}>{t("30 DAYS")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'pending' ? var_sortorder : null} onClick={()=>onClick_sort('pending')}>{t("PENDING")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'expired' ? var_sortorder : null} onClick={()=>onClick_sort('expired')}>{t("EXPIRED")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'requested' ? var_sortorder : null} onClick={()=>onClick_sort('requested')}>{t("REQUESTED")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'missing' ? var_sortorder : null} onClick={()=>onClick_sort('missing')}>{t("MISSING")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'invalid' ? var_sortorder : null} onClick={()=>onClick_sort('invalid')}>{t("INVALID")}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {var_ready && var_requirements[0]['totalrows'] !== 0 &&
                            <Table.Body>
                                {var_requirements.map((item) =>
                                    <Table.Row key={item.id} onClick={()=>onClick_requirement(item.id, item.requirement)}>
                                        <Table.Cell >{item.requirement}</Table.Cell>
                                        <Table.Cell >{item.indvs}</Table.Cell>
                                        <Table.Cell className="td_green">{item.validreq}</Table.Cell>
                                        <Table.Cell className="td_green">{item.comingdue}</Table.Cell>
                                        <Table.Cell className="td_yellow">{item.pending}</Table.Cell>
                                        <Table.Cell className="td_red">{item.expired}</Table.Cell>
                                        <Table.Cell className="td_red">{item.requested}</Table.Cell>
                                        <Table.Cell className="td_red">{item.missing}</Table.Cell>
                                        <Table.Cell className="td_red">{item.invalid}</Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                            }
                        </Table>
                        {var_ready && var_requirements[0]['totalrows'] === 0 &&
                        <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t("alert icon")}/>{t("there are no requirements to display")}</div>
                        }
                    </div>
                    <div>
                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_requirements && var_requirements.length > 0 ? var_requirements[0]['totalrows'] : 0} populatefunction={populate_requirements}
                            />
                        </div>
                    </div>
                </div>

                {/***** MODAL: ADD REQUIREMENT ************************************************************************/}

                <Modal id="org-requirements_mdl_addrequirement"
                       dimmer={'inverted'}
                       open={var_mdl_add_open}
                       onClose={() => set_mdl_add_open(false)}
                       closeOnEscape={false}
                       closeOnDimmerClick={false}>
                    <MDL_ADD
                        set_mdl_open={set_mdl_add_open}
                        populate_function={populate_requirements}
                        var_organization_id={auth.actingorganization_id}>
                    </MDL_ADD>
                </Modal>

                {/***** END MODAL: ADD REQUIREMENT ********************************************************************/}

                {/***** MODAL: UPDATE REQUIREMENT ************************************************************************/}

                <Modal id="org-requirements_mdl_updaterequirement"
                       dimmer={'inverted'}
                       open={var_mdl_update_requirement_open}
                       onClose={() => set_mdl_update_requirement_open(false)}
                       closeOnEscape={false}
                       closeOnDimmerClick={false}>
                    <MDL_UPDATE
                        set_mdl_open={set_mdl_update_requirement_open}
                        populate_function={populate_requirements}
                        var_target_id={var_mdl_update_requirement_details.id}>
                    </MDL_UPDATE>
                </Modal>

                {/***** END MODAL: UPDATE REQUIREMENT ********************************************************************/}

            </div>
        </>
    )
}
import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import auth from '../../../../libs/auth-lib';
import { useTranslation } from 'react-i18next';
import '../../../../i18n';

import CRD_DETAILS from "./crd_details/crd_details";
import TAB_ORGCREDENTIALS from './tab_orgcredentials/tab_orgcredentials';

export default function(appProps) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();
    const history = useHistory();

    const [ var_activetab, set_activetab ] = useState('CREDENTIALS');
    const [ var_pageready, set_pageready ] = useState(false);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (auth.is_loggedin && auth.org_wallet) {
            appProps.set_visibleframe(true);
            appProps.set_visiblemenu('ORGANIZATION')
            appProps.set_activemenu('/org/wallet/wallet');
            set_pageready(true);
        } else {
            history.push('/login');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    //  async functions ------------------------------------------------------------------------------------------------

    //  API calls ------------------------------------------------------------------------------------------------------

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_tab(var_tab){
        set_activetab(var_tab);
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            {auth.is_loggedin &&
            <div className='content'>
                <div className='content-topmenu'>
                    <img src={"/icons/wallet 60px (585b5a).svg?ts=" + Date.now()} alt={t('wallet icon')}></img> {JSON.parse(sessionStorage.getItem('authentication')).organization} {t('Wallet')}
                </div>
                <div className='content-wrapper'>
                {var_pageready &&
                <>
                    <div className="statswrapper">
                        <CRD_DETAILS
                            var_pageready={var_pageready}>
                        </CRD_DETAILS>
                    </div>

                    <div className="content-tabs">
                        {(auth.org_credentials) &&
                        <div
                            className={"content-tabs-item " + (var_activetab === 'CREDENTIALS' ? 'content-tabs-item-active' : '')}
                            onClick={() => onClick_tab('CREDENTIALS')}><img src={"/icons/credential 60px (717473).svg?ts=" + Date.now()} alt={t('credential icon')}/>
                            {t('Credentials')}
                        </div>
                        }

                    </div>

                    {auth.org_credentials &&
                    <div className={(var_activetab !== 'CREDENTIALS' ? 'content-card-hide' : '')}>
                        <TAB_ORGCREDENTIALS
                            var_activetab={var_activetab}>
                        </TAB_ORGCREDENTIALS>
                    </div>
                    }
                </>
                }
                </div>
            </div>
            }
        </>
    )
};
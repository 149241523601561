import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import TAB_DETAILS from './tab_details/tab_details';
import TAB_REQUIREMENTS from './tab_requirements/tab_requirements';

import './mdl_update.css';

export default function({ set_mdl_open, target_id, target, populate_function }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_activetab, set_activetab] = useState('DETAILS');

    //  variable listeners ---------------------------------------------------------------------------------------------



    //  async functions ------------------------------------------------------------------------------------------------

    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_tab(var_tab){
        set_activetab(var_tab);
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{target}</div>
                <div className="modal-header-tabs">
                    <div className={"modal-header-tabs-item"  + (var_activetab === 'DETAILS' ? ' active' : '')} onClick={() => onClick_tab(`DETAILS`)}>
                        {t("Details")}
                    </div>
                    <div className={"modal-header-tabs-item"  + (var_activetab === 'REQUIREMENTS' ? ' active' : '')} onClick={() => onClick_tab(`REQUIREMENTS`)}>
                        {t("Requirements")}
                    </div>
                </div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
            </div>
            <div className="modal-tabs">
                <div className={(var_activetab !== 'DETAILS' ? 'modal-tab-hide' : '')}>
                    <TAB_DETAILS
                        set_mdl_open={set_mdl_open}
                        var_activetab={var_activetab}
                        package_id={target_id}
                        populate_function={populate_function}
                    />
                </div>

                <div className={(var_activetab !== 'REQUIREMENTS' ? 'modal-tab-hide' : '')}>
                    <TAB_REQUIREMENTS
                        set_mdl_open={set_mdl_open}
                        var_activetab={var_activetab}
                        package_id={target_id}
                    />
                </div>
            </div>
        </>
    )
}
import React from "react";
import { useTranslation } from 'react-i18next';
import '../../../i18n';

import './cmp_credential_legend.css';


export default function(){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();



    //  variable listeners ---------------------------------------------------------------------------------------------


    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <>
        <div className="legend-footer">
          <div className="legend-item">
            <img src={"/icons/credential 30px (69b536).svg?ts=" + Date.now()} alt={t('pass credential icon')}/>
            <div className="legend-item-text">
              {t(' = Verified credential')}
            </div>
          </div>
          <div className="legend-item">
            <img src={"/icons/credential 30px (4dcbd4).svg?ts=" + Date.now()} alt={t('self verified pass credential icon')}/>
            <div className="legend-item-text">
              {t(' = Self-verified credential')}
            </div>
          </div>
          <div className="legend-item">
            <img src={"/icons/credential 30px (ffae00).svg?ts=" + Date.now()} alt={t('pending icon')}/>
            <div className="legend-item-text">
              {t(' = Credential pending verification')}
            </div>
          </div>
          <div className="legend-item">
            <img src={"/icons/credential 30px (e0454f).svg?ts=" + Date.now()} alt={t('fail credential icon')}/>
            <div className="legend-item-text">
              {t(' = Expired or invalid credential')}
            </div>
          </div>
        </div>
        </>
    )
}
import React, { useState, useEffect } from "react";
import { Button, Form } from 'semantic-ui-react';
import { API } from "aws-amplify";
import { useTranslation } from 'react-i18next';
import '../../../../../../../i18n';

import './mdl_updatestatus.css';

export default function({ set_mdl_open, var_modaltitle, populate_holders, credential_id, var_confirmation_status }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();
    const [ var_confirmation, set_confirmation] = useState([]);
    const [ var_processing, set_processing ] = useState(false);

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        const confirmation = { ...var_confirmation };
        confirmation['confirmation_status'] = var_confirmation_status;
        confirmation['organization_id'] = JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id;
        confirmation['transactionby'] = JSON.parse(sessionStorage.getItem('authentication')).id;
        confirmation['transactionorg'] = JSON.parse(sessionStorage.getItem('authentication')).organization_id;
        set_confirmation(confirmation);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //  async functions ------------------------------------------------------------------------------------------------

    async function update_confirmation() {
        set_processing(true);
        try {
            await API_put_org_confirmation();
            set_mdl_open(false);
            populate_holders();
        } catch (e) {
            console.log(e);
        }
        set_processing(false);
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    async function API_put_org_confirmation(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        await API.put('org-credentials', '/put-org-verification/'+ credential_id, { body: var_confirmation });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_status(e, { value }){
        const confirmation = { ...var_confirmation };
        confirmation['confirmation_status'] = value;
        set_confirmation(confirmation);
    }

    function onClick_updatestatus() {
        update_confirmation();
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{var_modaltitle}</div>
                <div className="modal-header-tabs">
                </div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t('close icon')}/></div>
            </div>
            <div className="modal-content">
                <Form>
                <Form.Group>
                    <Form.Select
                        className='confirmation_status'
                        name='confirmation_status'
                        label={t("VERIFICATION STATUS")}
                        options={[  {text: t('PASS'), value: 'PASS'},
                                        {text: t('VERIFIED'), value: 'VERIFIED'},
                                        {text: t('CLEAR'), value: 'CLEAR'},
                                        {text: t('REJECTED'), value: 'REJECTED'},
                                        {text: t('SUSPENDED'), value: 'SUSPENDED'},
                                        {text: t('REVOKED'), value: 'REVOKED'},
                                        {text: t('WARNING'), value: 'WARNING'},
                                        {text: t('UNDISCLOSED'), value: 'UNDISCLOSED'}]}
                        value={var_confirmation.confirmation_status || ''}
                        onChange={onChange_status}
                        placeholder={t("Select a verification status...")}
                    />
                </Form.Group>
                </Form>
            </div>
            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>{t("CANCEL")}</Button>
                    {!var_processing ?
                        <Button className={"btn_primary btn_active"} onClick={() => onClick_updatestatus()}>{t("UPDATE STATUS")}</Button>
                        :
                        <Button loading className="btn_primary btn_active">{t("UPDATE DETAILS")}</Button>
                    }
                </div>
            </div>
        </>
    )
}
import React, { useEffect } from "react";
import "./all_indv_credentials.css";

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import CRD_ALL_CREDENTIALS from "./crd_all_credentials/crd_all_credentials";


export default function(appProps) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        appProps.set_visibleframe(true);
        appProps.set_visiblemenu('INDIVIDUAL');
        appProps.set_activemenu('/indv/all-credentials');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);



    //  async functions ------------------------------------------------------------------------------------------------

    //  API calls ------------------------------------------------------------------------------------------------------

    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return(
        <>
            {(sessionStorage.getItem('authentication')) &&
            <div className="content">
                <div className="content-topmenu">{t('All Credentials')} </div>
                <div className="content-wrapper">
                    <CRD_ALL_CREDENTIALS></CRD_ALL_CREDENTIALS>

                </div>
            </div>
            }
        </>
    )
};

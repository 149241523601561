import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { Button, Form, Dropdown } from 'semantic-ui-react';
import datelib from '../../../../../libs/date-lib';
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import './mdl_add_individual.css';

export default function({ var_mdl_open, set_mdl_open, requirement_name, targetentity_id, requirement_id, populate_function }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_dd_availableindividuals, set_dd_availableindividuals ] = useState([]);
    const [ var_processing, set_processing] = useState(false);
    const [ var_selected_individual_ids, set_selected_individual_ids ] = useState([]);


    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_mdl_open) {
            set_selected_individual_ids([]);
            populate_dd_availableindvs_meeting_requirement();
        }
    }, [var_mdl_open]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_dd_availableindvs_meeting_requirement(){
        try {
            let results = await API_get_dd_avail_indvs_meeting_requirement();
            set_dd_availableindividuals(results);
        } catch (e) {
            console.log(e);
        }
    }

    async function insert_individuals() {
        set_processing(true);
        try {
            let authentication = JSON.parse(sessionStorage.getItem('authentication'));
            let data = {
                organization_id: authentication.actingorganization_id,
                transactionby: authentication.id,
                transactionorg: authentication.actingorganization_id,
                location_id: targetentity_id,
                requirement_id: requirement_id
            };

            for (let individual_id of var_selected_individual_ids) {
                await API_post_individual({individual_id, ...data});
                await API_post_credentials({individual_id, ...data});
            }

            set_processing(false);
            populate_function();
            set_mdl_open(false);
        } catch (e) {
            console.log(e);
        }

    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_dd_avail_indvs_meeting_requirement() {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-locations', '/get-dd-avail-indvs-meeting-requirement/'+targetentity_id+'/'+requirement_id, {
            queryStringParameters: {
                tz : datelib.timezone             
            }
        });
    }

    function API_post_individual(data) {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-locations', '/post-loc-individual', { body: data });
    }

    function API_post_credentials(data){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('indv-credentials', '/post-loc-credential', { body: data});
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_individuals(e, { value }) {
        set_selected_individual_ids(value);
    }

    function onClick_submit() {
        if (var_selected_individual_ids.length > 0) {
            insert_individuals();
        }
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{t("Add Individuals")} - {requirement_name}</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
            </div>

            <div className="modal-content">
                <div>
                    {t('This allows you to select individual(s) in the organization that meet the ')}
                    {requirement_name}
                    {t(' requirement to add to the location/project')}
                </div>
                <div>
                    <Form>
                        <Form.Select
                            className='individual_ids'
                            name='individual_ids'
                            label={t("INDIVIDUALS TO ADD")}
                            options={var_dd_availableindividuals}
                            value={var_selected_individual_ids}
                            onChange={onChange_individuals}
                            placeholder={t("Select an individual...")}
                            fluid
                            multiple
                            selection
                            search
                        />
                    </Form>
                </div>
            </div>

            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>{t("CANCEL")}</Button>
                    <Button loading={var_processing} className={'btn_primary' + (var_selected_individual_ids.length > 0 ? ' btn_active' : '')} onClick={onClick_submit}>
                        {t(var_selected_individual_ids.length > 1 ? 'ADD INDIVIDUALS' : 'ADD INDIVIDUAL')}
                    </Button>
                </div>
            </div>
        </>
    )
}
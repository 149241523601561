import { API } from "aws-amplify";
import { Button, Form, Input, Search, Message } from 'semantic-ui-react'
import { useEffect, useState } from "react";
import React from "react";
import { useTranslation } from 'react-i18next';
import '../../../i18n';

import './crd_createaccount.css';



export default function({ var_login, set_login, set_login_step, var_location_id, var_qrdata }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_processing, set_processing ] = useState(false);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_companytype, set_companytype ] = useState('ORG');
    const [ var_noresultmessage, set_noresultmessage ] = useState('');
    const [ var_subcontractor, set_subcontractor ] = useState('');
    const [ var_errormessage, set_errormessage ] = useState(null);

    const [ var_company, set_company ] = useState('');
    const [ var_companies, set_companies ] = useState([]);
    const [ var_companysearchresults, set_companysearchresults ] = useState([]);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        populate_subcontractors();
        const login = { ...var_login };
        // if(var_qrdata.subcontractor === 'YES'){
        //     login['individual_type'] = 'USER';
        // }else{
            login['individual_type'] = 'GUEST';
        // }
        if (var_qrdata.subcontractor === 'YES') {
            set_subcontractor('SUBCON');
        }
        login['location_id'] = var_location_id;
        login['company'] = '';
        login['qrphone'] = var_qrdata.qrphone;
        login['organization'] = var_qrdata.organization;
        login['name'] = var_qrdata.name;
        login['subcontractor'] = var_qrdata.subcontractor;
        login['termsofservice'] = var_qrdata.termsofservice;
        login['invitedby'] = var_qrdata.invitedby;
        login['invitedbyemail'] = var_qrdata.invitedbyemail;
        login['organization_id'] = var_qrdata.organization_id;
        set_logo(login, var_qrdata.logo);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(()=> {
        let var_validation = true;

        if (!var_login.firstname || var_login.firstname === '') {
            var_validation = false;
        }

        if (!var_login.lastname || var_login.lastname === '') {
            var_validation = false;
        }

        if (!var_login.email || var_login.email === '') {
            var_validation = false;
        } else {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|([\w-]+(?:\+[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*)|([\w-]+(?:\.[\w-]+)*)([\w-]+(?:\+[\w-]+)*)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(var_login.email)) {
                var_validation = false;
            }
        }

        if (var_login.qrphone === 'YES' && (!var_login.phone || var_login.phone === '')) {
            var_validation = false;
        }

        if (var_login.subcontractor === 'YES' && var_subcontractor === 'SUBCON' && (!var_login.company || var_login.company === '')) {
            var_validation = false;
        }

        set_ready(var_validation);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_login,var_subcontractor]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function set_logo(login, filename) {
        let logo = (filename && !filename.toLowerCase().startsWith('https://') && !filename.toLowerCase().startsWith('http://')) ?
        ((await API.endpoint('storage')) + '/logo/' + login.organization_id + '/' + filename) : filename;
        login['logo'] = logo;
        set_login(login);
    }

    async function populate_subcontractors(){
        try {
            let companies = await API_get_subcontractors();
            set_companies(companies);
        } catch (e) {
            console.log(e);
        }
    }

    async function insert_individual(){
        if(var_ready) {

            set_processing(true);

            //  check status of cognito account if one exists
            let cognito_result = await API_get_cognitoaccount(var_login.email);
            set_processing(false);
            if (cognito_result.userstatus === 'ERROR') {
                set_errormessage(cognito_result.message);
            } else {
                set_login_step(cognito_result.userstatus);
            }
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_subcontractors(){
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.get('org', '/get-dd-subcontractors?organization_id=' + var_qrdata.organization_id);
    }

    function API_get_cognitoaccount(email) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.get('admin', '/get-cognitoaccount?cognitousername=' + encodeURIComponent(email) + '&location_id=' + var_location_id);
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(event){
        set_errormessage(null);
        if(event.target.name==='email'){
            event.target.value=event.target.value.toLowerCase().trim()
        }else{
            event.target.value=event.target.value.trimStart();
        }
        const account = { ...var_login };
        account[event.target.name] = event.target.value;
        set_login(account);
    }

    function onChange_search(e, { value }){
        set_errormessage(null);
        set_company(value);

        const login = { ...var_login };
        login['company'] = value;
        set_login(login);

        if(value.length < 1){
            set_companysearchresults([]);
        }else {
            if(var_companies.filter(function(company){return company.title.toLowerCase().indexOf(value.toLowerCase()) >= 0}).length === 0){
                set_companysearchresults([]);
                set_noresultmessage(value);
            }else{
                set_companysearchresults(var_companies.filter(function(company){return company.title.toLowerCase().indexOf(value.toLowerCase()) >= 0}));
            }

        }
    }

    function onSelect_search(e, { result }){
        set_errormessage(null);
        set_company(result.title);

        const login = { ...var_login };
        login['company'] = result.title;
        set_login(login);
    }


    function onClick_submitaccount(){
        if(var_ready) {
            insert_individual();
        }
    }

    function onClick_backtologin(){
        set_login_step('LOGIN');
    }

    // function onChange_Subcontractor(e, { value }){
    //     if(value === 'ORG'){
    //         const login = { ...var_login };
    //         login['company'] = '';
    //         login['individual_type'] = 'USER';
    //         set_login(login);
    //     }else{
    //         const login = { ...var_login };
    //         login['individual_type'] = 'GUEST';
    //         set_login(login);
    //     }
    //     set_companytype(value)
    // }




    // RENDER APP ======================================================================================================

    return (
        <div className="crd_createaccount">
            {var_login.name &&
            <>
                <div className="logo"><img src={var_qrdata.logo_path} alt={t('Organization logo')}/> </div>
                {var_location_id !== '-' &&
                <div className="locationlabel">{var_qrdata.organization.toUpperCase()} {t('LOCATION/PROJECT')}</div>
                }
                <div className="location">{var_login.name}</div>
            </>
            }

            {var_errormessage &&
            <Message className="warning">
                <div className="message_icon"><img src={"/icons/warning 60px (d91e18).svg?ts=" + Date.now()} alt={t('warning icon')} /></div>
                <div className="message_text_wrapper">{t(var_errormessage)}</div>
            </Message>
            }

            <Form>
                <Form.Group>
                    <Form.Field
                        className='firstname'
                        id='firstname'
                        name='firstname'
                        value={var_login.firstname || ''}
                        control={Input}
                        onChange={onChange_input}
                        label={t('FIRST NAME')}
                        placeholder={t('Your first name...')}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Field
                        className='lastname'
                        id='lastname'
                        name='lastname'
                        value={var_login.lastname || ''}
                        control={Input}
                        onChange={onChange_input}
                        label={t('LAST NAME')}
                        placeholder={t('Your last name...')}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Field
                        className='email'
                        id='email'
                        name='email'
                        value={var_login.email || ''}
                        control={Input}
                        onChange={onChange_input}
                        label={t('EMAIL')}
                        placeholder={t('Your email address...')}
                    />
                </Form.Group>
                {var_login.qrphone === 'YES' &&
                <Form.Group>
                    <Form.Field
                        className='phone'
                        id='phone'
                        name='phone'
                        value={var_login.phone || ''}
                        control={Input}
                        onChange={onChange_input}
                        label={t('PHONE')}
                        placeholder={t('Your phone number...')}
                    />
                </Form.Group>
                }
                {var_login.subcontractor === 'YES' &&
                <Form.Group className="subcontractor">
                    {/*<label>I WORK FOR</label>*/}
                    {/*<Form.Radio*/}
                        {/*id='subcontractor_option'*/}
                        {/*label={var_qrdata.organization}*/}
                        {/*value='ORG'*/}
                        {/*checked={var_companytype === 'ORG'}*/}
                        {/*onChange={onChange_Subcontractor}*/}
                    {/*/>*/}
                    {/*<Form.Radio*/}
                        {/*label='Another Company'*/}
                        {/*value='SUBCON'*/}
                        {/*checked={var_companytype === 'SUBCON'}*/}
                        {/*onChange={onChange_Subcontractor}*/}
                    {/*/>*/}
                    {/*{var_companytype === 'SUBCON' &&*/}
                    {/*<>*/}
                        <label>{t('YOUR COMPANY NAME')}</label>
                        <Form.Group>
                            <Search
                                className='company'
                                id='company'
                                name='company'
                                fluid
                                placeholder={t('Your company name...')}
                                onResultSelect={onSelect_search}
                                onSearchChange={onChange_search}
                                results={var_companysearchresults}
                                value={var_company}
                                noResultsMessage={var_noresultmessage}
                            />
                        </Form.Group>
                    {/*</>*/}
                    {/*}*/}
                </Form.Group>
                }

            {!var_processing ?
                <Button id="btn_submitaccount" className={"btn_primary " + (var_ready ? 'btn_active' : '')} onClick={() => onClick_submitaccount()}>{t('CREATE ACCOUNT')}</Button>
                :
                <Button loading id="btn_submitaccount" className="btn_primary btn_active">{t('LOGIN')}</Button>
            }

                <div className="btn_backtologin" onClick={() => onClick_backtologin()}>{t('Back To Login')}</div>
            </Form>

        </div>
    )

}

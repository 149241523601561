import { API } from "aws-amplify";
import { useEffect, useState} from "react";
import {Button, Checkbox, Dropdown, Form, Input, Modal} from 'semantic-ui-react'
import React from "react";
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import './mdl_response.css';
import MDL_CONFIRMATION from "../../../../../components/cmp_confirmation/cmp_confirmation";



export default function({ set_mdl_open, populate_function, var_item }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_network, set_network ] = useState([]);
    const [ var_ready, set_ready] = useState(true);
    const [ var_processing, set_processing] = useState(false);

    const [ var_mdl_confirmation_open, set_mdl_confirmation_open ] = useState(false);


    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_network.transactionby === undefined){
            const network = {...var_network};
            network['id'] = var_item.id;
            network['organization_id'] = JSON.parse(sessionStorage.getItem('authentication')).organization_id;
            network['transactionby'] = JSON.parse(sessionStorage.getItem('authentication')).id;
            network['transactionorg'] = JSON.parse(sessionStorage.getItem('authentication')).organization_id;
            set_network(network);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_network]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function update_network(var_status) {
        set_processing(true);
        try {
            await API_put_org_network(var_status);
            set_ready(false);
            set_processing(false);
            set_mdl_open(false);
            populate_function();
        } catch (e) {
            console.log(e);
        }

    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_put_org_network(var_status){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.put('org', '/put-org-network/'+var_status, { body: var_network });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_accept(){
        if(var_ready){
            update_network('CONNECTED');
        }
    }

    function onClick_reject(){
        set_mdl_confirmation_open(true);
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{t("Network Request")}</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
            </div>
            <div className="modal-content">
                {var_item.organization+' has invited '+JSON.parse(sessionStorage.getItem('authentication')).actingorganization+' to join their network.'}
            </div>

            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => onClick_reject()}>{t("REJECT")}</Button>
                    {!var_processing ?
                        <Button className={"btn_primary " + (var_ready ? ' btn_active' : '')} onClick={() => onClick_accept()}>{t("ACCEPT")}</Button>
                        :
                        <Button loading className="btn_primary btn_active">{t("ACCEPT")}</Button>
                    }
                </div>
            </div>

            {/***** MODAL: CONFIRMATION *******************************************************************************/}

            <Modal id="mdl_confirmation"
                   dimmer={'inverted'}
                   open={var_mdl_confirmation_open}
                   onClose={() => set_mdl_confirmation_open(false)}>
                <MDL_CONFIRMATION
                    set_mdl_open={set_mdl_confirmation_open}
                    var_modaltitle={t('Reject Network Request')}
                    var_message={t('Are you sure you want to reject the network request from ')+var_item.organization}
                    confirmation_function={()=>update_network('REJECTED')}>
                </MDL_CONFIRMATION>
            </Modal>

            {/***** END MODAL: CONFIRMATION **************************************************************************/}

        </>
    )
}
//  OUTCOMES -----------------------------------------------------------------------------------------------------------
//
//  - displays details of a credential, including the current version as well as historical versions
//  - also allows the user to archive or create a new version
//
//  --------------------------------------------------------------------------------------------------------------------

import React, { useState, useEffect } from 'react';
import { Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import '../../../../i18n';
import auth from '../../../../libs/auth-lib';

import TAB_CURRENT_VERSION from './tab_current_version/tab_current_version';
import TAB_HISTORICAL_VERSIONS from './tab_historical_versions/tab_historical_versions';
import TAB_PREAPPROVALS from './tab_preapprovals/tab_preapprovals';
import TAB_NEW_VERSION from './tab_new_version/tab_new_version';

import './mdl_credential_details.css';

export default function({ var_mdl_open, set_mdl_open, var_target_id, populate_function, credential_type = 'indv' }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();
    const [ var_activetab, set_activetab ] = useState('CURRENT');
    const [ var_credential, set_credential ] = useState(null);

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        set_activetab('');
        set_credential(null);
        var_mdl_open && set_activetab('CURRENT');
    }, [var_mdl_open]);

    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <Modal id='credentials_mdl_credential_details'
            dimmer={'inverted'}
            open={var_mdl_open}
            onClose={() => set_mdl_open(false)}
            closeOnEscape={false}
            closeOnDimmerClick={false}
            className='modal-container'>
            <div className='modal-header'>
                <div className='modal-header-title'>{t('Credential Details')}</div>
                <div className='modal-header-close' onClick={() => set_mdl_open(false)}><img src={'/icons/x 60px (717473).svg?ts=' + Date.now()} alt={t('x icon')} /></div>
            </div>
            {['CURRENT', 'HISTORICAL', 'PREAPPROVALS'].includes(var_activetab) &&
                <div className='modal-tabs'>
                    <div className={'modal-tabs-item' + (var_activetab === 'CURRENT' ? ' active' : '')} onClick={() => set_activetab('CURRENT')}>
                        {t('Current version')}
                    </div>
                    <div className={'modal-tabs-item' + (var_activetab === 'HISTORICAL' ? ' active' : '')} onClick={() => set_activetab('HISTORICAL')}>
                        {t('Credential history')}
                    </div>
                    {auth.has_access('ORG-REQ', 'manage_history') && credential_type === 'indv' && var_credential?.credential_id !== '00000000-0000-0000-0000-000000000000' &&
                    <div className={'modal-tabs-item' + (var_activetab === 'PREAPPROVALS' ? ' active' : '')} onClick={() => set_activetab('PREAPPROVALS')}>
                        {t('Pre-Approvals')}
                    </div>
                    }
                </div>
            }
            {var_activetab === 'CURRENT' &&
                <div className='modal-tab-body'>
                    <TAB_CURRENT_VERSION var_activetab={var_activetab} set_activetab={set_activetab} var_target_id={var_target_id} populate_function={populate_function} credential_type={credential_type} var_credential={var_credential} set_credential={set_credential}/>
                </div>
            }
            {var_activetab === 'HISTORICAL' &&
                <div className='modal-tab-body'>
                    <TAB_HISTORICAL_VERSIONS var_activetab={var_activetab} var_target_id={var_target_id} credential_type={credential_type} />
                </div>
            }
            {var_activetab === 'PREAPPROVALS' &&
                <div className='modal-tab-body'>
                    <TAB_PREAPPROVALS var_activetab={var_activetab} var_target_id={var_target_id} var_credential={var_credential} />
                </div>
            }
            {var_activetab === 'NEW' &&
                <div className='modal-tab-body'>
                    <TAB_NEW_VERSION var_activetab={var_activetab} set_activetab={set_activetab} var_target_id={var_target_id} populate_function={populate_function} credential_type={credential_type} />
                </div>
            }
        </Modal>
    );
}

//  OUTCOMES -----------------------------------------------------------------------------------------------------------
//
//  - allows the user to create a new version of a credential
//
//  --------------------------------------------------------------------------------------------------------------------

import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { Button, Form, Input, Message } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';
import auth from '../../../../../libs/auth-lib';
import datelib from '../../../../../libs/date-lib';
import { enCA, frCA } from 'date-fns/locale';

import CMP_FILEUPLOAD from '../../../../../components/cmp_fileupload/cmp_fileupload';

import './tab_new_version.css';

export default function({ var_activetab, set_activetab, var_target_id, populate_function, credential_type }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();
    const [ var_credential, set_credential ] = useState(null);
    const [ var_issueddate, set_issueddate ] = useState(null);
    const [ var_expirydate, set_expirydate ] = useState(null);
    const [ var_credential_number, set_credential_number ] = useState(null);
    const [ var_dirty, set_dirty ] = useState(false);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_validation_errors, set_validation_errors ] = useState([]);
    const [ var_issuer_verifierAPIs, set_issuer_verifierAPIs ] = useState(null);

    
    const [ var_fileuploadstatus, set_fileuploadstatus ] = useState('');
    const [ var_fileuploadname, set_fileuploadname ] = useState(null);
    const [ var_fileuploaddisplayname, set_fileuploaddisplayname ] = useState(null);
    const [ var_ready, set_ready ] = useState(true);
    const [ var_fileupload, set_fileupload ] = useState(null);
    const [ var_multiple_files, set_multiple_files ] = useState(false);

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        set_credential(null);
        var_activetab === 'NEW' && var_target_id && populate_credential();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=>{
        if(var_fileuploadstatus === 'COMPLETE') {
            update_credential();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_fileuploadstatus]);

    useEffect(() => {
        if (var_fileupload) {
            set_dirty(true);
        }
    }, [var_fileupload]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_credential() {
        let results;
        if (credential_type === 'indv'){
            results = await API_get_indv_credential();
            set_credential(results[0]);

            if (results[0].issuer_id) {
                let issuer_verifier_results = await API_get_issuer_verifier_apis(results[0].issuer_id);
                issuer_verifier_results.length > 0 && set_issuer_verifierAPIs(issuer_verifier_results[0]);
            }

        } else {
            results = await API_get_org_credential();
            set_credential(results);

            if (results.issuer_id) {
                let issuer_verifier_results = await API_get_issuer_verifier_apis(results.issuer_id);
                issuer_verifier_results.length > 0 && set_issuer_verifierAPIs(issuer_verifier_results[0]);
            }
        }
        set_dirty(false);
        set_validation_errors([]);
    }

    async function update_credential() {
        set_processing(true);
        try {
            let data = {
                issuedepoch: var_issueddate === null ? null : datelib.localdate_to_utc_midnight_epoch(var_issueddate),
                expiresepoch: var_expirydate === null ? null : datelib.localdate_to_utc_midnight_epoch(var_expirydate),
                credential_number: (var_credential_number && var_credential_number.trim().length > 0) ? var_credential_number.trim() : null,
                ...(var_fileupload ? { 
                        filename: var_fileuploadname,
                        submittedfilename: var_multiple_files ? var_credential.credential + '.pdf' : var_fileuploaddisplayname
                    } : {}),
                confirmation_status: var_credential.confirmation_status,
                organization_id: auth.actingorganization_id,
                transactionby: auth.id,
                transactionorg: auth.organization_id
            };
            if (var_issuer_verifierAPIs && var_issuer_verifierAPIs.API_verifier === 'YES') {
                await API_put_indv_credential_external({ ...var_credential, ...data });
            } else {
                credential_type === 'indv' ? await API_put_indv_credential(data) : await API_put_org_credential({...data, organizationcredential_id: var_target_id, id: var_credential.id});
            }
            set_dirty(false);
            set_processing(false);
            populate_function();
            set_activetab('CURRENT');
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_indv_credential() {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.get('indv-credentials', '/get-indv-credential/' + var_target_id,
        {
            queryStringParameters: {
                tz : datelib.timezone
            }
        });
    }

    function API_get_org_credential() {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.get('org-orgcredentials', '/get-org-credential/' + var_target_id,
        {
            queryStringParameters: {
                tz : datelib.timezone
            }
        });
    }

    function API_get_issuer_verifier_apis(issuer_id) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.get('org-credentials', '/th-get-issuer-api/' + issuer_id);
    }

    function API_put_indv_credential(data) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.put('indv-credentials', '/put-indv-credential/' + var_target_id, { body: data});
    }

    function API_put_org_credential(data){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.put('org-orgcredentials', '/put-org-credential', { body: data });
    }

    function API_put_indv_credential_external(data) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.put(var_issuer_verifierAPIs.API_service, '/' + var_issuer_verifierAPIs.API_put + '/' + var_target_id, { body: data });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_issueddate(value) {
        if (value === var_issueddate) return;

        set_issueddate(value);
        if (value !== null && var_credential.expires === 'YES') {
            set_expirydate(
                datelib.utcmoment_to_local_midnight_date(
                    datelib.localdate_to_utc_midnight_moment(value).add(var_credential.expires_amount, var_credential.expires_unit + 's')
                )
            );
        }
        set_dirty(true);
        set_validation_errors([]);
    }

    function onChange_expirydate(value) {
        if (value === var_expirydate) return;

        set_expirydate(value);
        set_dirty(true);
        set_validation_errors([]);
    }

    function onChange_credential_number(value) {
        if (value === var_credential_number) return;

        set_credential_number(value);
        set_dirty(true);
        set_validation_errors([]);
    }

    function onChange_multiple_files() {
        set_fileupload(null);
        set_fileuploaddisplayname(null);
        set_fileuploadname(null);
        set_multiple_files(!var_multiple_files);
    }

    function onClick_save() {
        if (!var_ready || !var_dirty || var_processing) return;

        // validation
        let errors = [];
        if (var_credential.issuedrequired === 'YES' && var_issueddate === null) {
            errors.push('Please enter an issued date');
        }
        if (var_credential.expires === 'YES' && var_expirydate === null) {
            errors.push('Please enter an expires date');
        }
        if (var_credential.has_credential_number === 'YES' && var_credential.credential_number_required === 'YES' && (!var_credential_number || var_credential_number.trim().length === 0)) {
            errors.push('Please enter the ' + (var_credential.credential_number_label || 'credential number').toLowerCase());
        }
        set_validation_errors(errors);
        if (errors.length > 0) return;

        if (var_fileupload) {
            if (var_fileuploadstatus === 'PENDING') {
                set_fileuploadstatus('START');
                set_processing(true);
            } else {
                update_credential();
            }
        } else {
            update_credential();
        }
    }

    // RENDER APP ======================================================================================================

    return var_credential === null ? null : (
        <>
            <div className='new-version'>
                <div className='title'>{t('Create new version')}</div>
                <div className='title-description'>{t('Update the information below to create a new version of this credential')}</div>
                {var_validation_errors.length > 0 &&
                    <Message error list={var_validation_errors} />
                }
                {var_credential.code &&
                    <div className='detail-row'>
                        <div className='detail-label'>{t('Code')}:</div>
                        <div className='detail-value'>{var_credential.code}</div>
                    </div>
                }
                <div className='detail-row'>
                    <div className='detail-label'>{t('Credential')}:</div>
                    <div className='detail-value'>{var_credential.credential}</div>
                </div>
                <div className='detail-row'>
                    <div className='detail-label'>{t('Issued by')}:</div>
                    <div className='detail-value'>{var_credential.issuedby}</div>
                </div>
                <Form>
                    <div className='input-row'>
                        <div className='detail-label'>{t('Issued')}:</div>
                        <div className='detail-input'>
                            <Form.Group className="date_fields">
                                <SemanticDatepicker
                                    locale={JSON.parse(sessionStorage.getItem('authentication')).language.datepicker}
                                    onChange={(event, data) => onChange_issueddate(data.value)}
                                    format={auth.language.name === 'French' ? 'D MMM YYYY' : 'MMM DD, YYYY'}
                                    formatOptions={{locale: auth.language.name === 'French' ? frCA: enCA}}
                                    clearable={false}
                                    datePickerOnly={true}
                                    showToday={false}
                                    maxDate = {var_expirydate}
                                    placeholder={t('Select date')}
                                    value={var_issueddate}
                                    />
                            </Form.Group>
                        </div>
                    </div>
                    {(var_credential.expires === 'YES' || var_credential.confirmation_status === 'SELF VERIFIED')  &&
                        <div className='input-row'>
                            <div className='detail-label'>{t('Expires')}:</div>
                            <div className='detail-input'>
                                <Form.Group className="date_fields">
                                    <SemanticDatepicker
                                        locale={JSON.parse(sessionStorage.getItem('authentication')).language.datepicker}
                                        onChange={(event, data) => onChange_expirydate(data.value)}
                                        format={auth.language.name === 'French' ? 'D MMM YYYY' : 'MMM DD, YYYY'}
                                        formatOptions={{locale: auth.language.name === 'French' ? frCA: enCA}}
                                        clearable={false || var_credential.confirmation_status === 'SELF VERIFIED'}
                                        datePickerOnly={true}
                                        showToday={false}
                                        minDate = {var_issueddate}
                                        placeholder={t('Select date')}
                                        value={var_expirydate}
                                        />
                                </Form.Group>
                            </div>
                        </div>
                    }
                    {(var_credential.has_credential_number === 'YES' || var_credential.confirmation_status === 'SELF VERIFIED') &&
                        <div className='input-row'>
                            <div className='detail-label'>{t(var_credential.credential_number_label || 'Credential number')}:</div>
                            <div className='detail-input'>
                                <Form.Group>
                                    <Form.Field
                                        id='credential_number'
                                        name='credential_number'
                                        value={var_credential_number || ''}
                                        control={Input}
                                        onChange={(event, data) => onChange_credential_number(data.value)}
                                        placeholder={t(var_credential.credential_number_label || 'Credential number')}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    }
                </Form>
                <div className='document-title-row'>
                    <div className='document-title'>{t('Document')}</div>
                    <div className='document-title-description'>{t('(Please include all pages, front and back, if applicable.)')}</div>
                </div>
                <div className='document-component'>
                    <CMP_FILEUPLOAD
                        var_fileuploaddirectory={credential_type === 'indv' ? 'certificate' : 'orgcredential'}
                        var_fileuploadlabel={null}
                        var_fileuploadstatus={var_fileuploadstatus}
                        set_fileuploadstatus={set_fileuploadstatus}
                        var_fileuploadname={var_fileuploadname}
                        set_fileuploadname={set_fileuploadname}
                        var_fileuploaddisplayname={var_fileuploaddisplayname}
                        set_fileuploaddisplayname={set_fileuploaddisplayname}
                        var_placeholder={var_credential.submittedfilename || var_credential.filename || t('No file selected...')}
                        var_filetype={(var_multiple_files ? '' : 'application/pdf, ') + 'image/png, image/jpg, image/jpeg'}
                        var_fileextension={var_multiple_files ? ['png','jpg','jpeg'] : ['pdf','png','jpg','jpeg']}
                        var_filesize={'10'}
                        var_ready={var_ready}
                        set_ready={set_ready}
                        var_fileupload={var_fileupload}
                        set_fileupload={set_fileupload}
                        var_multiple_files={var_multiple_files}
                    />
                </div>
                <div className='document-footer'>{t('Only ' + (var_multiple_files ? '' : 'PDF, ') + 'JPG and PNG file types are accepted.')}</div>
                <Button className='btn_tertiary' onClick={onChange_multiple_files}>{var_multiple_files ? t('I want to upload one file') : t('I want to upload multiple files')}</Button>
            </div>
            <div className='modal-footer-button-bar'>
                <Button className={'btn_primary' + (var_ready && var_dirty ? ' btn_active' : '')} loading={var_processing} onClick={onClick_save}>{t('SAVE NEW VERSION')}</Button>
                <Button className='btn_secondary' onClick={() => set_activetab('CURRENT')}>{t('CANCEL')}</Button>
            </div>
        </>
    );
}
//  OUTCOMES -----------------------------------------------------------------------------------------------------------
//
//  - Assigns requirements to an individual including adding a credential to the requirements' pre-approved list 
//
//  --------------------------------------------------------------------------------------------------------------------

import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { Button, Table, Form, Modal } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';
import auth from '../../../../../libs/auth-lib';
import datelib from '../../../../../libs/date-lib';

import CMP_CONFIRMATION from '../../../../../components/cmp_confirmation/cmp_confirmation';
import CMP_SUCCESS from '../../../../../components/cmp_success/cmp_success';


import './mdl_assign_requirement.css';

export default function({ set_mdl_add_credential_open, var_credential, var_credential_in_preapproved, var_requirements, var_individual_id }) {

    //  variable declarations ----, --------------------------------------------------------------------------------------

    const { t } = useTranslation();

    const [ var_ready, set_ready ] = useState(false);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_dd_requirements, set_dd_requirements ] = useState([]);
    const [ var_selectedrequirements, set_selectedrequirements ] = useState([]);
    const [ var_finalrequirements, set_finalrequirements ] = useState([]);
    const [ var_mdl_success_requirements_open, set_mdl_success_requirements_open ] = useState(false);

    //  variable listeners ---------------------------------------------------------------------------------------------

    //  async functions ------------------------------------------------------------------------------------------------
    async function populate_requirements() {
        if (var_requirements && var_requirements.length > 0) {
            if (var_credential_in_preapproved) {
                let requirements_credential_in_preapproved = var_requirements
                    .filter(item => item.total_in_preapproved > 0)
                    .map(item => ({ value: item.id, text: item.requirement }));
                set_dd_requirements(requirements_credential_in_preapproved);
            } else {
                set_dd_requirements(var_requirements.map(item => ({ value: item.id, text: item.requirement })));
            }
            set_ready(true);
        }
    }    
 
    async function add_requirement_historical(requirement_id) {
        set_processing(true);
        try {
            await API_post_requirement_historical(requirement_id);           
        } catch (e) {
            console.log(e);
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_requirement_historical(requirement_id) {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-requirements','/post-requirement-historical',
         {
            queryStringParameters: {
                tz : datelib.timezone
            },
            body: {
                requirement_id: requirement_id,
                issuer_id: var_credential.issuer_id,
                credential_id: var_credential.credential_id,
                status: 'VALID',
                transactionorg: auth.actingorganization_id
            }
        });
    }

    function API_post_indv_requirement_assignment(data){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('indv-credentials', '/post-indv-requirement-assignment', { body: data});
    }

    function API_post_indv_requirement(data){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('indv-credentials', '/post-indv-requirement', { body: data});
    }


    //  event functions ------------------------------------------------------------------------------------------------

    async function onChange_requirements(e, { value }){
        let selected_requirements = [];
        for (let requirement_id of value) {
            let requirement_name = var_requirements.find(item => item.id === requirement_id)?.requirement;
            selected_requirements.push({ id: requirement_id, name: requirement_name });
        }
        set_finalrequirements(selected_requirements);
        set_selectedrequirements(value);      
    }

    async function onClick_assign_requirements() {
        set_processing(true);
 
        // Iterate through each requirement, adding it to the individual                         
        try {
            for (let requirement of var_finalrequirements) {
                // Add the credential to the requirement's pre-approved list if not in pre-approved list
                if (!var_credential_in_preapproved) {
                    await add_requirement_historical(requirement.id);
                }

                let data = {};                 
                data['requirement_id'] = requirement.id;
                data['targetentity'] = 'ORGANIZATION';
                data['targetentity_id'] = auth.actingorganization_id;
                data['individual_id'] = var_individual_id;
                data['organization_id'] = auth.actingorganization_id;
                data['transactionby'] = auth.id;
                data['transactionorg'] = auth.actingorganization_id;

                let count_indv_requirement = var_requirements.find(item => item.id === requirement.id)?.count_indv_requirement;
                if (count_indv_requirement === 0) {
                    await API_post_indv_requirement_assignment(data);
                    await API_post_indv_requirement(data);
                }
            }
            set_selectedrequirements([]);
            set_mdl_success_requirements_open(true);
        } catch (e) {
            console.log(e);
        }             
        set_processing(false);       
    }
    

    // RENDER APP ======================================================================================================

    return (
        <>{var_ready ? render_form() : render_confirmation()}</>
    );

    function render_form() {
        let select_instr;
        let modal_title;
        let button_action;
        let selected_requirements = var_finalrequirements.map(item => item.name).join(',');
        let success_message = `${selected_requirements} ${t('have been assigned to the individual.')}`;    

        if (var_credential_in_preapproved) {
            select_instr = t('Select requirement(s) you want to assign to this individual');
            modal_title = t('Assign Requirement');
            button_action = t('ADD REQUIREMENT');        
        } else {
            select_instr = `${t('Select requirement(s) you want to add')} ${var_credential.name}
                            ${t('as a pre-approved credential and assign the requirements to the individual')}`;
            modal_title = t('Add Credential');
            button_action = t('ADD CREDENTIAL');
            success_message = `${var_credential.name} ${t('has been added to the')} ${selected_requirements} ${t('pre-approval accepted lists.')} ${success_message}`;          
        }

        return (
        <>
            <div>
                <div className="modal-header">
                    <div className="modal-header-title">{modal_title}</div>
                </div>   
                <div className='modal-content'>
                    <div>{select_instr}</div>
                    <Form>
                        <Form.Group>
                            <Form.Select
                                className='select_field'
                                id="requirements"
                                name="requirements"
                                label={t('REQUIREMENT')}
                                placeholder={t('Requirements...')}
                                options={var_dd_requirements}
                                onChange={onChange_requirements}
                                value={var_selectedrequirements || []}
                                fluid
                                multiple
                                search
                            />
                        </Form.Group>
                    </Form>
                    <div className='modal-footer-buttons'>
                        <Button className="btn_secondary" onClick={() => set_mdl_add_credential_open(false)}>{t('CANCEL')}</Button>
                        <Button disabled={var_selectedrequirements.length === 0} loading={var_processing} className={'btn_primary' + (var_selectedrequirements.length > 0 ? ' btn_active' : '')}
                                onClick={onClick_assign_requirements}>{button_action}</Button>
                    </div>
                </div>
            </div>

            {/***** MODAL: Success *******************************************************************************/}

            <Modal
                id="mdl_requirements_info"
                dimmer={'inverted'}
                open={var_mdl_success_requirements_open}
                onClose={() => set_mdl_success_requirements_open(false)}>
                <CMP_SUCCESS
                    title={t('Requirements Assigned')}
                    set_mdl_open={set_mdl_add_credential_open}
                    success_or_fail='SUCCESS'
                    body={success_message}
                />
            </Modal>

            {/***** END MODAL: INFO **************************************************************************/}        
        </>
        )
    }

    function render_confirmation() {
        let message = var_credential_in_preapproved ?     
            `${var_credential.name} ${t("is a pre-approved credential for a requirement(s). Do you want to assign the requirement(s) to the individual?")}`
            :
            `${var_credential.name} ${t("is not a pre-approved credential for any requirement. Do you want to add the credential to a requirement's pre-approved list and assign the requirement to the individual?")}`;

        return (  
                <CMP_CONFIRMATION set_mdl_open={set_mdl_add_credential_open} var_modaltitle={t('Add Requirement')}
                    var_message={message} confirmation_function={populate_requirements} var_call_to_function={true} success_function={() => {set_ready(true);}}
                    cancel_function={() => { set_mdl_add_credential_open(false) }} />
           );
    }
}
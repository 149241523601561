import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import {Button, Checkbox, Form, Input} from "semantic-ui-react";
import React from "react";
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';
import auth from '../../../../../libs/auth-lib';

import './mdl_add.css';



export default function({ set_mdl_open, populate_function }){

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_questionnaire, set_questionnaire ] = useState([]);
    const [ var_failwarning, set_failwarning ] = useState(false);
    const [ var_notify_checkpass, set_notify_checkpass ] = useState(false);
    const [ var_notify_checkfail, set_notify_checkfail ] = useState(false);
    const [ var_guestconsent, set_guestconsent ] = useState(false);
    const [ var_notify_consentfail, set_notify_consentfail ] = useState(false);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_processing, set_processing] = useState(false);
    const [ var_required_errors, set_required_errors] = useState([]);
    const [var_required_field,set_required_field] = useState(false)
    const { t } = useTranslation();



    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_questionnaire.transactionby === undefined){
            const questionnaire = { ...var_questionnaire };
            questionnaire['stat_passtext'] = 'COME TO SITE';
            questionnaire['stat_failtext'] = 'STAY HOME';
            questionnaire['organization_id'] = JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id;
            questionnaire['transactionby'] = JSON.parse(sessionStorage.getItem('authentication')).id;
            questionnaire['transactionorg'] = JSON.parse(sessionStorage.getItem('authentication')).organization_id;
            questionnaire['failwarning'] = 'NO';
            questionnaire['notify_checkpass'] = 'NO';
            questionnaire['notify_checkfail'] = 'NO';
            questionnaire['guestconsent'] = 'NO';
            questionnaire['notify_consentfail'] = 'NO';
            set_questionnaire(questionnaire);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_questionnaire]);

    useEffect(() => {
        const questionnaire = {...var_questionnaire};
        questionnaire['failwarning'] =  var_failwarning ? 'YES' : 'NO';
        set_questionnaire(questionnaire);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_failwarning]);
    
    useEffect(() => {
        const questionnaire = {...var_questionnaire};
        questionnaire['notify_checkpass'] = var_notify_checkpass ? 'YES' : 'NO';
        set_questionnaire(questionnaire);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_notify_checkpass]);

    useEffect(() => {
        const questionnaire = {...var_questionnaire};
        questionnaire['notify_checkfail'] = var_notify_checkfail ? 'YES' : 'NO';
        set_questionnaire(questionnaire);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_notify_checkfail]);

    useEffect(() => {
        const questionnaire = {...var_questionnaire};
        questionnaire['guestconsent'] = var_guestconsent ? 'YES' : 'NO';
        set_questionnaire(questionnaire);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_guestconsent]);

    useEffect(() => {
        const questionnaire = {...var_questionnaire};
        questionnaire['notify_consentfail'] = var_notify_consentfail ? 'YES' : 'NO';
        set_questionnaire(questionnaire);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_notify_consentfail]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function insert_questionnaire() {
        try {
            await API_post_org_questionnaire();
            set_ready(false);
            set_processing(false);
            set_mdl_open(false);
            populate_function();
        } catch (e) {
            console.log(e);
        }

    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_org_questionnaire(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-dailychecks', '/post-org-questionnaire', { body: var_questionnaire });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(event){
        const questionnaire = { ...var_questionnaire };
        questionnaire[event.target.name] = event.target.value;
         if(var_required_field===true){
            error_messages(event.target.name)
          }
        set_questionnaire(questionnaire);
        set_ready(true);

    }

    function onChange_failwarning(){
        set_failwarning(!var_failwarning);
        set_ready(true);
    }

    function onChange_notify_checkpass(){
        set_notify_checkpass(!var_notify_checkpass);
        set_ready(true);
    }

    function onChange_notify_checkfail(){
        set_notify_checkfail(!var_notify_checkfail);
        set_ready(true);
    }

    function onChange_guestconsent(){
        set_guestconsent(!var_guestconsent);
        set_ready(true);
    }

    function onChange_notify_consentfail(){
        set_notify_consentfail(!var_notify_consentfail);
        set_ready(true);
    }

    function onClick_submit(){

        if(var_ready){
            if((var_questionnaire.name === ''||var_questionnaire.name ===  undefined ) || var_questionnaire.stat_failtext==='' || var_questionnaire.stat_passtext ===''){
                 set_required_field(true)
                 error_check();
            }else{
            set_processing(true);
            insert_questionnaire();
            }
        }
    }

    function error_check(){
        const errors = [];
        set_ready(false);

        if (var_questionnaire.name ===''||var_questionnaire.name === undefined  ) {
            errors.push({error: "Please provide a name for this questionnaire"});
            set_required_errors(errors)

        }
        if (var_questionnaire.stat_passtext ==='' || var_questionnaire.stat_passtext === undefined){
            errors.push({error: "Please provide the passed daily check text to display in the individual's wallet"});
            set_required_errors(errors)
        }
        if (var_questionnaire.stat_failtext==='' || var_questionnaire.stat_failtext === undefined){
            errors.push({error: "Please provide the failed daily check text to display in the individual's wallet"});
            set_required_errors(errors)
        }

    }

    function error_messages(target){
        const error =var_required_errors;

         if(target==="name"){
            for (var key in error) {
                 if( error[key].error ==="Please provide a name for this questionnaire"){
                 error.splice(key, 1)
                 set_required_errors(error)
                 }
            }
         }

         if(target==="stat_passtext"){
            for (key in error) {
                 if( error[key].error ==="Please provide the passed daily check text to display in the individual's wallet"){
                 error.splice(key, 1)
                 set_required_errors(error)
                }
            }
         }

         if(target==="stat_failtext"){
            for (key in error) {
                 if( error[key].error ==="Please provide the failed daily check text to display in the individual's wallet"){
                  error.splice(key, 1)
                  set_required_errors(error)
                 }
            }
         }
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{t('Add Questionnaire')}</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
            </div>
            <div className="modal-content">
                <Form>
                    <Form.Group>
                        <Form.Field
                            className='name'
                            name='name'
                            value={var_questionnaire.name || ''}
                            control={Input}
                            onChange={onChange_input}
                            label={t('QUESTIONNAIRE NAME')}
                            placeholder={t('Questionnaire name...')}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Checkbox
                            toggle
                            className={"failwarning " + (var_failwarning ? ' toggle_active' : '')}
                            label={t('Fail Warning')}
                            onChange={onChange_failwarning}
                            checked={var_failwarning || false}
                        />
                    </Form.Group>
                    {var_failwarning &&
                    <Form.Group>
                        <Form.TextArea
                            className='failwarningtext'
                            name='failwarningtext'
                            value={var_questionnaire.failwarningtext || ''}
                            onChange={onChange_input}
                            label={t('FAIL WARNING TEXT')}
                            placeholder={t('Fail warning text...')}
                        />
                    </Form.Group>
                    }
                    <Form.Group>
                        <Checkbox
                            label={t('Send email on passed daily check?')}
                            onChange={onChange_notify_checkpass}
                            checked={var_notify_checkpass}
                            toggle
                        />
                    </Form.Group>
                    <Form.Group>
                        <Checkbox
                            label={t('Send email on failed daily check?')}
                            onChange={onChange_notify_checkfail}
                            checked={var_notify_checkfail}
                            toggle
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                        className='stat_passtext'
                        name='stat_passtext'
                        value={var_questionnaire.stat_passtext || ''}
                        control={Input}
                        onChange={onChange_input}
                        label={t('PASSED DAILY CHECK WALLET TEXT (MAX 20 CHARACTERS)')}
                        placeholder='COME TO SITE'
                        maxLength="20"
                            />
                    </Form.Group>
                    <Form.Group>
                    <Form.Field
                        className='stat_failtext'
                        name='stat_failtext'
                        value={var_questionnaire.stat_failtext || ''}
                        control={Input}
                        onChange={onChange_input}
                        label={t('FAILED DAILY CHECK WALLET TEXT (MAX 20 CHARACTERS)')}
                        placeholder='STAY HOME'
                        maxLength="20"
                    />
                    </Form.Group>
                    {auth.org_guest && 
                    <Form.Group>
                        <Checkbox
                            label={t('Prompt for Guest Consent?')}
                            onChange={onChange_guestconsent}
                            checked={var_guestconsent}
                            toggle
                        />
                    </Form.Group>
                    }
                    {var_guestconsent &&
                        <Form.Group>
                            <Form.TextArea
                                className='guestconsenttext'
                                name='guestconsenttext'
                                value={var_questionnaire.guestconsenttext || ''}
                                onChange={onChange_input}
                                label={t('GUEST CONSENT TEXT')}
                                placeholder={t('Guest consent text...')}
                            />
                        </Form.Group>
                    }
                    {var_guestconsent &&
                        <Form.Group>
                            <Checkbox
                                label={t('Send email on failed consent?')}
                                onChange={onChange_notify_consentfail}
                                checked={var_notify_consentfail}
                                toggle
                            />
                        </Form.Group>
                    }
                </Form>

                {var_required_field=== true && var_required_errors.length >0 &&
                    <div id ="reqfielderror" className="message warning">
                        <div className="message_icon"><img src={"/icons/warning 60px (d91e18).svg?ts=" + Date.now()} alt={t('warning icon')} /></div>
                        <div className="message_text_wrapper">
                        {var_required_errors.map((message, i) => (
                            <div className="message_text cause" key={i}>{t(message.error)}</div>
                        ))}
                        </div>
                    </div>
                }

            </div>
            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>{t('CANCEL')}</Button>
                    {!var_processing ?
                        <Button className={"btn_primary " + (var_ready ? ' btn_active' : '')} onClick={() => onClick_submit()}>{t('ADD QUESTIONNAIRE')}</Button>
                        :
                        <Button loading className="btn_primary btn_active">{t('ADD QUESTIONNAIRE')}</Button>
                    }
                </div>
            </div>
        </>
    )
}

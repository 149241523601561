import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import {Table, Modal, Button} from 'semantic-ui-react';
import datelib from '../../../../../../libs/date-lib';
import { useTranslation } from 'react-i18next';
import '../../../../../../i18n';
import auth from '../../../../../../libs/auth-lib';

import CMP_LEGEND from '../../../../../../components/cmp_legend/cmp_forms_legend/cmp_forms_legend';
import CMP_TABLECONTROLS, { filter_helper } from '../../../../../../components/cmp_tablecontrols/cmp_tablecontrols';
import MDL_MANAGE from './mdl_manage/mdl_manage';
import MDL_ASSIGN from './mdl_assign/mdl_assign';
import MDL_DOWNLOAD from './mdl_download/mdl_download';
import MDL_DOCVIEWER from '../../../../../../components/cmp_docviewer/cmp_docviewer';
import MDL_UPDATE from '../../../../../org-forms/forms/crd_active_forms/mdl_update/mdl_update';
import MDL_CONFIRMATION from '../../../../../../components/cmp_confirmation/cmp_confirmation';

import './tab_forms.css';



export default function({ var_activetab, var_location_id, var_location, var_org_owns_location }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_forms, set_forms ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(10);
    const [ var_offset, set_offset] = useState(0);
    const [ var_sortby, set_sortby ] = useState('assignedepoch');
    const [ var_sortorder, set_sortorder ] = useState('descending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));
    const [ var_closed, set_closed ] = useState(false);

    const [ var_mdl_manage_open, set_mdl_manage_open ] = useState(false);
    const [ var_mdl_assign_open, set_mdl_assign_open ] = useState(false);
    const [ var_mdl_download_open, set_mdl_download_open ] = useState(false);

    const [ var_mdl_docviewer_open, set_mdl_docviewer_open ] = useState(false);
    const [ var_mdl_update_open, set_mdl_update_open ] = useState(false);
    const [ var_docviewerid, set_docviewerid ] = useState(null);
    const [ var_docviewer_review, set_docviewer_review ] = useState(false);
    const [ var_form_to_update ,set_form_to_update] = useState(null);

    const [ var_mdl_confirmation_open, set_mdl_confirmation_open ] = useState(false);
    const [ var_target_id, set_target_id ] = useState(false);
    const [ var_target_text, set_target_text ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_activetab === 'FORMS') {
            set_filter(filter_helper.initialize(false));
        }
    },[var_activetab]);

    useEffect(()=>{
        if(var_filter.load && var_activetab === 'FORMS'){
            populate_forms(null,0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_filter]);

    useEffect(()=>{
        if(var_closed !== undefined) {
            set_filter(filter_helper.initialize(false));
        }
    },[var_closed]);

    useEffect(()=>{
        populate_forms(null,0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_update_open]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_forms(limit,offset,sortby,sortorder,closed,filter){
        if (var_activetab !== 'FORMS') return; // Don't load data if tab is not visible

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        filter = filter ? filter : var_filter;

        if(closed){
            set_closed(closed);
        } else {
            closed = var_closed;
        }

        try {
            let results = await API_get_loc_activeforms(limit,offset,sortby,sortorder,closed,filter);
            set_forms(results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername,sortorder){
        try {
            return await API_get_loc_activeforms(filter_helper.maxFilterItems,0,filtername,sortorder,var_closed,{},filtername);
        } catch (e) {
            console.log(e);
        }
    }

    async function download_forms(limit,offset){
        try {
            return await API_get_loc_activeforms(limit,offset,var_sortby,var_sortorder,var_closed,var_filter);
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    async function delete_form(){
        try {
            await API_delete_form();
            populate_forms();
        } catch (e) {

        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_loc_activeforms(limit,offset,sortby,sortorder,closed,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-forms',
            '/get-loc-activeforms/' + var_location_id,
            {
                queryStringParameters: {
                    limit : limit,
                    offset : offset,
                    sortby : sortby,
                    sortorder: sortorder,
                    tz : datelib.timezone,
                    closed : closed,
                    filtername : filtername
                },
                body : filter
            }
        );
    }

    function API_delete_form(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.del('org-forms', '/delete-activeform/' + var_target_id, { 
            body: {
                location_id: var_location_id,
                organization_id: auth.organization_id,
                transactionby: auth.id,
                transactionorg: auth.organization_id
            }
        });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_forms('',0,sortby,sortorder)
    }

    function onOpen_mdl_assignform(){
        set_mdl_assign_open(true);
    }

    function onOpen_mdl_manageforms(){
        set_mdl_manage_open(true);
    }

    function onClick_paperclip(event, id, review = false) {
        event.stopPropagation();
        set_docviewerid(id);
        set_docviewer_review(review);
        set_mdl_docviewer_open(true);
    }

    function open_mdl_update(item){
        if (var_org_owns_location) {
            set_form_to_update(item);
            set_mdl_update_open(true);
        }
    }

    function onClick_removeform(event, id, text){
        event.stopPropagation();
        set_target_id(id);
        set_target_text(text);
        set_mdl_confirmation_open(true);
    }

    // RENDER APP ======================================================================================================

    return (
        <div className="modal-tab" id="tab_forms">
            <div className="content-filter">
                <div className="content-filter-item content-filter-archive">
                    {var_closed ?
                        <Button className="btn_tertiary" onClick={() => set_closed(!var_closed)}><img className="btn_icon" src={"/icons/form 60px (29719a).svg?ts=" + Date.now()} alt={t("form icon")}/>{t("VIEW ACTIVE FORMS")}</Button>
                        :
                        <Button className="btn_tertiary" onClick={() => set_closed(!var_closed)}><img className="btn_icon" src={"/icons/form 60px (29719a).svg?ts=" + Date.now()} alt={t("form icon")}/>{t("VIEW CLOSED FORMS")}</Button>
                    }
                </div>
                <div className="content-filter-item item-pagination">
                    <CMP_TABLECONTROLS
                        var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                        total_rows={var_forms && var_forms.length > 0 ? var_forms[0]['totalrows'] : 0}  populatefunction={populate_forms}
                        downloadname='Forms' downloadfunction={download_forms}
                        var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                        table_config={[
                            {name: 'formname', datatype: 'text', labelKey: 'FORM', download: true, filter: true},
                            ...var_org_owns_location ? [{name: 'individual', datatype: 'text', labelKey: 'ASSIGNED TO', download: true, filter: true}] : [],
                            {name: 'organization', datatype: 'text', labelKey: 'ORGANIZATION', download: true, filter: true},
                            {name: 'assignedepoch', datatype: 'date', labelKey: 'ASSIGNED', download: true, filter: true},
                            {name: 'status', datatype: 'text', labelKey: 'STATE', download: true, filter: true}
                        ]}
                    />
                </div>
            </div>
            <div className="tablewrapper">
                <Table sortable compact className={(var_loading ? " tbl_loading" : "")}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell sorted={var_sortby === 'formname' ? var_sortorder : null} onClick={() => onClick_sort('formname')}>{t("FORM")}</Table.HeaderCell>
                            {var_org_owns_location &&
                            <Table.HeaderCell sorted={var_sortby === 'individual' ? var_sortorder : null} onClick={() => onClick_sort('individual')}>{t("ASSIGNED TO")}</Table.HeaderCell>
                            }
                            <Table.HeaderCell sorted={var_sortby === 'organization' ? var_sortorder : null} onClick={() => onClick_sort('organization')}>{t("ORGANIZATION")}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'assignedepoch' ? var_sortorder : null} onClick={() => onClick_sort('assignedepoch')}>{t("ASSIGNED")}</Table.HeaderCell>
                            {var_closed &&
                            <Table.HeaderCell className="td_paperclip" sorted={var_sortby === 'PDF' ? var_sortorder : null} onClick={() => onClick_sort('PDF')}><img src={"/icons/paperclip 30px (c9caca).svg?ts=" + Date.now()} alt={t("attachment icon")}/></Table.HeaderCell>
                            }
                            <Table.HeaderCell sorted={var_sortby === 'status' ? var_sortorder : null} onClick={() => onClick_sort('status')}>{t("STATE")}</Table.HeaderCell>
                            {(auth.has_access('ORG-LOC', 'write') && var_org_owns_location && !var_closed) &&
                            <Table.HeaderCell className="td_icon_center"></Table.HeaderCell>
                            }
                        </Table.Row>
                    </Table.Header>
                    {var_ready && var_forms[0]['totalrows'] !== 0 &&
                    <Table.Body>
                        {var_forms.map((item, i) =>
                            <Table.Row key={i} onClick={() => open_mdl_update(item)}>
                                <Table.Cell>{item.formname}</Table.Cell>
                                {var_org_owns_location &&
                                <Table.Cell className="td_overflow">{item.individual}</Table.Cell>
                                }
                                <Table.Cell className="td_overflow">{item.organization}</Table.Cell>
                                <Table.Cell className="td_overflow">{datelib.date_localized_format(item.assigned)}</Table.Cell>
                                {var_closed &&
                                <Table.Cell className="td_paperclip">
                                    {(item.status === 'CLOSED' && item.PDF !== null && item.PDF !== '') ?
                                        <div className='td_attachment' onClick={(event) => onClick_paperclip(event, item.id)}> <img src={"/icons/paperclip 60px (717473).svg?ts=" + Date.now()} alt={t("attachment icon")}/></div>
                                        :
                                        <div className='td_attachment'></div>
                                    }
                                </Table.Cell>
                                }
                                <Table.Cell className="td_overflow" >{item.status}</Table.Cell>
                                {(auth.has_access('ORG-LOC', 'write') && var_org_owns_location && !var_closed) &&
                                    <Table.Cell className="td_icon_center">
                                        {(['NEW', 'IN-PROGRESS'].includes(item.status)) &&
                                            <div onClick={(event) => onClick_removeform(event, item.id, item.formname)}> <img src={"/icons/delete 18px (6B7280).svg?ts=" + Date.now()} alt={t("delete icon")}/></div>
                                        }
                                    </Table.Cell>
                                }
                            </Table.Row>
                        )}
                    </Table.Body>
                    }
                </Table>
                {var_ready && var_forms[0]['totalrows'] === 0 &&
                <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt="alert icon"/>{t("there are no forms to display")}</div>
                }
            </div>
            <div className="content-filter">
                <div className="content-filter-item item-pagination">
                    <CMP_TABLECONTROLS
                        var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                        total_rows={var_forms && var_forms.length > 0 ? var_forms[0]['totalrows'] : 0}  populatefunction={populate_forms}
                    />
                </div>
            </div>
            <div className="legend_footer">
                <CMP_LEGEND
                    var_indv_view={false}
                />
            </div>
            {(var_org_owns_location && auth.has_access('ORG-LOC', 'write')) &&
            <div className="modal-footer">
                <div className="modal-footer-share">
                    <Button className="btn_tertiary" onClick={() => onOpen_mdl_manageforms()}><img className="btn_icon" src={"/icons/gear 30px (4dcbd4).svg?ts=" + Date.now()} alt={t("add icon")}/>{t("MANAGE FORMS")}</Button>
                </div>
                <div className="modal-footer-buttons">
                    {auth.has_access('ORG-FORMS', 'download') &&
                        <Button className='btn_secondary' onClick={() => set_mdl_download_open(true)}>{t('DOWNLOAD COMPLETED FORMS')}</Button>
                    }
                    <Button className={"btn_primary " + (var_ready ? 'btn_active' : '')} onClick={() => onOpen_mdl_assignform()}>{t("ASSIGN A NEW FORM")}</Button>
                </div>

            </div>
            }


            {/***** MODAL: MANAGE ************************************************************************************/}

            <Modal id="org-locations_mdl_manageforms"
                   dimmer={'inverted'}
                   open={var_mdl_manage_open}
                   onClose={() => set_mdl_manage_open(false)}>
                <MDL_MANAGE
                    set_mdl_open={set_mdl_manage_open}
                    var_modaltitle={'Manage ' + var_location + ' Forms'}
                    populate_function={populate_forms}
                    var_location={var_location}
                    var_location_id={var_location_id}>
                </MDL_MANAGE>
            </Modal>

            {/***** END MODAL: MANAGE ********************************************************************************/}

            {/***** MODAL: UPDATE ************************************************************************************/}

            <Modal id="org_mdl_updateform"
                   dimmer={'inverted'}
                   open={var_mdl_update_open}
                   onClose={() => set_mdl_update_open(false)}>
                <MDL_UPDATE
                    set_mdl_open={set_mdl_update_open}
                    var_location={var_location}
                    var_location_id={var_location_id}
                    onClick_paperclip={onClick_paperclip}
                    form={var_form_to_update}>
                </MDL_UPDATE>
            </Modal>

            {/***** END MODAL: UPDATE ********************************************************************************/}

            {/***** MODAL: ASSIGN ************************************************************************************/}

            <Modal id="org-locations_mdl_assignform"
                   dimmer={'inverted'}
                   open={var_mdl_assign_open}
                   onClose={() => set_mdl_assign_open(false)}>
                <MDL_ASSIGN
                    set_mdl_open={set_mdl_assign_open}
                    populate_function={populate_forms}
                    var_location_id={var_location_id}>
                </MDL_ASSIGN>
            </Modal>

            {/***** END MODAL: ASSIGN ********************************************************************************/}

            {/***** MODAL: DOWNLOAD **********************************************************************************/}

            <Modal id="org-locations_mdl_downloadform"
                   dimmer={'inverted'}
                   open={var_mdl_download_open}
                   onClose={() => set_mdl_download_open(false)}>
                <MDL_DOWNLOAD
                    set_mdl_open={set_mdl_download_open}
                    location_id={var_location_id} />
            </Modal>

            {/***** END MODAL: DOWNLOAD ******************************************************************************/}

            {/***** MODAL: DOCUMENT VIEW *****************************************************************************/}

            <Modal id="mdl_docviewer"
                   dimmer={'inverted'}
                   open={var_mdl_docviewer_open}
                   onClose={() => set_mdl_docviewer_open(false)}>
                <MDL_DOCVIEWER
                    set_mdl_open={set_mdl_docviewer_open}
                    targetid={var_docviewerid}
                    var_review={var_docviewer_review}
                    onComplete_review={populate_forms}
                    classification='form'
                />
            </Modal>

            {/***** END MODAL: DOCUMENT VIEW *********************************************************************/}


            {/***** MODAL: CONFIRMATION *******************************************************************************/}

            <Modal id="mdl_confirmation"
                   dimmer={'inverted'}
                   open={var_mdl_confirmation_open}
                   onClose={() => set_mdl_confirmation_open(false)}>
                <MDL_CONFIRMATION
                    set_mdl_open={set_mdl_confirmation_open}
                    var_modaltitle={t("Confirm Deletion")}
                    var_message={t("Delete the form") + ' "' + var_target_text + '"' + t("?")}
                    confirmation_function={delete_form}>
                </MDL_CONFIRMATION>
            </Modal>

            {/***** END MODAL: CONFIRMATION **************************************************************************/}


        </div>
    )
}
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import {Button, Form, Input} from 'semantic-ui-react'
import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import datelib from '../../../../../../libs/date-lib';

import { useTranslation } from 'react-i18next';
import '../../../../../../i18n';

import './mdl_addlink.css';

export default function({ set_mdl_open, populate_function }){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();
    const [ var_link, set_link] = useState(false);
    const [ var_ready, set_ready] = useState(false);
    const [ var_processing, set_processing] = useState(false);

    const [ var_credentials, set_credentials ] = useState([])
    let [ var_selected_credentials, set_selected_credentials] = useState([]);

    const [ var_limit, set_limit] = useState(100);
    const [ var_offset, set_offset] = useState(0);
    const [ var_sortby_cred, set_sortby_cred ] = useState('id');
    const [ var_sortorder, set_sortorder ] = useState('ascending');

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_link.transactionby === undefined){
            const link = {...var_link};
            link['organization_id'] = JSON.parse(sessionStorage.getItem('authentication')).organization_id;
            link['transactionby'] = JSON.parse(sessionStorage.getItem('authentication')).id;
            link['transactionorg'] = JSON.parse(sessionStorage.getItem('authentication')).organization_id;
            set_link(link);
        }
        populate_org_credentials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_link.transactionby]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function insert_profilelink(){
        set_processing(true);
        try {
            await API_post_org_profilelink();
            set_ready(false);
            set_processing(false);
            set_mdl_open(false);
            populate_function();
        } catch (e) {
            console.log(e);
        }
    }

    async function populate_org_credentials(){

        let limit = var_limit;
        let offset = var_offset;
        let sortby = var_sortby_cred;
        let sortorder = var_sortorder;
        let archive = 'NO';

        let creds = [];
        let option= '0';
        let credential_type = 'org';

        try {
            let results = await API_get_credentials(limit,offset,sortby,sortorder,archive);

            if(results.totalrows !== 0){
                results.results.map((item, i) =>
                    item.credential !== 'undefined' && item.credential !== null &&
                    creds.push({
                        'value':credential_type+'##'+option+'##'+item.id,
                        'text':item.credential,
                        'id':item.id,
                        'content': (
                            <div className={(item.verification_status === 'VALID' && item.confirmation_status === 'SELF VERIFIED') ? "dropdown_option self_verified" : 'dropdown_option'}>
                                <div className="option_icon">
                                    {(item.verification_status === 'VALID' && item.confirmation_status !== 'SELF VERIFIED') &&
                                    <img src={"/icons/checkmark 30px (69b536).svg?ts=" + Date.now()} alt={t("checkmark icon")}/>
                                    }
                                    {item.verification_status === 'INVALID' &&
                                    <img src={"/icons/x 30px (e0454f).svg?ts=" + Date.now()} alt={t("x icon")}/>
                                    }
                                    {item.verification_status === 'PENDING' &&
                                    <img src={"/icons/warning 30px (ffae00).svg?ts=" + Date.now()} alt={t("warning icon")}/>
                                    }
                                    {(item.verification_status === 'VALID' && item.confirmation_status === 'SELF VERIFIED') &&
                                    <img src={"/icons/checkmark 30px (4dcbd4).svg?ts=" + Date.now()} alt={t('self verified icon')}/>
                                    }
                                    {(item.verification_status !== 'VALID' && item.verification_status !== 'INVALID' && item.verification_status !== 'PENDING') &&
                                    <img src={"/icons/circle 30px (bcbebe).svg?ts=" + Date.now()} alt={t("grey circle")}/>
                                    }
                                </div>
                                <div className="option_content">
                                    <div className="option_title">{item.credential}</div>
                                    <div className="option_meta_list">
                                        <div><b>{t('ISSUED BY')}: </b>{item.issuedby}</div>
                                        <div><b>{t('ISSUED')} : </b>{item.issued}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                )
            }
            set_credentials(creds);
        } catch (e) {
            console.log(e);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_org_profilelink(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-wallet', '/post-profilelink', { body: var_link });
    }

    function API_get_credentials(limit,offset,sortby,sortorder,archive){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-orgcredentials',
                        '/get-org-credentials/' + JSON.parse(sessionStorage.getItem('authentication')).organization_id,
                        {
                            queryStringParameters: {
                            archive: archive,
                            limit: limit,
                            offset: offset,
                            sortby: sortby,
                            sortorder: sortorder,
                            tz : datelib.timezone
                            }
                        }
                    );
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(event){
        const link = { ...var_link };
        link[event.target.name] = event.target.value;
        set_link(link);
        set_ready(true);
    }

    function onChange_credential(event, data){
        set_selected_credentials(data.value);
        const link = { ...var_link };
        link.credentials = data.value.map((item) => item.split('##')[2]);
        set_link(link);
        set_ready(!!link.description);
    }

    function onClick_submit(){
        if(var_ready && var_link){
            insert_profilelink();
        }
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{t('Share credentials')}</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t('close')}/></div>
            </div>
            <div className="modal-content">
            <p>{t('Adding a description will help you remember who you sent this link to. The link itself will be automatically created using this description.')}</p>
                <Form>
                    <Form.Group>
                        <Form.Field
                            className='description'
                            id='description'
                            name='description'
                            value={var_link.description || ''}
                            control={Input}
                            onChange={onChange_input}
                            label={t('DESCRIPTION')}
                            placeholder={t('Description...')}
                        />
                    </Form.Group>
                    {JSON.parse(sessionStorage.getItem('authentication')).indv_credentials === 'YES' &&
                    <Form.Group>
                        <Form.Select
                            className='multiple'
                            id="multiple"
                            name="multiple"
                            label={t('CREDENTIALS')}
                            placeholder={t('Credentials...')}
                            options={var_credentials}
                            onChange={onChange_credential}
                            value={var_selected_credentials}
                            fluid
                            multiple
                            search
                        />
                    </Form.Group>
                    }
                </Form>
            </div>
            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>{t('Cancel')}</Button>
                    {!var_processing ?
                        <Button className={"btn_primary " + (var_ready ? ' btn_active' : '')} onClick={() => onClick_submit()}>{t('Create')}</Button>
                        :
                        <Button loading className="btn_primary btn_active">{t('Create')}</Button>
                    }

                </div>
            </div>
        </>
    )
}

import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import React from "react";

import { useTranslation } from 'react-i18next';
import '../../../../../../i18n';

import './tab_details.css';
import {Button, Modal} from "semantic-ui-react";
import MDL_CONFIRMATION from "../../../../../../components/cmp_confirmation/cmp_confirmation";
import MDL_DOCVIEWER from "../../../../../../components/cmp_docviewer/cmp_docviewer";



export default function({ var_activetab, var_document_id, populate_function }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();
    const [ var_document, set_document ] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [ var_ready, set_ready ] = useState(false);

    const [ var_mdl_confirmation_open, set_mdl_confirmation_open ] = useState(false);

    const [ var_mdl_docviewer_open, set_mdl_docviewer_open ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_activetab === 'DETAILS') {
            populate_document()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_activetab]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_document(){
        try {
            let result = await API_get_org_document();
            result[0]['organization_id'] = JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id;
            result[0]['transactionby'] = JSON.parse(sessionStorage.getItem('authentication')).id;
            result[0]['transactionorg'] = JSON.parse(sessionStorage.getItem('authentication')).organization_id;
            set_ready(false);
            set_document(result[0]);
        } catch (e) {
            console.log(e);
        }
    }

    async function archive_document(){
        try {
            if(var_document.status !== 'ARCHIVE'){
                await API_put_org_archive('ARCHIVE');
            }else{
                await API_put_org_archive('ACTIVE');
            }
            populate_document();
            populate_function();
        } catch (e) {
            console.log(e);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_document(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-documents', '/get-org-document/'+var_document_id);
    }

    function API_put_org_archive(var_archive){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.put('org-documents', '/put-org-document-archive/'+var_document_id+'/'+var_archive, { body: var_document });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_downloaddocument(){
        set_mdl_docviewer_open(true);
    }

    function onClick_archive(){
        set_mdl_confirmation_open(true);
    }

    function onClick_unarchive(){
        archive_document();
    }




    // RENDER APP ======================================================================================================

    return (
        <div className="modal-tab">

            {var_document.status === 'ARCHIVE' &&
            <div className="modal-archive" onClick={() => onClick_unarchive()}>
                <div className="modal-archive-icon"><img className="btn_icon" src={"/icons/archive 30px (ffffff).svg?ts=" + Date.now()} alt={t('archive icon')}/></div>
                <div className="modal-archive-text">{t('This document has been archived.  Click this bar to make it active again.')}
                </div>
            </div>
            }

            <div id="tab_details">
                <div className="detail-item" onClick={()=>onClick_downloaddocument()}>
                    <div className="detail-item-label"><Button className="btn_tertiary"><img src={"/icons/documents 30px (4dcbd4).svg?ts=" + Date.now()} alt={t('documents icon')} />{t("TAP TO VIEW")}</Button></div>
                    {/* <img src={"/icons/download 60px (717473).svg?ts=" + Date.now()} alt={t('DOWNLOAD DOCUMENT')}/> */}
                    <div className="detail-item-text">{var_document.name}</div>
                </div>
            </div>
            <div className="modal-footer">

                {var_document.status !== 'ARCHIVE' &&
                <div className="modal-footer-archive">
                    <Button className="btn_tertiary" onClick={() => onClick_archive()}><img className="btn_icon" src={"/icons/archive 30px (4dcbd4).svg?ts=" + Date.now()} alt={t('Details.alt.archive')}/>{t('ARCHIVE DOCUMENT')}</Button>
                </div>
                }

                <div className="modal-footer-buttons">
                    {/*<Button className="btn_secondary" onClick={() => set_mdl_sharing_open(false)}>CLOSE</Button>*/}
                </div>
            </div>



            {/***** MODAL: CONFIRMATION ******************************************************************************/}

            <Modal id="mdl_confirmation"
                   dimmer={'inverted'}
                   open={var_mdl_confirmation_open}
                   onClose={() => set_mdl_confirmation_open(false)}>
                <MDL_CONFIRMATION
                    set_mdl_open={set_mdl_confirmation_open}
                    var_modaltitle={t('Archive Document')}
                    var_message={t('Are you sure you want to archive this document?')}
                    confirmation_function={archive_document}>
                </MDL_CONFIRMATION>
            </Modal>

            {/***** END MODAL: CONFIRMATION **************************************************************************/}



            {/***** MODAL: DOCUMENT VIEW *****************************************************************************/}

            <Modal id="mdl_docviewer"
                   dimmer={'inverted'}
                   open={var_mdl_docviewer_open}
                   onClose={() => set_mdl_docviewer_open(false)}>
                <MDL_DOCVIEWER
                    set_mdl_open={set_mdl_docviewer_open}
                    targetid={var_document_id}
                    classification='document'
                />
            </Modal>

            {/***** END MODAL: DOCUMENT VIEW *************************************************************************/}



        </div>
    )

};
import { API } from "aws-amplify";
import React, { useEffect,useState } from "react";
import { Button,Modal,Table } from 'semantic-ui-react';
import auth from '../../../../../../libs/auth-lib';
import datelib from '../../../../../../libs/date-lib';

import { useTranslation } from 'react-i18next';
import '../../../../../../i18n';

import CMP_TABLECONTROLS, { filter_helper } from '../../../../../../components/cmp_tablecontrols/cmp_tablecontrols';
import './tab_locations.css';
import MDL_ADD from "./mdl_add/mdl_add"
import MDL_CONFIRMATION from "../../../../../../components/cmp_confirmation/cmp_confirmation";


export default function({ var_activetab, var_document_id, populate_function }){

    //  variable declarations ------------------------------------------------------------------------------------------
    // eslint-disable-next-line no-unused-vars
    const { t, i18n } = useTranslation();
    const [ var_locations, set_locations ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(10);
    const [ var_offset, set_offset] = useState(0);
    const [ var_search, set_search ] = useState('');
    const [ var_sortby, set_sortby ] = useState('code');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_document, set_document ] = useState(null);
    const [ var_mdl_confirmation_open, set_mdl_confirmation_open ] = useState(false);
    const [ var_loc_doc_id, set_loc_doc_id ] = useState(null);
    const [ var_location_name, set_location_name ] = useState(null);
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));

    const [ var_mdl_add_open, set_mdl_add_open ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_activetab === 'LOCATIONS') {
            populate_locations()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_activetab]);

    useEffect(()=>{
        if(var_filter.load){
            populate_locations(null,0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_filter]);

    //  async functions ------------------------------------------------------------------------------------------------
    async function populate_locations(limit,offset,sortby,sortorder,search,filter){
        if (var_activetab !== 'LOCATIONS') return; // Don't load data if tab is not visible

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        if(search) {
            if(search === '-') {
                search = '';
            }
            set_search(search);
        }else{
            search=var_search
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_doc_locations(limit,offset,sortby,sortorder,search,filter);
            set_totalrows(results.totalrows);
            set_locations(results.results === undefined ? [] : results.results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function remove_location(){
        set_ready(false);
        set_loading(true);
        try {
            await API_delete_loc_document();
            populate_locations();
        } catch (e) {
            console.log(e);
        }
    }

    async function populate_filters(filtername,sortorder){
        try {
            return (await API_get_doc_locations(filter_helper.maxFilterItems, 0, filtername, sortorder, var_search,{}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_document_locations(limit, offset){
        try {
            return (await API_get_doc_locations(limit, offset, var_sortby, var_sortorder, var_search, var_filter)).results;
        }  catch (e) {
             console.log(e);
             throw e;
         }
     }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_doc_locations(limit,offset,sortby,sortorder,search,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-documents',
                        '/get-doc-locations/'+ var_document_id,
                        {
                            queryStringParameters: {
                            limit: limit,
                            offset: offset,
                            sortby: sortby,
                            sortorder: sortorder,
                            search: search,
                            tz: datelib.timezone,
                            filtername: filtername
                            },
                            body: filter
                        }
                    );
    }

    function API_delete_loc_document(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.del("org-locations",'/delete-loc-document/'+var_loc_doc_id, {body: var_document});

    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_locations('',0,sortby,sortorder)
    }

    function onClick_add(){
        set_mdl_add_open(true)
    }

    function onClick_removelocation(loc_doc_id, location_name) {

        set_location_name(location_name);
        set_loc_doc_id(loc_doc_id);
        const document = {...var_document};
        document['locationdocument_id'] = var_loc_doc_id;
        document['organization_id'] = auth.actingorganization_id;
        document['transactionby'] = auth.id;
        document['transactionorg'] = auth.organization_id;
        set_document(document);
        set_mdl_confirmation_open(true);

    }

    // RENDER APP ======================================================================================================

    return (
        <div className="modal-tab">
            <div className="content-filter">
                <div className="content-filter-item item-pagination">
                        <CMP_TABLECONTROLS
                            var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                            total_rows={var_locations && var_locations.length > 0 ? var_totalrows : 0} populatefunction={populate_locations}
                            downloadname='Document Locations & Projects' downloadfunction={download_document_locations}
                            var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                            table_config={[
                                {name: 'code', datatype: 'text', labelKey: 'CODE', download: true, filter: true},
                                {name: 'location_name', datatype: 'text', labelKey: 'LOCATION', download: true, filter: true},
                                {name: 'address', datatype: 'text', labelKey: 'ADDRESS', download: true, filter: true},
                                {name: 'city', datatype: 'text', labelKey: 'CITY', download: true, filter: true},
                                {name: 'province', datatype: 'text', labelKey: 'PROVINCE', download: true, filter: true},
                                {name: 'country', datatype: 'text', labelKey: 'COUNTRY', download: true, filter: true}
                            ]}
                        />
                    </div>
            </div>
            <div className="tablewrapper">
                <Table sortable compact className={(var_loading ? " tbl_loading" : "")}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell sorted={var_sortby === 'code' ? var_sortorder : null} onClick={()=>onClick_sort('code')}>{t("CODE")}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'location_name' ? var_sortorder : null} onClick={()=>onClick_sort('location_name')}>{t("LOC/PROJ")}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'address' ? var_sortorder : null} onClick={()=>onClick_sort('address')}>{t("ADDRESS")}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'city' ? var_sortorder : null} onClick={()=>onClick_sort('city')}>{t("CITY")}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'province' ? var_sortorder : null} onClick={()=>onClick_sort('province')}>{t("PROV/STATE")}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'country' ? var_sortorder : null} onClick={()=>onClick_sort('country')}>{t("COUNTRY")}</Table.HeaderCell>
                            <Table.HeaderCell ></Table.HeaderCell>


                        </Table.Row>
                    </Table.Header>
                    {var_ready && var_totalrows !== 0 &&
                    <Table.Body>
                        {var_locations.map((item, i) =>
                            <Table.Row key={i} >
                                <Table.Cell>{item.code}</Table.Cell>
                                <Table.Cell >{item.location_name}</Table.Cell>
                                <Table.Cell >{item.address}</Table.Cell>
                                <Table.Cell>{item.city}</Table.Cell>
                                <Table.Cell>{item.province}</Table.Cell>
                                <Table.Cell>{item.country}</Table.Cell>
                                <Table.Cell className="td_icon_remove" onClick={() => onClick_removelocation(item.loc_doc_id, item.location_name)}><img src={"/icons/delete 18px (6B7280).svg?ts=" + Date.now()} alt="delete icon"/></Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                    }
                </Table>
                {var_ready && var_totalrows === 0 &&
                <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t('alert icon')}/>{t("no available location/project for this document")}</div>
                }
            </div>
            <div className="content-filter">
                <div className="content-filter-item item-pagination">
                    <CMP_TABLECONTROLS
                        var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                        total_rows={var_locations && var_locations.length > 0 ? var_totalrows : 0} populatefunction={populate_locations}
                    />
                </div>
            </div>
            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    {[auth.constants.permission_ids.Admin, auth.constants.permission_ids.GlobalAdmin, auth.constants.permission_ids.Superadmin].includes(auth.permission_id)
                    ? <Button className={'btn_primary ' + (var_ready ? ' btn_active' : '')} onClick={onClick_add}>{t('ADD TO LOCATION/PROJECT')}</Button>
                    : <div/>}
                </div>
            </div>


            {/* **** MODAL: ADD ***********************************************************************************/}

            <Modal id="org-documents_mdl_addlocation"
                    dimmer={'inverted'}
                    open={var_mdl_add_open}
                    onClose={() => set_mdl_add_open(false)}>
                <MDL_ADD
                    set_mdl_open={set_mdl_add_open}
                    populate_function={populate_locations}
                    populate_document={populate_function}
                    document_id={var_document_id}>
                </MDL_ADD>
            </Modal>

            {/***** END MODAL: ADD ****************************************************************************** */}

            {/***** MODAL: CONFIRMATION *******************************************************************************/}

            <Modal id="mdl_confirmation"
                   dimmer={'inverted'}
                   open={var_mdl_confirmation_open}
                   onClose={() => set_mdl_confirmation_open(false)}>
                <MDL_CONFIRMATION
                    set_mdl_open={set_mdl_confirmation_open}
                    var_modaltitle={'Remove Document'}
                    var_message={'Are you sure you want to remove document from ' + var_location_name + '?'}
                    confirmation_function={remove_location}>
                </MDL_CONFIRMATION>
            </Modal>

            {/***** END MODAL: CONFIRMATION **************************************************************************/}

        </div>
    )

};




import React, { useState } from 'react';
import { Form, Radio, Button } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { API } from 'aws-amplify';
import auth from '../../../../../../../libs/auth-lib';
import { useTranslation } from 'react-i18next';
import '../../../../../../../i18n';
import { enCA, frCA } from 'date-fns/locale';

import CMP_BULKDOWNLOAD_STATUS from '../../../../../../../components/cmp_bulkdownload_status/cmp_bulkdownload_status';

import './mdl_download.css';

export default function MDL_DOWNLOAD({set_mdl_open, location_id}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_processing, set_processing ] = useState(false);
    const [ var_status, set_status ] = useState('NEW');
    const [ var_all_forms, set_all_forms ] = useState(true);
    const [ var_filter_dates, set_filter_dates ] = useState([]);
    const [ var_bulkdownload_id, set_bulkdownload_id ] = useState(null);
    const [ var_errormessage, set_errormessage ] = useState(null);

    //  variable listeners ---------------------------------------------------------------------------------------------


    //  async functions ------------------------------------------------------------------------------------------------

    async function download() {
        set_processing(true);
        try {
            let result = await API_post_bulkdownload_loc_forms();
            if (result.status === 'ERROR' && result.type === 'NO FILES') {
                set_status('ERROR');
                set_errormessage(var_all_forms ? 'There are no completed forms for this location.' : 'There are no completed forms for the date(s) selected.');
            } else {
                set_bulkdownload_id(result.bulk_download_id);
                set_status('PROCESSING');
            }
        } catch (exception) {
            console.log(exception);
            set_errormessage('An error has occurred.  Please try again later.');
            set_status('ERROR');
            set_processing(false);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_bulkdownload_loc_forms() {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        let body = var_all_forms ? {} : {
            from_date: var_filter_dates[0].valueOf()/1000,
            to_date: (var_filter_dates[1] || var_filter_dates[0]).valueOf()/1000   // if only a single date was selected then array will contain just the one date, use that for the to date as well.
        };
        return API.post('org-forms', '/post-bulkdownload-loc-forms/' + location_id, { body });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_download() {
        if (!var_processing && (var_all_forms || var_filter_dates.length > 0)) {
            download();
        }
    }

    function onError_download() {
        set_status('ERROR');
        set_errormessage('An error has occurred.  Please try again later.');
    }

    function onComplete_download() {
        set_status('COMPLETE');
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='modal-header'>
                <div className='modal-header-title'>{t('Download completed forms')}</div>
                <div className='modal-header-close' onClick={() => set_mdl_open(false)}><img src={'/icons/x 60px (717473).svg?ts=' + Date.now()} alt={t('x icon')} /></div>
            </div>

            <div className='modal-content loc-forms-bulkdownload'>
                {var_status === 'NEW'
                    ? render_selection()
                    : var_status === 'ERROR'
                    ? t(var_errormessage)
                    : var_status === 'PROCESSING'
                    ? <CMP_BULKDOWNLOAD_STATUS bulkdownload_id={var_bulkdownload_id} onError={onError_download} onComplete={onComplete_download} />
                    : var_status === 'COMPLETE'
                    ? `${t('An email with a link to the zip file containing the completed forms has been sent to')} ${auth.email}`
                    : null
                }
            </div>

            <div className='modal-footer'>
                <div className='modal-footer-buttons'>
                    {var_status === 'COMPLETE' ?
                        <Button className='btn_primary btn_active' onClick={() => set_mdl_open(false)}>{t('CLOSE')}</Button>
                        :
                        <>
                            <Button className='btn_secondary' onClick={() => set_mdl_open(false)}>{t('CANCEL')}</Button>
                            <Button className={'btn_primary' + ((var_all_forms || var_filter_dates.length > 0) ? ' btn_active' : '')} loading={var_processing} onClick={onClick_download}>{t('SEND LINK')}</Button>
                        </>
                    }
                </div>
            </div>
        </>
    )

    function render_selection() {
        return (
            <div>
                <div className='instructions'>
                    {`${t("To download completed forms for this location, select which forms to include and click the 'SEND LINK' button below and an email will be sent to")} ${auth.email} ${t('with a link to the zip file containing the completed forms for this location.')}
                        ${t('This link will remain active for one week.')}`}
                </div>
                <Form>
                    <Form.Group>
                        <Form.Field>
                            <Radio name='all_forms'
                                label={t('All forms')}
                                checked={var_all_forms}
                                onChange={() => set_all_forms(true)} />
                        </Form.Field>
                        <Form.Field>
                            <Radio name='all_forms'
                                label={t('Specific forms')}
                                checked={!var_all_forms}
                                onChange={() => set_all_forms(false)} />
                        </Form.Field>
                    </Form.Group>
                    {!var_all_forms &&
                        <div>
                            <div className='instructions'>
                                {t('Please select the range of dates that the forms were closed to download.')}
                            </div>
                            <Form.Group>
                                <SemanticDatepicker
                                    className='filter_dates'
                                    format={auth.language.name === 'French' ? 'D MMM YYYY' : 'MMM DD, YYYY'}
                                    formatOptions={{locale: auth.language.name === 'French' ? frCA: enCA}}
                                    locale={auth.language.datepicker}
                                    onChange={(e, {value}) => set_filter_dates(value)}
                                    clearable={false}
                                    datePickerOnly={true}
                                    showToday={false}
                                    value={var_filter_dates}
                                    type='range' />
                            </Form.Group>
                        </div>
                    } 
                </Form>
            </div>
        );
    }
}
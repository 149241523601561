import { API } from "aws-amplify";
import React, { useState, useEffect} from "react";
import { useHistory } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { useTranslation } from 'react-i18next';
import '../../i18n';
import auth from '../../libs/auth-lib';

import CRD_LOGIN from "./crd_login/crd_login";
import CRD_CREATEACCOUNT from "./crd_createaccount/crd_createaccount";
import CRD_NEWPASSWORD from "./crd_newpassword/crd_newpassword";

import "./login.css";



export default function(appProps) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const history = useHistory();

    const [ var_login, set_login ] = useState([]);
    const [ var_login_step, set_login_step ] = useState(null);
    const [ var_show_sso, set_show_sso ] = useState([]);
    const [ var_location_id, set_location_id ] = useState('-');
    const [ var_qrdata, set_qrdata ] = useState([]);
    const [ var_auth_token, set_auth_token ] = useState(false);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect( ()=>{

        //  check for location ids
        let params = window.location.search.split('?').join('').split('&');
        let logout_if_loggedin = false;
        for(let param of params) {
            let paramdata = param.split('=');
            if(paramdata[0] === 'location_id'){
                populate_qrdata(paramdata[1]);
                sessionStorage.setItem('sso_invite', JSON.stringify({location_id: paramdata[1]}));
                logout_if_loggedin = true;
            }
        }
        if (appProps.match.params.code) {
            if (appProps.match.params.code === 'auto') {
                set_auth_token(true);
            } else {
                let authToken = JSON.parse(atob(appProps.match.params.code));
                if ('email' in authToken && 'password' in authToken) {
                    set_auth_token(true);
                }
            }
        }

        if(window.location.href.indexOf('mybvc.ca') >= 0) {
            set_show_sso('BVC');
        }

        if(window.location.href.indexOf('cwallet.mintzscreeningservices.com') >= 0) {
            set_show_sso('MINTZ');
        }

        if(window.location.href.indexOf('cwallettest.mintzscreeningservices.com') >= 0) {
            set_show_sso('MINTZ_QA');
        }

        if (auth.is_loggedin && logout_if_loggedin){
            auth.logout(null);
            appProps.set_visibleframe(false);
            set_login_step('LOGIN');
        } else if (auth.is_loggedin) {
            history.push('/indv/wallet');
        } else {
            appProps.set_visibleframe(false);

            if(sessionStorage.getItem('invitestatus')){
                let invitestatus = JSON.parse(sessionStorage.getItem('invitestatus'));
                sessionStorage.removeItem('invitestatus');
                set_login({emaildisabled: true, ...invitestatus});
                sessionStorage.setItem('sso_invite', JSON.stringify({inviteid: invitestatus.inviteid}));
                if (invitestatus.invitestatus === 'NEW USER') {
                    set_login_step('NEWPASSWORD');
                } else {
                    set_login_step('LOGIN');
                }
            }else{
                set_login_step('LOGIN');
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_qrdata(location_id){
        let result = await API_get_qrdata(location_id);
        result[0]['qrlocation_id'] = location_id;
        result[0].termsofservice && (result[0].termsofservice = `${await API.endpoint('storage')}/termsofservice/${result[0].organization_id}/${result[0].termsofservice}`);
        result[0].logo_path = await API.endpoint('storage') + '/logo/' + result[0].organization_id + '/' + result[0].logo;
        set_qrdata(result[0]);
        set_location_id(location_id);
        sessionStorage.setItem('qrdata',JSON.stringify(result[0]));
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_qrdata(location_id){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('admin', '/get-qrdata?location_id=' + location_id);
    }



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return(
        <>
            { var_auth_token &&
                <div style={{visibility: "hidden"}}>
                    <CRD_LOGIN
                        var_login={var_login}
                        set_login={set_login}
                        set_login_step={set_login_step}
                        var_location_id={var_location_id}
                        var_qrdata={var_qrdata}
                        set_language={appProps.set_appDefaultLanguage}
                        var_params={appProps.match.params}>
                    </CRD_LOGIN>
                </div>
            }
            {!var_auth_token &&
                <div className="login_wrapper">
                    <div className='logowrapper'>
                        <img id='CrediveraLogo' src='icons/Credivera Horizontal Blue.png'/>
                        <div className="login">
                            {var_login_step === 'LOGIN' &&
                                <CRD_LOGIN
                                    var_login={var_login}
                                    set_login={set_login}
                                    set_login_step={set_login_step}
                                    var_location_id={var_location_id}
                                    var_qrdata={var_qrdata}
                                    set_language={appProps.set_appDefaultLanguage}
                                    var_params={appProps.match.params}>
                                </CRD_LOGIN>
                            }

                            {var_show_sso === 'BVC' &&
                                <div>
                                    <div class="or_sso">- OR -</div>
                                    <Button id="btn_sso" className="ui btn_primary btn_active"
                                            onClick={() => window.location.href = 'https://a7f27797-fed2-41cd-b9be-21097c45df4c.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=BVC&redirect_uri=https://7wvzzl4d8h.execute-api.us-east-1.amazonaws.com/prod/get-saml/bvc&response_type=CODE&client_id=nnf9dl466bu0s190mqtpe29hk&scope=aws.cognito.signin.user.admin email openid profile'}>{t('SINGLE SIGN ON')}</Button>
                                </div>
                            }

                            {var_show_sso === 'MINTZ' &&
                                <div>
                                    <div class="or_sso">- OR -</div>
                                    <Button id="btn_sso" className="ui btn_primary btn_active"
                                            onClick={() => window.location.href = 'https://a7f27797-fed2-41cd-b9be-21097c45df4c.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=Mintz&redirect_uri=https://7wvzzl4d8h.execute-api.us-east-1.amazonaws.com/prod/get-saml/mintz&response_type=CODE&client_id=1d2m3dk2t3grf18ibu1osboa9&scope=aws.cognito.signin.user.admin email openid profile'}>{t('SINGLE SIGN ON')}</Button>
                                </div>
                            }

                            {var_show_sso === 'MINTZ_QA' &&
                                <div>
                                    <div class="or_sso">- OR -</div>
                                    <Button id="btn_sso" className="ui btn_primary btn_active"
                                            onClick={() => window.location.href = 'https://b4f66b02-f349-4a12-b6b3-d1e1592a8357.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=Mintz&redirect_uri=https://9lluvtnqp9.execute-api.us-east-1.amazonaws.com/qa/get-saml/mintz&response_type=CODE&client_id=4iupcuiqam5sh5ml8p85ghjddp&scope=aws.cognito.signin.user.admin email openid profile verification-api/read_verification verification-api/update_verification'}>{t('SINGLE SIGN ON')}</Button>
                                </div>
                            }

                            {var_login_step === 'CREATEACCOUNT' &&
                                <CRD_CREATEACCOUNT
                                    var_login={var_login}
                                    set_login={set_login}
                                    var_login_step={var_login_step}
                                    set_login_step={set_login_step}
                                    var_location_id={var_location_id}
                                    var_qrdata={var_qrdata}>
                                </CRD_CREATEACCOUNT>
                            }

                            {var_login_step === 'NEWPASSWORD' &&
                                <CRD_NEWPASSWORD
                                    var_login={var_login}
                                    set_login={set_login}
                                    var_login_step={var_login_step}
                                    set_login_step={set_login_step}
                                    var_location_id={var_location_id}
                                    var_qrdata={var_qrdata}
                                    set_language={appProps.set_appDefaultLanguage}>
                                </CRD_NEWPASSWORD>
                            }

                        </div>
                    </div>
                </div>
            }
        </>
    )


};

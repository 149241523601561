import React from "react";
import {useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { API } from "aws-amplify";
import '../../../../i18n';

import './sta_document.css';

export default function({var_pageready, var_documentsrefresh}){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();
    const history = useHistory();

    const [ var_indv_document_stat, set_indv_document_stat ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_pageready) {
            populate_indv_documents_stat()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_pageready]);

    useEffect(()=>{
        if(var_documentsrefresh){
            populate_indv_documents_stat()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_documentsrefresh]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_indv_documents_stat(){
        try {
            let results = await API_get_indv_documents_stat();
            set_indv_document_stat(results[0]);
        } catch (e) {
            console.log(e);
        }

        set_ready(true);
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_indv_documents_stat(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('indv-documents', '/get-indv-documents-stat/'+JSON.parse(sessionStorage.getItem('authentication')).id)
            .then(response => response)
            .catch(error => {
                console.log(error.response);
                return [{"assigned":0}];  // on error, return a sane result
            });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_documents(){
        history.push('/indv/documents')
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="stat-card" id="sta_indv_overview_document" onClick={()=>onClick_documents()}>
                {var_ready &&
                <>
                    {(var_indv_document_stat.notacknowledged === 0) &&
                        <div className="document-bg document-none">
                            <div className="document-icon"><img src={"/icons/documents 60px (ffffff).svg?ts=" + Date.now()} alt={t('document icon')}></img></div>
                            <div className="document-title">{t('DOCUMENTS')}</div>
                            <div className="document-subtitle">{t('No documents require your attention')}</div>
                        </div>
                    }
                    {(var_indv_document_stat.notacknowledged === 1) &&
                    <div className="document-bg document-alert">
                        <div className="document-icon"><img src={"/icons/documents 60px (ffffff).svg?ts=" + Date.now()} alt={t('document icon')}></img></div>
                        <div className="document-title">{t('DOCUMENTS')}</div>
                        <div className="document-subtitle">{t('You have 1 document to acknowledge')}</div>
                    </div>
                    }
                    {(var_indv_document_stat.notacknowledged > 1) &&
                    <div className="document-bg document-alert">
                        <div className="document-icon"><img src={"/icons/documents 60px (ffffff).svg?ts=" + Date.now()} alt={t('document icon')}></img></div>
                        <div className="document-title">{t('DOCUMENTS')}</div>
                        <div className="document-subtitle">{t('You have')} {var_indv_document_stat.notacknowledged} {t('documents to acknowledge')}</div>
                    </div>
                    }
                </>
                }
            </div>
        </>
    )
}

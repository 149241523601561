import { Button, Form, Input, Icon, Modal } from 'semantic-ui-react'
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import datelib from '../../../../../libs/date-lib';
import { enCA, frCA } from 'date-fns/locale';
import auth from '../../../../../libs/auth-lib';

import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import CMP_FILEUPLOAD from "../../../../../components/cmp_fileupload/cmp_fileupload";
import MDL_ASSIGN_REQUIREMENT from './mdl_assign_requirement';

import './mdl_add.css';

export default function({ set_mdl_open, var_individual_id, populate_function, is_org_side }){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();
    const [ var_credential, set_credential ] = useState([]);
    const [ var_org_credential, set_org_credential ] = useState([]);
    const [ var_issued, set_issued ] = useState(null);
    const [ var_expires, set_expires ] = useState(null);
    const [ var_subject_id, set_subject_id ] = useState(null);
    const [ var_credential_number, set_credential_number ] = useState(null);
    const [ var_ready, set_ready] = useState(false);
    const [ var_processing, set_processing] = useState(false);
    const [ var_issuer_verifierAPIs, set_issuer_verifierAPIs ] = useState([]);
    const [ var_validationerror, set_validationerror] = useState(false);
    const [ var_validationerrormessage, set_validationerrormessage] = useState('');
    const [ var_selfverified, set_selfverified ] = useState(false);
    const [ var_new_credential, set_new_credential ] = useState(false);

    const [ var_dd_issuers, set_dd_issuers ] = useState([]);
    const [ var_issuer_id, set_issuer_id ] = useState(null);

    const [ var_credential_dd, set_credential_dd ] = useState([]);
    const [ var_credential_id, set_credential_id ] = useState(null);

    const [ var_fileuploadstatus, set_fileuploadstatus ] = useState('');
    const [ var_fileuploadname, set_fileuploadname ] = useState('');
    const [ var_fileuploadlabel, set_fileuploadlabel ] = useState('CREDENTIAL ATTACHMENT (PDF, JPG, JPEG, PNG)');
    // eslint-disable-next-line no-unused-vars
    const [ var_fileuploaddirectory, set_fileuploaddirectory ] = useState('certificate');
    const [ var_fileuploaddisplayname, set_fileuploaddisplayname ] = useState(null);

    const [ var_filetype, set_filetype ] = useState('application/pdf, image/png, image/jpg, image/jpeg');
    const [ var_fileextension, set_fileextension ] = useState(['pdf','png','jpg','jpeg']);
    // eslint-disable-next-line no-unused-vars
    const [ var_filesize, set_filesize ] = useState('10');

    const [ var_active_step, set_active_step] = useState('DOCUMENTS');
    const [ var_fileupload, set_fileupload ] = useState(null);
    const [ var_multiple_files, set_multiple_files] = useState(false);
    const [ var_dependant_org, set_dependant_org ] = useState(false);
    const [ var_dependant_credential, set_dependant_credential ] = useState(false);

    const [ var_mdl_assign_requirement_open, set_mdl_assign_requirement_open ] = useState(false);
    const [ var_credential_in_preapproved, set_credential_in_preapproved ] = useState(false);
    const [ var_requirements, set_requirements ] = useState([]);

    const divStyle = {
        display: 'none'
      };



    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_dd_indv_issuers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=>{
        if(var_credential.transactionby !== undefined) {
            if (var_fileuploadstatus === 'PENDING') {
                set_fileuploadstatus('START');
                set_processing(true);
            } else {
                set_processing(true);
                insert_credential();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_credential.transactionby]);

    useEffect(()=>{
        if(var_fileuploadstatus === 'COMPLETE') {
            set_processing(true);
            insert_credential();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_fileuploadstatus]);

    useEffect(() => {
        if(var_fileuploadstatus !== 'COMPLETED'){
            const credential = {...var_credential};
            credential['filename'] = var_fileuploadname;
            if (!var_multiple_files) {
                credential['submittedfilename']=var_fileuploaddisplayname;
            }
            set_credential(credential);
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_fileuploadname, var_fileuploaddisplayname, var_fileuploadstatus]);

    useEffect(() => {
        if (var_multiple_files) {
            set_fileextension(['png','jpg','jpeg']);
            set_filetype('image/png, image/jpg, image/jpeg');
            set_fileuploadlabel('CREDENTIAL DOCUMENT (JPG, JPEG, PNG)');
        } else {
            set_fileextension(['pdf','png','jpg','jpeg']);
            set_filetype('application/pdf, image/png, image/jpg, image/jpeg');
            set_fileuploadlabel('CREDENTIAL DOCUMENT (PDF, JPG, JPEG, PNG)');
        }
    }, [var_multiple_files]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_dd_indv_issuers(){
        try {
            let results = await API_get_dd_indv_issuers();
            set_dd_issuers(results);
        } catch (e) {
            console.log(e);
        }
    }

    async function populate_issuer_details(issuer_id){
        populate_dd_credentials(issuer_id);
        populate_subjectid(issuer_id);
        populate_verifierAPIs(issuer_id);
    }

    async function populate_dd_credentials(issuer_id){
        try {
            let results = await API_get_org_indv_credentials_dd(issuer_id);
            set_credential_dd(results);
        } catch (e) {
            console.log(e);
        }
    }

    async function populate_subjectid(issuer_id){
        try {
            let results = await API_get_indv_subjectid(issuer_id);

            if(results[0] !== undefined) {
                set_subject_id(results[0]['subject_id']);
            }else{
                set_subject_id('');
            }

        } catch (e) {
            console.log(e);
        }
    }

    async function populate_verifierAPIs(issuer_id){
        try {
            let results = await API_get_issuer_verifier_apis(issuer_id);
            if(results[0] !== undefined) {
                set_issuer_verifierAPIs(results[0]);
            }else{
                set_issuer_verifierAPIs([]);
            }

        } catch (e) {
            console.log(e);
        }
    }

    async function populate_org_credential(credential_id){
        try {
            let result = await API_get_org_credential(credential_id);
            set_org_credential(result[0]);
            const credential = { ...var_credential };
            calculate_expirydate(false, credential, result[0], var_issued);
            set_credential(credential);
            if(result[0]['issuedrequired'] === 'NO'){
                set_ready(true);
            }else{
                if(var_issuer_id === '' || var_issuer_id === undefined || var_issuer_id === null){
                    set_ready(false);
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    async function insert_credential() {
        try {
            if(!var_selfverified && !var_new_credential && var_issuer_verifierAPIs.API_verifier && var_issuer_verifierAPIs.API_verifier === "YES"){
                API_post_indv_credential_external(var_issuer_verifierAPIs.API_service, var_issuer_verifierAPIs.API_post);
            } else {
                await API_post_indv_credential();
            }
            set_ready(false);
            set_processing(false);
            populate_function();

           if (auth.org_requirements && auth.has_access('ORG-REQ', 'manage_history') && !var_selfverified) {
                await assign_requirement_individual();
            } else {
                set_mdl_open(false);
            }
        } catch (e) {
            console.log(e);
        }
    }

    function credential_number_info() {
        let display = var_selfverified || var_new_credential || var_org_credential.has_credential_number === 'YES';
        if (!display) return { display };
        let required = !var_selfverified && !var_new_credential && var_org_credential.credential_number_required === 'YES';
        let label = (var_selfverified || var_new_credential || !var_org_credential.credential_number_label) ? 'Credential number' : var_org_credential.credential_number_label;
        return { display, required, label: label.toUpperCase() + (required ? ' (REQUIRED)' : ''), placeholder: label + '...' };
    }

    async function assign_requirement_individual() {        
        let results = await API_get_requirements_credential();
        if (results && results.length > 0) {
            set_requirements(results); 
            if (results.some(item => item.total_in_preapproved > 0)) {                       
                set_credential_in_preapproved(true);                    
            }     
            set_mdl_assign_requirement_open(true);  
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_dd_indv_issuers(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('indv-credentials', '/get-dd-indv-issuers/' + auth.id);
    }

    function API_get_org_indv_credentials_dd(issuer_id){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('indv-credentials', '/get-org-indv-credentials-dd/'+issuer_id+'/'+var_individual_id);
    }

    function API_get_indv_subjectid(issuer_id){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('indv-credentials', '/get-indv-subjectid/' + auth.organization_id + '/'+var_individual_id);
    }

    function API_get_org_credential(credential_id){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-credentials', '/get-org-credential/'+credential_id);
    }

    function API_post_indv_credential(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('indv-credentials', '/post-indv-credential', { body: var_credential });
    }

    function API_get_issuer_verifier_apis(issuer_id){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-credentials', '/th-get-issuer-api/'+issuer_id);
    }

    function API_post_indv_credential_external(API_service, API_post){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post(API_service, '/'+API_post, { body: var_credential });
    }

    function API_get_requirements_credential() {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-requirements', '/get-requirements-credential/'+auth.organization_id+'/'+var_credential.credential_id+'/'+var_credential.issuer_id+'/'+var_individual_id);
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_dd_issuers(event, { value }){
        set_issued(null);
        set_expires(null);
        set_credential_dd([]);
        set_issuer_id(value);
        populate_issuer_details(value);
        set_validationerror(false);
    }

    function onChange_credential_dd(event, { value }){
        set_credential_id(value);
        populate_org_credential(value);
    }

    function onChange_issued(event, data){
        if(data.value !== var_issued) {
            set_issued(data.value);
            const credential = { ...var_credential };
            credential['issued'] = data.value === null ? null : datelib.localdate_to_utc_midnight_epoch(data.value);

            calculate_expirydate(var_selfverified || var_new_credential, credential, var_org_credential, data.value);

            set_credential(credential);
            set_ready(true);
        }
    }

    function calculate_expirydate(selfverified_or_new, credential, org_credential, issueddate) {
        if (selfverified_or_new) {
            return;
        }
        if (org_credential.expires !== 'YES') {
            credential.expires = null;
            set_expires(null);
        } else if (issueddate) {
            if(org_credential.expires_unit === 'year'){
                credential.expires = datelib.moment_to_epoch(datelib.localdate_to_utc_midnight_moment(issueddate).add(org_credential.expires_amount, 'years'));
            }
            if(org_credential.expires_unit === 'month'){
                credential.expires = datelib.moment_to_epoch(datelib.localdate_to_utc_midnight_moment(issueddate).add(org_credential.expires_amount, 'months'));
            }
            if(org_credential.expires_unit === 'day'){
                credential.expires = datelib.moment_to_epoch(datelib.localdate_to_utc_midnight_moment(issueddate).add(org_credential.expires_amount, 'days'));
            }
            set_expires(datelib.utcmoment_to_local_midnight_date(datelib.epoch_to_utcmoment(credential.expires)));
        }
    }

    function onChange_expires(event, data){
        if(data.value !== var_expires) {
            set_expires(data.value);
            const credential = { ...var_credential };
            credential['expires'] = data.value === null ? null : datelib.localdate_to_utc_midnight_epoch(data.value);
            set_credential(credential);
            set_ready(true);
        }
    }

    function onChange_credential_number(event, data) {
        set_credential_number(data.value);
        set_ready(true);
    }

    function onChange_dependant_org(event){
        set_dependant_org(event.target.value);
        set_validationerror(false);
        set_ready(true);
    }

    function onChange_dependant_credential(event){
        set_dependant_credential(event.target.value);
        set_validationerror(false);
        set_ready(true);
    }

    function onClick_submit(){
        if(var_ready){
            const credential = {...var_credential};
            let var_validation = false;
            let var_validmessage = '';
            if(var_selfverified){
                if (!var_dependant_org || var_dependant_org === '') {
                    var_validation = true;
                    var_validmessage = t('Please enter an issuer');
                }
                if (!var_dependant_credential || var_dependant_credential === '') {
                    var_validation = true;
                    var_validmessage = t('Please enter a credential name');
                }

            }else if(var_new_credential) {
                if (!var_issuer_id || var_issuer_id === '') {
                    var_validation = true;
                    var_validmessage = t('Please enter an issuer');
                }
                if (!var_dependant_credential || var_dependant_credential === '') {
                    var_validation = true;
                    var_validmessage = t('Please enter a credential name');
                }
            } else {
                if (!var_issuer_id || var_issuer_id === '') {
                    var_validation = true;
                    var_validmessage = t('Please select an issuer');
                }
                if (!var_credential_id || var_credential_id === '') {
                    var_validation = true;
                    var_validmessage = t('Please select a credential');
                }
                if (var_org_credential.issuedrequired === 'YES' && !var_issued) {
                    var_validation = true;
                    var_validmessage = t('Please select the issued date of the credential');
                }
                if (var_org_credential.expires === 'YES' && !var_expires) {
                    var_validation = true;
                    var_validmessage = t('Please select the expiry date of the credential');
                }
                if (var_org_credential.has_credential_number && var_org_credential.credential_number_required && (!var_credential_number || var_credential_number.trim().length === 0)) {
                    var_validation = true;
                    var_validmessage = t('Please enter the ' + (var_org_credential.credential_number_label || 'credential number').toLowerCase() + ' of the credential');
                }
            }
            set_validationerrormessage(var_validmessage);
            set_validationerror(var_validation);
            if (var_validation === false) {
                if(var_selfverified || var_new_credential){
                    if(var_new_credential) {
                        credential['issuer_id'] = var_issuer_id;
                        credential['dependantorg'] = var_dd_issuers.find(iss => iss.value === var_issuer_id).text;
                        credential['dependant_credential'] = var_dependant_credential;
                    } else {
                        credential['issuer_id'] = '11111111-1111-1111-1111-111111111111';
                        credential['dependantorg'] = var_dependant_org;
                        credential['dependant_credential'] = var_dependant_credential;
                    }
                    if (var_multiple_files && var_fileupload) {
                        credential['submittedfilename'] = var_dependant_credential + '.pdf';
                    }
                    credential['credential_id'] = '00000000-0000-0000-0000-000000000000';
                    credential['verifyingorg_id'] = null;
                    credential.credential_number = var_credential_number;
                    credential['verification_status'] = 'VALID';
                    if (is_org_side && [auth.constants.permission_ids.Superadmin, auth.constants.permission_ids.GlobalAdmin, auth.constants.permission_ids.Admin].includes(auth.permission_id)) {
                        credential['confirmation_status'] = 'VERIFIED';
                    } else {
                        credential['confirmation_status'] = 'SELF VERIFIED';
                    }
                } else {
                    if (var_multiple_files && var_fileupload) {
                        credential['submittedfilename'] = var_org_credential.name + '.pdf';
                    }
                    credential['issuer_id'] = var_issuer_id;
                    credential['credential_id'] = var_credential_id;
                    credential['name'] = var_org_credential.name;
                    credential['verifyingorg_id'] = var_org_credential['verifyingorg_id'];
                    credential['subject_id'] = var_subject_id;
                    credential.credential_number = var_org_credential.has_credential_number === 'YES' ? var_credential_number : null;
                    credential['verification_status'] = '';
                    credential['confirmation_status'] = '';
                }
                credential['transactionby'] = auth.id;
                credential['transactionorg'] = auth.organization_id;
                credential['individual_id'] = var_individual_id;
                set_credential(credential);
            }
        }
    }

    function onChange_new_credential(status){
        if (!status) {
            set_dependant_credential(null);
        }
        set_new_credential(status);
        const credential = { ...var_credential };
        calculate_expirydate(status, credential, var_org_credential, var_issued);
        set_credential(credential);
    }

    function onChange_selfverified(state) {
        if (state) {
            set_new_credential(false);
        }
        set_selfverified(state);
    }

    function onChange_activestep(step) {
        if (!var_processing) {
            set_active_step(step);
        }
    }

    function onChange_multiple_files() {
        set_fileupload(null);
        set_fileuploaddisplayname(null);
        set_fileuploadname(null);
        set_multiple_files(!var_multiple_files);
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">
                    <div className="modal-header-img">
                        <img src={"/icons/credential 30px (717473).svg?ts=" + Date.now()} alt={t("credential icon")} />
                    </div>
                    <div className="modal-header-text">
                        {t('Add A New Credential')}
                    </div>
                </div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
            </div>
            <div className="modal-content">
                <div className="steps_panel">
                    <div className={'app-sidenav-item '+(var_active_step === 'DOCUMENTS' ? 'active' : '')} onClick={() => onChange_activestep('DOCUMENTS')}>
                        <div className="section-title">
                            {t('ATTACHMENTS')}
                        </div>
                        <div className="section-description">
                            {t('Upload your attachment files')}
                        </div>
                    </div>
                    <div className={'app-sidenav-item '+(var_active_step === 'ISSUER' ? 'active' : '')} onClick={() => onChange_activestep('ISSUER')}>
                        <div className="section-title">
                            {t('ISSUER')}
                        </div>
                        <div className="section-description">
                            {t('Find the credential issuer')}
                        </div>
                    </div>
                    <div className={'app-sidenav-item '+(var_active_step === 'CREDENTIAL' ? 'active' : '')} onClick={((!var_selfverified && !var_issuer_id) || (var_selfverified && (!var_dependant_org || var_dependant_org === ''))) ? null : () => onChange_activestep('CREDENTIAL')}>
                        <div className="section-title">
                            {t('CREDENTIAL')}
                        </div>
                        <div className="section-description">
                            {t('Add credential details')}
                        </div>
                    </div>
                </div>
                <div className="page">
                    {var_active_step === 'DOCUMENTS' && 
                    <>
                        <div className="page-title">
                            {t('Supporting Attachments')}
                        </div>
                        <div className="description-text">
                            <p>{t('Adding an image of the credential will make it available to others for review and help with the verification process. If you do not have an image, you can skip this step and add it later.')}</p>
                            <p>{t('*Uploaded file will be unavailable for viewing until the virus scan is complete.')}</p>
                        </div>
                        <CMP_FILEUPLOAD
                            var_fileuploaddirectory={var_fileuploaddirectory}
                            var_fileuploadlabel={var_fileuploadlabel}
                            var_fileuploadstatus={var_fileuploadstatus}
                            set_fileuploadstatus={set_fileuploadstatus}
                            var_fileuploadname={var_fileuploadname}
                            set_fileuploadname={set_fileuploadname}
                            var_fileuploaddisplayname={var_fileuploaddisplayname}
                            set_fileuploaddisplayname={set_fileuploaddisplayname}
                            var_placeholder={t('No file selected...')}
                            var_filetype={var_filetype}
                            var_fileextension={var_fileextension}
                            var_filesize={var_filesize}
                            var_ready={var_ready}
                            set_ready={set_ready}
                            var_fileupload={var_fileupload}
                            set_fileupload={set_fileupload}
                            var_multiple_files={var_multiple_files}>
                        </CMP_FILEUPLOAD>
                        <div className="modal-footer-buttons">
                            <Button className="btn_secondary skip_btn multi_upload" onClick={() => onChange_multiple_files()}>{var_multiple_files ? t('I WANT TO UPLOAD ONE FILE') : t('I WANT TO UPLOAD MULTIPLE FILES')}</Button>
                            <Button disabled={var_fileupload !== null} className="btn_secondary skip_btn" onClick={() => set_active_step('ISSUER')}>{t('SKIP THIS STEP')} <Icon className="skip-icon" name="arrow alternate circle right outline" size="large" /></Button>
                        </div>
                    </>
                    }
                    {var_active_step === 'ISSUER' &&
                    <>
                        <div className="page-title">
                            {t('Credential Issuer')}
                        </div>
                        <div className="description-text">
                            <p>{t('Select the issuer of this credential from the list below. If the issuer is not in the list you can add it manually by clicking the Issuer Not Found button.')}</p>
                        </div>
                        {!var_selfverified ?
                        <>
                        <Form>
                            <Form.Group>
                                <Form.Select
                                    className='issuedby'
                                    id='issuedby'
                                    name='issuedby'
                                    label={t('CREDENTIAL ISSUER')}
                                    search
                                    options={var_dd_issuers}
                                    value={var_issuer_id || ''}
                                    onChange={onChange_dd_issuers}
                                    placeholder={t('Type to search and select an issuer...')}
                                />
                            </Form.Group>
                        </Form>
                        <Button className="btn_secondary skip_btn" onClick={() => onChange_selfverified(true)}>{t('ISSUER NOT FOUND')} <Icon className="skip-icon" name="arrow alternate circle right outline" size="large" /></Button>
                        </>
                        :
                        <>
                        <Form>
                            <Form.Group>
                                <Form.Field
                                    className='issuedby'
                                    id='dependantorg'
                                    name='dependantorg'
                                    value={var_dependant_org || ''}
                                    control={Input}
                                    onChange={onChange_dependant_org}
                                    label={t('CREDENTIAL ISSUER')}
                                    placeholder={t('Enter issuer name...')}
                                />
                            </Form.Group>
                        </Form>
                        <Button className="btn_secondary previous_btn" onClick={() => onChange_selfverified(false)}><Icon className="previous-icon" name='arrow alternate circle left outline' size="large" />{t('BACK TO LIST OF ISSUERS')}</Button>
                        </>
                        }
                    </>
                    }
                    {var_active_step === 'CREDENTIAL' &&
                    <>
                        <div className="page-title">
                            {t('Credential Details')}
                        </div>
                        <div className="description-text">
                            <p>{t('Add the remaining credential details and click submit.')}</p>
                        </div>

                        {var_selfverified || var_new_credential?
                            <>
                            <Form>
                                <Form.Field
                                    className='dependant_credential'
                                    id='dependant_credential'
                                    name='dependant_credential'
                                    value={var_dependant_credential || ''}
                                    control={Input}
                                    onChange={onChange_dependant_credential}
                                    label={t('CREDENTIAL NAME (REQUIRED)')}
                                    placeholder={t('Enter credential name...')}
                                    maxLength="100"
                                />
                            </Form>
                            {var_new_credential &&
                            <Button className="btn_secondary previous_btn" onClick={() => onChange_new_credential(false)}><Icon name='arrow alternate circle left outline' size='large'/>{t('BACK TO LIST OF CREDENTIALS')}</Button>
                            }
                            </>
                        :
                            <>
                            <Form>
                                <Form.Select
                                    className='credential'
                                    id='credential'
                                    name='credential'
                                    label={t('CREDENTIAL NAME (REQUIRED)')}
                                    search
                                    options={var_credential_dd}
                                    value={var_credential_id || ''}
                                    onChange={onChange_credential_dd}
                                    placeholder={t('Type to search and select a credential...')}
                                    disabled={var_processing}
                                />
                            </Form>
                            <Button disabled={var_processing} className="btn_secondary skip_btn" onClick={() => onChange_new_credential(true)}>{t('CREDENTIAL NOT FOUND')}<Icon className="skip-icon" name="arrow alternate circle right outline" size="large" /></Button>
                            </>
                        }
    
                        <Form className={ var_selfverified ? "issued-expired-form" : ""}>
                        <Form.Group>
                        <SemanticDatepicker id='issued'
                                            locale={auth.language.datepicker}
                                            format={auth.language.name === 'French' ? 'D MMM YYYY' : 'MMM DD, YYYY'}
                                            formatOptions={{locale: auth.language.name === 'French' ? frCA: enCA}}
                                            name='issued'
                                            onChange={onChange_issued}
                                            clearable={false}
                                            datePickerOnly={true}
                                            showToday={false}
                                            label={t('ISSUED' + (var_org_credential.issuedrequired === 'YES' ? ' (REQUIRED)' : ''))}
                                            placeholder={t('Credential issued...')}
                                            disabled={var_processing}
                                            value={var_issued}/>
                        {(var_org_credential.expires === 'YES' || var_selfverified || var_new_credential) &&
                        <SemanticDatepicker id='expires'
                                            locale={auth.language.datepicker}
                                            format={auth.language.name === 'French' ? 'D MMM YYYY' : 'MMM DD, YYYY'}
                                            formatOptions={{locale: auth.language.name === 'French' ? frCA: enCA}}
                                            name='expires'
                                            onChange={onChange_expires}
                                            clearable={false || var_selfverified || var_new_credential}
                                            datePickerOnly={true}
                                            showToday={false}
                                            label={t('EXPIRES' + (var_org_credential.expires === 'YES' ? ' (REQUIRED)' : ''))}
                                            placeholder={t('Credential expires...')}
                                            disabled={var_processing}
                                            value={var_expires}/>
                        }
                        </Form.Group>
                        <Form.Group>
                            {!var_selfverified && !var_new_credential &&
                                <Form.Field className='half-width-field'
                                            id='subjectid'
                                            name='subjectid'
                                            value={var_subject_id || ''}
                                            control={Input}
                                            label={t('HOLDER ID')}
                                            placeholder={t('Holder ID...')}
                                />
                            }
                            {credential_number_info().display &&
                                <Form.Field className='half-width-field'
                                    id='credential_number'
                                    name='credential_number'
                                    value={var_credential_number || ''}
                                    control={Input}
                                    onChange={onChange_credential_number}
                                    label={t(credential_number_info().label)}
                                    placeholder={t(credential_number_info().placeholder)}
                                />
                            }
                        </Form.Group>
                        </Form>
                        {var_validationerror &&
                        <div className="message warning">
                            <div className="message_icon"><img src={"/icons/warning 60px (d91e18).svg?ts=" + Date.now()} alt={t('warning icon')} /></div>
                            <div className="message_text_wrapper">
                                <div className="message_text cause">{t(var_validationerrormessage)}</div>
                            </div>
                        </div>
                        }
                        <div className="second_cmp" style={(var_fileuploadstatus !== 'UPLOADING' && var_fileuploadstatus !== 'START' && var_fileuploadstatus !== 'COMPLETE' && var_fileuploadstatus !== 'FAIL') ? divStyle : null}>
                        <CMP_FILEUPLOAD
                            var_fileuploaddirectory={var_fileuploaddirectory}
                            var_fileuploadlabel={var_fileuploadlabel}
                            var_fileuploadstatus={var_fileuploadstatus}
                            set_fileuploadstatus={set_fileuploadstatus}
                            var_fileuploadname={var_fileuploadname}
                            set_fileuploadname={set_fileuploadname}
                            var_fileuploaddisplayname={var_fileuploaddisplayname}
                            set_fileuploaddisplayname={set_fileuploaddisplayname}
                            var_placeholder={t('No file selected...')}
                            var_filetype={var_filetype}
                            var_fileextension={var_fileextension}
                            var_filesize={var_filesize}
                            var_ready={var_ready}
                            set_ready={set_ready}
                            var_fileupload={var_fileupload}
                            set_fileupload={set_fileupload}
                            var_multiple_files={var_multiple_files}>
                        </CMP_FILEUPLOAD>
                        </div>
                    </>
                    }
                    <div className="modal-footer">
                        <div className="modal-footer-buttons">
                            {var_active_step === 'DOCUMENTS' &&
                            <>
                                <div></div>
                                <Button disabled={!var_fileupload} className="btn_primary btn_active next_btn" onClick={() => set_active_step('ISSUER')}>{t('NEXT')}</Button>
                            </>
                            }
                            {var_active_step === 'ISSUER' &&
                                <>
                                    <Button className="btn_secondary previous_btn" onClick={() => set_active_step('DOCUMENTS')} ><Icon className="previous-icon" name="arrow alternate circle left outline" size="large" />{t('PREVIOUS STEP')}</Button>
                                    <Button disabled={(!var_selfverified && !var_issuer_id) || (var_selfverified && (!var_dependant_org || var_dependant_org === ''))} className="btn_primary btn_active next_btn" onClick={() => set_active_step('CREDENTIAL')}>{t('NEXT')}</Button>
                                </>
                            }
                            {var_active_step === 'CREDENTIAL' &&
                                <>
                                    <Button className="btn_secondary previous_btn" disabled={var_processing} onClick={() => set_active_step('ISSUER')} ><Icon className="previous-icon" name="arrow alternate circle left outline" size="large" />{t('PREVIOUS STEP')}</Button>
                                    {!var_processing
                                        ?
                                        <Button disabled={!var_ready} className={'btn_primary btn_active next_btn'} onClick={() => onClick_submit()}>{t('SUBMIT')}</Button>
                                        :
                                        <Button loading className="btn_primary btn_active next_btn">{t('SUBMIT')}</Button>
                                    }
                                </>
                                
                            }

                        </div>
                    </div>
                </div>
            </div>

            {/***** MODAL: ADD REQUIREMENT ***************************************************************************************/}

            <Modal id="mdl_assign_requirement"
                   dimmer={'inverted'}
                   open={var_mdl_assign_requirement_open}
                   onClose={() => set_mdl_assign_requirement_open(false)}
                   closeOnEscape={false}
                   closeOnDimmerClick={false}>
                <MDL_ASSIGN_REQUIREMENT
                    set_mdl_add_credential_open={set_mdl_open}                   
                    var_credential={var_credential}
                    var_credential_in_preapproved={var_credential_in_preapproved}    
                    var_requirements={var_requirements}
                    var_individual_id={var_individual_id}>                    
                </MDL_ASSIGN_REQUIREMENT>
            </Modal>

        {/***** END MODAL: ADD REQUIREMENT ***********************************************************************************/}

        </>
    )
}
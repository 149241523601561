import { useEffect, useState } from "react";
import { Form, Input, Progress } from 'semantic-ui-react'
import React from "react";
import XLSX from "xlsx";
import { useTranslation } from 'react-i18next';
import '../../i18n';

import './cmp_fileprocess.css';


export default function({ var_fileuploadlabel, var_fileuploadstatus, set_fileuploadstatus, var_fileuploaddisplayname, set_fileuploaddisplayname, var_placeholder, set_filedata }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_fileupload, set_fileupload ] = useState(null);
    const [ var_filetypeerror, set_filetypeerror ] = useState(null);
    const [ var_progress, set_progress ] = useState(0);



    //  variable listeners ---------------------------------------------------------------------------------------------


    useEffect(() => {
        console.log('var_fileuploadstatus: '+var_fileuploadstatus);
        if(var_fileuploadstatus === 'START'){
            processfile()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_fileuploadstatus]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function processfile(){

        set_fileuploadstatus('UPLOADING');

        var reader = new FileReader();
        reader.onload = function (e) {
            var data = e.target.result;
            let readerData = XLSX.read(data, {type: 'binary'});
            const wsname = readerData.SheetNames[0];
            const ws = readerData.Sheets[wsname];
            set_filedata(XLSX.utils.sheet_to_json(ws, {skipHeader:true}));
            set_fileuploadstatus('COMPLETE');
        };
        reader.onprogress = function(data) {
            if (data.lengthComputable) {
                set_progress(parseInt(((data.loaded / data.total) * 100), 10));
            }
        }
        reader.readAsBinaryString(var_fileupload);

    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_selectfile(){
        set_filetypeerror(false);
        document.getElementById('btn_hiddenfileinput').click();
    }

    function onChange_selectfile(event){
        if(["xlsx", "xls", "csv"].indexOf(event.target.files[0].name.split('.').pop()) < 0){
            set_filetypeerror(true);
        } else {
            set_fileuploadstatus('PENDING');
            set_fileupload(event.target.files[0]);
            set_fileuploaddisplayname(event.target.files[0].name);
        }
    }



    // RENDER APP ======================================================================================================

    return (
        <div className="cmp_fileupload">
            {(var_fileuploadstatus === '' || var_fileuploadstatus === 'PENDING') &&
            <Form className="fileupload">
                <Form.Group>
                    <input
                        id="btn_hiddenfileinput"
                        type="file"
                        style={{display: "none"}}
                        onChange={onChange_selectfile}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />

                    <Form.Field
                        className='selectfile'
                        name='selectfile'
                        control={Input}
                        action={{
                            content: t('SELECT FILE'),
                            onClick: onClick_selectfile
                        }}
                        label={var_fileuploadlabel}
                        value={var_fileuploaddisplayname || ''}
                        placeholder={var_placeholder}
                    />
                </Form.Group>
            </Form>
            }

            {var_fileuploadstatus === 'UPLOADING' &&
            <Progress className="mdl_createresource_progressbar" percent={var_progress} progress/>
            }

            {(var_fileuploadstatus === 'FAIL' || var_filetypeerror) &&
            <div id="invite_error" className="message warning">
                <div className="message_icon"><img src={"/icons/x 60px (e0454f).svg?ts=" + Date.now()} alt="x icon"/></div>
                <div className="message_text_wrapper">
                    <div className="message_text resolution">{var_filetypeerror? t('You have selected an invalid file type for bulk uploads. Please select either a CSV or XLS file ') : t('File upload failed')}</div>
                </div>
            </div>
            }


        </div>
    )
}
//  OUTCOMES -----------------------------------------------------------------------------------------------------------
//
//  - displays tabs showing the pre-approved/pre-reject list of credentials for a requirement
//
//  --------------------------------------------------------------------------------------------------------------------

import React, { useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import TAB_ACCEPTREJECT from './tab_acceptreject/tab_acceptreject';

import './mdl_preapprovals.css';

function MDL_PREAPPROVALS({ set_mdl_open, requirement_id, requirement_name, populate_function }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_activetab, set_activetab ] = useState('ACCEPT');

    //  variable listeners ---------------------------------------------------------------------------------------------



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_tab(var_tab){
        set_activetab(var_tab);
    }

    // RENDER APP ======================================================================================================

    return (
        <div id='mdl_preapprovals_container'>
            <div className="modal-header">
                <div className="modal-header-title">{requirement_name}</div>
                <div className="modal-header-tabs">
                    <div className={"modal-header-tabs-item"  + (var_activetab === 'ACCEPT' ? ' active' : '')} onClick={() => onClick_tab(`ACCEPT`)}>
                        {t("Accept")}
                    </div>
                    <div className={"modal-header-tabs-item"  + (var_activetab === 'REJECT' ? ' active' : '')} onClick={() => onClick_tab(`REJECT`)}>
                        {t("Reject")}
                    </div>
                </div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
            </div>
            <div className="modal-tabs">
                <div className={(var_activetab !== 'ACCEPT' ? 'modal-tab-hide' : '')}>
                    <TAB_ACCEPTREJECT
                        activetab={var_activetab}
                        requirement_id={requirement_id}
                        requirement_name={requirement_name}
                        accept_or_reject='ACCEPT'
                        populate_function={populate_function}
                    />
                </div>
                <div className={(var_activetab !== 'REJECT' ? 'modal-tab-hide' : '')}>
                    <TAB_ACCEPTREJECT
                        activetab={var_activetab}
                        requirement_id={requirement_id}
                        requirement_name={requirement_name}
                        accept_or_reject='REJECT'
                        populate_function={populate_function}
                    />
                </div>
            </div>
        </div>
    )
}

MDL_PREAPPROVALS.propTypes = {
    set_mdl_open: propTypes.func.isRequired,
    requirement_id: propTypes.string.isRequired,
    requirement_name: propTypes.string.isRequired,
    populate_function: propTypes.func
}

export default MDL_PREAPPROVALS;
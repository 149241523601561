import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import "./download.css";
import '../../i18n';


export default function(appProps) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();
    const [ var_status, set_status ] = useState(null);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        appProps.set_visibleframe(false);
        get_bulkdownload();
    },[]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function get_bulkdownload() {
        try {
            let result = await API_get_bulkdownload();
            set_status(result.status);
            return result;
        } catch (exception) {
            set_status('ERROR');
            console.log(exception);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_bulkdownload() {
        let token = appProps.match.params.token;
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.get('storage', '/get-bulkdownload/' + token);
    }


    //  event functions ------------------------------------------------------------------------------------------------

    async function onClick_download() {
        // re-retrieve data to make sure link is fresh
        let result = await get_bulkdownload();
        if (result.status === 'SUCCESS') {
            const a = document.createElement('a');
            a.href = result.url;
            a.click();
        }
    }

    // RENDER APP ======================================================================================================

    return (
        <div className='download_wrapper'>
            <div className='download_content'>
                {var_status === 'NOT FOUND' || var_status === 'EXPIRED'
                    ? t('A valid or active download link could not be found. Please create a new one')
                    : var_status === 'ERROR'
                    ? t('An error has occurred.  Please try again later.')
                    : var_status === 'SUCCESS'
                    ? <Button className='btn_tertiary' onClick={onClick_download}><img src={"/icons/download 30px (4dcbd4).svg?ts=" + Date.now()} alt={t('download icon')} /> {t('Click to download your file')}</Button>
                    : null
                }
            </div>
        </div>
    );
}
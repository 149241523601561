//  OUTCOMES -----------------------------------------------------------------------------------------------------------
//
//  - displays list of requirement for an individual, with the ability to add a credential to the list
//
//  --------------------------------------------------------------------------------------------------------------------

import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { API } from 'aws-amplify';
import { Table, Button, Modal } from 'semantic-ui-react';
import datelib from '../../../../../libs/date-lib';
import Moment from 'moment';
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';
import auth from '../../../../../libs/auth-lib';

import CMP_TABLECONTROLS, {filter_helper} from '../../../../../components/cmp_tablecontrols/cmp_tablecontrols';
import MDL_DOCVIEWER from '../../../../../components/cmp_docviewer/cmp_docviewer';
import MDL_INDV_REQUIREMENT from '../../../../common/requirements/mdl_indv_requirement/mdl_indv_requirement';

import './tab_requirements.css';

function TAB_REQUIREMENTS({ activetab, individual_id }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_records, set_records ] = useState(null);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(10);
    const [ var_offset, set_offset] = useState(0);
    const [ var_sortby, set_sortby ] = useState('requirement');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));

    const [ var_mdl_indv_requirement_open, set_mdl_indv_requirement_open ] = useState(false);
    const [ var_mdl_indv_requirement_source, set_mdl_indv_requirement_source ] = useState('ORG');
    const [ var_mdl_indv_requirement_target_id, set_mdl_indv_requirement_target_id ] = useState('');

    const [ var_mdl_docviewer_open, set_mdl_docviewer_open ] = useState(false);
    const [ var_docviewerid, set_docviewerid ] = useState(null);

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(()=>{
        if (activetab === 'REQUIREMENTS') {
            set_filter(filter_helper.initialize(false));
        }
    },[activetab]);

    useEffect(()=>{
        if (var_filter.load && activetab === 'REQUIREMENTS'){
            populate_records(null,0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_filter]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_records(limit,offset,sortby,sortorder,filter){
        if (activetab !== 'REQUIREMENTS') return; // Don't load data if tab is not visible

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_requirements(limit,offset,sortby,sortorder,filter);
            set_totalrows(results[0].totalrows);
            set_records(results[0].totalrows === 0 ? [] : results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
    }

    async function populate_filters(filtername,sortorder){
        try {
            return await API_get_requirements(filter_helper.maxFilterItems,0,filtername,sortorder,{},filtername);
        } catch (e) {
            console.log(e);
        }
    }

    async function download_results(limit,offset){
        try {
            return await API_get_requirements(limit,offset,var_sortby,var_sortorder,var_filter);
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_requirements(limit,offset,sortby,sortorder,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('indv-credentials', '/get-indv-requirements/'+individual_id,
            {
                queryStringParameters: {
                    limit : limit,
                    offset : offset,
                    sortby : sortby,
                    sortorder : sortorder,
                    filtername : filtername,
                    tz : datelib.timezone
                },
                body : filter
            }
        );
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        } else {
            sortorder = sortorder === 'ascending' ? 'descending' : 'ascending';
        }
        populate_records('',0,sortby,sortorder)
    }

    function onClick_existingrequirement(id) {
        if (auth.has_access('ORG-INDV', 'write')) {
            set_mdl_indv_requirement_source('ORG');
            set_mdl_indv_requirement_target_id(id);
            set_mdl_indv_requirement_open(true);
        }
    }

    function onClick_addrequirement(){
        set_mdl_indv_requirement_source('ORG-NEW');
        set_mdl_indv_requirement_target_id(individual_id);
        set_mdl_indv_requirement_open(true);
    }

    function onClick_paperclip(event, id){
        event.stopPropagation();
        set_docviewerid(id);
        set_mdl_docviewer_open(true);
    }

    // RENDER APP ======================================================================================================

    return (
        <div className='modal-tab tab_requirements_container'>
            <div className='content-filter'>
                <div className='content-filter-item item-pagination'>
                    <CMP_TABLECONTROLS
                        var_limit={var_limit} var_offset={var_offset} var_ready={var_records !== null} var_loading={var_loading}
                        total_rows={var_totalrows} populatefunction={populate_records}
                        downloadname='Requirements' downloadfunction={download_results}
                        var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                        table_config={[
                            {name:'requirement', datatype:'text', labelKey:'REQUIREMENT', download: true, filter: true},
                            {name:'status', datatype:'text', labelKey:'STATUS', download: true, filter: true},
                            {name:'issuedby', datatype:'text', labelKey:'ISSUED BY', download: true, filter: true},
                            {name:'credential', datatype:'text', labelKey:'CREDENTIAL', download: true, filter: true},
                            {name:'expires', datatype:'text', labelKey:'EXPIRES', download: true, filter: false},
                            {name:'epochexpires', datatype:'date', labelKey:'EXPIRES', download: false, filter: true},
                            {name:'submittedfilename', datatype:'text', labelKey:'FILE', download: true, filter: true},
                        ]}
                    />
                </div>
            </div>
            <div className='tablewrapper'>
                <Table sortable compact className={(var_loading ? 'tbl_loading' : '')}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell sorted={var_sortby === 'requirement' ? var_sortorder : null} onClick={() => onClick_sort('requirement')}>{t('REQUIREMENT')}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'status' ? var_sortorder : null} onClick={() => onClick_sort('status')}>{t('STATUS')}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'issuedby' ? var_sortorder : null} onClick={() => onClick_sort('issuedby')}>{t('ISSUED BY')}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'credential' ? var_sortorder : null} onClick={() => onClick_sort('credential')}>{t('CREDENTIAL')}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'epochexpires' ? var_sortorder : null} onClick={() => onClick_sort('epochexpires')}>{t('EXPIRES')}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'filename' ? var_sortorder : null} onClick={() => onClick_sort('filename')}><img src={'/icons/paperclip 30px (c9caca).svg?ts=' + Date.now()} alt={t('paperclip icon')}/></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    {var_records && var_records.length > 0 &&
                    <Table.Body>
                        {var_records.map((item) =>
                            <Table.Row key={item.individualrequirement_id} onClick={() => onClick_existingrequirement(item.individualrequirement_id)}>
                                <Table.Cell className='td_overflow'>{item.requirement}</Table.Cell>
                                <Table.Cell>{item.status}</Table.Cell>
                                <Table.Cell>{item.issuedby}</Table.Cell>
                                <Table.Cell>{item.credential}</Table.Cell>
                                <Table.Cell>{item.epochexpires !== null && item.epochexpires !== undefined && item.epochexpires !== '' ? Moment.unix(item.epochexpires).utc(false).format('ll') : ''}</Table.Cell>
                                <Table.Cell>
                                    {(item.filename !== null && item.filename !== '' && item.filename !== 'No file selected...') ?
                                        <div className='td_attachment' onClick={(event) => onClick_paperclip(event, item.individualcredential_id)}> <img src={'/icons/paperclip 60px (717473).svg?ts=' + Date.now()} alt={t('paperclip icon')}/></div>
                                        :
                                        <div className='td_attachment'></div>
                                    }
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                    }
                </Table>
                {!var_loading && var_totalrows === 0 &&
                <div className='emptytable'><img src={'/icons/alert 60px (e5e5e5).svg?ts=' + Date.now()} alt='alert icon'/>{t('there are no requirements to display')}</div>
                }
            </div>
            <div className='content-filter'>
                <div className='content-filter-item item-pagination'>
                    <CMP_TABLECONTROLS
                        var_limit={var_limit} var_offset={var_offset} var_ready={var_records !== null} var_loading={var_loading}
                        total_rows={var_totalrows} populatefunction={populate_records}
                    />
                </div>
            </div>
            {auth.has_access('ORG-INDV', 'write') &&
            <div className='modal-footer'>
                <div className='modal-footer-buttons'>
                    <Button className='btn_primary btn_active' onClick={onClick_addrequirement}>{t('ADD REQUIREMENT')}</Button>
                </div>
            </div>
            }
            
            {/***** MODAL: ADD REQUIREMENT ***********************************************************************/}

            <Modal id='mdl_indv_requirement'
                    dimmer={'inverted'}
                    open={var_mdl_indv_requirement_open}
                    onClose={() => set_mdl_indv_requirement_open(false)}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}>
                <MDL_INDV_REQUIREMENT
                    var_mdl_open={var_mdl_indv_requirement_open}
                    set_mdl_open={set_mdl_indv_requirement_open}
                    source={var_mdl_indv_requirement_source}
                    var_target_id={var_mdl_indv_requirement_target_id}
                    populate_function={populate_records} />
            </Modal>

            {/***** END MODAL: ADD REQUIREMENT *******************************************************************/}


            {/***** MODAL: DOCUMENT VIEW *************************************************************************/}

            <Modal id='mdl_docviewer'
                    dimmer={'inverted'}
                    open={var_mdl_docviewer_open}
                    onClose={() => set_mdl_docviewer_open(false)}>
                <MDL_DOCVIEWER
                    set_mdl_open={set_mdl_docviewer_open}
                    targetid={var_docviewerid}
                    classification='credential' />
            </Modal>

            {/***** END MODAL: DOCUMENT VIEW *********************************************************************/}
        </div>
    )
}

TAB_REQUIREMENTS.propTypes = {
    activetab: propTypes.string.isRequired,
    individual_id: propTypes.string.isRequired
}

export default TAB_REQUIREMENTS;
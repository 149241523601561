import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Button, Modal, Table } from 'semantic-ui-react';
import datelib from '../../../../../libs/date-lib';
import auth from '../../../../../libs/auth-lib';
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import CMP_TABLECONTROLS, { filter_helper } from '../../../../../components/cmp_tablecontrols/cmp_tablecontrols';
import MDL_HISTORY from '../../../../../components/cmp_history/cmp_history';
import MDL_ADDLINK from '../mdl_sharing/mdl_addlink/mdl_addlink';
import MDL_UPDATELINK from '../mdl_sharing/mdl_updatelink/mdl_updatelink';
import MDL_SHARELINK from '../mdl_sharing/mdl_sharelink/mdl_sharelink';

import './crd_sharing.css';



export default function(){

        //  variable declarations ------------------------------------------------------------------------------------------

        const { t } = useTranslation();
        const [ var_profilelinks, set_profilelinks] = useState({});
        const [ var_totalrows, set_totalrows ] = useState(0);
        const [ var_ready, set_ready ] = useState(false);
        const [ var_loading, set_loading ] = useState(true);
        const [ var_limit, set_limit] = useState(10);
        const [ var_offset, set_offset] = useState(0);
        const [ var_search, set_search ] = useState('');
        const [ var_sortby, set_sortby ] = useState('status');
        const [ var_sortorder, set_sortorder ] = useState('ascending');
    
        const [ var_mdl_history_open, set_mdl_history_open ] = useState(false);
        const [ var_modaltitle, set_modaltitle ] = useState('');
        const [ var_target_id, set_target_id ] = useState('');
        const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));
    
        const [ var_mdl_org_wallet_sharing_addlink_open, set_mdl_org_wallet_sharing_addlink_open ] = useState(false);
    
        const [ var_org_wallet_sharing_updatelink_open, set_org_wallet_sharing_updatelink_open ] = useState(false);
        const [ var_link_id, set_link_id ] = useState('');
        const [var_mdl_sharelink_open, set_mdl_sharelink_open] = useState(false);

    useEffect(() => {
        populate_profilelinks()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=>{
        if(var_filter.load){
            populate_profilelinks(null,0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_filter]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_profilelinks(limit,offset,sortby,sortorder,search,filter){

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        if(search) {
            if(search === '-') {
                search = '';
            }
            set_search(search);
        }else{
            search=var_search
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_profilelinks(limit,offset,sortby,sortorder,search,filter);
            set_totalrows(results.totalrows);
            set_profilelinks(results.results === undefined ? [] : results.results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);

    }

    async function populate_filters(filtername,sortorder){
        try {
            return (await API_get_profilelinks(filter_helper.maxFilterItems, 0, filtername, sortorder, var_search,{}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_profile_links(limit, offset){
        try {
            return (await API_get_profilelinks(limit, offset, var_sortby, var_sortorder, var_search, var_filter)).results;
        }  catch (e) {
             console.log(e);
             throw e;
         }
     }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_profilelinks(limit,offset,sortby,sortorder,search,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-wallet',
                        '/get-profilelinks/' + auth.organization_id,
                        {
                            queryStringParameters: {
                            limit: limit,
                            offset: offset,
                            sortby: sortby,
                            sortorder: sortorder,
                            search : search,
                            tz : datelib.timezone,
                            filtername: filtername
                            },
                            body: filter
                        }
                    );
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_profilelinks('',0,sortby,sortorder)
    }


    function onClick_publicprofile(link_id){
        window.open(window.location.protocol+'//'+window.location.hostname+"/public/orgprofile/"+link_id);
    }


    function onOpen_mdl_history(link_id, description) {
        set_modaltitle('Public Profile Link History - '+description);
        set_target_id(link_id);
        set_mdl_history_open(true)
    }

    function onOpen_mdl_addlink(){
        set_mdl_org_wallet_sharing_addlink_open(true);
    }

    function onClick_updatelink(link_id){
        set_link_id(link_id);
        set_org_wallet_sharing_updatelink_open(true);
    }

    function onOpen_mdl_sharelink(link_id) {
        set_link_id(link_id);
        set_mdl_sharelink_open(true);
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="content-card" id="crd_org_sharing">
                <div className="content-card-header">
                    <div className="content-card-header-title"></div>
                    <Button className="btn_tertiary" onClick={() => onOpen_mdl_addlink()}>
                        <img className="btn_icon" src={"/icons/add 30px (4dcbd4).svg?ts=" + Date.now()} alt={t("add icon")}></img>
                        {t('ADD A LINK')}
                    </Button>
                </div>
                <div className="content-card-gridcontent">
                    <div className="content-filter">
                        <div className="content-filter-archive">
                        </div>
                        <div className="content-filter-item item-pagination">
                        <CMP_TABLECONTROLS
                            var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                            total_rows={var_profilelinks && var_profilelinks.length > 0 ? var_totalrows : 0} populatefunction={populate_profilelinks}
                            downloadname='Organization Credentials Sharing Links' downloadfunction={download_profile_links}
                            var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                            table_config={[
                                {name: 'description', datatype: 'text', labelKey: 'DESCRIPTION', download: true, filter: true},
                                {name: 'credentials', datatype: 'text', labelKey: 'CREDENTIALS', download: true, filter: true},
                                {name: 'status', datatype: 'text', labelKey: 'STATUS', download: true, filter: true}
                            ]}
                        />
                        </div>
                    </div>
                    <div className="tablewrapper">
                        <Table sortable compact className={(var_loading ? " tbl_loading" : "")}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell className="td_icon_center">{t('VIEW')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'description' ? var_sortorder : null} onClick={()=>onClick_sort('description')}>{t('DESCRIPTION')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'credentials' ? var_sortorder : null} onClick={()=>onClick_sort('credentials')}>{t('CREDENTIALS')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'status' ? var_sortorder : null} onClick={()=>onClick_sort('status')}>{t('STATUS')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_icon_center">{t('HISTORY')}</Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {var_ready && var_totalrows !== 0 &&
                            <Table.Body>
                                {var_profilelinks.map((item, i) =>
                                    <Table.Row key={i} >
                                        <Table.Cell className="td_icon_center" onClick={() => onClick_publicprofile(item.id)}>
                                        {item.status === 'ACTIVE' &&
                                            <img src={"/icons/linkchain 24px (10B981).svg?ts=" + Date.now()} alt={t('active icon')}/>
                                        }
                                        {item.status !== 'ACTIVE' &&
                                            <img src={"/icons/linkchain 24px (E0454F).svg?ts=" + Date.now()} alt={t('inactive icon')}/>
                                        }
                                    </Table.Cell>
                                    <Table.Cell onClick={() => onClick_publicprofile(item.id)}>{item.description}</Table.Cell>
                                    <Table.Cell>{item.credentials}</Table.Cell>
                                    <Table.Cell onClick={() => onClick_updatelink(item.id)}>{item.status}</Table.Cell>
                                    <Table.Cell className="td_icon_center" onClick={() => onOpen_mdl_history(item.id, item.description)}><img src={"/icons/history 30px (bcbebe).svg?ts=" + Date.now()} alt={t('archive icon')}></img></Table.Cell>
                                    <Table.Cell onClick={() => onOpen_mdl_sharelink(item.id)}><a>Share Link</a></Table.Cell>
                                </Table.Row>
                                )}
                            </Table.Body>
                            }
                        </Table>
                        {var_ready && var_totalrows === 0 &&
                        <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t('alert icon')}/>{t('there are no links to display')}</div>
                        }
                    </div>
                    <div className="content-filter">
                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_profilelinks && var_profilelinks.length > 0 ? var_totalrows : 0} populatefunction={populate_profilelinks}
                            />
                        </div>
                    </div>
                </div>

                {/***** MODAL: HISTORY ***********************************************************************************/}

                <Modal id="mdl_history"
                    dimmer={'inverted'}
                    open={var_mdl_history_open}
                    onClose={() => set_mdl_history_open(false)}>
                    <MDL_HISTORY
                        set_mdl_open={set_mdl_history_open}
                        var_modaltitle={var_modaltitle}
                        var_target_id={var_target_id}>
                    </MDL_HISTORY>
                </Modal>

                {/***** END MODAL: DETAILS *******************************************************************************/}



                {/***** MODAL: ADD LINK **********************************************************************************/}

                <Modal id="mdl_org_wallet_sharing_addlink"
                    dimmer={'inverted'}
                    open={var_mdl_org_wallet_sharing_addlink_open}
                    onClose={() => set_mdl_org_wallet_sharing_addlink_open(false)}>
                    <MDL_ADDLINK
                        set_mdl_open={set_mdl_org_wallet_sharing_addlink_open}
                        populate_function={populate_profilelinks}>
                    </MDL_ADDLINK>
                </Modal>

                {/***** END MODAL: ADD LINK ******************************************************************************/}



                {/***** MODAL: UPDATE LINK *******************************************************************************/}

                <Modal id="mdl_org_wallet_sharing_updatelink"
                    dimmer={'inverted'}
                    open={var_org_wallet_sharing_updatelink_open}
                    onClose={() => set_org_wallet_sharing_updatelink_open(false)}>
                    <MDL_UPDATELINK
                        set_mdl_open={set_org_wallet_sharing_updatelink_open}
                        populate_function={populate_profilelinks}
                        var_link_id={var_link_id}>
                    </MDL_UPDATELINK>
                </Modal>

                {/***** END MODAL: UPDATE LINK ***************************************************************************/}

                {/***** MODAL: SHARE LINK *****************************************************************************/}

                <Modal id="mdl_share_link"
                    dimmer={'inverted'}
                    open={var_mdl_sharelink_open}
                    onClose={() => set_mdl_sharelink_open(false)}>
                    <MDL_SHARELINK
                        set_mdl_open={set_mdl_sharelink_open}
                        var_modaltitle={var_modaltitle}
                        populate_function={populate_profilelinks}
                        var_link_id={var_link_id}>
                    </MDL_SHARELINK>
                </Modal>

                {/***** END MODAL: SHARE LINK *************************************************************************/}
            </div>
        </>
    )

}

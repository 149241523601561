import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { Table, Select, Modal } from 'semantic-ui-react';
import datelib from '../../../../libs/date-lib';

import { useTranslation } from 'react-i18next';
import '../../../../i18n';
import auth from '../../../../libs/auth-lib';

import CMP_TABLECONTROLS, {filter_helper} from '../../../../components/cmp_tablecontrols/cmp_tablecontrols';
import MDL_INDV from '../../../org/individuals/mdl_indv/mdl_indv';

import './crd_compliancereport.css';

export default function({var_activetab}) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_columns, set_columns ] = useState([]);
    const [ var_results, set_results ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(100);
    const [ var_offset, set_offset] = useState(0);
    const [ var_sortby, set_sortby ] = useState('score');
    const [ var_sortorder, set_sortorder ] = useState('descending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));
    const [ var_requirementoptions, set_requirementoptions ] = useState([]);
    const [ var_requirementids, set_requirementids ] = useState([]);
    const [ var_requirementschanged, set_requirementschanged ] = useState(false);
    const [ var_columnchanged, set_columnschanged ] = useState(false);
    const [ var_mdl_indv_open, set_mdl_indv_open ] = useState(false);
    const [ var_individual_id, set_individual_id ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_activetab === 'COMPLIANCEREPORT'){
            populate_lookup();
            set_filter(filter_helper.initialize(false));
        }
    },[var_activetab]);

    useEffect(()=>{
        if (var_columnchanged) {
            set_columnschanged(false);
        } else if (var_filter.load) {
            populate_results(null,0);
        }
    },[var_filter]);

    useEffect(()=>{
        if (var_columnchanged) {
            set_filter(filter_helper.initialize(false));
        }
    },[var_columnchanged]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_lookup() {
        set_requirementoptions(await API_get_dd_org_requirements());
    }

    async function populate_results(limit,offset,sortby,sortorder,filter,requirement_ids) {

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        filter = filter ? filter : var_filter;
        requirement_ids = requirement_ids || var_requirementids;

        try {
            let results = await API_get_compliance_report(limit,offset,sortby,sortorder,filter,undefined,requirement_ids);
            if (var_requirementschanged) {
                set_columns(results.requirements);
                set_requirementschanged(false);
                set_columnschanged(true);
            }
            set_results(results.results);
            set_totalrows(results.totalrows);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername,sortorder){
        try {
            return (await API_get_compliance_report(filter_helper.maxFilterItems,0,filtername,sortorder,{},filtername,var_requirementids)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_results(limit,offset){
        try {
            return (await API_get_compliance_report(limit,offset,var_sortby,var_sortorder,var_filter,undefined,var_requirementids)).results;
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_dd_org_requirements() {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-requirements', '/get-dd-org-requirements/'+auth.organization_id);
    }

    function API_get_compliance_report(limit,offset,sortby,sortorder,filter,filtername,requirement_ids) {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        if (requirement_ids.length === 0) {
            return {requirements: [], results: [], totalrows: 0};
        }
        return API.post('org-requirements', '/get-compliance-report/'+auth.organization_id,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    tz : datelib.timezone
                },
                body: { filter, requirement_ids }
            }
        );
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        } else {
            sortorder = sortorder === 'ascending' ? 'descending' : 'ascending';
        }
        populate_results(null,0,sortby,sortorder)
    }

    function onChange_requirements(event, data){
        set_requirementids(data.value);
        set_requirementschanged(true);
        set_offset(0);
        set_filter(filter_helper.initialize(false));
    }

    function onClick_individual(individual_id) {
        set_individual_id(individual_id);
        set_mdl_indv_open(true);
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="content-card-noheader" id="crd_compliancereport_container">
                <div className="content-card-gridcontent">
                    <div className="content-filter">
                        <Select
                            className='select_field'
                            id="requirement_ids"
                            name="requirement_ids"
                            label={t('REQUIREMENTS')}
                            placeholder={t('Requirements...')}
                            options={var_requirementoptions}
                            onChange={onChange_requirements}
                            value={var_requirementids}
                            fluid
                            search
                            multiple
                        />
                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_totalrows} populatefunction={populate_results}
                                downloadname='Compliance Report' downloadfunction={download_results}
                                var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                                table_config={[
                                    {name: 'individual', datatype: 'text', labelKey: 'INDIVIDUAL', download: true, filter: true},
                                    {name: 'organization', datatype: 'text', labelKey: 'ORGANIZATION', download: true, filter: true},
                                    {name: 'score', datatype: 'int', labelKey: 'SCORE', download: false, filter: true},
                                    {name: 'scorestring', datatype: 'text', labelKey: 'SCORE', download: true, filter: false},
                                    ...var_columns.map(item => ({name: item.columnname, datatype: 'text', label: item.name, download: true, filter: true}))
                                ]}
                            />
                        </div>
                    </div>
                    <div className="tablewrapper">
                        <Table unstackable sortable compact className={(var_loading ? " tbl_loading" : "")}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell sorted={var_sortby === 'individual' ? var_sortorder : null} onClick={() => onClick_sort('individual')}>{t('INDIVIDUAL')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'organization' ? var_sortorder : null} onClick={() => onClick_sort('organization')}>{t('ORGANIZATION')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'score' ? var_sortorder : null} onClick={() => onClick_sort('score')}>{t('SCORE')}</Table.HeaderCell>
                                    {
                                        var_columns.map(item =>
                                            <Table.HeaderCell key={item.id} sorted={var_sortby === item.columnname ? var_sortorder : null} onClick={() => onClick_sort(item.columnname)}>{item.name}</Table.HeaderCell>
                                    )}
                                </Table.Row>
                            </Table.Header>
                            {var_ready && var_results && var_results.length > 0 &&
                            <Table.Body>
                                {var_results.map((item) =>
                                    <Table.Row key={item.id} onClick={()=>onClick_individual(item.id)}>
                                        <Table.Cell>{item.individual}</Table.Cell>
                                        <Table.Cell>{item.organization}</Table.Cell>
                                        <Table.Cell>{item.scorestring}</Table.Cell>
                                        {var_columns.map(column =>
                                            <Table.Cell key={item.id+'-'+column.id}>
                                                {item[column.columnname] === 'YES'
                                                    ? <img src={"/icons/checkmark 30px (69b536).svg?ts=" + Date.now()} alt={t('valid requirement icon')}/>
                                                    : <img src={"/icons/x 30px (e0454f).svg?ts=" + Date.now()} alt={t('no valid requirement icon')}/>
                                                }
                                            </Table.Cell>
                                        )}
                                    </Table.Row>
                                )}
                            </Table.Body>
                            }
                        </Table>
                        {var_ready && var_totalrows === 0 &&
                        <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t('alert icon')}/>{t('No individuals have been assigned to this requirement at this time')}</div>
                        }
                    </div>
                    <div>
                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_totalrows} populatefunction={populate_results}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/***** MODAL: INDIVIDUAL ****************************************************************************/}

            <Modal id="mdl_org_individuals_indv"
                   dimmer={'inverted'}
                   open={var_mdl_indv_open}
                   onClose={() => set_mdl_indv_open(false)}>
                <MDL_INDV
                    set_mdl_open={set_mdl_indv_open}
                    var_individual_id={var_individual_id}
                    populate_function={populate_results}>
                </MDL_INDV>
            </Modal>

            {/***** END MODAL: INDIVIDUAL ************************************************************************/}
        </>
    )
}
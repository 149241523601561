import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import './mdl_report.css';


export default function({ set_mdl_open, var_report }){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();



    //  variable listeners ---------------------------------------------------------------------------------------------



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{t("Report")}</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t("x icon")} /></div>
            </div>
        </>
    )
}
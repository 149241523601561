import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from 'semantic-ui-react';
import datelib from '../../../../../../libs/date-lib';
import { useTranslation } from 'react-i18next';
import '../../../../../../i18n';

import MDL_INDV_DAILYCHECKS from "../../../../../org/overview/tab_dailycheck/mdl_indv_dailychecks/mdl_indv_dailychecks";
import MDL_DAILYCHECKREPORT from './mdl_dailycheckreport/mdl_dailycheckreport';
import MDL_DAILYCHECKOTHERLOC from './mdl_dailycheckotherloc/mdl_dailycheckotherloc';
import CMP_TABLECONTROLS, { filter_helper } from '../../../../../../components/cmp_tablecontrols/cmp_tablecontrols';

import './tab_dailychecks.css';



export default function({ var_activetab, var_location_id }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_dailychecks, set_dailychecks ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(10);
    const [ var_offset, set_offset] = useState(0);
    const [ var_sortby, set_sortby ] = useState('individual');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));

    const [ var_mdl_open, set_mdl_open ] = useState(false);
    const [ var_individual, set_individual ] = useState('');
    const [ var_individual_id, set_individual_id ] = useState('');
    const [ var_mdl_dailycheckreport_open, set_mdl_dailycheckreport_open ] = useState(false);
    const [ var_mdl_dailycheckotherloc_open, set_mdl_dailycheckotherloc_open ] = useState(false);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_activetab === 'DAILYCHECKS') {
            set_filter(filter_helper.initialize(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_activetab]);

    useEffect(()=>{
        if(var_filter.load) {
        populate_dailychecks(null, 0);
        }
       },[var_filter]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_dailychecks(limit,offset,sortby,sortorder,filter){
        if (var_activetab !== 'DAILYCHECKS') return; // Don't load data if tab is not visible

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_loc_dailychecks(limit,offset,sortby,sortorder,filter);
            set_dailychecks(results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername,sortorder){
        try {
        return await API_get_loc_dailychecks(filter_helper.maxFilterItems,0,filtername,sortorder,{},filtername);
        } catch (e) {
        console.log(e);
        }
    }

    async function download_loc_dailychecks(limit, offset){
        try {
        return await API_get_loc_dailychecks(limit,offset,var_sortby,var_sortorder,var_filter);
        } catch (e){
        console.log(e);
        throw e;
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_loc_dailychecks(limit,offset,sortby,sortorder,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-dailychecks', '/get-loc-dailychecks/'+var_location_id,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    tz: datelib.timezone,
                    filtername: filtername
                },
                body: filter
            }
        );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_dailychecks('',0,sortby,sortorder)
    }

    function onOpen_mdl_history(individual_id,individual) {
        set_individual(individual);
        set_individual_id(individual_id);
        set_mdl_open(true);
    }

    function onOpen_dailycheckreport() {
        set_mdl_dailycheckreport_open(true);
    }

    function onOpen_mdl_dailycheckotherloc(individual_id,individual) {
        set_individual(individual);
        set_individual_id(individual_id);
        set_mdl_dailycheckotherloc_open(true);
    }

    // RENDER APP ======================================================================================================

    return (
        <div className="modal-tab" id="tab_org_location_dailycheck">
            <div className="content-filter">
                <div className="content-filter-item item-pagination">
                    <CMP_TABLECONTROLS
                            var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                            total_rows={var_dailychecks && var_dailychecks.length > 0 ? var_dailychecks[0]['totalrows'] : 0} populatefunction={populate_dailychecks}
                            downloadname="Daily Checks" downloadfunction={download_loc_dailychecks}
                            var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                            table_config={[
                                {name: 'dailycheck', datatype: 'text', labelKey: 'TODAY', download: true, filter: true},
                                {name: 'individual', datatype: 'text', labelKey: 'NAME', download: true, filter: true},
                                {name: 'email', datatype: 'text', labelKey: 'EMAIL', download: true, filter: true},
                                {name: 'organization', datatype: 'text', labelKey: 'ORGANIZATION', download: true, filter: true},
                                {name: 'location', datatype: 'text', labelKey: 'OTHER LOC/PROJ', download: true, filter: true},
                                {name: 'dailychecklastdone', datatype: 'text', labelKey: 'LAST CHECK DATE', download: true, filter: false},
                                {name: 'epochlastdone', datatype: 'date', labelKey: 'LAST CHECK DATE', download: false, filter: true},
                                {name: 'fittoreturn', datatype: 'int', labelKey: 'RETURN DATE', download: true, filter: false},
                                {name: 'epochfittoreturn', datatype: 'date', labelKey: 'RETURN DATE', download: false, filter: true}

                            ]}
                        />
                </div>
            </div>
            <div className="tablewrapper">
                <Table sortable compact className={(var_loading ? " tbl_loading" : "")}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell sorted={var_sortby === 'dailycheck' ? var_sortorder : null} onClick={()=>onClick_sort('dailycheck')}>{t("TODAY")}</Table.HeaderCell>
                            {renderHeaderCell('individual','NAME')}
                            {renderHeaderCell('email','EMAIL')}
                            <Table.HeaderCell sorted={var_sortby === 'organization' ? var_sortorder : null} onClick={() => onClick_sort('organization')}>{t("ORGANIZATION")}</Table.HeaderCell>
                            {renderHeaderCell('location', 'OTHER LOC/PROJ')}
                            {renderHeaderCell('epochlastdone','LAST CHECK DATE')}
                            {renderHeaderCell('epochfittoreturn','RETURN DATE')}
                            <Table.HeaderCell className="td_icon_center">{t("HSTRY")}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    {var_ready && var_dailychecks[0]['totalrows'] !== 0 &&
                    <Table.Body>
                        {var_dailychecks.map((item, i) =>
                            <Table.Row key={i} >
                                <Table.Cell className="td_icon_center">
                                    {item.dailycheck === 'PASS' &&
                                    <div className='tbl_icon'> <img src={"/icons/checkmark 30px (69b536).svg?ts=" + Date.now()} alt={t("active icon")}/></div>
                                    }
                                    {item.dailycheck === 'FAIL' &&
                                    <div className='tbl_icon'><img src={"/icons/x 30px (e0454f).svg?ts=" + Date.now()} alt={t("inactive icon")}/></div>
                                    }
                                    {item.dailycheck === 'NONE' &&
                                    <div className='tbl_icon'><img src={"/icons/circle 30px (bcbebe).svg?ts=" + Date.now()} alt={t("inactive icon")}/></div>
                                    }
                                </Table.Cell>
                                <Table.Cell>{item.individual}</Table.Cell>
                                <Table.Cell>{item.email}</Table.Cell>
                                <Table.Cell>{item.organization}</Table.Cell>
                                <Table.Cell>
                                    {item.location &&
                                        <div onClick={() => onOpen_mdl_dailycheckotherloc(item.individual_id, item.individual)}>{t(item.location)}</div>
                                    }
                                </Table.Cell>
                                <Table.Cell>{datelib.datetime_localized_format(item.dailychecklastdone)}</Table.Cell>
                                <Table.Cell>
                                    {item.fittoreturn !== null &&
                                    <>
                                        <div className='tbl_checkmark'><img src={"/icons/alert 60px (DB2430).svg?ts=" + Date.now()} alt={t("alert icon")}></img></div>
                                        {datelib.date_localized_format(item.fittoreturn)}
                                    </>
                                    }
                                </Table.Cell>
                                {item.indv_in_loc === 'YES' ?
                                    <Table.Cell className="td_icon_center" onClick={() => onOpen_mdl_history(item.individual_id, item.individual)}><img src={"/icons/history 60px (bcbebe).svg?ts=" + Date.now()} alt={t("history icon")}></img></Table.Cell>
                                    :<Table.Cell></Table.Cell>
                                }
                            </Table.Row>
                        )}
                    </Table.Body>
                    }
                </Table>
                {var_ready && var_dailychecks[0]['totalrows'] === 0 &&
                <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t("alert icon")}/>{t("there are no daily checks to display")}</div>
                }
            </div>
            <div className="content-filter">
                <div className="content-filter-item item-pagination">
                    <CMP_TABLECONTROLS
                        var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                        total_rows={var_dailychecks && var_dailychecks.length > 0 ? var_dailychecks[0]['totalrows'] : 0} populatefunction={populate_dailychecks}
                    />
                </div>
            </div>
            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className={"btn_primary " + (var_ready ? 'btn_active' : '')} onClick={() => onOpen_dailycheckreport()}>{t("DAILY CHECKS REPORT")}</Button>
                </div>
            </div>


            {/***** MODAL: INDIVIDUAL DAILY CHECKS ***************************************************************/}

            <Modal id="mdl_indv_dailychecks"
                   dimmer={'inverted'}
                   open={var_mdl_open}
                   onClose={() => set_mdl_open(false)}>
                <MDL_INDV_DAILYCHECKS
                    var_individual={var_individual}
                    var_individual_id={var_individual_id}
                    set_mdl_indv_dailychecks_open={set_mdl_open}
                    populate_dailychecks={populate_dailychecks}>
                </MDL_INDV_DAILYCHECKS>
            </Modal>

            {/***** END MODAL: INDIVIDUAL DAILY CHECKS ***********************************************************/}

            {/***** MODAL: DAILY CHECK REPORT ***************************************************************/}

            <Modal id="org-locations_mdl_dailycheckreport"
                   dimmer={'inverted'}
                   open={var_mdl_dailycheckreport_open}
                   onClose={() => set_mdl_dailycheckreport_open(false)}>
                <MDL_DAILYCHECKREPORT
                    set_mdl_open={set_mdl_dailycheckreport_open}
                    var_location_id={var_location_id}>
                </MDL_DAILYCHECKREPORT>
            </Modal>

            {/***** END MODAL: DAILY CHECK REPORT ***********************************************************/}

            {/***** MODAL: DAILY CHECK OTHER LOCATIONS ***************************************************************/}

            <Modal id="org-locations_mdl_dailycheckotherloc"
                   dimmer={'inverted'}
                   open={var_mdl_dailycheckotherloc_open}
                   onClose={() => set_mdl_dailycheckotherloc_open(false)}>
                <MDL_DAILYCHECKOTHERLOC
                    set_mdl_open={set_mdl_dailycheckotherloc_open}
                    var_individual={var_individual}
                    var_individual_id={var_individual_id}
                    populate_dailychecks={populate_dailychecks}>
                </MDL_DAILYCHECKOTHERLOC>
            </Modal>

            {/***** END MODAL: DAILY CHECK OTHER LOCATIONS ***********************************************************/}

        </div>
    )

    function renderHeaderCell(field,labelkey){
        return (
            <Table.HeaderCell sorted={var_sortby === field ? var_sortorder : null} onClick={()=>onClick_sort(field)}>
                {t(labelkey)}
            </Table.HeaderCell>
        );
    }

};

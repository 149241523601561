import React from 'react';
import { Table, TableBody } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';
import datelib from '../../../../../libs/date-lib';

import './crd_credentials.css';
import CMP_CREDENTIALSTATUS from '../../../../../components/cmp_credentialstatus';

export default function({var_credentials, var_linkid}){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();

    // RENDER APP ======================================================================================================

    return (
        <>
            <section className="profile_section">

                <div className="profile_section_header">{t('Credentials')}</div>

                <div className="profile_section_body">
                    <div className="tablewrapper reset_table_pointer">
                        <Table unstackable compact>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell className="td_loc_icon_center" textAlign='center'>{t('Status')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('Credentials')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_issuedby">{t('Issued by')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_issued">{t('Issued')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_expires">{t('Expires')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_daysleft">{t('Days Left')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_verification">{t('Verification')}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <TableBody>
                            {var_credentials.map((item, i) =>
                                <Table.Row key={i} className={(item.verification_status === 'VALID' && item.confirmation_status === 'SELF VERIFIED') ? "self_verified" : ''}>
                                    <Table.Cell className="view_certificate mobile_view_display" textAlign='center'>
                                        <CMP_CREDENTIALSTATUS confirmation_status={item.confirmation_status} verification_status={item.verification_status} clickfor_publiccert={true} id={item.id} has_customsecurity={item.has_customsecurity} var_linkid={var_linkid} is_location={true} />
                                    </Table.Cell>
                                    <Table.Cell onClick={() => window.alert('HI!')} className="td_loc_icon_center mobile_view_hide" textAlign='center'>
                                        {(item.verification_status === 'VALID' && item.confirmation_status !== 'SELF VERIFIED') &&
                                        <img src={"/icons/checkmark 30px (69b536).svg?ts=" + Date.now()} alt={t('active icon')}/>
                                        }
                                        {item.verification_status === 'INVALID' &&
                                        <img src={"/icons/x 30px (e0454f).svg?ts=" + Date.now()} alt={t('inactive icon')}/>
                                        }
                                        {item.verification_status === 'PENDING' &&
                                        <img src={"/icons/warning 30px (ffae00).svg?ts=" + Date.now()} alt={t('missing icon')}/>
                                        }
                                        {(item.verification_status === 'VALID' && item.confirmation_status === 'SELF VERIFIED') &&
                                        <img src={"/icons/checkmark 30px (4dcbd4).svg?ts=" + Date.now()} alt={t('self verified icon')}/>
                                        }
                                        {item.verification_status !== 'VALID' && item.verification_status !== 'INVALID' && 
                                        item.verification_status !== 'PENDING' && <img src={"/icons/circle 30px (bcbebe).svg?ts=" + Date.now()} alt={t('inactive icon')}/>}
                                    </Table.Cell>
                                    <Table.Cell className="td_overflow">{item.credential}</Table.Cell>

                                    <Table.Cell className="td_issuedby">{item.issuedby}</Table.Cell>
                                    <Table.Cell className="td_issued">{datelib.date_localized_format(item.issued)}</Table.Cell>
                                    <Table.Cell className="td_expires">{datelib.date_localized_format(item.expires)}</Table.Cell>
                                    <Table.Cell className="td_daysleft">{item.daysleft}</Table.Cell>
                                    <Table.Cell className='td_verification view_certificate'>
                                        <CMP_CREDENTIALSTATUS confirmation_status={item.confirmation_status} verification_status={item.verification_status} clickfor_publiccert={true} id={item.id} has_customsecurity={item.has_customsecurity} var_linkid={var_linkid} is_location={true} />
                                    </Table.Cell>
                                </Table.Row>
                            )}
                            </TableBody>
                        </Table>
                        {var_credentials.length === 0 &&
                            <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t('alert icon')}/>{t('there are no credentials to display')}</div>
                        }
                    </div>
                </div>
            </section>
        </>
    )

}
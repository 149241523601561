import React from "react";

import CRD_DETAILS from "./crd_details/crd_details";
import CRD_CREDENTIALS from "./crd_credentials/crd_credentials";
import CRD_DAILYCHECKS from "./crd_dailychecks/crd_dailychecks";
import CRD_ONSITE from "./crd_onsite/crd_onsite";
import CRD_CREDENTIALSATTACHMENTS from "./crd_credentialsAttachments/crd_credentialsAttachments";

import "./profile.css";


export default function({var_individual, var_org, var_linkid}) {

    //  variable declarations ------------------------------------------------------------------------------------------


    //  event listeners ------------------------------------------------------------------------------------------------


    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------


    // RENDER APP ======================================================================================================

    return(
            <>
                <div className="profile_wrapper">

                    <CRD_DETAILS
                        var_detail={var_individual.detail}
                        var_org={var_org}
                        />


                    <div className="profile_content">

                        {var_individual.credentials &&
                            <CRD_CREDENTIALS
                                var_credentials={var_individual.credentials}
                                var_linkid={var_linkid}
                            />
                        }

                        {var_individual.dailychecks &&
                            <CRD_DAILYCHECKS
                                var_dailychecks={var_individual.dailychecks}
                            />
                        }

                        {var_individual.onsite &&
                            <CRD_ONSITE
                                var_onsite={var_individual.onsite}
                            />
                        }

                        {var_individual.credentials &&
                            <CRD_CREDENTIALSATTACHMENTS
                                var_credentials={var_individual.credentials}
                            />
                        }

                    </div>

                </div>

            </>
    )

};

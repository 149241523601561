const form_helper = {
    cleanse_string_property: function(the_object, property_name) {
        the_object[property_name] = !the_object[property_name] || the_object[property_name].trim().length === 0 ? null : the_object[property_name].trim();
    },
    // validations
    validate_required_string: function(value) {
        return value && value.trim().length > 0;
    },
    validate_email_address: function(value, allow_displayname = false) {
        // email regular expression from: http://emailregex.com
        let backtick = '`';
        let email_pattern = String.raw`(?:[a-z0-9!#$%&'*+/=?^_${backtick}{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_${backtick}{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])`;
        // check if the value is just an email
        let email_only_check = new RegExp(`^${email_pattern}$`, 'i').test(value.trim());
        if (!allow_displayname || email_only_check) return email_only_check;
        // not sure on the allowed characters for the display name
        // so I just made it that it must start with a non-space character, followed by 0 or more other characters of any type
        return new RegExp(String.raw`^\S.*?\s<${email_pattern}>$`, 'i').test(value.trim());
    }
};

export default form_helper;
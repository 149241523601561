import React, { useState, useEffect} from "react";
import { Message } from 'semantic-ui-react'
import { useHistory } from "react-router-dom";
import Amplify, { API, Auth } from "aws-amplify";
import config from "../../config";
import {
    CognitoUser,
    CognitoUserSession,
    CognitoIdToken,
    CognitoRefreshToken,
    CognitoAccessToken,
} from 'amazon-cognito-identity-js';
import { CognitoIdentityCredentials } from 'aws-sdk';
import { useTranslation } from 'react-i18next';
import '../../i18n';
import auth from '../../libs/auth-lib';
import datelib from '../../libs/date-lib';

import "./saml.css";


export default function(appProps) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const history = useHistory();
    const { t } = useTranslation();
    // eslint-disable-next-line no-unused-vars
    const [var_login_step, set_login_step] = useState('LOGIN');

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect( ()=>{
        setTimeout(openSaml, 100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function openSaml() {
        sessionStorage.removeItem('redirect');

        localStorage.setItem('SAML',JSON.stringify({loading: true}));
        let redirect_uri = config.cognito.redirect_uri;
        let provider_name = "Nasdaq";
        let user_pool_client_id = config.cognito.USER_POOL_ID;
        let org_id = config.cognito.org_id;
        let app_client_id = config.cognito.APP_CLIENT_ID;
        let identity_provider;
        let sso_logout = 'NO';

        let sso_configuration = await API_get_sso_configuration(appProps.match.params.orgname);

        if (sso_configuration && sso_configuration.length > 0) {
            sso_configuration = sso_configuration[0];
            redirect_uri = sso_configuration.redirect_uri;
            provider_name = appProps.match.params.orgname;
            user_pool_client_id = sso_configuration.app_client_id;
            org_id = sso_configuration.organization_id;
            app_client_id = sso_configuration.app_client_id;
            identity_provider = sso_configuration.identity_provider;
            sso_logout = sso_configuration.sso_logout;
        }

        let details = {
            'grant_type': 'authorization_code',
            'client_id': app_client_id,
            'code': appProps.match.params.code,
            'redirect_uri': redirect_uri
        };

        let formBody = [];
        for (let property in details) {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        console.log(formBody);

        fetch(config.cognito.oauth_fetch, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: formBody
        })
            .then(response => response.json())
            .then(async json => {
                let idData = JSON.parse(atob(json.access_token.split('.')[1]));
                console.log(json);
                console.log(idData);

                // Try logging in
                Amplify.configure({
                    userPoolWebClientId: user_pool_client_id, //switch to saml client
                });

                const localSession = new CognitoUserSession({
                    IdToken: new CognitoIdToken({ IdToken: json.id_token }),
                    RefreshToken: new CognitoRefreshToken({ RefreshToken: json.refresh_token }),
                    AccessToken: new CognitoAccessToken({ AccessToken: json.access_token }),
                });

                // Add the session to the identity pool
                let options = {
                    IdentityPoolId: config.cognito.IDENTITY_POOL_ID,
                    Logins: {}
                };
                options.Logins[config.cognito.idp] = json.id_token;
                const credentials = new CognitoIdentityCredentials(options);
                const localUser = new CognitoUser({
                    Username: idData.username,
                    Pool: Auth.userPool,
                    Storage: Auth.userPool.storage,
                });
                localUser.setSignInUserSession(localSession);

                // this seems like a hack
                Auth.currentCredentials = async () => credentials; // = async () => localSession;

                try {
                    await Auth.currentSession();

                    // Set default values
                    let firstname = 'System';
                    let lastname = 'User';
                    let email = idData.username.replace(identity_provider+'_','');
                    let role = 'User';
                    let identities = [{"providerName":provider_name}];

                    let cognitoData = await Auth.currentUserInfo();
                    console.log('Current User Info:');
                    console.log(cognitoData);

                    if(cognitoData.attributes.hasOwnProperty('given_name')) {
                        firstname = cognitoData.attributes.given_name;
                    }
                    if(cognitoData.attributes.hasOwnProperty('family_name')) {
                        lastname = cognitoData.attributes.family_name;
                    }
                    if(cognitoData.attributes.hasOwnProperty('email')) {
                        email = cognitoData.attributes.email;
                    }
                    if(cognitoData.attributes.hasOwnProperty('profile')) {
                        role = cognitoData.attributes.profile;
                    }
                    if(cognitoData.attributes.hasOwnProperty('identities')) {
                        identities = JSON.parse(cognitoData.attributes.identities);
                    }

                    if(identities[0]['providerName'] === 'BVC') {
                        org_id = 'DFACF8D4-19A4-4373-88D5-4912465F90EA';
                        role = 'SUPERADMIN';
                    }

                    console.warn(`SAML login current session!!`);

                    // Create or update user details in DB - NASDAQ Specific
                    fetch(config.cognito.post_indiv + idData.username + '/' + org_id, {
                        method: 'POST',
                        body: JSON.stringify({
                            'firstname': firstname,
                            'lastname': lastname,
                            'email': email,
                            'role': role,
                            'default_language': window.navigator.language.split('-')[0]
                        })
                    })
                        .then(response => response.json())
                        .then(async json => {

                            if(sessionStorage.getItem('sso_invite')){
                                let invite = JSON.parse(sessionStorage.getItem('sso_invite'));
                                if (invite.inviteid) {
                                    API_put_invite_accept(invite.inviteid);
                                } else {
                                    API_post_invite_qrcode(invite.location_id);
                                }
                                sessionStorage.removeItem('sso_invite');
                            }

                            let resultAuth = await API_get_authentication();

                            //check if account has been archived
                            if (resultAuth === 'NO ACTIVE ACCOUNT'){
                                alert('NO ACTIVE ACCOUNT');
                                history.push("/");
                            }

                            sessionStorage.setItem('username', localUser.username);

                            let authentication = await auth.parse_authentication_result(resultAuth);
                            authentication['authenticated'] = true;
                            authentication['cognito_id'] = localUser.username;

                            let default_language = "en";
                            if (authentication.language !== undefined && authentication.language !== null && authentication.language !== "" && (authentication.language === "en" || authentication.language === "fr")) {
                                default_language = authentication.language;
                                authentication.language = config.language[authentication.language];
                            } else {
                                const browser_language = window.navigator.language.split('-')[0];
                                if (browser_language === "en" || browser_language === "fr") {
                                    default_language = browser_language;
                                    authentication.language = config.language[browser_language];
                                } else {
                                    authentication.language = config.language.en;
                                }
                                await API_put_indv(authentication.id, default_language);
                            }
                            appProps.set_appDefaultLanguage(default_language);
                            sessionStorage.setItem('authentication',JSON.stringify(authentication));
                            auth.clear();

                            // SSO config
                            let sso_config = {
                                sso_org: appProps.match.params.orgname,
                                sso_client: user_pool_client_id,
                                sso_logout: sso_logout !== 'YES' ? null :
                                    `${config.cognito.oauth_fetch.substring(0, config.cognito.oauth_fetch.indexOf('oauth2'))}logout?client_id=${user_pool_client_id}&logout_uri=` +
                                        `${config.cognito.redirect_uri.substring(0, config.cognito.redirect_uri.indexOf('get-saml'))}get-saml-logout/${provider_name}`
                            };
                            sessionStorage.setItem('sso_config', JSON.stringify(sso_config));

                            let results = await API_get_systemstatus();
                            if(results.length > 0  && results[0].systemstatus === 'OFFLINE') {
                                await auth.logout(history);
                            }else{
                                if (JSON.parse(sessionStorage.getItem('authentication')).organization_id === config.cognito.org_id) {
                                    history.push('/indv/wallet')
                                } else {
                                    history.push("/");
                                }
                            }
                        });
                } catch (ex) {
                    console.warn(`SAML login ${ex}`);
                }
            });
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_systemstatus() {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.get('admin', '/get-systemstatus?tz='+datelib.querystring_timezone);
    }

    function API_put_invite_accept(inviteid) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.put('admin', '/put-invite-accept/' + inviteid);
    }

    function API_post_invite_qrcode(location_id) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.post('admin', '/post-invite-qrcode', {body: {location_id: location_id}});
    }

    function API_get_authentication(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('admin', '/get-authentication');
    }

    function API_put_indv(individual_id,default_language) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.put('indv', '/put-indv/' + individual_id, { body: { default_language } });
     }

    function API_get_sso_configuration(organization_code) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.get('org', '/get-sso-configuration/' + organization_code);
    }

    // RENDER APP ======================================================================================================

    return(

        <div className="login_wrapper">
            <div className="login">
                <div className="login_logo">
                </div>
                {var_login_step === 'LOGIN' &&
                <div>
                    <Message>{t('Loading user data...')}</Message>
                </div>
                }
            </div>
        </div>
    )
}

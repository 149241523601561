import { Button, Form } from 'semantic-ui-react'
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";

import { useTranslation } from 'react-i18next';
import '../../../../../../../i18n';
import './mdl_updatelink.css';



export default function({ set_mdl_open, populate_function, var_link_id }){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();
    const [ var_link, set_link] = useState(false);
    const [ var_ready, set_ready] = useState(false);
    const [ var_processing, set_processing] = useState(false);

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_profilelink()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_profilelink(){
        try {
            let result = await API_get_indv_profilelink();
            result[0]['individual_id'] = JSON.parse(sessionStorage.getItem('authentication')).id;
            result[0]['transactionby'] = JSON.parse(sessionStorage.getItem('authentication')).id;
            result[0]['transactionorg'] = JSON.parse(sessionStorage.getItem('authentication')).organization_id;
            set_link(result[0]);
        } catch (e) {
            console.log(e);
        }
    }


    async function update_profilelink(){
        set_processing(true);
        try {
            await API_put_indv_profilelink();
            set_ready(false);
            set_processing(false);
            set_mdl_open(false);
            populate_function();
        } catch (e) {
            console.log(e);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_indv_profilelink(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('indv', '/get-indv-profilelink/'+var_link_id);
    }


    function API_put_indv_profilelink(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.put('indv', '/put-indv-profilelink/'+var_link_id, { body: var_link });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_radio(event, { value }){
        const link = { ...var_link };
        link['status'] = value;
        set_link(link);
        set_ready(true);
    }


    function onClick_submit(){
        if(var_ready){
            update_profilelink();
        }
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{t('Update Profile Link')}</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t('close icon')}/></div>
            </div>
            <div className="modal-content">
                <Form>
                    <label>LINK STATUS</label>
                    {var_link &&
                    <Form.Group inline>
                        <Form.Radio
                            label={t('Active')}
                            value='ACTIVE'
                            checked={var_link.status === 'ACTIVE'}
                            onChange={onChange_radio}
                        />
                        < Form.Radio
                            label={t('Inactive')}
                            value='REVOKED'
                            checked={var_link.status === 'REVOKED'}
                            onChange={onChange_radio}
                            />
                    </Form.Group>
                    }
                </Form>
            </div>
            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>{t('CANCEL')}</Button>
                    {!var_processing ?
                        <Button className={"btn_primary " + (var_ready ? ' btn_active' : '')} onClick={() => onClick_submit()}>{t('UPDATE LINK')}</Button>
                        :
                        <Button loading className="btn_primary btn_active">{t('UPDATE LINK')}</Button>
                    }
                </div>
            </div>
        </>
    )
}
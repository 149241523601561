/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import auth from '../../../libs/auth-lib';

import STA_DAILYCHECK from "./sta_dailycheck/sta_dailycheck";
import STA_r2k from "./sta_r2k/sta_r2k";
import STA_CREDENTIALS from "./sta_credentials/sta_credentials";
import STA_issuer from "./sta_issuer/sta_issuer";
import TAB_dailycheck from "./tab_dailycheck/tab_dailycheck";
import TAB_CREDENTIALS from "./tab_credentials/tab_credentials";
import TAB_REQUIREMENTS from "./tab_requirements/tab_requirements";

import "./overview.css";



export default function(appProps) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const history = useHistory();

    const [ var_activetab, set_activetab ] = useState('DAILYCHECK')

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if (auth.is_loggedin && auth.has_access('ORG', 'overview')) {
            appProps.set_visibleframe(true);
            appProps.set_visiblemenu('ORGANIZATION');
            appProps.set_activemenu('/org/overview');
            (!auth.org_dailycheck) &&
                set_activetab('CREDENTIALS');

        }else{
            history.push('/login');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);



    //  async functions ------------------------------------------------------------------------------------------------

    //  API calls ------------------------------------------------------------------------------------------------------

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_tab(var_tab){
        set_activetab(var_tab);
    }


    // RENDER APP ======================================================================================================

    return(
        <>
            {auth.is_loggedin &&
            <div className="content">
                <div className="content-topmenu"><img src={"/icons/globe 60px (585b5a).svg?ts=" + Date.now()} alt={t("globe icon")}></img>{auth.actingorganization} {t("Overview")}
                </div>
                <div className="content-wrapper">

                    <div className="statswrapper">
                        {auth.org_dailycheck &&
                        <STA_DAILYCHECK></STA_DAILYCHECK>
                        }
                        {auth.org_credentials &&
                        <STA_CREDENTIALS></STA_CREDENTIALS>
                        }
                        {auth.org_r2w &&
                        <STA_r2k></STA_r2k>
                        }

                        {auth.org_issuer &&
                        <STA_issuer></STA_issuer>
                        }
                    </div>

                    <div className="content-tabs">
                        {auth.org_dailycheck &&
                        <div
                            className={"content-tabs-item " + (var_activetab === 'DAILYCHECK' ? 'content-tabs-item-active' : '')} onClick={() => onClick_tab('DAILYCHECK')}><img src={"/icons/checkmark 60px (717473).svg?ts=" + Date.now()} alt={t("daily check icon")}></img>{t("Today's Daily Checks")}
                        </div>
                        }
                        {auth.org_credentials &&
                        <div className={"content-tabs-item " + (var_activetab === 'CREDENTIALS' ? 'content-tabs-item-active' : '')} onClick={() => onClick_tab('CREDENTIALS')}><img src={"/icons/credential 60px (717473).svg?ts=" + Date.now()} alt={t("credential icon")}></img>{t("Credentials")}</div>
                        }
                        {auth.org_requirements &&
                        <div className={"content-tabs-item " + (var_activetab === 'REQUIREMENTS' ? 'content-tabs-item-active' : '')} onClick={() => onClick_tab('REQUIREMENTS')}><img src={"/icons/credential 60px (717473).svg?ts=" + Date.now()} alt={t("requirements icon")}></img>{t("Requirements")}</div>
                        }

                    </div>

                    {auth.org_dailycheck &&
                        <div className={(var_activetab !== 'DAILYCHECK' ? 'content-card-hide' : '')}>
                            <TAB_dailycheck
                                var_activetab={var_activetab}>
                            </TAB_dailycheck>
                    </div>
                    }
                    {auth.org_credentials &&
                        <div className={(var_activetab !== 'CREDENTIALS' ? 'content-card-hide' : '')}>
                            <TAB_CREDENTIALS
                                var_activetab={var_activetab}>
                            </TAB_CREDENTIALS>
                        </div>
                    }
                    {auth.org_requirements &&
                        <div className={(var_activetab !== 'REQUIREMENTS' ? 'content-card-hide' : '')}>
                            <TAB_REQUIREMENTS
                                var_activetab={var_activetab}>
                            </TAB_REQUIREMENTS>
                        </div>
                    }
                </div>
            </div>
            }
        </>
    )
};

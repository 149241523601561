import { Button, Modal, Table } from 'semantic-ui-react'
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import React from "react";
import datelib from '../../../../libs/date-lib';

import CMP_TABLECONTROLS, { filter_helper } from '../../../../components/cmp_tablecontrols/cmp_tablecontrols';
import MDL_HISTORY from "../../../../components/cmp_history/cmp_history";
import MDL_ADD from "./mdl_add/mdl_add";
import MDL_UPDATE from "./mdl_update/mdl_update";

import './crd_credentials.css';

import { useTranslation } from 'react-i18next';
import '../../../../i18n';

export default function(appProps){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();

    const [ var_credentials, set_credentials ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(100);
    const [ var_offset, set_offset] = useState(0);
    const [ var_archive, set_archive ] = useState('NO');
    const [ var_sortby, set_sortby ] = useState('credential');
    const [ var_sortorder, set_sortorder ] = useState('ascending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));

    const [ var_mdl_history_open, set_mdl_history_open ] = useState(false);
    const [ var_modaltitle, set_modaltitle ] = useState('');
    const [ var_target_id, set_target_id ] = useState('');

    const [ var_mdl_add_open, set_mdl_add_open ] = useState(false);
    const [ var_mdl_update_open, set_mdl_update_open ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_archive !== undefined) {
            let filter = filter_helper.initialize(false);
            set_filter(filter);
            populate_credentials(null, null, null, null, null, filter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_archive]);


    useEffect(()=>{
        if(var_filter.load) {
        populate_credentials(null, 0);
        }
       },[var_filter]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_credentials(limit,offset,sortby,sortorder,archive,filter){

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }


        if(archive) {
            set_archive(archive);
        }else{
            archive=var_archive
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_credentials(limit,offset,sortby,sortorder,archive,filter);
            set_credentials(results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername,sortorder){
        try {
            return await API_get_credentials(filter_helper.maxFilterItems, 0, filtername, sortorder, var_archive, [], filtername);
        } catch (e) {
            console.log(e);
        }
    }

    async function download_org_credentials(limit, offset){
        try {
            return await API_get_credentials(limit, offset, var_sortby, var_sortorder, var_archive, var_filter);
        }  catch (e) {
             console.log(e);
             throw e;
         }
     }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_credentials(limit,offset,sortby,sortorder,archive,filter, filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-credentials',
                        '/get-org-credentials/'+JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id,
                        {queryStringParameters: {
                            limit: limit,
                            offset: offset,
                            sortby: sortby,
                            sortorder: sortorder,
                            archive: archive,
                            tz : datelib.timezone,
                            filtername: filtername
                        },
                        body: filter
                    }
                    );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_credentials('',0,sortby,sortorder)
    }

    function onApply_filter(filter){
        populate_credentials(null,0,null,null,null,filter);
    }

    function onOpen_mdl_history(target_id, target_name) {
        set_modaltitle(t("Credential History")+' - '+target_name);
        set_target_id(target_id);
        set_mdl_history_open(true)
    }

    function onClick_addcredential(){
        set_mdl_add_open(true);
    }

    function onClick_update(var_target_id){
        set_target_id(var_target_id);
        set_mdl_update_open(true)
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="content-card" id="crd_org_individuals_individuals">
                <div className="content-card-header">
                    {var_archive === 'NO' ?
                        <div className="content-card-header-title">{t('All Active Credentials')}</div>
                        :
                        <div className="content-card-header-title">{t('All Archived Credentials')}</div>
                    }
                    <Button className="btn_tertiary" onClick={()=>onClick_addcredential()}><img className="btn_icon" src={"/icons/add 30px (4dcbd4).svg?ts=" + Date.now()} alt={t('add icon')}/>{t('ADD CREDENTIAL')}</Button>
                </div>
                <div className="content-card-gridcontent">
                    <div className="content-filter">

                        <div className="content-filter-archive">
                            {var_archive === 'NO' ?
                                <Button className="btn_tertiary" onClick={() => set_archive('YES')}><img className="btn_icon" src={"/icons/archive 30px (4dcbd4).svg?ts=" + Date.now()} alt={t('archive icon')}/>{t('VIEW ARCHIVED CREDENTIALS')}</Button>
                                :
                                <Button className="btn_tertiary" onClick={() => set_archive('NO')}><img className="btn_icon" src={"/icons/checkmark 30px (4dcbd4).svg?ts=" + Date.now()} alt={t('active icon')}/>{t('VIEW ACTIVE CREDENTIALS')}</Button>
                            }
                        </div>

                        <div className="content-filter-item item-pagination">
                        <CMP_TABLECONTROLS
                            var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                            total_rows={var_credentials && var_credentials.length > 0 ? var_credentials[0]['totalrows'] : 0} populatefunction={populate_credentials}
                            downloadname='Organization Credentials' downloadfunction={download_org_credentials}
                            var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters} onApply_filter={onApply_filter}
                            table_config={[
                                {name: 'credential', datatype: 'text', labelKey: 'CREDENTIAL', download: true, filter: true},
                                {name: 'status', datatype: 'text', labelKey: 'STATUS', download: true, filter: true},
                                {name: 'code', datatype: 'text', labelKey: 'CODE', download: true, filter: true},
                                {name: 'expires', datatype: 'text', labelKey: 'EXPIRES', download: true, filter: true},
                                {name: 'duration', datatype: 'text', labelKey: 'DURATION', download: true, filter: true},
                                {name: 'holders', datatype: 'int', labelKey: 'HOLDERS', download: true, filter: true},
                                {name: 'credential_category_id', datatype: 'text', labelKey: 'CATEGORY', download: true, filter: true}
                            ]}
                        />
                        </div>
                    </div>
                    <div className="tablewrapper">
                        <Table sortable compact className={(var_loading ? " tbl_loading" : "")}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell className="td_icon_center" sorted={var_sortby === 'status' ? var_sortorder : null} onClick={()=>onClick_sort('status')}>{t('STATUS')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'code' ? var_sortorder : null} onClick={()=>onClick_sort('code')}>{t('CODE')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'credential' ? var_sortorder : null} onClick={()=>onClick_sort('credential')}>{t('CREDENTIAL')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'expires' ? var_sortorder : null} onClick={()=>onClick_sort('expires')}>{t('EXPIRES')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'duration' ? var_sortorder : null} onClick={()=>onClick_sort('duration')}>{t('DURATION')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'holders' ? var_sortorder : null} onClick={()=>onClick_sort('holders')}>{t('HOLDERS')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'credential_category_id' ? var_sortorder : null} onClick={()=>onClick_sort('credential_category_id')}>{t('CATEGORY')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_icon_center" >{t('HSTRY')}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {var_ready && var_credentials[0]['totalrows'] !== 0 &&
                            <Table.Body>
                                {var_credentials.map((item, i) =>
                                    <Table.Row key={i}>
                                        <Table.Cell className="td_icon_center" onClick={() => onClick_update(item.id)}>
                                            {item.status === 'ACTIVE' &&
                                            <img src={"/icons/checkmark 30px (69b536).svg?ts=" + Date.now()} alt={t('active icon')}/>
                                            }
                                            {item.status === 'INACTIVE' &&
                                            <img src={"/icons/x 30px (e0454f).svg?ts=" + Date.now()} alt={t('inactive icon')}/>
                                            }
                                            {item.status === 'PENDING' &&
                                            <img src={"/icons/warning 30px (ffae00).svg?ts=" + Date.now()} alt={t('pending icon')}/>
                                            }
                                        </Table.Cell>
                                        <Table.Cell onClick={() => onClick_update(item.id)}>{item.code}</Table.Cell>
                                        <Table.Cell className = "td_overflow" onClick={() => onClick_update(item.id)}>{item.credential}</Table.Cell>
                                        <Table.Cell onClick={() => onClick_update(item.id)}>{item.expires}</Table.Cell>
                                        <Table.Cell onClick={() => onClick_update(item.id)}>{t(item.duration)}</Table.Cell>
                                        <Table.Cell onClick={() => onClick_update(item.id)}>{item.holders}</Table.Cell>
                                        <Table.Cell onClick={() => onClick_update(item.id)}>{item.credential_category_id}</Table.Cell>
                                        <Table.Cell className="td_icon_center" onClick={() => onOpen_mdl_history(item.id, item.credential)}><img src={"/icons/history 30px (bcbebe).svg?ts=" + Date.now()} alt={t('history icon')}></img></Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                            }
                        </Table>
                        {var_ready && var_credentials[0]['totalrows'] === 0 &&
                        <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t('alert icon')}/>{t('there are no credentials to display')}</div>
                        }
                    </div>
                    <div className="content-filter">
                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_credentials && var_credentials.length > 0 ? var_credentials[0]['totalrows'] : 0} populatefunction={populate_credentials}
                            />
                        </div>
                    </div>
                </div>



                {/***** MODAL: HISTORY *******************************************************************************/}

                <Modal id="mdl_history"
                       dimmer={'inverted'}
                       open={var_mdl_history_open}
                       onClose={() => set_mdl_history_open(false)}>
                    <MDL_HISTORY
                        set_mdl_open={set_mdl_history_open}
                        var_modaltitle={var_modaltitle}
                        var_target_id={var_target_id}>
                    </MDL_HISTORY>
                </Modal>

                {/***** END MODAL: DETAILS ***************************************************************************/}



                {/***** MODAL: ADD CREDENTIAL ************************************************************************/}

                <Modal id="org-credentials_mdl_addcredential"
                       dimmer={'inverted'}
                       open={var_mdl_add_open}
                       onClose={() => set_mdl_add_open(false)}
                       closeOnEscape={false}
                       closeOnDimmerClick={false}>
                    <MDL_ADD
                        set_mdl_open={set_mdl_add_open}
                        populate_function={populate_credentials}
                        var_organization_id={JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id}
                        var_issuer_id={''}>
                    </MDL_ADD>
                </Modal>

                {/***** END MODAL: ADD CREDENTIAL ********************************************************************/}



                {/***** MODAL: UPDATE ********************************************************************************/}

                <Modal id="org-credentials_mdl_updatecredential"
                       dimmer={'inverted'}
                       open={var_mdl_update_open}
                       onClose={() => set_mdl_update_open(false)}
                       closeOnEscape={false}
                       closeOnDimmerClick={false}>
                    <MDL_UPDATE
                        set_mdl_open={set_mdl_update_open}
                        var_target_id={var_target_id}
                        populate_function={populate_credentials}>
                    </MDL_UPDATE>
                </Modal>

                {/***** END MODAL: UPDATE ***************************************************************************/}


            </div>
        </>
    )

}
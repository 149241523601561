//  OUTCOMES -----------------------------------------------------------------------------------------------------------
//
//  - displays a questionnaire for the user to click through and see what the result would be
//  - does nothing with the result other than display it to the user
//
//  --------------------------------------------------------------------------------------------------------------------

import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Button, Checkbox } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import '../../../../../../../i18n';

import CRD_QUESTIONNAIRE from '../../../../../../common/questionnaires/crd_questionnaire/crd_questionnaire';

import './mdl_preview.css';



function MDL_PREVIEW({var_mdl_open, set_mdl_open, var_questions}) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_individual_type, set_individual_type ] = useState('USER');
    const [ var_previewquestions, set_previewquestions ] = useState([]);
    const [ var_complete, set_complete ] = useState(false);
    const [ var_passfail, set_passfail ] = useState(null);
    const [ var_quarantinedays, set_quarantinedays ] = useState(null);
    const { t } = useTranslation();


    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_mdl_open) {
            // reset all variables
            set_previewquestions([]);
            set_complete(false);
            set_passfail(null);
            set_quarantinedays(null);
            // now set the questions to display
            set_previewquestions(var_questions);
        }
    }, [var_mdl_open]);

    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='modal-header'>
                <div className='modal-header-title'>{t('Preview Questionnaire')}</div>
                <div className='modal-header-close' onClick={() => set_mdl_open(false)}><img src={'/icons/x 60px (717473).svg?ts=' + Date.now()} alt={t('x icon')} /></div>
            </div>
            <div className='modal-content'>
                <div className='preview-toolbar'>
                    <label onClick={() => set_individual_type('USER')}>{t('USER')}</label>
                    <Checkbox slider onChange={(e, data) => set_individual_type(data.checked ? 'GUEST' : 'USER')}
                        checked={var_individual_type === 'GUEST'} />
                    <label onClick={() => set_individual_type('GUEST')}>{t('GUEST')}</label>
                </div>
                <div className='scrolling content'>
                    <CRD_QUESTIONNAIRE var_questions={var_previewquestions} var_indv_type={var_individual_type}
                        set_complete={set_complete} set_passfail={set_passfail} set_quarantinedays={set_quarantinedays} />
                </div>
                <div className='preview-results'>
                    <label className='preview-results-label'>{t('RESULT')}:</label>
                    {
                        var_passfail === 'FAIL'
                        ?
                        <>
                            <div className='preview-results-fail'>{t('FAIL')}</div>
                            <div className='preview-results-quarantinedays'>{t('(quarantine for ') + var_quarantinedays + t(` day${var_quarantinedays === 1 ? '' : 's'})`)}</div>
                        </>
                        :
                        var_complete
                        ?
                        <div className='preview-results-pass'>{t('PASS')}</div>
                        :
                        <div className='preview-results-incomplete'>{t('INCOMPLETE')}</div>
                    }
                </div>
            </div>
            <div className='modal-footer'>
                <div className='modal-footer-buttons'>
                    <Button className='btn_secondary' onClick={() => set_mdl_open(false)}>{t('CLOSE')}</Button>
                </div>
            </div>
        </>
    )
}

MDL_PREVIEW.propTypes = {
    var_mdl_open: propTypes.bool.isRequired,
    set_mdl_open: propTypes.func.isRequired,
    var_questions: propTypes.arrayOf(propTypes.object).isRequired
}

export default MDL_PREVIEW;
import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Checkbox, Dropdown } from 'semantic-ui-react';
import { API } from 'aws-amplify';
import auth from '../../../../../../../libs/auth-lib';
import datelib from '../../../../../../../libs/date-lib';

import { useTranslation } from 'react-i18next';

import './mdl_addlink.css';



export default function({ set_mdl_open, populate_function, var_location_id }){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();
    const [ var_link, set_link] = useState(false);
    const [ var_ready, set_ready] = useState(false);
    const [ var_processing, set_processing] = useState(false);
    const [ var_credentials, set_credentials] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [ var_dailychecks, set_dailychecks] = useState(false);
    const [ var_onsite, set_onsite] = useState(false);
    const [ var_required_field, set_required_field ] = useState(false);
    const [ var_required_field_description, set_required_field_description ] = useState('');
    const [ var_all_individuals, set_all_individuals ] = useState(true);
    const [ var_dd_individuals, set_dd_individuals ] = useState([]);
    const [ var_selectedindividual, set_selectedindividual ] = useState([]);
    const [ var_all_credentials, set_all_credentials ] = useState(true);
    const [ var_dd_credentials, set_dd_credentials ] = useState([]);
    const [ var_selectedcredentials, set_selectedcredentials ] = useState([]);

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_link.transactionby === undefined){
            const link = { ...var_link,
                individual_id: auth.id,
                transactionby: auth.id,
                transactionorg: auth.organization_id
            };
            set_link(link);
        }
        populate_dd_individuals();
        populate_dd_credentials();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (var_credentials || var_dailychecks || var_onsite){
            set_ready(true)
        }else{
            set_ready(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_link,var_credentials,var_dailychecks,var_onsite]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function insert_profilelink(){
        set_processing(true);
        try {
            let authorize_data = {
                individuals: var_all_individuals ? 'YES' : var_selectedindividual,
                selected_credentials: (var_credentials && !var_all_credentials) ? var_selectedcredentials : null,
                requirements: 'YES',
                dailychecks: var_dailychecks ? 'YES' : 'NO',
                credentials: var_credentials ? 'YES' : 'NO',
                onsite: var_onsite ? 'YES' : 'NO'
            };

            let authorize = JSON.stringify(authorize_data);
            const link = { ...var_link };

            let body = {
                individual_id: link['individual_id'],
                transactionby: link['transactionby'],
                transactionorg: link['transactionorg'],
                description: link['description'],
                authorize: authorize
            }

            await API_post_loc_profilelink(body);
            set_ready(false);
            set_processing(false);
            set_mdl_open(false);
            populate_function();
        } catch (e) {
            console.log(e);
        }
    }

    async function populate_dd_individuals(){
        try {
            let results = await API_get_dd_individuals();
            set_dd_individuals(results);
        } catch (e) {
            console.log(e);
        }
    }

    async function populate_dd_credentials(all_individuals, selected_credentials) {
        try {
            all_individuals === undefined && (all_individuals = var_all_individuals);
            selected_credentials === undefined && (selected_credentials = var_selectedindividual);
            let body = {
                location_id: var_location_id,
                all_individuals: all_individuals,
                individuals: all_individuals ? null : selected_credentials,
                tz: datelib.tz
            };
            let results = (all_individuals || selected_credentials.length > 0) ? await API_get_dd_profilelink_credentials(body) : [];
            let selectedcredentials = var_selectedcredentials;
            selectedcredentials = selectedcredentials.filter(item => results.some(result => result.value === item));
            set_selectedcredentials(selectedcredentials);
            set_dd_credentials(results);
        } catch (e) {
            console.log(e);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_loc_profilelink(body){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-locations', '/post-loc-profilelink/'+var_location_id, { body: body });
    }

    function API_get_dd_individuals(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-locations', '/get-dd-individuals-users/'+var_location_id);
    }

    function API_get_dd_profilelink_credentials(body) {
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-locations', '/get-dd-profilelink-credentials', { body });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(event){
        const link = { ...var_link };
        link[event.target.name] = event.target.value;
        if(event.target.name ==="description" && var_required_field===true){
            set_required_field(false)
        }
        set_link(link);
        set_ready(true);
    }

    function onChange_dailychecks() {
        set_dailychecks(var_dailychecks => !var_dailychecks);
    }

    function onChange_credentials() {
        set_credentials(var_credentials => !var_credentials);
    }

    function onChange_onsite() {
        set_onsite(var_onsite => !var_onsite);
    }

    function onClick_submit(){
        if(var_ready){
            if (!var_link.description || var_link.description === '') {
                set_processing(false);
                set_required_field(true);
                set_required_field_description('Please provide a description for this link');
            } else if (!var_all_individuals && var_selectedindividual.length === 0) {
                set_processing(false);
                set_required_field(true);
                set_required_field_description('Please select at least one individual for this link');
            } else if (var_credentials && !var_all_credentials && var_selectedcredentials.length === 0) {
                set_processing(false);
                set_required_field(true);
                set_required_field_description('Please select at least one credential for this link');
            } else{
                insert_profilelink();
            }
        }
    }

    function onChange_all_individuals(){
        set_all_individuals(!var_all_individuals);
        populate_dd_credentials(!var_all_individuals);
    }

    function onChange_individual(e, { value }){
        set_selectedindividual(value);
        populate_dd_credentials(undefined, value);
    }

    function onChange_all_credentials() {
        set_all_credentials(!var_all_credentials);
    }

    function onChange_selected_credentials(e, { value }) {
        set_selectedcredentials(value);
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{t('Create Profile Link')}</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src={"/icons/x 60px (717473).svg?ts=" + Date.now()} alt={t('close icon')}/></div>
            </div>
            <div className="modal-content">

                <Form>
                    <Form.Group>
                        <Form.Field
                            className='description'
                            id='description'
                            name='description'
                            value={var_link.description || ''}
                            control={Input}
                            onChange={onChange_input}
                            label={t('LINK DESCRIPTION')}
                            placeholder={t('Description...')}
                        />

                    </Form.Group>

                    <Form.Group>
                        <Form.Field
                            className='description'
                            id='description'
                            name='description'
                            label={t('Select from the various options below to customize the types of information to be included in this public profile')}
                        />
                    </Form.Group>

                    {auth.org_dailycheck &&
                    <Form.Group>
                        <Form.Field>
                            <Checkbox label={t('Daily Checks')} toggle
                            onChange={onChange_dailychecks}
                            checked={var_dailychecks || false}/>
                        </Form.Field>
                    </Form.Group>
                    }
                    {auth.org_credentials &&
                    <Form.Group>
                        <Form.Field>
                            <Checkbox label={t('Credentials')} toggle
                            onChange={onChange_credentials}
                            checked={var_credentials || false}/>
                        </Form.Field>
                    </Form.Group>
                    }
                    {auth.org_r2w &&
                    <Form.Group>
                        <Form.Field>
                            <Checkbox label={t('Onsite')} toggle
                            onChange={onChange_onsite}
                            checked={var_onsite || false}/>
                        </Form.Field>
                    </Form.Group>
                    }
                </Form>

                <label>{t('INDIVIDUALS')}</label>

                <Dropdown
                    className='individual_id'
                    id='individual_id'
                    name='individual_id'
                    label={t("INDIVIDUAL")}
                    options={var_dd_individuals}
                    value={var_selectedindividual || []}
                    onChange={onChange_individual}
                    placeholder={t("Select an individual...")}
                    multiple
                    selection
                    search
                    fluid
                    disabled={var_all_individuals}
                />

                <Checkbox toggle
                    className='add_all'
                    label={t('All individuals in location/project')}
                    checked={var_all_individuals || false}
                    onChange={onChange_all_individuals}
                />

                {var_credentials &&
                    <>
                        <label className='credential_selection'>{t('CREDENTIALS')}</label>
                        <Dropdown
                            label={t('CREDENTIAL')}
                            options={var_dd_credentials}
                            value={var_selectedcredentials}
                            onChange={onChange_selected_credentials}
                            placeholder={t('Select a credential...')}
                            multiple
                            selection
                            search
                            fluid
                            disabled={var_all_credentials}
                        />
                        <Checkbox toggle
                            className='add_all'
                            label={t('All credentials for selected individuals')}
                            checked={var_all_credentials}
                            onChange={onChange_all_credentials}
                        />
                    </>
                }

                {var_required_field &&
                    <div id="reqfielderror" className="message warning">
                        <div className="message_icon"><img src={"/icons/warning 60px (d91e18).svg?ts=" + Date.now()} alt={t('warning icon')} /></div>
                        <div className="message_text_wrapper">
                            <div className="message_text cause">{t(var_required_field_description)}</div>
                        </div>
                    </div>
                }
            </div>
            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>{t('CANCEL')}</Button>
                    {!var_processing ?
                        <Button className={"btn_primary " + (var_ready ? ' btn_active' : '')} onClick={() => onClick_submit()}>{t('CREATE LINK')}</Button>
                        :
                        <Button loading className="btn_primary btn_active">{t('CREATE LINK')}</Button>
                    }

                </div>
            </div>
        </>
    )
}

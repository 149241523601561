import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from 'semantic-ui-react'
import Moment from "moment";
import datelib from '../../../../libs/date-lib';

import { useTranslation } from 'react-i18next';
import '../../../../i18n';

import './crd_forms.css';
import CMP_LEGEND from '../../../../components/cmp_legend/cmp_forms_legend/cmp_forms_legend';
import CMP_TABLECONTROLS, { filter_helper } from '../../../../components/cmp_tablecontrols/cmp_tablecontrols';
import MDL_ADD from "./mdl_add/mdl_add";
import MDL_DOCVIEWER from "../../../../components/cmp_docviewer/cmp_docviewer";
import MDL_CONFIRMATION from '../../../../components/cmp_confirmation/cmp_confirmation';
import CMP_SUCCESS from '../../../../components/cmp_success/cmp_success';
import MDL_ASSIGNMENT from "./mdl_assignment/mdl_assignment";

export default function({set_formsrefresh}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();
    const [ var_forms, set_forms ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(10);
    const [ var_offset, set_offset] = useState(0);
    const [ var_sortby, set_sortby ] = useState('assignedepoch');
    const [ var_sortorder, set_sortorder ] = useState('descending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));
    const [ var_closed, set_closed ] = useState(false);
    const [ var_review, set_review ] = useState(false);

    const [ var_dd_locations, set_dd_locations ] = useState([]);
    const [ var_selectedlocation, set_selectedlocation ] = useState('');

    const [ var_mdl_add_open, set_mdl_add_open ] = useState(false);

    const [ var_mdl_docviewer_open, set_mdl_docviewer_open ] = useState(false);
    const [ var_docviewerid, set_docviewerid ] = useState(null);

    const [ var_mdl_assignment_open, set_mdl_assignment_open ] = useState(false);
    const [ var_activeform, set_activeform ] = useState(null);

    const [ var_mdl_confirmation_open, set_mdl_confirmation_open ] = useState(false);
    const [ var_mdl_success_open, set_mdl_success_open ] = useState(false);

    const [ var_formlock_id, set_formlock_id ] = useState('');
    const [ var_formlock_lockedby, set_formlock_lockedby ] = useState('');

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_dd_locations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(()=>{
        if(var_filter.load){
            populate_forms(null,0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_filter]);

    useEffect(()=>{
        if(!var_mdl_assignment_open){
            populate_forms();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_assignment_open]);

    useEffect(()=>{
        if(var_closed !== undefined) {
            set_filter(filter_helper.initialize(false));
        }
    },[var_closed]);

    useEffect(() => {
        if(var_selectedlocation.length >0) {
            populate_forms();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_selectedlocation]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_forms(limit,offset,sortby,sortorder,closed,filter){

        set_loading(true);
        set_formsrefresh(false);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        filter = filter ? filter : var_filter;

        if(closed){
            set_closed(closed);
        } else {
            closed = var_closed;
        }

        try {
            let results = await API_get_loc_activeforms(limit,offset,sortby,sortorder,closed,filter);
            set_forms(results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
        set_formsrefresh(true);
    }

    async function populate_filters(filtername,sortorder){
        try {
            return await API_get_loc_activeforms(filter_helper.maxFilterItems,0,filtername,sortorder,var_closed,{},filtername);
        } catch (e) {
            console.log(e);
        }
    }

    async function download_forms(limit,offset){
        try {
            return await API_get_loc_activeforms(limit,offset,var_sortby,var_sortorder,var_closed,var_filter);
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    async function populate_dd_locations(){
        try {
            let results = await API_get_dd_locations();
            set_dd_locations(results);
        } catch (e) {
            console.log(e);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_loc_activeforms(limit,offset,sortby,sortorder,closed,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('indv-forms',
            '/get-indv-activeforms/' + JSON.parse(sessionStorage.getItem('authentication')).id,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    tz: datelib.timezone,
                    closed: closed,
                    filtername: filtername,
                    location_id: var_selectedlocation
                },
                body: filter
            }
        );
    }

    function API_get_activeform(id){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return (API.get('indv-forms',
            '/get-activeform/' + id,
            {
                queryStringParameters : {
                    tz: datelib.timezone,
                }
            }
        ));
    }

    function API_post_lockform(id) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.post('indv-forms', '/post-lockform/' + JSON.parse(sessionStorage.getItem('authentication')).id,
            {body: {
                    "organization_id": JSON.parse(sessionStorage.getItem('authentication')).organization_id,
                    "activeform_id": id
                }})
            .then(response => response)
            .catch(error => {
                console.log(error.response);
                return {} // on error, return a sane object with empty values
            });
    }

    function API_post_unlockform(locked_form_id) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.post('indv-forms', '/post-unlockform/' + JSON.parse(sessionStorage.getItem('authentication')).id,
            {body: {
                    "organization_id": JSON.parse(sessionStorage.getItem('authentication')).organization_id,
                    "activeform_id": locked_form_id
                }})
            .then(response => response)
            .catch(error => {
                console.log(error.response);
                return {} // on error, return a sane object with empty values
            });
    }

    function API_get_dd_locations(){
        const qrdata = JSON.parse(sessionStorage.getItem('qrdata'));
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('indv', '/get-dd-indv-locations-forms/'+JSON.parse(sessionStorage.getItem('authentication')).id,
        {
            queryStringParameters: {
                qrlocation_id: qrdata ? qrdata.qrlocation_id : null
            }
        });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_forms('',0,sortby,sortorder)
    }

    async function onClick_open_form({id}){
        // open an empty window
        const tab = window.open('about:blank');

        //Get current state of the clicked form
        let activeform = (await API_get_activeform(id))[0];

        if (!['NEW', 'IN-PROGRESS'].includes(activeform.status)) {
            // close the empty window
            tab.close();
            ['IN-REVIEW', 'CLOSED'].includes(activeform.status) && open_docviewer(activeform);

        } else {
            if(activeform.locked_by) {
                // close the empty window
                tab.close();

                if(activeform.locked_by_id === JSON.parse(sessionStorage.getItem('authentication')).id) {
                    set_formlock_id(activeform.id);
                    set_mdl_confirmation_open(true);
                } else {
                    set_formlock_lockedby(activeform.locked_by);
                    set_mdl_success_open(true);
                }

            } else {
                // update the actual URL
                tab.location = activeform.status === 'IN-PROGRESS' ?
                                activeform.editlink :
                                activeform.formurl + '?entry=' + encodeURIComponent(JSON.stringify({'Site': activeform.location_name, 'UID': activeform.id, 'Number': activeform.location_code}));
                tab.focus();

                set_activeform(activeform.id);
                await API_post_lockform(activeform.id);
            }
        } 
        populate_forms();
    }

    async function onClick_paperclip(item) {
        //Get current state of the clicked form
        let activeform = (await API_get_activeform(item.id))[0];
        open_docviewer(activeform);
    }

    function open_docviewer(activeform) {
        if (!activeform.PDF) {
            return;
        } else {
            set_review(activeform.status === 'IN-REVIEW');
            set_docviewerid(activeform.id);
            set_mdl_docviewer_open(true);
        }
    }

    async function unlock_form() {
        await API_post_unlockform(var_formlock_id);
        await populate_forms();
    }

    function onOpen_mdl_addform(){
        set_mdl_add_open(true);
    }

    function onClick_assignment(event, item){
        event.stopPropagation();
        set_activeform(item);
        set_mdl_assignment_open(true);
    }

    function onChange_location(e, { value }){
        set_selectedlocation(value);
        set_filter(filter_helper.initialize(false));
        set_ready(false);
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="content-card" id="crd_indv_forms_forms">
                <div className="content-card-header">
                    {var_closed ?
                        <div className="content-card-header-title">{t('All Closed Forms')}</div>
                        :
                        <div className="content-card-header-title">{t('All Active Forms')}</div>
                    }
                    <Button className="btn_primary btn_active" onClick={() => onOpen_mdl_addform()}><img className="btn_icon" src={"/icons/add 30px (ffffff).svg?ts=" + Date.now()} alt={t('add icon')}></img>{t('FILL OUT A FORM')}</Button>
                </div>
                <div className="content-card-gridcontent">
                    <div className="content-filter">
                        <div className="content-filter-item">
                            <div className="content-filter-dropdown">
                                <Form>
                                    <Form.Select 
                                        className='location_id'
                                        id='location_id'
                                        name='location_id'
                                        label={t("LOCATIONS & PROJECTS")}
                                        search
                                        options={var_dd_locations}
                                        value={var_selectedlocation || ''}
                                        onChange={onChange_location}
                                        placeholder={t("Select a location or project...")}
                                    />
                                </Form>
                            </div>
                            <div className="content-filter-archive">
                            {var_closed ?
                                <Button className="btn_tertiary" onClick={() => set_closed(!var_closed)}><img className="btn_icon" src={"/icons/form 60px (29719a).svg?ts=" + Date.now()} alt={t("form icon")}/>{t("VIEW ACTIVE FORMS")}</Button>
                                :
                                <Button className="btn_tertiary" onClick={() => set_closed(!var_closed)}><img className="btn_icon" src={"/icons/form 60px (29719a).svg?ts=" + Date.now()} alt={t("form icon")}/>{t("VIEW CLOSED FORMS")}</Button>
                            }
                            </div>
                        </div>
                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                var_offset={var_offset} var_limit={var_limit} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_forms && var_forms.length > 0 ? var_forms[0]['totalrows'] : 0} populatefunction={populate_forms}
                                downloadname='Forms' downloadfunction={download_forms}
                                var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                                table_config={[
                                    {name: 'assignedtouser', datatype: 'text', labelKey: 'ASSIGNED TO USER', download: true, filter: true},
                                    {name: 'formname', datatype: 'text', labelKey: 'FORM', download: true, filter: true},
                                    {name: 'location_name', datatype: 'text', labelKey: 'LOC/PROJ', download: true, filter: true},
                                    {name: 'assignedepoch', datatype: 'date', labelKey: 'ASSIGNED', download: true, filter: true},
                                    {name: 'assignedto', datatype: 'text', labelKey: 'ASSIGNED TO', download: true, filter: true},
                                    {name: 'status', datatype: 'text', labelKey: 'STATE', download: true, filter: true},
                                    {name: 'locked_by', datatype: 'text', labelKey: 'LOCKED BY', download: true, filter: true},
                                    {name: 'locked_on', datatype: 'epoch', labelKey: 'LOCKED ON', download: true, filter: true}
                                ]}
                            />
                        </div>
                    </div>
                    <div className="tablewrapper">
                        <Table stackable sortable compact className={(var_loading ? " tbl_loading" : "")}>
                            <Table.Header className="tbl_forms_header">
                                <Table.Row>
                                    <Table.HeaderCell sorted={var_sortby === 'assignedtouser' ? var_sortorder : null} onClick={() => onClick_sort('assignedtouser')}>{t("STATUS")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'formname' ? var_sortorder : null} onClick={() => onClick_sort('formname')}>{t("FORM")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'location_name' ? var_sortorder : null} onClick={() => onClick_sort('location_name')}>{t("LOC/PROJ")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'assignedepoch' ? var_sortorder : null} onClick={() => onClick_sort('assignedepoch')}>{t("ASSIGNED")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'assignedto' ? var_sortorder : null} onClick={() => onClick_sort('assignedto')}>{t("ASSIGNED TO")}</Table.HeaderCell>
                                    {var_closed &&
                                        <Table.HeaderCell className="td_paperclip" sorted={var_sortby === 'PDF' ? var_sortorder : null} onClick={() => onClick_sort('PDF')}><img src={"/icons/paperclip 30px (c9caca).svg?ts=" + Date.now()} alt={t("attachment icon")}/></Table.HeaderCell>
                                    }
                                    <Table.HeaderCell sorted={var_sortby === 'status' ? var_sortorder : null} onClick={() => onClick_sort('status')}>{t("STATE")}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_lockedby" sorted={var_sortby === 'locked_by' ? var_sortorder : null} onClick={() => onClick_sort('locked_by')}>{t("LOCKED BY")}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_lockedon" sorted={var_sortby === 'locked_on' ? var_sortorder : null} onClick={() => onClick_sort('locked_on')}>{t("LOCKED TIME")}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {var_ready && var_forms[0]['totalrows'] !== 0 &&
                            <Table.Body>
                                {var_forms.map((item, i) =>
                                    <Table.Row className="tbl_row" key={i} onClick={() => { ['IN-REVIEW', 'CLOSED'].includes(item.status) ? onClick_paperclip(item) : onClick_open_form(item) }}>
                                        <Table.Cell className="td_formstatus">
                                            {((item.assignedtouser === 'YES' && (item.status === 'NEW' || item.status === 'IN-PROGRESS')) || item.status === 'IN-REVIEW')
                                            ? <div className='td_formicon'><img src={"/icons/warning 60px (db2430).svg?ts=" + Date.now()} alt={t("warning icon")}/></div>
                                            : <div className='td_formicon'><img src={"/icons/circle 60px (717473).svg?ts=" + Date.now()} alt={t("circle icon")}/></div>}
                                        </Table.Cell>
                                        <Table.Cell>{item.formname}</Table.Cell>
                                        <Table.Cell>{item.location_name}</Table.Cell>
                                        <Table.Cell className="td_overflow" >{datelib.date_localized_format(item.assigned)}</Table.Cell>
                                        <Table.Cell className="td_overflow" onClick={(event) => onClick_assignment(event,item)}>{item.assignedto}</Table.Cell>
                                        {var_closed &&
                                        <Table.Cell className="td_paperclip">
                                            {((item.status === 'CLOSED' || item.status === 'IN-REVIEW') && item.PDF !== null && item.PDF !== '') ?
                                                <div className='td_attachment'><img src={"/icons/paperclip 60px (717473).svg?ts=" + Date.now()} alt={t("attachment icon")}/></div>
                                                :
                                                <div className='td_attachment'></div>
                                            }
                                        </Table.Cell>
                                        }
                                        <Table.Cell className="td_overflow">{item.status}</Table.Cell>
                                        <Table.Cell className="td_lockedby">{item.locked_by}</Table.Cell>
                                        <Table.Cell className="td_lockedon">{item.locked_on !== null && item.locked_on !== undefined && item.locked_on !== "" ? Moment.unix(item.locked_on).local(false).format('HH:mm'): ''}</Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                            }
                        </Table>
                        {var_ready && var_forms[0]['totalrows'] === 0 &&
                        <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt="alert icon"/>{t("there are no forms to display")}</div>
                        }
                    </div>
                    <div className="content-filter">
                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                var_offset={var_offset} var_limit={var_limit} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_forms && var_forms.length > 0 ? var_forms[0]['totalrows'] : 0} populatefunction={populate_forms}
                            />
                        </div>
                    </div>
                    <div className="legend_footer">
                        <CMP_LEGEND
                            var_indv_view={true}
                        />
                    </div>
                </div>


                {/***** MODAL: ADD ***************************************************************************************/}

                <Modal id="indv-forms_mdl_addform"
                       dimmer={'inverted'}
                       open={var_mdl_add_open}
                       onClose={() => set_mdl_add_open(false)}>
                    <MDL_ADD
                        set_mdl_open={set_mdl_add_open}
                        populate_function={populate_forms}>
                    </MDL_ADD>
                </Modal>

                {/***** END MODAL: ASSIGN ********************************************************************************/}

                {/***** MODAL: DOCUMENT VIEW *****************************************************************************/}

                <Modal id="mdl_docviewer"
                       dimmer={'inverted'}
                       open={var_mdl_docviewer_open}
                       onClose={() => set_mdl_docviewer_open(false)}>
                    <MDL_DOCVIEWER
                        set_mdl_open={set_mdl_docviewer_open}
                        targetid={var_docviewerid}
                        classification='form'
                        var_review={var_review}
                        onComplete_review={populate_forms}
                    />
                </Modal>

                {/***** END MODAL: DOCUMENT VIEW ***********************************************************************/}

                {/***** MODAL: ASSIGNMENT  *****************************************************************************/}

                <Modal id="indv_forms_mdl_assignment"
                       dimmer={'inverted'}
                       open={var_mdl_assignment_open}
                       onClose={() => set_mdl_assignment_open(false)}>
                    <MDL_ASSIGNMENT
                        set_mdl_open={set_mdl_assignment_open}
                        populate_function={populate_forms}
                        var_activeform={var_activeform}
                    />
                </Modal>

                {/***** END MODAL: ASSIGNMENT **************************************************************************/}

                {/***** MODAL: CONFIRMATION *******************************************************************************/}

                <Modal id="mdl_confirmation"
                    dimmer={'inverted'}
                    open={var_mdl_confirmation_open}
                    onClose={() => set_mdl_confirmation_open(false)}>
                    <MDL_CONFIRMATION
                        set_mdl_open={set_mdl_confirmation_open}
                        var_modaltitle={t("Locked Form")}
                        var_message={t('This form is locked by you (probably in another window). \n\n Would you like to unlock it?')}
                        confirmation_function={unlock_form}>
                    </MDL_CONFIRMATION>
                </Modal>

                {/***** END MODAL: CONFIRMATION **************************************************************************/}

                {/***** MODAL: INFO *******************************************************************************/}

                <Modal id="mdl_info"
                    dimmer={'inverted'}
                    open={var_mdl_success_open}
                    onClose={() => set_mdl_success_open(false)}>
                    <CMP_SUCCESS
                        title={t('Locked Form')}
                        set_mdl_open={set_mdl_success_open}
                        success_or_fail='INFO'
                        body={t('Form is currently locked by ') + var_formlock_lockedby + '.'} />
                </Modal>

                {/***** END MODAL: INFO **************************************************************************/}

            </div>
        </>
    )

}

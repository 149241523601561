import React, { useEffect, useState } from "react";
import { API } from 'aws-amplify';
import {Modal} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import '../../../../i18n';

import './crd_credentialsAttachments.css';

import MDL_DOCVIEWER from "../../../../components/cmp_docviewer/cmp_docviewer";

export default function({var_credentials}){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();
    // eslint-disable-next-line no-unused-vars
    const [ var_credential_url, set_credential_url ] = useState(null);
    const [ var_loading, set_loading ] = useState([]);
    const [ var_src, set_src ] = useState(false);
    const [ var_title, set_title ] =  useState(false);

    const [ var_mdl_docviewer_open, set_mdl_docviewer_open ] = useState(false);
    const [ var_docviewerid, set_docviewerid ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        get_credential_url();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //  async functions ------------------------------------------------------------------------------------------------

    async function get_credential_url(){
        set_credential_url((await API.endpoint('storage'))+'/orgcredential/');
    }

    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_attachment(id){
        set_docviewerid(id);
        set_mdl_docviewer_open(true);
    }


    // RENDER APP ======================================================================================================

    return (
        <>
		{var_credentials && var_credentials.length !== 0 && var_credentials.filter(function(obj) { return (obj.filename !== null && obj.filename !== '' && obj.filename !== 'No file selected...'); }).length > 0 &&
            <section className="profile_section">

                <div className="profile_section_header">{t('Credentials Attachments')}</div>

                <ul className="credentials_attachment_list">
                    {var_credentials.map((item, i) =>
                        <>
                            {(item.filename !== null && item.filename !== '' && item.filename !== 'No file selected...') &&
                                <li key={i}>
                                    <figure className="credentials_attachment_thumb" onClick={() => onClick_attachment(item.id)}>
                                    {item.filename.split('.').pop().toLowerCase() === 'pdf' &&
                                        <div id="attachment_pdf"><img src="/pdf/pdf-document.png" alt="pdf" /></div>
                                    }
                                    {item.filename.split('.').pop().toLowerCase() !== 'pdf' &&
                                        <div><img src={var_credential_url + item.id + '/' + item.filename} alt={item.filename}/></div>
                                    }
                                    <figcaption>{item.credential}</figcaption>
                                    </figure>
                                </li>
                            }
                        </>
                    )}
                </ul>

            </section>
        }



        {/***** MODAL: DOCUMENT VIEW *****************************************************************************/}

        <Modal id="mdl_docviewer"
                dimmer={'inverted'}
                open={var_mdl_docviewer_open}
                onClose={() => set_mdl_docviewer_open(false)}>
            <MDL_DOCVIEWER
                set_mdl_open={set_mdl_docviewer_open}
                targetid={var_docviewerid}
                classification='orgcredential'
                ispublic={true}
            />
        </Modal>

        {/***** END MODAL: DOCUMENT VIEW *********************************************************************/}

        </>
    )

}

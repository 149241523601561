import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import {Modal, Table} from 'semantic-ui-react'
import React from "react";
import Moment from "moment";
import datelib from '../../../../../../libs/date-lib';
import auth from '../../../../../../libs/auth-lib';

import CMP_TABLECONTROLS, { filter_helper } from "../../../../../../components/cmp_tablecontrols/cmp_tablecontrols";

import './tab_holders.css';
import MDL_UPDATESTATUS from "./mdl_updatestatus/mdl_updatestatus";
import MDL_HISTORY from "../../../../../../components/cmp_history/cmp_history";

import { useTranslation } from 'react-i18next';
import '../../../../../../i18n';


export default function({ var_activetab, var_credential_id }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();
    const [ var_holders, set_holders ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(10);
    const [ var_offset, set_offset] = useState(0);
    const [ var_search, set_search ] = useState('');
    const [ var_sortby, set_sortby ] = useState('epochexpires');
    const [ var_sortorder, set_sortorder ] = useState('descending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));

    const [ var_mdl_updatestatus_open, set_mdl_updatestatus_open ] = useState(false);
    const [ var_mdl_history_open, set_mdl_history_open ] = useState(false);
    const [ var_modaltitle, set_modaltitle ] = useState('');
    const [ var_target_id, set_target_id ] = useState('');
    const [ var_confirmation_status, set_confirmation_status ] = useState('');


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_activetab === 'HOLDERS') {
            set_filter(filter_helper.initialize(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_activetab]);

    useEffect(()=>{
        if (var_filter.load){
            populate_holders(null, 0);
        }
    },[var_filter])

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_holders(limit,offset,sortby,sortorder,search,filter){
        if (var_activetab !== 'HOLDERS') return; // Don't load data if tab is not visible

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        if(search) {
            if(search === '-') {
                search = '';
            }
            set_search(search);
        }else{
            search=var_search
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_org_holders(limit,offset,sortby,sortorder,search,filter);
            set_holders(results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername,sortorder){
        try {
        return await API_get_org_holders(filter_helper.maxFilterItems,0,filtername,var_search,sortorder,{},filtername);
        } catch (e) {
        console.log(e);
        }
    }

    async function download_credential_holders(limit, offset){
        try {
        return await API_get_org_holders(limit,offset,var_sortby,var_sortorder,var_search,var_filter);
        } catch (e){
        console.log(e);
        throw e;
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_holders(limit,offset,sortby,sortorder,search,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-credentials',
                        '/get-org-credentialholders/'+var_credential_id,
                        {
                            queryStringParameters: {
                                limit: limit,
                                offset: offset,
                                sortby: sortby,
                                sortorder: sortorder,
                                search: search,
                                tz: datelib.timezone,
                                filtername: filtername
                            },
                            body: filter
                        }
                    );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_holders('',0,sortby,sortorder)
    }

    function onOpen_mdl_updatestatus(credential_id, confirmation_status) {
        if (auth.has_access('ORG-CRED', 'write')) {
            set_modaltitle(t('Update Verification Status'));
            set_target_id(credential_id);
            set_confirmation_status(confirmation_status);
            set_mdl_updatestatus_open(true);
        }
    }

    function onOpen_mdl_history(target_id, target_name) {
        set_modaltitle(t('Holder History') + ' - ' + target_name);
        set_target_id(target_id);
        set_mdl_history_open(true);
    }



    // RENDER APP ======================================================================================================

    return (
        <div className="modal-tab">
            <div className="content-filter">
                <div className="content-filter-item item-pagination">
                    <CMP_TABLECONTROLS
                        var_offset={var_offset} var_limit={var_limit} var_ready={var_ready} var_loading={var_loading}
                        total_rows={var_holders && var_holders.length > 0 ? var_holders[0]['totalrows'] : 0} populatefunction={populate_holders}
                        downloadname='CREDENTIAL HOLDERS' downloadfunction={download_credential_holders}
                        var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                        table_config={[
                            {name: 'holder', datatype: 'text', labelKey: 'HOLDER', download: true, filter: true},
                            {name: 'issued', datatype: 'text', labelKey: 'ISSUED', download: true, filter: false},
                            {name: 'epochissued', datatype: 'date', labelKey: 'ISSUED', download: false, filter: true},
                            {name: 'expires', datatype: 'text', labelKey: 'EXPIRES', download: true, filter: false},
                            {name: 'epochexpires', datatype: 'date', labelKey: 'EXPIRES', download: false, filter: true},
                            {name: 'confirmation_status', datatype: 'text', labelKey: 'VERIFICATION', download: true, filter: true},
                        ]}
                    />
                    </div>
               </div>
            <div className="tablewrapper">
                <Table sortable compact className={(var_loading ? " tbl_loading" : "")}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell sorted={var_sortby === 'holder' ? var_sortorder : null} onClick={()=>onClick_sort('holder')}>{t('HOLDER')}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'epochissued' ? var_sortorder : null} onClick={()=>onClick_sort('epochissued')}>{t('ISSUED')}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'epochexpires' ? var_sortorder : null} onClick={()=>onClick_sort('epochexpires')}>{t('EXPIRES')}</Table.HeaderCell>
                            <Table.HeaderCell sorted={var_sortby === 'confirmation_status' ? var_sortorder : null} onClick={()=>onClick_sort('confirmation_status')}>{t('VERIFICATION')}</Table.HeaderCell>
                            <Table.HeaderCell className="td_icon_center" >{t('HSTRY')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    {var_ready && var_holders[0]['totalrows'] !== 0 &&
                    <Table.Body>
                        {var_holders.map((item, i) =>
                            <Table.Row key={i} >
                                <Table.Cell >{item.holder}</Table.Cell>
                                <Table.Cell >{item.epochissued !== null && item.epochissued !== undefined && item.epochissued !== "" ? Moment.unix(item.epochissued).utc(false).format('ll'): ''}</Table.Cell>
                                <Table.Cell >{item.epochexpires !== null && item.epochexpires !== undefined && item.epochexpires !== "" ? Moment.unix(item.epochexpires).utc(false).format('ll') : ''}</Table.Cell>
                                <Table.Cell onClick={() => onOpen_mdl_updatestatus(item.id, item.confirmation_status)}>{item.confirmation_status}</Table.Cell>
                                <Table.Cell className="td_icon_center" onClick={() => onOpen_mdl_history(item.id, item.holder)}><img src={"/icons/history 30px (bcbebe).svg?ts=" + Date.now()} alt={t('history icon')}></img></Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                    }
                </Table>
                {var_ready && var_holders[0]['totalrows'] === 0 &&
                <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t('alert icon')}/>{t('there are no credential holders to display')}</div>
                }
            </div>
            <div className="content-filter">
                <div className="content-filter-item item-pagination">
                    <CMP_TABLECONTROLS
                        var_offset={var_offset} var_limit={var_limit} var_ready={var_ready} var_loading={var_loading}
                        total_rows={var_holders && var_holders.length > 0 ? var_holders[0]['totalrows'] : 0} populatefunction={populate_holders}
                    />
                </div>
            </div>


            {/***** MODAL: UPDATESTATUS *******************************************************************************/}

            <Modal id="mdl_updatestatus"
                   dimmer={'inverted'}
                   open={var_mdl_updatestatus_open}
                   onClose={() => set_mdl_updatestatus_open(false)}>
                <MDL_UPDATESTATUS
                    set_mdl_open={set_mdl_updatestatus_open}
                    var_modaltitle={var_modaltitle}
                    populate_holders={populate_holders}
                    credential_id={var_target_id}
                    var_confirmation_status={var_confirmation_status}>
                </MDL_UPDATESTATUS>
            </Modal>

            {/***** END MODAL: UPDATESTATUS ***************************************************************************/}

            {/***** MODAL: HISTORY *******************************************************************************/}

            <Modal id="mdl_history"
                   dimmer={'inverted'}
                   open={var_mdl_history_open}
                   onClose={() => set_mdl_history_open(false)}>
                <MDL_HISTORY
                    set_mdl_open={set_mdl_history_open}
                    var_modaltitle={var_modaltitle}
                    var_target_id={var_target_id}>
                </MDL_HISTORY>
            </Modal>

            {/***** END MODAL: DETAILS ***************************************************************************/}

        </div>
    )

};
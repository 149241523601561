import React, {useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import auth from '../../../libs/auth-lib';
import "./requirements.css";

import CRD_REQUIREMENTSREPORT from "./crd_requirementsreport/crd_requirementsreport";
import CRD_COMPLIANCEREPORT from "./crd_compliancereport/crd_compliancereport";
import CRD_REQUIREMENTSSETUP from "./crd_requirementssetup/crd_requirementssetup";
import CRD_PACKAGESSETUP from "./crd_packagessetup/crd_packagessetup";

import { useTranslation } from 'react-i18next';
import '../../../i18n';

export default function(appProps) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const history = useHistory();
    const [ var_activetab, set_activetab ] = useState(appProps.match.params.activetab === 'setup' ? 'REQUIREMENTSSETUP' : 'REQUIREMENTSREPORT');

    const { t } = useTranslation();

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if( auth.is_loggedin && auth.permission_id === auth.constants.permission_ids.Superadmin) {
            appProps.set_visibleframe(true);
            appProps.set_visiblemenu('ORGANIZATION')
            appProps.set_activemenu('/org/requirements');
        }else{
            history.push('/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);



    //  async functions ------------------------------------------------------------------------------------------------

    //  API calls ------------------------------------------------------------------------------------------------------

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_tab(var_tab){
        set_activetab(var_tab);
    }



    // RENDER APP ======================================================================================================

    return(
        <>
            {auth.is_loggedin &&
            <div className="content">
                <div
                    className="content-topmenu">{auth.organization} {t('Requirements')}
                </div>
                <div className="content-sidemenu-wrapper">
                    <div className="content-sidemenu">
                        <div className={"content-sidemenu-item " + (var_activetab === 'REQUIREMENTSREPORT' ? 'content-sidemenu-item-active' : '')} onClick={() => onClick_tab('REQUIREMENTSREPORT')}>
                            <div className="content-sidemenu-item_header">{t('Requirements Report')}</div>
                            <div className="content-sidemenu-item_subheader">{t('All individuals and their requirements')}</div>
                        </div>
                        <div className={"content-sidemenu-item " + (var_activetab === 'COMPLIANCEREPORT' ? 'content-sidemenu-item-active' : '')} onClick={() => onClick_tab('COMPLIANCEREPORT')}>
                            <div className="content-sidemenu-item_header">{t('Compliance Report')}</div>
                            <div className="content-sidemenu-item_subheader">{t('All individuals with selected requirements')}</div>
                        </div>
                        <div className={"content-sidemenu-item " + (var_activetab === 'REQUIREMENTSSETUP' ? 'content-sidemenu-item-active' : '')} onClick={() => onClick_tab('REQUIREMENTSSETUP')}>
                            <div className="content-sidemenu-item_header">{t('Requirements Setup')}</div>
                            <div className="content-sidemenu-item_subheader">{t('Manage requirements')}</div>
                        </div>
                        <div className={"content-sidemenu-item " + (var_activetab === 'PACKAGESSETUP' ? 'content-sidemenu-item-active' : '')} onClick={() => onClick_tab('PACKAGESSETUP')}>
                            <div className="content-sidemenu-item_header">{t('Packages Setup')}</div>
                            <div className="content-sidemenu-item_subheader">{t('Manage requirement packages')}</div>
                        </div>
                    </div>
                    <div className="content-wrapper">

                        <div className={(var_activetab !== 'REQUIREMENTSREPORT' ? 'content-card-hide' : '')}>
                            <CRD_REQUIREMENTSREPORT
                                var_activetab={var_activetab}/>
                        </div>

                        <div className={(var_activetab !== 'COMPLIANCEREPORT' ? 'content-card-hide' : '')}>
                            <CRD_COMPLIANCEREPORT
                                var_activetab={var_activetab}/>
                        </div>

                        <div className={(var_activetab !== 'REQUIREMENTSSETUP' ? 'content-card-hide' : '')}>
                            <CRD_REQUIREMENTSSETUP
                                var_activetab={var_activetab}/>
                        </div>

                        <div className={(var_activetab !== 'PACKAGESSETUP' ? 'content-card-hide' : '')}>
                            <CRD_PACKAGESSETUP
                                var_activetab={var_activetab}/>
                        </div>

                    </div>
                </div>
            </div>
            }
        </>
    )
};

/* eslint-disable no-unused-vars */
import React from 'react';
import { useEffect, useState } from "react";
import {
	Text,
	Document,
	Font,
	Page,
	StyleSheet,

} from '@react-pdf/renderer';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import Header from './profile/Header';
import Credentials from './profile/Credentials';
import CredentialsAttachments from './profile/CredentialsAttachments';


export default function({var_org, var_credentials, var_qrCode, credential_url}){

  //  variable declarations ------------------------------------------------------------------------------------------
  Font.register({
	family: 'Roboto',
	fonts: [
		{ src: '/pdf/Roboto/Roboto-Regular.ttf', fontStyle: 'normal', fontWeight: 400 },
		{ src: '/pdf/Roboto/Roboto-Medium.ttf', fontStyle: 'normal', fontWeight: 500 },
		{ src: '/pdf/Roboto/Roboto-Bold.ttf', fontStyle: 'normal', fontWeight: 700 }
	]
	});

	Font.registerHyphenationCallback(word => (
		[word]
	));
	
	const styles = StyleSheet.create({
		page: {
			backgroundColor: 'white',
			color: '#36484e',
			fontFamily: 'Roboto',
			paddingTop: 20,
			paddingBottom: 35,
		},
		pageNumber: {
			position: 'absolute',
			fontSize: 9,
			bottom: 10,
			left: 0,
			right: 0,
			textAlign: 'center',
			color: 'grey',
		},
	})


  //  event listeners ------------------------------------------------------------------------------------------------

  useEffect(()=>{
    
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // RENDER APP ======================================================================================================

	return (
		<>
			<Document author="TerraHub" keywords="resume" subject="Shared Profile" title="Shared Profile">
                <Page style={styles.page} size="A4">
                    <Header var_org = {var_org}  var_qrCode = {var_qrCode} />
                    <Credentials var_credentials = {var_credentials} var_org = {var_org} />
					<CredentialsAttachments var_credentials={var_credentials} credential_url={credential_url} />
                    <Text 
                    fixed
                    style={styles.pageNumber}
                    render={({ pageNumber, totalPages }) => (
                      `${pageNumber} / ${totalPages}`
                    )} 
                  />

                </Page>
			</Document>
		</>
  	)
}
import { Modal, Table } from 'semantic-ui-react';
import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import datelib from '../../../../libs/date-lib';
import { useTranslation } from 'react-i18next';
import '../../../../i18n';


import CMP_TABLECONTROLS, { filter_helper } from '../../../../components/cmp_tablecontrols/cmp_tablecontrols';
import MDL_INDV_DAILYCHECKS from './mdl_indv_dailychecks/mdl_indv_dailychecks';

import './tab_dailycheck.css';



export default function({var_activetab}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_dailychecks, set_dailychecks ] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [ var_date, set_date ] = useState(Moment().local().format('MMM DD'));
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(100);
    const [ var_offset, set_offset] = useState(0);
    const [ var_search, set_search ] = useState('');
    const [ var_sortby, set_sortby ] = useState('dailycheck');
    const [ var_sortorder, set_sortorder ] = useState('descending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));

    const [ var_mdl_indv_dailychecks_open, set_mdl_indv_dailychecks_open ] = useState(false);
    const [ var_individual, set_individual ] = useState('');
    const [ var_individual_id, set_individual_id ] = useState('');


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_activetab === 'DAILYCHECK') {
            set_filter(filter_helper.initialize(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_activetab]);

    useEffect(()=>{
        if(var_filter.load) {
            populate_dailychecks(null, 0);
        }
       // eslint-disable-next-line react-hooks/exhaustive-deps
       },[var_filter]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_dailychecks(limit,offset,sortby,sortorder,search,filter){
        if (var_activetab !== 'DAILYCHECK') return; // Don't load data if tab is not visible

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        if(search) {
            if(search === '-') {
                search = '';
            }
            set_search(search);
        }else{
            search=var_search
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_dailychecks(limit,offset,sortby,sortorder,search,filter);
            set_dailychecks(results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername,sortorder){
        try {
        return await API_get_dailychecks(filter_helper.maxFilterItems,0,filtername,var_search,sortorder,{},filtername);
        } catch (e) {
        console.log(e);
        }
    }

    async function download_org_dailychecks(limit, offset){
        try {
        return await API_get_dailychecks(limit,offset,var_sortby,var_sortorder,var_search,var_filter);
        } catch (e){
        console.log(e);
        throw e;
        }
    }
    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_dailychecks(limit,offset,sortby,sortorder,search,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('org-dailychecks',
                       '/get-org-dailychecks/'+JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id,
                       {
                        queryStringParameters: {
                            limit: limit,
                            offset: offset,
                            sortby: sortby,
                            sortorder: sortorder,
                            search:search,
                            tz: datelib.timezone,
                            filtername: filtername,
                            from_dailycheckdate: Moment().startOf('day').valueOf(),
                            to_dailycheckdate: Moment().startOf('day').valueOf()
                        },
                            body: filter
                      }
                    );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_dailychecks('',0,sortby,sortorder)
    }

    function onOpen_mdl_history(var_individual_id,individual) {
        set_individual(individual);
        set_individual_id(var_individual_id);
        set_mdl_indv_dailychecks_open(true);
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="content-card-noheader" id="tab_org_overview_dailycheck">
                <div className="content-card-gridcontent">
                    <div className="content-filter">
                        <div className="content-filter-item item-pagination">
                        <CMP_TABLECONTROLS
                            var_offset={var_offset} var_limit={var_limit} var_ready={var_ready} var_loading={var_loading}
                            total_rows={var_dailychecks && var_dailychecks.length > 0 ? var_dailychecks[0]['totalrows'] : 0} populatefunction={populate_dailychecks}
                            downloadname='Todays Daily Checks' downloadfunction={download_org_dailychecks}
                            var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                            table_config={[
                                {name: 'dailycheck', datatype: 'text', labelKey: 'TODAY', download: true, filter: true},
                                {name: 'individual', datatype: 'text', labelKey: 'NAME', download: true, filter: true},
                                {name: 'email', datatype: 'text', labelKey: 'EMAIL', download: true, filter: true},
                                {name: 'organization', datatype: 'text', labelKey: 'ORGANIZATION', download: true, filter: true},
                                {name: 'phone', datatype: 'text', labelKey: 'PHONE', download: true, filter: true},
                                {name: 'location', datatype: 'text', labelKey: 'LOC/PROJ', download: true, filter: true},
                                {name: 'businessunit', datatype: 'text', labelKey: 'BUSINESS UNIT', download: true, filter: true},
                                {name: 'organization', datatype: 'text', labelKey: 'ORGANIZATION', download: true, filter: true},
                                {name: 'fittoreturn', datatype: 'int', labelKey: 'RETURN DATE', download: true, filter: false},
                                {name: 'epochfittoreturn', datatype: 'date', labelKey: 'RETURN DATE', download: false, filter: true},
                                {name: 'datecompleted', datatype: 'text', labelKey: 'DATE COMPLETED', download: true, filter: false},
                                {name: 'issued', datatype: 'date', labelKey: 'DATE COMPLETED', download: false, filter: true}
                            ]}

                        />
                    </div>
                    </div>
                    <div className="tablewrapper">
                        <Table sortable compact className={(var_loading ? " tbl_loading" : "")}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell sorted={var_sortby === 'dailycheck' ? var_sortorder : null} onClick={()=>onClick_sort('dailycheck')}>{var_date.toUpperCase()}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'individual' ? var_sortorder : null} onClick={()=>onClick_sort('individual')}>{t("NAME")}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_email" sorted={var_sortby === 'email' ? var_sortorder : null} onClick={()=>onClick_sort('email')}>{t("EMAIL")}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_phone" sorted={var_sortby === 'phone' ? var_sortorder : null} onClick={()=>onClick_sort('phone')}>{t("PHONE")}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_location" sorted={var_sortby === 'location' ? var_sortorder : null} onClick={()=>onClick_sort('location')}>{t('LOC/PROJ')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_businessunit" sorted={var_sortby === 'businessunit' ? var_sortorder : null} onClick={()=>onClick_sort('businessunit')}>{t('BUSINESS UNIT')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_organization" sorted={var_sortby === 'organization' ? var_sortorder : null} onClick={() => onClick_sort('organization')}>{t("ORGANIZATION")}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'epochfittoreturn' ? var_sortorder : null} onClick={()=>onClick_sort('epochfittoreturn')}>{t("RETURN DATE")}</Table.HeaderCell>
                                    <Table.HeaderCell className="datecompleted" sorted={var_sortby === 'issued' ? var_sortorder: null} onClick={()=>onClick_sort('issued')}>{t('DATE COMPLETED')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_icon_center">{t("HSTRY")}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            {var_ready && var_dailychecks[0]['totalrows'] !== 0 &&
                            <Table.Body>
                                {var_dailychecks.map((item,i) =>
                                    <Table.Row key={i}>
                                        <Table.Cell className="td_icon_center">
                                            {item.dailycheck === 'PASS' &&
                                            <div className='tbl_icon'><img src={"/icons/checkmark 60px (549E17).svg?ts=" + Date.now()} alt={t("pass daily check icon")}></img></div>
                                            }
                                            {item.dailycheck === 'FAIL' &&
                                            <div className='tbl_icon'><img src={"/icons/x 60px (DB2430).svg?ts=" + Date.now()} alt={t("fail daily check icon")}></img></div>
                                            }
                                            {item.dailycheck === 'NONE' &&
                                            <div className='tbl_circle'><img src={"/icons/circle 30px (bcbebe).svg?ts=" + Date.now()} alt={t("missing daily check icon")}></img></div>
                                            }
                                        </Table.Cell>
                                        <Table.Cell className="td_individual"><div className='tooltip'>{item.individual}</div></Table.Cell>
                                        <Table.Cell className="td_email">{item.email}</Table.Cell>
                                        <Table.Cell className="td_phone">{item.phone}</Table.Cell>
                                        <Table.Cell className="td_location">{item.location}</Table.Cell>
                                        <Table.Cell className="td_businessunit" >{item.businessunit}</Table.Cell>
                                        <Table.Cell className="td_organization">{item.organization}</Table.Cell>
                                        <Table.Cell >
                                            {item.fittoreturn !== null &&
                                            <>
                                                <div className='tbl_checkmark tbl_icon'><img src={"/icons/alert 60px (DB2430).svg?ts=" + Date.now()} alt={t("alert icon")}></img></div>
                                                {item.fittoreturn}
                                            </>
                                            }
                                        </Table.Cell>
                                        <Table.Cell className='td_date_completed'>{item.datecompleted}</Table.Cell>
                                        <Table.Cell className="td_icon_center" onClick={() => onOpen_mdl_history(item.individual_id, item.individual)}><img src={"/icons/history 30px (bcbebe).svg?ts=" + Date.now()} alt={t("history icon")}></img></Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                            }
                        </Table>
                        {var_ready && var_dailychecks[0]['totalrows'] === 0 &&
                        <div className="emptytable"><img src={"/icons/alert 60px (e5e5e5).svg?ts=" + Date.now()} alt={t('alert icon')}/>{t('there are no daily checks to display')}</div>
                        }
                    </div>
                    <div className="content-filter">
                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                var_offset={var_offset} var_limit={var_limit} var_ready={var_ready} var_loading={var_loading}
                                total_rows={var_dailychecks && var_dailychecks.length > 0 ? var_dailychecks[0]['totalrows'] : 0} populatefunction={populate_dailychecks}
                            />
                        </div>
                    </div>
                </div>


                {/***** MODAL: INDIVIDUAL DAILY CHECKS ***************************************************************/}

                <Modal id="mdl_indv_dailychecks"
                       dimmer={'inverted'}
                       open={var_mdl_indv_dailychecks_open}
                       onClose={() => set_mdl_indv_dailychecks_open(false)}>
                    <MDL_INDV_DAILYCHECKS
                        var_individual={var_individual}
                        var_individual_id={var_individual_id}
                        set_mdl_indv_dailychecks_open={set_mdl_indv_dailychecks_open}
                        populate_dailychecks={populate_dailychecks}>
                    </MDL_INDV_DAILYCHECKS>
                </Modal>

                {/***** END MODAL: INDIVIDUAL DAILY CHECKS ***********************************************************/}

            </div>
        </>
    )

}

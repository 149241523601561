import React from "react";
import { Route, Switch } from "react-router-dom";
import login from "./containers/login/login";
import reset from "./containers/reset/reset";
import invite from "./containers/invite/invite";
import download from "./containers/download/download";
import notfound from "./containers/notfound/notfound";
import AppliedRoute from "./components/applied_route";
import saml from "./containers/saml/saml";

import org_overview from "./containers/org/overview/overview";
import org_individuals from "./containers/org/individuals/individuals";
import org_locations from "./containers/org/locations/locations";
import org_requirements from "./containers/org-credentials/requirements/requirements";
import org_networks from "./containers/org/networks/networks";
import org_credentials from "./containers/org-credentials/credentials/credentials";
import org_holders from "./containers/org-credentials/holders/holders";
import org_verifications from "./containers/org-credentials/verifications/verifications";
import org_dailychecks from "./containers/org-dailychecks/dailychecks/dailychecks";
import org_questionnaires from "./containers/org-dailychecks/questionnaires/questionnaires";
import org_documents from "./containers/org-documents/documents/documents";
import org_forms from "./containers/org-forms/forms/forms";
import org_onsite from "./containers/org-onsite/onsite/onsite";
import org_wallet from "./containers/org/wallet/wallet/wallet";
import org_wallet_profile from "./containers/org/wallet/profile/profile";
import org_sharing from "./containers/org/wallet/sharing/sharing";
import org_reports from "./containers/org-reports/reports/reports";

import indv_wallet from "./containers/indv/wallet/wallet";
import indv_sharing from './containers/indv/wallet/crd_details/sharing/sharing';
import indv_dailycheck from "./containers/indv/dailycheck/dailycheck";
import indv_documents from "./containers/indv/documents/documents";
import indv_forms from "./containers/indv/forms/forms";
import indv_all_credentials from "./containers/indv/all_indv_credentials/all_indv_credentials"

import public_certificate from "./containers/public/certificate/certificate";
import public_orgcertificate from "./containers/public/orgcertificate/orgcertificate";
import public_profile from "./containers/public/profile/profile";
import public_orgprofile from './containers/public/orgprofile/orgprofile';
import public_location from "./containers/public/location/location";

    export default function Routes({ appProps }) {
        return (
            <Switch>
                <AppliedRoute path="/" exact component={indv_wallet} appProps={appProps} />
                <AppliedRoute path="/login" exact component={login} appProps={appProps} />
                <AppliedRoute path="/login/:code" exact component={login} appProps={appProps} />
                <AppliedRoute path="/invite" exact component={invite} appProps={appProps} />
                <AppliedRoute path="/reset" exact component={reset} appProps={appProps} />
                <AppliedRoute path="/download/:token" exact component={download} appProps={appProps} />
                <AppliedRoute path="/saml/:code/:orgname?" exact component={saml} appProps={appProps} />

                <AppliedRoute path="/org/overview" exact component={org_overview} appProps={appProps} />
                <AppliedRoute path="/org/individuals" exact component={org_individuals} appProps={appProps} />
                <AppliedRoute path="/org/locations" exact component={org_locations} appProps={appProps} />
                <AppliedRoute path="/org/requirements/:activetab?" exact component={org_requirements} appProps={appProps} />
                <AppliedRoute path="/org/networks" exact component={org_networks} appProps={appProps} />
                <AppliedRoute path="/org/credentials" exact component={org_credentials} appProps={appProps} />
                <AppliedRoute path="/org/holders" exact component={org_holders} appProps={appProps} />
                <AppliedRoute path="/org/verifications" exact component={org_verifications} appProps={appProps} />
                <AppliedRoute path="/org/dailychecks" exact component={org_dailychecks} appProps={appProps} />
                <AppliedRoute path="/org/questionnaires" exact component={org_questionnaires} appProps={appProps} />
                <AppliedRoute path="/org/documents" exact component={org_documents} appProps={appProps} />
                <AppliedRoute path="/org/forms" exact component={org_forms} appProps={appProps} />
                <AppliedRoute path="/org/onsite" exact component={org_onsite} appProps={appProps} />
                <AppliedRoute path="/org/wallet/wallet" exact component={org_wallet} appProps={appProps} />
                <AppliedRoute path="/org/wallet/profile" exact component={org_wallet_profile} appProps={appProps} />
                <AppliedRoute path="/org/wallet/sharing" exact component={org_sharing} appProps={appProps} />
                <AppliedRoute path="/org/reports" exact component={org_reports} appProps={appProps} />

                <AppliedRoute path="/indv/wallet/sharing" exact component={indv_sharing} appProps={appProps} />
                <AppliedRoute path="/indv/wallet" exact component={indv_wallet} appProps={appProps} />
                <AppliedRoute path="/indv/dailycheck" exact component={indv_dailycheck} appProps={appProps} />
                <AppliedRoute path="/indv/documents" exact component={indv_documents} appProps={appProps} />
                <AppliedRoute path="/indv/forms" exact component={indv_forms} appProps={appProps} />
                <AppliedRoute path="/indv/all-credentials" exact component={indv_all_credentials} appProps={appProps} />

                <AppliedRoute path="/public/orgcertificate/:credentialid" exact component={public_orgcertificate} appProps={appProps} />
                <AppliedRoute path="/public/certificate/:credentialid/:linkid?/:islocation?" exact component={public_certificate} appProps={appProps}/>
                <AppliedRoute path="/public/orgprofile/:linkid" exact component={public_orgprofile} appProps={appProps} />
                <AppliedRoute path="/public/profile/:linkid" exact component={public_profile} appProps={appProps} />
                <AppliedRoute path="/public/location/:profileid" exact component={public_location} appProps={appProps} />

                <Route component={notfound} />
            </Switch>
        );
    }

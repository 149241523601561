//  OUTCOMES -----------------------------------------------------------------------------------------------------------
//
//  - displays a questionnaire
//  - Can be called from the individual's wallet, or from the org.
//
//  --------------------------------------------------------------------------------------------------------------------

import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import '../../../../i18n';

import './crd_questionnaire.css';

function CRD_QUESTIONNAIRE ({var_questions, var_indv_type, set_complete, set_passfail, set_quarantinedays}) {
    //#region  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();

    const [ var_crdquestions, set_crdquestions ] = useState([]);

    //#endregion


    //#region  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(()=>{
        set_crdquestions(var_questions.map(item => ({...item})));
    },[var_questions, var_indv_type])

    useEffect(()=>{
        let results = calculate_response();
        set_passfail(results.passfail);
        set_quarantinedays(results.quarantinedays);
        set_complete(!results.incomplete);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_crdquestions]);

    //#endregion


    //#region  async functions ------------------------------------------------------------------------------------------------

    function calculate_response(dependent_on_question_id = null, response = null) {
        let fail = false;
        let quarantinedays = 0;
        let incomplete = false;

        var_crdquestions.filter(item => item.dependent_on_question_id === dependent_on_question_id && item.dependent_on_response === response &&
            (item.visible_to_individual_type === 'ALL' || item.visible_to_individual_type === var_indv_type)).forEach(item => {
            incomplete = !item.response || incomplete;
            if (item.response === 'YES' && item.fail_on_yes === 'YES') {
                fail = true;
                quarantinedays = Math.max(quarantinedays, item.yes_quarantine_days);
            } else if (item.response === 'NO' && item.fail_on_no === 'YES') {
                fail = true;
                quarantinedays = Math.max(quarantinedays, item.no_quarantine_days);
            } else {
                let results = calculate_response(item.id, item.response);
                fail = fail || results.passfail === 'FAIL';
                quarantinedays = Math.max(quarantinedays, results.quarantinedays);
                incomplete = results.incomplete || incomplete;
            }
        });
        let passfail = fail ? 'FAIL' : 'PASS';
        
        return ({passfail, quarantinedays, incomplete});
    }

    //#endregion


    //#region  API calls ------------------------------------------------------------------------------------------------------


    //#endregion


    //#region  event functions ------------------------------------------------------------------------------------------------

    function onClick_response(id, response) {
        let questions = var_crdquestions.map(item => ({...item}));
        questions.find(item => item.id === id).response = response;
        set_crdquestions(questions);
    }

    //#endregion


    //#region  RENDER APP ======================================================================================================
    return(
        <div id="crd_questionnaire">
            {var_crdquestions.filter(item =>
                item.dependent_on_question_id === null).sort((a,b) =>
                    a.questionorder - b.questionorder).map((item) =>
                        render_question(item))}
        </div>
    );
    //#endregion


    function render_question(question) { 
        return (
            <React.Fragment key={question.id}>
            {(question.visible_to_individual_type === 'ALL' || question.visible_to_individual_type === var_indv_type) && 
            <div className="questionnaire-question"> 
                <div className="questionnaire-question-wrapper"> 
                    <div className="questionnaire-question-category">{t(question.category)}</div> 
                    <div className="questionnaire-question-text">{question.question}</div>
                </div>
                <div className="questionnaire-question-answer">
                    <div className={"questionnaire-question_answer_yes" +(question.response === 'YES' ? ' answer_active' : '')}
                        onClick={() => onClick_response(question.id, 'YES')}>
                        {t('YES')}
                    </div> 
                    <div  className={"questionnaire-question_answer_no" +(question.response === 'NO' ? ' answer_active' : '')}
                        onClick={() => onClick_response(question.id, 'NO')}>
                        {t('NO')}
                    </div> 
                </div> 
            </div>
            }
            {var_crdquestions.filter(item => item.dependent_on_question_id === question.id && item.dependent_on_response === question.response).sort((a,b) => a.questionorder - b.questionorder).map(item => 
                render_question(item) 
            )}
            </React.Fragment>
        ); 
    } 
}


export default CRD_QUESTIONNAIRE;

import React from "react";
import { Button } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import '../../i18n';
import './cmp_qrcode.css';

export default function({set_mdl_open, var_id, var_title_text, var_instruction_text, var_action_text, var_action_url, var_download_filename, 
    var_allow_download, var_close_text}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();
    const QRCode = require('qrcode.react');

    //  variable listeners ---------------------------------------------------------------------------------------------

    //  async functions ------------------------------------------------------------------------------------------------

    //  API calls ------------------------------------------------------------------------------------------------------

    //  event functions ------------------------------------------------------------------------------------------------
    function onClick_downloadQR(var_download_filename, var_id){
        const canvas = document.getElementById(var_id);
        const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = var_download_filename+' QR.png';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='modal-header'>
                <div className='modal-header-title'>{var_title_text}</div>
                <div className='modal-header-close' onClick={() => set_mdl_open(false)}>
                        <img src="https://public-resources-icons.s3.amazonaws.com/icons/x 60px (717473).svg" alt={t('x icon')} />
                </div>
            </div>
            
            <div className="modal-content">
                <div className="qrcode-instruction">{var_instruction_text}</div>
                <div className="mdl_cmp_qrcode">
                    <QRCode
                        onClick={(var_allow_download ? () => onClick_downloadQR(var_download_filename, var_id) : null )}
                        id={var_id}
                        value={var_action_url}
                        size={125}
                        level={"H"}
                        includeMargin={false}
                    />
                </div>              
                {/*
                TODO: Deep link this button to open a wallet app.  
                { //If no link descriptor is given, no link is shown / clickable 
                var_action_text &&
                <div>
                    <Button type='submit' id="btn_action" className={"btn_primary btn_active"} onClick={(var_action_url)}>
                        {var_action_text}
                    </Button>
                </div>
                }
                */}
            </div>

            <div className='modal-footer-button-bar'>
                    <Button className="btn_secondary" onClick={() => set_mdl_open(false)}>{var_close_text}</Button>
            </div>
        </>
    )
}
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import './sta_credentials.css';
import datelib from '../../../../../../../libs/date-lib';
import { useTranslation } from 'react-i18next';
import '../../../../../../../i18n';



export default function({ var_activetab, var_location_id }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_stat, set_stat] = useState([]);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_activetab === 'DETAILS') {
            populate_loc_stat()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_activetab]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_loc_stat(){
        try {
            let result = await API_get_loc_stat();
            result[0]['invalid'] = result[0]['invalid'] + result[0]['expired'] + result[0]['pending'];
            set_stat(result[0]);
        } catch (e) {
            console.log(e);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_loc_stat(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('org-credentials', '/get-loc-stat/'+var_location_id+'?tz='+datelib.querystring_timezone);
    }



    //  event functions ------------------------------------------------------------------------------------------------


    // RENDER APP ======================================================================================================

    return (
        <div id="credentialsummary" className="summary_wrapper">
            <div className="summary_icon">
                <img src={"/icons/credential 60px (717473).svg?ts=" + Date.now()} alt={t("credential icon")}/>
            </div>
            <div className="summary_item">
                <div className="summary_item_number green">{var_stat.valid || 0}</div>
                <div className="summary_item_text">{t("VALID")}</div>
                <div className="summary_item_text">{t("CREDENTIALS")}</div>
            </div>
            <div className="summary_item">
                <div className="summary_item_number green">{var_stat.comingdue || 0}</div>
                <div className="summary_item_text">{t("CREDENTIALS")}</div>
                <div className="summary_item_text">{t("COMING DUE")}</div>
            </div>
            <div className="summary_item">
                <div className="summary_item_number red">{var_stat.invalid || 0}</div>
                <div className="summary_item_text">{t("INVALID")}</div>
                <div className="summary_item_text">{t("CREDENTIALS")}</div>
            </div>
        </div>
    )

}
import { API } from "aws-amplify";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import datelib from '../../../../libs/date-lib';

import { useTranslation } from 'react-i18next';
import '../../../../i18n';

import './sta_dailycheck.css';



export default function({var_pageready}){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();
    const history = useHistory();

    const [ var_indv_dailycheck_stat, set_indv_dailycheck_stat ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{

        if(var_pageready) {
            populate_dailycheck()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_pageready]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_dailycheck(){
        try {
            let indv_dailycheck_stat = await API_get_dailycheck();
            set_indv_dailycheck_stat(indv_dailycheck_stat[0]);
        } catch (e) {
            console.log(e);
        }

        set_ready(true);
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_dailycheck(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('indv-dailychecks', '/get-indv-dailycheck-stat/'+JSON.parse(sessionStorage.getItem('authentication')).id+'?tz='+datelib.querystring_timezone)
            .then(response => response)
            .catch(error => {
                console.log(error.response);
                return [{"dailycheck":"NONE","fittoreturn":null}];  // on error, return a sane result
            });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_dailycheck(){
        history.push('/indv/dailycheck')
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="stat-card" id="sta_indv_overview_dailycheck" onClick={()=>onClick_dailycheck()}>
                {var_ready &&
                <>
                    {(var_indv_dailycheck_stat.dailycheck === 'NONE' && !var_indv_dailycheck_stat.fittoreturn) || (var_indv_dailycheck_stat.dailycheck === 'NONE' && var_indv_dailycheck_stat.fittoreturn === null) ?
                        <div className="dailycheck-bg dailycheck-none">
                            <div className="dailycheck-icon"><img src={"/icons/alert 60px (FFFFFF).svg?ts=" + Date.now()} alt={t('alert icon')}></img></div>
                            <div className="dailycheck-title">{t('TAP TO COMPLETE TODAY\'S DAILY CHECK')}</div>
                        </div>
                        :
                        <>
                            {var_indv_dailycheck_stat.fittoreturn === null &&
                            <div className="dailycheck-bg dailycheck-pass">
                                <div className="dailycheck-icon"><img src={"/icons/checkmark 60px (ffffff).svg?ts=" + Date.now()} alt={t('checkmark icon')}></img></div>
                                <div className="dailycheck-text-wrapper">
                                    <div className="dailycheck-title">{t(var_indv_dailycheck_stat.passtext)}</div>
                                    <div className="dailycheck-subtitle">{var_indv_dailycheck_stat.templatelastdone}</div>
                                    <div className="dailycheck-title">{datelib.datetime_localized_format(var_indv_dailycheck_stat.dailychecklastdone)}</div>
                                </div>
                            </div>
                            }
                            {var_indv_dailycheck_stat.fittoreturn !== null &&
                            <div className="dailycheck-bg dailycheck-fail">
                                <div className="dailycheck-icon"><img src={"/icons/alert 60px (FFFFFF).svg?ts=" + Date.now()} alt={t('alert icon_icon')}></img></div>
                                <div className="dailycheck-title">{t(var_indv_dailycheck_stat.failtext)}</div>
                                {var_indv_dailycheck_stat.fittoreturn === 'FAILED CONSENT' ?
                                    <>
                                        <div className="dailycheck-subtitle">{t('Failed to provide consent')}</div>
                                        <div className="dailycheck-title">{datelib.datetime_localized_format(var_indv_dailycheck_stat.dailychecklastdone)}</div>
                                    </>
                                    :
                                    <>
                                        <div className="dailycheck-subtitle">{t('Fit to return')}</div>
                                        <div className="dailycheck-title">{datelib.date_localized_format(var_indv_dailycheck_stat.fittoreturn)}</div>
                                    </>
                                }
                            </div>
                            }
                        </>
                    }
                </>
                }
            </div>
        </>
    )

}

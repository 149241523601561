import React from "react";
import {useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { API } from "aws-amplify";
import '../../../../i18n';

import './sta_forms.css';

export default function({var_pageready, var_formsrefresh}){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();
    const history = useHistory();

    const [ var_indv_form_stat, set_indv_form_stat ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_pageready) {
            populate_indv_forms_stat()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_pageready]);

    useEffect(()=>{
        if(var_formsrefresh){
            populate_indv_forms_stat()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_formsrefresh]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_indv_forms_stat(){
        try {
            let results = await API_get_indv_forms_stat();
            set_indv_form_stat(results[0]);
        } catch (e) {
            console.log(e);
        }

        set_ready(true);
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_indv_forms_stat(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('indv-forms', '/get-indv-forms-stat/'+JSON.parse(sessionStorage.getItem('authentication')).id)
            .then(response => response)
            .catch(error => {
                console.log(error.response);
                return [{"assigned":0}];  // on error, return a sane result
            });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_forms(){
        history.push('/indv/forms')
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="stat-card" id="sta_indv_overview_form" onClick={()=>onClick_forms()}>
                {var_ready &&
                <>
                    {(var_indv_form_stat.assigned === 0) &&
                        <div className="form-bg form-none">
                            <div className="form-icon"><img src={"/icons/form 60px (ffffff).svg?ts=" + Date.now()} alt={t('form icon')}></img></div>
                            <div className="form-title">{t('FORMS')}</div>
                            <div className="form-subtitle">{t('No forms require your attention')}</div>
                        </div>
                    }
                    {(var_indv_form_stat.assigned === 1) &&
                    <div className="form-bg form-alert">
                        <div className="form-icon"><img src={"/icons/form 60px (ffffff).svg?ts=" + Date.now()} alt={t('form icon')}></img></div>
                        <div className="form-title">{t('FORMS')}</div>
                        <div className="form-subtitle">{t('You have 1 form that requires your attention')}</div>
                    </div>
                    }
                    {(var_indv_form_stat.assigned > 1) &&
                    <div className="form-bg form-alert">
                        <div className="form-icon"><img src={"/icons/form 60px (ffffff).svg?ts=" + Date.now()} alt={t('form icon')}></img></div>
                        <div className="form-title">{t('FORMS')}</div>
                        <div className="form-subtitle">{t('You have')} {var_indv_form_stat.assigned} {t('forms that require your attention')}</div>
                    </div>
                    }
                </>
                }
            </div>
        </>
    )
}

import React, { useState, useEffect, useCallback } from 'react';
import { Button, Label, Icon, Modal, Loader, Checkbox, Input, Grid } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import _ from 'lodash';
import Moment from 'moment';
import { enCA, frCA } from 'date-fns/locale';
import auth from '../../../libs/auth-lib';

import './cmp_filter.css';

export default function ({ refresh, var_filter, set_filter, populatefilterfunction, table_config }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_modalopen, set_modalopen ] = useState(false);   
    const [ var_filterinformation, set_filterinformation ] = useState([]);    
    const delayed_search = useCallback(_.throttle(execute_search, 1500), []);

    const min_filter_column_height = 4;


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (table_config){
            set_filterinformation(table_config.reduce((acc, item) => item.filter ? acc.concat({...item, applied: false, open: false, loaded: false, options: []}) : acc, []));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]);

    //  general functions ----------------------------------------------------------------------------------------------

    async function execute_search(filterinformation){
        const new_filter = {
            ...var_filter,
            filters: [],
            updated: true
        };
        filterinformation.filter(item => item.applied && item.options.some(option => !option.selected)).forEach(item => {
            let blankSelected = item.options.some(option => option.selected && option.blank);
            let valuesSelected = item.options.reduce((acc, option) => option.selected && !option.blank ? acc.concat(option.value) : acc, []);
            let allOthersSelected = !item.options.some(option => !option.selected && !option.blank);
            let blankText = item.datatype === 'text' ? 'Blank' : 'Null';
            if (blankSelected && valuesSelected.length > 0){
                new_filter.filters.push({field: item.name, operator: 'oneOfOr'+blankText, values: valuesSelected.map(option => option)});
            } else if (blankSelected){
                new_filter.filters.push({field: item.name, operator: blankText.toLowerCase()});
            } else if (allOthersSelected){
                new_filter.filters.push({field: item.name, operator: 'not'+blankText});
            } else if (valuesSelected.length > 0){
                new_filter.filters.push({field: item.name, operator: 'oneOf', values: valuesSelected.map(option => option)});
            } else {
                new_filter.filters.push({field: item.name, operator: blankText.toLowerCase()});
                new_filter.filters.push({field: item.name, operator: 'not'+blankText});
            }
        });
        set_filter(new_filter);
    }

    async function load_lookup_values(name, datatype){
        try {
            let results = await populatefilterfunction(name, datatype === 'epoch' ? 'descending' : 'ascending');
            // Initial the existingselected property which determines which preselected filters are to be cleared in Cancel
            let options = results.reduce((acc, item) => item[name] !== null && item[name] !== '' ? acc.concat({value: item[name], selected: true, blank: false, existingselected: true}) : acc, []);
            if (options.length < results.length){
                options.unshift({value: '(Blanks)', selected: true, blank: true, existingselected: true});
            }
            const filterinformation = [...var_filterinformation];
            const filteritem = filterinformation.find(item => item.name === name);
            filteritem.options = options;
            filteritem.loaded = true;
            set_filterinformation(filterinformation);
        } catch(e) {
            console.log(e);
        }
    }

    function clear_preselected_filters(){
        const filterinformation = [...var_filterinformation];
        filterinformation.filter(item => item.optionapplied).forEach(item => {       
            item.options.filter(option => option.applied).forEach(option => { 
               option.selected = !option.selected; 
               option.applied = false;
            });         
            item.applied = !item.options.every(option => option.selected);  
            item.optionapplied = false;            
            set_filterinformation(filterinformation);
            execute_search(filterinformation);           
         });   
    }

    function clear_option_applied(){
        let filterinformation = [...var_filterinformation];        
        filterinformation.filter(item => item.optionapplied).forEach(item => {                      
            item.options.filter(option => option.applied).forEach(option => {
                option.applied = false;                                
            });
            if (item.options.every(option => option.selected)) {                  
                item.optionapplied = false;
            }
            set_filterinformation(filterinformation);
            execute_search(filterinformation);          
        });
    } 

    function get_displayoptions(item){
        if (item.datatype === 'text' && item.search && item.search.length > 0){
            const search = item.search.trim().toLowerCase();
            return item.options.filter(option => !option.blank && option.value.toLowerCase().includes(search));
        } else if (item.datatype === 'int' && ((item.min && item.min.length > 0) || (item.max && item.max.length > 0))){
            let min = item.min ? Number(item.min.trim()) : Number.NEGATIVE_INFINITY;
            let max = item.max ? Number(item.max.trim()) : Number.POSITIVE_INFINITY;
            if (Number.isNaN(min) || Number.isNaN(max) || min > max) {
                return [];
            }
            return item.options.filter(option => !option.blank && option.value >= min && option.value <= max);
        } else if ((item.datatype === 'date' || item.datatype === 'epoch') && (item.min || item.max)){
            let start = item.min ? (item.datatype === 'date' ? Moment.utc(Moment(item.min).format('YYYY-MM-DD')) : Moment(item.min)) : Moment(-8640000000000000);
            let end = item.max ? (item.datatype === 'date' ? Moment.utc(Moment(item.max).format('YYYY-MM-DD')) : Moment(item.max).add(1, 'days')) : Moment(8640000000000000);
            return item.options.filter(option => !option.blank && Moment.unix(Number(option.value)).isSameOrAfter(start) && Moment.unix(Number(option.value)).isSameOrBefore(end));
        }
        return item.options;
    }   

    function reset_items() {
        const filterinformation = [...var_filterinformation];
        // close all expanded items and clear searches
        if (filterinformation.some(item => item.open)) {
            filterinformation.find(item => item.open).open = false;
        }
    
        filterinformation.forEach(item => {
            if (item.datatype === 'text') {
                item.search = null;
            } else if (item.datatype === 'int') {
                item.min = '';
                item.max = '';
            } else if (item.datatype === 'date' || item.datatype === 'epoch') {
                item.min = null;
                item.max = null;
            }            
        });        
        set_filterinformation(filterinformation); 
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_openmodal(){
        set_modalopen(true);
        const filterinformation = [...var_filterinformation];
        // Store the existingselected status of the filters to determine which preselected filters are to be cleared in Cancel
        filterinformation.forEach(item => {            
            item.options.forEach(option => { 
               option.existingselected = option.selected;
               option.applied = false;
            });       
        }); 
        set_filterinformation(filterinformation);       
    }

    function onClose_modal(){
        clear_preselected_filters();
        set_modalopen(false); 
        reset_items();   
    }  

    function onClick_apply_filter(){        
        const filterinformation = [...var_filterinformation];
        if (filterinformation.some(item => item.optionapplied)) {         
            set_modalopen(false);
            reset_items();
            clear_option_applied();
        }         
    }

    function onClick_cancel(){   
        clear_preselected_filters();
        set_modalopen(false); 
        reset_items();          
    }

    function onClick_filteritemlabel(name){
        const filterinformation = [...var_filterinformation];
        filterinformation.filter(item => item.name !== name && item.open).forEach(item => item.open = false);
        const filteritem = filterinformation.find(item => item.name === name);
        filteritem.open = !filteritem.open;
        set_filterinformation(filterinformation);
        if (!filteritem.loaded){
            load_lookup_values(name, filteritem.datatype);
        }
    }

    function onClick_option(name, value){
        const filterinformation = [...var_filterinformation];
        const filteritem = filterinformation.find(item => item.name === name);
        let option = filteritem.options.find(option => option.value === value);
        option.selected = !option.selected;            
        // Add a filter level property applied indicating its preselected value different from its existing selected value     
        option.applied = option.selected !== option.existingselected;       
        filteritem.applied = filteritem.options.some(option => !option.selected);
        filteritem.optionapplied = filteritem.options.some(option => option.applied);
        set_filterinformation(filterinformation);
        delayed_search(filterinformation);
    }

    function onChange_search(value){
        const filterinformation = [...var_filterinformation];
        const filteritem = filterinformation.find(item => item.open);
        filteritem.search = value ? value : '';
        set_filterinformation(filterinformation);
    }

    function onChange_min(value){
        const filterinformation = [...var_filterinformation];
        const filteritem = filterinformation.find(item => item.open);
        filteritem.min = value;
        set_filterinformation(filterinformation);
    }

    function onChange_max(value){
        const filterinformation = [...var_filterinformation];
        const filteritem = filterinformation.find(item => item.open);
        filteritem.max = value;
        set_filterinformation(filterinformation);
    }

    function onClick_selectall(name){
        const filterinformation = [...var_filterinformation];
        let filteritem = filterinformation.find(item => item.name === name);
        let options = get_displayoptions(filteritem);     
        let setselected = options.some(option => !option.selected);
        options.forEach(option => {    
            option.selected = setselected;
            // Add a filter level property applied indicating its preselected value different from its existing selected value
            option.applied = option.selected !== option.existingselected;           
        });        
        filteritem.applied = filteritem.options.some(option => !option.selected)
        filteritem.optionapplied = filteritem.options.some(option => option.applied);      
        set_filterinformation(filterinformation);
        delayed_search(filterinformation);
    } 

    // RENDER ==========================================================================================================

    return (
        <div>
            <Button className='btn_icononly filter_button' onClick={onClick_openmodal}>
                <Icon name='filter' className='btn_icon' />
                {var_filterinformation.some(item => item.applied) &&
                <Label circular color='orange' className='filter_count_label'>{var_filterinformation.filter(item => item.applied).length}</Label>
                }
            </Button>
            <Modal className='filter_modal'
                dimmer={'inverted'}
                open={var_modalopen}
                onClose={onClose_modal}>
                <div className='modal-header'>
                    <Icon name='filter' />
                    <div className='modal-header-title'>{t('Filters')}</div>
                    <div className='modal-header-close' onClick={onClose_modal}>
                        <img src={'/icons/x 60px (717473).svg?ts=' + Date.now()} alt={t("x icon")} />
                    </div>
                </div>
                <div className='modal-content'>
                    <div className='filter-content'>
                        {
                        var_filterinformation.map(item => {
                            const displayoptions = get_displayoptions(item);
                            return (
                                <div key={'filterheader-'+item.name} className={'filter_item'+(item.open ? ' open' : '')}>
                                    <div className='filter_item_label' onClick={() => onClick_filteritemlabel(item.name)}>
                                        <div>
                                            <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 16 16' stroke='#000000' strokeWidth='2' strokeLinecap='round'>
                                            {item.open
                                                ? <><path d='M2 2L14 14' /><path d='M2 14L14 2' /></>
                                                : <><path d='M0 8h16' /><path d='M8 0v16' /></>
                                            }
                                            </svg>
                                        </div>
                                        <div>{item.label ? item.label : t(item.labelKey)}</div>
                                        {item.applied &&
                                        (<div><Label circular color='orange'>{item.options.filter(option => option.selected).length}</Label></div>)
                                        }
                                    </div>
                                    <div className='filter_item_option_container' style={{height: item.loaded ? Math.min(27*displayoptions.length+48+28,264) : 40}}>
                                        {rendersearch(item)}
                                        {item.loaded ?
                                            <div className='filter_item_options' style={{height: item.loaded ? Math.min(27*displayoptions.length+28, 244) : 40}}>
                                                <div>
                                                    <Checkbox checked={!displayoptions.some(option => !option.selected)}
                                                        disabled={displayoptions.length === 0}
                                                        onClick={() => onClick_selectall(item.name)}
                                                        label={t('Select All')} />
                                                </div>
                                                {displayoptions.map(option => (
                                                        <div key={'filteroption-'+item.name+'-'+option.value}>
                                                            <Checkbox checked={option.selected}
                                                                onClick={() => onClick_option(item.name, option.value)}
                                                                label={item.datatype === 'epoch' && !option.blank
                                                                    ? Moment.unix(option.value).utc(true).format('lll')
                                                                    : item.datatype === 'date' && !option.blank
                                                                    ? Moment.unix(option.value).utc(false).format('ll')
                                                                    : option.value}
                                                            />
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        :   (<Loader active inline='centered' />)
                                        }
                                    </div>
                                </div>)
                            }
                        )}
                        {var_filterinformation.length < min_filter_column_height 
                        ? [...Array(min_filter_column_height - var_filterinformation.length).keys()].map(item => {
                            return (
                                <div key={"filter_item_label_filler_" + item} className="filter_item_label filter_item_label_filler"></div>
                            )})
                        : <></>}
                    </div>
                    <div className="modal-footer">        
                        <div className="modal-footer-buttons">
                            <Button className="btn_secondary" onClick={() => onClick_cancel()}>{t("CANCEL")}</Button>                       
                            <Button className={'btn_primary'+(var_filterinformation.some(item => item.optionapplied) ? ' btn_active' : '')}                       
                                    onClick={onClick_apply_filter}>{t("APPLY FILTER")}</Button> 
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );

    function rendersearch(item){
        if (!item.loaded){
            return null;
        }
        switch (item.datatype){
            case 'text':
                return (
                    <div className='filter_search'>
                        <Input fluid
                            placeholder={t('Search...')}
                            type='search'
                            value={item.search || ''}
                            onChange={(e) => onChange_search(e.target.value)}
                            icon={item.search && item.search.length > 0 ? <Icon link name='times' onClick={() => onChange_search('')} /> : <Icon name='search' />}
                        />
                    </div>
                );
            case 'int':
                return (
                    <div className='filter_search'>
                        <Grid columns={2}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input
                                        fluid
                                        placeholder={t('Min')}
                                        value={item.min || ''}
                                        type='number'
                                        onChange={(e) => onChange_min(e.target.value)}
                                        error={item.min !== undefined && item.min.trim().length > 0 && Number.isNaN(Number(item.min))}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input
                                        fluid
                                        placeholder={t('Max')}
                                        value={item.max || ''}
                                        type='number'
                                        onChange={(e) => onChange_max(e.target.value)}
                                        error={item.max !== undefined && item.max.trim().length > 0 && Number.isNaN(Number(item.max))}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                );
            case 'date':
            case 'epoch':
                return (
                    <div className='filter_search'>
                        <Grid columns={2}>
                            <Grid.Row>
                                <Grid.Column>
                                    <SemanticDatepicker
                                        locale={auth.language.datepicker}
                                        format={auth.language.name === 'French' ? 'D MMM YYYY' : 'MMM DD, YYYY'}
                                        formatOptions={{locale: auth.language.name === 'French' ? frCA: enCA}}
                                        placeholder={t('Start date')}
                                        value={item.min || null}
                                        maxDate = {item.max || null}
                                        onChange={(e, data) => onChange_min(data.value)}
                                        clearable={true}
                                        datePickerOnly={true}
                                        showToday={false}
                                        iconPosition='left'
                                        />
                                </Grid.Column>
                                <Grid.Column>
                                    <SemanticDatepicker
                                        locale={auth.language.datepicker}
                                        format={auth.language.name === 'French' ? 'D MMM YYYY' : 'MMM DD, YYYY'}
                                        formatOptions={{locale: auth.language.name === 'French' ? frCA: enCA}}
                                        placeholder={t('End date')}
                                        value={item.max || null}
                                        minDate={item.min || null}
                                        onChange={(e, data) => onChange_max(data.value)}
                                        clearable={true}
                                        datePickerOnly={true}
                                        showToday={false}
                                        iconPosition='left'
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                );
            default:
                return null;
        }
    }
}
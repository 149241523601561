import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, StyleSheet, View, Image } from '@react-pdf/renderer';


export default function({var_indv}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

	const styles = StyleSheet.create({
		section: {
			paddingHorizontal: 20,
			marginTop: 20,
		},
		sectionTitle: {
			fontSize: 12,
			fontWeight: 700,
			paddingBottom: 5,
		},
		table: {
			borderStyle: 'solid',
			borderColor: '#ebebeb',
			borderWidth: 1,
		},
		tableHeaderRow: {
			flexDirection: "row",
		},
		tableRow: {
			flexDirection: "row",
			borderTopWidth: 1,
			borderTopStyle: 'solid',
			borderTopColor: '#ebebeb'
		},
		tableHeaderCol: {
			width: '25%',
			color: '#cfd3d5',
		},
		tableRowCol: {
			width: '25%',
		},
		tableHeaderCellText: {
			padding: 6,
			fontSize: 9,
			fontWeight: 500,
		},
		tableCellText: {
			padding: 6,
			fontSize: 9,
		},
		alert: {
			paddingHorizontal: '6px',
			paddingVertical: '6px',
			color: '#cdcecd',
			fontSize: 9,
			flexDirection: "row",
			alignItems: 'center',
			justifyContent: 'center',
			borderTopWidth: 1,
			borderTopStyle: 'solid',
			borderTopColor: '#ebebeb'
		},
		alertIcon: {
			width: '12px',
			marginRight: '4px'
		},
	});

    // RENDER APP ======================================================================================================
    return(
		<View style={styles.section}>

			<Text style={styles.sectionTitle}>{t('Individuals')}</Text>

			<View style={styles.table}>

				<View style={styles.tableHeaderRow} wrap={false}>
					<View style={[styles.tableHeaderCol, {width: '27%'}]}>
						<Text style={styles.tableHeaderCellText}>{t('NAME')}</Text>
					</View>
				</View>

				{var_indv.map((item, index) =>
					<View key={index} style={styles.tableRow} wrap={false}>
						<View style={[styles.tableRowCol, {width: '27%'}]}>
							<Text style={styles.tableCellText}>{item.detail.individual}</Text>
						</View>				
					</View>
				)}

				{var_indv.length === 0 &&
					<View style={styles.alert} wrap={false}>
						<Image src="/pdf/alert 60px (e5e5e5).png" style={styles.alertIcon} />
						<Text>{t('there are no individuals to display')}</Text>
					</View>
				}

			</View>

		</View>
    )

}